import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import { EntityData } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class EquipmentsDb extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/equipmentsDb.ts';

  equipments!: Table<EntityData>;

  logger: CustomLogger;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('equipmentsDb');
    this.version(1).stores({
      equipments: 'id',
    });

    this.logger = CustomLogger.getInstance();
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.equipments.count();
    } catch (error: unknown) {
      errorHandler(EquipmentsDb.TAG, error, 'countRecords');

      return 0;
    }
  };

  remplaceOrInsert = async (entityData: EntityData): Promise<boolean> => {
    try {
      await this.equipments.put(entityData);

      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        error.message = `L'enregistrement de l'entityData ${entityData.title} a échoué: ${error.message}`;
        errorHandler(EquipmentsDb.TAG, error, 'remplaceOrInsert');
      }
    }

    return false;
  };

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.equipments.clear();
      logger.log(EquipmentsDb.TAG, `all tables from ${this.name} have been cleared`);
    } catch (error) {
      errorHandler(EquipmentsDb.TAG, error, 'clearAllTables');
    }
  }

  async getEquipmentById(id?: string): Promise<EntityData | undefined> {
    try {
      if (!id) return;

      return await this.equipments.get({ id });
    } catch (error) {
      errorHandler(EquipmentsDb.TAG, error, 'getEquipmentById');
    }
  }
}
const equipmentsDb = new EquipmentsDb();

export default equipmentsDb;
