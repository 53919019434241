import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { Name } from './const';

const DeclarationViewSkeleton = () => {
  const data = new Array(6).fill('');

  return (
    <Box data-cy={Name} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {data.map((skel, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          animation="wave"
          sx={{ height: '8vh', width: '98vw', margin: '10px' }}
        />
      ))}
    </Box>
  );
};

export default DeclarationViewSkeleton;
