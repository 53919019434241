import { ConsentFrequency } from '90.quickConnect.Models/enums';

export const mapCheckRGPD = (checkRGPD: string | undefined): ConsentFrequency => {
  switch (checkRGPD) {
    case ConsentFrequency.Always:
      return ConsentFrequency.Always;

    case ConsentFrequency.EveryDay:
      return ConsentFrequency.EveryDay;

    case ConsentFrequency.EveryMonth:
      return ConsentFrequency.EveryMonth;

    case ConsentFrequency.EveryWeek:
      return ConsentFrequency.EveryWeek;

    case ConsentFrequency.EveryYear:
      return ConsentFrequency.EveryYear;

    case ConsentFrequency.OneTime:
      return ConsentFrequency.OneTime;

    default:
      return ConsentFrequency.Undef;
  }
};
