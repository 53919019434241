/**
 * Liste de valeurs possibles pour indiquer si on montre la carte où non
 */
export enum ShowMapEnum {
  /**
   * OFF, Par défaut
   */
  Off = 0,
  /**
   * ON sans déplacement,
   */
  Static = 1,
  /**
   * ON avec déplacement
   */
  Dynamic = 2,
}
