import { toast, Id } from 'react-toastify';
import { TFunction } from 'i18next';
import { AutocompleteChangeDetails } from '@mui/material';
import { FieldData, NotificationDesc, NotificationTarget, QCNotification } from '90.quickConnect.Models/models';
import { isEmail } from '80.quickConnect.Core/helpers';
import { OptionsRender } from '50.quickConnect.Fields/FieldsTypes/Inputs/TextQcField/types';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';
import { FieldType } from '90.quickConnect.Models/enums';

export const isOptionsRender = (input: unknown): input is OptionsRender => {
  if (typeof input !== 'object') return false;
  if (Array.isArray(input) || input === null) return false;

  return 'id' in input && 'title' in input && 'description' in input && 'value' in input;
};

export const checkNotificationTargets = (
  emailsStr: string,
  selectedTargets: NotificationTarget[],
  t: TFunction,
): NotificationTarget[] => {
  // On split la chaine de caractère par , ou ;
  let toastId: Id | undefined;
  const emails: string[] = emailsStr
    .replace(',', ';')
    .split(';')
    .map((str: string) => str.trim())
    .filter((value, index, array) => array.indexOf(value) === index);

  return emails.reduce((acc: NotificationTarget[], email: string): NotificationTarget[] => {
    if (!isEmail(email)) {
      if (toastId) {
        toast.update(toastId, { render: t('formlib_mail_error_not_a_mail').toString() });
      } else {
        toastId = toast.error(t('formlib_mail_error_not_a_mail').toString());
      }
      return acc;
    }

    const notificationTargetWithSameEmail = selectedTargets.find(
      (selectedTarget: NotificationTarget) => selectedTarget.target === email,
    );

    if (selectedTargets.length > 0 && notificationTargetWithSameEmail) {
      if (toastId) {
        toast.update(toastId, { render: t('formlib_mail_error_mail_already_added').toString() });
      } else {
        toastId = toast.error(t('formlib_mail_error_mail_already_added').toString());
      }

      return acc;
    }

    return !notificationTargetWithSameEmail ? [...acc, { alias: null, target: email }] : acc;
  }, selectedTargets);
};

export const checkNotificationTargetsNoToasts = (
  emailsStr: string,
  selectedTargets: NotificationTarget[],
): NotificationTarget[] => {
  // On split la chaine de caractère par , ou ;
  const emails: string[] = emailsStr
    .replace(',', ';')
    .split(';')
    .map((str: string) => str.trim())
    .filter((value, index, array) => array.indexOf(value) === index);

  return emails.reduce((acc: NotificationTarget[], email: string): NotificationTarget[] => {
    if (!isEmail(email)) {
      return acc;
    }

    const notificationTargetWithSameEmail = selectedTargets.find(
      (selectedTarget: NotificationTarget) => selectedTarget.target === email,
    );

    if (selectedTargets.length > 0 && notificationTargetWithSameEmail) {
      return acc;
    }

    return !notificationTargetWithSameEmail ? [...acc, { alias: null, target: email }] : acc;
  }, selectedTargets);
};

export const notificationFieldsCheckUnprocessedData = (
  simpleFields: FieldData[],
  isDraft: boolean,
  sendAPI: boolean | undefined,
  setFieldFocused: (fieldId: string) => void,
  t: TFunction,
): void => {
  if (!isDraft && sendAPI) {
    simpleFields.forEach((field) => {
      if (field.fieldType === FieldType.Notification) {
        if (isQCNotification(field.value)) {
          const notificationData = field.value as QCNotification;
          if (notificationData.unProcessedData?.length > 0) {
            const newSelectedTargets = checkNotificationTargetsNoToasts(
              notificationData.unProcessedData,
              notificationData.selectedTargets,
            );
            if (newSelectedTargets.length != notificationData.selectedTargets.length) {
              setFieldFocused(field.id);
              const infoMsg = t('fields_notificationqc_error_contains_unparsed_email').toString();
              throw new Error(infoMsg);
            }
          }
        }
      }
    });
  }
};

export const notificationHelperCreateOption = (
  value: string,
  { dataSource }: NotificationDesc,
  notificationData: QCNotification,
  t: TFunction,
): QCNotification => {
  // Voir si l'attribut forcedSelection est sur true...
  const isForcedSelection = dataSource?.forcedSelection ?? false;

  if (isForcedSelection) return notificationData;
  // On est dans le cas le plus simple puisque ce n'est qu'une chaine de caractères...
  const newSelectedTargets = checkNotificationTargets(value, notificationData.selectedTargets, t);

  return { ...notificationData, selectedTargets: newSelectedTargets };
};

export const notificationHelperSelectOption = (
  { value }: OptionsRender,
  notificationDesc: NotificationDesc,
  notificationData: QCNotification,
  details: AutocompleteChangeDetails<any> | undefined,
  t: TFunction,
): QCNotification => {
  const newSelectedTargets = checkNotificationTargets(value, notificationData.selectedTargets, t);

  return { ...notificationData, selectedTargets: newSelectedTargets };
};

export const notificationHelperRemoveOption = (
  notificationData: QCNotification,
  details: AutocompleteChangeDetails<NotificationTarget | string> | undefined,
): QCNotification | never => {
  if (!details)
    throw new Error(
      `[Client Web] notificationQCField/helpers/index.ts - notificationHelperRemoveOption method failed: 'details' parameter can not be undefined`,
    );
  const { option } = details;
  const targetToRemove = typeof option === 'string' ? option : option.target;
  const newSelectedTargets = notificationData.selectedTargets.filter(
    ({ target }: NotificationTarget) => target !== targetToRemove,
  );

  return { ...notificationData, selectedTargets: newSelectedTargets };
};

export const notificationHelperClearOption = (notificationData: QCNotification): QCNotification => ({
  ...notificationData,
  selectedTargets: [],
});
