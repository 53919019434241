import React, { FunctionComponent } from 'react';
import { Button, ButtonProps, Stack } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const NavigationButtons: FunctionComponent = () => {
  const { t } = useTranslation('moduleWorkSite');
  const navigate = useNavigate();
  const buttonProps: ButtonProps = {
    endIcon: <ChevronRight />,
    onClick: () => navigate('./users'),
    variant: 'contained',
    fullWidth: true,
    style: {
      textAlign: 'start',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
    },
  };

  return (
    <Stack gap={'.5rem'}>
      <Button {...buttonProps}>{t('work_site_summary_users')}</Button>
      <Button {...buttonProps}>{t('work_site_summary_work_part_wbs')}</Button>
    </Stack>
  );
};

export default NavigationButtons;
