import { FormEditedData } from '90.quickConnect.Models/models';

const mapQCHistoricalData = (historicalData: FormEditedData): FormEditedData =>
  ({
    id: historicalData.id,
    activityId: historicalData.activityId,
    alertLevelLabel: historicalData.alertLevelLabel,
    alertLevelValue: historicalData.alertLevelValue,
    eTag: historicalData.eTag,
    editedAt: historicalData.editedAt,
    editedData: historicalData.editedData,
    entityInstanceId: historicalData.entityInstanceId,
    entityInstanceStringExtract: historicalData.entityInstanceStringExtract,
    entitySchemaId: historicalData.entitySchemaId,
    formId: historicalData.formId,
    formType: historicalData.formType,
    itemDataStatus: historicalData.itemDataStatus,
    name: historicalData.name,
    organizationalUnitStringExtract: historicalData.organizationalUnitStringExtract,
    serializedBody: historicalData.serializedBody,
    stringExtract: historicalData.stringExtract,
    userUpn: historicalData.userUpn,
    workflowStatus: historicalData.workflowStatus,
  } as FormEditedData);

export default mapQCHistoricalData;
