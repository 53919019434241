import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Box, Chip } from '@mui/material';
import { HierarchicalListSummaryName } from './const';
import { HierarchicalListSummaryProps } from './types';
import { FlexColumn } from '50.quickConnect.Fields/styles';
import { HierarchicalChoice } from '90.quickConnect.Models/models';

const HierarchicalListSummary = ({
  parentId,
  value,
  isReadOnly,
  setSelectedIndex,
  updateDeclaration,
  hierarchicalDesc,
  setDeleteItem,
}: HierarchicalListSummaryProps) => {
  const nameWithParentId = `${HierarchicalListSummaryName}-${parentId}`;
  const { fullPathId } = hierarchicalDesc;
  const isArray = value.constructor.name === 'Array';

  const deletItem = useCallback(
    (item: string) => {
      if (isArray) {
        const filterValue = (value as HierarchicalChoice[]).filter((it) => it.value !== item, []);
        updateDeclaration(fullPathId, filterValue);
        setDeleteItem(item);
      } else {
        updateDeclaration(fullPathId, undefined);
        setDeleteItem(item);
      }
    },
    [fullPathId, isArray, setDeleteItem, updateDeclaration, value],
  );
  return (
    <Box
      data-cy={nameWithParentId}
      sx={FlexColumn}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(parentId))}
    >
      <Box>
        {isArray ? (
          (value as HierarchicalChoice[]).map(({ value: choiceValue, label }) => (
            <Chip
              disabled={isReadOnly}
              key={`${choiceValue}-${label}`}
              label={label}
              onDelete={() => {
                deletItem(choiceValue);
              }}
            />
          ))
        ) : (
          <Chip
            disabled={isReadOnly}
            key={`${(value as HierarchicalChoice).value}-${(value as HierarchicalChoice).label}`}
            label={(value as HierarchicalChoice).label}
            onDelete={() => {
              deletItem((value as HierarchicalChoice).value);
            }}
          />
        )}
      </Box>
    </Box>
  );
};
export default observer(HierarchicalListSummary);
