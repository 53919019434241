import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import {
  DeclarationViewer,
  FormEditedData,
  DeclarationContext,
  LocalBackupDeclaration,
  FormEditedDataDTO,
} from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { SynchronizeDeclaration } from '40.quickConnect.DataAccess/indexedDb/dbs/queriesTypes/declaration';
import { DocumentTransferState } from '90.quickConnect.Models/enums';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class DeclarationsDb extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/declarationsDb.ts';

  declarations!: Table<FormEditedData>;

  declarationsToUpload!: Table<SynchronizeDeclaration>;

  declarationListItems!: Table<DeclarationViewer>;

  backupDeclarationContext!: Table<LocalBackupDeclaration>;

  draftDeclarations!: Table<LocalBackupDeclaration>;

  logger: CustomLogger = CustomLogger.getInstance();

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('declarationsDb');
    this.version(5).stores({
      declarations: 'id',
      declarationListItems: 'id',
      backupDeclarationContext: '[formId+userUPN], userUPN',
      draftDeclarations: 'id',
      declarationsToUpload: 'userUPN, customerId, [customerId+userUPN], [customerId+transferState]',
    });
  }

  countBackUpDclRecords = async (): Promise<number> => {
    try {
      return await this.backupDeclarationContext.count();
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'countBackUpDclRecords');
      return 0;
    }
  };

  countDraftDclRecords = async (): Promise<number> => {
    try {
      return await this.draftDeclarations.count();
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'countDraftDclRecords');
      return 0;
    }
  };

  countDclToUploadRecords = async (): Promise<number> => {
    try {
      return await this.declarationsToUpload.count();
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'countDclToUploadRecords');

      return 0;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async clearAllTables(logger: CustomLogger) {}

  getDeclarationsToUpload = async (customerId: string): Promise<SynchronizeDeclaration[]> => {
    try {
      const records = this.declarationsToUpload
        .where({
          customerId: customerId,
          transferState: DocumentTransferState.ToBeTransfered,
        })
        .toArray();

      return records ? await records : [];
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'getDeclarationsToUpload');
      return [];
    }
  };

  addDeclarationToUpload = async (request: SynchronizeDeclaration): Promise<boolean> => {
    try {
      const userUPNSyncDeclaration: SynchronizeDeclaration | undefined = await this.declarationsToUpload.get(
        request.userUPN,
      );

      if (userUPNSyncDeclaration) {
        const { editedData } = userUPNSyncDeclaration;
        const { editedData: editedDataFromRequest, customerId } = request;
        const newEditedData: FormEditedDataDTO[] = [...editedData, ...editedDataFromRequest];
        const newData = { ...userUPNSyncDeclaration, editedData: newEditedData, customerId: customerId };
        await this.declarationsToUpload.put(newData, request.userUPN);
      } else {
        await this.declarationsToUpload.add(request, request.userUPN);
      }

      return true;
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'addDeclarationToSend');
      return false;
    }
  };

  removeDeclarationByUserUPN = async (userUPN: string): Promise<boolean> => {
    try {
      await this.declarationsToUpload.delete(userUPN);

      return true;
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'removeDeclarationByUserUPN');

      return false;
    }
  };

  /**
   *
   * @returns la déclaration à partir de son ID
   */
  public getDeclaration = async (declarationId: string): Promise<FormEditedData | undefined> => {
    try {
      const decl = await this.declarations.where({ id: declarationId }).first();
      return decl ? decl : undefined;
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'getDeclaration');
    }
  };

  public getLocalDrafts = async (userUPN: string, logger: CustomLogger): Promise<DeclarationViewer[]> => {
    try {
      const formsEdited = await this.draftDeclarations.toArray();

      const formsEditedByCurrentUserUPN = formsEdited.filter(
        (draft: LocalBackupDeclaration) => draft.userUPN === userUPN,
      );

      const declarationsViewerWithUndefined = await Promise.all(
        formsEditedByCurrentUserUPN.map((form: LocalBackupDeclaration) => {
          return this.declarationListItems.where({ id: form.id }).first();
        }),
      );

      return declarationsViewerWithUndefined.filter(
        (item: DeclarationViewer | undefined): item is DeclarationViewer => {
          return !!item;
        },
      );
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'getLocalDrafts');

      return [];
    }
  };

  public getLocalDraftByDeclarationId = async (declarationId: string): Promise<DeclarationContext | undefined> => {
    try {
      const localDraftDeclaration = await this.draftDeclarations.get({ id: declarationId });

      if (localDraftDeclaration) {
        const { userUPN, ...rest } = localDraftDeclaration;

        return rest;
      }
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'getLocalDraftByDeclarationId');
    }
  };

  public insertBackUpDeclarationContext = async (
    context: DeclarationContext,
    userUPN: string,
    logger: CustomLogger,
  ): Promise<void> => {
    try {
      const localBackupDeclarationContext: LocalBackupDeclaration = {
        ...context,
        userUPN,
      };

      const existingLocalBackupDeclarationContext = await this.backupDeclarationContext
        .where({ formId: context.formId, userUPN: userUPN })
        .toArray();

      if (existingLocalBackupDeclarationContext.length > 0) {
        await this.backupDeclarationContext.put(localBackupDeclarationContext, [context.formId, userUPN]);
      } else {
        await this.backupDeclarationContext.add(localBackupDeclarationContext, [context.formId, userUPN]);
      }
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'insertBackUpDeclarationContext');
    }
  };

  public getBackUpDeclarationContext = async (
    formId: string,
    userUPN: string,
    logger: CustomLogger,
  ): Promise<LocalBackupDeclaration | undefined> => {
    try {
      return await this.backupDeclarationContext.where({ formId: formId, userUPN: userUPN }).first();
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'getBackUpDeclarationContext');
    }
  };

  /** Permet de supprimer un enrigistrement de la table des Déclarations */
  removeDeclaration = async (logger: CustomLogger, declarationId: string): Promise<number> => {
    try {
      return await this.declarations.where({ id: declarationId }).delete();
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'removeDeclaration');
      return 0;
    }
  };

  removeDraftDeclarationById = async (declarationId: string): Promise<number> => {
    try {
      return await this.draftDeclarations.where({ id: declarationId }).delete();
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'removeDraftDeclaration');

      return 0;
    }
  };

  /** Permet de supprimer un enrigistrement de la table des Déclarations */
  removeBackupDeclaration = async (logger: CustomLogger, formId: string, userUPN: string): Promise<number> => {
    try {
      return await this.backupDeclarationContext.where({ formId, userUPN }).delete();
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'removeDeclaration');

      return 0;
    }
  };

  /**
   *Permet de trouver une sauvegarde automatique concernant l'utilisateur courant. Retourne la déclaration ou undefined
   *
   * @param {CustomLogger} logger
   * @param {string} userUPN
   * @memberof DeclarationsDb
   */
  findBackUpByUserUPNAsync = async (logger: CustomLogger, userUPN: string): Promise<LocalBackupDeclaration[]> => {
    try {
      // On va rechercher dans la collection et on prendra le première sauvegarde (Normalement, on doit en avoir une)
      return await this.backupDeclarationContext.where({ userUPN }).toArray();
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'findBackUpByUserUPN');

      return [];
    }
  };

  deleteLocalBackupAsync = async (logger: CustomLogger, formId: string, userUPN: string): Promise<boolean> => {
    try {
      const backup = this.backupDeclarationContext.where({ formId, userUPN });

      const backupDeleted: number = await backup.delete();

      return backupDeleted === 1;
    } catch (error: unknown) {
      errorHandler(DeclarationsDb.TAG, error, 'deleteLocalBackupAsync');
      return false;
    }
  };

  async deleteLocalBackUpByDeclarationId(declarationId: string): Promise<boolean> {
    try {
      await this.backupDeclarationContext.where('id').equals(declarationId).delete();
      return true;
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'deleteLocalBackUpByDeclarationId');
      return false;
    }
  }

  async deleteListItemsDeclaration(declarationId: string): Promise<boolean> {
    try {
      await this.declarationListItems.where('id').equals(declarationId).delete();

      return true;
    } catch (error) {
      errorHandler(DeclarationsDb.TAG, error, 'deleteListItemsDeclaration');
      return false;
    }
  }

  public async updatebackUpDcl(newContext: DeclarationContext, userUPN: string): Promise<boolean | never> {
    return this.transaction('rw', this.backupDeclarationContext, async () => {
      const oldBackUp = await this.backupDeclarationContext.get({ formId: newContext.formId, userUPN });

      if (!oldBackUp) {
        const newBackUp = {
          ...newContext,
          id: newContext.id,
          editedData: newContext.editedData,
          stringExtract: newContext.stringExtract,
          itemData: newContext.itemData,
          internalData: newContext.internalData,
        };

        await this.insertBackUpDeclarationContext(newBackUp, userUPN, CustomLogger.getInstance());
      } else {
        const newBackup = { ...oldBackUp, context: newContext, itemData: newContext.itemData };

        await this.backupDeclarationContext.put(newBackup);
      }

      return true;
    });
  }
}

const declarationsDb = new DeclarationsDb();

export default declarationsDb;
