import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UseDataProps } from './types';
import { StepDesc, HistoricalStepData } from '90.quickConnect.Models/models';
import { WorkflowStepStatus } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  historicalStepDataList: HistoricalStepData[],
  currentStepId: string,
  steps: StepDesc[],
  isEditable: boolean,
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const {
    DeclarationStore: { setCurrentStepWorkFlow },
  } = useStore();
  // On détermine la step courante: step à remplir
  const currentStepIndex = useMemo(
    () => steps.findIndex((s) => s.id.localeCompare(currentStepId, undefined, { sensitivity: 'base' }) === 0) ?? 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStepId],
  );

  // on récupère le statut des différentes steps
  const stepsTransitionStatus = useMemo(() => {
    const transitionStatusMap = new Map<string, WorkflowStepStatus>();
    steps.forEach((s, index) => {
      if (index < currentStepIndex) transitionStatusMap.set(s.id, WorkflowStepStatus.Validated);
      if (index === currentStepIndex) transitionStatusMap.set(s.id, WorkflowStepStatus.None);
      if (index > currentStepIndex) transitionStatusMap.set(s.id, WorkflowStepStatus.None);
    });
    return transitionStatusMap;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicalStepDataList]);

  // Onglet courant : onglet sélectionné
  const [valueTabIndex, setValueTabIndex] = useState(currentStepIndex);
  const [selectedStep, setSelectedStep] = useState<StepDesc>(steps[currentStepIndex]);

  // indique les champs de l'onglet courant sont readonly ou non
  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    if (selectedStep) {
      setCurrentStepWorkFlow(selectedStep);
    }
  }, [selectedStep, setCurrentStepWorkFlow]);
  const stepChanged = useCallback(
    (stepId: string) => {
      const newCurrentStepIndex = steps.findIndex((s) => s.id === stepId);
      if (newCurrentStepIndex <= currentStepIndex) {
        setValueTabIndex(newCurrentStepIndex);
        setSelectedStep(steps[newCurrentStepIndex]);
      } else {
        toast.warn(t('formlib_workflow_must_validate_current_step').toString());
      }
    },
    [currentStepIndex, steps, t],
  );

  useEffect(() => {
    if (isEditable) {
      const checkIsReadOnly = selectedStep
        ? stepsTransitionStatus.get(selectedStep.id) === WorkflowStepStatus.Validated
        : true;
      setIsReadOnly(checkIsReadOnly);
    }
  }, [isEditable, selectedStep, stepsTransitionStatus]);

  // Update la step si workflowData est mis à jours
  useEffect(() => {
    setSelectedStep(steps[currentStepIndex]);
    setValueTabIndex(currentStepIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  return {
    steps,
    selectedStep,
    stepsTransitionStatus,
    stepChanged,
    valueTabIndex,
    isReadOnly,
  };
};

export default useData;
