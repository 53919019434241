import { useTheme } from '@mui/material';
import useWindowSize from './useWindowSize';

const useIsDesktop = (): boolean => {
  const {
    breakpoints: {
      values: { xl },
    },
  } = useTheme();
  const { width } = useWindowSize();
  return !!width && width >= xl;
};
export default useIsDesktop;
