import React from 'react';
import { observer } from 'mobx-react';
import { Dialog } from '@mui/material';
import { DialogChoiceProps } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';
import { DialogChoiceName } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/const';
import DialogChoiceActions from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/DialogChoiceItems/DialogChoiceActions';
import DialogChoiceContent from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/DialogChoiceItems/DialogChoiceContent';
import DialogChoiceOptions from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/DialogChoiceItems/DialogChoiceOptions';
import DialogChoiceTitle from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/DialogChoiceItems/DialogChoiceTitle';
import DialogChoiceContentText from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/DialogChoiceItems/DialogChoiceContentText';

const DialogChoice = ({ onClose, open, name, children, sx }: DialogChoiceProps) => {
  const id = `${DialogChoiceName}-${name}`;

  return (
    <Dialog open={open} onClose={onClose} data-cy={`${id}-dialog`} sx={sx}>
      {children}
    </Dialog>
  );
};

DialogChoice.Actions = DialogChoiceActions;

DialogChoice.Content = DialogChoiceContent;

DialogChoice.ContentText = DialogChoiceContentText;

DialogChoice.Option = DialogChoiceOptions;

DialogChoice.Title = DialogChoiceTitle;

export default observer(DialogChoice);
