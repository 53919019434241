import React from 'react';
import { observer } from 'mobx-react';
import { DialogTitle } from '@mui/material';
import { DialogChoiceTitleProps } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';

const DialogChoiceTitle = ({ name, children }: DialogChoiceTitleProps) => (
  <DialogTitle data-cy={`${name}-dialog-title`}>{children}</DialogTitle>
);

export default observer(DialogChoiceTitle);
