import { OperatorResult, UserParameterType } from '90.quickConnect.Models/enums';
import {
  BaseQCOperator,
  NamedUserParameterValue,
  AllFieldValueTypes,
  CtrlRefUserParemeterOperator,
} from '90.quickConnect.Models/models';

const getCtrlUserParameterRef = (
  baseQCOperator: BaseQCOperator,
  userParams: NamedUserParameterValue[],
): [AllFieldValueTypes, OperatorResult] => {
  const ctrlRefUserParemeterOperator = baseQCOperator as CtrlRefUserParemeterOperator;
  const param = userParams.find(
    (up) => up.userParameterId.toUpperCase() === ctrlRefUserParemeterOperator.parameterId.toUpperCase(),
  );
  if (param) {
    const { value, valueDecimal, valueInt, userParameterType } = param;
    switch (userParameterType) {
      case UserParameterType.Boolean:
        return [value, OperatorResult.BOOLEAN];
      case UserParameterType.Decimal:
        return [valueDecimal, OperatorResult.DOUBLE];
      case UserParameterType.Int:
        return [valueInt, OperatorResult.INT];
      case UserParameterType.SharedList:
        return [value, OperatorResult.LISTCHOICE];
      case UserParameterType.None:
      case UserParameterType.Text:
      default:
        return [value, OperatorResult.STRING];
    }
  }
  return [undefined, OperatorResult.ERROR];
};

export default getCtrlUserParameterRef;
