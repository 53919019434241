import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { DeclarationContainerProps } from './types';
import { DeclarationContainerName } from './const';
import Commands from './Commands';
import useData from './hooks';
import FormOrWorkflow from '20.formLib/DeclarationContainer/FormOrWorkflow';
import AllResolvers from '20.formLib/DeclarationContainer/AllResolvers';
import { QCSFormEventProvider } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';
import { QCSFunctionsProvider, QCUpdateFunctionsProvider } from '20.formLib/DeclarationContainer/contexts';

const DeclarationContainer = ({
  context,
  entityData,
  declaration,
  flattenFields,
  declarationData,
  setDeclarationData,
  setDeclaration,
  activityId,
  inboxId,
  declarationId,
  isEditable,
}: DeclarationContainerProps) => {
  const { formType, qcScriptObj, isDraft } = context;
  const {
    setCanSubmit,
    canSubmit,
    updateDeclaration,
    updateFieldVisibility,
    updateFieldErrors,
    forceFieldUpdate,
    updateItemsForThisField,
    steps,
    qcsFormEvent,
    qcsFunctions,
    updateProperty,
  } = useData(context, setDeclaration, declaration, formType, isDraft, isEditable, qcScriptObj);

  return (
    <QCSFunctionsProvider value={qcsFunctions}>
      <QCSFormEventProvider value={qcsFormEvent}>
        <QCUpdateFunctionsProvider value={{ updateProperty, updateDeclaration }}>
          <Box data-cy={DeclarationContainerName}>
            <AllResolvers
              flattenFields={flattenFields}
              updateFieldVisibility={updateFieldVisibility}
              updateDeclaration={updateDeclaration}
              updateFieldErrors={updateFieldErrors}
              setCanSubmit={setCanSubmit}
              forceFieldUpdate={forceFieldUpdate}
              formType={formType}
            >
              <FormOrWorkflow
                formType={formType}
                declaration={[...declaration]}
                updateDeclaration={updateDeclaration}
                updateItemsForThisField={updateItemsForThisField}
                setCanSubmit={setCanSubmit}
                flattenFields={flattenFields}
                declarationData={declarationData}
                setDeclaration={setDeclaration}
                steps={steps}
                isEditable={isEditable}
                context={context}
                updateFieldErrors={updateFieldErrors}
              />
            </AllResolvers>
            <Commands
              flattenFields={[...flattenFields]}
              updateDeclaration={updateDeclaration}
              context={context}
              declaration={declaration}
              canSubmit={canSubmit}
              declarationData={declarationData}
              setDeclarationData={setDeclarationData}
              steps={steps}
              activityId={activityId}
              inboxId={inboxId}
              declarationId={declarationId}
              isEditable={isEditable}
            />
          </Box>
        </QCUpdateFunctionsProvider>
      </QCSFormEventProvider>
    </QCSFunctionsProvider>
  );
};

export default observer(DeclarationContainer);
