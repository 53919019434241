import { useState, useCallback } from 'react';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    DeclarationStore: { LeftMenuDraftsStore, LeftMenuHistoryStore },
    FormStore: { LeftMenuStore },
  } = useStore();

  const { setFilter: setDraftFilter, filter: draftFilter } = LeftMenuDraftsStore;
  const { setFilter: setDeclarationFilter, filter: declarationFilter } = LeftMenuHistoryStore;
  const { setFilter: setFormFilter, filter: formFilter } = LeftMenuStore;

  // On définit le state
  const [filterInputValue, setFilterInputValue] = useState<string>(draftFilter ?? declarationFilter ?? formFilter);

  const applyFilter = useCallback((): void => {
    setDeclarationFilter(filterInputValue);
    setFormFilter(filterInputValue);
    setDraftFilter(filterInputValue);
  }, [filterInputValue, setDeclarationFilter, setDraftFilter, setFormFilter]);

  const clearFilter = useCallback((): void => {
    setDeclarationFilter('');
    setFormFilter('');
    setFilterInputValue('');
  }, [setDeclarationFilter, setFormFilter]);

  return {
    LeftMenuDraftsStore,
    LeftMenuHistoryStore,
    LeftMenuStore,
    filterInputValue,
    filter: declarationFilter ?? formFilter,
    setFilterInputValue,
    applyFilter,
    clearFilter,
  };
};
