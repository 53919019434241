import React from 'react';
import { observer } from 'mobx-react';
import { List, Button, useTheme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import DialogChoice from '10.quickConnect.app/components/shared/Dialogs/DialogChoice';
import useData from '10.quickConnect.app/components/shared/Dialogs/BackupDeclarationDialog/hooks';
import { choiceDialogName } from '10.quickConnect.app/components/shared/Dialogs/BackupDeclarationDialog/const';

const BackupDeclarationDialog = () => {
  const theme = useTheme();

  const {
    onClose,
    onContinueDeclaration,
    onDeleteDeclaration,
    t,
    backupDclContext,
    messageInfo,
    openModal,
    onSaveAsDraftDeclaration,
  } = useData();

  return (
    <DialogChoice name={choiceDialogName} onClose={onClose} open={openModal}>
      <DialogChoice.Title name={choiceDialogName}>
        <Typography sx={{ fontSize: '1.2em' }}>{t('qcapp_alert_dialog_save_form_title').toString()}</Typography>
      </DialogChoice.Title>
      <DialogChoice.Content name={choiceDialogName}>
        <DialogChoice.ContentText name={choiceDialogName}>{messageInfo}</DialogChoice.ContentText>
        <List>
          {backupDclContext.length === 1 && (
            <DialogChoice.Option
              handleClick={onContinueDeclaration}
              name={`${choiceDialogName}-dialog-option-continue-dcl`}
              optionTitle={t('qcapp_get_form_type_info')}
              avatar={<EditIcon />}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
          )}
          <DialogChoice.Option
            handleClick={onSaveAsDraftDeclaration}
            name={`${choiceDialogName}-dialog-option-save-draft-dcl`}
            optionTitle={
              backupDclContext.length > 1
                ? t('qcapp_save_draft_form_type_info_other')
                : t('qcapp_save_draft_form_type_info_one')
            }
            avatar={<SaveIcon fontSize="inherit" />}
            sx={{ backgroundColor: theme.palette.primary.main }}
          />
          <DialogChoice.Option
            handleClick={onDeleteDeclaration}
            name={`${choiceDialogName}-dialog-option-delete-dcl`}
            optionTitle={
              backupDclContext.length > 1 ? t('qcapp_delete_form_type_info_other') : t('qcapp_delete_form_type_info')
            }
            avatar={<DeleteIcon />}
            sx={{ backgroundColor: theme.palette.primary.main }}
          />
        </List>
      </DialogChoice.Content>
      <DialogChoice.Actions name={choiceDialogName}>
        <Button data-cy={`${choiceDialogName}-close`} onClick={onClose} variant="contained">
          {t('qcapp_dialog_item_data_close').toString()}{' '}
        </Button>
      </DialogChoice.Actions>
    </DialogChoice>
  );
};

export default observer(BackupDeclarationDialog);
