import React, { FunctionComponent, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export type ControlledTextFieldProps = Omit<TextFieldProps, 'onBlur'> & {
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => boolean;
};

const ControlledTextField: FunctionComponent<ControlledTextFieldProps> = (props: ControlledTextFieldProps) => {
  const [userValue, setUserValue] = useState(props.defaultValue);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setUserValue((userValueLoc: never) => (userValueLoc !== props.value ? props.value : userValueLoc));
  }, [props.value]);

  return (
    <TextField
      onChange={(e) => setUserValue(e.target.value)}
      {...props}
      error={props.error || error}
      value={userValue}
      onBlur={(e) => {
        let didChange = false;
        if (props.onBlur) {
          didChange = props.onBlur(e);
          setError(false);
        }
        if (!didChange) {
          setUserValue(props.value);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      }}
    />
  );
};

export default ControlledTextField;
