import React from 'react';
import { observer } from 'mobx-react';
import { Dialog, Box, Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalStateProps } from './types';
import { useData } from './hooks';
import { ModalStateBoxContainerStyles } from './styles';
import { ModalStateName } from './const';
import {
  DialogActionsStyle,
  DialogCancelButtonCustom,
  DialogCancelButtonStyle,
  DialogContentStyle,
  DialogStyle,
  DialogValidateButtonStyle,
} from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/styles';

function ModalState({ children, field, handleCancelButtonClick, handleValidateButtonClick }: ModalStateProps) {
  const { isDrawerOpened, handleCancelClick, handleValidateClick } = useData(
    field,
    handleCancelButtonClick,
    handleValidateButtonClick,
  );

  const { t } = useTranslation('declaration');
  const { palette, breakpoints } = useTheme();

  const nameWithId = `${ModalStateName}-${field.id}`;

  return (
    <Box sx={DialogStyle(breakpoints, palette)}>
      <Box sx={ModalStateBoxContainerStyles}>
        <Box sx={DialogContentStyle(breakpoints, isDrawerOpened)}>{children}</Box>
      </Box>
      <Box sx={{ ...DialogActionsStyle(breakpoints, palette), zIndex: 999 }}>
        <DialogCancelButtonCustom
          variant="contained"
          data-cy={`${nameWithId}-cancel`}
          color="secondary"
          sx={DialogCancelButtonStyle(breakpoints, palette)}
          onClick={handleCancelClick}
        >
          {t('qcapp_dialog_action_cancel')}
        </DialogCancelButtonCustom>
        <Button
          variant="contained"
          data-cy={`${nameWithId}-submit`}
          sx={DialogValidateButtonStyle(breakpoints, palette)}
          onClick={handleValidateClick}
        >
          {t('qcapp_dialog_action_validate')}
        </Button>
      </Box>
    </Box>
  );
}

export default observer(ModalState);
