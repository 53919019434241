import mapEntity from '../mapEntityBase';
import { FormType } from '90.quickConnect.Models/enums';
import { Form } from '90.quickConnect.Models/models';

const mapForm = (form: Form): Form =>
  ({
    ...mapEntity(form),
    name: form.name ?? '',
    description: form.description ?? '',
    formType: form.formType ?? FormType.Unknow,
    folderPath: form.folderPath ?? '',
    ownerId: form.ownerId ?? '',
    appVersion: form.appVersion ?? 0,
    qcLibVersion: form.qcLibVersion ?? 0,
    parentId: form.parentId ?? '',
    parentName: form.parentName ?? '',
    entitySchemaId: form.entitySchemaId ?? undefined,
    script: form.script ?? null,
    stringExtract: form.stringExtract ?? null,
    retentionPeriod: form.retentionPeriod ?? 0,
    defaultLanguage: form.defaultLanguage ?? 'fr',
    currentLanguage: form.defaultLanguage ?? 'fr',
    body: form.body ?? '',
  } as Form);

export default mapForm;
