import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import DeclarationLineMenuOnTap from './DeclarationLineMenuOnTap';
import useData from './hooks';
import { DeclarationLineName } from './const';
import { getDeclarationIcon } from '10.quickConnect.app/components/domain/Home/helpers';
import {
  CustomListItemText,
  getAvatarStyle,
  getListItemButtonStyle,
  getListItemAvatarStyle,
  getAvatarDeclarationStyle,
} from '10.quickConnect.app/components/domain/Home/styles';
import { DeclarationViewer } from '90.quickConnect.Models/models';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';

const DeclarationLine = (declaration: TreeDataItem) => {
  const { t } = useTranslation('declaration');
  const { openDeclaration, openContextMenu, contextMenu, contextMenuClose, openInNewTab, removeLocal } =
    useData(declaration);
  const { id, formType, name, editedAt, isDraft, isLocal, stringExtract } = declaration as DeclarationViewer;

  const theme = useTheme();

  return isDraft ? (
    <ListItem data-cy={DeclarationLineName} disablePadding>
      <ListItemButton
        sx={getListItemButtonStyle(theme)}
        data-cy={`${DeclarationLineName}-${id}`}
        onContextMenu={openContextMenu}
        onClick={() => openDeclaration()}
      >
        <ListItemAvatar sx={getListItemAvatarStyle(theme)}>
          <Avatar sx={getAvatarDeclarationStyle(theme)}>{getDeclarationIcon(formType, isDraft, isLocal)}</Avatar>
        </ListItemAvatar>
        <CustomListItemText primary={stringExtract} secondary={editedAt.toLocaleString()} />
      </ListItemButton>
      <Menu
        open={contextMenu !== null}
        onClose={contextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={openInNewTab}>{t('contextual_menu_open_in_new_tab')}</MenuItem>

        {isLocal && <MenuItem onClick={removeLocal}>{t('contextual_menu_remove_local')}</MenuItem>}
      </Menu>
    </ListItem>
  ) : (
    <DeclarationLineMenuOnTap {...declaration} />
  );
};
export default observer(DeclarationLine);
