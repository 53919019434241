import mapDataSource from '90.quickConnect.Models/mappings/fields/mapDataSource';
import { NotificationDesc, QCNotification } from '90.quickConnect.Models/models';
import { NotificationType } from '90.quickConnect.Models/enums';
import { mapToNotificationData } from '90.quickConnect.Models/mappings';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';

const mapNotificationDesc = ({
  dataSourceType,
  dataSource,
  defaultValue,
  showAutoSendToMe,
  autoSend,
  lruCount,
  notificationType,
  value,
}: NotificationDesc): NotificationDesc => {
  const parsedDefaultValue =
    defaultValue && typeof defaultValue === 'string' ? mapToNotificationData(defaultValue, autoSend) : undefined;
  return {
    dataSourceType: dataSourceType,
    dataSource: dataSource ? mapDataSource(dataSource) : undefined,
    defaultValue: defaultValue,
    showAutoSendToMe: showAutoSendToMe,
    autoSend: autoSend ?? true,
    lruCount: lruCount ?? undefined,
    notificationType: notificationType ?? NotificationType.Email,
    value: isQCNotification(value) ? value : isQCNotification(parsedDefaultValue) ? parsedDefaultValue : undefined,
  } as NotificationDesc;
};

export default mapNotificationDesc;
