import React from 'react';
import { observer } from 'mobx-react';
import { Box, Tabs } from '@mui/material';
import { WorkflowProps } from './types';
import useData from './hooks';
import { WorkflowName } from './const';
import WorkflowTabHeader from './WorkflowTabHeader';
import Fields from '50.quickConnect.Fields/Fields';
import { WorkflowStepStatus } from '90.quickConnect.Models/enums';

const Workflow: React.VFC<WorkflowProps> = ({
  updateDeclaration,
  setCanSubmit,
  flattenFields,
  historicalStepDataList,
  currentStep,
  setDeclaration,
  steps,
  isEditable,
  context,
  updateFieldErrors,
  updateItemsForThisField,
}: WorkflowProps) => {
  const { selectedStep, stepsTransitionStatus, stepChanged, valueTabIndex, isReadOnly } = useData(
    historicalStepDataList,
    currentStep,
    steps,
    isEditable,
  );
  return (
    <Box data-cy={WorkflowName}>
      <Tabs variant="scrollable" scrollButtons="auto" value={valueTabIndex} sx={{ width: '50%', m: '0 auto' }}>
        {steps.map((step) => (
          <WorkflowTabHeader
            key={step.id}
            step={step}
            transitionStatus={stepsTransitionStatus.get(step.id) ?? WorkflowStepStatus.None}
            stepChanged={stepChanged}
          />
        ))}
      </Tabs>
      {selectedStep && (
        <Box>
          <Fields
            updateItemsForThisField={updateItemsForThisField}
            fieldsDescriptions={selectedStep.items ?? []}
            updateDeclaration={updateDeclaration}
            setCanSubmit={setCanSubmit}
            stepId={selectedStep.id}
            isReadOnly={isReadOnly}
            flattenFields={flattenFields}
            setDeclaration={setDeclaration}
            context={context}
            updateFieldErrors={updateFieldErrors}
          />
        </Box>
      )}
    </Box>
  );
};

export default observer(Workflow);
