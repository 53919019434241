import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { UseDataProps } from '10.quickConnect.app/components/shared/Dialogs/BackupDeclarationDialog/type';
import { useStore } from '30.quickConnect.Stores';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { NEW_DECLARATION } from '10.quickConnect.app/routes/paths';
import { LocalBackupDeclaration } from '90.quickConnect.Models/models';

import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { DateTimeFieldType } from '90.quickConnect.Models/enums';
import { errorHandler } from '80.quickConnect.Core/helpers';

const useData = (): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/shared/Dialogs/BackupDeclarationDialog/hooks.tsx';

  const navigate = useNavigate();
  const { t } = useTranslation('forms');

  const {
    DeclarationStore: { backupDclContext, deleteLocalBackups, saveAsDraftDeclarationAsync, setBackupDclContext },
    LoginStore: {
      signInInfos: { userUPN },
    },
  } = useStore();

  const onContinueDeclaration = useCallback(() => {
    if (backupDclContext.length === 0) return;

    const [backUpDcl] = backupDclContext;
    const { formId } = backUpDcl;

    setBackupDclContext([]);
    const link = NEW_DECLARATION.replace(':formId', formId);
    navigate(link);
  }, [backupDclContext, navigate, setBackupDclContext]);

  const onSaveAsDraftDeclaration = useCallback(async () => {
    try {
      await saveAsDraftDeclarationAsync();
    } catch (error: unknown) {
      errorHandler(tag, error, 'onSaveDraftDeclaration');
    }
  }, [saveAsDraftDeclarationAsync, tag]);

  const onDeleteDeclaration = useCallback(async () => {
    try {
      if (backupDclContext.length === 0) return;

      await deleteLocalBackups(userUPN);

      const msg: string =
        backupDclContext.length > 1
          ? t('qcapp_form_choice_dialog_success_other')
          : t('qcapp_form_choice_dialog_success_one').toString();

      toast.success(msg);
    } catch (error: unknown) {
      errorHandler(tag, error, 'onDeleteDeclaration');

      if (error instanceof Error) {
        toast.error(t('qcapp_form_choice_dialog_error').toString());
        setBackupDclContext([]);
      }
    }
  }, [backupDclContext, deleteLocalBackups, t, userUPN, setBackupDclContext, tag]);

  const messageInfo: JSX.Element | JSX.Element[] = useMemo(() => {
    switch (backupDclContext.length) {
      case 0:
        return <></>;

      case 1:
        return backupDclContext.map(({ id, stringExtract, editedAt }: LocalBackupDeclaration) => (
          <Typography key={id} variant="subtitle2" align="justify">
            {t('qcapp_back_up_form_type_info_one', {
              stringExtract,
              editedAt: DateTimeExtension.formatHumanReadeable(editedAt ?? new Date(), DateTimeFieldType.DateTime),
            }).toString()}
          </Typography>
        ));

      default:
        return (
          <>
            <Typography variant="subtitle2" align="justify" paragraph>
              {t('qcapp_back_up_form_type_info_other').toString()}
            </Typography>
            {backupDclContext.map(({ stringExtract, editedAt, id }: LocalBackupDeclaration) => (
              <Typography key={id} paragraph>
                •{' '}
                {t('qcapp_back_up_form_type_info_item', {
                  stringExtract,
                  editedAt: DateTimeExtension.formatHumanReadeable(editedAt ?? new Date(), DateTimeFieldType.DateTime),
                }).toString()}
              </Typography>
            ))}
          </>
        );
    }
  }, [backupDclContext, t]);

  const onClose = useCallback(() => {
    onSaveAsDraftDeclaration();
  }, [onSaveAsDraftDeclaration]);

  const openModal: boolean = useMemo(() => backupDclContext.length > 0, [backupDclContext]);

  return {
    onClose,
    onContinueDeclaration,
    onDeleteDeclaration,
    backupDclContext,
    messageInfo,
    openModal,
    onSaveAsDraftDeclaration,
    t,
  };
};

export default useData;
