import { SxProps } from '@mui/system';
import { FullHeightWithoutTopBar, OverflowForDesktop } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';

export const MainBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: FullHeightWithoutTopBar,
  overflowY: OverflowForDesktop,
  paddingTop: '10px',
  paddingBottom: '55px',
};
