import { FieldType, QcOperator, OperatorResult } from '90.quickConnect.Models/enums';
import { CtrlRefOperator, Dependency, AllFieldValueTypes, NumericDesc } from '90.quickConnect.Models/models';

export const evalComputedValue = (
  ctrlRefOperator: CtrlRefOperator,
  dependencies: Dependency[],
  scopeFullPathId: string,
): [AllFieldValueTypes, OperatorResult] => {
  // Scope
  // const parentId = scopeFullPathId.substring(0, scopeFullPathId.lastIndexOf('.'));
  // const currentScopeId = scopeFullPathId.substring(scopeFullPathId.lastIndexOf('.') + 1);

  // Dans le cas où on utilise le pattern avec '/'

  const stringToSearch = ctrlRefOperator.controlId.replace('*', '').replace('/', '');
  const patternToSearchInScope = stringToSearch.substring(stringToSearch.lastIndexOf('.') + 1);

  const patternRelated = dependencies.filter((d) =>
    d.id.toUpperCase().startsWith(patternToSearchInScope.toUpperCase()),
  );
  const valuesToCompute = patternRelated
    .filter(
      (f) => f.fieldType === FieldType.Numeric || f.fieldType === FieldType.Digits || f.fieldType === FieldType.Compute,
    )
    .map((f) => f as NumericDesc)
    .map((f) => ({ val: +f.value! as number, type: f.fieldType }))
    .filter((v) => !isNaN(v.val));
  const sum = valuesToCompute.reduce((partialSum, a) => partialSum + a.val!, 0);
  const operatorResult: OperatorResult = valuesToCompute.some(
    (obj: { val: number; type: FieldType }) => obj.type === FieldType.Numeric || !Number.isInteger(obj.val),
  )
    ? OperatorResult.DOUBLE
    : OperatorResult.INT;

  switch (ctrlRefOperator.type) {
    case QcOperator.Sum:
      // return [sum, OperatorResult.DOUBLE];
      return valuesToCompute.length > 0 ? [sum, operatorResult] : ['', operatorResult];
    case QcOperator.Avg:
      return [sum / valuesToCompute.length || 0, OperatorResult.DOUBLE];
    default:
      return [undefined, OperatorResult.ERROR];
  }
};
