import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { ConfirmDialogProps } from './types';
import { confirmDialogName } from './const';
import { CancelButtonStyle } from './styles';

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  close,
  actionForLabelOne,
  actionForLabelTwo,
  actionLabelOne,
  actionLabelTwo,
  title,
  subtitle,
  children,
  sendingData,
  cancelLabel,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={close} data-cy={confirmDialogName}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{subtitle}</DialogContentText>
      </DialogContent>
      {children}
      <DialogActions sx={{ display: 'flex', justifyContent: `${cancelLabel ? '' : 'center'}` }}>
        {cancelLabel && (
          <Button
            onClick={close}
            data-cy={`${confirmDialogName}-cancel`}
            color="error"
            variant="contained"
            sx={CancelButtonStyle}
          >
            {cancelLabel}
          </Button>
        )}
        {actionLabelTwo && actionForLabelTwo && (
          <Button
            onClick={actionForLabelTwo}
            data-cy={`${confirmDialogName}-exit`}
            variant="contained"
            color={cancelLabel ? 'primary' : 'error'}
            disabled={sendingData}
            startIcon={sendingData ? <CircularProgress size="20px" /> : ''}
          >
            {actionLabelTwo || 'Abandonner'}
          </Button>
        )}
        <Button
          onClick={actionForLabelOne}
          data-cy={`${confirmDialogName}-confirm`}
          variant="contained"
          disabled={sendingData}
          startIcon={sendingData ? <CircularProgress size="20px" /> : ''}
        >
          {actionLabelOne || 'Valider'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
