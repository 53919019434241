import React from 'react';

const ExternalEditRegularSvg = (color: string) => {
  return (
    <svg width="24" height="24" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        // eslint-disable-next-line max-len
        d="M402.3 344.9l32 -32c5 -5 13.7 -1.5 13.7 5.7L448 464c0 26.5 -21.5 48 -48 48L48 512c-26.5 0 -48 -21.5 -48 -48L0 112c0 -26.5 21.5 -48 48 -48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5 -3.5 2.3 -5.7 2.3L48 112v352h352L400 350.5c0 -2.1 0.8 -4.1 2.3 -5.6zM558.9 143.1L296.3 405.7l-90.4 10c-26.2 2.9 -48.5 -19.2 -45.6 -45.6l10 -90.4L432.9 17.1c22.9 -22.9 59.9 -22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 0.1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3 -7.3L460.1 174zM524.9 94.3l-43.2 -43.2c-4.1 -4.1 -10.8 -4.1 -14.8 0L436 82l58.1 58.1 30.9 -30.9c4 -4.2 4 -10.8 -0.1 -14.9z"
      />
    </svg>
  );
};

export default ExternalEditRegularSvg;
