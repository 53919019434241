import { SxProps, Theme } from '@mui/material';

export const iconsLogoStyle = (theme: Theme): SxProps => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '10vw',
  },
  [theme.breakpoints.down('md')]: {
    width: '7vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '20vw',
  },
  maxWidth: '110px',
  height: '75px',
  fontSize: '20px',
  cursor: 'pointer',
});
