import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { ListItemButton, ListItemIcon, Checkbox, ListItemText, Radio } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { HierarchicalItemProps } from './types';
import { HierarchicalItemName } from './const';
import { HierarchicalChoice } from '90.quickConnect.Models/models';

const HierarchicalItem = ({
  onClickHandle,
  hierarchicalChoice,
  localValue,
  parentId,
  multiSelection,
}: HierarchicalItemProps) => {
  const nameWithId = `${HierarchicalItemName}-${parentId}`;

  const { children, hierarchicalLabel } = hierarchicalChoice;

  const showCheckbox = useMemo(() => {
    return multiSelection && children.length === 0 && localValue;
  }, [children.length, localValue, multiSelection]);

  const showRadio = useMemo(() => {
    return !multiSelection && children.length === 0 && localValue;
  }, [children.length, localValue, multiSelection]);

  // const isSelected = useMemo(() => {
  //   return showCheckbox || showRadio ? localValue!.indexOf(hierarchicalChoice) > -1 : false;
  // }, [hierarchicalChoice, localValue, showCheckbox, showRadio]);

  const isSelected = useMemo(() => {
    return Array.isArray(localValue) && (showCheckbox || showRadio)
      ? localValue!.findIndex(
          (hierarchicalChoiceFromLocalValue: HierarchicalChoice) =>
            hierarchicalChoice.label === hierarchicalChoiceFromLocalValue.label &&
            hierarchicalChoice.value === hierarchicalChoiceFromLocalValue.value,
        ) > -1
      : false;
  }, [hierarchicalChoice, localValue, showCheckbox, showRadio]);

  const showArrow = useMemo(() => {
    return children.length > 0;
  }, [children.length]);

  return (
    <ListItemButton
      data-cy={`${nameWithId}-${hierarchicalLabel}`}
      role={undefined}
      onClick={onClickHandle(hierarchicalChoice)}
      dense
    >
      {showCheckbox && (
        <ListItemIcon>
          <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple />
        </ListItemIcon>
      )}
      {showRadio && (
        <ListItemIcon>
          <Radio edge="start" checked={isSelected} tabIndex={-1} disableRipple />
        </ListItemIcon>
      )}
      <ListItemText primary={hierarchicalChoice.hierarchicalLabel} />
      {showArrow && (
        <ListItemIcon>
          <ArrowForwardIosIcon />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};
export default observer(HierarchicalItem);
