import { BooleanExtension } from '80.quickConnect.Core/formatting/BooleanExtension';
import { isActionData } from '90.quickConnect.Models/guards';

export default function mapAllFieldTypeValuesToBoolean(nextValue: unknown): boolean | undefined {
  let result: boolean | undefined;

  if (nextValue === null) result = false;
  else if (typeof nextValue === 'boolean') result = nextValue;
  else if (typeof nextValue === 'number' || typeof nextValue === 'bigint') result = nextValue !== 0;
  else if (typeof nextValue === 'string') result = BooleanExtension.convertFromString(nextValue);
  else if (isActionData(nextValue)) {
    const [firstAction] = nextValue;
    const { value: qcActionValueData } = firstAction;

    result = mapAllFieldTypeValuesToBoolean(qcActionValueData);
  }

  return result;
}
