import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { FieldType } from '90.quickConnect.Models/enums';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';

export const getReferencedNumericValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const numericValue = isNumeric(valueReferenceDependency) ? +valueReferenceDependency : undefined;

  if (!numericValue) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
    case FieldType.Separator:
      return numericLocaleFormatLitteral(numericValue);

    case FieldType.CheckBox:
      return numericValue > 0 ? 1 : 0;

    case FieldType.Counter:
    case FieldType.Digits:
      return Math.trunc(numericValue);

    case FieldType.Slider:
    case FieldType.Numeric:
      return numericValue;

    default:
      return undefined;
  }
};
