import { SxProps } from '@mui/material';

export const ModalStateBoxContainerStyles: SxProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export const ModalStateBoxActionButtonsStyles: SxProps = {
  width: '100%',
  position: 'absolute',
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'flex-end',
  padding: '2em',
  gap: '2em',
};
