/**
 * Représente les type d'attribut possible
 */
export enum EntitySchemaAttributeTypes {
  /**
   * Valeur de type booléenne
   */
  Bool = 1,
  /**
   * Valeur de type numérique entière
   */
  Int = 2,
  /**
   * Valeur de type numérique décimale
   */
  Decimal = 3,
  /**
   * Valeur de type 'Date et heure'
   */
  DateTime = 4,
  /**
   * Champ de type 'Chaîne de caractères'
   */
  String = 5,
  /**
   * Champ de type 'Liste partagée'
   */
  SharedList = 6,
  /**
   * Valeur de type 'Date'
   */
  Date = 7,
  /**
   * Champ de type 'Liste Partagée' hiérarchique
   */
  SharedListHierarchical = 8,
  /**
   * Champ de type 'Liste Partagée' référentiel
   */
  // En attente !!
  // SharedListRepository = 9,

  /**
   * Champ de type texte contenant un email
   */
  Email = 10,
}
