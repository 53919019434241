import { errorHandler } from '80.quickConnect.Core/helpers';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

export default class NumberExtension extends Number {
  protected static TAG = '80.quickConnect.Core/formatting/NumberExtesion.ts';

  protected isDecimal = false;

  constructor(num: unknown) {
    super(num);

    if (Number.isNaN(num))
      errorHandler(NumberExtension.TAG, new Error(`Impossible de convertir en nombre le param: ${num}`), 'constructor');
  }

  public static createDoubleValue(value: unknown): number {
    if (value === null || value === undefined || value === '') return NaN;

    return parseNumber(value) ?? NaN;
  }

  public static createIntegerValue(value: unknown): number {
    const dbleValue = NumberExtension.createDoubleValue(value);

    if (Number.isInteger(dbleValue)) return dbleValue;

    return Math.floor(dbleValue);
  }

  public static convertIntegerToString(params: AllFieldValueTypes, defaultValue: string): string {
    if (typeof params !== 'number' || Number.isNaN(params)) return defaultValue;

    return params.toFixed(0);
  }

  public static convertDoubleToString(params: AllFieldValueTypes, defaultValue: string): string {
    if (typeof params !== 'number' || Number.isNaN(params)) return defaultValue;

    return params.toLocaleString(undefined, { minimumFractionDigits: 1 });
  }

  public static convertBigIntToString(params: AllFieldValueTypes, defaultValue: string): string {
    if (typeof params !== 'bigint') return defaultValue;

    return params.toString();
  }

  public static isAnLitteralNumber(input: string): boolean {
    return !Number.isNaN(Number(input));
  }

  public static isAValidNumber(num: unknown): boolean {
    return typeof num === 'number' && !Number.isNaN(num);
  }

  public static isAValidInteger(num: unknown): boolean {
    return typeof num === 'number' && !Number.isNaN(num) && Number.isInteger(num);
  }

  public static getValueInRange(val: number, min?: number, max?: number): number {
    if (min !== undefined && max !== undefined) {
      if (min > max) {
        NumberExtension._swapMinMax(min, max);
      }
      if (val < min) return min;
      else if (val > max) return max;
      else return val;
    } else if (min !== undefined) {
      return val < min ? min : val;
    } else if (max !== undefined) {
      return val > max ? max : val;
    } else {
      return val;
    }
  }

  private static _swapMinMax(min: number, max: number) {
    const tmp = min;
    min = max;
    max = min;
  }
}
