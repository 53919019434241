import { QCErrorHTTPCodeEnum } from '90.quickConnect.Models/enums';
import { mapAxiosQCErrorHTTP } from '90.quickConnect.Models/mappings';
import { IQCErrorHTTP } from '90.quickConnect.Models/models/errors/contract/IQCErrorHTTP';

export class QCErrorHTTP implements IQCErrorHTTP {
  constructor(
    public code: QCErrorHTTPCodeEnum,
    public status: number,
    public message: string,
    public config: unknown,
    public timeout: boolean,
  ) {}

  public isTimeout(): boolean {
    return this.timeout;
  }

  public getMessage(): string | undefined {
    return this.message;
  }

  public getStatus(): number | undefined {
    return this.status;
  }

  public getCode(): QCErrorHTTPCodeEnum {
    return this.code;
  }

  public getUrl(): string {
    if (!this.config) return '';

    if (typeof this.config !== 'object') return '';

    if (!('url' in this.config)) return '';

    if (typeof this.config.url !== 'string') return '';

    return this.config.url;
  }

  public static isHttpError<T extends Error>(error: unknown): error is T {
    return mapAxiosQCErrorHTTP.isAxiosError<T>(error);
  }
}
