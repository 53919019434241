import { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ForcedTabIdKey, TabIdKey } from './const';

const useTabId = (): void => {
  const id = useMemo(() => {
    const forcedId = sessionStorage.getItem(ForcedTabIdKey);
    if (forcedId) {
      // On supprime l'item du session Storage
      sessionStorage.removeItem(ForcedTabIdKey);
      return forcedId;
    }
    return uuidv4();
  }, []);

  // Permet définir un id pour identifier la table courante et de le garder même en cas de refresh
  useEffect(() => {
    if (typeof Storage !== 'undefined') {
      sessionStorage.setItem(TabIdKey, id);
    }
  }, [id]);
};

export default useTabId;
