import { useCallback } from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

const useData = (onDrop: (item: { files: any[] }, parentId: string) => void, nameWithParentId: string) => {
  // Tag
  const tag = '50.quickConnect.Fields/FieldsTypes/Attachments/AttachmentsList/hooks.ts';

  const logger = CustomLogger.getInstance();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.currentTarget;
      const arrayOfFiles: any[] = [];
      for (const file of files ?? []) {
        arrayOfFiles.push(file);
      }
      onDrop({ files: arrayOfFiles }, nameWithParentId);
      event.target.value = '';
    },
    [onDrop, nameWithParentId],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        logger.log(tag, `'drop', ${item.files}`);
        onDrop(item, nameWithParentId);
      },
      canDrop(item: any) {
        logger.log(tag, `'canDrop', ${item.files}, ${item.items}`);
        return true;
      },
      hover(item: any) {
        logger.log(tag, `'hover', ${item.files}, ${item.items}`);
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop, logger, nameWithParentId],
  );

  return {
    handleChange,
    drop,
  };
};

export default useData;
