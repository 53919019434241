export const FEATURE_001 = 'FEAT-001';
export const FEATURE_002 = 'FEAT-002';
export const FEATURE_003 = 'FEAT-003';
export const FEATURE_004 = 'FEAT-004';
export const FEATURE_005 = 'FEAT-005';
export const FEATURE_006 = 'FEAT-006';
export const FEATURE_007 = 'FEAT-007';
export const FEATURE_008 = 'FEAT-008';
export const FEATURE_009 = 'FEAT-009';
export const FEATURE_010 = 'FEAT-010';
export const FEATURE_011 = 'FEAT-011';
