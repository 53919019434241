import { useLocation } from 'react-router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { FormViewer } from '90.quickConnect.Models/models';

const useData = (): UseDataProps => {
  // On récupère le store
  const {
    UserSettingsStore: { formsAsTree },
    FormStore: { MainMenuStore, getFormsList },
    CommonStore: { setWorkInProgress, setWorkDone },
    DeclarationStore: { setCloseConsentPopup },
    LoginStore: {
      signInInfos: { userUPN },
      currentOuCode,
    },
  } = useStore();

  const location = useLocation();
  const { items: forms, filterThis: filterThisForms, filter } = MainMenuStore;

  const { t } = useTranslation('forms');

  const getFormsJob = useMemo(() => [uuidv4(), t('jobMessage_getFormsJob')] as [string, string], [t]);
  const [filteredForms, setFilteredForms] = useState<FormViewer[]>([]);

  const souldGetList = useRef(true);
  const lastSetOuCode = useRef<string | undefined>(undefined);

  const getDeclarationsJob = useMemo(
    () => [uuidv4(), t('declaration:jobMessage.getDeclarationsJob')] as [string, string],
    [t],
  );
  useEffect(() => {
    setCloseConsentPopup();
    if (souldGetList.current === true || lastSetOuCode.current !== currentOuCode) {
      souldGetList.current = false;
      // on appelle getformviewer que dans l'ecran de la liste des formulaires
      if (location.pathname === '/forms') {
        setWorkInProgress(getFormsJob);
        getFormsList().finally(() => {
          setWorkDone(getFormsJob[0]);
          lastSetOuCode.current = currentOuCode;
        });
      }
    }
  }, [
    currentOuCode,
    getDeclarationsJob,
    getFormsJob,
    getFormsList,
    location.pathname,
    setCloseConsentPopup,
    setWorkDone,
    setWorkInProgress,
    userUPN,
  ]);

  useEffect(() => {
    const newFilteredForms = filterThisForms(forms ?? [], (f: FormViewer) => f.name);
    setFilteredForms(newFilteredForms);
    // Ne pas enlever filter de ce tableau de dépendance !
  }, [filter, filterThisForms, forms]);

  return { formsAsTree, filteredForms, MainMenuStore };
};

export default useData;
