import { EntityInstanceSearch } from '90.quickConnect.Models/models';

const mapEntityInstanceSearch = ({
  id,
  createdAt,
  updatedAt,
  customerId,
  instanceId,
  schemaId,
  correlationId,
  title,
  code,
  attributeId,
  attributeName,
  value,
}: EntityInstanceSearch): EntityInstanceSearch =>
  ({
    id,
    createdAt: createdAt ? new Date(createdAt) : new Date(),
    updatedAt: updatedAt ? new Date(updatedAt) : new Date(),
    customerId,
    instanceId,
    schemaId,
    correlationId,
    title,
    code,
    attributeId,
    attributeName,
    value,
  } as EntityInstanceSearch);

export default mapEntityInstanceSearch;
