import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { ActionConditionType, UseDataProps } from './types';
import { debounceTime } from '50.quickConnect.Fields/const';
import { AddressData, AddressDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { defaultAddress } from '90.quickConnect.Models/mappings';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  addressDesc: AddressDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, reference } = addressDesc;

  const {
    DeclarationStore: { startReverseGeocodingAsync },
  } = useStore();

  const [localValue, setLocalValue] = useState<AddressData>((value as AddressData) ?? defaultAddress());
  const [reverseGeoCodingLoading, setReverseGeoCodingLoading] = useState<boolean>(false);

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const updateState = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, valueType: ActionConditionType) => {
      const newValue = event != undefined ? event.target.value : '';
      switch (valueType) {
        case ActionConditionType.StreetNumber:
          setLocalValue((prev) => ({
            ...prev,
            streetNumber: newValue,
          }));
          break;
        case ActionConditionType.Street:
          setLocalValue((prev) => ({
            ...prev,
            street: newValue,
          }));
          break;
        case ActionConditionType.Complement:
          setLocalValue((prev) => ({
            ...prev,
            complement: newValue,
          }));
          break;
        case ActionConditionType.ZipCode:
          setLocalValue((prev) => ({
            ...prev,
            zipCode: newValue,
          }));
          break;
        case ActionConditionType.City:
          setLocalValue((prev) => ({
            ...prev,
            city: newValue,
          }));
          break;
        case ActionConditionType.Country:
          setLocalValue((prev) => ({
            ...prev,
            country: newValue,
          }));
          break;
      }
      setTimeout(() => debouncedUpdateGlobalState(), 100);
    },
    [debouncedUpdateGlobalState],
  );

  const startReverseGeocoding = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setReverseGeoCodingLoading(true);
      const { coordinates } = value as AddressData;
      if (coordinates?.latitude && coordinates?.longitude) {
        const newAddressData = await startReverseGeocodingAsync(coordinates);
        if (newAddressData) {
          setLocalValue(newAddressData);
          debouncedUpdateGlobalState();
        }
      }

      setReverseGeoCodingLoading(false);
    },
    [startReverseGeocodingAsync, value, debouncedUpdateGlobalState],
  );

  // UseEffect pour le système des références
  useEffect(() => {
    setLocalValue((previousLocalValue) =>
      previousLocalValue !== value ? value ?? defaultAddress() : previousLocalValue,
    );
  }, [value]);

  // On retourne les valeurs à la vue
  return {
    reverseGeoCodingLoading,
    t,
    localValue,
    setLocalValue,
    updateState,
    startReverseGeocoding,
  };
};

export default useData;
