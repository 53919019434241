import { SortDirection } from '@mui/material';
import descendingComparator from './descendingComparator';

const getComparator = <Key extends keyof any>(
  order: SortDirection,
  orderBy: Key,
): ((
  a: { [key in Key]: number | Date | string | Blob | undefined },
  b: { [key in Key]: number | Date | string | Blob | undefined },
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export default getComparator;
