import { GeolocationData } from '90.quickConnect.Models/models';

export default function isCoordinates(value: unknown): value is GeolocationData {
  return (
    typeof value === 'object' &&
    value !== null &&
    'longitude' in value &&
    typeof value.longitude === 'number' &&
    'latitude' in value &&
    typeof value.latitude === 'number' &&
    (!('precision' in value) || ('precision' in value && typeof value.precision === 'number'))
  );
}
