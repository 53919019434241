import React from 'react';
import { observer } from 'mobx-react';
import { DialogActions } from '@mui/material';
import { DialogChoiceActionsProps } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';

const DialogChoiceActions = ({ name, children }: DialogChoiceActionsProps) => (
  <DialogActions data-cy={`${name}-dialog-content`}>{children}</DialogActions>
);

export default observer(DialogChoiceActions);
