import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { isNumeric } from '80.quickConnect.Core/helpers/common';

export const getReferencedDigitsValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const digitValue = isNumeric(valueReferenceDependency) ? +valueReferenceDependency : undefined;

  if (!digitValue) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
    case FieldType.Separator:
      return digitValue.toString();

    case FieldType.CheckBox:
      return digitValue > 0 ? 1 : 0;

    case FieldType.Counter:
    case FieldType.Digits:
      return digitValue;

    case FieldType.Slider:
      return digitValue > 5 ? roundTo(5, 1) : digitValue < 0 ? roundTo(0, 0) : roundTo(digitValue, 1);

    case FieldType.Numeric:
      return roundTo(digitValue, 1);

    default:
      return undefined;
  }
};
