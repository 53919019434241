import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { CommandType } from '../types';
import { UseDataProps } from './types';
import { ItemData, DeclarationContext, FieldDesc } from '90.quickConnect.Models/models';
import { FORMS } from '10.quickConnect.app/routes/paths';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';
import { useStore } from '30.quickConnect.Stores';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';

const useData = (
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>,
  sendDeclaration: (newItemData: ItemData) => Promise<boolean>,
  setDeclarationData: Dispatch<SetStateAction<ItemData>>,
  context: DeclarationContext,
  flattenFields: FieldDesc[],
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const qcsFormEvent = useQCSFormEvent();
  const {
    DeclarationStore: {
      isMaxAttachment,
      requiredField,
      scrollTarget,
      sendDeclarationToAPIAsync,
      setEditableDeclaration,
      setIsEditingCurrentDeclaration,
      setIsUseDeclarationComponent,
      removeBackUpDcl,
      resetStore: dclResetStore,
    },
    QCScriptStore: { resetStore: qcsResetStore, setFlattenFields },
    ConsentStore: { resetStore: consentResetStore },
  } = useStore();
  const navigate = useNavigate();
  const onLine = useNavigatorOnLine();

  const onPublish = useCallback(async () => {
    const { qcScriptObj } = context;
    const [goToNextStep, nextStepIndex, newItemData] = await handleItemDatas(CommandType.Send);

    let isValid = true;

    // Vérification qcScript
    if (qcScriptObj && qcScriptObj !== '' && qcsFormEvent) {
      const { onFormValidating, displayInvalidErrorMessage } = qcsFormEvent;
      setFlattenFields(flattenFields);
      const result = onFormValidating(flattenFields);

      if (typeof result === 'boolean') isValid = result;
      if (!isValid) {
        displayInvalidErrorMessage();
        return;
      }
    }

    if (!isMaxAttachment) {
      const declarationSend = await sendDeclaration(newItemData);
      if (declarationSend) {
        if (goToNextStep && nextStepIndex && newItemData && newItemData.Workflow) {
          newItemData.Workflow.currentStep = nextStepIndex;
          if (onLine) {
            sendDeclarationToAPIAsync();
          }
          setDeclarationData(newItemData);
        } else {
          if (newItemData.Workflow) {
            removeBackUpDcl(context.formId);
            setIsUseDeclarationComponent(false);
            setIsEditingCurrentDeclaration(false);
            setEditableDeclaration(false);
            dclResetStore();
            qcsResetStore();
            consentResetStore();
          }
          navigate(FORMS, { replace: true });
        }
      }
    } else {
      toast.warn(`${t('attachment_fields_errors_has_max_count_for_form')} (60)`);
    }
  }, [
    handleItemDatas,
    isMaxAttachment,
    sendDeclaration,
    navigate,
    setDeclarationData,
    context,
    flattenFields,
    qcsFormEvent,
    sendDeclarationToAPIAsync,
    t,
    onLine,
    setIsUseDeclarationComponent,
    setIsEditingCurrentDeclaration,
    setEditableDeclaration,
    dclResetStore,
    qcsResetStore,
    consentResetStore,
    setFlattenFields,
    removeBackUpDcl,
  ]);

  return {
    t,
    onPublish,
    requiredField,
    scrollTarget,
  };
};

export default useData;
