import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import { Form } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class FormsDb extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/formsDb.ts';

  forms!: Table<Form>;

  logger: CustomLogger;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('formsDb');
    this.version(1).stores({
      forms: 'id, folderPath',
    });

    this.logger = CustomLogger.getInstance();
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.forms.count();
    } catch (error: unknown) {
      errorHandler(FormsDb.TAG, error, 'countRecords');

      return 0;
    }
  };

  async addAllForms(forms: Form[], logger: CustomLogger) {
    try {
      await this.forms.bulkAdd(forms);
    } catch (error) {
      errorHandler(FormsDb.TAG, error, 'addAllForms');
    }
  }

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.forms.clear();
      logger.log(FormsDb.TAG, `all tables from ${this.name} have been cleared`);
    } catch (error) {
      errorHandler(FormsDb.TAG, error, 'clearAllTables');
    }
  }
}

const formsDb = new FormsDb();

export default formsDb;
