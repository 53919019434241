/**
 * Type attendu pour la visibilité d'un composant
 */
export enum ToolExpectedValueEnum {
  None = 0,
  Number = 1,
  Text = 2,
  Bool = 3,
  Date = 4,
  Object = 5,
  List = 6,
  QRCode = 7,
  Primitif = 8,
}
