import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab, useTheme } from '@mui/material';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { PreviousName } from './const';
import { PreviousCommandProps } from './types';
import useData from './hooks';

const PreviousCommand = ({ formType, handleItemDatas, sendDeclaration, setDeclarationData }: PreviousCommandProps) => {
  const { t, onPreviousWorkflow } = useData(formType, handleItemDatas, sendDeclaration, setDeclarationData);

  const theme = useTheme();

  const {
    palette: {
      grey: { '700': grey, '300': lightGrey },
    },
  } = theme;

  return (
    <Tooltip title={t('formlib_review_button_text').toString()} placement="right">
      <Fab
        sx={{ marginTop: '10px', backgroundColor: grey, color: lightGrey }}
        data-cy={`${PreviousName}-btn-previous`}
        size="large"
        onClick={onPreviousWorkflow}
      >
        <SkipPreviousIcon />
      </Fab>
    </Tooltip>
  );
};

export default observer(PreviousCommand);
