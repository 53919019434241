import React from 'react';
import { observer } from 'mobx-react';
import { Rating } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { SliderQcFieldName } from './const';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { EditDesc } from '90.quickConnect.Models/models';
import useLabel from '50.quickConnect.Fields/hooks/useLabel';
import { dippedGreen500 } from '00.app/theme';

const SliderQcField = ({ field, updateDeclaration, labelSpan, labelClass }: ValueFieldsTypesProps) => {
  const editDesc = field as EditDesc;
  const { toolTip, id, fullPathId } = editDesc;

  const nameWithId = `${SliderQcFieldName}-${id}`;
  const { breakpoints } = useTheme();
  const { handleChangeRating, localValue } = useData(editDesc, updateDeclaration);
  return (
    <Box className={labelClass} data-cy={nameWithId} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <Rating
        sx={{ color: dippedGreen500 }}
        name="half-rating"
        precision={0.5}
        value={localValue}
        onChange={(event, newValue) => handleChangeRating(event, newValue)}
      />
    </Box>
  );
};
export default observer(SliderQcField);
