import { format, parseISO } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { parseTimeToAPI } from '90.quickConnect.Models/mappings';

/** Permet d'obtenir le format de la date attendu ainsi que la locale */
const getFormatDateTimeAndLocale = (datetime: Date, langugageBrowser: string): string => {
  switch (langugageBrowser) {
    case 'fr':
      return format(datetime, 'HH:mm', { locale: frLocale });
    case 'us':
    default:
      return format(datetime, 'HH:mm aaa', { locale: enLocale });
  }
};

/** Permet de vérifier si le paramètre passé peut être converti en date */
const isDate = (dateValue: AllFieldValueTypes): dateValue is Date =>
  dateValue instanceof Date || (typeof dateValue === 'string' && parseISO(dateValue) instanceof Date);

export const getReferencedTimeValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const timeValue = isDate(valueReferenceDependency) ? new Date(valueReferenceDependency) : undefined;

  if (!timeValue || Number.isNaN(timeValue.getTime())) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Time:
      return timeValue;

    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
    case FieldType.Separator:
      return getFormatDateTimeAndLocale(timeValue, window.navigator.language.substring(0, 2).toLocaleLowerCase());
    default:
      return undefined;
  }
};
