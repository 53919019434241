import { v4 as uuidv4 } from 'uuid';
import { UserAADSearch } from '90.quickConnect.Models/models/user/userAADSearch';

const mapUserAADSearch = ({ displayName, mail, givenName, surname, userPrincipalName }: any): UserAADSearch => ({
  id: uuidv4(),
  displayName,
  mail,
  givenName,
  surname,
  userPrincipalName,
});

export default mapUserAADSearch;
