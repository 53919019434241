import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

function findElement<T, K extends keyof T>(item: T, otherItem: Pick<T, K>, key: K): boolean {
  if (typeof item[key] === 'string' && typeof otherItem[key] === 'string')
    return StringExtension.isTheSame(item[key] as string, otherItem[key] as string);
  else return item[key] === otherItem[key];
}

const arrayIntersect = <T, K extends keyof T>(arr: T[], keysToKeep: Pick<T, K>[], key: K | K[]) => {
  return arr.filter((item) =>
    keysToKeep.some((otherItem) => {
      if (!Array.isArray(key)) {
        return findElement(item, otherItem, key);
      } else {
        let result = false;
        for (const k of key) {
          result = findElement(item, otherItem, k);

          if (result) break;
        }

        return result;
      }
    }),
  );
};

export default arrayIntersect;
