import { useEffect } from 'react';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

const useData = (pageTitle: string): UseDataProps => {
  // On récupère le store
  const {
    LoginStore: { isLoggingOut },
    CommonStore: { showLeftMenu },
  } = useStore();

  // On ajoute des hooks
  useEffect(() => {
    document.title = pageTitle ? pageTitle : 'Quick Connect Forms';
  }, [pageTitle]);

  return {
    isLoggingOut,
    showLeftMenu,
  };
};

export default useData;
