import React, { CSSProperties, FunctionComponent } from 'react';
import { Box, Card, Grid2, Icon, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { styled } from '@mui/system';
import { WorkSite, WorkSiteStatus } from './hookWorkSiteList';
import { WORK_SITE_SUMMARY } from '10.quickConnect.app/routes/paths';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '1rem',
  border: 'solid 1px',
  borderColor: theme.palette.divider,
  cursor: 'pointer',
  marginBottom: '1rem',
}));

const titleStyle: CSSProperties = {
  textAlign: 'center',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: 'bold',
};

const WorkSiteCard: FunctionComponent<WorkSite> = (props: WorkSite) => {
  const theme = useTheme();
  const { t } = useTranslation('moduleWorkSite');
  const navigate = useNavigate();
  const { id, icon, published, status, name } = props;

  function goToWorkSite() {
    navigate(WORK_SITE_SUMMARY.replace(':workSiteId', props.id));
  }

  const getStatusBubble = () => {
    const bubbleSize = '10px';
    let color = status === WorkSiteStatus.CLOSED ? theme.palette.grey['500'] : undefined;
    if (!color) {
      color = published ? theme.palette.success.main : theme.palette.warning.main;
    }
    return <Box width={bubbleSize} height={bubbleSize} borderRadius={'50%'} bgcolor={color}></Box>;
  };

  const getStatusText = () => {
    switch (status) {
      case WorkSiteStatus.CLOSED:
        return t('work_site_status_to_complete');
      case WorkSiteStatus.INCOMPLETE:
        return t('work_site_status_closed');
      default:
        return '';
    }
  };

  return (
    <Grid2 size={3}>
      <StyledCard key={id} onClick={goToWorkSite}>
        <Stack alignItems={'center'} gap={'1rem'}>
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
            <Typography variant={'caption'} display={'flex'} alignItems={'center'} gap={'0.2rem'}>
              {getStatusBubble()} {getStatusText()}
            </Typography>
            <Icon color={'success'}>
              <EditIcon />
            </Icon>
          </Stack>
          <img src={icon} width={'50%'} style={{ maxHeight: '150px', objectFit: 'contain' }} alt={name}></img>
          <Tooltip title={name}>
            <Typography variant={'caption'} style={titleStyle}>
              {name}
            </Typography>
          </Tooltip>
        </Stack>
      </StyledCard>
    </Grid2>
  );
};

export default WorkSiteCard;
