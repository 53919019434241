import CustomLogger from '80.quickConnect.Core/logger/customLogger';

const tag = '80.quickConnect.Core/helpers/isObject.ts';

export const isObject = (obj: any) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);

export const areObjects = (obj1: unknown, obj2: unknown): boolean => isObject(obj1) && isObject(obj2);

export const isSerializedObject = (obj: any): boolean => {
  try {
    if (typeof obj !== 'string') return false;

    const objParsed = JSON.parse(obj);

    return !!objParsed;
  } catch (error) {
    CustomLogger.getInstance().debug(
      tag,
      `[Client Web] [DEBUG] file isObject - function isSerializedObject failed: ${(error as Error).message}`,
    );

    return false;
  }
};
