import { intervalToDuration, isBefore } from 'date-fns';
import { TFunction } from 'i18next';

import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

const removeTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const operationWithDates = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  t: TFunction,
  isDateTime: boolean,
): [AllFieldValueTypes, OperatorResult] => {
  // Vérification des paramètres
  if (
    (typeof ref1Value === 'string' && isNaN(Date.parse(ref1Value))) ||
    (typeof ref2Value === 'string' && isNaN(Date.parse(ref2Value))) ||
    (ref1Value instanceof Date && isNaN(ref1Value.getTime())) ||
    (ref2Value instanceof Date && isNaN(ref2Value.getTime()))
  ) {
    return [t('computed_fields_errors_eval_error').toString(), OperatorResult.ERROR];
  }

  const date1 = new Date(ref1Value as string);
  const date2 = new Date(ref2Value as string);

  if (type === QcOperator.Subst) {
    let result: Duration;

    // If we're only comparing days, get rid of the time
    if (isDateTime) {
      result = intervalToDuration({
        start: date2,
        end: date1,
      });
    } else {
      result = intervalToDuration({
        start: removeTime(date2),
        end: removeTime(date1),
      });
    }

    if (isBefore(date1, date2)) {
      if (result.years) result.years = -result.years;
      else if (result.months) result.months = -result.months;
      else if (result.days) result.days = -result.days;
      else if (result.hours) result.hours = -result.hours;
      else if (result.minutes) result.minutes = -result.minutes;
    }

    return [result, OperatorResult.DURATION];
  } else {
    return [t('computed_fields_errors_operator_type_unknown').toString(), OperatorResult.ERROR];
  }
};
