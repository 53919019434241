import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { FormsTreeViewName } from './const';
import FormTreeItemRenderer from './FormTreeItemRenderer';
import { FormsTreeProps } from './types';
import ViewSkeleton from '10.quickConnect.app/components/domain/Home/Shared/Skeletons/viewSkeleton';
import CustomTree from '10.quickConnect.app/components/domain/Home/Shared/CustomTree';
import { getTreeBoxStyle } from '10.quickConnect.app/components/domain/Home/styles';
import { FormViewer } from '90.quickConnect.Models/models';

const FormsTree = ({ title, hideScrollBar, store, cle }: FormsTreeProps) => {
  return (
    <Box data-cy={FormsTreeViewName} sx={{ ...getTreeBoxStyle(), height: { xs: 'unset', sm: 'unset' } }}>
      {store.items ? (
        <CustomTree<FormViewer>
          store={store}
          title={title}
          RenderTreeItem={FormTreeItemRenderer}
          getName={(i: FormViewer) => i.name}
          cle={cle}
        />
      ) : (
        <ViewSkeleton />
      )}
    </Box>
  );
};
export default observer(FormsTree);
