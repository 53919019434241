import { v4 as uuidv4 } from 'uuid';
import { encode as base64_encode } from 'base-64';
import { LogConfiguration, OptionalParamsLog } from './type';
import { FormType, LogLevel, SeverityLevel } from '90.quickConnect.Models/enums';
import logDb from '40.quickConnect.DataAccess/indexedDb/dbs/logDb';
import getUNIXDateTimeWithTimeZoneOffset from '80.quickConnect.Core/helpers/getUNIXDateTimeWithTimeZoneOffset';

class CustomLogger {
  logLevelNumber: LogLevel;

  useConsole: boolean;

  initDateTimeApp: number;

  private static instance: CustomLogger | null = null;

  /**
   *Le nom de l'utilisateur courant
   *
   * @private
   * @type {string}
   * @memberof CustomLogger
   */
  private _userUPN = '';
  /**
   *Le nom du formulaire courant si l'utilisateur est en train de remplir une déclaration.
   *
   * @private
   * @type {string}
   * @memberof CustomLogger
   */
  private _formName = '';

  /**
   * Indique le type de Formulaire
   *
   * @private
   * @type {FormType}
   * @memberof CustomLogger
   */
  private _formType: FormType = FormType.Unknow;

  private constructor() {
    const stringLogLevel = process.env.REACT_APP_LOG_LEVEL ?? 'None';
    this.logLevelNumber = LogLevel[stringLogLevel as unknown as keyof typeof LogLevel];
    this.useConsole = process.env.REACT_APP_LOG_CONSOLE === true.toString();
    this.initDateTimeApp = performance.now();
    logDb.clearAllTables(this);
  }

  // Region Accessor

  public get userUPN(): string {
    return this._userUPN;
  }

  public set userUPN(value: string) {
    this._userUPN = value;
  }

  public get formName(): string {
    return this._formName;
  }

  public set formName(value: string) {
    this._formName = value;
  }

  public getFormType(): string {
    switch (this._formType) {
      case FormType.Unknow:
        return '';

      case FormType.Form:
        return 'Formulaire Simple';

      case FormType.Template:
        return 'Modèle de Formulaire';

      case FormType.Workflow:
        return 'Formulaire WorkFlow';
    }
  }

  public set formType(value: FormType) {
    this._formType = value;
  }

  // End Region Accessor

  /**
   * Permet de retourner unse seule instance du customLogger. (Singleton Pattern)
   *
   * @static
   * @return {*}  {CustomLogger}
   * @memberof CustomLogger
   */
  public static getInstance(): CustomLogger {
    if (this.instance === null) {
      this.instance = new CustomLogger();
    }

    return this.instance;
  }

  /**
   * Permet de reset l'horodatage (utile dans le cas où on fait appel à des requêtes serveurs)
   */
  resetInitDateTimeApp = () => {
    this.initDateTimeApp = performance.now();
  };

  setDeclarationInfos(formType: FormType, formName: string, userUPN: string) {
    this.formName = formName;
    this.userUPN = userUPN;
    this.formType = formType;
  }

  resetDeclarationInfos() {
    this.formName = '';
    this.formType = FormType.Unknow;
    this.userUPN = '';
  }

  // eslint-disable-next-line
  serialize = (value: any) => {
    if (typeof value === 'function') {
      return value.toString();
    }
    if (value && value !== null && typeof value === 'object') {
      // eslint-disable-next-line no-var
      var serializeObject: any = {};
      for (const [objectKey, objectValue] of Object.entries(value)) {
        serializeObject[objectKey] = this.serialize(objectValue);
      }
      return serializeObject;
    }

    return value;
  };

  formatLog = (
    tag: string,
    message: unknown,
    severityLevel: SeverityLevel,
    optionalParamsLog?: OptionalParamsLog,
  ): LogConfiguration => {
    let msg = 'Erreur Inconnue';
    if (typeof message === 'string') msg = message;
    if (typeof message === 'object' && message !== null) msg = JSON.stringify(message);
    return {
      id: uuidv4(),
      item: {
        Message: this.getMessage(msg, tag),
        MessageData: optionalParamsLog?.MessageData ?? null,
        EventDateTime: getUNIXDateTimeWithTimeZoneOffset(), // Todo: Changer ce format pour string Date UNIX + Offset
        Categorie: optionalParamsLog?.Categorie ?? 'General',
        EventID: optionalParamsLog?.EventID ?? 0,
        EventType: severityLevel,
        Duration: Math.round(performance.now() - this.initDateTimeApp),
      },
      // A voir pour installer base64 package.
      CrashDump:
        optionalParamsLog?.CrashDump &&
        optionalParamsLog.CrashDump !== undefined &&
        typeof optionalParamsLog.CrashDump === 'string'
          ? base64_encode(optionalParamsLog.CrashDump)
          : null,
    };
  };

  // eslint-disable-next-line
  log = async (tag: string, message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Debug && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined
          ? console.log(this.getMessage(message, tag), optionalParams)
          : console.log(this.getMessage(message, tag));
      }

      const itemLogConfiguration = this.formatLog(tag, message, SeverityLevel.Verbose, optionalParams);

      await this.saveLogInDatabase(this.serialize(itemLogConfiguration));
    }
  };

  // eslint-disable-next-line
  error = async (tag: string, message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Error && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined
          ? console.error(this.getMessage(message, tag), optionalParams)
          : console.error(this.getMessage(message, tag));
      }

      const itemLogConfiguration = this.formatLog(tag, message, SeverityLevel.Error, optionalParams);

      try {
        await this.saveLogInDatabase(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  warn = async (tag: string, message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Warning && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined
          ? console.warn(this.getMessage(message, tag), optionalParams)
          : console.log(this.getMessage(message, tag));
      }

      const itemLogConfiguration = this.formatLog(tag, message, SeverityLevel.Warning, optionalParams);

      try {
        await this.saveLogInDatabase(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  info = async (tag: string, message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Information && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined
          ? console.info(this.getMessage(message, tag), optionalParams)
          : console.log(this.getMessage(message, tag));
      }

      const itemLogConfiguration = this.formatLog(tag, message, SeverityLevel.Information, optionalParams);

      try {
        await this.saveLogInDatabase(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  debug = async (tag: string, message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Debug && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined
          ? console.debug(this.getMessage(message, tag), optionalParams)
          : console.log(this.getMessage(message, tag));
      }

      const itemLogConfiguration = this.formatLog(tag, message, SeverityLevel.Verbose, optionalParams);

      try {
        await this.saveLogInDatabase(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  trace = async (tag: string, message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Trace && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined
          ? console.log(this.getMessage(message, tag), optionalParams)
          : console.log(this.getMessage(message, tag));
      }

      const itemLogConfiguration = this.formatLog(tag, message, SeverityLevel.Verbose, optionalParams);

      try {
        const serializedDataLog = this.serialize(itemLogConfiguration);
        await this.saveLogInDatabase(serializedDataLog);
      } catch (error) {
        console.error(error);
      }
    }
  };

  private getMessage(msg: string, tag: string): string {
    return `[Client Web] - ${tag} - ${this._userUPN !== '' ? 'Utilisateur: ' + this._userUPN + ' -' : ''} ${
      this._formName !== ''
        ? 'Formulaire: ' + this._formName + ', Type de Formulaire: ' + this.getFormType() + ' -'
        : ''
    }: ${msg}`;
  }

  async saveLogInDatabase(log: any): Promise<void> {
    await logDb.logs.add(log);
  }
}

export default CustomLogger;
