import { SxProps, Theme } from '@mui/material';

export const getAvatarStyle = (theme: Theme): SxProps => ({
  backgroundColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    width: 40,
    height: 40,
    fontSize: 20, // définit la taille l'icon
    mr: 2,
  },
  [theme.breakpoints.down('md')]: {
    width: 35,
    height: 35,
    fontSize: 20, // définit la taille l'icon
  },
});
