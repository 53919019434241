// eslint-disable-next-line @typescript-eslint/ban-types
export type LeftMenuProps = {};

export enum MenuTabs {
  Undefined = 'Undefined',
  Declarations = 'Declarations',
  Equipments = 'Equipments',
}

export type UseDataProps = {
  showLeftMenu: boolean;
  setShowLeftMenu: (showLeftMenu: boolean) => boolean;
  valueTabIndex: MenuTabs;
  handleTabChange: (newTab: MenuTabs) => void;
};
