import {
  getChoiceList,
  getChoicesFromString,
  getDataSourceValue,
  isQCNotification,
} from '80.quickConnect.Core/helpers/common';
import {
  AllFieldValueTypes,
  EntityData,
  FieldDesc,
  QCNotification,
  NotificationTarget,
  Choice,
  HierarchicalChoice,
  ComboDesc,
} from '90.quickConnect.Models/models';
import { FieldType, NotificationType } from '90.quickConnect.Models/enums';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { isFloat, isNumeric, isDate } from '80.quickConnect.Core/helpers/common';
import { camelize, convertToBooleanValue } from '80.quickConnect.Core/helpers';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

export const getReferencedCodeReaderValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
  fieldWithReference: FieldDesc,
  entityData: EntityData | undefined,
  columnId: string | undefined,
): AllFieldValueTypes => {
  const codeReaderValue = valueReferenceDependency as string;

  const referencesProperties = columnId?.split(',');
  switch (fieldTypeFieldWithReference) {
    case FieldType.CheckBox: {
      const nextValue = getDataSourceValue(entityData, false, referencesProperties);

      if (typeof nextValue === 'undefined') return undefined;

      return convertToBooleanValue(nextValue);
    }

    case FieldType.Text:
    case FieldType.Label:
    case FieldType.Digits:
    case FieldType.ReadOnlyValue:
      return referencesProperties ? getDataSourceValue(entityData, true, referencesProperties) : codeReaderValue;
    case FieldType.Numeric:
      const newValue = getDataSourceValue(entityData, false, referencesProperties);

      if (!isNumeric(newValue)) return undefined;

      const floatValue = isFloat(+newValue) ? +newValue : roundTo(+newValue, 1);

      return floatValue;

    case FieldType.Combo:
    case FieldType.HierarchicalList:
    case FieldType.RadioList:
      const valueFound = getDataSourceValue(entityData, false, referencesProperties);

      if (typeof valueFound === 'string' && (valueFound.startsWith('{') || valueFound.startsWith('['))) {
        return valueFound;
      } else if (typeof valueFound === 'string') {
        // Il faut rechercher dans une listChoices
        const { listChoice: listChoiceFromExp } = fieldWithReference as ComboDesc;

        return getChoicesFromString(valueFound, listChoiceFromExp ?? [], fieldTypeFieldWithReference);
      } else {
        return Array.isArray(valueFound)
          ? fieldTypeFieldWithReference !== FieldType.HierarchicalList
            ? (valueFound[0] as Choice)
            : (valueFound as HierarchicalChoice[])
          : valueFound;
      }

    case FieldType.DateTime: {
      const nextValue = getDataSourceValue(entityData, false, referencesProperties) ?? codeReaderValue;

      if (!isDate(nextValue)) return undefined;

      return new DateTimeExtension(nextValue);
    }

    case FieldType.Notification: {
      const nextValue = getDataSourceValue(entityData, false, referencesProperties) ?? codeReaderValue;

      if (typeof nextValue !== 'string' && isQCNotification(nextValue)) return nextValue;

      if (typeof nextValue !== 'string') return undefined;

      if (nextValue === '') return undefined;

      if (nextValue === undefined) return undefined;

      if (typeof nextValue === 'string' && !nextValue.startsWith('{')) return undefined;

      const notifData: Partial<QCNotification> = JSON.parse(nextValue, function (key: string, value: any) {
        const camelCaseKey: string = camelize(key);

        if (this instanceof Array || camelCaseKey === key) return value;
        else this[camelCaseKey] = value;
      });

      // Prendre en compte l'unicité des selectedTargets...
      const newSelectedTargets =
        notifData.selectedTargets?.filter(
          (notifTarget: NotificationTarget, index: number, array: NotificationTarget[]) =>
            array.findIndex((nT: NotificationTarget) => nT.target === notifTarget.target) === index,
        ) ?? [];

      return {
        autoSend: notifData.autoSend ?? true,
        notificationType: notifData.notificationType ?? NotificationType.Email,
        searchAllUO: notifData.searchAllUO ?? false,
        selectedTargets: newSelectedTargets,
        sendToMe: notifData.sendToMe ?? false,
        subject: notifData.subject ?? null,
      } as QCNotification;
    }

    default:
      return getDataSourceValue(entityData, false, referencesProperties) ?? codeReaderValue;
  }
};
