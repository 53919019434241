import React from 'react';
import { Box, Tabs, Tab, Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { EquipmentName } from './const';
import useData from './hooks';
import { ColumnContainer } from './styles';
import QCDocuments from './QCDocuments';
import EntityData from './EntityData';
import EntityForms from './EntityForms';
import { EquipmentTabs } from './types';
import HistoricalData from './HistoricalData';
import TopBar from '10.quickConnect.app/components/shared/LayOut/TopBar';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const Equipment: React.FC<Record<string, unknown>> = () => {
  const { t } = useTranslation('equipment');
  const { entityId, entityData, selectedTab, handleTabChange, schemasIds } = useData(t);
  const isMobile = useIsMobile();

  return (
    <Box data-cy={EquipmentName} sx={ColumnContainer}>
      <TopBar
        pageTitle={entityData ? `${t('title')} : ${entityData.title} (${entityData.code})` : t('title')}
        leftChildren={
          !isMobile ? (
            <Tabs
              sx={{ width: '100%' }}
              value={selectedTab}
              onChange={handleTabChange}
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile
              variant="fullWidth"
            >
              <Tab
                label={t('tabs.attributs')}
                value={EquipmentTabs.Attributs}
                data-cy={`${EquipmentName}-${EquipmentTabs.Attributs}`}
              />
              <Tab
                label={t('tabs_forms')}
                value={EquipmentTabs.Forms}
                data-cy={`${EquipmentName}-${EquipmentTabs.Forms}`}
              />
              <Tab
                label={t('tabs.documents')}
                value={EquipmentTabs.Documents}
                data-cy={`${EquipmentName}-${EquipmentTabs.Documents}`}
              />
              <Tab
                label={t('tabs.historic')}
                value={EquipmentTabs.Historic}
                data-cy={`${EquipmentName}-${EquipmentTabs.Historic}`}
              />
            </Tabs>
          ) : (
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
              <BottomNavigation
                showLabels
                value={selectedTab}
                onChange={(event: React.SyntheticEvent, newValue: EquipmentTabs) => {
                  return handleTabChange(event, newValue);
                }}
              >
                <BottomNavigationAction
                  label={t('tabs.attributs')}
                  value={EquipmentTabs.Attributs}
                  data-cy={`${EquipmentName}-${EquipmentTabs.Attributs}`}
                />
                <BottomNavigationAction
                  label={t('tabs_forms')}
                  value={EquipmentTabs.Forms}
                  data-cy={`${EquipmentName}-${EquipmentTabs.Forms}`}
                />
                <BottomNavigationAction
                  label={t('tabs.documents')}
                  value={EquipmentTabs.Documents}
                  data-cy={`${EquipmentName}-${EquipmentTabs.Documents}`}
                />
                <BottomNavigationAction
                  label={t('tabs.historic')}
                  value={EquipmentTabs.Historic}
                  data-cy={`${EquipmentName}-${EquipmentTabs.Historic}`}
                />
              </BottomNavigation>
            </Paper>
          )
        }
      />
      {selectedTab === EquipmentTabs.Attributs && entityData && <EntityData entityData={entityData} />}
      {selectedTab === EquipmentTabs.Forms && entityId && entityData && (
        <EntityForms schemaIds={schemasIds} entityId={entityId} />
      )}
      {selectedTab === EquipmentTabs.Documents && entityData && <QCDocuments qcDocuments={entityData.qcDocuments} />}
      {selectedTab === EquipmentTabs.Historic && entityData && <HistoricalData />}
      {/* {isMobile && <ScrollTo idToScrollTo="topBarComponent" />} */}
    </Box>
  );
};
export default observer(Equipment);
