import React from 'react';

export const LogoStandardSmallSvg = () => {
  return (
    <svg width="125" height="75" viewBox="0 0 185 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="80" width="105" height="80" fill="#464B47" />
      <path
        // eslint-disable-next-line max-len
        d="M120.308 71.92C119.854 71.92 119.469 71.7613 119.152 71.444C118.834 71.1266 118.676 70.7186 118.676 70.22V54.988C117.588 56.4386 116.182 57.5946 114.46 58.456C112.782 59.272 110.788 59.68 108.476 59.68C106.164 59.68 104.124 59.2946 102.356 58.524C100.588 57.7533 99.0918 56.6653 97.8678 55.26C96.6438 53.8546 95.7145 52.1773 95.0798 50.228C94.4452 48.2786 94.0825 46.148 93.9918 43.836C93.9465 42.9293 93.9238 42.0906 93.9238 41.32C93.9238 40.5493 93.9465 39.7333 93.9918 38.872C94.0825 36.424 94.4452 34.2253 95.0798 32.276C95.7598 30.3266 96.6892 28.672 97.8678 27.312C99.0918 25.9066 100.588 24.8413 102.356 24.116C104.124 23.3453 106.164 22.96 108.476 22.96C111.06 22.96 113.213 23.436 114.936 24.388C116.704 25.2946 118.109 26.4733 119.152 27.924V25.34C119.152 24.8413 119.31 24.4333 119.628 24.116C119.945 23.7986 120.353 23.64 120.852 23.64H127.312C127.81 23.64 128.218 23.7986 128.536 24.116C128.853 24.4333 129.012 24.8413 129.012 25.34V70.22C129.012 70.7186 128.853 71.1266 128.536 71.444C128.218 71.7613 127.81 71.92 127.312 71.92H120.308ZM111.536 51.52C113.168 51.52 114.482 51.1573 115.48 50.432C116.522 49.7066 117.293 48.8 117.792 47.712C118.336 46.5786 118.63 45.3773 118.676 44.108C118.721 43.2466 118.744 42.3853 118.744 41.524C118.744 40.6626 118.721 39.824 118.676 39.008C118.63 37.648 118.358 36.3786 117.86 35.2C117.406 34.0213 116.658 33.0466 115.616 32.276C114.618 31.5053 113.258 31.12 111.536 31.12C109.858 31.12 108.498 31.5053 107.456 32.276C106.458 33.0013 105.733 33.976 105.28 35.2C104.872 36.424 104.622 37.7613 104.532 39.212C104.441 40.6173 104.441 42.0226 104.532 43.428C104.622 44.8786 104.872 46.216 105.28 47.44C105.733 48.664 106.458 49.6613 107.456 50.432C108.498 51.1573 109.858 51.52 111.536 51.52Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M153.293 59.68C149.983 59.68 147.059 59.068 144.521 57.844C142.027 56.62 140.055 54.852 138.605 52.54C137.154 50.1826 136.361 47.372 136.225 44.108C136.179 43.3826 136.157 42.476 136.157 41.388C136.157 40.2546 136.179 39.3253 136.225 38.6C136.361 35.2906 137.131 32.48 138.537 30.168C139.987 27.8106 141.982 26.02 144.521 24.796C147.059 23.572 149.983 22.96 153.293 22.96C156.33 22.96 158.914 23.3906 161.045 24.252C163.175 25.068 164.921 26.1106 166.281 27.38C167.641 28.6493 168.638 29.9866 169.273 31.392C169.953 32.752 170.315 33.9533 170.361 34.996C170.406 35.4493 170.247 35.8573 169.885 36.22C169.567 36.5373 169.182 36.696 168.729 36.696H161.453C160.999 36.696 160.637 36.5826 160.365 36.356C160.138 36.084 159.911 35.7213 159.685 35.268C159.05 33.6813 158.234 32.548 157.237 31.868C156.239 31.188 154.993 30.848 153.497 30.848C151.457 30.848 149.847 31.5053 148.669 32.82C147.49 34.1346 146.855 36.1746 146.765 38.94C146.719 40.708 146.719 42.3173 146.765 43.768C146.901 46.5786 147.535 48.6186 148.669 49.888C149.847 51.1573 151.457 51.792 153.497 51.792C155.083 51.792 156.353 51.452 157.305 50.772C158.257 50.092 159.05 48.9586 159.685 47.372C159.866 46.9186 160.093 46.5786 160.365 46.352C160.637 46.08 160.999 45.944 161.453 45.944H168.729C169.182 45.944 169.567 46.1253 169.885 46.488C170.247 46.8053 170.406 47.1906 170.361 47.644C170.315 48.4146 170.089 49.3213 169.681 50.364C169.318 51.4066 168.706 52.472 167.845 53.56C167.029 54.648 165.963 55.668 164.649 56.62C163.334 57.5266 161.725 58.2746 159.821 58.864C157.962 59.408 155.786 59.68 153.293 59.68Z"
        fill="white"
      />
      <path d="M0 32C0 14.3269 14.3269 0 32 0H80V80H0V32Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 8H32C18.7452 8 8 18.7452 8 32V72H72V8ZM32 0C14.3269 0 0 14.3269 0 32V80H80V0H32Z"
        fill="#17DCB8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.343 28H23.9998V20H59.9998V56H51.9998V33.6569L23.3282 62.3284L17.6714 56.6716L46.343 28Z"
        fill="#17DCB8"
      />
    </svg>
  );
};
