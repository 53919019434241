import { isAnAttachmentItemsData, isFieldDataArray } from '90.quickConnect.Models/guards';
import { AttachmentItemData, FieldData, QCAttachment } from '90.quickConnect.Models/models';
import { IsAnAttachmentField } from '90.quickConnect.Models/models/fields/fieldDesc/fieldDesc';

const mapDistantUriForOtherStep = (allAttachments: QCAttachment[], simpleFields: FieldData[]): FieldData[] => {
  return simpleFields.map((fd: FieldData) => {
    if (isFieldDataArray(fd, fd.value)) return { ...fd, value: mapDistantUriForOtherStep(allAttachments, fd.value) };

    if (!IsAnAttachmentField(fd.fieldType)) return fd;

    if (!isAnAttachmentItemsData(fd.value)) return fd;

    const attachItems = fd.value as AttachmentItemData[];

    const nextValue = attachItems.reduce((acc: AttachmentItemData[], current: AttachmentItemData) => {
      if (current.distantUri !== '') return [...acc, current];

      const attachItemFromDb = allAttachments.find((attach: QCAttachment) => attach.id === current.id);

      if (!attachItemFromDb) return [...acc, current];

      return [...acc, { ...current, distantUri: attachItemFromDb.distantUri }];
    }, []);

    fd.value = nextValue;

    return fd;
  });
};

export default mapDistantUriForOtherStep;
