import mapBaseAttachmentDesc from './mapBaseAttachmentDesc';
import { DrawDesc } from '90.quickConnect.Models/models';

const mapDrawDesc = (field: DrawDesc): DrawDesc =>
  ({
    ...mapBaseAttachmentDesc(field),
    defaultImage: field.defaultImage,
    allowPhoto: field.allowPhoto ?? true,
    allowPick: field.allowPick ?? true,
  } as DrawDesc);

export default mapDrawDesc;
