import { getReferencedAddressValue } from './address';
import { getReferencedCheckboxListValue } from './checkboxList';
import { getReferencedComboValue } from './combo';
import { getReferencedCheckboxValue } from './checkbox';
import { getReferencedCodeReaderValue } from './codeReader';
import { getReferencedCounterValue } from './counter';
import { getReferencedDateTimeValue } from './dateTime';
import { getReferencedDigitsValue } from './digits';
import { getReferencedGeolocationValue } from './geolocation';
import { getReferencedLabelValue } from './label';
import { getReferencedNotificationValue } from './notification';
import { getReferencedNumericValue } from './numeric';
import { getReferencedReadOnlyValue } from './readOnlyValue';
import { getReferencedRfidReaderValue } from './rfidReader';
import { getReferencedSliderValue } from './slider';
import { getReferencedComputeValue } from './compute';
import { getReferencedTextValue } from './text';
import { getReferencedTimeValue } from './time';
import { getReferencedTodoListValue } from './todoList';
import { getReferencedHierarchicalListValue } from './hierarchicalList';
import { getReferencedActionValue } from './action';

export {
  getReferencedAddressValue,
  getReferencedCheckboxListValue,
  getReferencedComboValue,
  getReferencedCheckboxValue,
  getReferencedCodeReaderValue,
  getReferencedCounterValue,
  getReferencedDateTimeValue,
  getReferencedDigitsValue,
  getReferencedGeolocationValue,
  getReferencedLabelValue,
  getReferencedNotificationValue,
  getReferencedNumericValue,
  getReferencedReadOnlyValue,
  getReferencedRfidReaderValue,
  getReferencedSliderValue,
  getReferencedComputeValue,
  getReferencedTextValue,
  getReferencedTimeValue,
  getReferencedTodoListValue,
  getReferencedHierarchicalListValue,
  getReferencedActionValue,
};
