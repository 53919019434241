import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MandatoryFieldType } from './types';
import { FieldType, Mandatory } from '90.quickConnect.Models/enums';
import { QCFieldComponentRoot } from '50.quickConnect.Fields/const';
import { isMandatoryItems } from '90.quickConnect.Models/mappings/fields/isMandatoryItems';

const MandatoryField = ({ fieldDesc, children }: MandatoryFieldType) => {
  const theme = useTheme();
  const { t } = useTranslation('declaration');
  const { mandatory, errors, fieldType, isVisible } = fieldDesc;

  const mandatoryIndicator = useMemo(() => {
    // pour mettre l'asterix que sur les champs obligatoires
    const requiredError = errors?.filter((er) => er === t('errors_mandatory_field'));

    if (requiredError && requiredError.length > 0) {
      return <span style={{ color: theme.palette.error.main, fontWeight: 'bolder', marginRight: '0.5em' }}>*</span>;
    }
  }, [errors, t, theme.palette.error.main]);

  return (
    <Box sx={{ display: 'flex' }} data-cy={QCFieldComponentRoot}>
      <>
        {(isVisible && mandatoryIndicator) ?? (
          <span style={{ marginRight: fieldType === FieldType.Include ? '0rem' : '1em' }}> </span>
        )}
        {children}
      </>
    </Box>
  );
};
export default MandatoryField;
