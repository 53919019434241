import { v4 as uuidv4 } from 'uuid';
import { FieldDesc } from '90.quickConnect.Models/models';

const updateStatedIdFieldOrChild = (fullPathId: string, field: FieldDesc): [FieldDesc, boolean] => {
  let changeMade = false;
  if (field.fullPathId == fullPathId) {
    field.stateId = uuidv4();
    changeMade = true;
  }
  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateStatedIdFieldOrChild(fullPathId, child);
      if (childChanged) changeMade = childChanged;
      child = updatedChild;
    });
  }
  return [field, changeMade];
};

export default updateStatedIdFieldOrChild;
