import { AllData, EntitySchemaInfosDTO } from '30.quickConnect.Stores/RootStore/EquipmentsStore/Payloads/responses';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/enums';
import { AttributeValue, EntityData, KeyValuePair } from '90.quickConnect.Models/models';

const mapAttributeValueFromBindingsFromAllData = (
  entityData: AllData,
  binding: { [key: string]: string },
  bindingTypes: KeyValuePair<string, EntitySchemaAttributeTypes>[],
): AttributeValue[] => {
  const attributes: AttributeValue[] = [];
  if (binding) {
    const entries = Object.entries(binding);
    entries.forEach((entry) => {
      const [attributeId, attributeLabel] = entry;
      const value = entityData[attributeLabel];
      const type = bindingTypes[attributeLabel as any];
      attributes.push({
        attributeId: attributeId,
        attributeLabel: attributeLabel,
        value: value,
        type: type,
      } as unknown as AttributeValue);
    });
  }
  return attributes;
};

const mapEntitiesData = (
  partialEntitiesData: AllData[] | undefined,
  schemaInfos: Record<string, EntitySchemaInfosDTO> | undefined,
): EntityData[] => {
  if (!partialEntitiesData || !schemaInfos) return [];
  return partialEntitiesData.map((partialEntityData: AllData) => {
    const { entityschemaid, id, title, code, correlationId, qcTagCode } = partialEntityData;
    const schemaInfo = schemaInfos[entityschemaid];

    return {
      id,

      eTag: '',
      createdAt: new Date(),
      updatedAt: undefined,
      title,
      code,
      organizationalUnitId: '',
      entitySchemaId: entityschemaid,
      attributes: mapAttributeValueFromBindingsFromAllData(
        partialEntityData,
        schemaInfo?.binding ?? [],
        schemaInfo?.bindingTypes ?? [],
      ),
      correlationId,
      qcTagCode: qcTagCode ?? '',
    } as EntityData;
  });
};

export default mapEntitiesData;
