import { QCSBaseObject } from './QCSBaseObject';
import { QCSBool } from './QCSBool';
import { QCSInt } from './QCSInt';
import { QCSString } from './QCSString';

export class QCSMatcher extends QCSBaseObject {
  private _regEx: RegExp;

  private _toLookIn: string;

  private _matches: RegExpExecArray | null = null;

  private _currentIndex: number;

  constructor(regEx: RegExp, toLookIn: string) {
    super();
    this._regEx = regEx;
    this._toLookIn = toLookIn;
    this._currentIndex = -1;
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    return null;
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 1: // find
        return this.find();

      case 2: // groupCount
        return this.groupCount();

      case 3: // group
        return this.group(qcParams);

      default:
        return null;
    }
  }

  private group(qcParams: Array<QCSBaseObject>): QCSBaseObject {
    if (this._matches && this._matches !== null && this._matches.length > this._currentIndex) {
      const indexGroup: number = (qcParams[0] as QCSInt)?.value ?? -1;
      if (indexGroup !== -1 && indexGroup < this._matches.length) {
        return new QCSString(this._matches[indexGroup]);
      }
    }
    return QCSBaseObject.QCSNull;
  }

  private groupCount(): QCSBaseObject {
    if (this._matches && this._matches !== null && this._matches.length > this._currentIndex)
      return new QCSInt(this._matches.length);
    return new QCSInt(0);
  }

  private find(): QCSBaseObject {
    this._currentIndex += 1;
    if (this._currentIndex === 0) {
      this._matches = this._regEx.exec(this._toLookIn);
    }
    if (!this._matches?.length) return new QCSBool(false);

    return new QCSBool(this._currentIndex < this._matches?.length ?? false);
  }
}
