import { toast } from 'react-toastify';
import { IQCSHost } from '20.formLib/helpers/QCScriptLib/interfaces/IQCSHost';
import { ToastItem } from '90.quickConnect.Models/models';

export const toastifyOnChange = (formManager?: IQCSHost | null): void => {
  toast.onChange((payload) => {
    switch (payload.status) {
      case 'added':
      case 'updated':
        formManager?.qcscriptStore?.setToastId(payload.id);
        break;

      case 'removed':
        formManager?.qcscriptStore?.setToastId(undefined);
        break;
    }
  });
};
