import styled from '@emotion/styled';
import { SxProps } from '@mui/system';

export const LeftLogo = styled.img`
  max-height: 60px;
  width: auto;
`;

export const LogoBox = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
} as SxProps;
