import { NumericDesc } from '90.quickConnect.Models/models';

const mapNumericDesc = (field: NumericDesc): NumericDesc =>
  ({
    isVertical: field.isVertical,
    min: field.min,
    max: field.max,
    value: field.value?.toString() ?? field.defaultValue?.toString() ?? undefined,
    defaultValue: field.defaultValue?.toString(),
  } as NumericDesc);

export default mapNumericDesc;
