import {
  FixedAttachmentDesc,
  FixedAttachmentType,
} from '90.quickConnect.Models/models/fields/fieldDesc/attachmentField/fixedAttachmentDesc';

const mapFixedAttachmentDesc = (fieldDesc: FixedAttachmentDesc): FixedAttachmentType =>
  ({
    value: fieldDesc.value ?? (fieldDesc.fixedAttachment ? [fieldDesc.fixedAttachment] : undefined),
  } as unknown as FixedAttachmentType);

export default mapFixedAttachmentDesc;
