import { toJS } from 'mobx';
import { ModuleMethodInfo } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/ModuleMethodInfo';
import { QCSInterpreter } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSInterpreter';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';
import { IQCSHost } from '20.formLib/helpers/QCScriptLib/interfaces/IQCSHost';
import { QCSModulesEnum } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/enums/QCSModules';
import { QCSInstance } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInstance';
import { QCSDouble } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDouble';
import { QCSInt } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInt';
import { QCSString } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSString';
import { QCSBool } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBool';
import { QCSArray } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSArray';
import { QCSPattern } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSPattern';
import { QCSMatcher } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSMatcher';
import { QCSLong } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSLong';
import { QCSChoiceList } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSChoiceList';
import { QCSChoice } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSChoice';
import { QCSDate } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDate';
import { QCSMath } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSMath';
import { QCSFormContext } from '20.formLib/helpers/QCScriptLib/bridge/QCSFormContext';
import { FormContextDico } from '20.formLib/helpers/QCScriptLib/bridge/types/qcsFormContext';
import { AllFieldValueTypes, DeclarationContext, FieldDesc } from '90.quickConnect.Models/models';
import QCScriptStore from '30.quickConnect.Stores/RootStore/QCScriptStore';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { CaseInsensitiveMap } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/utils/CaseInsensitiveMap';
import { errorHandler } from '80.quickConnect.Core/helpers';
import { FieldType } from '90.quickConnect.Models/enums';

export class QCSHost implements IQCSHost {
  private static readonly TAG = '20.formLib/helpers/QCScriptLib/QuickConnect.API.Integration.Tests/QCSHost.ts';

  private fc: QCSFormContext | undefined;

  private context: DeclarationContext | undefined;

  qcscriptStore: QCScriptStore | undefined;

  toastId: string | number | undefined;

  isDebugModeEnabled: boolean;
  constructor(qcscriptStore: QCScriptStore) {
    this.setHost(qcscriptStore);
    this.isDebugModeEnabled = qcscriptStore.isDebugModeEnabled;
  }

  getFlattenFields(): FieldDesc[] {
    return this.qcscriptStore?.flattenFields ?? [];
  }

  updateFlattenFieldValue(fullPathId: string, newValueForReferencedField: AllFieldValueTypes) {
    this.qcscriptStore?.updateFlattenFieldValue(fullPathId, newValueForReferencedField);
  }

  validate(sid: string, nextValueProposed: unknown): boolean {
    if (!this.qcscriptStore) throw new Error('QCScriptStore not found');
    return this.qcscriptStore?.validate(sid, nextValueProposed);
  }

  getValue(sid: string): FieldDesc | undefined {
    const plainFlattenFields = toJS(this.qcscriptStore?.flattenFields);
    const result = plainFlattenFields?.find((f: FieldDesc) => sid === f.fullPathId);
    return result;
  }

  getFieldType(sid: string): FieldType {
    return this.qcscriptStore?.getFieldType(sid) ?? FieldType.Unknow;
  }

  callQCScript = (
    interpreter: QCSInterpreter,
    methodName: string,
    fieldId?: string,
    onImageClickedFieldId?: string,
  ): unknown => {
    // this.fc.setBaseFieldScope(fieldId ?? '');
    return this.fc ? interpreter.callInterpreterMethod(methodName, this.fc, fieldId, onImageClickedFieldId) : null;
  };

  callExternal(mmi: ModuleMethodInfo, instance: QCSBaseObject | null, qcParams: QCSBaseObject[]): QCSBaseObject | null {
    const { moduleId, methodId } = mmi;
    switch (moduleId) {
      case QCSModulesEnum.QCS_FORM_CONTEXT: {
        if (instance === null) return QCSFormContext.staticCall(methodId, qcParams);

        if (instance instanceof QCSInstance) {
          const fcm: object | null = instance.value;
          return fcm instanceof QCSFormContext ? fcm.callQCSModule(methodId, qcParams) : null;
        } else {
          return null;
        }
      }

      case QCSModulesEnum.QCS_STRING: {
        if (instance === null) return QCSString.staticCall(methodId, qcParams);
        if (instance instanceof QCSString === false) return null;

        const s: QCSString = instance as QCSString;
        return s.callQCSObject(methodId, qcParams);
      }

      case QCSModulesEnum.QCS_DATE: {
        if (instance === null) return QCSDate.staticCall(mmi.methodId, qcParams);

        if (instance instanceof QCSDate === false) return null;

        const d: QCSDate = instance as QCSDate;
        return d.callQCSObject(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_MATH: {
        return QCSMath.staticCall(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_PATTERN: {
        if (instance === null) return QCSPattern.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSPattern === false) return null;

        const p: QCSPattern = instance as QCSPattern;
        return p.callQCSObject(mmi.methodId, qcParams);
      }
      case QCSModulesEnum.QCS_MATCHER: {
        if (instance === null) return QCSMatcher.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSMatcher === false) return null;

        const m: QCSMatcher = instance as QCSMatcher;
        return m.callQCSObject(mmi.methodId, qcParams);
      }
      case QCSModulesEnum.QCS_LONG: {
        if (instance === null) return QCSLong.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSLong === false) return null;

        const m: QCSLong = instance as QCSLong;
        return m.callQCSObject(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_INT: {
        if (instance === null) return QCSInt.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSInt === false) return null;

        const m: QCSInt = instance as QCSInt;
        return m.callQCSObject(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_DOUBLE: {
        if (instance === null) return QCSDouble.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSDouble === false) return null;

        const m: QCSDouble = instance as QCSDouble;
        return m.callQCSObject(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_ARRAY:
        if (instance === null) return QCSArray.StaticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSArray === false) return null;

        const d: QCSArray = instance as QCSArray;
        return d.callQCSObject(mmi.methodId, qcParams);

      case QCSModulesEnum.QCS_BOOL: {
        if (instance == null) return QCSBool.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSBool === false) return null;

        const b: QCSBool = instance as QCSBool;
        return b.callQCSObject(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_CHOICE_LIST: {
        if (instance === null) return QCSChoiceList.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSChoiceList === false) return null;

        const cl: QCSChoiceList = instance as QCSChoiceList;
        return cl.callQCSObject(mmi.methodId, qcParams);
      }

      case QCSModulesEnum.QCS_CHOICE: {
        if (instance === null) return QCSChoice.staticCall(mmi.methodId, qcParams);
        if (instance instanceof QCSChoice === false) return null;

        const c: QCSChoice = instance as QCSChoice;
        return c.callQCSObject(mmi.methodId, qcParams);
      }

      default:
        return null;
    }
  }

  private initFieldDico(formContextDico: FormContextDico): boolean {
    return this.fc ? this.fc.initFieldDico(formContextDico) : false;
  }

  public setBaseFieldScope = (baseScope: string): void => {
    if (this.fc) this.fc.setBaseFieldScope(baseScope);
  };

  public getFormContextFieldDico(): CaseInsensitiveMap<string, unknown> {
    return this.fc ? this.fc.fieldValue : new CaseInsensitiveMap();
  }

  getForm = (): DeclarationContext | undefined => this.context;

  public getFormContext = (): QCSFormContext | undefined => this.fc;

  public setHost = (qcscriptStore: QCScriptStore): void => {
    this.qcscriptStore = qcscriptStore;
    this.context = qcscriptStore.context!;
    if (this.fc) {
      this.fc.setFormContext(this);
    } else {
      this.fc = new QCSFormContext(this);
    }
    try {
      if (!this.initFieldDico(qcscriptStore.formContextDico))
        throw new Error(
          `Erreur d'initialisation du dico de valeur de champ pour le script ${qcscriptStore.qcscriptObj!.scriptName}`,
        );
    } catch (error: unknown) {
      errorHandler(QCSHost.TAG, error, 'setHost');
    }
  };

  setValueMainFormContext(qcParams: QCSBaseObject[], sid?: string): void {
    const valueSet = this.fc?.setValue(qcParams, sid) ?? false;

    if (!valueSet) {
      const error = new Error(
        `la methode "setValue" du formContext Principal a echoué. formName: ${
          this.getForm()?.name
        } - Params: fullPathId: ${qcParams[0].getValue()}, value: ${qcParams[1].getValue()}`,
      );

      errorHandler(QCSHost.TAG, error, 'setValueMainFormContext');
    }
  }
}
