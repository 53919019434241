// TODO: Revoir l'import quand la lib sera Implementé dans le client Web
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSBaseObject } from './QCSBaseObject';
import { QCSInt } from './QCSInt';
import { QCSChoice } from './QCSChoice';
import { QCSString } from './QCSString';
import { Choice } from '90.quickConnect.Models/models/declarations/choice';

export class QCSChoiceList extends QCSBaseTypedObject<Array<Choice>> {
  public constructor(cl: Choice[]) {
    super(cl);
    this.value = cl;
  }

  public static staticCall = (method: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => null;

  public callQCSObject = (methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => {
    switch (methodId) {
      case 1: // length
      case 2: // size (idem length)
        return new QCSInt(this.value.length);

      case 3: // get
        return this.getQCSChoice(qcParams);

      case 4: // toDisplayString
        return new QCSString(this.toDisplayString());

      default:
        return null;
    }
  };

  private getQCSChoice = (qcParams: Array<QCSBaseObject>): QCSBaseObject => {
    const index: number = (qcParams[0] as QCSInt).value;

    const currentValue: Choice = this.value[index];

    return new QCSChoice(currentValue);
  };

  private toDisplayString = (): string => {
    if (this.value.length === 0) return '';

    return this.value.map((choice: Choice): string => (choice.label !== '' ? choice.value : choice.label)).join(', ');
  };
}
