export enum ExportTypeEnum {
  Declaration = 0,
  TodoListsDesc = 1,
  TodoLists = 2,
  TodoListsTaskDesc = 3,
  TodoListsTask = 4,
  TodoListsNewTask = 5,
  TodoListTaskEditedData = 6,
  DeclarationPDF = 7,
  DeclarationTemplate = 8,
  DeclarationCSV = 9,
  TodoListsDelete = 10,
}
