import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CancelCommandName } from './const';
import { CancelCommandProps } from './types';
import useData from './hooks';

const CancelCommand = ({
  formType,
  handleItemDatas,
  sendDeclaration,
  setDeclarationData,
  declarationId,
}: CancelCommandProps) => {
  const { t, onCancel } = useData(formType, handleItemDatas, sendDeclaration, setDeclarationData, declarationId);

  return (
    <Tooltip title={t('formlib_cancel_button').toString()} placement="right">
      <Fab
        sx={{ marginTop: '10px' }}
        data-cy={`${CancelCommandName}-btn-cancel`}
        size="large"
        onClick={onCancel}
        color="warning"
      >
        <ArrowBackIcon />
      </Fab>
    </Tooltip>
  );
};

export default observer(CancelCommand);
