import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { QcTooltipProps } from './types';
import useData from './hooks';
import { QcTooltipName } from './const';
import { ToolTipType } from '90.quickConnect.Models/enums';

const QcTooltip: React.VFC<QcTooltipProps> = ({ tooltip: { toolTipType, value }, parentId }: QcTooltipProps) => {
  const { open, isMobile, handleTooltipClose, handleTooltipOpen, handleClickTooltip } = useData();
  const parentIdAndName = `${QcTooltipName}-${parentId}`;
  switch (toolTipType) {
    case ToolTipType.Link:
      return (
        <Tooltip title={value}>
          <IconButton data-cy={parentIdAndName} onClick={() => handleClickTooltip(value)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    case ToolTipType.Text:
      return isMobile ? (
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          title={value}
        >
          <IconButton data-cy={parentIdAndName} onClick={handleTooltipOpen}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={value}>
          <IconButton data-cy={parentIdAndName}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      );
    case ToolTipType.None:
    default:
      return <></>;
  }
};
export default QcTooltip;
