import React from 'react';
import useData from './hooks';
import { VisibilityResolverProps } from './types';

/**
 * Ce composant permet d'avoir un useEffect différent pour chaque visibilityDependency
 * @param param0
 * @returns
 */
const VisibilityResolver = ({
  id,
  fullPathId,
  visible,
  updateFieldVisibility,
  flattenFields,
}: VisibilityResolverProps) => {
  useData(id, fullPathId, visible, updateFieldVisibility, flattenFields);

  return <></>;
};

export default VisibilityResolver;
