import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { Choice, ComboDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import isChoice from '90.quickConnect.Models/guards/fields/isChoice';
import { getChoicesListFromListDef } from '80.quickConnect.Core/helpers/common';
import { choiceListParse } from '80.quickConnect.Core/helpers/choiceListParsers';
import { SharedListType } from '90.quickConnect.Models/enums';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

const useData = (
  comboDesc: ComboDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  choices: Choice[],
  isItemDeleted: string | undefined,
  setIsItemDeleted: React.Dispatch<React.SetStateAction<string | undefined>>,
  setIsDeleteAll: Dispatch<SetStateAction<boolean>>,
  isDeleteAll: boolean,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { defaultValue, fullPathId, value, reference, listChoice, listDef } = comboDesc;

  const [localValue, setLocalValue] = useState<Choice[]>((): Choice[] => {
    if (value && Array.isArray(value)) {
      const valueChoices = (value as Choice[]).reduce((acc: Choice[], current: Choice) => {
        const indexChoice = choices.findIndex((choiceParsed: Choice) => {
          return choiceParsed.label === current.label && choiceParsed.value === current.value;
        });

        return indexChoice !== -1 ? [...acc, choices[indexChoice]] : acc;
      }, []);

      return valueChoices;
    } else return [];
  });

  useEffect(() => {
    if (isItemDeleted) {
      setLocalValue(localValue.filter((it) => it.value !== isItemDeleted));
      setIsItemDeleted(undefined);
    }
  }, [isItemDeleted, localValue, setIsItemDeleted]);

  useEffect(() => {
    if (isDeleteAll) {
      setLocalValue([]);
      setIsDeleteAll(false);
    }
  }, [isDeleteAll, setIsDeleteAll]);
  const [label, setLabel] = useState('');
  const [currentChoices, setCurrentChoices] = useState<Choice[]>(() => choices);
  const [onSearch, setOnsearch] = useState<boolean>(false);
  const [listExistedChoise, setListExistedChoise] = useState<Choice[] | undefined>(choices);

  // On définit les callbacks
  // Update du formulaire au global
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const updateLocalState = useCallback(
    (choice: Choice) => () => {
      const newChecked = [...localValue];
      const currentIndex = newChecked.findIndex((c) => c.value === choice.value && c.label === choice.label);
      if (currentIndex === -1) {
        newChecked.push(choice);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setLocalValue(newChecked);
      debouncedUpdateGlobalState();
    },
    [localValue, debouncedUpdateGlobalState],
  );

  // Gestion de la valeur par défaut, on est obligé de la faire ici car les sharedList peuvent ne pas être présentent lors du mapping
  useEffect(() => {
    if (
      value &&
      Array.isArray(value) &&
      (value as Choice[]).every((val: Choice) => 'label' in val && 'value' in val) === false
    ) {
      (async () => {
        if (typeof defaultValue !== 'string') return [];
        const list: Choice[] = listChoice ?? (await getChoicesListFromListDef(listDef)) ?? [];
        const newDefaultChoices: Choice[] = choiceListParse(
          defaultValue,
          list,
          SharedListType.Simple,
          CustomLogger.getInstance(),
        );

        setLocalValue(newDefaultChoices);
        debouncedUpdateGlobalState();
      })();
    }
  }, [listChoice, listDef, value, defaultValue, debouncedUpdateGlobalState]);

  const searchLabel = useCallback(
    (valueToSearch: string): void => {
      setOnsearch(true);
      if (listExistedChoise) {
        setCurrentChoices(
          listExistedChoise.filter((l) =>
            l.label.toLowerCase().replace(/\s/g, '').includes(valueToSearch.toLowerCase().replace(/\s/g, '')),
          ),
        );
      }
    },
    [listExistedChoise],
  );

  const handleClear = useCallback((): void => {
    setLabel('');
    setCurrentChoices(choices);
    setOnsearch(false);
  }, [choices]);
  const debouncedSearch = useDebouncedCallback((valueToSearch) => {
    searchLabel(valueToSearch);
  }, debounceTime);
  useEffect(() => {
    if (label) {
      debouncedSearch(label);
    }
  }, [debouncedSearch, label]);

  // useEffect pour les références...
  useEffect(() => {
    if (reference) {
      const newLocalValue =
        value && Array.isArray(value)
          ? (value as unknown[]).every((c: unknown) => isChoice(c))
            ? (value as Choice[])
            : []
          : [];
      setLocalValue((previousLocalState: Choice[]) =>
        newLocalValue !== previousLocalState ? newLocalValue : previousLocalState,
      );
    }
  }, [reference, value]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    updateGlobalState,
    updateLocalState,
    searchLabel,
    label,
    setLabel,
    currentChoices,
    handleClear,
    onSearch,
  };
};

export default useData;
