import mapFieldDesc from '90.quickConnect.Models/mappings/fields/mapFieldDesc';
import { FieldDesc, IncludeDesc } from '90.quickConnect.Models/models';

const mapIncludeDesc = (field: IncludeDesc, templateBodies: Record<string, string>, parentId?: string): IncludeDesc => {
  const { modelId } = field;
  const template = templateBodies[modelId];
  const templateParsed: FieldDesc[] = template ? JSON.parse(template) : [];
  return {
    modelId: modelId,
    items: templateParsed.map((f) => mapFieldDesc(f, parentId ?? field.id, templateBodies)),
  } as IncludeDesc;
};

export default mapIncludeDesc;
