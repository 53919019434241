import React from 'react';
import useData from '20.formLib/DeclarationContainer/AllResolvers/QCScriptsResolver/hooks';
import { QCScriptsResolverProps } from '20.formLib/DeclarationContainer/AllResolvers/QCScriptsResolver/types';

const QCScriptsResolver = ({ children, flattenFields }: QCScriptsResolverProps) => {
  useData(flattenFields);

  return <>{children}</>;
};

export default QCScriptsResolver;
