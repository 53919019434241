import { QCTranslation } from '90.quickConnect.Models/models';

const mapTranslation = (translation: QCTranslation): QCTranslation =>
  ({
    name: translation.name ?? '',
    values: translation.values ?? '',
    isDefault: translation.isDefault ?? '',
    languagesSource: translation.languagesSource ?? '',
    defaultValue: translation.defaultValue ?? '',
  } as QCTranslation);

export default mapTranslation;
