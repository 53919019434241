import { SxProps } from '@mui/system';

export const MainBoxStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  // alignItems: 'center',
  marginRight: '5px',
  marginLeft: '5px',
  paddingTop: '5px',
  paddingBottom: '5px',
  gap: '2em',
} as SxProps;
