import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const useData = (): UseDataProps => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const { t } = useTranslation('declaration');
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleClickTooltip = useCallback(
    (value: string) => {
      const urlRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      if (urlRegex.test(value)) {
        window.open(value);
      } else {
        toast.error(t('tooltip_url', { url: value }).toString());
      }
    },
    [t],
  );

  return {
    open,
    isMobile,
    handleTooltipClose,
    handleTooltipOpen,
    handleClickTooltip,
  };
};

export default useData;
