import React from 'react';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { SvgIcon } from '@mui/material';
import ExternalLinkAltSolidGreySvg from './Shared/icons/externalLinkAltSolidGreySvg';
import ExternalEditRegularSvg from './Shared/icons/externalEditRegularSvg';
import ExternalLinkAltSolidSvg from './Shared/icons/externalLinkAltSolidSvg';
import { FormType, InboxItemType } from '90.quickConnect.Models/enums';
import { dippedGreen500, kraft500 } from '00.app/theme';

export const getFormIcon = (formType: FormType) => {
  switch (formType) {
    case FormType.Template:
      return <ErrorIcon fontSize="inherit" />;
    case FormType.Workflow:
      return <AccountTreeOutlinedIcon fontSize="inherit" />;
    case FormType.Form:
    default:
      return <ContentPasteOutlinedIcon fontSize="inherit" />;
  }
};

export const getDeclarationIcon = (formType: FormType, isDraft: boolean, isLocal: boolean) => {
  switch (formType) {
    case FormType.Template:
      return <ErrorIcon fontSize="inherit" />;
    case FormType.Workflow:
    case FormType.Form:
    default:
      if (isLocal && !isDraft) return <SvgIcon component={ExternalLinkAltSolidGreySvg} fontSize="inherit" />;
      else if (isDraft)
        return <SvgIcon component={() => ExternalEditRegularSvg(kraft500)} fontSize="inherit" viewBox="inherit" />;
      return <SvgIcon component={() => ExternalLinkAltSolidSvg(dippedGreen500)} fontSize="inherit" />;
  }
};

export const getInboxIcon = (inboxType: InboxItemType) => {
  switch (inboxType) {
    case InboxItemType.PrefilledForm:
    case InboxItemType.TransferredPrefilledForm:
      return <ContentPasteGoOutlinedIcon fontSize="inherit" />;
    case InboxItemType.Task:
      return <PlaylistAddCheckIcon fontSize="inherit" />;
    default:
      return <MailOutlineIcon fontSize="inherit" />;
  }
};
