import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FieldRendererName } from './const';
import { FieldRendererProps, UseDataProps } from './types';
import useLabel from '50.quickConnect.Fields/hooks/useLabel';
import { useStore } from '30.quickConnect.Stores';

const useData = ({ field, selectedIndex }: FieldRendererProps): UseDataProps => {
  const { fieldType, id, label, mandatory, errors, isVisible, importance, fullPathId, visible } = field;
  const {
    DeclarationStore: { setCloseDrawer, setVerifRequiredField, requiredField, verifRequiredField },
  } = useStore();
  const labelSpan = useLabel(label, mandatory, `${FieldRendererName}-${id}`, fieldType, errors);
  const [labelClass, setLabelClass] = useState<string>('');
  useEffect(() => {
    if (selectedIndex === undefined) {
      setCloseDrawer();
    }
  }, [selectedIndex, setCloseDrawer]);

  const stopAnimation = useDebouncedCallback(() => {
    setVerifRequiredField(false);
  }, 1000);

  useEffect(() => {
    if (verifRequiredField && requiredField?.fullPathId === fullPathId) {
      setLabelClass('animate__animated animate__shakeX');
      stopAnimation();
    } else {
      setLabelClass('');
    }
  }, [fullPathId, requiredField?.fullPathId, setVerifRequiredField, stopAnimation, verifRequiredField]);

  return {
    fieldType,
    isVisible,
    labelSpan,
    importance,
    labelClass,
    visible,
  };
};

export default useData;
