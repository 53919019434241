import { DigitsDesc } from '90.quickConnect.Models/models';

const mapDigitsDesc = (field: DigitsDesc): DigitsDesc =>
  ({
    min: field.min ?? undefined,
    max: field.max ?? undefined,
    isVertical: field.isVertical,
    value: field.value?.toString() ?? field.defaultValue?.toString() ?? undefined,
    defaultValue: field.defaultValue ?? undefined,
  } as DigitsDesc);

export default mapDigitsDesc;
