import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';
import { getTextEllipsisStyle } from '../TextEllipsisStyle';
import { Report } from '../../types';

export type ReportDialogProps = {
  report: Report;
  onClose: () => void;
};

export function useDialogTitle(reportProps: ReportDialogProps, prefix: string) {
  return (
    <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'} width={'100%'} gap={1}>
      <Tooltip title={reportProps.report.name} placement={'top'}>
        <Typography
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          maxHeight={'5rem'}
          variant={'h4'}
          sx={getTextEllipsisStyle(2)}
        >
          {prefix} - {reportProps.report.name}
        </Typography>
      </Tooltip>
      <IconButton onClick={reportProps.onClose}>
        <Close />
      </IconButton>
    </Stack>
  );
}
