import { useCallback } from 'react';

import { FixedAttachment } from '90.quickConnect.Models/models/fields/fieldDesc/attachmentField/fixedAttachmentDesc';

const useData = ({ distantUri = '', fileName = '', type = '' }: FixedAttachment) => {
  const onOpenAttachment = useCallback(() => {
    let link = '';

    // If it's a word, powerpoint, or excel, open the file using the office viewer
    if (/.?(word|powerpoint|excel)/.test(type)) {
      link = 'https://view.officeapps.live.com/op/view.aspx?src=';
    }
    link += encodeURI(distantUri);

    window.open(link);
  }, [distantUri, type]);

  return { distantUri, fileName, onOpenAttachment, type };
};

export default useData;
