import { EntityData } from '90.quickConnect.Models/models';

export enum EquipmentTabs {
  Forms = 'Forms',
  Attributs = 'Attributs',
  Documents = 'Documents',
  Historic = 'Historic',
}
export type UseDataProps = {
  entityId: string | undefined;
  entityData: EntityData | undefined;
  selectedTab: EquipmentTabs;
  handleTabChange: (event: React.SyntheticEvent, newValue: EquipmentTabs) => void;
  schemasIds: string[];
};
