import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';

const useData = (
  setOpenCommands: Dispatch<SetStateAction<boolean>>,
  setOpenHistory: Dispatch<SetStateAction<boolean>>,
): UseDataProps => {
  const { t } = useTranslation('declaration');

  const onHistoryWorkflow = useCallback(async () => {
    setOpenCommands(false);
    setOpenHistory(true);
  }, [setOpenCommands, setOpenHistory]);

  return {
    t,
    onHistoryWorkflow,
  };
};

export default useData;
