import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, IconButton, InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import useData from './hooks';
import { DigitsQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { DigitsDesc } from '90.quickConnect.Models/models';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';

const DigitsQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  labelClass,
}: ValueFieldsTypesProps) => {
  const digitsDesc = field as DigitsDesc;
  const { id, toolTip, fullPathId } = digitsDesc;
  const nameWithId = `${DigitsQcFieldName}-${id}`;
  const { t, localValue, clearState, handleChange, verifRequiredField, requiredField, fieldFocused, onBlur } = useData(
    digitsDesc,
    updateDeclaration,
    flattenFields,
  );

  const { breakpoints } = useTheme();

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      sx={FieldMainBox(breakpoints)}
      data-cy={nameWithId}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <OutlinedInput
        inputRef={(input) => {
          if (input != null && verifRequiredField && requiredField?.fullPathId === fullPathId) {
            input.focus();
          } else if (
            input != null &&
            !verifRequiredField &&
            !requiredField &&
            fieldFocused &&
            fullPathId === fieldFocused
          ) {
            return input.focus();
          }
        }}
        onBlur={onBlur}
        data-cy={`${nameWithId}-input`}
        id={`${nameWithId}-input`}
        type={'text'}
        value={localValue}
        required
        onChange={handleChange}
        inputProps={{
          readOnly: isReadOnly,
          disabled: isReadOnly,
        }}
        placeholder={t("input_number'").toString()}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={t('formlib_array_deleted_title').toString()}>
              <span>
                <IconButton disabled={isReadOnly} data-cy={`${nameWithId}-clear`} edge="end" onClick={clearState}>
                  <ClearIcon />
                </IconButton>
              </span>
            </Tooltip>
          </InputAdornment>
        }
      />
    </Box>
  );
};
export default observer(DigitsQcField);
