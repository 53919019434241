import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DialogChoice from '10.quickConnect.app/components/shared/Dialogs/DialogChoice';
import { SendingAttachmentsDialogName } from '10.quickConnect.app/components/shared/Dialogs/SendingAttachmentsDialog/const';
import useData from '10.quickConnect.app/components/shared/Dialogs/SendingAttachmentsDialog/hooks';

import LinearProgressWithLabel from '10.quickConnect.app/components/shared/LinearProgress';
import { DialogChoiceName } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/const';

const SendingAttachmentsDialog = () => {
  const { t } = useTranslation(['declaration', 'axios', 'forms']);
  const { open, onClose, attachmentsToSend, progress } = useData();

  return (
    <DialogChoice name={SendingAttachmentsDialogName} open={open} onClose={onClose}>
      <DialogChoice.Title name={SendingAttachmentsDialogName}>
        {t('job_message_synchronize_declaration').toString()}
      </DialogChoice.Title>
      <DialogChoice.Content name={SendingAttachmentsDialogName}>
        <DialogChoice.ContentText name={SendingAttachmentsDialogName}>
          <LinearProgressWithLabel value={progress} />
          {t('qcapp_warning_cannot_logout_nbr_attachement', { ns: 'axios', count: attachmentsToSend })}
        </DialogChoice.ContentText>
      </DialogChoice.Content>
      <DialogChoice.Actions name={SendingAttachmentsDialogName}>
        <Button
          data-cy={`${DialogChoiceName}-${SendingAttachmentsDialogName}-close`}
          onClick={onClose}
          variant="contained"
        >
          {t('qcapp_dialog_item_data_close', { ns: 'forms' }).toString()}{' '}
        </Button>
      </DialogChoice.Actions>
    </DialogChoice>
  );
};

export default observer(SendingAttachmentsDialog);
