export enum PackStatus {
  TOCOMPLETE,
  WAITING,
  SYNCING,
  COMPLETED,
}

export type Form = {
  id: string;
  name: string;
  completed: boolean;
};

interface PackReportCommonProps {
  name: string;
  id: string;
  selected: boolean;
}

export interface Pack extends PackReportCommonProps {
  status: PackStatus;
  nbForm: number;
}

export interface Report extends PackReportCommonProps {
  description: string;
  nbMissingReports: number;
  previewPictures: Array<string>; // Url Array
  forms: { [key: string]: Array<Form> }; // Sorted by pack
}

export type WorkSiteProps = {
  //General Info
  id?: string;
  name?: string;
  agency?: string;
  postalCode?: string;
  sharePointSiteName?: string;
  sharePointLibraryName?: string;
  logo?: string | File;

  //Packs
  standardPacks?: Array<Pack>;
  otherPacks?: Array<Pack>;

  //Reports
  reports?: Array<Report>;
  // Front attrs
  hasChanged: boolean;
  // TODO KTY append the data here for more props, all optional, will be used as local storage too
};

export enum WorkSiteActType {
  ID,
  NAME,
  AGENCY,
  POSTCODE,
  SHAREPOINTSITENAME,
  SHAREPOINTLIBRARYNAME,
  LOGO,

  //Packs
  PACKSINGLE,
  STANDARDPACKS,
  OTHERPACKS,
  SELECTPACK,

  //Reports
  REPORTS,
  SELECTREPORT,
}

type WorkSiteStringAct = {
  type:
    | WorkSiteActType.ID
    | WorkSiteActType.NAME
    | WorkSiteActType.AGENCY
    | WorkSiteActType.POSTCODE
    | WorkSiteActType.SHAREPOINTSITENAME
    | WorkSiteActType.SHAREPOINTLIBRARYNAME;
  value?: string;
};
type WorkSiteFileAct = { type: WorkSiteActType.LOGO; value?: File };
type WorkSitePackArrayAct = { type: WorkSiteActType.STANDARDPACKS | WorkSiteActType.OTHERPACKS; value: Array<Pack> };
type WorkSitePackAct = { type: WorkSiteActType.PACKSINGLE; value: { id: Pack['id']; pack: Partial<Pack> } };
type WorkSitePackSelectAct = {
  type: WorkSiteActType.SELECTPACK | WorkSiteActType.SELECTREPORT;
  value: { id: PackReportCommonProps['id']; selected: boolean };
};
type WorkSiteReportArrayAct = { type: WorkSiteActType.REPORTS; value: Array<Report> };

export type WorkSiteAct =
  | WorkSiteStringAct
  | WorkSiteFileAct
  | WorkSitePackArrayAct
  | WorkSitePackAct
  | WorkSitePackSelectAct
  | WorkSiteReportArrayAct;

const changePackAttribute = (workSite: WorkSiteProps, packId: Pack['id'], value: Partial<Pack>) => {
  let key: 'standardPacks' | 'otherPacks' = 'standardPacks';
  let packIdx = workSite.standardPacks && workSite.standardPacks.findIndex((p) => p.id === packId);
  if (packIdx === -1 && workSite.otherPacks) {
    key = 'otherPacks';
    packIdx = workSite.otherPacks.findIndex((p) => p.id === packId);
  }

  if (packIdx === -1) {
    throw Error(`Unable to find pack with Id ${packId}`);
  }

  const array =
    (workSite?.[key] as WorkSiteProps['standardPacks'] | WorkSiteProps['otherPacks'])?.map((element, idx) => {
      if (idx === packIdx) {
        return { ...element, ...value };
      }
      return element;
    }) || workSite?.[key];
  return { ...workSite, [key]: array };
};

function changeReportAttribute(workSite: WorkSiteProps, id: Report['id'], value: Partial<Report>) {
  const array = workSite.reports?.map((report) => {
    if (report.id === id) {
      return { ...report, ...value };
    }
    return report;
  });

  return { ...workSite, reports: array };
}

export const workSiteReducer = (state: WorkSiteProps, action: WorkSiteAct) => {
  const modifiedState = { ...state, hasChanged: true };
  switch (action.type) {
    case WorkSiteActType.ID:
      return { ...modifiedState, id: action.value };
    case WorkSiteActType.NAME:
      return { ...modifiedState, name: action.value };
    case WorkSiteActType.AGENCY:
      return { ...modifiedState, agency: action.value };
    case WorkSiteActType.POSTCODE:
      return { ...modifiedState, postalCode: action.value };
    case WorkSiteActType.SHAREPOINTSITENAME:
      return { ...modifiedState, sharePointSiteName: action.value };
    case WorkSiteActType.SHAREPOINTLIBRARYNAME:
      return { ...modifiedState, sharePointLibraryName: action.value };
    case WorkSiteActType.LOGO:
      return { ...modifiedState, logo: action.value };
    case WorkSiteActType.STANDARDPACKS:
      return { ...modifiedState, standardPacks: action.value };
    case WorkSiteActType.OTHERPACKS:
      return { ...modifiedState, otherPacks: action.value };
    case WorkSiteActType.SELECTPACK:
      return changePackAttribute(modifiedState, action.value.id, { selected: action.value.selected });
    case WorkSiteActType.REPORTS:
      return { ...modifiedState, reports: action.value };
    case WorkSiteActType.SELECTREPORT:
      return changeReportAttribute(modifiedState, action.value.id, { selected: action.value.selected });
    default:
      return modifiedState;
  }
};
