import bigDecimal from 'js-big-decimal';
import { QCSBaseObject } from '../IL/QCSObject/QCSBaseObject';
import { QCSDouble } from '../IL/QCSObject/QCSDouble';
import { QCSInt } from '../IL/QCSObject/QCSInt';
import { QCSLong } from '../IL/QCSObject/QCSLong';
import { IQCSModule } from './IQCSModule';
import { RoundingModes } from '90.quickConnect.Models/enums';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

// Résumé:
//     Represents the ratio of the circumference of a circle to its diameter, specified
//     by the constant, π.
export const PI = 3.1415926535897931;

//
// Résumé:
//     Represents the natural logarithmic base, specified by the constant, e.
export const E = 2.7182818284590451;

const curryCeilFloorRoundWithDecimals =
  (fn: (value: number) => number) =>
  (num: number, dec: number): number => {
    return fn(num * Math.pow(10, dec)) / Math.pow(10, dec);
  };

export class QCSMath implements IQCSModule {
  // Tag
  private static readonly TAG =
    '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/QCInterpreter.implemen/QCSMath.ts';
  //
  // Résumé:
  //     Returns the absolute value of a double-precision floating-point number.
  //
  // Paramètres:
  //   value:
  //     A number that is greater than or equal to System.Double.MinValue, but less than
  //     or equal to System.Double.MaxValue.
  //
  // Retourne:
  //     A double-precision floating-point number, x, such that 0 ≤ x ≤System.Double.MaxValue.
  public static abs(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.abs(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.abs(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.abs(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the angle whose cosine is the specified number.
  //
  // Paramètres:
  //   d:
  //     A number representing a cosine, where d must be greater than or equal to -1,
  //     but less than or equal to 1.
  //
  // Retourne:
  //     An angle, θ, measured in radians, such that 0 ≤θ≤π -or- System.Double.NaN if
  //     d &lt; -1 or d &gt; 1 or d equals System.Double.NaN.
  public static acos(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.acos(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.acos(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.acos(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  public static acosh(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.acosh(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.acosh(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.acosh(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  public static asin(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.asin(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.asin(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.asin(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  public static asinh(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.asinh(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.asinh(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.asinh(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }
  //
  // Résumé:
  //     Returns the angle whose tangent is the specified number.
  //
  // Paramètres:
  //   d:
  //     A number representing a tangent.
  //
  // Retourne:
  //     An angle, θ, measured in radians, such that -π/2 ≤θ≤π/2. -or- System.Double.NaN
  //     if d equals System.Double.NaN, -π/2 rounded to double precision (-1.5707963267949)
  //     if d equals System.Double.NegativeInfinity, or π/2 rounded to double precision
  //     (1.5707963267949) if d equals System.Double.PositiveInfinity.

  public static atan(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.atan(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.atan(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.atan(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the angle whose tangent is the quotient of two specified numbers.
  //
  // Paramètres:
  //   y:
  //     The y coordinate of a point.
  //
  //   x:
  //     The x coordinate of a point.
  //
  // Retourne:
  //     An angle, θ, measured in radians, such that -π≤θ≤π, and tan(θ) = y / x, where
  //     (x, y) is a point in the Cartesian plane. Observe the following: For (x, y) in
  //     quadrant 1, 0 &lt; θ &lt; π/2. For (x, y) in quadrant 2, π/2 &lt; θ≤π. For (x,
  //     y) in quadrant 3, -π &lt; θ &lt; -π/2. For (x, y) in quadrant 4, -π/2 &lt; θ
  //     &lt; 0. For points on the boundaries of the quadrants, the return value is the
  //     following: If y is 0 and x is not negative, θ = 0. If y is 0 and x is negative,
  //     θ = π. If y is positive and x is 0, θ = π/2. If y is negative and x is 0, θ =
  //     -π/2. If y is 0 and x is 0, θ = 0. If x or y is System.Double.NaN, or if x and
  //     y are either System.Double.PositiveInfinity or System.Double.NegativeInfinity,
  //     the method returns System.Double.NaN.
  public static atan2(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (
      (qcParam instanceof QCSDouble || qcParam instanceof QCSInt) &&
      (qcParam2 instanceof QCSDouble || qcParam2 instanceof QCSInt)
    ) {
      const result = Math.atan2(qcParam.value, qcParam2.value);

      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  public static atanh(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.atanh(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the smallest integral value that is greater than or equal to the specified
  //     double-precision floating-point number.
  //
  // Paramètres:
  //   a:
  //     A double-precision floating-point number.
  //
  // Retourne:
  //     The smallest integral value that is greater than or equal to a. If a is equal
  //     to System.Double.NaN, System.Double.NegativeInfinity, or System.Double.PositiveInfinity,
  //     that value is returned. Note that this method returns a System.Double instead
  //     of an integral type.
  public static ceiling(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.ceil(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.ceil(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.ceil(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the cosine of the specified angle.
  //
  // Paramètres:
  //   d:
  //     An angle, measured in radians.
  //
  // Retourne:
  //     The cosine of d. If d is equal to System.Double.NaN, System.Double.NegativeInfinity,
  //     or System.Double.PositiveInfinity, this method returns System.Double.NaN.
  public static cos(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.cos(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.cos(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.cos(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the hyperbolic cosine of the specified angle.
  //
  // Paramètres:
  //   value:
  //     An angle, measured in radians.
  //
  // Retourne:
  //     The hyperbolic cosine of value. If value is equal to System.Double.NegativeInfinity
  //     or System.Double.PositiveInfinity, System.Double.PositiveInfinity is returned.
  //     If value is equal to System.Double.NaN, System.Double.NaN is returned.
  public static cosh(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.cosh(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.cosh(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = Math.cosh(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns e raised to the specified power.
  //
  // Paramètres:
  //   d:
  //     A number specifying a power.
  //
  // Retourne:
  //     The number e raised to the power d. If d equals System.Double.NaN or System.Double.PositiveInfinity,
  //     that value is returned. If d equals System.Double.NegativeInfinity, 0 is returned.
  public static exp(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      const result = Math.exp(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }

    if (qcParam instanceof QCSInt) {
      const result = Math.exp(qcParam.value);
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }

    if (qcParam instanceof QCSLong) {
      const result = BigInt(Math.exp(Number(qcParam.value)));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSLong(BigInt(result));
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the largest integer less than or equal to the specified double-precision
  //     floating-point number.
  //
  // Paramètres:
  //   d:
  //     A double-precision floating-point number.
  //
  // Retourne:
  //     The largest integer less than or equal to d. If d is equal to System.Double.NaN,
  //     System.Double.NegativeInfinity, or System.Double.PositiveInfinity, that value
  //     is returned.
  public static floor(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble) {
      return new QCSDouble(Math.floor(qcParam.value));
    }

    if (qcParam instanceof QCSInt) {
      return new QCSInt(Math.floor(qcParam.value));
    }

    if (qcParam instanceof QCSLong) {
      return new QCSLong(BigInt(Math.floor(Number(qcParam.value))));
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the remainder resulting from the division of a specified number by another
  //     specified number.
  //
  // Paramètres:
  //   x:
  //     A dividend.
  //
  //   y:
  //     A divisor.
  //
  // Retourne:
  //     A number equal to x - (y Q), where Q is the quotient of x / y rounded to the
  //     nearest integer (if x / y falls halfway between two integers, the even integer
  //     is returned). If x - (y Q) is zero, the value +0 is returned if x is positive,
  //     or -0 if x is negative. If y = 0, System.Double.NaN is returned.
  public static IEEERemainder(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (
      (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) &&
      (qcParam2 instanceof QCSDouble || qcParam2 instanceof QCSInt || qcParam2 instanceof QCSLong)
    ) {
      const result =
        Number(qcParam.value) - Number(qcParam2.value) * Math.round(Number(qcParam.value) / Number(qcParam2.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the natural (base e) logarithm of a specified number.
  //
  // Paramètres:
  //   d:
  //     The number whose logarithm is to be found.
  //
  //   newBase (Optional):
  //     The base of the logarithm.
  //
  // Retourne:
  //     One of the values in the following table. d parameter Return value Positive The
  //     natural logarithm of d; that is, ln d, or log e d Zero System.Double.NegativeInfinity
  //     Negative System.Double.NaN Equal to System.Double.NaNSystem.Double.NaN Equal
  //     to System.Double.PositiveInfinitySystem.Double.PositiveInfinity
  public static log(qcParam: QCSBaseObject, qcParam2?: QCSBaseObject): QCSBaseObject {
    if (
      qcParam2 &&
      (qcParam2 instanceof QCSDouble || qcParam2 instanceof QCSInt || qcParam2 instanceof QCSLong) &&
      (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong)
    ) {
      const result = Math.log(Number(qcParam.value)) / Math.log(Number(qcParam2.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    } else if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.log(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the base 10 logarithm of a specified number.
  //
  // Paramètres:
  //   d:
  //     A number whose logarithm is to be found.
  //
  // Retourne:
  //     One of the values in the following table. d parameter Return value Positive The
  //     base 10 log of d; that is, log 10d. Zero System.Double.NegativeInfinity Negative
  //     System.Double.NaN Equal to System.Double.NaNSystem.Double.NaN Equal to System.Double.PositiveInfinitySystem.Double.PositiveInfinity
  public static log10(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.log10(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the larger of two 32-bit signed integers.
  //
  // Paramètres:
  //   val1:
  //     The first of two 32-bit signed integers to compare.
  //
  //   val2:
  //     The second of two 32-bit signed integers to compare.
  //
  // Retourne:
  //     Parameter val1 or val2, whichever is larger.
  public static maxInt(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSInt && qcParam2 instanceof QCSInt) {
      return new QCSInt(Math.max(qcParam.value, qcParam2.value));
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the larger of two double-precision floating-point numbers.
  //
  // Paramètres:
  //   val1:
  //     The first of two double-precision floating-point numbers to compare.
  //
  //   val2:
  //     The second of two double-precision floating-point numbers to compare.
  //
  // Retourne:
  //     Parameter val1 or val2, whichever is larger. If val1, val2, or both val1 and
  //     val2 are equal to System.Double.NaN, System.Double.NaN is returned.
  public static maxDouble(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble && qcParam2 instanceof QCSInt) {
      return new QCSDouble(Math.max(qcParam.value, qcParam2.value));
    }

    if (qcParam instanceof QCSDouble && qcParam2 instanceof QCSDouble) {
      return new QCSDouble(Math.max(qcParam.value, qcParam2.value));
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the smaller of two double-precision floating-point numbers.
  //
  // Paramètres:
  //   val1:
  //     The first of two double-precision floating-point numbers to compare.
  //
  //   val2:
  //     The second of two double-precision floating-point numbers to compare.
  //
  // Retourne:
  //     Parameter val1 or val2, whichever is smaller. If val1, val2, or both val1 and
  //     val2 are equal to System.Double.NaN, System.Double.NaN is returned.
  public static minInt(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSInt && qcParam2 instanceof QCSInt) {
      return new QCSInt(Math.min(qcParam.value, qcParam2.value));
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the smaller of two 32-bit signed integers.
  //
  // Paramètres:
  //   val1:
  //     The first of two 32-bit signed integers to compare.
  //
  //   val2:
  //     The second of two 32-bit signed integers to compare.
  //
  // Retourne:
  //     Parameter val1 or val2, whichever is smaller.
  public static minDouble(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble && qcParam2 instanceof QCSInt) {
      return new QCSDouble(Math.min(qcParam.value, qcParam2.value));
    }

    if (qcParam instanceof QCSDouble && qcParam2 instanceof QCSDouble) {
      return new QCSDouble(Math.min(qcParam.value, qcParam2.value));
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns a specified number raised to the specified power.
  //
  // Paramètres:
  //   x:
  //     A double-precision floating-point number to be raised to a power.
  //
  //   y:
  //     A double-precision floating-point number that specifies a power.
  //
  // Retourne:
  //     The number x raised to the power y.
  public static pow(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble && (qcParam2 instanceof QCSInt || qcParam2 instanceof QCSDouble)) {
      const result: number = Math.pow(qcParam.value, qcParam2.value);

      if (!Number.isNaN(result) && Number.isFinite(result)) return new QCSDouble(result);
    } else if (qcParam instanceof QCSInt && (qcParam2 instanceof QCSInt || qcParam2 instanceof QCSDouble)) {
      const result: number = Math.pow(qcParam.value, qcParam2.value);

      if (!Number.isNaN(result) && Number.isFinite(result)) {
        return result < Number.MAX_SAFE_INTEGER && result > Number.MIN_SAFE_INTEGER
          ? qcParam2 instanceof QCSInt
            ? new QCSInt(result)
            : new QCSDouble(result)
          : new QCSLong(BigInt(result));
      }
    } else if (qcParam instanceof QCSLong && (qcParam2 instanceof QCSInt || qcParam2 instanceof QCSDouble)) {
      const result = BigInt(Math.pow(Number(qcParam.value), qcParam2.value));

      if (!Number.isNaN(result) && Number.isFinite(result)) {
        return result < Number.MAX_SAFE_INTEGER && result > Number.MIN_SAFE_INTEGER
          ? qcParam2 instanceof QCSInt
            ? new QCSInt(Number(result))
            : new QCSDouble(Number(result))
          : new QCSLong(result);
      }
    }

    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Rounds a double-precision floating-point value to the nearest integer. A parameter
  //     specifies how to round the value if it is midway between two numbers.
  //
  // Paramètres:
  //   value:
  //     A double-precision floating-point number to be rounded.
  //
  //   mode:
  //     Specification for how to round value if it is midway between two other numbers.
  //
  // Retourne:
  //     The integer nearest value. If value is halfway between two integers, one of which
  //     is even and the other odd, then mode determines which of the two is returned.
  //
  // Exceptions:
  //   T:System.ArgumentException:
  //     mode is not a valid value of System.MidpointRounding.
  public static roundMid(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble && qcParam2 instanceof QCSInt) {
      switch (qcParam2.value) {
        case 0: // MidpointRounding.ToEven
          const twoLastnumberStr = qcParam.value.toString().slice(-2);

          if (twoLastnumberStr === '50') {
            return Math.floor(qcParam.value) % 2 === 0
              ? new QCSDouble(Math.floor(qcParam.value))
              : new QCSDouble(Math.ceil(qcParam.value));
          } else {
            return new QCSDouble(Math.round(qcParam.value));
          }

        case 1: // MidpointRounding.AwayFromZero
          return new QCSDouble(Math.round(qcParam.value));

        case 2: // MidpointRounding.ToZero
          return qcParam.value > 0 ? new QCSDouble(Math.floor(qcParam.value)) : new QCSDouble(Math.ceil(qcParam.value));

        case 3: // MidpointRounding.ToNegativeInfinity
          return new QCSDouble(Math.floor(qcParam.value));

        case 4: // MidpointRounding.ToPositiveInfinity
          return new QCSDouble(Math.ceil(qcParam.value));
      }
      return QCSBaseObject.QCSNull;
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Rounds a double-precision floating-point value to a specified number of fractional
  //     digits. A parameter specifies how to round the value if it is midway between
  //     two numbers.
  //
  // Paramètres:
  //   value:
  //     A double-precision floating-point number to be rounded.
  //
  //   digits:
  //     The number of fractional digits in the return value.
  //
  //   mode:
  //     Specification for how to round value if it is midway between two other numbers.
  //
  // Retourne:
  //     The number nearest to value that has a number of fractional digits equal to digits.
  //     If value has fewer fractional digits than digits, value is returned unchanged.
  //
  // Exceptions:
  //   T:System.ArgumentOutOfRangeException:
  //     digits is less than 0 or greater than 15.
  //
  //   T:System.ArgumentException:
  //     mode is not a valid value of System.MidpointRounding.
  public static roundDigMid(qcParam: QCSBaseObject, qcParam2: QCSBaseObject, qcParam3: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble && qcParam2 instanceof QCSInt && qcParam3 instanceof QCSInt) {
      switch (qcParam3.value) {
        case 0: // MidpointRounding.ToEven
          const twoLastnumberStr = qcParam.value.toString().slice(-2);

          if (twoLastnumberStr === '50') {
            return curryCeilFloorRoundWithDecimals(Math.floor)(qcParam.value, qcParam2.value) % 2 === 0
              ? new QCSDouble(curryCeilFloorRoundWithDecimals(Math.floor)(qcParam.value, qcParam2.value))
              : new QCSDouble(curryCeilFloorRoundWithDecimals(Math.ceil)(qcParam.value, qcParam2.value));
          } else {
            return new QCSDouble(curryCeilFloorRoundWithDecimals(Math.round)(qcParam.value, qcParam2.value));
          }

        case 1: // MidpointRounding.AwayFromZero
          return new QCSDouble(curryCeilFloorRoundWithDecimals(Math.ceil)(qcParam.value, qcParam2.value));

        case 2: // MidpointRounding.ToZero
          return qcParam.value > 0
            ? new QCSDouble(curryCeilFloorRoundWithDecimals(Math.floor)(qcParam.value, qcParam2.value))
            : new QCSDouble(curryCeilFloorRoundWithDecimals(Math.ceil)(qcParam.value, qcParam2.value));

        case 3: // MidpointRounding.ToNegativeInfinity
          return new QCSDouble(curryCeilFloorRoundWithDecimals(Math.floor)(qcParam.value, qcParam2.value));

        case 4: // MidpointRounding.ToPositiveInfinity
          return new QCSDouble(curryCeilFloorRoundWithDecimals(Math.ceil)(qcParam.value, qcParam2.value));
      }
      return QCSBaseObject.QCSNull;
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Rounds a double-precision floating-point value to a specified number of fractional
  //     digits.
  //
  // Paramètres:
  //   value:
  //     A double-precision floating-point number to be rounded.
  //
  //   digits:
  //     The number of fractional digits in the return value.
  //
  // Retourne:
  //     The number nearest to value that contains a number of fractional digits equal
  //     to digits.
  //
  // Exceptions:
  //   T:System.ArgumentOutOfRangeException:
  //     digits is less than 0 or greater than 15.
  public static roundDig(qcParam: QCSBaseObject, qcParam2: QCSBaseObject): QCSBaseObject {
    if ((qcParam instanceof QCSDouble || qcParam instanceof QCSInt) && qcParam2 instanceof QCSInt) {
      return new QCSDouble(curryCeilFloorRoundWithDecimals(Math.round)(qcParam.value, qcParam2.value));
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Rounds a double-precision floating-point value to the nearest integral value.
  //
  // Paramètres:
  //   a:
  //     A double-precision floating-point number to be rounded.
  //
  // Retourne:
  //     The integer nearest a. If the fractional component of a is halfway between two
  //     integers, one of which is even and the other odd, then the even number is returned.
  //     Note that this method returns a System.Double instead of an integral type.
  public static round(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      let bd = new bigDecimal(qcParam.value.toString());
      bd = bd.round(0, RoundingModes.HALF_UP);

      if (Number(bd.getValue()) >= Number.MIN_SAFE_INTEGER && Number(bd.getValue()) <= Number.MAX_SAFE_INTEGER) {
        return new QCSInt(Number(bd.getValue()));
      }
    }
    return QCSBaseObject.QCSNull;
  }
  //
  // Résumé:
  //     Returns an integer that indicates the sign of a double-precision floating-point
  //     number.
  //
  // Paramètres:
  //   value:
  //     A signed number.
  //
  // Retourne:
  //     A number that indicates the sign of value, as shown in the following table. Return
  //     value Meaning -1 value is less than zero. 0 value is equal to zero. 1 value is
  //     greater than zero.
  //
  // Exceptions:
  //   T:System.ArithmeticException:
  //     value is equal to System.Double.NaN.

  public static sign(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam === QCSBaseObject.QCSNull) return QCSBaseObject.QCSNull;

    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.sign(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSInt(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the sine of the specified angle.
  //
  // Paramètres:
  //   a:
  //     An angle, measured in radians.
  //
  // Retourne:
  //     The sine of a. If a is equal to System.Double.NaN, System.Double.NegativeInfinity,
  //     or System.Double.PositiveInfinity, this method returns System.Double.NaN.
  public static sin(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.sin(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the hyperbolic sine of the specified angle.
  //
  // Paramètres:
  //   value:
  //     An angle, measured in radians.
  //
  // Retourne:
  //     The hyperbolic sine of value. If value is equal to System.Double.NegativeInfinity,
  //     System.Double.PositiveInfinity, or System.Double.NaN, this method returns a System.Double
  //     equal to value.
  public static sinh(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.sinh(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the square root of a specified number.
  //
  // Paramètres:
  //   d:
  //     The number whose square root is to be found.
  //
  // Retourne:
  //     One of the values in the following table. d parameter Return value Zero or positive
  //     The positive square root of d. Negative System.Double.NaN Equals System.Double.NaNSystem.Double.NaN
  //     Equals System.Double.PositiveInfinitySystem.Double.PositiveInfinity
  public static sqrt(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.sqrt(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the tangent of the specified angle.
  //
  // Paramètres:
  //   a:
  //     An angle, measured in radians.
  //
  // Retourne:
  //     The tangent of a. If a is equal to System.Double.NaN, System.Double.NegativeInfinity,
  //     or System.Double.PositiveInfinity, this method returns System.Double.NaN.
  public static tan(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.tan(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Returns the hyperbolic tangent of the specified angle.
  //
  // Paramètres:
  //   value:
  //     An angle, measured in radians.
  //
  // Retourne:
  //     The hyperbolic tangent of value. If value is equal to System.Double.NegativeInfinity,
  //     this method returns -1. If value is equal to System.Double.PositiveInfinity,
  //     this method returns 1. If value is equal to System.Double.NaN, this method returns
  //     System.Double.NaN.
  public static tanh(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = Math.tanh(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  //
  // Résumé:
  //     Calculates the integral part of a specified double-precision floating-point number.
  //
  // Paramètres:
  //   d:
  //     A number to truncate.
  //
  // Retourne:
  //     The integral part of d; that is, the number that remains after any fractional
  //     digits have been discarded, or one of the values listed in the following table.
  //     d Return value System.Double.NaNSystem.Double.NaNSystem.Double.NegativeInfinitySystem.Double.NegativeInfinitySystem.Double.PositiveInfinitySystem.Double.PositiveInfinity
  public static truncate(qcParam: QCSBaseObject): QCSBaseObject {
    if (qcParam instanceof QCSDouble || qcParam instanceof QCSInt || qcParam instanceof QCSLong) {
      const result = this.truncateSafely(Number(qcParam.value));
      return Number.isNaN(result) || !Number.isFinite(result) ? QCSBaseObject.QCSNull : new QCSDouble(result);
    }
    return QCSBaseObject.QCSNull;
  }

  static truncateSafely(value: number): number {
    return value < 0 ? Math.ceil(value) : Math.floor(value);
  }

  public callQCSModule(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    const logger: CustomLogger = CustomLogger.getInstance();
    logger.log(QCSMath.TAG, 'Call Method not implemented on module QCSMath');
    return null;
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    // eslint-disable
    const [fParam] = qcParams;
    switch (methodId) {
      case 1: // Absolute
        return this.abs(fParam);
      case 2: // Acos
        return this.acos(fParam);
      case 3: // Acosh
        return this.acosh(fParam);
      case 4: // Asin
        return this.asin(fParam);
      case 5: // Asinh
        return this.asinh(fParam);
      case 6: // Atan
        return this.atan(fParam);
      case 7: // Atan2
        return this.atan2(fParam, qcParams[1]);
      case 8: // Atanh
        return this.atanh(fParam);
      case 9: // Ceiling
        return this.ceiling(fParam);
      case 10: // Cos
        return this.cos(fParam);
      case 11: // Cosh
        return this.cosh(fParam);
      case 12: // Exp
        return this.exp(fParam);
      case 13: // Floor
        return this.floor(fParam);
      case 14: // IEEERemainder
        return this.IEEERemainder(fParam, qcParams[1]);
      case 15: // Log
        return this.log(fParam);
      case 16: // Log new base
        return this.log(fParam, qcParams[1]);
      case 17: // Log10
        return this.log10(fParam);
      case 18: // Max int
        return this.maxInt(fParam, qcParams[1]);
      case 19: // Max double
        return this.maxDouble(fParam, qcParams[1]);
      case 20: // Min int
        return this.minInt(fParam, qcParams[1]);
      case 21: // Min double
        return this.minDouble(fParam, qcParams[1]);
      case 22: // Round Mid
        return this.roundMid(fParam, qcParams[1]);
      case 23: // Round Digit Mid
        return this.roundDigMid(fParam, qcParams[1], qcParams[2]);
      case 24: // Round Digit
        return this.roundDig(fParam, qcParams[1]);
      case 25: // Round
        return this.round(fParam);
      case 26: // Sign
        return this.sign(fParam);
      case 27: // Sin
        return this.sin(fParam);
      case 28: // Sinh
        return this.sinh(fParam);
      case 29: // Sqrt
        return this.sqrt(fParam);
      case 30: // Tan
        return this.tan(fParam);
      case 31: // Tanh
        return this.tanh(fParam);
      case 32: // Truncate
        return this.truncate(fParam);
      case 33: // Pow
        return this.pow(fParam, qcParams[1]);
    }
    return null;
  }
}
