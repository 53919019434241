import { useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from '10.quickConnect.app/components/domain/Home/Declarations/types';
import { useStore } from '30.quickConnect.Stores';

const useData = (): UseDataProps => {
  const { t } = useTranslation();

  // On récupère le store
  const {
    UserSettingsStore: { declarationsAsTree },
    CommonStore: { setWorkInProgress, setWorkDone },
    DeclarationStore: { getDeclarationList },
    LoginStore: {
      signInInfos: { userUPN },
    },
  } = useStore();

  const getDeclarationsJob = useMemo(
    () => [uuidv4(), t('declaration:jobMessage.getDeclarationsJob')] as [string, string],
    [t],
  );

  const shouldGetDeclarationsList = useRef<boolean>(true);

  useEffect(() => {
    if (shouldGetDeclarationsList.current) {
      setWorkInProgress(getDeclarationsJob);
      getDeclarationList(userUPN).finally(() => {
        setWorkDone(getDeclarationsJob[0]);
      });
      shouldGetDeclarationsList.current = false;
    }
  }, [getDeclarationList, getDeclarationsJob, setWorkDone, setWorkInProgress, userUPN]);

  return { declarationsAsTree };
};

export default useData;
