import { QCNotification } from '90.quickConnect.Models/models';
import { NotificationType } from '90.quickConnect.Models/enums';

const mapQCNotification = ({
  autoSend,
  notificationType,
  searchAllUO,
  selectedTargets,
  sendToMe,
  subject,
}: Partial<QCNotification>): QCNotification => ({
  autoSend: autoSend ?? true,
  notificationType: notificationType ?? NotificationType.Email,
  searchAllUO: searchAllUO ?? false,
  selectedTargets: selectedTargets ?? [],
  sendToMe: sendToMe ?? false,
  subject: subject ?? null,
});

export default mapQCNotification;
