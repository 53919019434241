import { FieldType, Mandatory } from '90.quickConnect.Models/enums';
import { isEmptyFieldValue } from '90.quickConnect.Models/guards';
import { FieldDesc } from '90.quickConnect.Models/models';

export default function fieldIsMandatory({ items, fieldType, mandatory, isVisible, value }: FieldDesc): boolean {
  if (!items || items.length === 0)
    return (
      mandatory === Mandatory.Required && isEmptyFieldValue(value) && fieldType !== FieldType.CheckBox && isVisible
    );

  return items.some(fieldIsMandatory);
}
