import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';

/**
 * Outils de manipulation des chaine
 */
export class StringExtension {
  public static isNullOrEmpty(input: string | null | undefined): boolean {
    return !input || (typeof input === 'string' && input === '');
  }
  public static trim(original: string, charsToTrim: string): string {
    let beginIndex = 0;
    let endIndex = original.length - 1;

    while (beginIndex < endIndex) {
      const c = original.charAt(beginIndex);
      if (charsToTrim.indexOf(c) > -1) {
        beginIndex++;
      } else break;
    }

    while (endIndex > beginIndex) {
      const c = original.charAt(endIndex);
      if (charsToTrim.indexOf(c) > -1) endIndex--;
      else {
        endIndex++;
        break;
      }
    }

    return original.substring(beginIndex, endIndex);
  }

  public static replaceAt = (index: number, replacement: string, subject: string): string =>
    subject.substring(0, index) + replacement + subject.substring(index + replacement.length);

  public static convertFromObjectWithFormat = (obj: unknown, defaultValue: string): string => {
    if (obj === undefined || obj === null) {
      return defaultValue;
    }

    if (isNumeric(obj)) {
      if (typeof obj === 'string') return obj;
      const num = parseNumber(obj);
      const significantFractionDigits = Number.isInteger(num) ? 0 : 4;
      const numberFormatOptions: Intl.NumberFormatOptions = {
        maximumFractionDigits: significantFractionDigits,
      };
      return numericLocaleFormatLitteral(num, numberFormatOptions);
    }
    if (typeof obj === 'string') {
      return obj;
    } else {
      // Malheureusement, Java et Javascript n'ont pas la meme valeur de retour de la methode toString()...

      return StringExtension.customStringify(obj);
    }
  };

  public static customStringify(obj: { [key: string]: any }) {
    let result = '{';
    for (const key in obj) {
      // if (obj.hasOwnProperty(key)) {
      result += `${key}:${obj[key]},`;
      // }
    }
    // Supprimer la virgule finale et ajouter la fermeture de l'objet
    result = result.slice(0, -1) + '}';
    return result;
  }

  public static isTheSame(str1: string, str2: string): boolean {
    return str1.localeCompare(str2, undefined, { sensitivity: 'base' }) === 0;
  }

  public static join(input: string[], sep = ', '): string {
    return input.join(sep);
  }
}
