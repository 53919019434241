import { List, ListItem, ListItemText, Collapse, useTheme, Typography, ListSubheader } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useData from '../hooks';
const ApplicationInfo = () => {
  const { t } = useTranslation('userSetting');
  const { apiInfo } = useData();
  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <Typography component="div" id="nested-list-subheader" sx={{ color: '#FF8C00' }}>
          {t('qcapp_preference_category_appinfos')}
        </Typography>
      }
    >
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_client_web_name')}
          secondary={
            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
              {`QuickConnect App ${process.env.REACT_APP_VERSION} C2S`}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" />
      <ListItem>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1', fontWeight: 'bold' }}
          primary={t('qcapp_preference_appinfos_api')}
          secondary={
            apiInfo &&
            Object.keys(apiInfo).map((key, index) => {
              return (
                <Typography key={index} sx={{ display: 'block' }} component="span" variant="body2">
                  {`${key} : ${apiInfo[key]}`}
                </Typography>
              );
            })
          }
        />
      </ListItem>
    </List>
  );
};
export default ApplicationInfo;
