import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useData from './hooks';

const BackButton = () => {
  const { t } = useTranslation('common');
  const { goBack } = useData();
  return (
    <Tooltip title={t('back').toString()}>
      <IconButton edge="start" onClick={goBack}>
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};

export default observer(BackButton);
