import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { AddressData } from '90.quickConnect.Models/models/fields/values/addressData';
import { UseDataQcFieldPropsBase } from '50.quickConnect.Fields/FieldsTypes/types';

export type UseDataProps = UseDataQcFieldPropsBase & {
  localValue: AddressData | undefined;
  setLocalValue: Dispatch<SetStateAction<AddressData>>;
  startReverseGeocoding: (e: React.SyntheticEvent) => void;
  updateState: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
    valueType: ActionConditionType,
  ) => void;
  reverseGeoCodingLoading: boolean;
};

export enum ActionConditionType {
  StreetNumber = 0,
  Street = 1,
  Complement = 2,
  ZipCode = 3,
  City = 4,
  Country = 5,
}

export type DataForComponent = {
  nameForDataCy: string;
  labelForInputLabel: string;
  value: string;
  multiline: boolean;
  placeHolder: string;
};
