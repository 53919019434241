import { action, computed, makeAutoObservable, observable, toJS } from 'mobx';
import { AbortRequestsStore } from '30.quickConnect.Stores/RootStore/interfaces';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import RootStore from '30.quickConnect.Stores/RootStore';
import { ConsentData, FieldDesc } from '90.quickConnect.Models/models';
import consentFormDd from '40.quickConnect.DataAccess/indexedDb/dbs/consentFormRGPD';
import { ConsentFrequency, DocumentTransferState } from '90.quickConnect.Models/enums';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { RGPDConsents } from '30.quickConnect.Stores/RootStore/DeclarationStore/Payloads/requests';
import { mapConsentToRGPDConsents } from '90.quickConnect.Models/mappings';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class ConsentStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/ConsentStore/index.ts';

  // Les champs demandés
  fieldsToAsked: ConsentData[] = [];

  // Les fullPathIds des champs demandés
  fullPathIds: string[] = [];

  // Instance du logger
  logger: CustomLogger;

  // Instance du RootStore
  RootStore: RootStore;

  shouldAbortRequests = false;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.RootStore = rootStore;
    this.logger = logger;

    makeAutoObservable(
      this,
      {
        // Observables
        fieldsToAsked: observable,
        fullPathIds: observable,

        // Computed
        hasConsentsAsked: computed,

        // Actions
        setShouldAbortRequests: action,
        setFieldsToAsked: action,
      },
      { autoBind: true },
    );
  }

  //* Computed Values
  get hasConsentsAsked(): boolean {
    return this.fieldsToAsked.length > 0;
  }

  //* Actions
  setShouldAbortRequests(shouldAbortRequests: boolean) {
    this.shouldAbortRequests = shouldAbortRequests;
  }

  setFieldsToAsked(fieldsToAsked: ConsentData[]) {
    this.fieldsToAsked = fieldsToAsked;
  }

  //* Public functions Region
  resetStore() {
    this.shouldAbortRequests = false;
    this.fieldsToAsked = [];
    this.fullPathIds = [];
  }

  //* Private Functions Region

  /**
   * Crée de nouveaux ConsentData à partir des informations du formulaire
   */
  private _createConsentData = (
    formId: string,
    formName: string,
    userUPN: string,
    fields: FieldDesc[],
  ): ConsentData[] =>
    fields.map(({ checkRGPD, fullPathId, label }: FieldDesc) => ({
      formId,
      userUPN,
      formName,
      checkRGPD,
      fieldFullId: fullPathId,
      accepted: false,
      acceptedAt: undefined,
      labelField: label ?? '',
      transferState: DocumentTransferState.DoNotTransfer,
    }));

  private _sortConsentData = (newConsentData: ConsentData[], consentDataSaved: ConsentData[]): ConsentData[] =>
    newConsentData.reduce((acc: ConsentData[], current: ConsentData): ConsentData[] => {
      const consentSaved = consentDataSaved.find(
        ({ userUPN, formId, fieldFullId }: ConsentData) =>
          current.formId === formId && current.fieldFullId === fieldFullId && current.userUPN === userUPN,
      );

      return consentSaved
        ? [...acc, { ...consentSaved, transferState: DocumentTransferState.DoNotTransfer }]
        : [...acc, current];
    }, []);

  /**
   * check la validité des champs et met a jour la variable d'état fieldsAsked de la classe
   */
  private _consentManagement(consentDataToCheck: ConsentData[]) {
    this.setFieldsToAsked(
      consentDataToCheck
        .reduce((acc: ConsentData[], current: ConsentData) => {
          const dt = new Date();
          switch (current.checkRGPD) {
            case ConsentFrequency.Always:
              return [...acc, { ...current, accepted: false }];

            case ConsentFrequency.EveryDay: {
              const nextAcceptedValue = current.accepted
                ? DateTimeExtension.lessThanOneDay(dt, current.acceptedAt)
                : current.accepted;

              return [...acc, { ...current, accepted: nextAcceptedValue }];
            }

            case ConsentFrequency.EveryWeek: {
              const nextAcceptedValue = current.accepted
                ? DateTimeExtension.lessThanOneWeek(dt, current.acceptedAt)
                : current.accepted;

              return [...acc, { ...current, accepted: nextAcceptedValue }];
            }

            case ConsentFrequency.EveryMonth: {
              const nextAcceptedValue = current.accepted
                ? DateTimeExtension.lessThanOneMonth(dt, current.acceptedAt)
                : current.accepted;

              return [...acc, { ...current, accepted: nextAcceptedValue }];
            }

            case ConsentFrequency.EveryYear: {
              const nextAcceptedValue = current.accepted
                ? DateTimeExtension.lessThanOneYear(dt, current.acceptedAt)
                : current.accepted;

              return [...acc, { ...current, accepted: nextAcceptedValue }];
            }

            default:
              return [...acc, current];
          }
        }, [])
        .filter(({ accepted }: ConsentData) => !accepted),
    );
  }

  //* Async Public Functions

  async getRGPDConsentsToSend(): Promise<RGPDConsents[]> {
    try {
      return await consentFormDd.getRGPDConsentsToSendAsync();
    } catch (error) {
      errorHandler(ConsentStore.TAG, error, 'getRGPDConsentsToSend');
    }

    return [];
  }

  // Recupère la liste des Champs dont une consentement est démandé dans un formulaire pour une personne donnée
  async getConsentsForThisFormAndUserUPN(
    formId: string,
    formName: string,
    userUPN: string,
    fieldsDesc: FieldDesc[],
  ): Promise<void> {
    // On crée d'abord les nouveaux ConsentData en fonction des champs du formulaires
    const newConsentAskedFields = this._createConsentData(formId, formName, userUPN, fieldsDesc);

    // On récupère les champs déjà crées
    const existingFields = await consentFormDd.getFields(
      formId,
      userUPN,
      fieldsDesc.map((f: FieldDesc) => f.fullPathId),
    );

    // On trie, si dans les nouveaux champs crées, un existant est déjà présent, on utilisera ce dernier, sinon on utilise le nouveau.
    const consentDataToCheck = this._sortConsentData(newConsentAskedFields, existingFields);

    // On vérifie les infos
    this._consentManagement(consentDataToCheck);
  }

  async onAcceptConsentAsync(): Promise<void> {
    try {
      await this._saveConsentInLocalBase(true);
    } catch (error) {
      errorHandler(ConsentStore.TAG, error, 'OnAcceptConsentAsync');
    }
  }

  async onDeclineConsentAsync(): Promise<void> {
    try {
      await this._saveConsentInLocalBase(false);
    } catch (error) {
      errorHandler(ConsentStore.TAG, error, 'OnDeclineConsentAsync');
    }
  }

  async updateToBoTransferredConsents(): Promise<boolean> {
    try {
      await consentFormDd.updateConsentToBeTransferred();

      return true;
    } catch (error) {
      errorHandler(ConsentStore.TAG, error, 'updateToBoTransferredConsents');
    }

    return false;
  }

  public async transferringConsents(): Promise<RGPDConsents[]> {
    try {
      const consentsTransferring = await consentFormDd.transferringConsents();

      return consentsTransferring.map(mapConsentToRGPDConsents);
    } catch (error) {
      errorHandler(ConsentStore.TAG, error, 'transferringConsents');

      return [];
    }
  }

  //* Async Private Functions
  private async _saveConsentInLocalBase(hasAccepted: boolean): Promise<void> {
    const dt = new Date();
    this.setFieldsToAsked(
      this.fieldsToAsked.map((f: ConsentData) => ({
        ...f,
        accepted: hasAccepted,
        acceptedAt: dt,
        transferState: DocumentTransferState.ToBeTransfered,
      })),
    );

    await consentFormDd.updateConsent(toJS(this.fieldsToAsked));

    this.setFieldsToAsked([]);
  }
}
