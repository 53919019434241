/// La liste des PCODE que le générateur de code peut générer dans les QCScriptObj
/// Pour des raisons de compatibilité, les nouveaux PCODE sont ajoutés à la fin
/// et les valeurs des PCODE existants ne doivent pas changer
export enum Op { // opcodes
  ADD,
  SUB,
  MUL,
  DIV,
  EQU,
  NEQU,
  MOD,
  PLUSEQ,
  SUBEQ,
  MULEQ,
  DIVEQ,
  LSS,
  GTR,
  LSSE,
  GTRE,
  NEG,
  LOAD,
  LOADG,
  STO,
  STOG,
  CONST,
  LCONST,
  SCONST,
  DCONST,
  ICONST,
  CALL,
  RET,
  ENTER,
  LEAVE,
  JMP,
  FJMP,
  TJMP,
  RETURN,
  CALLX,
  SSWTCH,
  ESWTCH,
  CSWTCH,
  ANEW,
  AINIT,
  AINDEX,
  TRUE,
  FALSE,
  NULL,
  NOT,
  OR,
  AND,
  CONV,
}
