import { format, parseISO } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { AllFieldValueTypes, DateTimeDesc, FieldDesc } from '90.quickConnect.Models/models';
import { DateTimeFieldType, FieldType } from '90.quickConnect.Models/enums';
import { isDate } from '80.quickConnect.Core/helpers/common';

/** Permet d'obtenir le format de la date attendu ainsi que la locale */
const getFormatDateTimeAndLocale = (
  datetime: Date,
  type: DateTimeFieldType | undefined,
  langugageBrowser: string,
): string => {
  switch (langugageBrowser) {
    case 'fr':
      switch (type) {
        case DateTimeFieldType.DateTime:
          return format(datetime, 'dd/MM/yyyy HH:mm', { locale: frLocale });

        case DateTimeFieldType.Date:
        default:
          return format(datetime, 'dd/MM/yyyy', { locale: frLocale });
      }
    case 'us':
    default:
      switch (type) {
        case DateTimeFieldType.DateTime:
          return format(datetime, 'MM/dd/yyyy HH:mm aaa', { locale: enLocale });

        case DateTimeFieldType.Date:
        default:
          return format(datetime, 'MM/dd/yyyy', { locale: enLocale });
      }
  }
};

export const getReferencedDateTimeValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
  referenceField: FieldDesc,
): AllFieldValueTypes => {
  const dateTimeValue = isDate(valueReferenceDependency) ? new Date(valueReferenceDependency) : undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.DateTime:
      return dateTimeValue;

    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
    case FieldType.Separator:
      const { type } = referenceField as DateTimeDesc;
      return dateTimeValue
        ? getFormatDateTimeAndLocale(dateTimeValue, type, window.navigator.language.substring(0, 2).toLocaleLowerCase())
        : undefined;
    default:
      return undefined;
  }
};
