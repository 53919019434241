export enum OperatorType {
  Value = 'Value',
  Selector = 'Selector',
  Bitwise = 'Bitwise',
  Conditional = 'Conditional',
  Comparison = 'Comparison',
  Arithmetic = 'Arithmetic',
  ComparisonContainer = 'ComparisonContainer',
  Pattern = 'Pattern',
}
