import mapEntity from '../mapEntityBase';
import mapChoice from './mapChoice';
import mapHierarchicalChoices from './mapHierarchicalChoices';
import { SharedList } from '90.quickConnect.Models/models';
import { SharedListType } from '90.quickConnect.Models/enums';

const mapSharedList = (sharedList: SharedList): SharedList =>
  ({
    ...mapEntity(sharedList),
    name: sharedList.name,
    description: sharedList.description,
    data: sharedList.data && sharedList.listType !== SharedListType.Hierarchical ? sharedList.data.map(mapChoice) : [],
    hierarchicalChoices:
      sharedList.data && sharedList.listType === SharedListType.Hierarchical
        ? mapHierarchicalChoices(sharedList.data)
        : [],
    lastModificationDate: sharedList.lastModificationDate ? new Date(sharedList.lastModificationDate) : new Date(),
    listType: sharedList.listType,
    companionQCId: sharedList.companionQCId,
    correlationId: sharedList.correlationId,
    defaultLanguage: sharedList.defaultLanguage,
    currentLanguage: sharedList.defaultLanguage,
    toBeTranslated: sharedList.toBeTranslated,
    etag: sharedList.etag,
  } as SharedList);

export default mapSharedList;
