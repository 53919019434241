import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { dippedGreen500, pureWhiteColor, slateGrey800 } from '00.app/theme';

export const SeparatorQCFieldBoxStyle: SxProps = {
  mt: 2,
  mb: 2,
  p: 1,
  minHeight: '1vh',
  backgroundColor: dippedGreen500,
  color: slateGrey800,
  borderRadius: '5px',
  width: '100%',
};

export const SeparatorQCFieldTypoStyle: SxProps = {
  fontWeight: 600,
};
