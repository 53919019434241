import { useParams } from 'react-router';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { EquipmentTabs, UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { EntityData } from '90.quickConnect.Models/models';

const useData = (t: TFunction): UseDataProps => {
  // On récupère le store
  const {
    EquipmentsStore: { getEntityData },
  } = useStore();

  // On récupère des données depuis l'URL
  const { schemaId, entityId } = useParams();

  // On set le state
  const [entityData, setEntityData] = useState<EntityData | undefined>();
  const [selectedTab, setSelectedTab] = useState<EquipmentTabs>(EquipmentTabs.Attributs);

  const handleTabChange = (event: React.SyntheticEvent, newValue: EquipmentTabs) => {
    setSelectedTab(newValue);
  };

  // On ajoute des hooks
  useEffect(() => {
    const fetchEntityData = async () => {
      if (schemaId && entityId) {
        const entity = await getEntityData(schemaId, entityId);
        setEntityData(entity);
      }
    };
    fetchEntityData();
  }, [entityId, getEntityData, schemaId]);

  const schemasIds: string[] = useMemo(() => {
    if (!entityData) return [];

    const { parentSchemasIds, entitySchemaId } = entityData;
    return [...parentSchemasIds, entitySchemaId];
  }, [entityData]);

  return {
    entityId,
    entityData,
    selectedTab,
    handleTabChange,
    schemasIds,
  };
};

export default useData;
