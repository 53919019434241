import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { DECLARATION } from '10.quickConnect.app/routes/paths';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';

const useData = (): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/Home/Declarations/DeclarationHistory/hooks.ts';

  const {
    DeclarationStore: { getOldDeclaration },
    LoginStore: { signInInfos },
  } = useStore();
  const [forms, setForms] = useState<any>();
  const [detailsDeclaration, setDetailsDeclaration] = useState<any>();
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const res = await getOldDeclaration({ userUPN: signInInfos.userUPN });
      if (res) {
        setForms(res.data);
      }
    };
    fetch();
  }, [getOldDeclaration, signInInfos.userUPN]);

  const getDeclarationByFormName = useCallback(
    async (formName: string) => {
      const fetch = async () => {
        try {
          const res = await getOldDeclaration({ userUPN: signInInfos.userUPN, formName });
          if (res) {
            setDetailsDeclaration(res.data);
          }
        } catch (error) {
          errorHandler(tag, error, 'getDeclarationByFormName');
        }
      };
      fetch();
    },
    [getOldDeclaration, tag, signInInfos.userUPN],
  );

  const handleMoveTo = useCallback(
    (formName: string, id: string) => {
      const fetch = async () => {
        try {
          const res = await getOldDeclaration({ userUPN: signInInfos.userUPN, formName, declarationId: id });
          if (res) {
            navigate(DECLARATION.replace(':formId', res.data.declaration.formId).replace(':declarationId', id));
          }
        } catch (error) {
          errorHandler(tag, error, 'handleMoveTo');
        }
      };
      fetch();
    },
    [getOldDeclaration, tag, navigate, signInInfos.userUPN],
  );

  const handleClick = useCallback(
    (item: string) => {
      getDeclarationByFormName(item);
      setOpen((prevState: { [key: string]: boolean }) => ({ [item]: !prevState[item] }));
    },
    [getDeclarationByFormName],
  );
  return {
    forms,
    getDetailDec: getDeclarationByFormName,
    detailsDeclaration,
    handleMoveTo,
    setOpen,
    handleClick,
    open,
  };
};
export default useData;
