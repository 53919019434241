import { EditDesc, CodeReaderDesc } from '90.quickConnect.Models/models';

const mapCodeReaderDesc = ({
  mustBeOfSchema,
  mustHaveQCTag,
  searchEquipment,
  value,
  entityData,
}: CodeReaderDesc): CodeReaderDesc =>
  ({
    entityData: entityData,
    mustHaveQCTag: mustHaveQCTag ?? '',
    mustBeOfSchema: mustBeOfSchema ?? '',
    searchEquipment: searchEquipment ?? false,
    value: value,
  } as CodeReaderDesc);

export default mapCodeReaderDesc;
