export enum EditorType {
  ReadOnlyTextEditor = 0,
  TextEditor = 1,
  DateEditor = 2,
  ComboEditor = 3,
  YoupiEditor = 4,
  CheckEditor = 5,
  RadioEditor = 6,
  SliderEditor = 7,
  SliderComboEditor = 8,
  /**
   * Une combo avec 3 valeurs : Oui, Non, Personnalisée
   * Si 'Personnalisée', alors tu affiches un TextEditor
   */
  TrueFalseCustomEditor = 9,
  /**
   * Une combo avec X valeurs, dont la dernière est 'Personnalisée'
   * Si 'Personnalisée', alors il doit pouvoir sasir une liste d'éléments (key value pair) de son choix
   */
  ComboCustomEditor = 10,
  NumericEditor = 11,
  /** pour les champs calculés */
  ComputeEditor = 12,
  RichTextEditor = 25,
  StepTransitionEditor = 26,
  IntRangeEditor = 27,
  DecimalRangeEditor = 28,
  ToolTipEditor = 29,
  ComboDataSourceEditor = 31,
  DefaultImage = 32,
  DefaultFile = 33,
  AutoComplete = 40,
  MultiSelect = 50,
}
