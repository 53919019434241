import { styled, OutlinedInput } from '@mui/material';

export const OutlinedInputStyledComponent = styled(OutlinedInput)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'block',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});
