import { useState } from 'react';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

export enum WorkSiteStatus {
  COMPLETED,
  INCOMPLETE,
  CLOSED,
}

export type WorkSite = {
  id: string;
  name: string;
  status: WorkSiteStatus;
  published: boolean;
  icon: string;
};

/*
TODO KTY MOCK DATA
 */

const mockWorkSite: Array<WorkSite> = [
  {
    id: 'jesuisunId',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Petit Nom Minion',
    status: WorkSiteStatus.COMPLETED,
  },
  {
    id: 'jesuisunIdAussi',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: false,
    name: "Je suis un grand nom de chantier histoire de pousser les limites de l'affichage et d'emerder le dev front end qui doit gérer des long noms",
    status: WorkSiteStatus.INCOMPLETE,
  },
  {
    id: 'IDTruc',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de chantier',
    status: WorkSiteStatus.CLOSED,
  },
  {
    id: 'IDSomething',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de Chantier',
    status: WorkSiteStatus.INCOMPLETE,
  },
  {
    id: 'IDMachin',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: false,
    name: 'Nom de ChAnTiEr',
    status: WorkSiteStatus.CLOSED,
  },
  {
    id: 'BonjourId',
    icon: 'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image7.png?se=2050-02-23T14:45:48Z&si=imagesreader&spr=https&sv=2022-11-02&sr=c&sig=VrD8q8kuGtw6BOr7YJbkWqBi8AWTy8UUMgGVfUp8gyo%3D',
    published: true,
    name: 'Nom de CHANTIER',
    status: WorkSiteStatus.CLOSED,
  },
];

/*
TODO KTY MOCK DATA
 */

type HomeContentProps = {
  workSiteList: Array<WorkSite>;
};

const useWorkSiteListData = (): HomeContentProps => {
  const [workSiteList, setWorkSiteList] = useState<Array<WorkSite>>([]);

  useEffectOnce(() => {
    /*TODO KTY faire un call api pour recup la liste des chantiers*/
    setWorkSiteList(mockWorkSite);
  });
  return { workSiteList: workSiteList };
};

export default useWorkSiteListData;
