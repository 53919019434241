import { useNavigate } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast, Id } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { DeclarationViewer } from '90.quickConnect.Models/models';
import { DECLARATION, NEW_DECLARATION_FROM_DUPLICATION } from '10.quickConnect.app/routes/paths';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { FormType } from '90.quickConnect.Models/enums';

const useData = (declaration: TreeDataItem): UseDataProps => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { id, formId, formType } = declaration as DeclarationViewer;
  // On ajoute des hooks
  const [isHover, setIsHover] = useState<boolean>(false);

  const [toastId, setToastId] = useState<Id>();

  const { t } = useTranslation('declaration');

  const link = useMemo(() => DECLARATION.replace(':formId', formId).replace(':declarationId', id), [formId, id]);

  const duplicationLink = useMemo(
    () => NEW_DECLARATION_FROM_DUPLICATION.replace(':formId', formId).replace(':declarationId', id),
    [formId, id],
  );

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const createDeclaration = useCallback(async () => {
    navigate(link);
  }, [link, navigate]);

  const openInNewTab = useCallback(async () => {
    window.open(link);
    setContextMenu(null);
  }, [link]);

  const contextMenuClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const openContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    },
    [contextMenu],
  );

  const handleDuplicate = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setContextMenu(null);

      if (formType === FormType.Workflow) {
        if (toastId) toast.update(toastId);
        else setToastId(toast.error(t('qcapp_histo_edited_search_old_duplicate_wkf')));
      } else {
        navigate(duplicationLink);
      }
    },
    [navigate, duplicationLink, toastId, setToastId, formType, t],
  );

  if (toastId)
    toast.onChange((payload) => {
      switch (payload.status) {
        case 'removed':
          setToastId(undefined);
      }
    });

  return {
    openDeclaration: createDeclaration,
    isMobile,
    openContextMenu,
    contextMenu,
    contextMenuClose,
    openInNewTab,
    handleDuplicate,
    isHover,
    setIsHover,
  };
};

export default useData;
