import React from 'react';
import useData from './hooks';
import { ErrorResolverProps } from './types';

/**
 * Ce composant permet de gérer les erreurs de type mandatory, min, max...
 * @param param0
 * @returns
 */
const ErrorResolver = ({ fieldToCheck, updateFieldErrors, allFieldsToCkeck, flattenFields }: ErrorResolverProps) => {
  useData(fieldToCheck, updateFieldErrors, allFieldsToCkeck, flattenFields);
  return <></>;
};

export default ErrorResolver;
