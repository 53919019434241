import React from 'react';
import { Button, FormControlLabel, Paper, Tooltip, Typography, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Box } from '@mui/system';
import { DividerStyle } from '../../Declarations/DeclarationsToolBar/styles';
import { InboxesToolBarProps } from './types';
import { MainBoxStyle } from './styles';
import { useData } from './hooks';
import { InboxesToolBarName } from './const';
import { hideOnMobile } from '20.formLib/sharedStyles';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';

// eslint-disable-next-line no-empty-pattern
const InboxesToolBar = ({}: InboxesToolBarProps) => {
  const { t } = useTranslation(['inboxes', 'common']);
  const {
    clearFilter,
    filterInputValue,
    setFilterInputValue,
    inboxFilter,
    handleExpandClick,
    isAllExpanded,
    isAllExpandedDisabled,
    inboxesAsTree,
    setInboxesAsTree,
    setInboxesAsAlphaNumericSort,
    inboxesAsAlphaNumericSort,
  } = useData();

  return (
    <Paper elevation={0} data-cy={InboxesToolBarName} sx={MainBoxStyle}>
      <SearchBar
        toolTip={t('qcapp_inbox_search_bar').toString()}
        filterInputValue={filterInputValue}
        filter={inboxFilter}
        setFilter={setFilterInputValue}
        applyFilter={() => {}}
        clearFilter={clearFilter}
        setSearchIconToRigth={false}
      />

      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>

      <Tooltip title={t('qcapp_common_switch_view_mode', { ns: 'common' }).toString()}>
        <FormControlLabel
          sx={{ ...hideOnMobile }}
          control={
            <Switch
              checked={inboxesAsTree}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setInboxesAsTree(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('qcapp_inbox_tree_view').toString()}
        />
      </Tooltip>
      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>
      <Tooltip title={t('qcapp_preference_title_mode_display_forms').toString()}>
        <FormControlLabel
          sx={{ ...hideOnMobile }}
          control={
            <Switch
              checked={inboxesAsAlphaNumericSort}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                setInboxesAsAlphaNumericSort(checked)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('qcapp_preference_summary_sort_inbox_form_to_complete_alphanumeric_order').toString()}
        />
      </Tooltip>
      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>
      <Tooltip
        disableHoverListener={!inboxesAsTree}
        title={
          isAllExpanded
            ? t('qcapp_common_expand_all', { ns: 'common' }).toString()
            : t('qcapp_common_collapse_all', { ns: 'common' }).toString()
        }
      >
        <span>
          <Button
            sx={{ ...hideOnMobile, visibility: inboxesAsTree ? 'visible' : 'hidden' }}
            disabled={isAllExpandedDisabled}
            variant="contained"
            startIcon={isAllExpanded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            onClick={handleExpandClick}
          >
            {isAllExpanded
              ? t('qcapp_common_expand_all', { ns: 'common' })
              : t('qcapp_common_collapse_all', { ns: 'common' })}
          </Button>
        </span>
      </Tooltip>
    </Paper>
  );
};
export default observer(InboxesToolBar);
