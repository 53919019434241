import { AttributeValue } from '90.quickConnect.Models/models';

const mapAttributeValue = (attribute: AttributeValue): AttributeValue =>
  ({
    attributeId: attribute.attributeId,
    attributeLabel: attribute.attributeLabel,
    value: attribute.value,
  } as AttributeValue);

export default mapAttributeValue;
