import React from 'react';
import styled from '@emotion/styled';
import { SxProps } from '@mui/system';
import { Button, alpha, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { Theme, styled as MUIStyled } from '@mui/material/styles';

export const LeftLogo = styled.img`
  max-height: 60px;
  width: auto;
`;

export const UserButtonAction = styled(Button)`
  &:disabled {
    color: ${(props) => alpha((props.theme as Theme).palette.lightThemeTextColor.main, 0.5)};
    border-color: ${(props) => alpha((props.theme as Theme).palette.lightThemeTextColor.main, 0.5)};
  }
  ${(props) => (props.theme as Theme).breakpoints.down('lg')} {
    font-size: 1vw;
  }

  ${(props) => (props.theme as Theme).breakpoints.between(600, 699)} {
    width: 0;
  }
`;

export const LogoBox = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
} as SxProps;

// Styled Components

export const CustomAttachmentsToSendToolTip = MUIStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});
