import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { useStore } from '30.quickConnect.Stores';
import { InboxItemType } from '90.quickConnect.Models/enums';
import { InboxViewer } from '90.quickConnect.Models/models';
import compareStrings from '80.quickConnect.Core/helpers/compareStrings';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    InboxesStore: {
      PreFilledStore: { items: preFilled },
      TasksStore: { items: tasks },
      OthersStore: { items: others },
      prefilledItemsCount,
      tasksItemsCount,
      othersItemsCount,
      filterThisInboxes,
      filter,
    },
    UserSettingsStore: { inboxesAsAlphaNumericSort },
  } = useStore();
  const { t } = useTranslation('inboxes');

  const [currentPrefilledForms, setPrefilledForms] = useState<InboxViewer[]>(preFilled ?? []);
  const [currentTasks, setTasks] = useState<InboxViewer[]>(tasks ?? []);
  const [currentOthers, setOthers] = useState<InboxViewer[]>(others ?? []);

  useEffect(() => {
    const filteredPrefilledForms = filterThisInboxes(InboxItemType.PrefilledForm);
    setPrefilledForms(filteredPrefilledForms);
    const filteredTasks = filterThisInboxes(InboxItemType.Task);
    setTasks(filteredTasks);
    const filteredOthers = filterThisInboxes(InboxItemType.Unknow);
    setOthers(filteredOthers);
  }, [filter, filterThisInboxes]);

  const sortFn = useCallback(
    (a: TreeDataItem, b: TreeDataItem) => {
      if (inboxesAsAlphaNumericSort) {
        return compareStrings((a as InboxViewer).subject, (b as InboxViewer).subject);
      } else {
        return (b as InboxViewer)?.sentAt && (a as InboxViewer)?.sentAt
          ? new Date((b as InboxViewer).sentAt).getTime() - new Date((a as InboxViewer).sentAt).getTime()
          : -1;
      }
    },
    [inboxesAsAlphaNumericSort],
  );

  const prefilledItemsTitle = useMemo((): string => {
    const startTitle = t('qcapp_inbox_item_prefilled_form_node_name');
    return prefilledItemsCount > 0 ? `${startTitle} (${prefilledItemsCount})` : startTitle;
  }, [prefilledItemsCount, t]);

  const tasksItemsTitle = useMemo((): string => {
    const startTitle = t('qcapp_inbox_item_todolist_node_name');
    return tasksItemsCount > 0 ? `${startTitle} (${tasksItemsCount})` : startTitle;
  }, [tasksItemsCount, t]);

  const othersItemsTitle = useMemo((): string => {
    const startTitle = t('qcapp_inbox_item_category_not_found');
    return othersItemsCount > 0 ? `${startTitle} (${othersItemsCount})` : startTitle;
  }, [othersItemsCount, t]);

  return {
    preFilled: currentPrefilledForms,
    tasks: currentTasks,
    others: currentOthers,
    sortFn,
    prefilledItemsTitle,
    tasksItemsTitle,
    othersItemsTitle,
  };
};
