import { TFunction } from 'i18next';
import {
  FieldDesc,
  AllFieldValueTypes,
  Reference,
  EntityData,
  LabelDesc,
  ComboDesc,
  HierarchicalDesc,
  CheckBoxListDesc,
} from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import {
  getReferencedAddressValue,
  getReferencedCheckboxListValue,
  getReferencedComboValue,
  getReferencedCheckboxValue,
  getReferencedCodeReaderValue,
  getReferencedCounterValue,
  getReferencedDateTimeValue,
  getReferencedDigitsValue,
  getReferencedGeolocationValue,
  getReferencedLabelValue,
  getReferencedNotificationValue,
  getReferencedNumericValue,
  getReferencedReadOnlyValue,
  getReferencedRfidReaderValue,
  getReferencedSliderValue,
  getReferencedComputeValue,
  getReferencedTextValue,
  getReferencedTimeValue,
  getReferencedTodoListValue,
  getReferencedHierarchicalListValue,
  getReferencedActionValue,
} from '20.formLib/helpers/referencedValue/fields';
import { findChoices, getChoiceList } from '80.quickConnect.Core/helpers/common';

export const getReferencedValue = (
  fieldWithReference: FieldDesc,
  referenceField: FieldDesc,
  referenceDependency: Reference,
  t: TFunction,
  entityData?: EntityData,
): AllFieldValueTypes => {
  const {
    fieldType: fieldTypeReferenceDependency,
    value: valueReferenceDependency,
    referenceId,
    columnId,
  } = referenceDependency;
  const { fieldType: fieldTypeFieldWithReference } = fieldWithReference;

  switch (fieldTypeReferenceDependency) {
    case FieldType.Action:
      return getReferencedActionValue(valueReferenceDependency, fieldWithReference, referenceId, columnId);

    case FieldType.Address:
      return getReferencedAddressValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.CheckBoxList:
      if (valueReferenceDependency === undefined) return valueReferenceDependency;

      // Dans le cas où les valeurs indiquées dans checkboxListValues ne seraient pas un Array<Choice>
      const choices = (referenceField as CheckBoxListDesc).listChoice ?? [];
      const checboxListValues = findChoices(choices, valueReferenceDependency);
      if (checboxListValues.length > 0) {
        return getReferencedCheckboxListValue(
          fieldWithReference,
          checboxListValues,
          fieldTypeFieldWithReference,
          columnId,
        );
      }
      return getReferencedCheckboxListValue(
        fieldWithReference,
        valueReferenceDependency,
        fieldTypeFieldWithReference,
        columnId,
      );

    case FieldType.Alert:
    case FieldType.Combo:
    case FieldType.RadioList:
      return getReferencedComboValue(
        fieldWithReference,
        valueReferenceDependency,
        fieldTypeFieldWithReference,
        columnId,
      );

    case FieldType.HierarchicalList:
      const hierarchicalChoices = (referenceField as HierarchicalDesc).listChoice ?? [];
      const hierarchicalValues = findChoices(hierarchicalChoices, valueReferenceDependency);
      if (hierarchicalValues.length > 0) {
        return getReferencedHierarchicalListValue(
          fieldWithReference,
          hierarchicalValues,
          fieldTypeFieldWithReference,
          columnId,
        );
      }

      return getReferencedHierarchicalListValue(
        fieldWithReference,
        valueReferenceDependency,
        fieldTypeFieldWithReference,
        columnId,
      );

    case FieldType.CheckBox:
      return getReferencedCheckboxValue(valueReferenceDependency, fieldTypeFieldWithReference, t);

    case FieldType.CodeReader:
      return getReferencedCodeReaderValue(
        valueReferenceDependency,
        fieldTypeFieldWithReference,
        fieldWithReference,
        entityData,
        columnId,
      );

    case FieldType.Compute:
      return getReferencedComputeValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Counter:
      return getReferencedCounterValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.DateTime:
      return getReferencedDateTimeValue(valueReferenceDependency, fieldTypeFieldWithReference, referenceField);

    case FieldType.Digits:
      return getReferencedDigitsValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Geolocation:
      return getReferencedGeolocationValue(valueReferenceDependency, fieldTypeFieldWithReference, fieldWithReference);

    case FieldType.Label:
      return getReferencedLabelValue(fieldWithReference as LabelDesc, fieldTypeFieldWithReference);

    case FieldType.Notification:
      return getReferencedNotificationValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Numeric:
      return getReferencedNumericValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.ReadOnlyValue:
      return getReferencedReadOnlyValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.RfidReader:
      return getReferencedRfidReaderValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Slider:
      return getReferencedSliderValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Text:
      return getReferencedTextValue(fieldWithReference, valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Time:
      return getReferencedTimeValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.TodoList:
      return getReferencedTodoListValue(valueReferenceDependency, fieldTypeFieldWithReference);

    case FieldType.Array:
    case FieldType.Attachment:
    case FieldType.Audio:
    case FieldType.DataSource:
    case FieldType.Dialog:
    case FieldType.Draw:
    case FieldType.FixedAttachment:
    case FieldType.FixedImage:
    case FieldType.Group:
    case FieldType.Include:
    case FieldType.Photo:
    case FieldType.Plugin:
    case FieldType.RepeatableGroup:
    case FieldType.Separator:
    case FieldType.Signature:
    case FieldType.Step:
    case FieldType.Unknow:
    default:
      return undefined;
  }
};
