import mapEntity from '../mapEntityBase';
import mapAttributeValue from './mapAttributeValue';
import mapAttributeValueFromBindings from './mapAttributeValueFromBindings';
import { EntityData, KeyValuePair } from '90.quickConnect.Models/models';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/enums';

const mapEntityData = (
  entityData: EntityData,
  binding: { [key: string]: string },
  bindingTypes: KeyValuePair<string, EntitySchemaAttributeTypes>[],
): EntityData =>
  ({
    ...mapEntity(entityData),
    title: entityData.title,
    code: entityData.code,
    organizationalUnitId: entityData.organizationalUnitId,
    // L'API ne respecte pas le camel case...
    entitySchemaId: entityData.entityschemaid ?? entityData.entitySchemaId,
    attributes:
      entityData.attributes && entityData.attributes.length > 0
        ? entityData.attributes.map(mapAttributeValue)
        : mapAttributeValueFromBindings(entityData, binding, bindingTypes),
    correlationId: entityData.correlationId,
    qcTagCode: entityData.qcTagCode,
    parentSchemasIds: entityData.parentSchemasIds ?? [],
  } as EntityData);

export default mapEntityData;
