import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { camelize } from '80.quickConnect.Core/helpers';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

const tag = '90.quickConnect.Models/mappings/forms/mapInjectedData.ts';

const mapInjectedData = (injectedData: string | undefined): Record<string, AllFieldValueTypes> | undefined => {
  if (!injectedData) return undefined;

  return JSON.parse(injectedData, function (key: string, value: any): any | undefined {
    const camelizedKey = camelize(key);

    if (this instanceof Array || camelizedKey === key) {
      // if this is Array
      // or key does not change after converted to camel case
      // then just return the value so that the default "reviving" is done
      return value;
    } else {
      // if key changes assing value to camel case one and return nothing
      this[camelizedKey] = value;
    }
  });
};

export default mapInjectedData;
