import React, { FunctionComponent } from 'react';
import { Stack } from '@mui/material';
import { WorkSiteSummaryProps } from '../hookWorkSiteSummary';
import ReportCard from './ReportCard/ReportCard';

const ReportList: FunctionComponent<WorkSiteSummaryProps> = (props: WorkSiteSummaryProps) => {
  const { reports } = props.workSite;
  return (
    <Stack gap={'1rem'}>
      {reports?.map((report) => (
        <ReportCard key={report.id} report={report} setWorkSite={props.setWorkSite} />
      ))}
    </Stack>
  );
};

export default ReportList;
