import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { DeclarationsTreeViewName } from './const';
import { DeclarationsTreeProps } from './types';
import ViewSkeleton from '10.quickConnect.app/components/domain/Home/Shared/Skeletons/viewSkeleton';
import CustomTree from '10.quickConnect.app/components/domain/Home/Shared/CustomTree';
import DeclarationTreeItemRenderer from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationsTrees/DeclarationTreeItemRenderer';
import { getTreeBoxStyle } from '10.quickConnect.app/components/domain/Home/styles';
import { DeclarationViewer } from '90.quickConnect.Models/models';

const DeclarationsTree = ({ title, hideScrollBar, store, cle }: DeclarationsTreeProps) => {
  return (
    <Box data-cy={`${DeclarationsTreeViewName}`} sx={{ ...getTreeBoxStyle(), height: { xs: 'unset', sm: 'unset' } }}>
      {store.items ? (
        <CustomTree<DeclarationViewer>
          store={store}
          title={title}
          RenderTreeItem={DeclarationTreeItemRenderer}
          getName={(i: DeclarationViewer) => i.name + ' ' + i.folderPath}
          cle={cle}
        />
      ) : (
        <ViewSkeleton />
      )}
    </Box>
  );
};
export default observer(DeclarationsTree);
