import React from 'react';
import useData from './hooks';
import { ReferencesResolverProps } from './types';
import ReferenceResolver from './ReferenceResolver';

/**
 * Ce composant permet des géré les ComputedFields et de déterminer ses dépendances
 * @param param0
 * @returns
 */
const ReferencesResolver = ({
  children,
  flattenFields,
  updateDeclaration,
  forceFieldUpdate,
}: ReferencesResolverProps) => {
  const { allFieldsWithReference } = useData(flattenFields);

  return (
    <>
      {allFieldsWithReference.map((fieldWithReference) => (
        <ReferenceResolver
          key={fieldWithReference.fullPathId}
          fieldWithReference={fieldWithReference}
          updateDeclaration={updateDeclaration}
          otherFields={flattenFields.filter((f) => f.fullPathId !== fieldWithReference.fullPathId)}
          forceFieldUpdate={forceFieldUpdate}
        />
      ))}
      {children}
    </>
  );
};

export default ReferencesResolver;
