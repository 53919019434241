import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import L, { DomUtil, map, Map, tileLayer, marker, LatLngLiteral } from 'leaflet';
import { Box } from '@mui/material';
import * as ELG from 'leaflet-control-geocoder';
import { useDebouncedCallback } from 'use-debounce';
import { GeolocationQcFieldName } from './const';
import { ShowMapEnum, UseDataProps } from './types';
import { AllFieldValueTypes, GeolocationData } from '90.quickConnect.Models/models';
import { GeolocationDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/geolocationDesc';
import { debounceTime } from '50.quickConnect.Fields/const';

const useData = (
  field: GeolocationDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  nameWithId: string,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  selectedIndex?: string,
): UseDataProps => {
  // On récupère la translation

  const { t } = useTranslation('declaration');
  const { id, fullPathId, reference, value } = field;
  const [qcMap, setQcMap] = useState<Map | undefined>();
  const mapId = useMemo(() => `${GeolocationQcFieldName}-${fullPathId}-map`, [fullPathId]);
  const [mapContainer, setMapContainer] = useState<JSX.Element | undefined>();
  const [locations, setLocation] = useState<LatLngLiteral | undefined>({ lat: 0, lng: 0 });
  const [localValue, setLocalValue] = useState<GeolocationData>((field.value as GeolocationData) ?? undefined);

  const initializeMapRef = useRef<boolean>(false);

  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  useEffect(() => {
    if (selectedIndex === fullPathId && !qcMap) {
      const previousMap = DomUtil.get(mapId);
      if (previousMap) setMapContainer(undefined);
      setMapContainer(
        <Box
          id={mapId}
          data-cy={`${nameWithId}-map-Container`}
          sx={{ width: '100%', height: '500px', marginBottom: '5px', display: `${field.showMap ? '' : 'none'}` }}
        />,
      );
    }
  }, [field.showMap, fullPathId, mapId, nameWithId, qcMap, selectedIndex]);

  useEffect(() => {
    if (mapContainer && selectedIndex === fullPathId && !initializeMapRef.current) {
      setQcMap(map(mapId));
      initializeMapRef.current = true;
    }
  }, [mapContainer, mapId, selectedIndex, fullPathId]);

  useEffect(() => {
    if (qcMap && selectedIndex === fullPathId) {
      qcMap.locate({ setView: true, maxZoom: 16 });
      qcMap.on('locationfound', (e) => {
        qcMap.flyTo(e.latlng, qcMap.getZoom());
        setLocation(e.latlng);
        const previousMarker = L.marker(e.latlng, { draggable: field.showMap === ShowMapEnum.Dynamic })
          .addTo(qcMap)
          .openPopup()
          .on('dragend', (event) => {
            setLocation({ lat: event.target._latlng.lat, lng: event.target._latlng.lng });
          });
        if (field.showSearchBar) {
          const searchControl = new (ELG as any).Geocoder({
            position: 'topleft',
            collapsed: false,
            placeholder: `${t('search')}`,
            defaultMarkGeocode: false,
          }).addTo(qcMap);

          searchControl.on('markgeocode', (data: any) => {
            qcMap.removeLayer(previousMarker);
            qcMap.flyTo(data.geocode.center, qcMap.getZoom());
            setLocation(data.geocode.center);
            marker(data.geocode.center, { draggable: field.showMap === ShowMapEnum.Dynamic })
              .addTo(qcMap)
              .on('dragend', (event) => {
                setLocation({ lat: event.target._latlng.lat, lng: event.target._latlng.lng });
              });
          });
        }
        debouncedUpdateGlobalState();
      });

      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(qcMap);
    }
    return () => {
      if (selectedIndex !== fullPathId) {
        qcMap?.off();
      }
    };
  }, [debouncedUpdateGlobalState, field.showMap, field.showSearchBar, qcMap, t, selectedIndex, fullPathId]);

  // UseEffect pour les références...
  useEffect(() => {
    const newLocalValue = value as GeolocationData;
    setLocalValue((previousLocalState: GeolocationData) =>
      previousLocalState !== newLocalValue ? newLocalValue : previousLocalState,
    );
  }, [value]);

  const handleCloseDialog = useCallback(() => {
    initializeMapRef.current = false;
    setMapContainer(undefined);
    setQcMap(undefined);
    setSelectedIndex(undefined);
  }, [setSelectedIndex]);

  const handleValidate = useCallback(() => {
    setLocalValue({ latitude: locations?.lat, longitude: locations?.lng, precision: 0 });
    debouncedUpdateGlobalState();
    handleCloseDialog();
  }, [debouncedUpdateGlobalState, handleCloseDialog, locations?.lat, locations?.lng]);

  const handleDelete = useCallback(() => {
    handleCloseDialog();
    setLocation({ lat: 0, lng: 0 });
    setLocalValue({
      latitude: undefined,
      longitude: undefined,
      precision: undefined,
    });
  }, [handleCloseDialog]);
  // On retourne les valeurs à la vue
  return {
    t,
    mapContainer,
    locations,
    localValue,
    handleValidate,
    setQcMap,
    setMapContainer,
    initializeMapRef,
    handleCloseDialog,
    handleDelete,
  };
};

export default useData;
