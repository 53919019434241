import { SxProps } from '@mui/system';
import { kraft500 } from '00.app/theme';
import { TopBarHeight } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';

export const MainBox: SxProps = {
  width: {
    xs: '100vw',
    sm: '100vw',
    md: 'calc(100vw - 1.2rem)',
    lg: 'calc(100vw - 1.2rem)',
    xl: 'calc(100vw - 1.2rem)',
  },
  position: 'sticky',
  borderRadius: {
    xs: '0',
    sm: '0',
    md: '15px',
    lg: '15px',
    xl: '15px',
  },
  margin: {
    xs: '0',
    sm: '0',
    md: '1.2em auto !important',
    lg: '1.2em auto !important',
    xl: '1.2em auto !important',
  },
  height: `${TopBarHeight}px`,
  marginBottom: '10px',
  background: kraft500,
  boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;',
};
