import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes, FieldDesc, Choice, ComboDesc, NotificationTarget } from '90.quickConnect.Models/models';
import {
  getValueFromChoice,
  getValueFromChoiceProperties,
  isQCNotification,
  splitAndTrim,
} from '80.quickConnect.Core/helpers/common';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';
import { mapToNotificationDataFromHashMap } from '90.quickConnect.Models/mappings';

export const getReferencedCheckboxListValue = (
  fieldWithReference: FieldDesc,
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
  columnId: string | undefined,
): AllFieldValueTypes => {
  const checboxListValues = valueReferenceDependency as Choice[];
  const referencesProperties = columnId?.split(',');

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
      const result = getValueFromChoiceProperties(checboxListValues, referencesProperties, true).join(', ');

      if (result === undefined) return undefined;

      if (typeof result === 'number') return numericLocaleFormatLitteral(result);

      return result;

    case FieldType.Notification:
      const { value } = fieldWithReference;
      const notificationValue = isQCNotification(value) ? value : mapToNotificationDataFromHashMap({});
      const res = getValueFromChoiceProperties(checboxListValues, referencesProperties, true);

      const resultNotification =
        splitAndTrim(res)
          /* eslint-disable-next-line no-useless-escape */
          .filter((item: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item))
          .map((item: string) => ({ alias: null, target: item } as NotificationTarget)) ?? [];

      return {
        ...notificationValue,
        selectedTargets: resultNotification,
      };

    case FieldType.CheckBox:
      return checboxListValues.length > 0 ? 1 : 0;

    case FieldType.Combo:
    case FieldType.HierarchicalList:
    case FieldType.CheckBoxList:
    case FieldType.RadioList:
    case FieldType.Alert:
      const { listChoice: listChoiceFromReferenceField } = fieldWithReference as ComboDesc;

      if (!listChoiceFromReferenceField) return;

      return getValueFromChoice(checboxListValues, listChoiceFromReferenceField, fieldTypeFieldWithReference);

    case FieldType.Counter:
    case FieldType.Digits:
      return parseInt(getValueFromChoiceProperties(checboxListValues, referencesProperties).join(', '));

    case FieldType.Numeric:
    case FieldType.Slider:
      const numVal: number | undefined = parseNumber(
        getValueFromChoiceProperties(checboxListValues, referencesProperties).join(', '),
      );

      return numVal;

    default:
      return undefined;
  }
};
