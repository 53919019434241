import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EntityFormsName } from './const';
import { MainBox } from './styles';
import { EntityFormsProps } from './types';
import ViewSkeleton from '10.quickConnect.app/components/domain/Home/Shared/Skeletons/viewSkeleton';
import CustomList from '10.quickConnect.app/components/domain/Home/Shared/CustomList';

import FormLine from '10.quickConnect.app/components/domain/Home/Forms/FormLine';
import { useStore } from '30.quickConnect.Stores';
import { FormViewer } from '90.quickConnect.Models/models';

const EntityForms = ({ schemaIds, entityId }: EntityFormsProps) => {
  // On récupère le store
  const {
    FormStore: { formsWithSchemaId },
  } = useStore();

  const { t } = useTranslation('equipment');

  const filteredForms = useMemo(
    () =>
      formsWithSchemaId
        ? formsWithSchemaId
            .filter((f) => schemaIds.includes(f.entitySchemaId ?? ''))
            .map((form) => ({ ...form, entityInstanceId: entityId } as FormViewer))
        : [],
    [entityId, formsWithSchemaId, schemaIds],
  );

  return (
    <Box data-cy={EntityFormsName} sx={MainBox}>
      {formsWithSchemaId ? <CustomList items={filteredForms} RenderLine={FormLine} /> : <ViewSkeleton />}
    </Box>
  );
};
export default observer(EntityForms);
