import { QCSBaseObject } from './QCSBaseObject';

export class QCSBaseTypedObject<T> extends QCSBaseObject {
  value: T;

  constructor(value: T) {
    super();
    this.value = value;
  }

  getValue(): T {
    super.getValue();
    return this.value;
  }
}
