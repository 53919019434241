import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useStore } from '30.quickConnect.Stores';
import { FieldDesc } from '90.quickConnect.Models/models';
import { UseConsentData } from '50.quickConnect.Fields/hooks/useConsent/types';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { ConsentFrequency } from '90.quickConnect.Models/enums';

export const useConsent = (
  flattenFields: FieldDesc[],
  formId: string,
  formName: string,
  userUPN: string,
): UseConsentData => {
  //* Lib Hooks
  const navigate = useNavigate();

  //* Custom hooks Region
  const {
    ConsentStore: {
      getConsentsForThisFormAndUserUPN,
      hasConsentsAsked,
      fieldsToAsked,
      onAcceptConsentAsync,
      onDeclineConsentAsync,
      resetStore,
    },
  } = useStore();

  //* UseMemo Region
  const hasConsentAskedFields = useMemo(
    () => flattenFields.filter((f: FieldDesc) => f.checkRGPD !== ConsentFrequency.Undef),
    [flattenFields],
  );

  //* UseCallBack Region
  const onAcceptConsent = useCallback(async () => {
    await onAcceptConsentAsync();
  }, [onAcceptConsentAsync]);

  const onDeclineConsent = useCallback(async () => {
    await onDeclineConsentAsync();
    navigate(-1);
  }, [onDeclineConsentAsync, navigate]);

  //* UseEffect Region
  useEffectOnce(() => {
    if (hasConsentAskedFields.length > 0) {
      (async () => {
        await getConsentsForThisFormAndUserUPN(formId, formName, userUPN, hasConsentAskedFields);
      })();
    }
  });

  return {
    hasConsentsAsked,
    fieldsToAsked,
    onAcceptConsent,
    onDeclineConsent,
  };
};
