import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

export const getReferencedRfidReaderValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const rfidReaderValue = typeof valueReferenceDependency === 'string' ? valueReferenceDependency : undefined;

  if (!rfidReaderValue) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.Separator:
    case FieldType.ReadOnlyValue:
    case FieldType.RfidReader:
      return rfidReaderValue;

    default:
      return undefined;
  }
};
