import { EntitySchemaAttribute } from '90.quickConnect.Models/models';

const mapEntitySchemaAttribute = (entitySchemaAttribute: EntitySchemaAttribute): EntitySchemaAttribute =>
  ({
    isSelectable: entitySchemaAttribute.isSelectable ?? '',
    isSelected: entitySchemaAttribute.isSelected ?? '',
    description: entitySchemaAttribute.description ?? '',
    type: entitySchemaAttribute.type ?? '',
    entityId: entitySchemaAttribute.entityId ?? '',
    customerId: entitySchemaAttribute.customerId ?? '',
    sharedListId: entitySchemaAttribute.sharedListId ?? '',
    isRequired: entitySchemaAttribute.isRequired ?? '',
    columnName: entitySchemaAttribute.columnName ?? '',
    propertyName: entitySchemaAttribute.propertyName ?? '',
    isSearchable: entitySchemaAttribute.isSearchable ?? '',
    listOrder: entitySchemaAttribute.listOrder ?? '',
    value: entitySchemaAttribute.value ?? '',
    defaultLanguage: entitySchemaAttribute.defaultLanguage ?? '',
  } as EntitySchemaAttribute);

export default mapEntitySchemaAttribute;
