import { AddressData, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';

/**
 * Perment de libeller tous les inputs du champ Address en une seule phrase
 */
const addressGetLabel = ({ streetNumber, street, complement, zipCode, city, country }: AddressData): string => {
  const result: string[] = [];

  if (streetNumber) result.push(streetNumber);
  if (street) result.push(street);
  if (complement) result.push(complement);
  if (zipCode) result.push(zipCode);
  if (city) result.push(city);
  if (country) result.push(country);

  return result.length > 0 ? result.join(' ') : '';
};

export const getReferencedAddressValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const addressValue = valueReferenceDependency as AddressData;
  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
      return addressGetLabel(addressValue);
    case FieldType.Geolocation:
      return addressValue.coordinates;
    case FieldType.Address:
      return addressValue;
    default:
      return undefined;
  }
};
