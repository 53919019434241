import { FieldDesc } from '90.quickConnect.Models/models';

export const getAllParentsFieldsAreVisible = (fields: FieldDesc[], fullPathId: string): boolean => {
  const field = fields.find(({ fullPathId: fullId }: FieldDesc) => fullId === fullPathId);

  if (!field) return false;

  const bVisibility = field.isVisibleOnDevice ?? field.isVisible;

  if (!fullPathId.includes('.')) return bVisibility;

  if (!bVisibility) return false;

  const parentFullPathId = fullPathId.substring(0, fullPathId.lastIndexOf('.'));

  return getAllParentsFieldsAreVisible(fields, parentFullPathId);
};
