import { i18n } from '80.quickConnect.Core/utils';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';
import NumberExtension from '80.quickConnect.Core/formatting/NumberExtension';

export class BooleanExtension {
  public static readonly TRUE_LABEL = i18n.t('qccore_true_label', { ns: 'common' });
  public static readonly FALSE_LABEL = i18n.t('qccore_false_label', { ns: 'common' });
  public static readonly TRUE_LABEL_EN = 'True';
  public static readonly FALSE_LABEL_EN = 'False';

  /**
   * Converti en boolean depuis une chaine de caractères ou retourne une default value
   * @param str
   * @param defaultValue
   * @returns
   */
  public static convertFromString(str: string, defaultValue?: boolean): boolean | undefined {
    const trueTranslated = i18n.t('qccore_true_label', { ns: 'common' });
    const falseTranslated = i18n.t('qccore_false_label', { ns: 'common' });
    if (StringExtension.isTheSame(str, trueTranslated)) return true;
    else if (StringExtension.isTheSame(str, falseTranslated)) return false;
    else if (StringExtension.isTheSame(str, BooleanExtension.TRUE_LABEL_EN)) return true;
    else if (StringExtension.isTheSame(str, BooleanExtension.FALSE_LABEL_EN)) return false;
    // Case des nombres en litteraux ...
    else if (NumberExtension.isAnLitteralNumber(str)) return str !== '0';

    return defaultValue;
  }

  public static toString(bool: boolean): string {
    return bool ? i18n.t('qccore_true_label', { ns: 'common' }) : i18n.t('qccore_false_label', { ns: 'common' });
  }

  public static isBool(input: unknown): input is boolean {
    return typeof input === 'boolean';
  }
}
