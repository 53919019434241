import { VisibilityType } from '../conditions/visibilityType';
import { ToolTipContainer } from '../toolTipContainer';
import { AllFieldValueTypes } from './allFieldValueType';
import { ConsentFrequency, FieldType, ImportanceLevel, Mandatory } from '90.quickConnect.Models/enums';
import { WithId } from '90.quickConnect.Models/models';

export type FieldDesc = WithId & {
  stateId: string | undefined;
  fieldType: FieldType;
  value: AllFieldValueTypes;
  errors?: string[] | undefined;
  fullPathId: string;
  lruCount?: string;
  label?: string | undefined;
  reset?: boolean | undefined;
  visible?: VisibilityType;
  isVisibleOnDevice?: boolean | undefined;
  toolTip?: ToolTipContainer | undefined;
  controller?: string | undefined;
  binding?: string | undefined;
  importance?: ImportanceLevel | undefined;
  reference?: string | undefined;
  fieldIsReadOnly: boolean;
  checkRGPD: ConsentFrequency;
  items: FieldDesc[];
  mandatory?: Mandatory | undefined;
  canDuplicate?: boolean | undefined;
  // value: string | string[] | number | boolean | undefined;
  useVariable?: string | undefined;
  // defaultValue: string | string[] | number | boolean | undefined;

  // ¯\_(ツ)_/¯
  stayOnSameLine?: boolean | undefined; // Le champs va être sur la même ligne?
  isSameLine?: boolean | undefined;
  isStateDiv?: boolean | undefined;
  injectedValue?: boolean | undefined;
  fieldDataInjected?: boolean | undefined;
  type?: string;
  isVisible: boolean;

  // desactivatedAutomation: Permet de désactiver l'appel aux mécaniques Référence et QCScript
  desactivatedAutomation?: boolean;
};

export const IsAnAttachmentField = (fieldType: FieldType): boolean => {
  switch (fieldType) {
    case FieldType.Attachment:
    case FieldType.Draw:
    case FieldType.FixedAttachment:
    case FieldType.FixedImage:
    case FieldType.Signature:
    case FieldType.Photo:
      return true;
    default:
      return false;
  }
};
