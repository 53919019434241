import { Choice } from '90.quickConnect.Models/models';
import { JsonWrapper, errorHandler } from '80.quickConnect.Core/helpers';
import { isChoice, isChoiceList } from '90.quickConnect.Models/guards';

const tag = '90.QuickConnect.Models/mappings/sharedList/mapChoiceListFromString.ts';

export default function mapChoiceListFromString(data: string): Choice[] {
  let result: Choice[] = [];

  if (data === '') return result;

  const str = data.trim();

  if (str.startsWith('[')) {
    try {
      const choices = JsonWrapper.parse<Choice[]>(isChoiceList)(str);

      if (choices) result = choices;
    } catch (error) {
      errorHandler(tag, error, 'mapChoiceListFromString');
      return [];
    }
  } else if (str.startsWith('{')) {
    try {
      const choice = JsonWrapper.parse<Choice>(isChoice)(str);
      if (choice) result = [choice];
    } catch (error) {
      errorHandler(tag, error, 'mapChoiceListFromString');
      return [];
    }
  } else {
    const sX = str.split(/[,;]/);

    for (const s of sX) {
      const sX2 = s.split('¤');

      if (sX2.length === 2) {
        const [lbl, val] = sX2;
        result.push({
          label: lbl,
          value: val,
          data: undefined,
        });
      } else {
        result.push({
          label: s.trim(),
          value: s.trim(),
          data: undefined,
        });
      }
    }
  }

  return result;
}
