import React from 'react';
import { Backdrop, Box, CircularProgress, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { QrCodeScanName } from './const';
import useData from './hooks';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';
import CenterItems from '10.quickConnect.app/components/shared/LayOut/TopBar/CenterItems';
import {
  ListColumnOrRowContainer,
  TabsMainBox,
  ListColumnContainer,
} from '10.quickConnect.app/components/domain/Home/styles';

const QRCodeScan = () => {
  const { t } = useTranslation('qrCode');
  const { code, setCode, searchEquipment } = useData(t);
  const theme = useTheme();

  return (
    <Box sx={{ width: { md: '80vw', lg: '80vw' }, margin: { md: '1em auto', lg: '1em auto' } }}>
      <Box data-cy={QrCodeScanName} sx={TabsMainBox}>
        <Box sx={ListColumnOrRowContainer(theme)}>
          <Box sx={ListColumnContainer}>
            <CenterItems showLeftMenu={false} pageTitle={t('qcapp_qrcode_scan_title')} searchView={true} />
            <SearchBar
              toolTip={t('search_bar')}
              filterInputValue={code}
              setFilter={setCode}
              filter={code}
              applyFilter={searchEquipment}
              clearFilter={() => setCode('')}
              setSearchIconToRigth={false}
            />

            <Backdrop open={false}>
              <CircularProgress />
            </Backdrop>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default observer(QRCodeScan);
