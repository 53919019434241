import { v4 as uuidv4 } from 'uuid';
import { AttachmentItemData } from '90.quickConnect.Models/models';
import { resizeBase64 } from '80.quickConnect.Core/helpers';

const mapAttachmentItemDataFromFile = async (file: File, valueFile: string): Promise<AttachmentItemData> => {
  const thumbnailFromFile = await resizeBase64(file);

  return {
    id: uuidv4(),
    localUri: valueFile,
    distantUri: '',
    title: file.name,
    fileName: file.name,
    creationDate: new Date(),
    purgeDate: '',
    type: file.type,
    size: file.size ?? 0,
    metadata: [],
    thumbnail: (thumbnailFromFile as string).replace(/^data:image\/[a-z]+;base64,/, ''),
    icon: '',
    file: file,
  } as AttachmentItemData;
};

export default mapAttachmentItemDataFromFile;
