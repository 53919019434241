/**
 * Les types de liste partagée disponible
 */
export enum SharedListType {
  /**
   * Liste simple
   */
  Simple = 0,
  /**
   *  Liste hierarchique
   */
  Hierarchical = 1,
  // /**
  //  *  Liste hierarchique
  //  */
  // Referential = 2,			// VMT - 24/07 - fonctionnalité pas encore prête à être livrée
}
