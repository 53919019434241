import { action, computed, makeAutoObservable, observable } from 'mobx';
import { FieldDesc } from '90.quickConnect.Models/models';
import RootStore from '30.quickConnect.Stores/RootStore';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { DialogHashMap } from '30.quickConnect.Stores/RootStore/DialogStore/types';

const initialItems: DialogHashMap = {};

class DialogStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/DialogStore/index.ts';

  RootStore: RootStore;

  logger: CustomLogger;

  items = initialItems;

  fullPathIdForFocus: string | undefined;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.RootStore = rootStore;
    this.logger = logger;

    makeAutoObservable(
      this,
      {
        items: observable,
        fullPathIdForFocus: observable,
        isAutoFocused: computed,

        setItems: action,
        setFullPathIdForFocus: action,
      },
      { autoBind: true },
    );
  }

  get isAutoFocused(): boolean {
    return (
      Object.values(this.items)
        .reduce((acc: FieldDesc[], current: FieldDesc[]) => [...acc, ...current], [])
        .findIndex((f: FieldDesc) => f.fullPathId === this.fullPathIdForFocus) !== 1 ?? false
    );
  }

  setItems = (fullPathId: string, items: FieldDesc[]) => {
    this.items[fullPathId] = items;
  };

  setFullPathIdForFocus = (fullPathId: string) => {
    this.fullPathIdForFocus = fullPathId;
  };

  resetStore = () => {
    this.items = initialItems;
    this.fullPathIdForFocus = undefined;
  };
}

export default DialogStore;
