import {
  parse,
  isDate,
  setDefaultOptions,
  format,
  parseISO,
  isValid,
  isPast,
  formatISO,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  addDays,
  subMinutes,
  addMinutes,
  toDate,
  isBefore,
  isAfter,
  startOfDay,
  isSameYear,
  isSameMonth,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameSecond,
} from 'date-fns';
import { utcToZonedTime, format as dateFnsTzFormat, zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { fr, enUS } from 'date-fns/locale';
import { DateTimeFieldType, DateTimeRangeType } from '90.quickConnect.Models/enums';
import { CtrlRefDateTime, FieldDesc } from '90.quickConnect.Models/models';
import { JsonWrapper, errorHandler } from '80.quickConnect.Core/helpers';
import { isCtrlRefDateTime } from '90.quickConnect.Models/guards';
import { parseTimeFromAPI } from '90.quickConnect.Models/mappings';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

/**
 * Permet d'etendre les fonctionnalités de l'objet Date de JS.
 *
 * @export
 * @class DateTimeExtension
 * @extends {Date}
 */
export class DateTimeExtension extends Date {
  // Tag
  private static readonly TAG = '80.quickConnect.Core/formatting/DataTimeExtension.ts';
  // // Default Pattern
  // public static readonly DEFAULT_FMT_FR = 'dd/MM/yyyy HH:mm:ss';
  // public static readonly DEFAULT_FMT = 'M/d/yyyy HH:mm:ss';

  // // Default TimeZoneOffset
  // private static readonly defaultTimeZoneOffset = format(new Date(), 'xxx');

  // Format date avec séparateur / (jour / mois / année sur 2 digits) - EN
  public static readonly SIMPLE_SHORT_SLASH_DATE_US = 'MM/dd/yy';
  public static readonly SIMPLE_SHORT_DASH_DATE_US = 'MM-dd-yy';
  public static readonly SIMPLE_SHORT_POINT_DATE_US = 'MM.dd.yy';
  public static readonly SIMPLE_SHORT_DATE_US = 'MM dd yy';

  // Format date avec séparateur / (jour / mois / année sur 2 digits) - FR
  public static readonly SIMPLE_SHORT_SLASH_DATE_FR = 'dd/MM/yy';
  public static readonly SIMPLE_SHORT_DASH_DATE_FR = 'dd-MM-yy';
  public static readonly SIMPLE_SHORT_POINT_DATE_FR = 'dd.MM.yy';
  public static readonly SIMPLE_SHORT_DATE_FR = 'dd MM yy';

  // Custom Pattern
  public static readonly PATTERN_FULL_DATE_LONG_TIME = 'EEEE, MMMM dd, yyyy HH:mm:ss a';

  public static readonly PATTERN_FULL_DATE_SHORT_TIME = 'EEEE, MMMM dd, yyyy HH:mm a';

  public static readonly PATTERN_SHORT_DATE = 'MM/dd/yyyy';

  public static readonly PATTERN_LONG_DATE = 'EEEE, MMMM dd, yyyy';

  public static readonly PATTERN_SHORT_TIME = 'hh:mm aaa';

  public static readonly PATTERN_LONG_TIME = 'HH:mm:ss a';

  public static readonly PATTERN_SHORT_DATE_LONG_TIME = 'MM/dd/yy HH:mm:ss a';

  public static readonly PATTERN_SHORT_DATE_SHORT_TIME = 'MM/dd/yy HH:mm a';

  public static readonly PATTERN_LONG_DATE_SHORT_TIME = 'MM/dd/yyyy hh:mm aaa';

  public static readonly PATTERN_FULL_DATE_LONG_TIME_FR = 'EEEE dd MMMM yyyy HH:mm:ss';

  public static readonly PATTERN_FULL_DATE_SHORT_TIME_FR = 'EEEE dd MMMM yyyy HH:mm';

  public static readonly PATTERN_SHORT_DATE_FR = 'dd/MM/yyyy';

  public static readonly PATTERN_LONG_DATE_FR = 'EEEE dd MMMM yyyy';

  public static readonly TIME_FORMATER = 'HH:mm';

  // TIME_FORMATER_HMS="HH:mm:ss"
  public static readonly TIME_FORMATER_HMS = 'HH:mm:ss';

  // TIME_FORMATER_FULL_SEPARATOR= "HH:mm:ss.SSSZ"
  public static readonly TIME_FORMATER_FULL_SEPARATOR = "HH:mm:ss.SSS'Z'";

  public static readonly PATTERN_SHORT_TIME_FR = 'HH:mm';

  public static readonly PATTERN_LONG_TIME_FR = 'HH:mm:ss';

  public static readonly PATTERN_SHORT_DATE_LONG_TIME_FR = 'dd/MM/yy HH:mm:ss';

  public static readonly PATTERN_SHORT_DATE_SHORT_TIME_FR = 'dd/MM/yy HH:mm';

  // Format date sans séparateur (jour mois année sur 4 digits) - FR
  public static readonly FULL_DATE_FORMATER_FR = 'dd MM yyyy';

  public static readonly FULL_DATE_SLASH_FORMATER_FR = 'dd/MM/yyyy';

  public static readonly FULL_DATE_DASH_FORMATER_FR = 'dd-MM-yyyy';

  public static readonly FULL_DATE_POINT_FORMATER_FR = 'dd.MM.yyyy';

  // Format date sans séparateur (jour mois année sur 4 digits) - FR
  public static readonly FULL_DATE_FORMATER_US = 'MM dd yyyy';

  public static readonly FULL_DATE_SLASH_FORMATER_US = 'MM/dd/yyyy';

  public static readonly FULL_DATE_DASH_FORMATER_US = 'MM-dd-yyyy';

  public static readonly FULL_DATE_POINT_FORMATER_US = 'MM.dd.yyyy';

  // Format date sans séparateur (jour mois année sur 4 digits) - EN
  public static readonly INVERTED_FULL_DATE_FORMATER = 'yyyy MM dd';

  public static readonly INVERTED_FULL_DATE_SLASH_FORMATER = 'yyyy/MM/dd';

  public static readonly INVERTED_FULL_DATE_DASH_FORMATER = 'yyyy-MM-dd';

  public static readonly INVERTED_FULL_DATE_POINT_FORMATER = 'yyyy.MM.dd';

  // Format date et heure (sans séparateur: année sur 2 digits+mois+jour) - heure+minutes+seconds sur 2 digits
  public static readonly SIMPLE_SHORT_DATE_AND_TIME_2 = 'yyMMdd-HHmmss';

  // Format date et heure (avec séparateur / (jour / mois / année sur 2 digits) et heure:minutes
  public static readonly SIMPLE_SHORT_DATE_AND_TIME_US = 'MM/dd/yy HH:mm';

  // Format date et heure simple
  public static readonly SIMPLE_DATE_TIME_FORMATER_US = 'MM/dd/yyyy HH:mm';

  // Format date et heure simple
  public static readonly INVERTED_SIMPLE_DATE_TIME_FORMATER = 'yyyy/MM/dd HH:mm';

  //Format complet avec les ms basé sur l'ISO 6801 avec des séparateurs
  public static readonly FORMAT_ISO_8601_FULL_SEPARATOR = "yyyy-MM-dd'T'HH:mm:ss.SSSXX";

  //Format complet avec les ms basé sur l'ISO 6801 avec des séparateurs
  public static readonly FORMAT_ISO_8601_TZ = "yyyy-MM-dd'T'HH:mm:ssXX";

  public static readonly FORMAT_ISO_8601_TZ_WITH_DOT = "yyyy-MM-dd'T'HH:mm:ssXXX";

  // Format date et heure (avec séparateur / (jour / mois / année sur 2 digits) et heure:minutes
  public static readonly SIMPLE_SHORT_DATE_AND_TIME_FR = 'dd/MM/yy HH:mm';

  // Format date et heure simple
  public static readonly SIMPLE_DATE_TIME_FORMATER_FR = 'dd/MM/yyyy HH:mm';

  public static readonly SIMPLE_DATE_TIME_FORMATTER2_SLASH = 'dd/MM/yyyy HH:mm:ss';
  public static readonly SIMPLE_DATE_TIME_FORMATTER2_DASH = 'dd-MM-yyyy HH:mm:ss';
  public static readonly SIMPLE_DATE_TIME_FORMATTER2_DOT = 'dd.MM.yyyy HH:mm:ss';

  public static readonly INVERTED_SIMPLE_DATE_TIME_FORMATTER2_SLASH = 'yyyy/MM/dd HH:mm:ss';
  public static readonly INVERTED_SIMPLE_DATE_TIME_FORMATTER2_DASH = 'yyyy-MM-dd HH:mm:ss';
  public static readonly INVERTED_SIMPLE_DATE_TIME_FORMATTER2_DOT = 'yyyy.MM.dd HH:mm:ss';

  // Format complet basé sur l'ISO 6801 avec des séparateurs
  public static readonly FORMAT_ISO_8601_SEPARATOR = "yyyy-MM-dd'T'HH:mm:ss'Z'";
  public static readonly FORMAT_ISO_8601_SEPARATOR_WITHOUT_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss";
  public static readonly FORMAT_ISO_8601_FULL_SEPARATOR_WITHOUT_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss.SSS";

  // Format de base de la norme ISO 6801 avec millisecondes sur 24 character on rajoute 'Z'
  public static readonly FORMAT_ISO_8601_LEN_24 = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

  // Format complet basé sur l'ISO 6801 avec des séparateurs
  public static readonly FORMAT_ISO_8601_SEPARATOR_TZ = "yyyy-MM-dd'T'HH:mm:ss.SSSxx";

  // Format complet basé sur l'ISO 6801 avec les séparateurs en forcant l'offset à 00:00 pour conversion utc
  public static readonly FORMAT_ISO_8601_SEPARATOR_TZ_UTC = "yyyy-MM-dd'T'HH:mm:ss:SSS+00:00";

  // Permet d'indiquer si la date est en utc
  public isUTC: boolean = Intl.DateTimeFormat().resolvedOptions().timeZone === 'UTC' ?? false;

  // Permet d'avoir une représentation Textuelle de la date sous la norme
  public fromDateTimeToText: string;

  public constructor(date?: Date | string, isUTC?: boolean) {
    super(date ?? new Date());
    if (isUTC !== undefined) this.isUTC = isUTC;

    const formatterString = isUTC
      ? DateTimeExtension.FORMAT_ISO_8601_SEPARATOR_TZ_UTC
      : DateTimeExtension.FORMAT_ISO_8601_SEPARATOR_TZ;

    if (date instanceof Date) {
      this.fromDateTimeToText = format(date, formatterString);
    } else if (typeof date === 'string') {
      const dateCreated = DateTimeExtension.parseFromFormats(date, formatterString);
      this.fromDateTimeToText = dateCreated
        ? format(dateCreated, formatterString)
        : format(new Date(), formatterString);
    } else {
      this.fromDateTimeToText = format(new Date(), formatterString);
    }
  }

  public setIsUTC = (isUTC: boolean): void => {
    this.isUTC = isUTC;
  };

  public static formatCustom = (dt: DateTimeExtension | Date, fmt: string): string => {
    try {
      type DateFnsFormatOptions = {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
      };

      const options: DateFnsFormatOptions = {
        useAdditionalWeekYearTokens: true,
        locale: this.getLanguageCode() === 'FR' ? fr : enUS,
      };

      const utcRequired: boolean =
        dt instanceof DateTimeExtension
          ? dt.isUTC
          : Intl.DateTimeFormat().resolvedOptions().timeZone === 'UTC' ?? false;

      return utcRequired
        ? formatInTimeZone(zonedTimeToUtc(dt, Intl.DateTimeFormat().resolvedOptions().timeZone), 'UTC', fmt)
        : format(dt, fmt, options);
    } catch (error: unknown) {
      errorHandler(DateTimeExtension.TAG, error, 'formatCustom');
      return '';
    }
  };

  public static formatInTimeZoneUTC = (date: Date | string, fmt: string, tz = 'UTC'): string => {
    const dateParsed = utcToZonedTime(date, tz);

    return dateFnsTzFormat(dateParsed, fmt, { timeZone: tz });
  };

  public static isAValidDateTime(input: unknown): input is Date {
    return isDate(input) && !Number.isNaN((input as Date).getTime());
  }

  /**
   * Parser une date sérialisée potentiellement dans différents formats
   *
   * @param serializedDate une date sérialisée dans un format donné
   * @return la date désérialisée
   */
  public static parseMultiFormat(serializedDate: string): Date | null {
    if (serializedDate === '') {
      return null;
    }
    const langCode: string = this.getLanguageCode().toLocaleUpperCase();
    if (langCode === 'EN') {
      return this.parseMultiFormat_US(serializedDate);
    } else {
      return this.parseMultiFormat_FR(serializedDate);
    }
  }

  /**
   * Utilise les variables US pour parser la date
   *
   * @param serializedDate
   * @return
   */
  public static parseMultiFormat_US(serializedDate: string): Date | null {
    const len: number = serializedDate.length;

    try {
      switch (len) {
        case 8: // "MM/dd/yy" ou "MM-dd-yy" ou "MM.dd.yy" ou "MM dd yy"
          if (serializedDate.indexOf('/') > 0) {
            //"dd/MM/yy"
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_SLASH_DATE_US);
          } else if (serializedDate.indexOf('-') > 0) {
            //"dd-MM-yy";
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DASH_DATE_US);
          } else if (serializedDate.indexOf('.') > 0) {
            //"dd.MM.yy";
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_POINT_DATE_US);
          } else {
            // "dd MM yy"
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DATE_US);
          }
        case 10:
          if (serializedDate.indexOf('/') > 0) {
            // "MM/dd/yyyy" et "yyyy/MM/dd"
            return this.parseFromFormats(
              serializedDate,
              this.FULL_DATE_SLASH_FORMATER_US,
              this.INVERTED_FULL_DATE_SLASH_FORMATER,
            );
          } else if (serializedDate.indexOf('-') > 0) {
            // "MM-dd-yyyy" et "yyyy-MM-dd"
            return this.parseFromFormats(
              serializedDate,
              this.FULL_DATE_DASH_FORMATER_US,
              this.INVERTED_FULL_DATE_DASH_FORMATER,
            );
          } else if (serializedDate.indexOf('.') > 0) {
            // "MM.dd.yyyy" et "yyyy.MM.dd"
            return this.parseFromFormats(
              serializedDate,
              this.FULL_DATE_POINT_FORMATER_US,
              this.INVERTED_FULL_DATE_POINT_FORMATER,
            );
          } else {
            // "MM dd yyyy" et "yyyy MM dd"
            return this.parseFromFormats(serializedDate, this.FULL_DATE_FORMATER_US, this.INVERTED_FULL_DATE_FORMATER);
          }

        case 13: //  201013 - 095900 :   yyMMdd-HHmmss
          return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DATE_AND_TIME_2);
        case 14: // SIMPLE_SHORT_DATE_AND_TIME= "MM/dd/yy HH:mm");
          return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DATE_AND_TIME_US);
        case 16: //"MM/dd/yyyy HH:mm" et "yyyy/MM/dd HH:mm"
          return this.parseFromFormats(
            serializedDate,
            this.SIMPLE_DATE_TIME_FORMATER_US,
            this.INVERTED_SIMPLE_DATE_TIME_FORMATER,
          );
        case 19:
        case 20:
        case 23:
        case 24:
        case 25:
          return this.parseSerializedDateIso8601(serializedDate);
        default: // Et par defaut FORMAT_ISO_8601_FULL_SEPARATOR = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
          return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_FULL_SEPARATOR);
      }
    } catch (e: unknown) {
      if (e instanceof Error) e.message = `Erreur de parsing de la date US: ${serializedDate}`;
      errorHandler(DateTimeExtension.TAG, e, 'parseMultiFormat_US');
      return null;
    }
  }

  /**
   * Utilise les variables FR pour parser la date
   *
   * @param serializedDate
   * @return
   */
  public static parseMultiFormat_FR(serializedDate: string): Date | null {
    const len: number = serializedDate.length;
    try {
      switch (len) {
        case 5:
          return DateTimeExtension.parseTimeFromString(serializedDate);
        case 8: //"dd/MM/yy" ou "dd-MM-yy" ou "dd.MM.yy" ou "dd MM yy"
          if (serializedDate.indexOf('/') > 0) {
            //"dd/MM/yy"
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_SLASH_DATE_FR);
          } else if (serializedDate.indexOf('-') > 0) {
            //"dd-MM-yy";
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DASH_DATE_FR);
          } else if (serializedDate.indexOf('.') > 0) {
            //"dd.MM.yy";
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_POINT_DATE_FR);
          } else {
            // "dd MM yy"
            return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DATE_FR);
          }
          break;
        case 10:
          if (serializedDate.indexOf('/') > 0) {
            // "dd/MM/yyyy" et "yyyy/MM/dd"
            return this.parseFromFormats(
              serializedDate,
              this.FULL_DATE_SLASH_FORMATER_FR,
              this.INVERTED_FULL_DATE_SLASH_FORMATER,
            );
          } else if (serializedDate.indexOf('-') > 0) {
            // "dd-MM-yyyy" et "yyyy-MM-dd"
            return this.parseFromFormats(
              serializedDate,
              this.FULL_DATE_DASH_FORMATER_FR,
              this.INVERTED_FULL_DATE_DASH_FORMATER,
            );
          } else if (serializedDate.indexOf('.') > 0) {
            // "dd.MM.yyyy" et "yyyy.MM.dd"
            return this.parseFromFormats(
              serializedDate,
              this.FULL_DATE_POINT_FORMATER_FR,
              this.INVERTED_FULL_DATE_POINT_FORMATER,
            );
          } else {
            // "dd MM yyyy" et "yyyy MM dd"
            return this.parseFromFormats(serializedDate, this.FULL_DATE_FORMATER_FR, this.INVERTED_FULL_DATE_FORMATER);
          }
          break;
        case 13: //  201013 - 095900 :   yyMMdd-HHmmss
          return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DATE_AND_TIME_2);
          break;
        case 14: // SIMPLE_SHORT_DATE_AND_TIME= "dd/MM/yy HH:mm");
          return this.parseFromFormats(serializedDate, this.SIMPLE_SHORT_DATE_AND_TIME_FR);
          break;
        case 16: // SIMPLE_DATE_TIME_FORMATER = "dd/MM/yyyy HH:mm";
          return this.parseFromFormats(
            serializedDate,
            this.SIMPLE_DATE_TIME_FORMATER_FR,
            this.INVERTED_SIMPLE_DATE_TIME_FORMATER,
          );
          break;
        case 19:
          return this.parseFromFormats(
            serializedDate,
            DateTimeExtension.SIMPLE_DATE_TIME_FORMATTER2_SLASH,
            DateTimeExtension.SIMPLE_DATE_TIME_FORMATTER2_DASH,
            DateTimeExtension.SIMPLE_DATE_TIME_FORMATTER2_DOT,
            DateTimeExtension.INVERTED_SIMPLE_DATE_TIME_FORMATTER2_SLASH,
            DateTimeExtension.INVERTED_SIMPLE_DATE_TIME_FORMATTER2_DASH,
            DateTimeExtension.INVERTED_SIMPLE_DATE_TIME_FORMATTER2_DOT,
          );
        case 20:
        case 23:
        case 24:
        case 25:
          return this.parseSerializedDateIso8601(serializedDate);
          break;
        default: // Et par defaut FORMAT_ISO_8601_FULL_SEPARATOR = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
          return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_FULL_SEPARATOR);
          break;
      }
    } catch (e: unknown) {
      if (e instanceof Error) e.message = `Erreur de parsing de la date FR: ${serializedDate}`;
      errorHandler(DateTimeExtension.TAG, e, 'parseMultiFormat_FR');
      return null;
    }
  }

  public static getPatternFromCustomPattern = (customPattern: string): string => {
    const localeCode: string = this.getLanguageCode();

    switch (customPattern) {
      case 'd':
        return localeCode === 'EN' ? DateTimeExtension.PATTERN_SHORT_DATE : DateTimeExtension.PATTERN_SHORT_DATE_FR;

      case 'D':
        return localeCode === 'EN' ? DateTimeExtension.PATTERN_LONG_DATE : DateTimeExtension.PATTERN_LONG_DATE_FR;

      case 'f':
        return localeCode === 'EN'
          ? DateTimeExtension.PATTERN_FULL_DATE_SHORT_TIME
          : DateTimeExtension.PATTERN_FULL_DATE_SHORT_TIME_FR;

      case 'F':
        return localeCode === 'EN'
          ? DateTimeExtension.PATTERN_FULL_DATE_LONG_TIME
          : DateTimeExtension.PATTERN_FULL_DATE_LONG_TIME_FR;

      case 't':
        return localeCode === 'EN' ? DateTimeExtension.PATTERN_SHORT_TIME : DateTimeExtension.PATTERN_SHORT_TIME_FR;

      case 'T':
        return localeCode === 'EN' ? DateTimeExtension.PATTERN_LONG_TIME : DateTimeExtension.PATTERN_LONG_TIME_FR;

      case 'g':
        return localeCode === 'EN'
          ? DateTimeExtension.PATTERN_SHORT_DATE_SHORT_TIME
          : DateTimeExtension.PATTERN_SHORT_DATE_SHORT_TIME_FR;

      case 'G':
        return localeCode === 'EN'
          ? DateTimeExtension.PATTERN_SHORT_DATE_LONG_TIME
          : DateTimeExtension.PATTERN_SHORT_DATE_LONG_TIME_FR;

      default:
        return '';
    }
  };

  private static getLanguageCode(): string {
    return window.navigator.language.substring(0, 2).toUpperCase();
  }

  private static setOptions() {
    setDefaultOptions({ locale: this.getLanguageCode().toLocaleLowerCase() === 'fr' ? fr : enUS });
  }

  private static parseFromFormats(...args: string[]): DateTimeExtension | null {
    const [strValue, ...formaters] = args;
    this.setOptions();
    return formaters.reduce((acc: DateTimeExtension | null, formater: string) => {
      // if (strValue.length !== formater.length) return acc;
      let dateParsed = parse(strValue, formater, new Date());

      if (isNaN(dateParsed.getTime())) {
        dateParsed = parseISO(strValue);
      }

      return isDate(dateParsed) && !isNaN(dateParsed.getTime()) ? new DateTimeExtension(dateParsed) : acc;
    }, null);
  }

  private static parseSerializedDateIso8601(serializedDate: string): Date | null {
    const len: number = serializedDate.length;
    switch (len) {
      case 19: //FORMAT_ISO_8601_SEPARATOR_WITHOUT_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss";
        return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_SEPARATOR_WITHOUT_TIMEZONE);

      case 20: // FORMAT_ISO_8601_SEPARATOR = "yyyy-MM-dd'T'HH:mm:ss'Z'";
        return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_SEPARATOR);

      case 23: //FORMAT_ISO_8601_SEPARATOR_WITHOUT_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss.SSS";
        return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_FULL_SEPARATOR_WITHOUT_TIMEZONE);

      case 24: // FORMAT yyyy-MM-dd'T'HH:mm:ss.SSSZ  --> on le format avec  yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
        return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_LEN_24);

      case 25: // FORMAT_ISO_8601_SEPARATOR = "yyyy-MM-dd'T'HH:mm:ssXXX";
        return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_TZ, this.FORMAT_ISO_8601_TZ_WITH_DOT);
      default:
        return this.parseFromFormats(serializedDate, this.FORMAT_ISO_8601_SEPARATOR_TZ);
    }
  }

  /**
   * Permet d'avoir une chaine de caractère formatée depuis depuis une date un DateTimeFieldType;
   *
   * @static
   * @param {Date} dt
   * @param {DateTimeFieldType} dateTimeType
   * @memberof DateTimeExtension
   */
  public static formatHumanReadeable = (dt: Date, dateTimeType?: DateTimeFieldType): string => {
    const lang = this.getLanguageCode();
    const dateFmt =
      lang === 'FR' ? DateTimeExtension.SIMPLE_SHORT_SLASH_DATE_FR : DateTimeExtension.SIMPLE_SHORT_SLASH_DATE_US;
    const dateTimeFmt =
      lang === 'FR'
        ? DateTimeExtension.PATTERN_SHORT_DATE_SHORT_TIME_FR
        : DateTimeExtension.PATTERN_SHORT_DATE_SHORT_TIME;
    const fmt = dateTimeType === DateTimeFieldType.DateTime ? dateTimeFmt : dateFmt;

    return format(dt, fmt);
  };

  /**
   * Permet de savoir si la valeur donnée est comprise dans un intervalle donné
   *
   * @static
   * @param {number} value
   * @memberof DateTimeExtension
   */
  public static checkTime = (value: number, minRange = 0, maxRange = 60): boolean =>
    value >= minRange && value < maxRange;

  /**
   * Permet de vérifier si la chaine de caractère passée en paramètre correspond à une date en UTC (Match par les chiffres du décalage horaire)
   *
   * @static
   * @param {string} value
   * @memberof DateTimeExtension
   */
  public static isUTCString = (value: string): boolean => {
    const regExpForUTC = new RegExp('00:?00$', 'gi');

    return regExpForUTC.test(value);
  };

  public static createUTCTimeFromDate = (dt: Date | DateTimeExtension): DateTimeExtension => {
    if (dt instanceof DateTimeExtension && dt.isUTC === true) return dt;

    return new DateTimeExtension(
      new Date(
        dt.getUTCFullYear(),
        dt.getUTCMonth(),
        dt.getUTCDate(),
        dt.getUTCHours(),
        dt.getUTCMinutes(),
        dt.getUTCSeconds(),
      ),
      true,
    );
  };

  public static createLocalDateTimeFromString = (
    str: string,
    formatter = DateTimeExtension.FORMAT_ISO_8601_SEPARATOR_TZ,
  ): DateTimeExtension | null => {
    return DateTimeExtension.parseFromFormats(str, formatter);
  };

  public static createUTCDateTimeFromString = (
    str: string,
    formatter = DateTimeExtension.FORMAT_ISO_8601_SEPARATOR_TZ_UTC,
  ): DateTimeExtension | null => {
    const dateCreated = DateTimeExtension.parseFromFormats(str, formatter);

    if (!dateCreated) return null;

    if (isNaN(dateCreated.getTime())) return null;

    return new DateTimeExtension(dateCreated, true);
  };

  /**
   * Permet de savoir si la date donnée à plus de 24 heures d'intervalle (utilisé pour la sync des tables d'enregistrement).
   * @param {string} dateStr
   */
  public static isPast24Hours = (dateStr: string): boolean | never => {
    if (dateStr === '') return true;
    const dt: Date = parseISO(dateStr);

    if (!isValid(dt))
      throw new Error(
        `[Client Web] - DateTimeExtension.ts, isPast24Hours method failed: Parameter ${dateStr} is not a correct date formatted`,
      );

    return isPast(dt) && differenceInDays(dt, new Date()) >= 1;
  };

  public static setISOFormat = (dt: Date): string | never => {
    if (!isValid(dt))
      throw new Error(
        `[Client Web] - DateTimeExtension.ts, setISOFormat method failed: Parameter ${dt} is not a correct date formatted`,
      );

    return formatISO(dt);
  };

  public static getDateFromControlRef = (allFields: FieldDesc[], ctrlRef?: string): Date | undefined => {
    if (!ctrlRef || allFields.length === 0) return undefined;

    if (ctrlRef.includes('controlId')) {
      const dataValue = JsonWrapper.parse(isCtrlRefDateTime)(ctrlRef);

      if (!dataValue) return undefined;

      const field = allFields.find(
        ({ fullPathId, id }: FieldDesc) => id === dataValue.controlId || dataValue.controlId === fullPathId,
      );

      if (!field) return undefined;

      return isDate(field.value) ? (field.value as Date) : undefined;
    }
  };

  public static getDateFromCustomValue(minValue: string): Date | undefined {
    const result: Date = new Date();
    const customValueRegExp = new RegExp('^([-+]?d[dwmy])+$', 'g');
    const matches = customValueRegExp.exec(minValue);

    if (!matches) return result;
  }

  public static getDateFromSplitString = (allFields: FieldDesc[], str: string): Date | undefined => {
    if (allFields.length === 0 || str.length === 0) return undefined;
    const strs: string[] = str.split('.');
    const controlId: string | undefined = strs.pop();

    if (!controlId) return undefined;

    const field = allFields.find(({ fullPathId, id }: FieldDesc) => controlId === id || controlId === fullPathId);

    if (!field) return undefined;

    return isDate(field.value) ? (field.value as Date) : undefined;
  };

  public static lessThanOneDay(dateReference?: Date, dateToCompare?: Date): boolean {
    if (!dateReference || !dateToCompare) return false;

    const diff = differenceInDays(dateReference, dateToCompare);

    return diff < 1;
  }

  public static lessThanOneWeek(dateReference?: Date, dateToCompare?: Date): boolean {
    if (!dateReference || !dateToCompare) return false;

    const diff = differenceInWeeks(dateReference, dateToCompare);

    return diff < 1;
  }

  public static lessThanOneMonth(dateReference?: Date, dateToCompare?: Date): boolean {
    if (!dateReference || !dateToCompare) return false;

    const diff = differenceInMonths(dateReference, dateToCompare);

    return diff < 1;
  }

  public static lessThanOneYear(dateReference: Date, dateToCompare?: Date): boolean {
    if (!dateReference || !dateToCompare) return false;

    const diff = differenceInYears(dateReference, dateToCompare);

    return diff < 1;
  }

  public static getTomorrowDate(): Date {
    return addDays(new Date(), 1);
  }

  public static formatDateOrDateTime(date: DateTimeExtension | Date, type: DateTimeFieldType): string {
    if (!(date instanceof Date)) return '';

    const language = DateTimeExtension.getLanguageCode();

    if (language.localeCompare('fr', undefined, { sensitivity: 'base' }) === 0) {
      return type === DateTimeFieldType.DateTime
        ? format(date, DateTimeExtension.SIMPLE_DATE_TIME_FORMATER_FR)
        : format(date, DateTimeExtension.PATTERN_SHORT_DATE_FR);
    } else {
      return type === DateTimeFieldType.DateTime
        ? format(date, DateTimeExtension.PATTERN_LONG_DATE_SHORT_TIME)
        : format(date, DateTimeExtension.PATTERN_SHORT_DATE);
    }
  }

  public static formatTime(date: DateTimeExtension | Date): string {
    if (!(date instanceof Date)) return '';

    const language = DateTimeExtension.getLanguageCode();

    return language.localeCompare('fr', undefined, { sensitivity: 'base' }) === 0
      ? format(date, DateTimeExtension.PATTERN_SHORT_TIME_FR)
      : format(date, DateTimeExtension.PATTERN_SHORT_TIME);
  }

  public static soustractMinutes(date: Date, amount: number): Date | never {
    if (!(date instanceof Date)) throw new Error(`date parameter must be instance of Date, type given: ${typeof date}`);

    return subMinutes(date, amount);
  }

  public static addMinutes(date: Date, amount: number): Date | never {
    if (!(date instanceof Date)) throw new Error(`date parameter must be instance of Date, type given: ${typeof date}`);

    return addMinutes(date, amount);
  }

  public static isDate(input: unknown): input is Date {
    return isDate(input);
  }

  public static formatShortTime(d: Date): string {
    if (Number.isNaN(d.getTime())) return '';
    return format(d, DateTimeExtension.TIME_FORMATER);
  }

  public static formatShortDateAndTime(d: Date): string {
    if (Number.isNaN(d.getTime())) return '';

    return format(d, 'dd/MM/yy HH:mm');
  }

  public static formatIso8601(d: Date): string {
    if (Number.isNaN(d.getTime())) return '';

    return format(d, DateTimeExtension.FORMAT_ISO_8601_FULL_SEPARATOR);
  }

  public static mapFromNumber(num: number): Date | null {
    if (num < 0) return null;

    return toDate(num);
  }

  public static getRangeDateTimeValue(rangeValue: string, fields: FieldDesc[]): Date | undefined {
    return rangeValue.includes('controlId')
      ? DateTimeExtension.getDateFromControlRef(fields, rangeValue)
      : DateTimeExtension.parseMultiFormat(rangeValue) ?? undefined;
  }

  public static getDateBefore(dt1: Date, dt2: Date | undefined): Date {
    if (!dt2) return dt1;
    else return isBefore(dt1, dt2) ? dt2 : dt1;
  }

  public static getDateAfter(dt1: Date, dt2: Date | undefined): Date {
    if (!dt2) return dt1;
    else return isAfter(dt1, dt2) ? dt2 : dt1;
  }

  public static getDateBetween(dt: Date, minDt: Date | undefined, maxDt: Date | undefined): Date {
    if (minDt && dt.getTime() < minDt.getTime()) return minDt;
    else if (maxDt && dt.getTime() > maxDt.getTime()) return maxDt;
    else return dt;
  }

  public static getValueInRange(
    datetimeValue: Date,
    fields: FieldDesc[],
    min: string | undefined,
    max: string | undefined,
  ): Date {
    if (min && max) {
      const minValue = DateTimeExtension.getRangeDateTimeValue(min, fields);
      const maxValue = DateTimeExtension.getRangeDateTimeValue(max, fields);

      return DateTimeExtension.getDateBetween(datetimeValue, minValue, maxValue);
    } else if (!min && max)
      return DateTimeExtension.getDateAfter(datetimeValue, DateTimeExtension.getRangeDateTimeValue(max, fields));
    else if (!max && min)
      return DateTimeExtension.getDateBefore(datetimeValue, DateTimeExtension.getRangeDateTimeValue(min, fields));
    else return datetimeValue;
  }

  public static getValueInTimeRange(timeValue: Date, min: Date | undefined, max: Date | undefined): Date {
    if (min && max) {
      return DateTimeExtension.getDateBetween(timeValue, min, max);
    } else if (!min && max) {
      return DateTimeExtension.getDateAfter(timeValue, max);
    } else if (min && !max) {
      return DateTimeExtension.getDateBefore(timeValue, min);
    } else return timeValue;
  }

  public static getDateFromDateTime(dt: Date): Date {
    return startOfDay(dt);
  }

  public static getDateTimeFromString(str: string): Date | null {
    const dateTimeRegex = /^(\d{4})[-./]?(\d{2})[-./]?(\d{2})(?: (\d{2}):(\d{2})(?::(\d{2}))?)?$/;

    const match = str.match(dateTimeRegex);

    if (!match) return null;
    const [, years, months, days, hours = '00', minutes = '00', seconds = '00'] = match;
    const month = parseInt(months) - 1;
    const year = parseInt(years);
    const monthFmt = parseInt(months) - 1 < 0 ? 0 : parseInt(months) - 1 > 11 ? 11 : parseInt(months) - 1;
    const day = parseInt(days);
    const hour = parseInt(hours);
    const minute = parseInt(minutes);
    const second = parseInt(seconds);

    const dt = new Date(year, monthFmt, day, hour, minute, second, 0);
    const realDt = DateTimeExtension.parseMultiFormat(str);

    if (!realDt) return null;

    return isSameYear(dt, realDt) &&
      isSameMonth(dt, realDt) &&
      isSameDay(dt, realDt) &&
      isSameHour(dt, realDt) &&
      isSameMinute(dt, realDt) &&
      isSameSecond(dt, realDt)
      ? realDt
      : null;
  }

  public static parseTimeFromDateTime(val: Date): Date {
    const hours = val.getHours();
    const minutes = val.getMinutes();

    return new Date(1970, 0, 1, hours, minutes, 0, 0);
  }

  public static parseToTime1970(val: string | Date): Date | null {
    if (val instanceof Date) return DateTimeExtension.parseTimeFromDateTime(val);
    else if (typeof val === 'string') {
      const dt = DateTimeExtension.parseTimeFromString(val);

      return !dt ? null : DateTimeExtension.parseTimeFromDateTime(dt);
    } else return null;
  }

  public static parseTimeFromDateTime1970(val: Date): Date {
    const hours = val.getHours();
    const minutes = val.getMinutes();

    const dt = new Date();
    dt.setHours(hours);
    dt.setMinutes(minutes);

    return dt;
  }

  public static parseFromTime1970(val: string | Date): Date | undefined {
    if (val instanceof Date) return DateTimeExtension.parseTimeFromDateTime1970(val);
    else if (typeof val === 'string') return parseTimeFromAPI(val);
    else return undefined;
  }

  public static parseTimeFromString(str: string): Date | null {
    if (StringExtension.isNullOrEmpty(str)) return null;

    const len = str.startsWith('1970-01-01T') ? str.substring(0, 11).length : str.length;

    switch (len) {
      case 5: // TIME_FORMATER= "HH:mm";
        return DateTimeExtension.parseFromFormats(str, DateTimeExtension.TIME_FORMATER);

      case 8: // TIME_FORMATER_HMS="HH:mm:ss";
        return DateTimeExtension.parseFromFormats(str, DateTimeExtension.TIME_FORMATER_HMS);

      case 13: // Et par defaut TIME_FORMATER_FULL_SEPARATOR= "HH:mm:ss.SSSZ";
        return DateTimeExtension.parseFromFormats(str, DateTimeExtension.TIME_FORMATER_FULL_SEPARATOR);

      default:
        return null;
    }
  }
}
