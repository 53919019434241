import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import useDeclarations from '10.quickConnect.app/components/domain/Home/Declarations/hooks';
import useForms from '10.quickConnect.app/components/domain/Home/Forms/hooks';

const useData = (): UseDataProps => {
  // On récupère le store
  const {
    CommonStore: { showLeftMenu },
  } = useStore();

  useDeclarations();
  useForms();

  return {
    showLeftMenu,
  };
};

export default useData;
