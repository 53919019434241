import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { NumericMaxValue, NumericMinValue, UseDataProps } from './types';
import { AllFieldValueTypes, CounterDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { isNumeric } from '80.quickConnect.Core/helpers/common';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  counterDesc: CounterDesc,
  updateDeclaration: (updatedFieldId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, min, max, value, reference } = counterDesc;
  const minValue = min ?? NumericMinValue;
  const maxValue = max ?? NumericMaxValue;

  const {
    DeclarationStore: { isDeepLink },
  } = useStore();

  const [localValue, setLocalValue] = useState<number | string>(() => {
    if (isNumeric(value) && !Number.isNaN(value)) {
      return parseInt(value.toString());
    } else {
      return '';
    }
  });

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue !== '' ? localValue : null);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      setLocalValue('');
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const val = event.target.value;
      if (val != '') {
        let parsed = Number.parseInt(event.target.value);
        if (!Number.isNaN(parsed)) {
          if (parsed < minValue) {
            parsed = minValue;
          } else if (parsed > maxValue) {
            parsed = maxValue;
          }
          setLocalValue(parsed);
        }
      } else {
        setLocalValue('');
      }
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState, maxValue, minValue],
  );

  // UseEffect pour le système des références...
  useEffect(() => {
    if (value === null && isDeepLink) setLocalValue('');
    else {
      const newLocalValue = isNumeric(value) ? (roundTo(+value, 0) as string) : '';
      setLocalValue((prevLocalValue) =>
        prevLocalValue !== newLocalValue ? newLocalValue ?? minValue : prevLocalValue,
      );
    }
  }, [reference, value, minValue, isDeepLink]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    setLocalValue,
    handleChange,
    handleClear,
    minValue,
    maxValue,
  };
};

export default useData;
