import React from 'react';
import { Autocomplete, Checkbox, Chip, FormControlLabel, IconButton, TextField, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import useData from './hook';
import { NotificationQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { NotificationDesc, NotificationTarget } from '90.quickConnect.Models/models';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import HistoryQCField from '50.quickConnect.Fields/FieldsTypes/Others/HistoryQC';
import { OptionsRender } from '50.quickConnect.Fields/FieldsTypes/Inputs/NotificationQcField/types';

const NotificationQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  isReadOnly,
  labelClass,
  selectedIndex,
  setSelectedIndex,
}: ValueFieldsTypesProps) => {
  const notificationDesc = field as NotificationDesc;
  const { id, dataSource, fullPathId } = notificationDesc;

  const nameWithId = `${NotificationQcFieldName}-${id}`;

  const {
    autoCompleteProp,
    localValue,
    handleChange,
    t,
    showAutoSendToMe,
    notificationData,
    handleOnChangeCheckBox,
    lruList,
    handleDeleteItem,
    toggleShowLruList,
    showLruList,
    handleOnInputChange,
    valueToDisplay,
    open,
    onTagChange,
  } = useData(notificationDesc, updateDeclaration, selectedIndex, setSelectedIndex);
  const { breakpoints } = useTheme();

  return (
    <Box className={labelClass} data-cy={nameWithId} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <>
        <Box sx={FieldMainBox(breakpoints)}>{labelSpan}</Box>
        {showAutoSendToMe && (
          <FormControlLabel
            sx={{ mb: '1.3em' }}
            control={
              <Checkbox
                checked={notificationData.sendToMe}
                data-cy={`${nameWithId}-checkbox`}
                onChange={handleOnChangeCheckBox}
              />
            }
            label={t('formlib_mail_send_me')}
          />
        )}
        <Box sx={FlexRow}>
          <Autocomplete
            sx={{ flex: 1 }}
            multiple
            id={nameWithId}
            limitTags={3}
            onChange={(event, value, reason, details) => {
              handleChange(event, value, reason, details);
            }}
            freeSolo={true}
            inputValue={localValue}
            disabled={isReadOnly}
            onInputChange={handleOnInputChange}
            value={valueToDisplay}
            options={autoCompleteProp}
            // filterOptions={isDataSourceAADOrFamilly ? (x) => x : undefined}
            // open={open}
            open={open}
            getOptionLabel={(option: any) =>
              !dataSource?.forcedSelection && typeof option === 'string' ? option : option?.value ?? 'rien'
            }
            filterOptions={(x) => x}
            renderOption={(props, option) => (
              <li
                {...props}
                key={(option as OptionsRender).id}
                id={(option as OptionsRender).id}
                data-cy={`${nameWithId}-li-${(option as OptionsRender).id}`}
              >
                <div>
                  {option.title}
                  <div>{option.description}</div>
                </div>
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option: string | NotificationTarget, index) => {
                return (
                  //eslint-disable-next-line react/jsx-key
                  <Chip
                    label={typeof option === 'string' ? option : option.target}
                    data-cy={`${nameWithId}-chip-${index}`}
                    {...getTagProps({ index })}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField {...params} label={t('formlib_mail_hint')} placeholder={t('formlib_mail_hint').toString()} />
            )}
          />
          {lruList.length > 0 && (
            <IconButton data-cy={`${nameWithId}-clear`} edge="end" onClick={toggleShowLruList} disabled={isReadOnly}>
              <ManageSearchIcon />
            </IconButton>
          )}
        </Box>
        {showLruList && (
          <HistoryQCField
            disabled={isReadOnly}
            prevValue={lruList}
            onTagChange={onTagChange}
            handleDeleteItem={handleDeleteItem}
            fieldFullId={fullPathId}
          />
        )}
      </>
    </Box>
  );
};

export default NotificationQcField;
