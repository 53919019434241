export enum QCErrorHTTPCodeEnum {
  ERR_NETWORK = 'ERR_NETWORK',
  ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE',
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  ERR_INVALID_URL = 'ERR_INVALID_URL',
  ERR_CANCELED = 'ERR_CANCELED',
  ECONNABORTED = 'ECONNABORTED',
  ETIMEDOUT = 'ETIMEDOUT',
  UNKNOWN = 'UNKOWN',
}
