import { FieldDesc } from '90.quickConnect.Models/models';
import { FieldType, Mandatory } from '90.quickConnect.Models/enums';

/**
 * Permet de mettre à jour le caractéristique mandatory (required ou optional) d'un champ ou un de ses enfants.
 *
 * @param {string} fullPathId
 * @param {FieldDesc} field
 * @param {boolean} isMandatory Si TRUE, le champ devient required, si FALSE, il devient Optional
 * @return {*}  {[FieldDesc, boolean]}
 */
const updateMandatoryFieldOrChild = (
  fullPathId: string,
  field: FieldDesc,
  isMandatory: boolean,
  updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
  messageToRemove?: string,
): [FieldDesc, boolean] => {
  let changeMade = false;
  const nextMandatory = isMandatory ? Mandatory.Required : undefined;
  if (
    field.fullPathId == fullPathId &&
    field.mandatory !== nextMandatory &&
    field.fieldType !== FieldType.Separator &&
    field.fieldType !== FieldType.CheckBox
  ) {
    changeMade = true;
    field.mandatory = nextMandatory;

    // Suppression du message d'erreur concernant le mandatory, afin de rendre valide le champ, si aucune autre erreur persiste.
    if (messageToRemove !== undefined && isMandatory === false) {
      field.errors = field.errors?.filter((error: string) => error !== messageToRemove);
      updateFieldErrors(fullPathId, field.errors ?? []);
    }
  }
  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateMandatoryFieldOrChild(
        fullPathId,
        child,
        isMandatory,
        updateFieldErrors,
      );
      if (childChanged) changeMade = childChanged;
      child = updatedChild;
    });
  }
  return [field, changeMade];
};

export default updateMandatoryFieldOrChild;
