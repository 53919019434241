import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { EditDesc } from '90.quickConnect.Models/models';

const mapSliderDesc = ({ defaultValue, value }: EditDesc): EditDesc =>
  ({
    defaultValue: defaultValue ?? '',
    value: value ?? isNumeric(defaultValue) ? defaultValue : undefined,
  } as unknown as EditDesc);

export default mapSliderDesc;
