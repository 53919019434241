import React from 'react';
import useData from './hooks';
import { ReferenceResolverProps } from './types';

/**
 * Ce composant permet d'avoir un useEffect différent pour chaque ComputedField
 * @param param0
 * @returns
 */
const ReferenceResolver = ({
  fieldWithReference,
  updateDeclaration,
  forceFieldUpdate,
  otherFields,
}: ReferenceResolverProps) => {
  useData(fieldWithReference, updateDeclaration, forceFieldUpdate, otherFields);
  return <></>;
};

export default ReferenceResolver;
