import mapBaseContainerDesc from './mapBaseContainerDesc';
import { DialogDesc, FieldDesc } from '90.quickConnect.Models/models';
import { mapFieldDesc } from '90.quickConnect.Models/mappings';
import { Mandatory } from '90.quickConnect.Models/enums';
import { getMandatoryInTemplate, isMandatoryItems } from '90.quickConnect.Models/mappings/fields/isMandatoryItems';

const mapDialogDesc = (
  field: DialogDesc,
  parentId: string | undefined,
  templateBodies: Record<string, string> | undefined | null,
): DialogDesc => {
  const { id, items } = field;
  const baseFieldWithTemplate = {
    ...field,
    items: items ? items.map((f) => mapFieldDesc(f, parentId ?? id, templateBodies)) : [],
  };

  return {
    ...mapBaseContainerDesc(baseFieldWithTemplate),
  } as DialogDesc;
};

export default mapDialogDesc;
