import { isBefore, isAfter } from 'date-fns';

/**
 * Permet de vérifier les dates pour l'ErrorResolver
 */
export const date1IsBeforeDate2 = (dt1: Date, dt2: Date, isDateTimeComparaison = true): boolean | undefined => {
  // Vérification des paramètres
  if (isNaN(dt1.getTime()) || isNaN(dt2.getTime())) return;

  const date2 = isDateTimeComparaison ? dt2 : new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate(), 0, 0, 0, 0);

  return isBefore(dt1, date2);
};

/**
 * Permet de vérifier les dates pour l'ErrorResolver
 */
export const date1IsAfterDate2 = (dt1: Date, dt2: Date, isDateTimeComparaison = true): boolean | undefined => {
  // Vérification des paramètres
  if (isNaN(dt1.getTime()) || isNaN(dt2.getTime())) return;

  const date2 = isDateTimeComparaison
    ? dt2
    : new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate(), 23, 59, 59, 999);

  return isAfter(dt1, date2);
};
