import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PreWrapSpan } from './styles';
import { FieldType, Mandatory } from '90.quickConnect.Models/enums';
import { FlexRow } from '50.quickConnect.Fields/styles';

const useLabel = (
  label: string | undefined,
  mandatory: Mandatory | undefined,
  parentName: string,
  _fieldType: FieldType,
  errors: string[] | undefined,
): JSX.Element => {
  const { t } = useTranslation('declaration');

  const [labelClass, setLabelClass] = useState('');

  const labelSpan = useMemo(() => {
    const labelToUse = label?.replaceAll('&lt;', '<').replaceAll('&gt;', '>') ?? '';
    return (
      <Box>
        <Box className={labelClass} sx={{ ...FlexRow, alignItems: 'flex-start' }}>
          {/* {mandatoryIndicator ?? ''} */}
          <PreWrapSpan
            data-cy={`${parentName}-label`}
            dangerouslySetInnerHTML={{ __html: labelToUse.replace(/<p><br><\/p>/g, '') }}
          />
        </Box>
        {errors &&
          errors.map((error, index) => (
            <Typography key={index} variant="body2" color="error">
              {error !== t('errors_mandatory_field') && error}
            </Typography>
          ))}
      </Box>
    );
  }, [label, labelClass, parentName, errors, t]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setLabelClass('animate__animated animate__pulse');
    } else {
      setLabelClass('');
    }
  }, [errors]);

  return labelSpan;
};

export default useLabel;
