/**
 * Représente les rôles interne à quickconnect
 */
export enum QuickConnectRoles {
  user = 0,
  analyst = 1,
  designerinter = 2,
  designer = 3,
  administrator = 4,
  C2SAdministrator = 5,
}
