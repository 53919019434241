import { AllFieldValueTypes, LabelDesc } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';

export const getReferencedLabelValue = (
  fieldWithReference: LabelDesc,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const { label } = fieldWithReference;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.Separator:
    case FieldType.ReadOnlyValue:
      return label ?? '';

    default:
      return undefined;
  }
};
