import { v4 as uuidv4 } from 'uuid';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import {
  FieldDesc,
  AllFieldValueTypes,
  Choice,
  ComboDesc,
  QCNotification,
  NotificationTarget,
  HierarchicalChoice,
  RepeatableGroupDesc,
  CodeReaderDesc,
  EditDesc,
  DataSourceField,
  IncludeDesc,
  NotificationDesc,
} from '90.quickConnect.Models/models';
import { errorHandler, flatten, isEmail, parseAndCamelize } from '80.quickConnect.Core/helpers';
import { getChoicesListFromListDef, isNumeric } from '80.quickConnect.Core/helpers/common';
import { FieldType, SharedListType, ConsentFrequency, DataSourceType } from '90.quickConnect.Models/enums';
import { choiceListParse } from '80.quickConnect.Core/helpers/choiceListParsers';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { mapFieldDesc } from '90.quickConnect.Models/mappings';

const tag = '10.quickConnect.app/components/domain/Declaration/helpers/initForm/deepLink/index.ts';

const prepareAutoComplete = (
  valueToFormat: string,
  fullPathId: string,
  dataSource: DataSourceField,
  dataSrcType: DataSourceType,
  autoCompleteInternal: {
    registerValidState: (fullPathId: string) => void;
    setIsInDataSource: (fullPathId: string, value: boolean) => void;
  },
): string => {
  // 1er cas, le forcedSelection est a false
  if (dataSource.forcedSelection !== true) return valueToFormat;

  const { registerValidState, setIsInDataSource } = autoCompleteInternal;
  setIsInDataSource(fullPathId, true);
  registerValidState(fullPathId);

  return valueToFormat;
};

const getFullPathIdFromDeepLink = (fullId: string): string => {
  if (!fullId.includes('(')) return fullId;

  return fullId
    .split('.')
    .reduce((acc: string[], current: string): string[] => {
      if (!current.includes('(')) return [...acc, current];

      const indexOfOpenParenthesis = current.indexOf('(');
      const indexOfCloseParenthesis = current.indexOf(')');
      const indexDplk = +current.substring(indexOfOpenParenthesis + 1, indexOfCloseParenthesis);
      if (!Number.isInteger(indexDplk)) throw new Error('Must be an integer');
      const index = indexDplk - 1 >= 0 ? indexDplk - 1 : 0;
      const rgId = current.substring(0, indexOfOpenParenthesis);
      const includeId = `${rgId}.${index}`;

      return [...acc, rgId, includeId];
    }, [])
    .join('.');
};

const getIdFromInjectedDataKey = (key: string): string => {
  if (!key.includes('.') && !key.includes('(')) return key;

  const [rootId] = key.split('.');

  return rootId.includes('(') ? rootId.substring(0, rootId.indexOf('(')) : rootId;
};

const prepareUpdateValue = async (
  valueToFormat: AllFieldValueTypes,
  fieldDesc: FieldDesc,
  autoCompleteInternal: {
    registerValidState: (fullPathId: string) => void;
    setIsInDataSource: (fullPathId: string, value: boolean) => void;
  },
): Promise<AllFieldValueTypes> => {
  const logger = CustomLogger.getInstance();
  try {
    const { fieldType } = fieldDesc;

    switch (fieldType) {
      case FieldType.Group:
        const { items } = fieldDesc;
        const newGroupItems = await Promise.all(
          items.map(async (item: FieldDesc) => {
            await prepareUpdateValue(valueToFormat, item, autoCompleteInternal);
            return item;
          }),
        );
        fieldDesc.items = newGroupItems;
        fieldDesc.value = true;
        break;

      case FieldType.RepeatableGroup: {
        const { groupTemplate, id, fullPathId, maxRow } = fieldDesc as RepeatableGroupDesc;

        // On recréer depuis la struct de données où l'index de l'array correspond au groupe
        // keyRG: [ { keyItem: valueItem }, { keyItem2: valueItem2 }, etc... ]

        if (!Array.isArray(valueToFormat)) return undefined;

        const newItems: FieldDesc[] = (valueToFormat as Record<string, AllFieldValueTypes>[]).reduce(
          (acc: FieldDesc[], currentItem: Record<string, AllFieldValueTypes>, currentIndex: number) => {
            if (currentIndex > maxRow - 1) return acc;

            const gt: FieldDesc[] = groupTemplate.map((i: FieldDesc) =>
              mapFieldDesc(i, `${fullPathId}-${id}.${currentIndex}`, undefined),
            );

            let bCreateIncludeField = false;

            for (const [key, value] of Object.entries(currentItem)) {
              // Vérifions si la key existe dans le groupe
              const childrenItem: FieldDesc | undefined = gt.find((f: FieldDesc) => f.id === key);

              if (!childrenItem) continue;

              childrenItem.value = value;
              bCreateIncludeField = true;
            }

            if (bCreateIncludeField) {
              const newIncludeField: FieldDesc = {
                stateId: uuidv4(),
                fieldType: FieldType.Include,
                items: gt,
                value: true,
                errors: undefined,
                fullPathId: `${fullPathId}.${id}.${currentIndex}`,
                id: `${id}.${currentIndex}`,
                fieldIsReadOnly: false,
                isVisible: true,
                checkRGPD: ConsentFrequency.Undef,
              };

              return [...acc, newIncludeField];
            } else {
              return acc;
            }
          },
          [],
        );

        fieldDesc.items = newItems;
        fieldDesc.value = true;
        break;
      }

      case FieldType.Text:
        // Dans le cas le plus simple...
        const textField = fieldDesc as EditDesc;
        if (!textField.dataSource) return valueToFormat;

        // Dans le cas ou la valeur est vide
        if (!valueToFormat || typeof valueToFormat !== 'string' || !textField.dataSourceType) return valueToFormat;

        // Dans ce Cas, il va falloir byPasser la rech. autoComplete
        return prepareAutoComplete(
          valueToFormat,
          textField.fullPathId,
          textField.dataSource,
          textField.dataSourceType,
          autoCompleteInternal,
        );

      default:
        return valueToFormat;
    }
  } catch (error: unknown) {
    errorHandler(tag, error, 'prepareUpdateValue');
    return undefined;
  }
};

const createSubGroup = (rgField: RepeatableGroupDesc, index: number): void => {
  const { groupTemplate, id, fullPathId, items } = rgField;

  const newItems: FieldDesc[] = items;

  // ATTENTION!! Ici, il faut prendre aussi en compte les items initialement crées...
  for (let i = items.length; i < index; i++) {
    const gt: FieldDesc[] = groupTemplate.map((item: FieldDesc) =>
      mapFieldDesc(item, `${fullPathId}.${id}.${i}`, undefined),
    );

    const newIncludeField: FieldDesc = {
      stateId: uuidv4(),
      fieldType: FieldType.Include,
      items: gt,
      value: true,
      errors: undefined,
      fullPathId: `${fullPathId}.${id}.${i}`,
      id: `${id}.${i}`,
      fieldIsReadOnly: false,
      isVisible: true,
      checkRGPD: ConsentFrequency.Undef,
    };

    newItems.push(newIncludeField);
  }

  rgField.items = newItems;
};

async function updateValue(
  valueToFormat: AllFieldValueTypes,
  fields: FieldDesc[],
  fullPathIdDplk: string,
  autoCompleteInternal: {
    registerValidState: (fullPathId: string) => void;
    setIsInDataSource: (fullPathId: string, value: boolean) => void;
  },
): Promise<void> {
  // On prend la premiere partie
  const indexForIdRoot = fullPathIdDplk.indexOf('.');

  const fieldIdDplk: string = indexForIdRoot === -1 ? fullPathIdDplk : fullPathIdDplk.substring(0, indexForIdRoot);

  const fieldId = fieldIdDplk.includes('(') ? fieldIdDplk.substring(0, fieldIdDplk.indexOf('(')) : fieldIdDplk;

  // Recherche dans la liste de champs...

  const field: FieldDesc | undefined = fields.find((f: FieldDesc) => f.id === fieldId);

  if (!field) throw new Error(`updateValue method failed: Field not found for this id ${fieldId}`);

  const { items, fieldType } = field;

  switch (fieldType) {
    case FieldType.Address: {
      field.value = valueToFormat === 'string' ? parseAndCamelize(valueToFormat) : valueToFormat;
      break;
    }

    case FieldType.Alert:
    case FieldType.Combo:
    case FieldType.RadioList:
    case FieldType.CheckBoxList: {
      if (typeof valueToFormat !== 'string') return;
      const { listChoice, listDef } = field as ComboDesc;
      const list: Choice[] = listChoice ?? (await getChoicesListFromListDef(listDef)) ?? [];
      const choicesNotSorted = choiceListParse(valueToFormat, list, SharedListType.Simple, CustomLogger.getInstance());

      const choiceHits = list.reduce((acc: Choice[], choice: Choice): Choice[] => {
        const choiceCorrespondance = choicesNotSorted.find(
          (cns: Choice) =>
            cns.label === choice.label ||
            cns.value === choice.value ||
            (cns.data && choice.data && choice.data === cns.data),
        );

        return choiceCorrespondance ? [...acc, choice] : acc;
      }, [] as Choice[]);

      field.value = fieldType === FieldType.CheckBoxList ? choiceHits : choiceHits.at(0);
      break;
    }

    case FieldType.HierarchicalList: {
      if (!Array.isArray(valueToFormat) || valueToFormat.some((val: unknown) => typeof val !== 'string')) break;

      const { listChoice, listDef } = field as ComboDesc;
      const list: HierarchicalChoice[] =
        (listChoice as HierarchicalChoice[]) ??
        ((await getChoicesListFromListDef(listDef)) as unknown as HierarchicalChoice) ??
        [];

      const valuesToSearch = (valueToFormat as string[]).reduce((acc: string[], current: string): string[] => {
        const strSplitted = current.split('¤');

        return [...acc, strSplitted.at(0)!];
      }, [] as string[]);

      const choicesNotSorted = choiceListParse(
        valuesToSearch.join(','),
        list,
        SharedListType.Hierarchical,
        CustomLogger.getInstance(),
      ) as HierarchicalChoice[];

      const allItems = flatten(list, (h) => h.children);

      const choiceHits = choicesNotSorted.reduce(
        (acc: HierarchicalChoice[], hchoice: HierarchicalChoice): HierarchicalChoice[] => {
          const choiceCorrespondance = allItems.find((choice: HierarchicalChoice): boolean => {
            return hchoice.label === choice.label && hchoice.value === choice.value;
          });

          return choiceCorrespondance ? [...acc, choiceCorrespondance] : acc;
        },
        [] as HierarchicalChoice[],
      );

      const oldValues = Array.isArray(field.value)
        ? (field.value as HierarchicalChoice[])
        : ([] as HierarchicalChoice[]);

      field.value = [...oldValues, ...choiceHits];
      break;
    }

    case FieldType.Time:
    case FieldType.DateTime:
      field.value = typeof valueToFormat === 'string' ? DateTimeExtension.parseMultiFormat(valueToFormat) : undefined;
      break;

    case FieldType.Digits:
    case FieldType.Numeric:
    case FieldType.Counter:
    case FieldType.Slider:
      if (valueToFormat === '') {
        field.value = undefined;
        break;
      }
      if (isNumeric(valueToFormat)) {
        field.value = Number(valueToFormat);
      }
      break;
    case FieldType.Notification:
      const {
        autoSend: autoSendInjected,
        searchAllUO: searchAllUOInjected,
        selectedTargets: selectedTargetsInjected,
        sendToMe: sendToMeInjected,
        subject: subjectInjected,
      } = valueToFormat as Partial<QCNotification>;

      const {
        autoSend,
        searchAllUO,
        notificationType,
        selectedTargets = [],
        sendToMe,
        subject,
      } = (field.value as QCNotification) || {};

      // Cas des selectedTargets...
      let selectedTargetsFormatted: NotificationTarget[];
      if (selectedTargetsInjected) {
        const emails = selectedTargetsInjected as unknown as string[];
        selectedTargetsFormatted = emails.reduce((acc: NotificationTarget[], email: string): NotificationTarget[] => {
          return isEmail(email) ? [...acc, { target: email, alias: null }] : acc;
        }, []);
      } else {
        selectedTargetsFormatted = [];
      }
      const autoSendFinal: boolean | undefined = autoSend ?? (field as NotificationDesc).autoSend;

      const nextValue: QCNotification = {
        autoSend: typeof autoSendInjected === 'boolean' ? autoSendInjected : autoSendFinal,
        searchAllUO: typeof searchAllUOInjected === 'boolean' ? searchAllUOInjected : searchAllUO,
        notificationType,
        selectedTargets: selectedTargetsInjected ? selectedTargetsFormatted : selectedTargets,
        sendToMe: typeof sendToMeInjected === 'boolean' ? sendToMeInjected : sendToMe,
        subject: subjectInjected ?? subject,
      };

      field.value = nextValue;
      break;

    case FieldType.Dialog:
    case FieldType.Include:
    case FieldType.Group: {
      const newFullPathIdDplk = fullPathIdDplk.substring(indexForIdRoot + 1);
      await updateValue(valueToFormat, items, newFullPathIdDplk, autoCompleteInternal);
      if (fieldType !== FieldType.Dialog) field.value = true;
      break;
    }

    case FieldType.RepeatableGroup: {
      if (Array.isArray(valueToFormat)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateRgFieldValuesByArray(
          valueToFormat as Array<Record<string, AllFieldValueTypes>>,
          fields,
          field.id,
          autoCompleteInternal,
        );
        break;
      }
      if (!fieldIdDplk.includes('(')) break;

      const idxOpenParenthesis = fieldIdDplk.indexOf('(') + 1;
      const idxCloseParenthesis = fieldIdDplk.indexOf(')');
      const index = +fieldIdDplk.substring(idxOpenParenthesis, idxCloseParenthesis);

      if (!Number.isInteger(index))
        throw new Error(`updateValue method failed: index passed in parameter is not an integer: ${fieldIdDplk}`);

      const rgField = field as RepeatableGroupDesc;

      if (index > rgField.maxRow) break;

      const childrenSize: number = items.length;

      if (index > childrenSize) createSubGroup(rgField, index);

      const newFullPathIdDplk = fullPathIdDplk.substring(indexForIdRoot + 1);
      await updateValue(
        valueToFormat,
        (rgField.items[index - 1] as IncludeDesc).items,
        newFullPathIdDplk,
        autoCompleteInternal,
      );
      rgField.value = true;
      break;
    }

    case FieldType.Text: {
      // Dans le cas le plus simple...
      const textField = field as EditDesc;
      if (!textField.dataSource) {
        field.value = valueToFormat;
        break;
      }
      // Dans le cas ou la valeur est vide
      if (!valueToFormat || typeof valueToFormat !== 'string' || !textField.dataSourceType) {
        field.value = valueToFormat;
        break;
      }
      // Dans ce Cas, il va falloir byPasser la rech. autoComplete
      field.value = prepareAutoComplete(
        valueToFormat,
        textField.fullPathId,
        textField.dataSource,
        textField.dataSourceType,
        autoCompleteInternal,
      );
      break;
    }

    default:
      field.value = valueToFormat;
  }
}

async function updateRgFieldValuesByArray(
  valuesToFormat: Array<Record<string, AllFieldValueTypes>>,
  fields: FieldDesc[],
  id: string,
  autoCompleteInternal: {
    registerValidState: (fullPathId: string) => void;
    setIsInDataSource: (fullPathId: string, value: boolean) => void;
  },
) {
  await Promise.all(
    valuesToFormat.map(async (valueToFormat, index: number, arr) => {
      for (const [key, value] of Object.entries(valueToFormat)) {
        const newKey = `${id}(${index + 1}).${key}`;
        await updateValue(value, fields, newKey, autoCompleteInternal);
      }
    }),
  );
}

export const setDeepLinkValues = async (
  formFields: FieldDesc[],
  injectedData: Record<string, AllFieldValueTypes> | undefined,
  autoCompleteInternal: {
    registerValidState: (fullPathId: string) => void;
    setIsInDataSource: (fullPathId: string, value: boolean) => void;
  },
): Promise<void> => {
  const logger: CustomLogger = CustomLogger.getInstance();
  try {
    if (!injectedData) return;

    logger.debug(tag, `injectedValue: ${JSON.stringify(injectedData)}`);

    // On va boucler sur l'objet injectedData;
    for (const [key, value] of Object.entries(injectedData)) {
      const id = getIdFromInjectedDataKey(key);

      const fieldToUpdate: FieldDesc | undefined = formFields.find(
        (f: FieldDesc) => f.id.toLowerCase() === id.toLowerCase(),
      );

      if (!fieldToUpdate) {
        logger.info(tag, `[Client Web] file: initForm, function setDeepLinkValues failed: No field found for id ${id}`);
        continue;
      }

      await updateValue(value, formFields, key, autoCompleteInternal);
    }
  } catch (error: unknown) {
    errorHandler(tag, error, 'setDeepLinkValues');
  }
};

export const getEntityDataByDeepLink = async (
  fields: FieldDesc[],
  synchronizeEquipmentsMultipleAsync: (
    fullPathIdAndqrCodes: Record<string, string>,
  ) => Promise<Record<string, { instanceId: string; schemaId: string }> | undefined>,
): Promise<Record<string, { instanceId: string; schemaId: string }> | undefined> => {
  // Récupération des champs codeReader avec l'attribut searchEquipment à true;
  const fullPathIdAndqrCodes: Record<string, string> = fields
    .filter(
      (field: FieldDesc) =>
        field.fieldType === FieldType.CodeReader &&
        (field as CodeReaderDesc).searchEquipment === true &&
        field.value &&
        field.value !== '',
    )
    .reduce((acc: Record<string, string>, { fullPathId, value }: FieldDesc) => {
      acc[fullPathId] = `##${value}`;
      return acc;
    }, {});

  const entityInfos = await synchronizeEquipmentsMultipleAsync(fullPathIdAndqrCodes);

  return entityInfos;
};
