/**
 * Liste les différents types de champ possible
 */
export enum FieldType {
  Unknow = 'Unknow',
  Text = 'Text',
  Label = 'Label',
  CheckBox = 'CheckBox',
  Combo = 'Combo',
  HierarchicalList = 'HierarchicalList',
  CheckBoxList = 'CheckBoxList',
  RadioList = 'RadioList',
  Group = 'Group',
  Dialog = 'Dialog',
  Include = 'Include',
  Attachment = 'Attachment',
  Photo = 'Photo',
  Audio = 'Audio',
  Draw = 'Draw',
  Slider = 'Slider',
  Geolocation = 'Geolocation',
  Compute = 'Compute',
  DateTime = 'DateTime',
  Separator = 'Separator',
  FixedImage = 'FixedImage',
  Array = 'Array',
  Signature = 'Signature',
  CodeReader = 'CodeReader',
  Counter = 'Counter',
  Plugin = 'Plugin',
  Numeric = 'Numeric',
  Time = 'Time',
  Alert = 'Alert',
  RepeatableGroup = 'RepeatableGroup',
  ReadOnlyValue = 'ReadOnlyValue',
  Address = 'Address',
  Digits = 'Digits',
  Step = 'Step',
  RfidReader = 'RfidReader',
  Notification = 'Notification',
  DataSource = 'DataSource',
  TodoList = 'TodoList',
  FixedAttachment = 'FixedAttachment',
  Action = 'Action',
  ImagesGroup = 'ImagesGroup',
}
