import React from 'react';
import { observer } from 'mobx-react';
import { Box, FormControl, useTheme } from '@mui/material';
import AttachmentsList from '../AttachmentsList';
import { PhotoQcFieldName } from './const';
import useData from './hook';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { PhotoDesc } from '90.quickConnect.Models/models';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';

const PhotoQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  labelClass,
}: ValueFieldsTypesProps) => {
  const photoDesc = field as PhotoDesc;
  const { fieldType, toolTip, id, fullPathId } = photoDesc;
  const nameWithId = `${PhotoQcFieldName}-${id}`;
  const { localValue, handleFileDrop, handleFileDelete, handleFilePreview } = useData(
    true,
    photoDesc,
    updateDeclaration,
  );

  const { breakpoints } = useTheme();

  return (
    <Box
      id={`scrollToAnchor-${fullPathId}`}
      className={labelClass}
      data-cy={`${nameWithId}-box`}
      sx={FieldMainBox(breakpoints)}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <FormControl data-cy={`${nameWithId}-control`} variant="outlined" fullWidth>
        <AttachmentsList
          fieldType={fieldType}
          onlyImage={true}
          parentId={nameWithId}
          attachments={localValue}
          onDrop={handleFileDrop}
          onDelete={handleFileDelete}
          onPreview={handleFilePreview}
          isReadOnly={isReadOnly}
          stateId={fullPathId}
        />
      </FormControl>
    </Box>
  );
};

export default observer(PhotoQcField);
