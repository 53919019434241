import mapInjectedData from './mapInjectedData';
import mapItemData from './mapItemData';
import { DeclarationContext, DeclarationContextContainer } from '90.quickConnect.Models/models';

const mapDeclarationContext = (
  context: DeclarationContextContainer,
  entityInstanceId: string | undefined,
  isInbox?: boolean,
): DeclarationContext | never => {
  try {
    const { dclContextDto, formContextDto, inboxContextDto } = context;
    let itemDataMapped;
    if (isInbox && inboxContextDto) {
      const itemDataRaw = inboxContextDto.itemData ? JSON.parse(inboxContextDto.itemData as string) : undefined;
      itemDataMapped = itemDataRaw ? mapItemData(itemDataRaw) : undefined;
      if (itemDataMapped && inboxContextDto.id) {
        itemDataMapped.Inbox = { id: inboxContextDto.id };
      }
    } else if (!isInbox && dclContextDto) {
      const itemDataRaw = dclContextDto.itemData ? JSON.parse(dclContextDto.itemData as string) : undefined;
      itemDataMapped = itemDataRaw ? mapItemData(itemDataRaw) : undefined;
    }
    return {
      // Form ou Inbox
      formId: formContextDto.formId,
      activityId: isInbox
        ? inboxContextDto?.activityId
        : dclContextDto
        ? dclContextDto.activityId
        : formContextDto?.activityId,
      formParentId: formContextDto.formParentId,
      name: formContextDto.name,
      folderPath: formContextDto.folderPath,
      formType: formContextDto.formType,
      formBody: formContextDto.formBody,
      actionDesc: formContextDto.actionDesc,
      qcScriptObj: formContextDto.qcScriptObj,
      entitySchemaId: inboxContextDto?.entitySchemaId ?? formContextDto.entitySchemaId,
      templateBodies: dclContextDto?.templateBodies ?? formContextDto.templateBodies,
      stringExtract: dclContextDto?.isDraft ? dclContextDto?.stringExtract : formContextDto.stringExtract,
      injectedData: formContextDto.injectedData ? mapInjectedData(formContextDto.injectedData) : undefined,

      // Declaration ou Inbox
      id: isInbox ? inboxContextDto?.id : dclContextDto?.id,
      isDraft: dclContextDto?.isDraft,
      editedData: dclContextDto?.editedData,
      editedAt: dclContextDto?.editedAt ? new Date(dclContextDto?.editedAt) : new Date(),
      type: dclContextDto?.type,
      itemData: itemDataMapped,
      entityInstanceId: inboxContextDto?.entityInstanceId ?? entityInstanceId,
      internalData: dclContextDto?.internalData
        ? dclContextDto.internalData
        : inboxContextDto?.internalData
        ? inboxContextDto.internalData
        : [],
    } as DeclarationContext;
  } catch (error: unknown) {
    throw error instanceof Error
      ? error
      : new Error('[Client Web] - mapDeclarationContext.ts - Erreur inconnue sur le mapping du declarationContext');
  }
};

export default mapDeclarationContext;
