import { DependencyList, useMemo } from 'react';
import isEqual from './isEqual';

const cacheDeps: Record<string, React.DependencyList> = {};

export default function useMemoDebugger<T>(factory: () => T, deps: DependencyList, name: string) {
  const cache = cacheDeps[name];
  if (cache && isEqual(deps, cache)) {
    deps?.forEach((dep, i) => {
      const oldDep = cache?.[i];
      if (dep !== oldDep) {
        console.info(`⚠️useMemoDebug[${name}] Missmatch on index ${i}`, dep, oldDep);
      }
    });
  }
  cacheDeps[name] = deps;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, deps);
}
