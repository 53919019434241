import { QCSBaseObject } from './QCSBaseObject';
import { QCSInt } from './QCSInt';

export class QCSArray extends QCSBaseObject {
  private static _empty: QCSArray = new QCSArray();

  public value: Array<QCSBaseObject> = [];

  private objType = 0;

  static empty = (): QCSArray => QCSArray._empty;

  static create = (objType: number, dims: Array<QCSInt>): QCSArray => {
    const result: QCSArray = new QCSArray();
    result.objType = objType;
    const iMax: number = dims.length;
    const firstDim: number = dims[0].value;

    const dummyList: Array<QCSBaseObject> = [];

    for (let index = 0; index < firstDim; index++) {
      dummyList.push(new QCSBaseObject());
    }

    for (let j = 0; j < firstDim; j++) {
      dummyList[j].add(QCSBaseObject.QCSNull);
    }
    result.value = dummyList;

    // Init subArray
    for (let i = 1; i < iMax; i++) {
      result.createSubArray(firstDim, i, dims);
    }
    return result;
  };

  private createSubArray = (firstDim: number, indexDim: number, dims: Array<QCSInt>): void => {
    for (let i = 0; i < firstDim; i++) {
      this.value[i] = QCSArray.create(this.objType, dims.slice(1));
    }
  };

  public load = (index: number): QCSBaseObject => this.value[index];

  public store = (index: number, qcsBaseObject: QCSBaseObject): void => {
    this.value[index] = qcsBaseObject;
  };

  public getAt = (dims: Array<QCSInt>): QCSBaseObject => {
    let result: QCSBaseObject = QCSBaseObject.QCSNull;
    const iMax: number = dims.length;
    // eslint-disable-next-line
    let inner: QCSArray = this;
    let index: number;
    for (let i = 0; i < iMax; i++) {
      index = dims[i].value;
      const innerArray: Array<QCSBaseObject> = inner.value;

      if (innerArray === null || index >= innerArray.length) return QCSBaseObject.QCSNull;

      result = innerArray.at(index)!;
      if (result instanceof QCSArray) inner = result;
    }

    return result;
  };

  public static StaticCall = (methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => null;

  public callQCSObject = (methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => {
    switch (methodId) {
      case 1: // length
        return new QCSInt(this.value.length);
      case 2: // size (idem length)
        return new QCSInt(this.value.length);
      case 3: // set
        return this.set(qcParams);
      case 4: // append
        this.append(qcParams);
        return null;
      default:
        return null;
    }
  };

  /**
   * Overriding QCSBaseObject getValue method
   *
   * @memberof QCSArray
   */
  public getValue = (): Array<QCSBaseObject> => this.value;

  private set = (qcParams: Array<QCSBaseObject>): QCSBaseObject => {
    const index: number = (qcParams[0] as QCSInt).value;
    const oldValue: QCSBaseObject = this.value[index];
    this.store(index, qcParams[1]);

    return oldValue;
  };

  private append = (qcParams: Array<QCSBaseObject>): void => {
    if (this.value == null) this.value = new Array<QCSBaseObject>();
    // for (const val of this.value) {
    //   val.add(qcParams[0]);
    // }
    for (const param of qcParams) {
      this.value.push(param);
    }
  };
}
