import { formatDuration } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { TFunction } from 'i18next';

export const getDurationText = (duration: Duration) => {
  const language = window.navigator.language.slice(0, 2);

  let locale;
  switch (language) {
    case 'fr':
      locale = fr;
      break;
    default:
      locale = enUS;
  }

  return formatDuration(duration, { delimiter: ' ', locale });
};

export const getDurationTranslator = (evalResult: Duration, t: TFunction): string => {
  const result: string[] = [];

  const { years, months, days, hours, minutes, seconds } = evalResult;

  if (years !== undefined && years !== 0) {
    result.push(t('qcapp_declaration_duration_to_years', { count: years }).toString());
  }

  if (months !== undefined && months !== 0) {
    result.push(t('qcapp_declaration_duration_to_months', { count: months }).toString());
  }

  if (days !== undefined && days !== 0) {
    result.push(t('qcapp_declaration_duration_to_days', { count: days }).toString());
  }

  if (hours !== undefined && hours !== 0) {
    result.push(`${t('qcapp_declaration_duration_to_hours', { count: hours }).toString()}`);
  }

  if (minutes !== undefined && minutes !== 0) {
    result.push(`${t('qcapp_declaration_duration_to_minutes', { count: minutes }).toString()}`);
  }

  if (seconds !== undefined && seconds !== 0) {
    result.push(`${t('qcapp_declaration_duration_to_seconds', { count: seconds }).toString()}`);
  }

  return result.join(' ');
};
