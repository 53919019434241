import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { isNumeric } from '80.quickConnect.Core/helpers/common';

export const getReferencedCounterValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const counterValue = isNumeric(valueReferenceDependency) ? +valueReferenceDependency : undefined;

  // Accepter le 0 comme valeur valide pour les champs de type Counter
  if (counterValue !== undefined) {
    switch (fieldTypeFieldWithReference) {
      case FieldType.Text:
      case FieldType.Label:
      case FieldType.ReadOnlyValue:
      case FieldType.Separator:
        return counterValue.toString();

      case FieldType.CheckBox:
        return counterValue > 0 ? 1 : 0;

      case FieldType.Counter:
      case FieldType.Digits:
        return counterValue;

      case FieldType.Slider:
        return counterValue > 5 ? roundTo(5, 1) : counterValue < 0 ? roundTo(0, 0) : roundTo(counterValue, 1);

      case FieldType.Numeric:
        return roundTo(counterValue, 1);

      default:
        return undefined;
    }
  }

  return undefined;
};
