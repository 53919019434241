import { IMapperValue } from '20.formLib/helpers/QCScriptLib/bridge/mapper';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';
import { QCSDouble } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDouble';
import { BooleanExtension } from '80.quickConnect.Core/formatting/BooleanExtension';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';
import NumberExtension from '80.quickConnect.Core/formatting/NumberExtension';
import { findChoice, findChoiceInChoiceList, findChoiceInHierarchicalChoice } from '80.quickConnect.Core/helpers';
import { DateTimeFieldType, FieldType } from '90.quickConnect.Models/enums';
import {
  mapAllFieldTypeValuesToBoolean,
  mapAllFieldTypeValuesToChoice,
  mapAllFieldTypeValuesToChoiceList,
  mapAllFieldTypeValuesToCoordinates,
  mapAllFieldTypeValuesToDateTime,
  mapAllFieldTypeValuesToNotificationData,
  mapAllFieldTypeValuesToNumeric,
  mapAllFieldTypeValuesToTime,
  mapAllFieldTypeValuesToString,
  mapAllFieldTypeToAddressData,
} from '90.quickConnect.Models/mappings';
import {
  AllFieldValueTypes,
  CheckBoxListDesc,
  Choice,
  ComboDesc,
  DateTimeDesc,
  DigitsDesc,
  FieldDesc,
  HierarchicalDesc,
  TimeDesc,
} from '90.quickConnect.Models/models';

export class MapperValue implements IMapperValue {
  mapToDateTime(nextValue: unknown): Date | undefined {
    if (nextValue instanceof Date) return nextValue;
    else if (typeof nextValue === 'string') return DateTimeExtension.parseMultiFormat(nextValue) ?? undefined;
    else if (typeof nextValue === 'number') return DateTimeExtension.mapFromNumber(nextValue) ?? undefined;
    else return;
  }
  mapToBoolean(nextValue: unknown): boolean | undefined {
    return BooleanExtension.isBool(nextValue) ? nextValue : undefined;
  }

  mapToInteger(nextValue: unknown): number | undefined {
    const intValue = NumberExtension.createIntegerValue(nextValue);

    return NumberExtension.isAValidNumber(intValue) ? intValue : undefined;
  }

  mapToDouble(nextValue: unknown): number | undefined {
    const dbleValue = DoubleExtension.createDoubleValue(nextValue);

    return NumberExtension.isAValidNumber(dbleValue) ? dbleValue : undefined;
  }

  mapToChoiceList(nextValue: unknown): Choice[] | undefined {
    return mapAllFieldTypeValuesToChoiceList(nextValue);
  }

  mapToString(nextValue: unknown, fieldType: FieldType | undefined): string | undefined {
    return mapAllFieldTypeValuesToString(nextValue, fieldType);
  }

  mapToChoice(nextValue: unknown): Choice | undefined {
    return mapAllFieldTypeValuesToChoice(nextValue);
  }

  convert(
    field: FieldDesc,
    nextValue: unknown,
    instanceValue: QCSBaseObject,
    flattenFields: FieldDesc[],
  ): AllFieldValueTypes {
    const { fieldType } = field;

    let result: AllFieldValueTypes;

    switch (fieldType) {
      case FieldType.Address: {
        result = mapAllFieldTypeToAddressData(nextValue);
        break;
      }

      case FieldType.Alert:
      case FieldType.RadioList:
      case FieldType.Combo: {
        const { listChoice } = field as ComboDesc;
        const c = mapAllFieldTypeValuesToChoice(nextValue);

        if (!c) return;
        result = findChoice(listChoice, c);
        break;
      }

      case FieldType.Counter:
      case FieldType.Numeric:
      case FieldType.Slider:
      case FieldType.Digits: {
        const { min, max } = field as DigitsDesc;
        const numericValue: number | undefined = mapAllFieldTypeValuesToNumeric(nextValue);

        // On fait pas le check !numericValue car 0 est une valeur valide
        if (numericValue === undefined) return;

        // Cas du slider...
        if (fieldType === FieldType.Slider) result = NumberExtension.getValueInRange(numericValue, 0, 5);
        else result = NumberExtension.getValueInRange(numericValue, min, max);
        break;
      }

      case FieldType.CodeReader: {
        result = mapAllFieldTypeValuesToString(nextValue, fieldType);
        break;
      }

      case FieldType.DateTime: {
        const { min, max, type } = field as DateTimeDesc;
        const datetimeValue: Date | undefined = mapAllFieldTypeValuesToDateTime(nextValue);

        if (!datetimeValue) return;

        const dtValue = DateTimeExtension.getValueInRange(datetimeValue, flattenFields, min, max);

        result = type === DateTimeFieldType.DateTime ? dtValue : DateTimeExtension.getDateFromDateTime(dtValue);
        break;
      }

      case FieldType.Time: {
        const { min, max } = field as TimeDesc;
        const timeValue: Date | undefined = mapAllFieldTypeValuesToTime(nextValue);

        if (!timeValue) return;

        result = DateTimeExtension.getValueInTimeRange(timeValue, min, max);
        break;
      }

      case FieldType.HierarchicalList: {
        const { listChoice } = field as HierarchicalDesc;

        if (!listChoice) return;

        const cl = mapAllFieldTypeValuesToChoiceList(nextValue);

        if (!cl) return;

        result = findChoiceInHierarchicalChoice(listChoice, cl);
        break;
      }

      case FieldType.CheckBoxList: {
        const { listChoice } = field as CheckBoxListDesc;

        if (!listChoice) return;

        const cl = mapAllFieldTypeValuesToChoiceList(nextValue);

        if (!cl) return;

        result = findChoiceInChoiceList(listChoice, cl);
        break;
      }

      case FieldType.Geolocation: {
        const geolocationValue = mapAllFieldTypeValuesToCoordinates(nextValue);

        return geolocationValue;
        break;
      }

      case FieldType.Notification: {
        const notificationDataValue = mapAllFieldTypeValuesToNotificationData(nextValue);

        if (!notificationDataValue) return;

        result = notificationDataValue;
        break;
      }

      case FieldType.CheckBox: {
        result = mapAllFieldTypeValuesToBoolean(nextValue);
        break;
      }

      case FieldType.ReadOnlyValue:
        result = mapAllFieldTypeValuesToString(nextValue, fieldType);
        break;

      case FieldType.Text: {
        const valToMap = instanceValue instanceof QCSDouble ? new DoubleExtension(nextValue) : nextValue;
        result = mapAllFieldTypeValuesToString(valToMap, fieldType);
        break;
      }

      default:
        break;
    }

    return result;
  }
}
