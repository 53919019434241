import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab, useTheme } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { HistoryCommandName } from './const';
import { HistoryCommandProps } from './types';
import useData from './hooks';

const HistoryCommand = ({ setOpenCommands, setOpenHistory }: HistoryCommandProps) => {
  const { t, onHistoryWorkflow } = useData(setOpenCommands, setOpenHistory);

  const theme = useTheme();

  const {
    palette: {
      info: { dark },
    },
  } = theme;

  return (
    <>
      <Tooltip title={t('formlib_history_button_text').toString()} placement="right">
        <Fab
          sx={{ marginTop: '10px', backgroundColor: dark }}
          data-cy={`${HistoryCommandName}-btn-history`}
          size="large"
          onClick={onHistoryWorkflow}
        >
          <HistoryIcon />
        </Fab>
      </Tooltip>
    </>
  );
};

export default observer(HistoryCommand);
