import { useMemo } from 'react';
import { UseDataProps } from './types';
import { FieldDesc } from '90.quickConnect.Models/models';

/**
 * Hook qui détermine les dépendances d'une règle de visibilité
 * @param flattenFields
 * @returns
 */
const useData = (flattenFields: FieldDesc[]): UseDataProps => {
  const allFieldsWithReference = useMemo(
    () => flattenFields.filter((f) => f.reference && f.desactivatedAutomation === false),
    [flattenFields],
  );

  return { allFieldsWithReference };
};

export default useData;
