import { useMemo } from 'react';
import { UseDataProps } from './types';
import { FieldDesc } from '90.quickConnect.Models/models';

/**
 * Hook qui détermine les dépendances d'une règle de visibilité
 * @param flattenFields
 * @returns
 */
const useData = (flattenFields: FieldDesc[]): UseDataProps => {
  const allHidableFields = useMemo(
    () => flattenFields.filter((v) => typeof v.visible !== 'boolean' && !!v.visible),
    [flattenFields],
  );

  return { allHidableFields };
};

export default useData;
