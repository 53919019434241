import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import NfcIcon from '@mui/icons-material/Nfc';
import { CodeReaderQcFieldName } from './const';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { CodeReaderDesc } from '90.quickConnect.Models/models';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import { FieldType } from '90.quickConnect.Models/enums';

const CodeReaderQcField = ({
  field,
  setSelectedIndex,
  updateDeclaration,
  labelSpan,
  isReadOnly,
  updateFieldErrors,
  labelClass,
}: ValueFieldsTypesProps) => {
  const { breakpoints } = useTheme();
  const { t } = useTranslation('declaration');
  const codeReaderDesc = field as CodeReaderDesc;
  const { id, fieldType, fullPathId } = codeReaderDesc;
  const nameWithId = `${CodeReaderQcFieldName}-${id}`;
  const {
    setCode,
    code,
    checkEquipment,
    localValue,
    handleClickOpen,
    handleClose,
    open,
    handleClear,
    handleAddRfidCode,
  } = useData(codeReaderDesc, updateDeclaration, setSelectedIndex, updateFieldErrors);
  return (
    <Box className={labelClass} sx={FieldMainBox(breakpoints)} data-cy={nameWithId} id={`scrollToAnchor-${fullPathId}`}>
      <Box data-cy={`${nameWithId}`} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography component={'span'}>{labelSpan}</Typography>
        <IconButton
          color="primary"
          component="label"
          onClick={handleClickOpen}
          data-cy={`${nameWithId}-open-dialog`}
          disabled={isReadOnly}
        >
          {fieldType === FieldType.RfidReader ? <NfcIcon /> : <QrCodeIcon />}
        </IconButton>
      </Box>

      {localValue && (
        <OutlinedInput
          data-cy={`${nameWithId}-input`}
          id={`${nameWithId}-input`}
          type={'text'}
          value={localValue}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title={t('formlib_array_deleted_title').toString()}>
                <span>
                  <IconButton disabled={isReadOnly} data-cy={`${nameWithId}-clear`} edge="end" onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          }
        />
      )}
      <Dialog open={open} onClose={handleClose} data-cy={`${nameWithId}-dialog`}>
        <DialogTitle id="alert-dialog-title">
          {t(`${fieldType === FieldType.RfidReader ? 'dialog_rfidCode_title' : 'dialog_qrCode_title'}`)}
        </DialogTitle>
        <DialogContent>
          <Typography component={'span'}>{labelSpan}</Typography>
          <TextField
            value={code}
            data-cy={`${nameWithId}-dialog-input`}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            margin="dense"
            id="name"
            label={t(`${fieldType === FieldType.RfidReader ? 'dialog_rfidCode_label' : 'dialog_qrCode_label'}`)}
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="outlined" color="error" data-cy={`${nameWithId}-dialog-cancel`}>
            {t('qcapp_dialog_action_cancel')}
          </Button>
          <Button
            onClick={fieldType === FieldType.RfidReader ? handleAddRfidCode : checkEquipment}
            variant="outlined"
            data-cy={`${nameWithId}-dialog-validate`}
          >
            {t('qcapp_dialog_action_validate')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default observer(CodeReaderQcField);
