import React from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import DeclarationsToolBar from './DeclarationsToolBar';
import { DeclarationsComponentName } from './const';
import useData from './hooks';
import DeclarationsTrees from './DeclarationsTrees';
import DeclarationsLists from './DeclarationsLists';
import ScrollTo from '10.quickConnect.app/components/shared/ScrollTo';
import { TabsMainBox } from '10.quickConnect.app/components/domain/Home/styles';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const Declarations = () => {
  const isMobile = useIsMobile();
  const { declarationsAsTree } = useData();
  return (
    <Box data-cy={DeclarationsComponentName} sx={TabsMainBox}>
      <DeclarationsToolBar />
      {declarationsAsTree ? <DeclarationsTrees /> : <DeclarationsLists />}
      {/* {isMobile && <ScrollTo idToScrollTo="topBarComponent" />} */}
    </Box>
  );
};
export default observer(Declarations);
