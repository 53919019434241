/* eslint-disable max-lines-per-function */
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTheme, Typography, Box, Slider, Fab, Button } from '@mui/material';
import { Card, CardActions, CardContent, CardMedia, IconButton } from '@mui/material';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import CanvasDraw from 'react-canvas-draw';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { DrawQcFieldName } from './const';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { BoxForLabel, FieldMainBox, FlexColumn, FlexRow } from '50.quickConnect.Fields/styles';
import TwoSidesRenderer from '50.quickConnect.Fields/TwoSidesRenderer';
import { pureWhiteColor, dippedGreen500 } from '00.app/theme';
import { DrawDesc } from '90.quickConnect.Models/models';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { AttachmentItemData } from '90.quickConnect.Models/models';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';

const DrawQcField = observer(
  ({
    field,
    updateDeclaration,
    labelSpan,
    selectedIndex,
    setSelectedIndex,
    isReadOnly,
    labelClass,
  }: ValueFieldsTypesProps) => {
    const drawDesc = field as DrawDesc;

    const isMobile = useIsMobile();
    const { id, fullPathId } = field;
    const nameWithId = `${DrawQcFieldName}-${id}`;
    const {
      brushRadius,
      setBrushRadius,
      brushColor,
      canvasRef,
      handleExport,
      handleClear,
      onDeleteSignature,
      backGroundImage,
      handleChange,
      setBrushColor,
      handleErase,
      isChecked,
      attachmentsItemsData,
      getSrcValue,
      handleUndo,
    } = useData(updateDeclaration, field, drawDesc, setSelectedIndex);

    const { breakpoints, palette } = useTheme();
    const { t } = useTranslation('declaration');
    const canvas = useMemo(
      () => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CanvasDraw
          enablePanAndZoom={true}
          brushRadius={brushRadius}
          brushColor={isChecked ? '#FFFFFF' : brushColor}
          ref={canvasRef}
          canvasWidth={isMobile ? window.innerWidth - 50 : 600}
          canvasHeight={isMobile ? window.innerWidth - 50 : 400}
          style={{ margin: 'auto', position: 'relative' }}
          hideInterface={true}
          hideGrid={true}
          imgSrc={backGroundImage}
          lazyRadius={0}
          catenaryColor="#0a0302"
        />
      ),
      [backGroundImage, brushColor, brushRadius, canvasRef, isChecked, isMobile],
    );

    const rightItems = useMemo(
      () => (
        <Box sx={{ width: isMobile ? 'auto' : '100%' }} data-cy={`${nameWithId}-canvasDraw`}>
          <>
            <Box sx={{ marginBottom: 2 }}>
              <Typography component="span">{labelSpan}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{ mr: 2 }}>
                <input
                  type="color"
                  value={brushColor}
                  onChange={(event) => {
                    setBrushColor(() => event.target.value);
                  }}
                />
              </Box>
              <Box sx={{ ...FlexColumn, justifyContent: 'start' }}>
                <Box>
                  <label htmlFor={`${nameWithId}-input`}>
                    <Fab color="primary" size="small" component="span">
                      <WallpaperIcon fontSize="medium" />
                    </Fab>
                  </label>
                </Box>
                <Box sx={{ ...FlexRow, justifyContent: 'start' }} data-cy={nameWithId}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    data-cy={`${nameWithId}-input`}
                    id={`${nameWithId}-input`}
                    name={`${nameWithId}-input`}
                    onChange={handleChange}
                    accept={'image/*'}
                    multiple
                    readOnly={isReadOnly}
                    disabled={isReadOnly}
                  />
                </Box>
              </Box>
              {/* <Button variant="contained" data-cy={`${nameWithId}-undo`} onClick={handleUndo}>
                {t('qcapp_fields_draw_undo')}
              </Button> */}
              <Box>
                <FormControlLabel
                  value="start"
                  control={<Switch onChange={handleErase} checked={isChecked} color="primary" />}
                  label={t('formlib_action_button_erase')}
                  labelPlacement="start"
                />
              </Box>
            </Box>
          </>
          {canvas}
          <Slider
            size="small"
            value={brushRadius}
            onChange={(_event, value) => setBrushRadius(value as number)}
            aria-label="Small"
            valueLabelDisplay="auto"
            step={0.5}
            marks
            min={2}
            max={20}
          />
          <Box>
            <Box pb={3} sx={{ display: 'flex' }}>
              <Fab
                color="primary"
                size="small"
                onClick={handleExport}
                sx={{ marginRight: '10px' }}
                data-cy={`${nameWithId}-valid-button`}
              >
                <DoneIcon />
              </Fab>
              <Fab color="error" size="small" onClick={handleClear}>
                <DeleteIcon />
              </Fab>
            </Box>
          </Box>
        </Box>
      ),
      [
        brushColor,
        brushRadius,
        canvas,
        handleChange,
        handleClear,
        handleErase,
        handleExport,
        isChecked,
        isReadOnly,
        labelSpan,
        nameWithId,
        setBrushColor,
        setBrushRadius,
        isMobile,
        t,
      ],
    );

    const list = useMemo(
      () =>
        attachmentsItemsData.map((x: AttachmentItemData, index: number) => (
          <Box sx={FlexRow} key={getFieldKey(field)}>
            <Card sx={{ width: 150, m: 2, backgroundColor: `${pureWhiteColor}` }}>
              <CardContent sx={{ m: 0, p: 0 }}>
                <Box>
                  <CardMedia
                    component="img"
                    height="100"
                    sx={{ p: 0, objectFit: 'contain' }}
                    src={getSrcValue(x)}
                    alt="QuickConnect"
                    onClick={() => {
                      window.open(URL.createObjectURL(x.file as Blob));
                    }}
                  />
                  <CardActions key={index} sx={{ display: 'flex', justifyContent: 'end', p: 0, height: 10 }}>
                    <IconButton
                      color="primary"
                      sx={{ mt: 2 }}
                      aria-label="delete"
                      onClick={(event) => {
                        onDeleteSignature(event, x);
                      }}
                      disabled={isReadOnly}
                    >
                      <DeleteOutlineRoundedIcon />
                    </IconButton>
                  </CardActions>
                </Box>
              </CardContent>
            </Card>
          </Box>
        )),
      [attachmentsItemsData, getSrcValue, isReadOnly, onDeleteSignature, field],
    );

    return (
      <Box
        id={`scrollToAnchor-${fullPathId}`}
        className={labelClass}
        sx={FieldMainBox(breakpoints)}
        data-cy={nameWithId}
      >
        <Box
          data-cy={`${nameWithId}-OpenRightPanelBox`}
          sx={{ ...BoxForLabel(palette), borderColor: selectedIndex === fullPathId ? dippedGreen500 : '' }}
          onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
        >
          <Box sx={FlexRow}>
            <Typography sx={{ flexGrow: 1 }} component="span">
              {labelSpan}
            </Typography>
            <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />
          </Box>
          {list}
        </Box>
        {!isReadOnly && selectedIndex === fullPathId && (
          <TwoSidesRenderer
            parentNameAndId={nameWithId}
            onClose={() => setSelectedIndex(undefined)}
            open={selectedIndex === fullPathId}
          >
            {rightItems}
          </TwoSidesRenderer>
        )}
      </Box>
    );
  },
);
export default DrawQcField;
