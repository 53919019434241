/**
 * Enumération des opérateurs de comparaisons
 */
export enum ComparisonOperators {
  None = 0,
  Equal = 1,
  LesserThan = 2,
  GreaterThan = 3,
  LesserOrEqualThan = 4,
  GreaterOrEqualThan = 5,
  Different = 6,
  Contains = 7,
  NotContains = 8,
  In = 9,
  NotIn = 10,
  StartsWith = 11,
  EndsWith = 12,
}
