import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupQcFieldName } from './const';
import { UseDataProps } from './types';
import { AllFieldValueTypes, GroupDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  field: GroupDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  openByImagesGroupField?: boolean,
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const { id, fullPathId, value, isCollapsable } = field;
  const nameWithId = `${GroupQcFieldName}-${id}`;
  const {
    DeclarationStore: { verifRequiredField, requiredField, setVerifRequiredField },
  } = useStore();
  const valueToBoolean = useMemo((): boolean => {
    if (openByImagesGroupField) return openByImagesGroupField;
    if (typeof value === 'boolean') {
      return value;
    } else if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return false;
    }
  }, [value, openByImagesGroupField]);

  const [isExpanded, setIsExpanded] = useState(valueToBoolean);

  useEffect(() => {
    if (!isExpanded) {
      setSelectedIndex(undefined);
    }
  }, [isExpanded, setSelectedIndex]);

  const handleChange = useCallback(
    (expanded: boolean) => {
      if (isCollapsable) {
        setIsExpanded(!expanded);
        updateDeclaration(fullPathId, !expanded);
      }
    },
    [fullPathId, isCollapsable, updateDeclaration],
  );

  useEffect(() => {
    if (verifRequiredField) {
      if (requiredField && requiredField?.fullPathId.includes(fullPathId)) {
        handleChange(!verifRequiredField);
        // setVerifRequiredField(false);
      }
    }
  }, [fullPathId, handleChange, requiredField, verifRequiredField]);

  return {
    t,
    nameWithId,
    isExpanded,
    handleChange,
  };
};

export default useData;
