import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Menu,
  MenuItem,
  useTheme,
  ListItemIcon,
  Tooltip,
} from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useData from './hooks';
import { DeclarationLineMenuOnTapName } from './const';
import { getDeclarationIcon } from '10.quickConnect.app/components/domain/Home/helpers';
import {
  CustomListItemText,
  getListItemButtonStyle,
  getListItemAvatarStyle,
  getDuplicateIconStyle,
  getAvatarDeclarationStyle,
} from '10.quickConnect.app/components/domain/Home/styles';
import { DeclarationViewer } from '90.quickConnect.Models/models';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';

const DeclarationLineMenuOnTap = (declaration: TreeDataItem) => {
  const { t } = useTranslation('declaration');
  const {
    openDeclaration,
    openContextMenu,
    contextMenu,
    contextMenuClose,
    openInNewTab,
    handleDuplicate,
    isMobile,
    isHover,
    setIsHover,
  } = useData(declaration);
  const { id, formType, name, editedAt, isDraft, isLocal, stringExtract } = declaration as DeclarationViewer;

  const theme = useTheme();

  return (
    <ListItem data-cy={DeclarationLineMenuOnTapName} disablePadding>
      <ListItemButton
        data-cy={`${DeclarationLineMenuOnTapName}-${id}`}
        onContextMenu={openContextMenu}
        sx={getListItemButtonStyle(theme)}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => openDeclaration()}
      >
        <ListItemAvatar sx={getListItemAvatarStyle(theme)}>
          <Avatar sx={getAvatarDeclarationStyle(theme)}>{getDeclarationIcon(formType, isDraft, isLocal)}</Avatar>
        </ListItemAvatar>
        <CustomListItemText
          primary={stringExtract !== '' ? stringExtract : name}
          secondary={editedAt.toLocaleString()}
        />
        {}
        <Menu
          open={contextMenu !== null}
          onClose={contextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        >
          <MenuItem onClick={openInNewTab}>{t('contextual_menu_open_in_new_tab')}</MenuItem>
          <MenuItem onClick={handleDuplicate}>{t('duplicate_declaration')}</MenuItem>
          {isLocal && <MenuItem>{t('contextual_menu_remove_local')}</MenuItem>}
        </Menu>
        {(isMobile || isHover) && (
          <Tooltip title={t('duplicate_declaration').toString()} onClick={handleDuplicate}>
            <ListItemIcon sx={getDuplicateIconStyle()}>
              <ContentCopy />
            </ListItemIcon>
          </Tooltip>
        )}
      </ListItemButton>
    </ListItem>
  );
};
export default observer(DeclarationLineMenuOnTap);
