import { createContext, useContext } from 'react';
import { FieldDesc } from '90.quickConnect.Models/models';

export const DeclarationContext = createContext<FieldDesc[] | undefined>(undefined);

export const useDeclarationContext = (): FieldDesc[] | never => {
  const dclCtx = useContext(DeclarationContext);

  if (!dclCtx) throw new Error('Impossible de récupérer la déclaration depuis le context');

  return dclCtx;
};

export const DeclarationProvider = DeclarationContext.Provider;
