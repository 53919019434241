import { FieldDesc } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { mapFromFieldData } from '90.quickConnect.Models/mappings';
import { FieldData } from '90.quickConnect.Models/models/fields/fieldData';
import { updateInitalField } from '10.quickConnect.app/components/domain/Declaration/helpers/initForm/updateInitalField';
import { errorHandler, flatten } from '80.quickConnect.Core/helpers';
import { FieldType, FormType } from '90.quickConnect.Models/enums';
import { RepeatableGroupDesc } from '90.quickConnect.Models/models';

const tag = '10.quickConnect.app/components/domain/Declaration/helpers/initForm/index.ts';

export const setHistoricValues = async (
  editedData: string,
  templateBodies: Record<string, string> | null | undefined,
  initialFields: FieldDesc[],
  dclId: string,
  isDuplicate: boolean,
  isInboxId: boolean,
  formType: FormType,
  currentStepId?: string,
): Promise<void> => {
  try {
    // Mise en forme des Données...
    const editedFields: FieldData[] = JSON.parse(editedData).map((f: FieldData) => mapFromFieldData(f));

    // Transformation des champs initiaux des formulaires
    const newEditedFields = await Promise.all(
      editedFields.map((editedField: FieldData) => {
        const initialField: FieldDesc | undefined = initialFields.find(
          (f: FieldDesc) => f.fullPathId === editedField.id,
        );

        if (!initialField) throw new Error(`initialField non trouvé avec l'Id: '${editedField.id}'`);

        // Mise à jour du formulaire - Hydratation des données
        return updateInitalField(
          initialField,
          editedField,
          templateBodies,
          dclId,
          isDuplicate,
          isInboxId,
          formType,
          currentStepId,
        );
      }),
    );

    initialFields = newEditedFields;
  } catch (error: unknown) {
    errorHandler(tag, error, 'setHistoricValues', 'error');
  }
};

export const getSharedListIds = (flattenFields: FieldDesc[]): string[] => {
  const newFlattenFields = flatten(flattenFields, (f: FieldDesc) =>
    f.fieldType === FieldType.RepeatableGroup ? (f as RepeatableGroupDesc).groupTemplate : [],
  );
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  return newFlattenFields.filter((f: any) => f.listId).map((f: any) => f.listId);
};
