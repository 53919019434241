import { HierarchicalChoice } from '90.quickConnect.Models/models';

const isHierarchicalChoice = (field: any): field is HierarchicalChoice => {
  return (
    'label' in field &&
    'value' in field &&
    'data' in field &&
    'hierarchicalParentNode' in field &&
    'hierarchicalNodePath' in field &&
    'hierarchicalLabel' &&
    'childrenLabels' in field &&
    'children' in field
  );
};

export default isHierarchicalChoice;
