import React, { useCallback, useRef, useState } from 'react';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { DialogQcFieldName } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/const';
import { UseDataProps } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/types';
import { DialogDesc } from '90.quickConnect.Models/models';
import { AllFieldValueTypes, FieldDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  dialogField: DialogDesc,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
  updateItemsForThisField: (fullPathId: string, nextItems: FieldDesc[]) => void,
): UseDataProps => {
  const { id, items, fullPathId } = dialogField;
  const { t } = useTranslation('declaration');
  const nameWithId = `${DialogQcFieldName}-${id}`;

  const [openDialogQCField, setOpenDialogQCField] = useState<string | undefined>();

  const dialogQCFieldRef = useRef<HTMLElement>(null);

  const {
    DialogStore: { items: itemsStored, setItems: setItemsInStore },
    DeclarationStore: { isDrawerOpened },
  } = useStore();

  /** Permet l'ouverture de la dialogue */
  const handleOpen = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();
      setOpenDialogQCField(fullPathId);
      setItemsInStore(fullPathId, items);
      setSelectedIndex(undefined);
    },
    [setOpenDialogQCField, fullPathId, setItemsInStore, items, setSelectedIndex],
  );

  /** Permet la fermeture de la dialogue en annulant les modifications effectués */
  const handleCancel = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();
      setOpenDialogQCField(undefined);
      const initialItems = toJS(itemsStored[fullPathId]);
      updateItemsForThisField(fullPathId, initialItems);

      setItemsInStore(fullPathId, []);
    },
    [setOpenDialogQCField, itemsStored, setItemsInStore, fullPathId, updateItemsForThisField],
  );

  /**Permet la fermeture de la dialogue en intégrant les modifications */
  const handleSubmit = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();
      setOpenDialogQCField(undefined);
      setItemsInStore(fullPathId, []);
    },

    [setItemsInStore, setOpenDialogQCField, fullPathId],
  );

  return {
    nameWithId,
    t,
    open: openDialogQCField,
    handleOpen,
    handleCancel,
    handleSubmit,
    dialogQCFieldRef,
    isDrawerOpened,
  };
};

export default useData;
