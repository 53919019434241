const flatten = <T>(list: T[], getChildren: (item: T) => T[]): T[] =>
  list.reduce((allItems, currentItem) => {
    const children = getChildren(currentItem);
    allItems.push(currentItem);
    if (children) {
      const flattenedChildren = flatten(children, getChildren);
      return allItems.concat(flattenedChildren);
    }
    return allItems;
  }, [] as T[]);

export default flatten;
