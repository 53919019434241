import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { CommandType } from '../types';
import { UseDataProps } from './types';
import { AllFieldValueTypes, DeclarationContext, FieldDesc, ItemData } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { FORMS } from '10.quickConnect.app/routes/paths';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';

const useData = (
  context: DeclarationContext,
  declaration: FieldDesc[],
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  flattenFields: FieldDesc[],
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>,
  setDeclarationData: Dispatch<SetStateAction<ItemData>>,
  activityId: string | undefined,
  inboxId: string | undefined,
  declarationId: string,
): UseDataProps => {
  // On récupère le store
  const {
    DeclarationStore: { saveDeclarationAsync, isMaxAttachment },
    LoginStore: { signInInfos },
    CommonStore: { setWorkInProgress, setWorkDone },
    QCScriptStore: { resetStore },
  } = useStore();

  const isOnline = useNavigatorOnLine();
  const { organizationalUnitName, userUPN } = signInInfos;
  const { t } = useTranslation('declaration');
  const { t: tAxios } = useTranslation('axios');
  const qcsFormEvent = useQCSFormEvent();

  const navigate = useNavigate();

  const onPauseDeclarationJob = useMemo(
    () => [uuidv4(), t('job_message_on_pause_declaration')] as [string, string],
    [t],
  );

  const onPause = useCallback(async () => {
    if (signInInfos) {
      const [goToNextStep, nextStepIndex, newItemData] = await handleItemDatas(CommandType.Pause);
      if (!isMaxAttachment) {
        setWorkInProgress(onPauseDeclarationJob);

        // Ajout du lifecycle FormEvent_OnFormPause
        if (context.qcScriptObj && qcsFormEvent) {
          const { onFormPause } = qcsFormEvent;
          onFormPause(flattenFields);
        }

        const result = await saveDeclarationAsync(
          context,
          declaration,
          userUPN,
          organizationalUnitName,
          tAxios,
          true,
          newItemData,
          activityId,
          inboxId,
          isOnline,
          declarationId,
          signInInfos,
          true,
        );
        setWorkDone(onPauseDeclarationJob[0]);
        resetStore();
        if (result) {
          if (goToNextStep && nextStepIndex && newItemData && newItemData.Workflow) {
            newItemData.Workflow.currentStep = nextStepIndex;
            setDeclarationData(newItemData);
          } else navigate(FORMS, { replace: true });
        }
      } else {
        toast.warn(`${t('attachment_fields_errors_has_max_count_for_form')} (60)`);
      }
    }
  }, [
    qcsFormEvent,
    flattenFields,
    signInInfos,
    handleItemDatas,
    isMaxAttachment,
    setWorkInProgress,
    onPauseDeclarationJob,
    saveDeclarationAsync,
    resetStore,
    context,
    declaration,
    userUPN,
    organizationalUnitName,
    tAxios,
    activityId,
    inboxId,
    isOnline,
    declarationId,
    setWorkDone,
    navigate,
    setDeclarationData,
    t,
  ]);

  return {
    t,
    onPause,
  };
};

export default useData;
