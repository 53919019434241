import { DocumentTransferState, TransferMode } from '90.quickConnect.Models/enums';
import { AttachmentItemData, QCAttachment } from '90.quickConnect.Models/models';

const mapQCAttachmentFromAttachmentItemData = (itemdata: AttachmentItemData, declarationId: string): QCAttachment => {
  const expDate = new Date();
  expDate.setDate(expDate.getDate() + 5); // Expiration dans 5 jours
  const newAttachment = {
    id: itemdata.id,
    description: itemdata.fileName,
    declarationId: declarationId,
    documentId: itemdata.id,
    correlationId: itemdata.id,
    transferState: DocumentTransferState.DoNotTransfer,
    localUri: itemdata.localUri,
    distantUri: itemdata.distantUri,
    thumbnail: itemdata.thumbnail,
    packageName: '',
    className: '',
    eTag: '',
    transferMode: TransferMode.UPLOAD,
    expirationDate: expDate,
    nbTry: 5,
    retryable: true,
    deletable: true,
    errorMessage: '',
    errorCode: -1,
    docContentType: itemdata.type,
    docCreateDate: itemdata.creationDate,
    docName: itemdata.fileName,
    docTitle: itemdata.title,
    purgeDate: itemdata.purgeDate,
    metadata: itemdata.metadata ?? [],
  } as QCAttachment;
  return newAttachment;
};

export default mapQCAttachmentFromAttachmentItemData;
