import { makeAutoObservable, action, observable } from 'mobx';
import { AbortRequestsStore } from '../interfaces';
import { ComputedFieldInfos, ComputedFieldInfo } from './types';
import { FieldDesc } from '90.quickConnect.Models/models';
import RootStore from '30.quickConnect.Stores/RootStore';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { FieldType, OperatorResult } from '90.quickConnect.Models/enums';

class ComputeStore implements AbortRequestsStore {
  shouldAbortRequests = false;

  RootStore: RootStore;

  logger: CustomLogger;

  computedFieldInfos: ComputedFieldInfos = new Map();

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.RootStore = rootStore;
    this.logger = logger;

    makeAutoObservable(
      this,
      {
        // Observable
        computedFieldInfos: observable,

        // Action
        setComputedFieldInfos: action.bound,
        resetComputedFieldInfos: action.bound,
        formatValuesBeforeSendDeclaration: action.bound,
      },
      { autoBind: true },
    );
  }

  resetStore = (): void => {
    this.computedFieldInfos = new Map();
  };

  setComputedFieldInfos = (fullPathId: string, computedFieldInfo: ComputedFieldInfo): ComputedFieldInfos =>
    this.computedFieldInfos.set(fullPathId, computedFieldInfo);

  getOperatorResult = (fullPathId: string): OperatorResult | undefined => {
    const computedFieldInfo: ComputedFieldInfo | undefined = this.computedFieldInfos.get(fullPathId);

    if (!computedFieldInfo) return;

    return computedFieldInfo.operatorResult;
  };

  setShouldAbortRequests = (shouldAbortRequests: boolean) => (this.shouldAbortRequests = shouldAbortRequests);

  resetComputedFieldInfos = (): ComputedFieldInfos => (this.computedFieldInfos = new Map());

  formatValuesBeforeSendDeclaration = (fieldDesc: FieldDesc): FieldDesc => {
    if (fieldDesc.fieldType === FieldType.Group) {
      fieldDesc.items = fieldDesc.items.map((i) => this.formatValuesBeforeSendDeclaration(i));
    }
    const { fullPathId } = fieldDesc;
    const computedFieldInfo: ComputedFieldInfo | undefined = this.computedFieldInfos.get(fullPathId);

    return computedFieldInfo ? { ...fieldDesc, value: computedFieldInfo.value } : fieldDesc;
  };
}

export default ComputeStore;
