import React from 'react';
import { Box } from '@mui/material';

import { ArrowStyle } from '../../styles';
import useData from './hooks';
import { dippedGreen500 } from '00.app/theme';

const Arrow = () => {
  const dimensions = useData();

  return (
    <Box sx={ArrowStyle}>
      <svg {...dimensions} viewBox="0 0 200 201" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.4046 0.5H200.142V139.56H160.142V68.7843L28.2843 200.642L0 172.358L131.858 40.5H59.4046V0.5Z"
          fill="url(#paint0_linear_696_17936)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_696_17936"
            x1="195.73"
            y1="3.49512"
            x2="14.1421"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.536458" stopColor={dippedGreen500} />
            <stop offset="1" stopColor={dippedGreen500} stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default Arrow;
