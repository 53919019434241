import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Drawer, Dialog, Button, IconButton, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TwoSidesRendererName } from './const';
import { TwoSidesRendererProps } from './types';
import useData from './hooks';
import { RightDrawer } from '50.quickConnect.Fields/Fields/styles';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { FlexRow } from '50.quickConnect.Fields/styles';
import { dippedGreen500 } from '00.app/theme';

const TwoSidesRenderer: React.FC<TwoSidesRendererProps> = ({
  children,
  onClose,
  open,
  parentNameAndId,
}: TwoSidesRendererProps) => {
  const { t } = useTranslation('declaration');
  const isMobile = useIsMobile();
  const { handleMousedown, drawerWidth, setCloseDrawer } = useData(open);

  return (
    <Box data-cy={`${TwoSidesRendererName}-${parentNameAndId}`}>
      {isMobile ? (
        <Dialog fullScreen open={open}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            <Button
              sx={{ borderRadius: '5%' }}
              data-cy={`CloseRightPanel-${parentNameAndId}`}
              onClick={() => onClose()}
              endIcon={<CloseIcon />}
            >
              {t('formlib_close_button_text')}
            </Button>
            {children && <Box>{children}</Box>}
          </Box>
        </Dialog>
      ) : (
        <Drawer
          open={open}
          sx={RightDrawer}
          variant="persistent"
          anchor="right"
          PaperProps={{ style: { width: drawerWidth } }}
        >
          <Box
            sx={{
              ...FlexRow,
              height: '100%',
            }}
          >
            <IconButton
              sx={{ borderRadius: '5%', height: '100%' }}
              data-cy={`CloseRightPanel-${parentNameAndId}`}
              onClick={() => {
                setCloseDrawer();
                onClose();
              }}
            >
              <Box
                id="dragger"
                sx={{
                  width: '5px',
                  cursor: 'ew-resize',
                  padding: '4px 0 0',
                  borderTop: '1px solid #ddd',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  height: '100%',
                  zIndex: 100,
                  backgroundColor: `${dippedGreen500}`,
                }}
                onMouseDown={() => {
                  handleMousedown();
                }}
              />
              <ArrowForwardIosIcon />
            </IconButton>
            {children && (
              <Box
                sx={{
                  paddingTop: '20px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: '100%',
                  overflowY: 'auto',
                  height: '100%',
                }}
              >
                {children}
              </Box>
            )}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};
export default observer(TwoSidesRenderer);
