import mapDataSource from './mapDataSource';
import { EditDesc } from '90.quickConnect.Models/models';

const mapEditDesc = ({
  editFormat,
  lines,
  dataSourceType,
  dataSource,
  lruCount,
  defaultValue,
  value,
}: EditDesc): EditDesc =>
  ({
    editFormat: editFormat,
    lines: lines ?? undefined,
    dataSourceType: dataSourceType,
    dataSource: dataSource ? mapDataSource(dataSource) : undefined,
    lruCount: lruCount ?? undefined,
    value: value ?? defaultValue ?? '',
    defaultValue: defaultValue,
  } as EditDesc);

export default mapEditDesc;
