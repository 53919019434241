import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    InboxesStore: { getInboxItems, resetTasks },
    UserSettingsStore: { inboxesAsTree },
    CommonStore: { setWorkInProgress, setWorkDone },
  } = useStore();

  const { t: tInboxes } = useTranslation('inboxes');

  const getInboxesJob = useMemo(() => [uuidv4(), tInboxes('jobMessage.getInboxesJob')] as [string, string], [tInboxes]);

  const shouldGetList = useRef(true);

  useEffect(() => {
    resetTasks();
    if (shouldGetList.current === true) {
      shouldGetList.current = false;
      setWorkInProgress(getInboxesJob);
      getInboxItems().finally(() => {
        setWorkDone(getInboxesJob[0]);
      });
    }
  }, [getInboxItems, getInboxesJob, resetTasks, setWorkDone, setWorkInProgress]);

  return { inboxesAsTree };
};
