import React from 'react';
import { observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { SummaryItemProps } from './types';
import { DialogSummaryItemName } from './const';
import useLabelForSummaryItem from '50.quickConnect.Fields/hooks/useLabelForSummaryItem';

const SummaryItem = ({ label, mandatory, fieldType, errors, value, parentId }: SummaryItemProps) => {
  const nameWithId = `${DialogSummaryItemName}-${parentId}`;
  const labelSpan = useLabelForSummaryItem(label, mandatory, nameWithId, fieldType, errors, value);

  return (
    <Box
      data-cy={nameWithId}
      sx={{
        marginRight: '5px',
        paddingBottom: '0.4em',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {labelSpan}
    </Box>
  );
};
export default observer(SummaryItem);
