import { operationWithNumbers } from './numbers/operationWithNumbers';
import { operationWithDates } from './dates/operationWithDates';
import { operationWithTimes } from './times/operationWithTimes';
import { operationWithStrings } from './strings/operationWithStrings';
import { operationWithBooleans } from './booleans/operationWithBooleans';
import { operationWithChoices, operationWithChoicesArray } from './choices/operationWithChoices';

export {
  operationWithNumbers,
  operationWithDates,
  operationWithTimes,
  operationWithStrings,
  operationWithBooleans,
  operationWithChoices,
  operationWithChoicesArray,
};
