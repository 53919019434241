import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { DateTimeFieldType, FieldType, OperatorResult } from '90.quickConnect.Models/enums';
import {
  BaseQCOperator,
  Dependency,
  AllFieldValueTypes,
  CtrlRefOperator,
  Choice,
  FieldDesc,
  QCNotification,
  AttachmentItemData,
  NotificationTarget,
} from '90.quickConnect.Models/models';

function isFloat(n: string | number) {
  return Number(n) === n && n % 1 !== 0;
}

function isInt(n: string | number) {
  return Number(n) === n && n % 1 === 0;
}

const getRefValue = (
  baseQCOperator: BaseQCOperator,
  dependencies: Dependency[],
): [AllFieldValueTypes, OperatorResult] => {
  const ctrlRef = baseQCOperator as CtrlRefOperator;
  let field = dependencies.find((d) => d.id.toUpperCase() === ctrlRef.controlId.toUpperCase());
  if (!field) field = dependencies.find((d) => d.fullPathId.toUpperCase() === ctrlRef.controlId.toUpperCase());
  if (!field) return [undefined, OperatorResult.ERROR];
  const { value, fieldType, type } = field as FieldDesc;
  switch (fieldType) {
    case FieldType.Combo:
    case FieldType.HierarchicalList:
    case FieldType.RadioList:
    case FieldType.CheckBoxList:
      return [value, OperatorResult.LISTCHOICE];
    case FieldType.Numeric:
      return [value, OperatorResult.DOUBLE];
    case FieldType.Digits:
      return [value, OperatorResult.INT];
    case FieldType.DateTime:
      return type === DateTimeFieldType.Date || !type ? [value, OperatorResult.DATE] : [value, OperatorResult.DATETIME];
    case FieldType.Notification:
      if (value) {
        const { selectedTargets } = value as QCNotification;
        return [selectedTargets.map(({ target }: NotificationTarget) => target).join(';'), OperatorResult.STRING];
      }
      return ['', OperatorResult.STRING];
    case FieldType.Time:
      return [value, OperatorResult.TIME];
    case FieldType.CheckBox:
      return [value, OperatorResult.BOOLEAN];
    case FieldType.Draw:
    case FieldType.Signature:
    case FieldType.Photo:
    case FieldType.Attachment:
    case FieldType.FixedImage:
    case FieldType.FixedAttachment:
      if (!Array.isArray(value)) return [false, OperatorResult.BOOLEAN];
      return [!!(value as AttachmentItemData[]).length, OperatorResult.BOOLEAN];
    case FieldType.Text:
    default:
      if (isNumeric(value)) {
        const numericValue = parseNumber(value);
        if (Number.isNaN(numericValue)) return [undefined, OperatorResult.ERROR];
        const typeValue: OperatorResult = Number.isInteger(numericValue) ? OperatorResult.INT : OperatorResult.DOUBLE;
        return [numericValue, typeValue];
      }

      if (value === null || value === undefined) return [undefined, OperatorResult.ERROR];
      return [value, OperatorResult.STRING];
  }
};

export default getRefValue;
