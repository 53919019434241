import { SxProps } from '@mui/system';

export const FormControlStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
};

export const getCardContentStyles: (distantUri: string) => SxProps = (distantUri) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: distantUri ? 'pointer' : 'not-allowed',
  padding: 0,
  height: '150px',
  width: '150px',
  '&:last-child': {
    paddingBottom: 0,
  },
});

export const CardMediaStyles: SxProps = {
  cursor: 'pointer',
  objectFit: 'contain',
};

export const TitleStyles: SxProps = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  padding: '5px 10px',
  textOverflow: 'ellipsis',
  width: '100%',
};
