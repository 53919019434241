import { FormContextDico } from '20.formLib/helpers/QCScriptLib/bridge/types/qcsFormContext';
import { FormContextActionType } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/actionType';
import { FormContextAction } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/actionType/enums';

export const formContextReducer = (
  state: FormContextDico | null,
  actionType: FormContextActionType,
): FormContextDico | null => {
  const { type, payload } = actionType;

  switch (type) {
    case FormContextAction.ON_SUCCESS:
      const { fieldReadonlyFlag, fieldUpdated, fieldVisibilityFlag, warnings, informations } = payload;
      return { ...state, fieldReadonlyFlag, fieldValue: fieldUpdated, fieldVisibilityFlag, warnings, informations };

    case FormContextAction.ON_ERROR:
      return null;

    default:
      return state;
  }
};
