import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab, Box } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { TransferCommandName } from './const';
import { TransferCommandProps } from './types';
import useData from './hooks';

const TransferCommand = ({ handleItemDatas, transferDeclaration, context }: TransferCommandProps) => {
  const { t, onTransfer } = useData(handleItemDatas, transferDeclaration, context);

  return (
    <Tooltip title={t('formlib_action_button_transfer').toString()} placement="right">
      <span>
        <Box color="success" onClick={onTransfer}>
          <Fab
            sx={{ marginTop: '10px', backgroundColor: '#007BFF' }}
            data-cy={`${TransferCommandName}-btn-transfer`}
            size="large"
          >
            <SwapHorizIcon />
          </Fab>
        </Box>
      </span>
    </Tooltip>
  );
};

export default observer(TransferCommand);
