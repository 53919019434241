import { OrganizationalUnitInfo } from '90.quickConnect.Models/models';
import { OrganizationalUnitViewer } from '90.quickConnect.Models/models/user/organizationalUnitViewer';

const mapOrganizationnalUnits = (OrganizationnalUnit: OrganizationalUnitInfo): OrganizationalUnitViewer =>
  ({
    code: OrganizationnalUnit.code,
    name: OrganizationnalUnit.name,
    fullName: OrganizationnalUnit.fullName,
    id: OrganizationnalUnit.id,
    isRattachment: OrganizationnalUnit.isRattachement,
    logo: OrganizationnalUnit.logo,
  } as OrganizationalUnitViewer);
export default mapOrganizationnalUnits;
