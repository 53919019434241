export enum OperatorResult {
  ERROR = 'ERROR',
  INT = 'INT',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  TIME = 'TIME',
  DATETIME = 'DATETIME',
  DURATION = 'DURATION',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  LISTCHOICE = 'LISTCHOICE',
  LISTTASK = 'LISTTASK',
}
