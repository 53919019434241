import { convertToBooleanValue } from '80.quickConnect.Core/helpers';
import { CheckBoxDesc } from '90.quickConnect.Models/models';

const mapCheckBoxDesc = (field: CheckBoxDesc): CheckBoxDesc => {
  const { defaultValue, isVertical, value } = field;
  const initValue = convertToBooleanValue(value) ?? convertToBooleanValue(defaultValue);
  return {
    value: value ?? initValue ?? false,
    defaultValue: defaultValue,
    isVertical: isVertical,
  } as unknown as CheckBoxDesc;
};

export default mapCheckBoxDesc;
