import { Components, Theme } from '@mui/material/styles';
import createPalette, { PaletteOptions } from '@mui/material/styles/createPalette';
import { frFR, enUS, deDE, nlNL, Localization } from '@mui/material/locale';
import { PaletteMode } from '@mui/material';
import { TopBarHeight } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';
import { getLanguageCode } from '80.quickConnect.Core/helpers';
import RubikVar from '00.app/fonts/Rubik/Rubik-VariableFont_wght.ttf';
import N27 from '00.app/fonts/N27/n27-regular-webfont.woff2';

declare module '@mui/material/styles' {
  interface Palette {
    borderGroup: Palette['primary'];
    backGroundGroup: Palette['primary'];
    lightThemeTextColor: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    backGroundGroup?: PaletteOptions['primary'];
    lightThemeTextColor?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lightThemeTextColor: true;
  }
}
// Colors From BlackFoot -------------------------------------------------//

// Green
export const dippedGreen50 = '#FAFFFD';
export const dippedGreen100 = '#EAFEF8';
export const dippedGreen200 = '#BEF3E5';
export const dippedGreen300 = '#8DEBD3';
export const dippedGreen400 = '#62E4C5';
export const dippedGreen500 = '#17DCB8';
export const dippedGreen600 = '#00CAAA';
export const dippedGreen700 = '#00B59A';
export const dippedGreen800 = '#00A08A';
export const dippedGreen900 = '#007C6E';

// Brown
export const kraft50 = '#FFFDDA';
export const kraft100 = '#FFF7D4';
export const kraft200 = '#FFEECC';
export const kraft300 = '#efd6ad';
export const kraft400 = '#D9BF9E';
export const kraft500 = '#BAA181';
export const kraft600 = '#987950';
export const kraft700 = '#7A6446';
export const kraft800 = '#584529';
export const kraft900 = '#352407';

// Red
export const terraCotta50 = '#FFE6C1';
export const terraCotta100 = '#FFC2A5';
export const terraCotta200 = '#F19F83';
export const terraCotta300 = '#D17E5F';
export const terraCotta400 = '#BA6546';
export const terraCotta500 = '#A1492A';
export const terraCotta600 = '#953F26';
export const terraCotta700 = '#83311B';
export const terraCotta800 = '#752418';
export const terraCotta900 = '#651310';

// Grey
export const slateGrey50 = '#FFFFFF';
export const slateGrey100 = '#F9FFFA';
export const slateGrey200 = '#F3F9F5';
export const slateGrey300 = '#E5EAE6';
export const slateGrey400 = '#C1C6C2';
export const slateGrey500 = '#A3A8A4';
export const slateGrey600 = '#797E7A';
export const slateGrey700 = '#464B47';
export const slateGrey800 = '#464B47';
export const slateGrey900 = '#262A26';

export const borderLightColor = '#DBDFDA';
export const textBlackColor = '#737273';
export const pureWhiteColor = '#fff';
export const pureWhiteColor100 = '#F9FFFA';
export const ghostWhite = '#f5f6fa';
export const blackColor = '#0b0b0b';
const orangeRedCrayola = '#ff9800';
const lightSkyBlue = '#90caf9';

const languageCode = getLanguageCode();

export const getMuiLocal = (): Localization => {
  switch (languageCode) {
    case 'nl':
      return nlNL;
    case 'de':
      return deDE;
    case 'en':
      return enUS;
    case 'fr':
    default:
      return frFR;
  }
};

export const lightPalette = createPalette({
  mode: 'light',
  primary: {
    main: dippedGreen500,
  },
  secondary: {
    main: slateGrey800,
  },
  error: {
    main: terraCotta300,
  },
  success: {
    light: dippedGreen100,
    main: dippedGreen500,
  },
  warning: {
    main: orangeRedCrayola,
  },
  info: {
    main: lightSkyBlue,
  },
  grey: {
    main: slateGrey800,
  },
  topBar: {
    main: ghostWhite,
  },
  text: {
    primary: textBlackColor,
    secondary: textBlackColor,
    disabled: textBlackColor,
  },
  divider: slateGrey800,
  background: {
    default: ghostWhite,
    paper: ghostWhite,
  },
  borderGroup: {
    main: borderLightColor,
  },
  backGroundGroup: {
    main: dippedGreen500,
  },
  lightThemeTextColor: {
    main: ghostWhite,
    light: ghostWhite,
    dark: slateGrey800,
  },
} as PaletteOptions);

export const darkPalette = createPalette({
  mode: 'dark',
  primary: {
    main: dippedGreen500,
  },
  secondary: {
    main: slateGrey300,
  },
  error: {
    main: terraCotta300,
  },
  success: {
    light: dippedGreen900,
    main: dippedGreen500,
  },
  warning: {
    main: orangeRedCrayola,
  },
  info: {
    main: lightSkyBlue,
  },
  grey: {
    main: slateGrey800,
  },
  topBar: {
    main: pureWhiteColor,
  },
  text: {
    primary: pureWhiteColor,
    secondary: pureWhiteColor,
    disabled: pureWhiteColor,
  },
  divider: '#fff',
  background: {
    default: slateGrey900,
    paper: slateGrey900,
  },
  borderGroup: {
    main: slateGrey700,
  },
  backGroundGroup: {
    main: kraft400,
  },
  lightThemeTextColor: {
    main: ghostWhite,
    light: slateGrey800,
    dark: ghostWhite,
  },
} as PaletteOptions);

export const getComponents = (muiMode: PaletteMode, theme: Theme): Components<Theme> =>
  ({
    MuiModal: {
      styleOverrides: {
        root: {
          maxWidth: '100vw',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            height: '100%',
          },
          [theme.breakpoints.up('sm')]: {
            height: '50%',
          },
        },
        flexContainer: {
          height: `${TopBarHeight}px`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          maxWidth: '100vw',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          borderTop: theme.palette.mode === 'dark' ? `1px solid ${slateGrey700}` : `1px solid ${slateGrey300}`,
          height: '80px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          borderLeft: `2px solid ${ghostWhite}`,
          boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          zIndex: 10,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSuccess: {
          color: dippedGreen500,
        },
        colorError: {
          color: terraCotta600,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: `
        @font-face {
          font-family: 'Rubik-var';
          font-style: normal;
          font-display: swap;
          src: url(${RubikVar}) format('ttf');
          font-weight: 100 900;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'N27-Regular';
          font-style: normal;
          font-display: swap;
          src: url(${N27}) format('woff2');
          font-weight: 100 900;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        scrollbar-color: ${muiMode === 'light' ? pureWhiteColor : slateGrey900} ${dippedGreen500} !important;
        scrollbar-width: thin !important;
        ::-webkit-scrollbar {import { terraCotta600 } from './theme';

          -webkit-appearance: none;
          width: 8px;
        }
        ::-webkit-scrollbar-thumb {
          background-clip: padding-box;
          background-color: ${dippedGreen500};
          border-radius: 9999px;
        }
        `,
      },
    },
  } as Components<Theme>);
