import { useMemo } from 'react';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';
import { DeclarationViewer } from '90.quickConnect.Models/models';

export const useData = (children: TreeViewData<TreeDataItem>[]): UseDataProps => {
  const nodeChildren = useMemo(() => children.filter((child) => child.data === undefined), [children]);
  const declarationChildren = useMemo(() => {
    const decl = children.filter((child) => !!child.data);
    return decl.sort((a, b) => {
      return (
        new Date((b.data as DeclarationViewer).editedAt).getTime() -
        new Date((a.data as DeclarationViewer).editedAt).getTime()
      );
    });
  }, [children]);

  return {
    nodeChildren,
    declarationChildren,
  };
};
