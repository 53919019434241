import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { isAddressData, isEmptyAddressData } from '90.quickConnect.Models/guards';

export default function isEmptyFieldValue(value: AllFieldValueTypes): boolean {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    Number.isNaN(value) ||
    (Array.isArray(value) && value.length === 0) ||
    (value instanceof Date && Number.isNaN(value.getTime())) ||
    (isAddressData(value) && isEmptyAddressData(value))
  );
}
