import React from 'react';
import { observer } from 'mobx-react';
import { Box, FormControl, IconButton, InputAdornment, Tooltip, useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import useData from './hooks';
import { CounterQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import { CounterDesc } from '90.quickConnect.Models/models';
import { OutlinedInputStyledComponent } from '50.quickConnect.Fields/FieldsTypes/Inputs/CounterQcField/styles';

const CounterQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  labelClass,
}: ValueFieldsTypesProps) => {
  const counterDesc = field as CounterDesc;
  const { toolTip, id, min, fullPathId } = counterDesc;
  const nameWithId = `${CounterQcFieldName}-${id}`;
  const { breakpoints } = useTheme();
  const { t, localValue, handleChange, handleClear, maxValue, minValue } = useData(counterDesc, updateDeclaration);

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      data-cy={nameWithId}
      sx={FieldMainBox(breakpoints)}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <FormControl data-cy={nameWithId} variant="outlined" fullWidth>
        <OutlinedInputStyledComponent
          data-cy={`${nameWithId}-input`}
          id={`${nameWithId}-input`}
          type={'number'}
          value={localValue}
          required
          onChange={handleChange}
          inputProps={{
            readOnly: isReadOnly,
            disabled: isReadOnly,
            min: minValue,
            max: maxValue,
          }}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title={t('formlib_array_deleted_title').toString()} data-cy={`${nameWithId}-clear-btn`}>
                <span>
                  <IconButton edge="end" disabled={isReadOnly} onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};
export default observer(CounterQcField);
