import React from 'react';
import { Box, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { WithMenuName } from './const';
import { MainBoxStyle } from './styles';
import useData from './hooks';
import { WithMenuProps } from './types';
import LeftMenu from './LeftMenu';
import TopBar from '10.quickConnect.app/components/shared/LayOut/TopBar';

const WithMenu = ({ pageTitle, children }: WithMenuProps) => {
  const { t } = useTranslation('declaration');
  const { showLeftMenu } = useData();
  const theme = useTheme();

  return (
    <Box data-cy={WithMenuName}>
      <TopBar pageTitle={pageTitle} />
      {showLeftMenu && <LeftMenu />}
      <Box sx={MainBoxStyle(theme, showLeftMenu)}>{children}</Box>
    </Box>
  );
};
export default observer(WithMenu);
