import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CommandType } from '../types';
import { UseDataProps } from './types';
import { FORMS } from '10.quickConnect.app/routes/paths';
import { ItemData } from '90.quickConnect.Models/models';
import { FormType } from '90.quickConnect.Models/enums';

const useData = (
  formType: FormType,
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>,
  sendDeclaration: (newItemData: ItemData) => Promise<boolean>,
  setDeclarationData: Dispatch<SetStateAction<ItemData>>,
  declarationId: string,
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const navigate = useNavigate();

  const onCancelWorkflow = useCallback(async () => {
    const [goToNextStep, nextStepIndex, newItemData] = await handleItemDatas(CommandType.Cancel);
    const declarationSend = await sendDeclaration(newItemData);
    if (declarationSend) {
      if (goToNextStep && nextStepIndex && newItemData && newItemData.Workflow) {
        newItemData.Workflow.currentStep = nextStepIndex;
        setDeclarationData(newItemData);
      } else {
        navigate(FORMS, { replace: true });
      }
    }
  }, [navigate, handleItemDatas, sendDeclaration, setDeclarationData]);

  return {
    t,
    onCancelWorkflow,
  };
};

export default useData;
