import { Choice } from '90.quickConnect.Models/models';

type UnknownChoice = {
  label: unknown;
  value: unknown;
};

export function isAlertValueStringFormat(str: string): boolean {
  return (
    str.localeCompare('Incident', undefined, { sensitivity: 'base' }) === 0 ||
    str.localeCompare('Alerte', undefined, { sensitivity: 'base' }) === 0 ||
    str.localeCompare('RAS', undefined, { sensitivity: 'base' }) === 0 ||
    str.localeCompare('Aucune', undefined, { sensitivity: 'base' }) === 0
  );
}

export function isAlertValue({ label, value }: UnknownChoice): boolean {
  const correctLbl = typeof label === 'string' && isAlertValueStringFormat(label);

  const correctValue = typeof value === 'string' && isAlertValueStringFormat(value);

  return correctLbl && correctValue;
}

export function isAnAlertChoice(input: unknown): input is Choice {
  return (
    typeof input === 'object' &&
    input !== null &&
    !Array.isArray(input) &&
    'label' in input &&
    'value' in input &&
    isAlertValue(input)
  );
}
