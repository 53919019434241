import { useState, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const useData = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [dimensions, setDimensions] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    if (isLg) {
      setDimensions({
        height: 139,
        width: 138,
      });
    } else if (isMd) {
      setDimensions({
        height: 111,
        width: 110,
      });
    } else if (isSm) {
      setDimensions({
        height: 99,
        width: 98,
      });
    } else if (isXs) {
      setDimensions({
        height: 61,
        width: 60,
      });
    }
  }, [isLg, isMd, isSm, isXs]);

  return dimensions;
};

export default useData;
