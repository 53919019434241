import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogSummary from '../DialogQcField/DialogSummary';
import useData from './hooks';
import { CustomAccordion, CustomAccordionDetails, CustomAccordionSummary } from './styles';
import { ContainerFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { GroupDesc } from '90.quickConnect.Models/models';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';

const GroupQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  setDeclaration,
  context,
  updateFieldErrors,
  labelClass,
  updateItemsForThisField,
  openByImagesGroupField,
}: ContainerFieldsTypesProps) => {
  const groupField = field as GroupDesc;
  const { fullPathId, summary, items } = groupField;
  const { t, nameWithId, isExpanded, handleChange } = useData(
    groupField,
    updateDeclaration,
    setSelectedIndex,
    openByImagesGroupField,
  );

  const customTheme = useTheme();
  const { breakpoints } = customTheme;

  return (
    <Box className={labelClass} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <CustomAccordion
        data-cy={nameWithId}
        theme={customTheme}
        expanded={openByImagesGroupField || isExpanded}
        onChange={() => handleChange(isExpanded)}
        elevation={0}
      >
        <CustomAccordionSummary
          theme={customTheme}
          disabled={openByImagesGroupField}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel1a-content-${groupField.stateId}-${field.id}`}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '6px 7px 6px 0px' }}>
            <Box>{labelSpan}</Box>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails theme={customTheme}>
          {items.map((item) => {
            const key = getFieldKey(item);
            return item?.importance ? (
              <Box sx={C2SImportant(item.importance)}>
                <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                  <FieldRenderer
                    key={key}
                    field={item}
                    updateItemsForThisField={updateItemsForThisField}
                    updateDeclaration={updateDeclaration}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isReadOnly={isReadOnly || item.fieldIsReadOnly}
                    flattenFields={flattenFields}
                    setDeclaration={setDeclaration}
                    context={context}
                    updateFieldErrors={updateFieldErrors}
                  />
                </MandatoryField>
              </Box>
            ) : (
              <MandatoryField key={getFieldKey(item)} fieldDesc={item}>
                <FieldRenderer
                  key={key}
                  field={item}
                  updateItemsForThisField={updateItemsForThisField}
                  updateDeclaration={updateDeclaration}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  isReadOnly={isReadOnly || item.fieldIsReadOnly}
                  flattenFields={flattenFields}
                  setDeclaration={setDeclaration}
                  context={context}
                  updateFieldErrors={updateFieldErrors}
                />
              </MandatoryField>
            );
          })}
        </CustomAccordionDetails>
      </CustomAccordion>
      {/* on est obligé d'utiliser l'opérateur spread ([...items]) pour que react comprennent les updates */}
      {summary === true && !isExpanded && (
        <DialogSummary items={[...items]} parentId={fullPathId} isReadOnly={isReadOnly} />
      )}
    </Box>
  );
};
export default observer(GroupQcField);
