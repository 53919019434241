import { isActionData, isAnAlertChoice, isChoice } from '90.quickConnect.Models/guards';
import { Choice } from '90.quickConnect.Models/models';

export default function mapAllFieldTypeValuesToChoice(nextValue: unknown): Choice | undefined {
  let result: Choice | undefined;

  if (isAnAlertChoice(nextValue) || isChoice(nextValue)) result = { ...nextValue };
  else if (typeof nextValue === 'string') {
    return {
      label: nextValue.trim(),
      value: nextValue.trim(),
      data: undefined,
    };
  } else if (isActionData(nextValue)) {
    const [first] = nextValue;

    const { value } = first;

    return Array.isArray(value) ? value[0] : value;
  }

  return result;
}
