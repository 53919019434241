export enum ImportanceLevel {
  /// <summary>
  /// normal par défaut
  /// </summary>
  Normal = 0,

  /// <summary>
  /// mise en avant
  /// </summary>
  Promote = 1,

  /// <summary>
  /// important
  /// </summary>
  Important = 2,
}
