import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { PasswordRulesType } from '10.quickConnect.app/components/domain/Login/types';

export const verifyResetCodeAndPassword = (
  isChangePassword: boolean,
  resetCodeOrCurrentPassword: string,
  newPassword: string,
  confirmPassword: string,
  passwordRules: PasswordRulesType,
  t: TFunction,
): boolean => {
  if (resetCodeOrCurrentPassword.trim() === '') {
    if (isChangePassword) {
      toast.error(t('qcapp_current_password_required').toString());
      return false;
    } else {
      toast.error(t('forget_password.reset_code_required').toString());
      return false;
    }
  }
  if (newPassword === '') {
    toast.error(t('forget_password.new_password_required').toString());
    return false;
  }
  if (confirmPassword === '') {
    toast.error(t('forget_password.confirm_password_required').toString());
    return false;
  }
  if (newPassword !== '' && confirmPassword !== '' && confirmPassword !== newPassword) {
    toast.error(t('forget_password.confirm_password_not_match').toString());

    return false;
  }
  if (Object.values(passwordRules).some((v) => v == false)) {
    toast.error(t('forget_password.new_password_rules').toString());

    return false;
  }
  return true;
};
