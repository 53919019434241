import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionsRender } from '50.quickConnect.Fields/FieldsTypes/Inputs/TextQcField/types';
import { useStore } from '30.quickConnect.Stores';
import { DataSourceField, SearchFamillyData } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { DataSourceType, InternalFieldState } from '90.quickConnect.Models/enums';
import { errorHandler } from '80.quickConnect.Core/helpers';

export const useFieldAutoComplete = () => {
  // Tag
  const tag = '50.quickConnect.Fields/hools/useAutoComplete.ts';

  const [lastStringSearchWhereMoreItems, setLastStringSearchWhereMoreItems] = useState<string>('');
  const [isThereMoreItems, setIsThereMoreItems] = useState<boolean>(true);
  const [searchFieldValues, setSearchFieldValues] = useState<OptionsRender[]>([]);
  const [searchFamillyData, setSearchFamillyData] = useState<SearchFamillyData[]>([]);

  // Imported Hooks
  const { t } = useTranslation('declaration');

  // Custom Hooks
  const {
    EquipmentsStore: { getSearchFieldValues, getSearchFieldValuesAzureAD },
    DeclarationStore: {
      deleteAutoCompleteForFamilly,
      deleteAutoCompleteForAAD,
      registerNewAutoCompleteForFamilly,
      registerNewAutoCompleteForAAD,
      registerNewState,
    },
  } = useStore();

  // Ce memo permet de savoir si on requete l'API ou bien on recherche dans les autoCompleteProp
  const isOkForFilterInCachedArray = useCallback(
    (searchText: string): boolean =>
      !isThereMoreItems &&
      searchText.length >= lastStringSearchWhereMoreItems.length &&
      searchText.toLowerCase().startsWith(lastStringSearchWhereMoreItems.toLowerCase()),
    [isThereMoreItems, lastStringSearchWhereMoreItems],
  );

  const getOptionsRenderForFamilyDataSource = useCallback(
    async (searchValue: string, dataSource: DataSourceField, searchAllUO: boolean): Promise<OptionsRender[]> => {
      if (isOkForFilterInCachedArray(searchValue))
        return searchFieldValues.filter(
          ({ title, description }: OptionsRender): boolean =>
            title.toLowerCase().includes(searchValue.toLowerCase()) ||
            description.toLowerCase().includes(searchValue.toLowerCase()),
        );

      const { idDataSource, idsAttributesSearch, idsAttributesSelect, addSearchAttributResult } = dataSource;

      const searchResults = await getSearchFieldValues(
        idDataSource,
        idsAttributesSearch,
        idsAttributesSelect,
        searchValue,
        t,
        searchAllUO,
        addSearchAttributResult,
      );

      const newSearchFamillyData: SearchFamillyData[] =
        searchResults?.newSearchFamillyResults.filter((sr) => sr !== null) ?? [];

      // Enregistrer les données pour la récupération du schemaId et de l'entityInstanceId
      setSearchFamillyData(newSearchFamillyData);

      const newSearchFieldValues =
        newSearchFamillyData.map(
          ({ id, title, description, value }: SearchFamillyData) =>
            ({ id, title, description, value } as OptionsRender),
        ) ?? [];

      const newIsThereMoreItems =
        searchResults && newSearchFieldValues.length > 0 ? searchResults.thereIsMoreItems : true;
      setIsThereMoreItems(newIsThereMoreItems);
      setSearchFieldValues(newSearchFieldValues);

      if (isThereMoreItems && newIsThereMoreItems === false) setLastStringSearchWhereMoreItems(searchValue);

      return newSearchFieldValues;
    },
    [isThereMoreItems, getSearchFieldValues, searchFieldValues, isOkForFilterInCachedArray, t],
  );

  const getOptionsRenderForAAD = useCallback(
    async (searchValue: string): Promise<OptionsRender[]> => {
      if (isOkForFilterInCachedArray(searchValue)) {
        const results = searchFieldValues.filter(({ title, description, data }: OptionsRender): boolean => {
          return (
            (title ?? '').toLowerCase().includes(searchValue.toLowerCase()) ||
            (description ?? '').toLowerCase().includes(searchValue.toLowerCase()) ||
            (data?.get('login')?.toLowerCase() ?? '').includes(searchValue.toLowerCase())
          );
        });
        return results;
      }

      const searchResults = await getSearchFieldValuesAzureAD(searchValue, t);

      const newSearchFieldValues: OptionsRender[] = searchResults?.searchUsersResults ?? [];

      const newIsThereMoreItems =
        searchResults && newSearchFieldValues.length > 0 ? searchResults.thereIsMoreItems : true;
      setIsThereMoreItems(newIsThereMoreItems);
      setSearchFieldValues(newSearchFieldValues);

      if (isThereMoreItems && newIsThereMoreItems === false) setLastStringSearchWhereMoreItems(searchValue);

      return newSearchFieldValues;
    },
    [getSearchFieldValuesAzureAD, isOkForFilterInCachedArray, isThereMoreItems, searchFieldValues, t],
  );

  const deleteAutoComplete = useCallback(
    (fullPathId: string, dataSourceType: DataSourceType) => {
      if (dataSourceType === DataSourceType.AzureAD) {
        return deleteAutoCompleteForAAD(fullPathId);
      }
      return deleteAutoCompleteForFamilly(fullPathId);
    },
    [deleteAutoCompleteForFamilly, deleteAutoCompleteForAAD],
  );

  const registerNewFamillySearch = useCallback(
    (fullPathId: string, value: string) => {
      const searchFamillyElement = searchFamillyData.find(
        ({ description, title, value: searchValue }: SearchFamillyData) =>
          description === value || title === value || value === searchValue,
      );

      // Gérer l'erreur dans le cas où on ne retrouve pas l'element
      if (!searchFamillyElement) {
        const errorNotFoundElement = new Error(
          `[Client Web] - useFieldAutoComplete.ts registerNewFamillySearch method failed:  L'élément seachFamillyElement n'est pas trouvé pour la valeur ${value} et pour le fullPathId ${fullPathId}`,
        );
        errorHandler(tag, errorNotFoundElement, 'registerNewFamillySearch');

        throw errorNotFoundElement;
      }

      const { schemaId, entityInstanceId } = searchFamillyElement;

      registerNewAutoCompleteForFamilly(fullPathId, schemaId, entityInstanceId);
    },
    [searchFamillyData, tag, registerNewAutoCompleteForFamilly],
  );

  const registerValidState = useCallback(
    (fullPathId: string) => {
      registerNewState(fullPathId, InternalFieldState.Valid, true);
    },
    [registerNewState],
  );

  const unRegisterValidState = useCallback(
    (fullPathId: string) => {
      registerNewState(fullPathId, InternalFieldState.Valid, false);
    },
    [registerNewState],
  );

  const registerNewAADSearch = useCallback(
    (fullPathId: string, value: string) => {
      const elementToFind: OptionsRender | undefined = searchFieldValues.find(
        ({ value: optionValue }: OptionsRender) => optionValue === value,
      );

      if (!elementToFind) {
        const errorNotFoundElement = new Error(
          `[Client Web] - useFieldAutoComplete.ts registerNewAADSearch method failed:  L'élément n'est pas trouvé pour la valeur ${value} et pour le fullPathId ${fullPathId}`,
        );

        errorHandler(tag, errorNotFoundElement, 'registerNewAADSearch');

        throw errorNotFoundElement;
      }

      registerNewAutoCompleteForAAD(fullPathId, value);
    },
    [searchFieldValues, tag, registerNewAutoCompleteForAAD],
  );

  return {
    getOptionsRenderForFamilyDataSource,
    getOptionsRenderForAAD,
    deleteAutoComplete,
    registerNewFamillySearch,
    registerNewAADSearch,
    registerValidState,
    unRegisterValidState,
  };
};
