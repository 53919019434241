import { FieldDesc } from '90.quickConnect.Models/models';

const updateItemsFieldOrChild = (fullPathId: string, field: FieldDesc, items: FieldDesc[]) => {
  if (field.fullPathId.toLowerCase() == fullPathId && field.items !== items) {
    field.items = [...items];
    // Pas besoin d'update le stateId car la méthode getFieldKey utilise l'id des enfants de manière récursive
  }
  if (field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => updateItemsFieldOrChild(fullPathId, child, items));
  }
};

export default updateItemsFieldOrChild;
