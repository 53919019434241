import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  compareAsc,
  differenceInMilliseconds,
  format,
  getDate,
  getDay,
  getDayOfYear,
  getHours,
  getMilliseconds,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
  isAfter,
  isBefore,
  isDate,
  isEqual,
  isExists,
  parse,
  setMilliseconds,
  setSeconds,
} from 'date-fns';
import { QCSBaseTypedObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseTypedObject';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';
import { QCSInt } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSInt';
import { QCSString } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSString';
import { QCSArray } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSArray';
import { QCSBool } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBool';
import { QCSDouble } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSDouble';
import { QCSLong } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSLong';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

export class QCSDate extends QCSBaseTypedObject<DateTimeExtension | null> {
  public static undef: QCSDate = new QCSDate(null);

  public constructor(...args: (number | null | Date)[]) {
    super(new DateTimeExtension());

    switch (args.length) {
      case 1:
        // eslint-disable-next-line
        const arg: number | null | Date = args[0];
        if (typeof arg === 'number') {
          if (arg < 0) throw new Error('A date cannot be defined on the base of a negative Tick (timestamp) value.');

          this.value = new DateTimeExtension(new Date(arg * 1000));
        } else if (arg instanceof DateTimeExtension) {
          this.value = arg;
        } else if (arg instanceof Date) {
          this.value = new DateTimeExtension(arg);
        } else if (arg === null || arg === '' || arg === undefined) {
          this.value = null;
        } else throw new Error('QCSDate constructor: invalid parameter for arg[0].');
        break;

      case 3:
        // eslint-disable-next-line
        if (!args.every((arg: number | null | Date) => typeof arg === 'number'))
          throw new Error('QCSDate constructor: invalid type parameters.');
        const [year, month, day, hours, minutes, seconds, milliseconds] = args as number[];

        this.value = new DateTimeExtension(
          new Date(year ?? 1970, month ?? 0, day ?? 1, hours ?? 0, minutes ?? 0, seconds ?? 0, milliseconds ?? 0),
        );
        break;

      default:
        throw new Error('QCSDate constructor: invalid parameters length.');
    }
  }

  public static now = (): QCSDate => new QCSDate(setMilliseconds(new Date(), 0));

  public static utcNow(date?: Date | null): QCSDate {
    const dt: Date = date && date !== null ? date : new Date();
    return new QCSDate(new DateTimeExtension(dt, true));
  }

  isFalse = (): boolean => this.value === null;

  greaterThan = (arg1: QCSBaseObject): QCSBaseObject => {
    if (arg1 instanceof QCSDate && this.value !== null && arg1.value !== null) {
      return new QCSBool(isAfter(this.value, arg1.value));
    }

    return new QCSBool(false);
  };

  lessThan = (arg1: QCSBaseObject): QCSBaseObject => {
    if (arg1 instanceof QCSDate && this.value !== null && arg1.value !== null) {
      return new QCSBool(isBefore(this.value, arg1.value));
    }

    return new QCSBool(false);
  };

  greaterEqualThan = (arg1: QCSBaseObject): QCSBaseObject => {
    if (arg1 instanceof QCSDate && this.value !== null && arg1.value !== null) {
      const isGreaterEqualThan: boolean = isAfter(this.value, arg1.value) || isEqual(this.value, arg1.value);
      return new QCSBool(isGreaterEqualThan);
    }

    return new QCSBool(false);
  };

  lessEqualThan = (arg1: QCSBaseObject): QCSBaseObject => {
    if (arg1 instanceof QCSDate && this.value !== null && arg1.value !== null) {
      const isLessEqualThan: boolean = isBefore(this.value, arg1.value) || isEqual(this.value, arg1.value);
      return new QCSBool(isLessEqualThan);
    }

    return new QCSBool(false);
  };

  private isUTC = (): boolean => {
    const isUTC = this.value?.isUTC;
    return isUTC ?? false;
    // const res: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // return res === 'UTC';
  };

  public static staticCall = (methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => {
    switch (methodId) {
      case 1: // undef
        return QCSDate.undef;
      case 2: // now
        return this.now();
      case 3: // utcNow
        return this.utcNow();
      case 4: // today
        return new QCSDate(setMilliseconds(setSeconds(new Date(), 0), 0));
      case 23: // compare
        return new QCSInt(QCSDate.compare(qcParams));
      case 24: // parse
        return QCSDate.parse(qcParams);
      case 28: // create
        return QCSDate.create(qcParams);
      default:
        return null;
    }
  };

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (true) {
      case methodId === 5: // GetDate
        return new QCSDate(setMilliseconds(setSeconds(new Date(), 0), 0));

      case methodId === 6 && this.value !== null: // GetDay
        return new QCSInt(getDate(this.value as Date));

      case methodId === 7 && this.value !== null: // GetDayOfWeek
        return new QCSInt(getDay(this.value as Date));

      case methodId === 8 && this.value !== null: // GetDayOfWeek
        return new QCSInt(getDayOfYear(this.value as Date));

      case methodId === 9 && this.value !== null: // GetHour
        return new QCSInt(getHours(this.value as Date));

      case methodId === 10 && this.value !== null: // isUtcTime
        return new QCSBool(this.isUTC());

      case methodId === 11 && this.value !== null: // GetMilliSeconds
        return new QCSInt(getMilliseconds(this.value as Date));

      case methodId === 12 && this.value !== null: // GetMinutes
        return new QCSInt(getMinutes(this.value as Date));

      case methodId === 13 && this.value !== null: // GetMonth
        return new QCSInt(getMonth(this.value as Date) + 1); // Janvier = 0

      case methodId === 14 && this.value !== null: // GetSecond
        return new QCSInt(getSeconds(this.value as Date));

      case methodId === 15 && this.value !== null: // getTimeOfDay
        return new QCSDouble(this.getTimeOfDay());

      case methodId === 16 && this.value !== null: // getYear
        return new QCSInt(getYear(this.value as Date));

      case methodId === 17 && this.value !== null: // addDays
        if (qcParams.at(0) instanceof QCSInt)
          return new QCSDate(addDays(this.value as Date, (qcParams.at(0) as QCSInt).value));
        return QCSDate.undef;

      case methodId === 18 && this.value !== null: // addHours
        if (qcParams.at(0) instanceof QCSInt)
          return new QCSDate(addHours(this.value as Date, (qcParams.at(0) as QCSInt).value));
        return QCSDate.undef;

      case methodId === 19 && this.value !== null: // addMilliseconds
        if (qcParams.at(0) instanceof QCSInt)
          return new QCSDate(addMilliseconds(this.value as Date, (qcParams.at(0) as QCSInt).value));
        return QCSDate.undef;

      case methodId === 20 && this.value !== null: // addMinutes
        if (qcParams.at(0) instanceof QCSInt)
          return new QCSDate(addMinutes(this.value as Date, (qcParams.at(0) as QCSInt).value));
        return QCSDate.undef;

      case methodId === 21 && this.value !== null: // addMonths
        if (qcParams.at(0) instanceof QCSInt)
          return new QCSDate(addMonths(this.value as Date, (qcParams.at(0) as QCSInt).value));
        return QCSDate.undef;

      case methodId === 22 && this.value !== null: // addSeconds
        if (qcParams.at(0) instanceof QCSInt)
          return new QCSDate(addSeconds(this.value as Date, (qcParams.at(0) as QCSInt).value));
        return QCSDate.undef;

      case methodId === 25 && this.value !== null: // toLocalTime
        return new QCSDate(new DateTimeExtension((this.value as DateTimeExtension).toISOString(), false));

      case methodId === 26: // toString
        return new QCSString(this.toString(qcParams));

      case methodId === 27: // toUtcDatetime
        const dtValue = this.value !== null ? this.value : null;
        const date = QCSDate.utcNow(dtValue).value;

        return new QCSDate(date);

      case methodId === 29 && this.value !== null: // getTime ( ms from epoch )
        const dateUtcEpoch: Date = setMilliseconds(this.value as Date, 0);
        return new QCSLong(BigInt(dateUtcEpoch.getTime()));

      default:
        return null;
    }
  }

  private static compare = (qcParams: Array<QCSBaseObject>): number => {
    const d1 = qcParams[0] instanceof QCSDate ? qcParams[0] : null;
    const d2 = qcParams[1] instanceof QCSDate ? qcParams[1] : null;

    if (d1 === null) {
      return d2 === null ? 0 : -1;
    } else if (d2 === null) {
      return d1 === null ? 0 : 1;
    } else
      return d1.value !== null && d2.value !== null ? compareAsc(setSeconds(d1.value, 0), setSeconds(d2.value, 0)) : 1;
  };

  private static parse = (qcParams: Array<QCSBaseObject>): QCSDate => {
    const s1 = qcParams[0] as QCSString;

    if (qcParams.length > 1) {
      const fmtParam: QCSBaseObject = qcParams.at(1)!;

      if (fmtParam instanceof QCSString) {
        const dtStr = s1.value.replace('SSS', '000'); // Problème de parsage de C# "2021-12-24T12:00:00.SSSZ" => "2021-12-24T12:00:00.000Z"
        const fmtStr = fmtParam.value.replace('FFF', 'SSS').replace('Z', "'Z'");

        const result2: Date = parse(dtStr, fmtStr, new Date());

        if (result2 !== null) return new QCSDate(result2);

        return QCSDate.undef;
      } else if (fmtParam instanceof QCSArray) {
        const values: string[] = fmtParam.value
          .reduce(
            (acc: QCSString[], current: QCSBaseObject) => (current instanceof QCSString ? [...acc, current] : acc),
            [],
          )
          .map((qcsstring: QCSString) => qcsstring.value);

        for (const fmtStr of values) {
          const result3 = parse(s1.value, fmtStr, new Date());

          if (isDate(result3)) {
            return new QCSDate(result3);
          }
        }

        return QCSDate.undef;
      }
    }
    const result: Date | null = DateTimeExtension.parseMultiFormat(s1.value);

    if (result !== null && !isNaN(result.getTime())) return new QCSDate(result);

    return QCSDate.undef;
  };

  private static create = (qcParams: Array<QCSBaseObject>): QCSDate => {
    const y: number = QCSDate.checkIsNumeric(qcParams[0], 'year');
    const M: number = QCSDate.checkIsNumeric(qcParams[1], 'month') - 1;
    const d: number = QCSDate.checkIsNumeric(qcParams[2], 'day');
    const h: number = qcParams.length > 3 ? QCSDate.checkIsNumeric(qcParams[3], 'hour') : 0;
    const m: number = qcParams.length > 4 ? QCSDate.checkIsNumeric(qcParams[4], 'minute') : 0;
    const s: number = qcParams.length > 5 ? QCSDate.checkIsNumeric(qcParams[5], 'second') : 0;

    if (
      y < 0 ||
      !isExists(y, M, d) ||
      !DateTimeExtension.checkTime(h, 0, 24) ||
      !DateTimeExtension.checkTime(m, 0, 60) ||
      !DateTimeExtension.checkTime(s, 0, 60)
    )
      return new QCSDate(null);

    return new QCSDate(new Date(y, M, d, h, m, s));
  };

  private static checkIsNumeric = (
    qcParam: QCSBaseObject,
    paramType: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second',
  ): number => {
    switch (true) {
      case qcParam instanceof QCSInt:
        return (qcParam as QCSInt).value;

      case qcParam instanceof QCSDouble:
        return (qcParam as QCSDouble).value;

      case qcParam instanceof QCSLong:
        return Number((qcParam as QCSLong).value);

      default:
        switch (paramType) {
          case 'year':
            return 1970;

          case 'day':
            return 1;

          case 'month':
          case 'hour':
          case 'minute':
          case 'second':
            return 0;
        }
    }
  };

  private getTime = (): number => (this.value !== null ? this.value.getTime() : 0);

  private toString = (qcParams: Array<QCSBaseObject>): string => {
    if (this.value === null) return '';
    // Attention a prendre en compte les cas avec dates transformées en UTC
    const time0ffset: string = this.value.isUTC ? '+00:00' : format(new Date(), 'xxx');
    // eslint-disable-next-line

    if (qcParams.length === 1 && qcParams[0] instanceof QCSString) {
      const [dateFormat] = qcParams;
      const { value } = dateFormat;
      if (value.startsWith('%%')) {
        return this.getFormatedDateWithCustomPattern(value);
      }
      return DateTimeExtension.formatCustom(this.value, dateFormat.value);
    }

    if (!this.value.isUTC) return format(this.value, `yyyy-MM-dd'T'HH:mm:ss.SSSxx`);

    return DateTimeExtension.formatInTimeZoneUTC(this.value, `yyyy-MM-dd'T'HH:mm:ss.SSSxx`);
    // return DateTimeExtension.getDefaultFormat(this.value);
  };

  private getFormatedDateWithCustomPattern = (customPattern: string): string => {
    if (this.value === null) return '';

    const param: string = customPattern.substring(2);

    const pattern: string = DateTimeExtension.getPatternFromCustomPattern(param);

    return DateTimeExtension.formatCustom(this.value, pattern);
  };

  private getTimeOfDay = (): number => {
    if (this.value === null) return 0;

    const dt = new Date(this.value);
    dt.setHours(0, 0, 0, 0);

    return differenceInMilliseconds(this.value, dt);
  };
}
