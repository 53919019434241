import { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { AllFieldValueTypes, DigitsDesc, FieldDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { useStore } from '30.quickConnect.Stores';
import { isNumeric } from '80.quickConnect.Core/helpers/common';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';

const useData = (
  numericDesc: DigitsDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  flattenFields?: FieldDesc[],
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, reference } = numericDesc;
  const {
    DeclarationStore: { requiredField, verifRequiredField, isDeepLink, fieldFocused, setFieldFocused },
    DialogStore: { setFullPathIdForFocus },
  } = useStore();
  // Custom Hooks
  const { isQCScriptForm } = useQCSFormEvent();

  const [localValue, setLocalValue] = useState<number | string>(
    typeof value === 'number' ? value.toString() : typeof value === 'string' ? (value as string) : '',
  );

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    setFullPathIdForFocus(fullPathId);
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration, setFullPathIdForFocus]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (/^-?(\d*)?$/.test(event.target.value)) setLocalValue((prev) => (prev = event.target.value));

      if (event.target.value === '') setLocalValue('');

      // Si le champ possède le focus, on le retire
      if (fieldFocused === fullPathId) setFieldFocused(undefined);

      debouncedUpdateGlobalState();
    },
    [setLocalValue, debouncedUpdateGlobalState, fieldFocused, fullPathId, setFieldFocused],
  );

  const clearState = useCallback(() => {
    setLocalValue('');
    debouncedUpdateGlobalState();
  }, [setLocalValue, debouncedUpdateGlobalState]);

  const onBlur = useCallback(() => {
    setFieldFocused(undefined);
  }, [setFieldFocused]);

  // UseEffect pour le système des références...
  useEffect(() => {
    if (value === null && isDeepLink) setLocalValue('');
    if (reference) {
      const newLocalValue = isNumeric(value) ? (roundTo(+value, 0) as string) : undefined;
      setLocalValue((prevLocalValue) => (prevLocalValue !== newLocalValue ? newLocalValue ?? '' : prevLocalValue));
    }

    if (isQCScriptForm && isNumeric(value)) {
      setLocalValue(() => {
        if (value === null) return '';
        if (typeof value === 'undefined') return '';
        if (typeof value === 'string') {
          if (value === '') return '';
          return !Number.isInteger(+value) ? (+value).toFixed(0) : Number(+value);
        } else {
          return !Number.isInteger(value) ? (value as number).toFixed(0) : (value as number).toString();
        }
      });
    } else if (isQCScriptForm && value === undefined) {
      setLocalValue('');
    }
  }, [reference, value, isQCScriptForm, isDeepLink]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    clearState,
    handleChange,
    requiredField,
    verifRequiredField,
    fieldFocused,
    onBlur,
  };
};

export default useData;
