import React, { useReducer, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List } from '@mui/material';
import DialogChoice from '10.quickConnect.app/components/shared/Dialogs/DialogChoice';
import {
  DialogChoiceOption,
  DialogChoiceState,
  UseDataProps,
} from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';
import { DialogChoiceEnum } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/enums';

const useDialogQCChoice = (
  reducer: (state: DialogChoiceState, action: any) => DialogChoiceState,
  initialState: DialogChoiceState,
  open: boolean,
  onClose: () => void,
): UseDataProps => {
  const { t: tForm } = useTranslation('forms');
  const [{ name, dialogTitle, dialogInfo, options }, dispatch] = useReducer(reducer, initialState);

  const handleClick = useCallback((option: DialogChoiceOption) => {
    dispatch({ type: DialogChoiceEnum.Close });
    dispatch(option.action);
  }, []);

  const DialogQCChoice = useMemo(() => {
    return (
      <DialogChoice open={open} onClose={onClose} name={name}>
        <DialogChoice.Title name={name}>{dialogTitle}</DialogChoice.Title>
        <DialogChoice.Content name={name}>
          <DialogChoice.ContentText name={name}>{dialogInfo}</DialogChoice.ContentText>
          {options.length > 0 && (
            <List>
              {options.map((option, index) => (
                <DialogChoice.Option
                  key={index.toString()}
                  handleClick={() => handleClick(option)}
                  name={option.name}
                  optionTitle={option.optionTitle}
                  sx={option.sx}
                  avatar={option.avatar}
                />
              ))}
            </List>
          )}
        </DialogChoice.Content>
        <DialogChoice.Actions name={name}>
          <Button data-cy={`${name}-close`} onClick={onClose} variant="contained">
            {tForm('qcapp_dialog_item_data_close')}{' '}
          </Button>
        </DialogChoice.Actions>
      </DialogChoice>
    );
  }, [open, name, dialogTitle, dialogInfo, options, onClose, tForm, handleClick]);

  return {
    DialogQCChoice,
  };
};

export default useDialogQCChoice;
