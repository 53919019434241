import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/enums';
import { AttributeValue, EntityData, KeyValuePair } from '90.quickConnect.Models/models';

const mapAttributeValueFromBindings = (
  entityData: EntityData,
  binding: { [key: string]: string },
  bindingTypes: KeyValuePair<string, EntitySchemaAttributeTypes>[],
): AttributeValue[] => {
  const attributes: AttributeValue[] = [];
  if (binding) {
    const entries = Object.entries(binding);
    entries.forEach((entry) => {
      const [attributeId, attributeLabel] = entry;
      const value = entityData[attributeLabel];
      const type = bindingTypes[attributeLabel as any];
      attributes.push({
        attributeId: attributeId,
        attributeLabel: attributeLabel,
        value: value,
        type: type,
      } as unknown as AttributeValue);
    });
  }
  return attributes;
};

export default mapAttributeValueFromBindings;
