import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import { SharedList } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class SharedListsDb extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/sharedListsDb.ts';

  sharedLists!: Table<SharedList>;

  logger: CustomLogger;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('sharedListsDb');
    this.version(1).stores({
      sharedLists: 'id',
    });

    this.logger = CustomLogger.getInstance();
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.sharedLists.count();
    } catch (error: unknown) {
      errorHandler(SharedListsDb.TAG, error, 'countRecords');

      return 0;
    }
  };

  async getSharedListById(id: string): Promise<SharedList | undefined> {
    try {
      return await this.sharedLists.get(id);
    } catch (error: unknown) {
      errorHandler(SharedListsDb.TAG, error, 'getSharedListById');
    }
  }

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.sharedLists.clear();
      logger.log(SharedListsDb.TAG, `all tables from ${this.name} have been cleared`);
    } catch (error) {
      errorHandler(SharedListsDb.TAG, error, 'clearAllTables');
    }
  }
}

const sharedListsDb = new SharedListsDb();

export default sharedListsDb;
