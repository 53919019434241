import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';

export const getReferencedSliderValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const sliderValue = isNumeric(valueReferenceDependency) ? +valueReferenceDependency : undefined;

  // Accepter le 0 comme valeur valide pour les champs de type Slider
  if (sliderValue === undefined) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
    case FieldType.Separator:
      const numberFormatOptions: Intl.NumberFormatOptions = {
        maximumFractionDigits: Number.isInteger(sliderValue) ? 1 : 4,
      };
      return numericLocaleFormatLitteral(sliderValue, numberFormatOptions);

    case FieldType.Slider:
    case FieldType.Numeric:
      return sliderValue;

    default:
      return undefined;
  }
};
