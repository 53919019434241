import { AllFieldValueTypes } from '90.quickConnect.Models/models';

export const isAValueEmpty = (value: AllFieldValueTypes): boolean => {
  if (value === undefined || value === null) return true;
  if (typeof value === 'string') return value === '';

  if (Array.isArray(value)) return value.length === 0;

  return false;
};
