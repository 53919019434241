// Compare 2 valeurs de type object et retourne un boolean indiquant la stricte égalité

export const isEqualObjects = (obj1: unknown, obj2: unknown): boolean => {
  // Cas du null
  if (obj1 === null) return obj1 === obj2;

  // Cas des dates
  if (obj1 instanceof Date && obj2 instanceof Date) return obj1.getTime() === obj2.getTime();

  // on stringnifie sinon
  try {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);

    return str1 === str2;
  } catch (error) {
    return false;
  }
};
