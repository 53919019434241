import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { UseDataProps } from './types';

const useData = (): UseDataProps => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    if (window.history.length > 1) navigate(-1);
    else window.close();
  }, [navigate]);

  return {
    goBack,
  };
};

export default useData;
