import {
  AllFieldValueTypes,
  FieldData,
  QCActionData,
  Choice,
  FieldDesc,
  QCNotification,
  NotificationTarget,
  ComboDesc,
} from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import {
  findValueProperties,
  splitAndTrim,
  isNumeric,
  getChoiceList,
  getChoicesFromChoice,
  isQCNotification,
} from '80.quickConnect.Core/helpers/common';
import { isAnArrayOfChoice, isChoice } from '90.quickConnect.Models/guards';
import { mapToNotificationDataFromHashMap } from '90.quickConnect.Models/mappings';

const getValueFromChoiceOrChoiceList = (
  fieldType: FieldType,
  value: AllFieldValueTypes,
  propertyName: string[] | undefined,
): string | undefined => {
  switch (fieldType) {
    case FieldType.RadioList:
    case FieldType.Combo:
    case FieldType.Alert: {
      const nextValue = value as Choice;
      return findValueProperties(nextValue, propertyName, false);
    }

    case FieldType.CheckBoxList: {
      const nextValues = value as Choice[];

      return nextValues.map((nextValue: Choice) => findValueProperties(nextValue, propertyName, false)).join(', ');
    }

    default:
      return undefined;
  }
};

export const getReferencedActionValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldWithReference: FieldDesc,
  referenceId: string,
  columnId?: string,
): AllFieldValueTypes => {
  if (!valueReferenceDependency) return undefined;

  const { fieldType: fieldTypeFieldWithReference, value: valueFromReference } = fieldWithReference;
  const qcActionsData = valueReferenceDependency as QCActionData;

  // Il faut déteerminer quel est le bon champ du QCActionData (car c'est un tableau de FieldData).
  const qcActionData = qcActionsData.find(
    (qcActData: FieldData) => qcActData.id.localeCompare(referenceId, undefined, { sensitivity: 'base' }) === 0,
  );

  if (!qcActionData) return undefined;

  // Récupérons la value de notre fieldData du QCActionData. il est du type AllFieldValueTypes
  const { value, fieldType } = qcActionData;
  const choiceOrChoiceListValue =
    fieldType === FieldType.CheckBoxList ? (value as Choice[]) : (value as Choice[]).at(0);

  if (!choiceOrChoiceListValue) return undefined;
  const propertyName: string[] | undefined = columnId?.split(',');

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.Separator:
    case FieldType.ReadOnlyValue:
      return getValueFromChoiceOrChoiceList(fieldType, choiceOrChoiceListValue, propertyName);

    case FieldType.Notification: {
      const nextValues: string[] = getValueFromChoiceOrChoiceList(fieldType, value, propertyName)?.split(', ') ?? [];

      const notificationValue = isQCNotification(valueFromReference)
        ? valueFromReference
        : mapToNotificationDataFromHashMap({});

      const resultNotification =
        splitAndTrim(nextValues)
          /* eslint-disable-next-line no-useless-escape */
          .filter((item: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item))
          .map((item: string) => ({ alias: null, target: item } as NotificationTarget)) ?? [];

      return {
        ...notificationValue,
        selectedTargets: resultNotification,
      };
    }

    case FieldType.Counter:
    case FieldType.Slider:
    case FieldType.Numeric:
    case FieldType.Digits: {
      const nextValue = getValueFromChoiceOrChoiceList(fieldType, value, propertyName);

      if (!isNumeric(nextValue)) return undefined;

      return +nextValue;
    }

    case FieldType.CheckBox:
      return 1;

    case FieldType.Combo:
    case FieldType.RadioList:
    case FieldType.Alert: {
      const choiceToSearch: Choice = isAnArrayOfChoice(value) ? value[0] : (value as Choice);

      const listField = fieldWithReference as ComboDesc;
      const choices: Choice[] = listField.listChoice ?? [];
      return getChoicesFromChoice(choiceToSearch, choices, fieldTypeFieldWithReference);
    }

    case FieldType.CheckBoxList:
    case FieldType.HierarchicalList: {
      const hierarchicalOrCheckBoxListField = fieldWithReference as ComboDesc;
      const hierarchicalOrCheckBoxListChoices: Choice[] = hierarchicalOrCheckBoxListField.listChoice ?? [];

      const choicesToSearch = isAnArrayOfChoice(value) ? value : isChoice(value) ? [value] : [];

      return getChoicesFromChoice(choicesToSearch, hierarchicalOrCheckBoxListChoices, fieldTypeFieldWithReference);
    }

    default:
      return undefined;
  }
};
