import React from 'react';
import { observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { InboxesListsViewName } from './const';
import { useData } from './hooks';
import CustomListInboxes from '10.quickConnect.app/components/domain/Home/Shared/CustomListInboxes';
import { ListColumnOrRowForCustomListContainer } from '10.quickConnect.app/components/domain/Home/styles';
import InboxLine from '10.quickConnect.app/components/domain/Home/Inboxes/InboxLine';

const InboxesLists = () => {
  const theme = useTheme();
  const { preFilled, tasks, others, sortFn, prefilledItemsTitle, tasksItemsTitle, othersItemsTitle } = useData();

  return (
    <Box data-cy={InboxesListsViewName} sx={ListColumnOrRowForCustomListContainer(theme)}>
      <CustomListInboxes title={prefilledItemsTitle} items={preFilled} RenderLine={InboxLine} sortFn={sortFn} />
      <CustomListInboxes title={tasksItemsTitle} items={tasks} RenderLine={InboxLine} sortFn={sortFn} />
      <CustomListInboxes title={othersItemsTitle} items={others} RenderLine={InboxLine} sortFn={sortFn} />
    </Box>
  );
};
export default observer(InboxesLists);
