import { DataSourceField } from '90.quickConnect.Models/models';

const mapDataSource = ({
  idDataSource,
  idsAttributesSearch,
  idsAttributesSelect,
  forceSearchAllUO,
  forcedSelection,
  addSearchAttributResult,
}: DataSourceField): DataSourceField =>
  ({
    idDataSource,
    idsAttributesSearch: idsAttributesSearch ?? [],
    idsAttributesSelect: idsAttributesSelect ?? [],
    forceSearchAllUO: forceSearchAllUO ?? false,
    forcedSelection: forcedSelection ?? false,
    addSearchAttributResult: addSearchAttributResult ?? false,
  } as DataSourceField);

export default mapDataSource;
