/* eslint-disable max-lines-per-function */
import { v4 as uuidv4 } from 'uuid';
import mapVisibility from '../others/mapVisibility';
import mapVisibilityOnDevice from '../others/mapVisibilityOnDevice';
import mapTooltip from '../others/mapTooltip';
import { mapCounterDesc } from '..';
import mapCheckBoxDesc from './mapCheckBoxDesc';
import mapComboDesc from './mapComboDesc';
import mapDateTimeDesc from './mapDateTimeDesc';
import mapDigitsDesc from './mapDigitsDesc';
import mapEditDesc from './mapEditDesc';
import mapGroupDesc from './mapGroupDesc';
import mapStepDesc from './mapStepDesc';
import mapNumericDesc from './mapNumericDesc';
import mapAddressDesc from './mapAddressDesc';
import mapHierarchicalDesc from './mapHierarchicalDesc';
import mapRepeatableGroupDesc from './mapRepeatableGroupDesc';
import mapComputeDesc from './mapComputeDesc';
import mapTimeDesc from './mapTimeDesc';
import mapAttachmentDesc from './mapAttachmentDesc';
import mapPhotoDesc from './mapPhotoDesc';
import mapIncludeDesc from './mapIncludeDesc';
import mapNotificationDesc from './mapNotificationDesc';
import mapDialogDesc from './mapDialogDesc';
import mapCodeReaderDesc from './mapCodeReaderDesc';
import mapFixedAttachmentDesc from './mapFixedAttachmentDesc';
import mapFixedImageDesc from './mapFixedImageDesc';
import mapGeolocationDesc from './mapGeolocationDesc';
import mapQcActionDesc from './mapQcActionDesc';
import mapReadOnlyDesc from './mapReadOnlyDesc';
import mapSliderDesc from './mapSliderDesc';
import mapTodoListDesc from './mapTodoListDesc';
import mapDrawDesc from './mapDrawDesc';
import { mapCheckRGPD } from './mapCheckRGPD';

import {
  AddressDesc,
  AttachmentDesc,
  BaseAttachmentDesc,
  CheckBoxDesc,
  ComboDesc,
  ComputeDesc,
  CounterDesc,
  DateTimeDesc,
  DigitsDesc,
  EditDesc,
  FieldDesc,
  GroupDesc,
  HierarchicalDesc,
  NumericDesc,
  PhotoDesc,
  RepeatableGroupDesc,
  StepDesc,
  TimeDesc,
  DialogDesc,
  NotificationDesc,
  DeclarationContext,
  DrawDesc,
  CodeReaderDesc,
  FixedImageDesc,
  QcActionDesc,
  TodoListDesc,
  ImagesGroupDesc,
} from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { IncludeDesc } from '90.quickConnect.Models/models/fields/fieldDesc/containerField/includeDesc';
import { FixedAttachmentDesc } from '90.quickConnect.Models/models/fields/fieldDesc/attachmentField/fixedAttachmentDesc';
import { GeolocationDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/geolocationDesc';
import { mapToImagesGroupDesc } from '90.quickConnect.Models/mappings';

const mapFieldDesc = (
  field: FieldDesc,
  parentId: string | undefined,
  templateBodies: Record<string, string> | undefined | null,
  context?: DeclarationContext,
): FieldDesc => {
  const visibility = mapVisibility(field.visible);
  const visibleOnDevice = mapVisibilityOnDevice(field.isVisibleOnDevice ?? field.visible);

  const {
    stateId,
    fieldType,
    id,
    label,
    reset,
    toolTip,
    controller,
    binding,
    importance,
    reference,
    fieldIsReadOnly,
    checkRGPD,
    items,
    mandatory,
    canDuplicate,
    stayOnSameLine,
    isSameLine,
    isStateDiv,
    injectedValue,
    fieldDataInjected,
    desactivatedAutomation,
    isVisible,
    isVisibleOnDevice,
  } = field;

  const baseField = {
    stateId: stateId ?? uuidv4(),
    fieldType: fieldType,
    fullPathId: parentId ? `${parentId}.${id}` : id,
    id: id,
    // label: label ? getLabelWithoutPTag(label) : '',
    label: label ?? '',
    reset: reset,
    // Détermine la visibilité finale ('visible') en fonction de plusieurs critères :
    // 1. Si 'visible' est un objet, il est utilisé tel quel (il y'a une vr).
    // 2. Sinon :
    //    - Si la visibilité globale ('isVisible') est explicitement définie à 'false', alors la visibilité finale est `false`.
    //    - Si la visibilité globale n'est pas 'false', la visibilité finale dépend de la combinaison :
    //        - 'visible' doit être truthy.
    //        - 'visibleOnDevice' ne doit pas être défini à 'false'.
    visible: typeof visibility === 'object' ? visibility : visibility && visibleOnDevice !== false,
    // visible est déjà mappé au dessus
    isVisible: isVisible ?? visibility !== false,
    isVisibleOnDevice: isVisible !== false ? isVisibleOnDevice ?? visibleOnDevice : undefined,
    toolTip: toolTip ? mapTooltip(toolTip) : undefined,
    controller: controller,
    binding: binding,
    importance: importance,
    reference: reference,
    fieldIsReadOnly: fieldIsReadOnly ?? false,
    checkRGPD: mapCheckRGPD(checkRGPD),
    items: items ? items.map((f) => mapFieldDesc(f, parentId ? `${parentId}.${id}` : id, templateBodies, context)) : [],
    mandatory: mandatory,
    canDuplicate: canDuplicate ?? true,

    // ¯\_(ツ)_/¯
    stayOnSameLine: stayOnSameLine,
    isSameLine: isSameLine,
    isStateDiv: isStateDiv,
    injectedValue: injectedValue,
    fieldDataInjected: fieldDataInjected,

    // desactivatedAutomation: Permet de désactiver l'appel aux mécaniques Référence et QCScript
    desactivatedAutomation: desactivatedAutomation ?? false,
  } as FieldDesc;

  switch (baseField.fieldType) {
    case FieldType.Text:
      return {
        ...baseField,
        ...mapEditDesc(field as EditDesc),
      } as EditDesc;
    case FieldType.Digits:
      return {
        ...baseField,
        ...mapDigitsDesc(field as DigitsDesc),
      } as DigitsDesc;
    case FieldType.Numeric:
      return {
        ...baseField,
        ...mapNumericDesc(field as NumericDesc),
      } as NumericDesc;
    case FieldType.Separator:
      return {
        ...baseField,
      } as NumericDesc;
    case FieldType.RepeatableGroup:
      return {
        ...baseField,
        ...mapRepeatableGroupDesc(field as RepeatableGroupDesc, templateBodies),
      } as GroupDesc;
    case FieldType.Dialog:
      return {
        ...baseField,
        ...mapDialogDesc(field as DialogDesc, parentId ? `${parentId}.${id}` : id, templateBodies),
      };
    case FieldType.Group:
      return {
        ...baseField,
        ...mapGroupDesc(field as GroupDesc),
      } as GroupDesc;
    case FieldType.ImagesGroup:
      return {
        ...baseField,
        ...mapToImagesGroupDesc(field as ImagesGroupDesc),
      };
    case FieldType.Time:
      return {
        ...baseField,
        ...mapTimeDesc(field as TimeDesc),
      } as TimeDesc;
    case FieldType.DateTime:
      return {
        ...baseField,
        ...mapDateTimeDesc(field as DateTimeDesc),
      } as DateTimeDesc;
    case FieldType.HierarchicalList:
      return {
        ...baseField,
        ...mapHierarchicalDesc(field as HierarchicalDesc),
        isCheckBoxList: true,
      } as HierarchicalDesc;
    case FieldType.Include:
      if (!templateBodies) throw new Error(`templateBodies manquant pour le champs ${id}`);
      const newTemplateBodies = typeof templateBodies === 'string' ? JSON.parse(templateBodies) : templateBodies;
      return {
        ...baseField,
        ...mapIncludeDesc(field as IncludeDesc, newTemplateBodies, parentId ? `${parentId}.${id}` : id),
        isCheckBoxList: true,
      } as FieldDesc;
    case FieldType.Alert:
    case FieldType.Combo:
    case FieldType.RadioList:
    case FieldType.CheckBoxList:
      return {
        ...baseField,
        ...mapComboDesc(field as ComboDesc),
        isCheckBoxList: true,
      } as ComboDesc;
    case FieldType.CheckBox:
      return {
        ...baseField,
        ...mapCheckBoxDesc(field as CheckBoxDesc),
      } as CheckBoxDesc;
    case FieldType.Address:
      return {
        ...baseField,
        ...mapAddressDesc(field as AddressDesc),
      } as AddressDesc;
    case FieldType.Step:
      return {
        ...baseField,
        ...mapStepDesc(field as StepDesc),
      } as StepDesc;

    case FieldType.Compute:
      return {
        ...baseField,
        ...mapComputeDesc(field as ComputeDesc),
      } as ComputeDesc;
    case FieldType.Counter:
      return {
        ...baseField,
        ...mapCounterDesc(field as CounterDesc),
      } as CounterDesc;
    case FieldType.Attachment:
      return {
        ...baseField,
        ...mapAttachmentDesc(field as AttachmentDesc),
      } as BaseAttachmentDesc;
    case FieldType.Photo:
    case FieldType.Signature:
      return {
        ...baseField,
        ...mapPhotoDesc(field as PhotoDesc),
      } as BaseAttachmentDesc;
    case FieldType.Draw:
      return {
        ...baseField,
        ...mapDrawDesc(field as DrawDesc),
      };
    case FieldType.Notification:
      const notificationField = {
        ...baseField,
        ...mapNotificationDesc(field as NotificationDesc),
      } as NotificationDesc;
      return notificationField;
    case FieldType.Slider:
      return {
        ...baseField,
        ...mapSliderDesc(field as EditDesc),
      };
    case FieldType.Geolocation:
      return {
        ...baseField,
        ...mapGeolocationDesc(field as GeolocationDesc),
      };
    case FieldType.CodeReader:
    case FieldType.RfidReader:
      return {
        ...baseField,
        ...mapCodeReaderDesc(field as CodeReaderDesc),
      };
    case FieldType.FixedImage:
      return {
        ...baseField,
        ...mapFixedImageDesc(field as FixedImageDesc),
      };
    case FieldType.FixedAttachment:
      return {
        ...baseField,
        ...mapFixedAttachmentDesc(field as FixedAttachmentDesc),
      };
    case FieldType.ReadOnlyValue:
      return {
        ...baseField,
        ...mapReadOnlyDesc(field as EditDesc),
      };
    case FieldType.TodoList:
      return {
        ...baseField,
        ...mapTodoListDesc(field as TodoListDesc),
      };
    case FieldType.Action:
      return {
        ...baseField,
        ...mapQcActionDesc(field as QcActionDesc, context, parentId ? `${parentId}` : id),
      };
    case FieldType.Label:
    case FieldType.Audio:
    case FieldType.Array:
    case FieldType.Plugin:
    case FieldType.DataSource:
    case FieldType.Unknow:
    default:
      return {
        ...baseField,
      };
  }
};

export default mapFieldDesc;
