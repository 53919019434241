import { FieldType } from '90.quickConnect.Models/enums';
import { ComboDesc, FieldDesc, HierarchicalDesc } from '90.quickConnect.Models/models';
import { CheckBoxListDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/checkBoxListDesc';

export const isComboOrRadioListDesc = (field: FieldDesc): field is ComboDesc =>
  field.fieldType === FieldType.Combo || field.fieldType === FieldType.RadioList;

export const isCheckboxListDesc = (field: FieldDesc): field is CheckBoxListDesc =>
  field.fieldType === FieldType.CheckBoxList;

export const isHierarchicalDesc = (field: FieldDesc): field is HierarchicalDesc =>
  field.fieldType === FieldType.HierarchicalList;

export const isListField = (f: FieldDesc): f is ComboDesc | HierarchicalDesc =>
  f.fieldType === FieldType.CheckBoxList ||
  f.fieldType === FieldType.Combo ||
  f.fieldType === FieldType.HierarchicalList ||
  f.fieldType === FieldType.RadioList;
