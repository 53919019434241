import { addMonths, addWeeks, addYears, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import addDays from 'date-fns/addDays';
import getDateTimeDefaultValue from './getDateTimeDefaultValue';
import { DateTimeDesc } from '90.quickConnect.Models/models';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

const getOperator = (data: string) => {
  if (data.includes('+') || data.includes('-')) {
    const x = data.substring(1);
    return x[x.length - 1];
  }
  return data[data.length - 1];
};
const getAmount = (data: string) => {
  if (data.includes('+') || data.includes('-')) {
    const x = data.substring(1);
    return x.substring(0, x.length - 1);
  }
  return data.substring(0, data.length - 1);
};
const addDate = (date: any, currentDate: any | undefined, amount: string) => {
  switch (date) {
    case 'd':
      return addDays(currentDate, +amount);
    case 'y':
      return addYears(currentDate, +amount);
    case 'm':
      return addMonths(currentDate, +amount);
    case 'w':
      return addWeeks(currentDate, +amount);
  }
};
const subDate = (date: any, currentDate: any | undefined, amount: string) => {
  switch (date) {
    case 'd':
      return subDays(currentDate, +amount);
    case 'y':
      return subYears(currentDate, +amount);
    case 'm':
      return subMonths(currentDate, +amount);
    case 'w':
      return subWeeks(currentDate, +amount);
  }
};
const getMinMaxDate = (date: string, currentDate?: Date) => {
  const dt = DateTimeExtension.parseMultiFormat(date);

  if (dt && DateTimeExtension.isAValidDateTime(dt)) return dt.toString();
  else if (!date.includes('controlId') && !date.includes('.')) {
    switch (date[0]) {
      case '-':
        return subDate(getOperator(date), currentDate, getAmount(date))?.toString();
      case '+':
        return addDate(getOperator(date), currentDate, getAmount(date))?.toString();
      default:
        return addDate(getOperator(date), currentDate, getAmount(date))?.toString();
    }
  } else {
    return date;
  }
};

const mapDateTimeDesc = (field: DateTimeDesc): DateTimeDesc => {
  const defaultValue = getDateTimeDefaultValue(field);
  const { value } = field;
  return {
    isVertical: field.isVertical,
    value: value ?? defaultValue ?? undefined,
    defaultValue: defaultValue ?? undefined,
    type: field.type ?? undefined,
    initDate: field.initDate ?? undefined,
    min: field.min
      ? field.min.includes('controlId')
        ? field.min
        : getMinMaxDate(field.min.toString(), new Date())
      : undefined,
    max: field.max
      ? field.max?.includes('controlId')
        ? field.max
        : getMinMaxDate(field.max.toString(), new Date())
      : undefined,
  } as DateTimeDesc;
};

export default mapDateTimeDesc;
