import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useTheme, Box, Typography, TextField } from '@mui/material';
import { ReadOnlyValueQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FieldMainBox } from '50.quickConnect.Fields/styles';

const ReadOnlyValueQcField = ({ field, labelSpan, setSelectedIndex, isReadOnly }: ValueFieldsTypesProps) => {
  const { t } = useTranslation('declaration');
  const { id, label, toolTip, value, fullPathId } = field;
  const nameWithId = `${ReadOnlyValueQcFieldName}-${id}`;
  const { breakpoints } = useTheme();

  return (
    <Box
      sx={FieldMainBox(breakpoints)}
      data-cy={nameWithId}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      {label && <Typography component="span">{labelSpan}</Typography>}
      <TextField disabled value={value ?? ''} />

      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );
};
export default observer(ReadOnlyValueQcField);
