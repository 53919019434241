import { useCallback, useLayoutEffect, useState } from 'react';
import { WindowSize } from '../helpers/types';
import useEventListener from './useEventListener';

const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, WindowSize] => {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener('resize', handleSize);

  useLayoutEffect(() => {
    handleSize();
  }, [handleSize, ref]);

  return [setRef, size];
};

export default useElementSize;
