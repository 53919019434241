import { addIfNotPresent, flatten } from '80.quickConnect.Core/helpers';
import { CtrlRefOperator, Dependency, FieldDesc } from '90.quickConnect.Models/models';

const getPatternDependencies = (
  ctrlRefOperator: CtrlRefOperator,
  array: Dependency[],
  flattenFields: FieldDesc[],
  scopeFullPathId: string,
) => {
  // Scope
  const parentId = scopeFullPathId.substring(0, scopeFullPathId.lastIndexOf('.'));
  const currentScopeId = scopeFullPathId.substring(scopeFullPathId.lastIndexOf('.') + 1);

  let scope = flattenFields;

  if (parentId) {
    const newScope = flattenFields.filter((d) => d.fullPathId.toUpperCase().startsWith(parentId.toUpperCase()));
    if (newScope) scope = newScope;
  }

  // Pattern
  const stringToSreach = ctrlRefOperator.controlId.replace('*', '').replace('/', '');
  const fullPathQuery = stringToSreach.substring(0, stringToSreach.lastIndexOf('.'));
  const patternToSearchInScope = stringToSreach.substring(stringToSreach.lastIndexOf('.') + 1);

  const group = scope.find((f) => f.fullPathId.toUpperCase() === fullPathQuery.toUpperCase());
  if (group) {
    const groupChildren = flatten(group.items ?? [], (i) => i.items);
    const patternRelated = groupChildren.filter((d) =>
      d.id.toUpperCase().startsWith(patternToSearchInScope.toUpperCase()),
    );
    patternRelated.forEach((field) => {
      addIfNotPresent<Dependency>(array, field);
    });
  } else {
    const patternRelated = scope.filter((d) => d.id.toUpperCase().startsWith(patternToSearchInScope.toUpperCase()));
    if (patternRelated.length > 0) {
      patternRelated.forEach((field) => {
        addIfNotPresent<Dependency>(array, field);
      });
    } else {
      const patternAbsoluted = flattenFields.filter(
        ({ fullPathId, id }: FieldDesc) =>
          fullPathId.toUpperCase().startsWith(stringToSreach.toUpperCase()) ||
          (ctrlRefOperator.controlId.startsWith('/') &&
            id.toUpperCase().startsWith(patternToSearchInScope.toUpperCase())),
      );

      if (patternAbsoluted.length > 0) {
        patternAbsoluted.forEach((field) => {
          addIfNotPresent<Dependency>(array, field);
        });
      }
    }
  }
};
export default getPatternDependencies;
