import declarationsDb from './indexedDb/dbs/declarationsDb';
import equipmentsDb from './indexedDb/dbs/equipmentsDb';
import formsDb from './indexedDb/dbs/formsDb';
import sharedListsDb from './indexedDb/dbs/sharedListsDb';
import attachmentDb from './indexedDb/dbs/attachmentDb';
import consentFormDd from './indexedDb/dbs/consentFormRGPD';
import { fieldSearchHistoryDb as fieldCacheDb } from './indexedDb/dbs/historyDb';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

export const resetAllStorage = async (logger: CustomLogger) => {
  // window.sessionStorage.clear();
  // window.localStorage.clear();
  await equipmentsDb.clearAllTables(logger);
  await formsDb.clearAllTables(logger);
  await sharedListsDb.clearAllTables(logger);
  await attachmentDb.clearAllTables(logger);
};

export const countRecords = async (logger: CustomLogger): Promise<void> => {
  const attachmentsRecords = await attachmentDb.countRecords();
  const consentFormRGPDRecords = await consentFormDd.countRecords();
  const formEditedDataRecords = await declarationsDb.countDclToUploadRecords();
  const draftFormEditedDataRecords = await declarationsDb.countDraftDclRecords();
  const backUpFormEditedDataRecords = await declarationsDb.countBackUpDclRecords();
  const equipmentsRecords = await equipmentsDb.countRecords();
  const formsRecords = await formsDb.countRecords();
  const sharedListsRecords = await sharedListsDb.countRecords();
  const fieldsCacheRecords = await fieldCacheDb.countRecords();

  const messageData: Record<string, unknown> = {
    QCT_QCConsentFormRGPD: consentFormRGPDRecords,
    QCT_QCAttachment: attachmentsRecords,
    QCT_QCFormEditedData: formEditedDataRecords,
    QCT_QCDraftFormEditedData: draftFormEditedDataRecords,
    QCT_QCBackUpFormEditedData: backUpFormEditedDataRecords,
    QCT_QCEquipment: equipmentsRecords,
    QCT_QCForm: formsRecords,
    QCT_QCSharedList: sharedListsRecords,
    QCT_QCFieldCache: fieldsCacheRecords,
  };

  logger.info("[Client Web] - SYNC - Récupération du nombre d'enregistrements des tables locales", {
    MessageData: messageData,
    EventID: 5001,
  });
};
