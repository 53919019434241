import { useCallback, useMemo, useState } from 'react';

import { AllFieldValueTypes, CheckBoxDesc, FieldDesc, ImagesGroupDesc } from '90.quickConnect.Models/models';
import {
  ImagesGroupState,
  TypePopup,
  UseDataProps,
} from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/types';
import { createImageGroupContextValue } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/contexts/imagesGroupContext';
import { FieldType } from '90.quickConnect.Models/enums';
import { fieldIsVisible } from '70.quickConnect.Common/fields';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';

export default function useData(
  imagesGroupField: ImagesGroupDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  flattenFields: FieldDesc[],
  isReadOnly?: boolean,
): UseDataProps {
  const { imagesMap, imagesBankId: imagesBankId, fullPathId, id } = imagesGroupField;
  const { isQCScriptForm, onImageClick } = useQCSFormEvent();
  const [imagesGroupState, setImagesGroupState] = useState<ImagesGroupState>({
    showList: false,
    fieldToShow: undefined,
    showModal: false,
    imagesBankId: imagesBankId ?? '',
  });

  const updateGlobalDeclaration = useCallback(
    (fullId: string, nextValue: AllFieldValueTypes): void => {
      updateDeclaration(fullId, nextValue);
    },
    [updateDeclaration],
  );

  const handleClickImageItem = useCallback(
    (field: FieldDesc) => {
      const { fieldType } = field;
      // Appel au formEvent_OnImageClick
      if (isQCScriptForm && !isReadOnly) {
        const bQcScriptOnImageClicked = onImageClick(fullPathId, id, field.id);

        if (!bQcScriptOnImageClicked) return;
      }
      switch (fieldType) {
        // Cas de la case à cocher, on valide directement l'état...
        case FieldType.CheckBox:
          if (isReadOnly) return;
          const { fullPathId: childFullPathId, value: checkboxValue } = field as CheckBoxDesc;
          const nextValue: boolean = typeof checkboxValue === 'boolean' ? !checkboxValue : true;
          setImagesGroupState({
            ...imagesGroupState,
            showList: false,
            showModal: false,
            fieldToShow: undefined,
          });
          updateGlobalDeclaration(childFullPathId, nextValue);
          break;

        // Les Listes
        case FieldType.Combo:
        case FieldType.Alert:
        case FieldType.RadioList:
        case FieldType.CheckBoxList:
        case FieldType.HierarchicalList:
          setImagesGroupState({
            ...imagesGroupState,
            showList: true,
            fieldToShow: field,
            showModal: false,
          });
          break;

        // Les containers
        case FieldType.Group:
        case FieldType.RepeatableGroup:
        case FieldType.Include:
        case FieldType.ImagesGroup:
          // Cas particulier du groupe répétable qui peut avoir aucun enregistrement.
          if (fieldType === FieldType.RepeatableGroup && field.items?.length === 0) {
            setImagesGroupState({
              ...imagesGroupState,
              showList: false,
              fieldToShow: field,
              showModal: true,
            });
            return;
          }
          // On vérifie qu'au moins un des enfants est visible.
          if (!field.items.some(fieldIsVisible)) return;

          setImagesGroupState({
            ...imagesGroupState,
            showList: false,
            fieldToShow: field,
            showModal: true,
          });
          break;

        default:
          // Ouverture de la modale...
          setImagesGroupState({
            ...imagesGroupState,
            showList: false,
            fieldToShow: field,
            showModal: true,
          });
      }
    },
    [isReadOnly, updateGlobalDeclaration, imagesGroupState, fullPathId, id, onImageClick, isQCScriptForm],
  );

  const closePopup = useCallback(
    (typeOfPopup: TypePopup): void => {
      setImagesGroupState((previousState) => ({
        ...imagesGroupState,
        showList: typeOfPopup === 'list' ? !previousState.showList : previousState.showList,
        showModal: typeOfPopup === 'modal' ? !previousState.showModal : previousState.showModal,
        fieldToShow: undefined,
      }));
    },
    [setImagesGroupState, imagesGroupState],
  );

  const imagesGroupContextValue = useMemo(
    () =>
      createImageGroupContextValue({
        imagesStateInfos: imagesGroupState,
        flattenFields,
        handleClickImageItem,
        updateGlobalDeclaration,
        closePopup,
      }),
    [imagesGroupState, flattenFields, handleClickImageItem, updateGlobalDeclaration, closePopup],
  );

  const handleCancelButtonClick = useCallback(() => {
    setImagesGroupState({ ...imagesGroupState, fieldToShow: undefined, showList: false, showModal: false });
  }, [setImagesGroupState, imagesGroupState]);

  const handleValidateButtonClick = useCallback(() => {
    setImagesGroupState({ ...imagesGroupState, fieldToShow: undefined, showList: false, showModal: false });
  }, [setImagesGroupState, imagesGroupState]);

  const imagesList = useMemo(() => Object.entries(imagesMap ?? {}), [imagesMap]);

  return {
    imagesList,
    imagesGroupContextValue,
    imagesGroupState,
    handleCancelButtonClick,
    handleValidateButtonClick,
  };
}
