import { useState, useCallback } from 'react';
import { MenuTabs, UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    CommonStore: { showLeftMenu, setShowLeftMenu },
  } = useStore();

  // On set le state
  const [valueTabIndex, setValueTabIndex] = useState(MenuTabs.Declarations);

  // On ajoute des hooks
  const handleTabChange = useCallback((newTab: MenuTabs) => {
    setValueTabIndex(newTab);
  }, []);

  return {
    showLeftMenu,
    setShowLeftMenu,
    valueTabIndex,
    handleTabChange,
  };
};
