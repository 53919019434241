/**
 * Les différents etats de publication d'un formulaire
 */
export enum FormPublishState {
  /**
   * Brouillon, c'est l'état initial
   */
  Draft = 0,
  /**
   * Publié, et donc disponible pour la saisie
   */
  Published = 1,
  /**
   * Publié en mode test seulement
   */
  Test = 2,
  /**
   * Déprécié, et rendre indisponible pour la saisie
   */
  Depreciated = 3,
}
