import React from 'react';
import { NoPhotographyOutlined } from '@mui/icons-material';
import { Badge, BadgeProps, Icon, IconProps, alpha, styled } from '@mui/material';
import { padding } from '@mui/system';

export const StyledIconFallBackSrcImages = styled(Icon)<IconProps>(
  ({
    theme: {
      palette: {
        secondary: { main },
      },
    },
  }) => {
    const secondaryMain200 = alpha(main, 0.2);
    return {
      width: 75,
      height: 75,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `4px solid ${secondaryMain200}`,
    };
  },
);

export const StyledNoPhotographyOutlined = styled(NoPhotographyOutlined)(
  ({
    theme: {
      palette: {
        secondary: { main },
      },
    },
  }) => {
    const secondaryMain200 = alpha(main, 0.2);

    return {
      color: secondaryMain200,
    };
  },
);

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiBadge-badge': {
    padding: '1.2em 1em',
    borderRadius: '50%',
  },
}));

export const StyledNoPhotoIcon = () => {
  return (
    <StyledIconFallBackSrcImages>
      <StyledNoPhotographyOutlined fontSize="large" />
    </StyledIconFallBackSrcImages>
  );
};
