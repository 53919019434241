import { PasswordRulesType } from '10.quickConnect.app/components/domain/Login/types';

export const checkPasswordRules = (value: string): PasswordRulesType => {
  let rules = {
    passLength: false,
    lowerCase: false,
    upperCase: false,
    digit: false,
    specialCharacters: false,
  };
  if (/(?=.*?[A-Z])/.test(value)) {
    rules = { ...rules, upperCase: true };
  }
  if (/(?=.*?[a-z])/.test(value)) {
    rules = { ...rules, lowerCase: true };
  }
  if (value.length >= 8) {
    rules = { ...rules, passLength: true };
  }
  if (/(?=.*?[0-9])/.test(value)) {
    rules = { ...rules, digit: true };
  }
  if (/(?=.*?[ !"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~])/.test(value)) {
    rules = { ...rules, specialCharacters: true };
  }
  return rules;
};
