import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CancelWorkFlowCommandName } from './const';
import { CancelWorkFlowCommandProps } from './types';
import useData from './hooks';
import { CancelWorkFlowCommandStyledComponent } from './styles';

const CancelWorkFlowCommand = ({
  formType,
  handleItemDatas,
  sendDeclaration,
  setDeclarationData,
  declarationId,
}: CancelWorkFlowCommandProps) => {
  const { t, onCancelWorkflow } = useData(
    formType,
    handleItemDatas,
    sendDeclaration,
    setDeclarationData,
    declarationId,
  );

  return (
    <Tooltip title={t('formlib_cancel_workflow_button_text').toString()} placement="right">
      <CancelWorkFlowCommandStyledComponent
        data-cy={`${CancelWorkFlowCommandName}-btn-cancelWkf`}
        size="large"
        onClick={onCancelWorkflow}
        color="warning"
      >
        <DeleteOutlineIcon />
      </CancelWorkFlowCommandStyledComponent>
    </Tooltip>
  );
};

export default observer(CancelWorkFlowCommand);
