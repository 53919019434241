import { Box, Typography } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useData from './hooks';
import { CenterItemsProps } from './types';
import { Name } from './const';
import { pureWhiteColor } from '00.app/theme';

const CenterItems: React.FC<CenterItemsProps> = ({
  pageTitle,
  children,
  showLeftMenu,
  searchView,
}: CenterItemsProps) => {
  const { t } = useTranslation('common');
  // eslint-disable-next-line no-empty-pattern
  const {} = useData();

  return !showLeftMenu ? (
    <Box
      data-cy={Name}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: { md: '2em', lg: '2em' } }}
    >
      {children || (
        <Typography
          variant="h6"
          sx={{
            display: {
              xs: searchView ? 'block' : 'none',
              sm: searchView ? 'block' : 'none',
              md: 'flex',
              lg: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              color: !searchView ? pureWhiteColor : 'inherit',
            },
            fontSize: !searchView ? '1.3vw' : '1.2em',
            lineBreak: 'anywhere',
          }}
        >
          {pageTitle}
        </Typography>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default observer(CenterItems);
