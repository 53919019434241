import { validate } from 'uuid';
import mapChoice from '../sharedList/mapChoice';
import { ComboDesc } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';

const mapComboDesc = (field: ComboDesc): ComboDesc => {
  let listChoice;
  let listId;
  let defaultValueFormatted;
  const { defaultValue } = field;
  if (field.listDef) {
    if (validate(field.listDef)) listId = field.listDef;
    else {
      const choices = JSON.parse(field.listDef);
      if (Array.isArray(choices)) listChoice = choices.map(mapChoice);
      if ((defaultValue && defaultValue.startsWith('[')) || defaultValue?.startsWith('{')) {
        defaultValueFormatted = JSON.parse(defaultValue);
      }
    }
  } else if ((defaultValue && defaultValue.startsWith('[')) || defaultValue?.startsWith('{')) {
    defaultValueFormatted = JSON.parse(defaultValue);
  }

  const valueByDefault = field.fieldType === FieldType.CheckBoxList ? [] : undefined;

  return {
    value: field.value ?? defaultValueFormatted ?? valueByDefault,
    listDef: field.listDef,
    listChoice: listChoice,
    listId: listId,
    isPopup: field.isPopup,
    defaultValue: field.value ?? field.defaultValue ?? undefined,
  } as ComboDesc;
};

export default mapComboDesc;
