/**
 * Différentes propriétés éditables sur les champs des formulaires
 */
export enum PropertyIdEnum {
  /**
   * Identifiant du champ
   */
  Id = 1,
  /**
   * Nom du champ
   */
  Name = 2,
  /**
   * Description du champ
   */
  Description = 3,
  /**
   * Le champ est-il visible ?
   */
  Visible = 4,
  /**
   * Le champ est-il obligatoire ?
   */
  Mandatory = 5,
  /**
   * Valeur minimum
   */
  Min = 10,
  /**
   * Valeur maximum
   */
  Max = 11,
  /**
   * Expression régulière
   */
  Regex = 12,
  /**
   * Nombre de lignes
   */
  Lines = 13,
  /**
   * Format d'édition
   */
  EditFormat = 14,
  StayOnSameLine = 15,
  Label = 16,
  DefaultValue = 17,
  Controller = 18,
  Binding = 19,
  Reset = 20,
  ShowAutoSendToMe = 21,
  MailSubject = 22,
  AutoSend = 23,
  NotificationType = 24,
  DataSourceType = 25,
  DataSource = 26,
  CanDuplicate = 27,
  Reference = 28,
  Importance = 29,
  ForcedSelection = 30,
  LRUCount = 31,
  Object = 32,
  ReadOnly = 33,
  CheckRGPD = 34,
  TplName = 35,

  MaxAttachment = 50,
  ContentType = 51,
  RetentionPeriod = 52,
  MaximumSize = 53,
  DefaultImage = 54,
  AllowPick = 55,
  AllowPhoto = 56,
  DefaultSharedFile = 57,

  Summary = 60,

  IsEditable = 70,

  Value = 80,
  Choices = 81,

  IsCheckBoxList = 90,
  IsPopup = 91,

  Formula = 100,

  DateType = 110,
  InitDate = 111,
  DisplayTimeNow = 112,
  InitTime = 113,

  IsCollapsable = 120,
  IsHideCollapseIcon = 121,

  IsMultiSelection = 130,
  IsSearchable = 131,

  ModelId = 140,

  PluginName = 150,

  IsVertical = 160,

  MaxRow = 170,
  NbRowOnInit = 171,
  StepTransition = 172,
  PushNotificationType = 173,

  ShowMap = 201,
  ShowSearchBar = 202,

  TodoListId = 210,
  UserComment = 211,

  SearchEquipment = 220,
  MustBeOfSchema = 221,
  MustHaveQCTag = 222,

  ActionName = 230,
  ActionLabel = 231,
  CallOnStart = 232,
  Executor = 233,
}
