import { useEffect, useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AllFieldValueTypes,
  FieldDesc,
  Reference,
  EntityData,
  CodeReaderDesc,
  ComboDesc,
  CheckBoxListDesc,
  HierarchicalDesc,
} from '90.quickConnect.Models/models';
import { getReferencedValue } from '20.formLib/helpers/referencedValue/getReferencedValue';
import { FieldType } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';
import { buildCalculatedLabelWithSystemRefTokens } from '30.quickConnect.Stores/helpers/getValueForCalculateLabel';
import updateValueFieldOrChild from '20.formLib/helpers/updateField/updateValueFieldOrChild';

const useData = (
  fieldWithReference: FieldDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  forceFieldUpdate: (fieldPathIdToUpdate: string) => void,
  otherFields: FieldDesc[],
): void => {
  const { fullPathId, reference, value: referenceValue, id: fieldReferenceId } = fieldWithReference;
  const previousReferenceValue = useRef<string>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sPatternSystemRefForStringExtract = new RegExp('SystemRef\\s*[(]\\s*(\\w+)\\s*[)]', 'g');
  const previousValue = useRef<AllFieldValueTypes>();

  const {
    SharedListStore: { getSharedList, getSharedLists, sharedLists, logger },
    EquipmentsStore: { bindingSharedLists },
  } = useStore();

  const { updateProperty } = useQCUpdateFunctionsContext();

  // Récupération de la fonction de traduction
  const { t } = useTranslation('declaration');

  const {
    LoginStore: { signInInfos },
  } = useStore();

  const referenceDependencies = useMemo(() => {
    if (reference) {
      const userInfoTokens = reference.match(sPatternSystemRefForStringExtract);
      if (userInfoTokens) {
        const labelValue = buildCalculatedLabelWithSystemRefTokens(reference, userInfoTokens, signInInfos);
        updateValueFieldOrChild(fieldWithReference.fullPathId, fieldWithReference, labelValue);
      }
      if (reference.includes(':')) {
        const indexOfChar = reference.indexOf(':');
        const referenceIdOrFullPathId = reference.substring(0, indexOfChar);
        const colId = reference.substring(indexOfChar + 1);
        const foundFields = otherFields.filter(
          (f) =>
            f.id.toUpperCase() === referenceIdOrFullPathId.toUpperCase() ||
            f.fullPathId.toUpperCase() === referenceIdOrFullPathId.toUpperCase(),
        );
        if (foundFields.length > 0) {
          return foundFields.map((foundField: FieldDesc) => {
            const { id: referencedId, value, fieldType, fullPathId: referenceFullPathId } = foundField;
            const { listChoice } = foundField as CheckBoxListDesc | ComboDesc | HierarchicalDesc;
            return {
              referenceId: referencedId,
              fullPathId: referenceFullPathId,
              value: value,
              fieldType: fieldType,
              columnId: colId,
              listChoice: listChoice,
            } as Reference;
          });
        }
      }
      const foundFields = otherFields.filter(
        (f) => f.id.toUpperCase() === reference.toUpperCase() || f.fullPathId.toUpperCase() === reference.toUpperCase(),
      );
      if (foundFields.length > 0) {
        return foundFields.map((foundField: FieldDesc) => {
          const { id: referencedId, value, fieldType, fullPathId: referenceFullPathId } = foundField;
          const { listChoice } = foundField as CheckBoxListDesc | ComboDesc | HierarchicalDesc;
          return {
            referenceId: referencedId,
            value: value,
            fieldType: fieldType,
            fullPathId: referenceFullPathId,
            listChoice: listChoice,
          } as Reference;
        });
      }
      return [];
    }

    return [];
  }, [reference, sPatternSystemRefForStringExtract, otherFields, signInInfos, fieldWithReference]);

  // Récupère les listChoice des champs que le Reference Resolver en a besoin
  // Comme les champs liste dans un champ dialogue encore fermé
  useEffect(() => {
    const { listId, listChoice, fieldType } = fieldWithReference as CheckBoxListDesc | ComboDesc | HierarchicalDesc;
    if (listId && !listChoice) {
      getSharedList(listId).then(async (sharedList) => {
        try {
          if (!sharedList) {
            const shareldListsIds: string[] = [listId];
            await getSharedLists(shareldListsIds);
            sharedList = await getSharedList(listId);
            if (!sharedList) {
              return;
            }
          }
          const propertyToUpdate = fieldType === 'HierarchicalList' ? 'hierarchicalChoices' : 'data';
          const dataToUpdate = sharedList[propertyToUpdate];
          if (dataToUpdate) {
            updateProperty(fullPathId, 'listChoice', dataToUpdate);
          }
        } catch (error) {
          logger.trace(ReferenceError.name, error);
        }
      });
    }
  }, [
    referenceDependencies,
    otherFields,
    fullPathId,
    getSharedList,
    getSharedLists,
    updateProperty,
    logger,
    fieldWithReference,
  ]);

  const evalReference = useCallback(
    (referenceDependency: Reference) => {
      const { fieldType, fullPathId: referenceFullPathId } = referenceDependency;
      let entityReference: EntityData | undefined = undefined;

      const referenceField = otherFields.find(
        (field: FieldDesc) => field.fullPathId.toUpperCase() === referenceFullPathId.toUpperCase(),
      );
      if (!referenceField) return;

      if (fieldType === FieldType.CodeReader && referenceField) {
        const codeReaderField = referenceField as CodeReaderDesc;
        entityReference = codeReaderField.entityData;
      }

      // Charger les choix de liste si nécessaire sur l'entityReference de l'equipement
      if (entityReference) {
        const attributesAreDirectProperties = !entityReference?.attributes;

        // Fonction pour mapper la valeur d'un attribut en utilisant une liste partagée
        const mapAttributeValueUsingSharedList = (attributeKey: string, attributeValue: unknown): unknown => {
          // Trouver l'ID de la liste partagée pour la clé d'attribut
          let bindingId: string | undefined;
          for (const key in bindingSharedLists) {
            if (Object.prototype.hasOwnProperty.call(bindingSharedLists, key)) {
              if (key === attributeKey && bindingSharedLists[key] !== undefined) {
                const result = bindingSharedLists[key];
                bindingId = result.toString();
                break;
              }
            }
          }
          if (bindingId) {
            const sharedList = sharedLists?.find((list) => list.id === bindingId);
            if (sharedList) {
              // Trouver la valeur correspondante dans la liste partagée
              const choice = sharedList.data.find((c) => c.label === attributeValue || c.value === attributeValue);
              if (choice) {
                return choice;
              }
            }
          }
          return attributeValue;
        };
        // Mapper les valeurs des attributs en utilisant les listes partagées
        const attributes = attributesAreDirectProperties
          ? Object.entries(entityReference).reduce((acc, [key, value]) => {
              acc[key] = mapAttributeValueUsingSharedList(key, value);
              return acc;
            }, {} as { [key: string]: any })
          : entityReference.attributes?.map((attr) => ({
              ...attr,
              value: mapAttributeValueUsingSharedList(attr.attributeLabel, attr.value),
            }));
        entityReference = { ...entityReference, attributes } as EntityData;
      }

      const newValueForReferencedField = getReferencedValue(
        fieldWithReference,
        referenceField,
        referenceDependency,
        t,
        entityReference,
      );

      if (previousValue.current !== newValueForReferencedField)
        updateDeclaration(fullPathId, newValueForReferencedField);
    },
    [otherFields, fieldWithReference, t, updateDeclaration, fullPathId, sharedLists, bindingSharedLists],
  );
  useEffect(() => {
    // Vérifie si les choix de liste pour le champ sont déjà chargés
    const areListChoicesLoaded = (field: FieldDesc) => {
      const { listId, listChoice } = field as CheckBoxListDesc | ComboDesc | HierarchicalDesc;
      return listId && (!listChoice || listChoice.length === 0);
    };

    // Vérifie si l'une des dépendances de référence nécessite le chargement des choix de liste
    const needListChoicesLoading =
      areListChoicesLoaded(fieldWithReference) ||
      referenceDependencies.some((refDep) => {
        const field = otherFields.find((f) => f.fullPathId.toUpperCase() === refDep.fullPathId.toUpperCase());
        return field && areListChoicesLoaded(field);
      });

    // Si les choix de liste doivent être chargés, retourne immédiatement
    if (needListChoicesLoading) return;

    // Dans le cas où la referenceValue a changé
    if (referenceDependencies.length > 0 && previousReferenceValue.current !== JSON.stringify(referenceDependencies)) {
      if (referenceDependencies.length > 1) {
        // On est dans un groupe répétable, il va falloir trouver le champ référencé a l'intérieur du groupe
        const indexOfChar = fullPathId.lastIndexOf('.');
        const parentFullPathId = fullPathId.substring(0, indexOfChar);
        const fullPathIdReferencedChildWithColumnId = `${parentFullPathId}.${reference}`;
        // On récupère le fullPathId du champ référencé, sans le columnId
        const fullPathIdReferencedChild = fullPathIdReferencedChildWithColumnId.includes(':')
          ? fullPathIdReferencedChildWithColumnId.substring(0, fullPathIdReferencedChildWithColumnId.indexOf(':'))
          : fullPathIdReferencedChildWithColumnId;
        const referenceDependency = referenceDependencies.find(
          (f: Reference) => f.fullPathId === fullPathIdReferencedChild,
        );

        if (referenceDependency) {
          evalReference(referenceDependency);
          previousReferenceValue.current = JSON.stringify(referenceDependencies);
        }
      } else {
        evalReference(referenceDependencies[0]);
        previousReferenceValue.current = JSON.stringify(referenceDependencies);
      }
    }
  }, [
    evalReference,
    fieldReferenceId,
    fullPathId,
    referenceDependencies,
    reference,
    referenceValue,
    fieldWithReference,
    otherFields,
  ]);
};

export default useData;
