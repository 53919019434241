import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { UseDataProps } from './types';

const useData = (setFilterList: Dispatch<SetStateAction<string>>): UseDataProps => {
  const [filter, setFilter] = useState('');

  const doSearch = useCallback(() => {
    setFilterList(filter);
  }, [filter, setFilterList]);

  const keyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.key == 'Enter') {
        doSearch();
      }
    },
    [doSearch],
  );

  const clear = useCallback(() => {
    setFilter('');
    setFilterList('');
  }, [setFilterList]);

  // On retourne les valeurs à la vue
  return {
    filter,
    setFilter,
    doSearch,
    clear,
    keyPress,
  };
};

export default useData;
