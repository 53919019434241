import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

export const getReferencedReadOnlyValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const readOnlyValue = typeof valueReferenceDependency === 'string' ? valueReferenceDependency : undefined;

  if (!readOnlyValue) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.Separator:
    case FieldType.ReadOnlyValue:
      return readOnlyValue;

    default:
      return undefined;
  }
};
