import React from 'react';
import { observer } from 'mobx-react';
import { AllResolversProps } from './types';
import VisibilitiesResolver from '20.formLib/DeclarationContainer/AllResolvers/VisibilitiesResolver';
import ComputedFieldsResolver from '20.formLib/DeclarationContainer/AllResolvers/ComputedFieldsResolver';
import ErrorsResolver from '20.formLib/DeclarationContainer/AllResolvers/ErrorsResolver';
import ReferencesResolver from '20.formLib/DeclarationContainer/AllResolvers/ReferencesResolver';
import QCScriptsResolver from '20.formLib/DeclarationContainer/AllResolvers/QCScriptsResolver';

const AllResolvers = ({
  children,
  flattenFields,
  updateFieldVisibility,
  updateDeclaration,
  setCanSubmit,
  updateFieldErrors,
  forceFieldUpdate,
  formType,
}: AllResolversProps) => {
  return (
    <>
      <VisibilitiesResolver flattenFields={flattenFields} updateFieldVisibility={updateFieldVisibility}>
        <ComputedFieldsResolver
          flattenFields={flattenFields}
          updateDeclaration={updateDeclaration}
          updateFieldErrors={updateFieldErrors}
        >
          <ErrorsResolver
            flattenFields={flattenFields}
            updateFieldErrors={updateFieldErrors}
            setCanSubmit={setCanSubmit}
            formType={formType}
          >
            <ReferencesResolver
              flattenFields={flattenFields}
              updateDeclaration={updateDeclaration}
              forceFieldUpdate={forceFieldUpdate}
            >
              <QCScriptsResolver
                flattenFields={flattenFields}
                updateDeclaration={updateDeclaration}
                updateFieldVisibility={updateFieldVisibility}
              >
                {children}
              </QCScriptsResolver>
            </ReferencesResolver>
          </ErrorsResolver>
        </ComputedFieldsResolver>
      </VisibilitiesResolver>
    </>
  );
};

export default observer(AllResolvers);
