/* eslint-disable max-lines-per-function */
import { TFunction } from 'i18next';
import { isAfter, isEqual, set, isBefore } from 'date-fns';
import evaluateComputedRule from './evaluateComputedRule';
import { QcOperator, OperatorResult } from '90.quickConnect.Models/enums';
import {
  DualOperator,
  Dependency,
  NamedUserParameterValue,
  AllFieldValueTypes,
  Choice,
} from '90.quickConnect.Models/models';
import { convertToBooleanValue, errorHandler } from '80.quickConnect.Core/helpers';
import { isDate } from '80.quickConnect.Core/helpers/common';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import {
  evalChoicesListContainsString,
  evalChoicesListContainsTextString,
  evalChoicesListEqualsString,
} from '20.formLib/helpers/evals/evalChoices';

// Tag
const tag = '20.formLib/helpers/evals/evalComparaisonLogic.ts';

const evalComparisonWithItems = (
  type: QcOperator,
  refValue: AllFieldValueTypes,
  refType: OperatorResult,
  toCompareValue: AllFieldValueTypes,
  toCompareType: OperatorResult,
): boolean => {
  if (refValue !== undefined || toCompareValue !== undefined) {
    // Cas des dates
    if (
      refValue instanceof Date &&
      toCompareValue instanceof Date &&
      (refType === OperatorResult.DATE || refType === OperatorResult.DATETIME) &&
      (toCompareType === OperatorResult.DATE || toCompareType === OperatorResult.DATETIME)
    ) {
      // Dans le cas des operatorResult de type Date, on supprime les heures, minutes et secondes
      const date1 =
        refType === OperatorResult.DATE
          ? set(refValue, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          : set(toCompareValue, { seconds: 0, milliseconds: 0 });

      const date2 =
        toCompareType === OperatorResult.DATE
          ? set(toCompareValue, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          : set(toCompareValue, { seconds: 0, milliseconds: 0 });

      switch (type) {
        case QcOperator.Equal:
          return isEqual(date1, date2);
        // return date1 === date2;
        case QcOperator.Less:
          return isBefore(date1, date2);
        case QcOperator.Greater:
          return isAfter(date1, date2);
        case QcOperator.LessOrEqual:
          return isBefore(date1, date2) || isEqual(date1, date2);
        case QcOperator.GreaterOrEqual:
          return isAfter(date1, date2) || isEqual(date1, date2);
        case QcOperator.Different:
          return isEqual(date1, date2) === false;
        case QcOperator.Contains:
          return date1.toLocaleDateString().toString().includes(date2.toLocaleDateString().toString());
        case QcOperator.NotContains:
          return !date1.toLocaleDateString().toString().includes(date2.toLocaleDateString().toString());
        case QcOperator.StartsWith:
          return date1.toLocaleDateString().toString().startsWith(date2.toLocaleDateString().toString());
        case QcOperator.EndsWith:
          return date1.toLocaleDateString().toString().endsWith(date2.toLocaleDateString().toString());
        default:
          return false;
      }
    }
    // Cas des dates avec une chaine de caractères
    else if (
      (refType === OperatorResult.DATE || refType === OperatorResult.DATETIME) &&
      toCompareType === OperatorResult.STRING
    ) {
      if (!isDate(refValue)) return type === QcOperator.IsEmpty || type === QcOperator.Different;
      if (typeof toCompareValue !== 'string') return false;
      if (toCompareValue === '') return false;

      const dtFmtOpt: Record<string, number> =
        refType === OperatorResult.DATE
          ? { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }
          : { seconds: 0, milliseconds: 0 };

      const date1 = set(refValue, dtFmtOpt);
      const dateTime2 = DateTimeExtension.parseMultiFormat(toCompareValue);

      if (!date1 || !dateTime2) return false;

      const date2 = set(dateTime2, dtFmtOpt);

      switch (type) {
        case QcOperator.Equal:
          return isEqual(date1, date2);

        case QcOperator.Different:
          return !isEqual(date1, date2);

        case QcOperator.Less:
          return isBefore(date1, date2);

        case QcOperator.Greater:
          return isAfter(date1, date2);

        case QcOperator.LessOrEqual:
          return isBefore(date1, date2) || isEqual(date1, date2);

        case QcOperator.GreaterOrEqual:
          return isAfter(date1, date2) || isEqual(date1, date2);

        default:
          return false;
      }
    }
    // Cas des times avec une string
    else if (refType === OperatorResult.TIME && toCompareType === OperatorResult.STRING) {
      if (!isDate(refValue)) return type === QcOperator.IsEmpty || type === QcOperator.Different;
      if (typeof toCompareValue !== 'string') return false;
      if (toCompareValue === '') return false;

      const timeStrArray: string[] = toCompareValue.split(':');

      if (timeStrArray.length !== 2 && timeStrArray.some((str: string) => Number.isNaN(str))) {
        errorHandler(
          tag,
          new Error(`Chaine de caractères incorrecte pour la valeur de toCompareValue: ${toCompareValue}`),
          'evalComparisonWithItems',
        );

        return false;
      }

      const [hours, minutes] = timeStrArray;

      const dtFmt = { hours: +hours, minutes: +minutes, seconds: 0, milliseconds: 0 };

      const dateTimeToCompare = set(new Date(), dtFmt);

      const dateTimeReference = set(refValue, { seconds: 0, milliseconds: 0 });

      switch (type) {
        case QcOperator.Equal:
          return isEqual(dateTimeReference, dateTimeToCompare);

        case QcOperator.Different:
          return !isEqual(dateTimeReference, dateTimeToCompare);

        case QcOperator.Less:
          return isBefore(dateTimeReference, dateTimeToCompare);

        case QcOperator.Greater:
          return isAfter(dateTimeReference, dateTimeToCompare);

        case QcOperator.LessOrEqual:
          return isBefore(dateTimeReference, dateTimeToCompare) || isEqual(dateTimeReference, dateTimeToCompare);

        case QcOperator.GreaterOrEqual:
          return isAfter(dateTimeReference, dateTimeToCompare) || isEqual(dateTimeReference, dateTimeToCompare);

        default:
          return false;
      }
    }
    // Cas des numbers et des int
    else if (
      (refType === OperatorResult.INT || refType === OperatorResult.DOUBLE || refType === OperatorResult.STRING) &&
      (toCompareType === OperatorResult.INT ||
        toCompareType === OperatorResult.DOUBLE ||
        toCompareType === OperatorResult.STRING) &&
      refValue !== null &&
      refValue !== undefined &&
      !isNaN(Number(refValue)) &&
      !isNaN(Number(toCompareValue))
    ) {
      const number1 = Number(refValue);
      const number2 = Number(toCompareValue ?? 0);
      switch (type) {
        case QcOperator.Equal:
          return number1 === number2;
        case QcOperator.Less:
          return number1 < number2;
        case QcOperator.Greater:
          return number1 > number2;
        case QcOperator.LessOrEqual:
          return number1 <= number2;
        case QcOperator.GreaterOrEqual:
          return number1 >= number2;
        case QcOperator.Different:
          return number1 !== number2;
        case QcOperator.Contains:
          return number1.toString().includes(number2.toString());
        case QcOperator.NotContains:
          return !number1.toString().includes(number2.toString());
        case QcOperator.StartsWith:
          return number1.toString().startsWith(number2.toString());
        case QcOperator.EndsWith:
          return number1.toString().endsWith(number2.toString());
        default:
          return false;
      }
    } else if (refType === OperatorResult.STRING && toCompareType === OperatorResult.STRING && refValue !== null) {
      const refValueStr = refValue ? refValue.toString() : '';
      const toCompareValueStr = toCompareValue!.toString();
      switch (type) {
        case QcOperator.Equal:
          return refValueStr === toCompareValueStr;
        case QcOperator.Less:
          return refValueStr < toCompareValueStr;
        case QcOperator.Greater:
          return refValueStr > toCompareValueStr;
        case QcOperator.LessOrEqual:
          return refValueStr <= toCompareValueStr;
        case QcOperator.GreaterOrEqual:
          return refValueStr >= toCompareValueStr;
        case QcOperator.Different:
          return refValueStr !== toCompareValueStr;
        case QcOperator.Contains:
          return refValueStr.includes(toCompareValueStr);
        case QcOperator.NotContains:
          return !refValueStr.includes(toCompareValueStr);
        case QcOperator.StartsWith:
          return refValueStr.startsWith(toCompareValueStr);
        case QcOperator.EndsWith:
          return refValueStr.endsWith(toCompareValueStr);
        default:
          return false;
      }
    } else if (
      (refType === OperatorResult.BOOLEAN && toCompareType === OperatorResult.BOOLEAN) ||
      (refType === OperatorResult.BOOLEAN && toCompareType === OperatorResult.DOUBLE) ||
      (refType === OperatorResult.BOOLEAN && toCompareType === OperatorResult.INT) ||
      (refType === OperatorResult.BOOLEAN && toCompareType === OperatorResult.STRING)
    ) {
      const ref1ValueBool = convertToBooleanValue(refValue);
      const ref2ValueBool = convertToBooleanValue(toCompareValue);
      if (ref1ValueBool !== undefined && ref2ValueBool !== undefined)
        switch (type) {
          case QcOperator.Equal:
            return ref1ValueBool === ref2ValueBool;
          case QcOperator.Less:
            return ref1ValueBool < ref2ValueBool;
          case QcOperator.Greater:
            return ref1ValueBool > ref2ValueBool;
          case QcOperator.LessOrEqual:
            return ref1ValueBool <= ref2ValueBool;
          case QcOperator.GreaterOrEqual:
            return ref1ValueBool >= ref2ValueBool;
          case QcOperator.Different:
            return ref1ValueBool !== ref2ValueBool;
          case QcOperator.Contains:
            return ref1ValueBool.toString().includes(ref2ValueBool.toString());
          case QcOperator.NotContains:
            return !ref1ValueBool.toString().includes(ref2ValueBool.toString());
          case QcOperator.StartsWith:
            return ref1ValueBool.toString().startsWith(ref2ValueBool.toString());
          case QcOperator.EndsWith:
            return ref1ValueBool.toString().endsWith(ref2ValueBool.toString());
          default:
            return false;
        }
    } else if (
      refValue !== null &&
      refValue !== undefined &&
      refType === OperatorResult.LISTCHOICE &&
      toCompareType === OperatorResult.LISTCHOICE
    ) {
      if (refValue?.constructor.name !== 'Array' && toCompareValue?.constructor.name !== 'Array') {
        const refValueList = refValue as Choice;
        const toCompareValueList = toCompareValue as Choice;
        switch (type) {
          case QcOperator.Equal:
            return refValueList.value === toCompareValueList.value;
          case QcOperator.Less:
            return refValueList.value < toCompareValueList.value;
          case QcOperator.Greater:
            return refValueList.value > toCompareValueList.value;
          case QcOperator.LessOrEqual:
            return refValueList.value <= toCompareValueList.value;
          case QcOperator.GreaterOrEqual:
            return refValueList.value >= toCompareValueList.value;
          case QcOperator.Different:
            return refValueList.value !== toCompareValueList.value;
          case QcOperator.Contains:
            return refValueList.value.includes(toCompareValueList.value);
          case QcOperator.NotContains:
            return !refValueList.value.includes(toCompareValueList.value);
          case QcOperator.StartsWith:
            return refValueList.value.startsWith(toCompareValueList.value);
          case QcOperator.EndsWith:
            return refValueList.value.endsWith(toCompareValueList.value);
          default:
            return false;
        }
      } else if (refValue?.constructor.name === 'Array' && toCompareValue?.constructor.name === 'Array') {
        const refValueList = refValue as Choice[];
        const toCompareValueList = toCompareValue as Choice[];

        switch (type) {
          case QcOperator.Equal:
            return refValueList.map((f) => f.value) === toCompareValueList.map((f) => f.value);
          case QcOperator.Less:
            return refValueList.map((f) => f.value) < toCompareValueList.map((f) => f.value);
          case QcOperator.Greater:
            return refValueList.map((f) => f.value) > toCompareValueList.map((f) => f.value);
          case QcOperator.LessOrEqual:
            return refValueList.map((f) => f.value) <= toCompareValueList.map((f) => f.value);
          case QcOperator.GreaterOrEqual:
            return refValueList.map((f) => f.value) >= toCompareValueList.map((f) => f.value);
          case QcOperator.Different:
            return refValueList.map((f) => f.value) !== toCompareValueList.map((f) => f.value);
          case QcOperator.Contains:
            return refValueList
              .map((f) => f.value)
              .toString()
              .includes(toCompareValueList.map((f) => f.value).toString());
          case QcOperator.NotContains:
            return !refValueList
              .map((f) => f.value)
              .toString()
              .includes(toCompareValueList.map((f) => f.value).toString());
          case QcOperator.StartsWith:
            return refValueList
              .map((f) => f.value)
              .toString()
              .startsWith(toCompareValueList.map((f) => f.value).toString());
          case QcOperator.EndsWith:
            return refValueList
              .map((f) => f.value)
              .toString()
              .endsWith(toCompareValueList.map((f) => f.value).toString());
          default:
            return false;
        }
      }
    } else if (refValue !== null && refType === OperatorResult.LISTCHOICE && toCompareType === OperatorResult.STRING) {
      if (refValue?.constructor.name !== 'Array' && toCompareValue?.constructor.name !== 'Array') {
        const refValueList = refValue as Choice;
        const toCompareValueList = toCompareValue as string;
        const newRefValueList: Choice[] = refValueList ? [refValueList] : [];
        switch (type) {
          case QcOperator.Equal:
            // case QcOperator.Contains:
            return evalChoicesListEqualsString(newRefValueList, toCompareValueList);
            return refValueList?.value === toCompareValueList;
          case QcOperator.Different:
            // return refValueList?.value !== toCompareValueList;
            return !evalChoicesListEqualsString(newRefValueList, toCompareValueList);

          case QcOperator.Contains: {
            // Refonte de la recherche en fonction de la chaine de caractères...
            // "code0,code1" = la ChoiceList doit posséder code0 ET code1 (et d'autres éléments) => TRUE
            // "code0|code1" = la choiceList doit posséder code0 OU code1 => TRUE

            return evalChoicesListContainsString(newRefValueList, toCompareValueList);
          }

          case QcOperator.NotContains: {
            return !evalChoicesListContainsString(newRefValueList, toCompareValueList);
          }

          case QcOperator.ContainsText: {
            return evalChoicesListContainsTextString(newRefValueList, toCompareValueList);
          }

          case QcOperator.NotContainsText: {
            return !evalChoicesListContainsTextString(newRefValueList, toCompareValueList);
          }

          default:
            return false;
        }
      } else if (
        refValue?.constructor.name === 'Array' &&
        toCompareValue?.constructor.name !== 'Array' &&
        typeof toCompareValue === 'string'
      ) {
        const refValueList = refValue as Choice[];

        switch (type) {
          case QcOperator.Equal: {
            return evalChoicesListEqualsString(refValueList, toCompareValue);
          }

          case QcOperator.Less:
            if (refValueList.length === 0) return false;
            return refValueList.every((f) => f.value < toCompareValue);
          case QcOperator.Greater:
            if (refValueList.length === 0) return false;
            return refValueList.every((f) => f.value > toCompareValue);
          case QcOperator.LessOrEqual:
            if (refValueList.length === 0) return false;
            return refValueList.every((f) => f.value <= toCompareValue);
          case QcOperator.GreaterOrEqual:
            if (refValueList.length === 0) return false;
            return refValueList.every((f) => f.value >= toCompareValue);
          case QcOperator.Different: {
            return !evalChoicesListEqualsString(refValueList, toCompareValue);
          }
          case QcOperator.Contains: {
            // Refonte de la recherche en fonction de la chaine de caractères...
            // "code0,code1" = la ChoiceList doit posséder code0 ET code1 (et d'autres éléments) => TRUE
            // "code0|code1" = la choiceList doit posséder code0 OU code1 => TRUE
            return evalChoicesListContainsString(refValueList, toCompareValue);
          }

          case QcOperator.NotContains: {
            return !evalChoicesListContainsString(refValueList, toCompareValue);
          }

          case QcOperator.ContainsText: {
            return evalChoicesListContainsTextString(refValueList, toCompareValue);
          }

          case QcOperator.NotContainsText: {
            return !evalChoicesListContainsTextString(refValueList, toCompareValue);
          }

          case QcOperator.StartsWith:
            return refValueList
              .some((f) => f.value)
              .toString()
              .startsWith(toCompareValue);
          case QcOperator.EndsWith:
            return refValueList
              .some((f) => f.value)
              .toString()
              .endsWith(toCompareValue);
          default:
            return false;
        }
      } else if (refValue?.constructor.name === 'Array' && toCompareValue?.constructor.name === 'Array') {
        const refValueList = refValue as Choice[];
        const toCompareValueList = toCompareValue as Choice[];

        switch (type) {
          case QcOperator.Equal:
            return refValueList.map((f) => f.value) === toCompareValueList.map((f) => f.value);
          case QcOperator.Less:
            return refValueList.map((f) => f.value) < toCompareValueList.map((f) => f.value);
          case QcOperator.Greater:
            return refValueList.map((f) => f.value) > toCompareValueList.map((f) => f.value);
          case QcOperator.LessOrEqual:
            return refValueList.map((f) => f.value) <= toCompareValueList.map((f) => f.value);
          case QcOperator.GreaterOrEqual:
            return refValueList.map((f) => f.value) >= toCompareValueList.map((f) => f.value);
          case QcOperator.Different:
            return refValueList.map((f) => f.value) !== toCompareValueList.map((f) => f.value);
          case QcOperator.Contains:
            return refValueList
              .map((f) => f.value)
              .toString()
              .includes(toCompareValueList.map((f) => f.value).toString());
          case QcOperator.NotContains:
            return !refValueList
              .map((f) => f.value)
              .toString()
              .includes(toCompareValueList.map((f) => f.value).toString());
          case QcOperator.StartsWith:
            return refValueList
              .map((f) => f.value)
              .toString()
              .startsWith(toCompareValueList.map((f) => f.value).toString());
          case QcOperator.EndsWith:
            return refValueList
              .map((f) => f.value)
              .toString()
              .endsWith(toCompareValueList.map((f) => f.value).toString());
          default:
            return false;
        }
      }
    } else if (refValue === null && refType === OperatorResult.LISTCHOICE && toCompareType === OperatorResult.STRING) {
      const toCompareValueList = toCompareValue as string;
      const newRefValueList: Choice[] = [];
      switch (type) {
        case QcOperator.Equal:
          // case QcOperator.Contains:
          return evalChoicesListEqualsString(newRefValueList, toCompareValueList);
        case QcOperator.Different:
          return !evalChoicesListEqualsString(newRefValueList, toCompareValueList);

        case QcOperator.Contains: {
          // Refonte de la recherche en fonction de la chaine de caractères...
          // "code0,code1" = la ChoiceList doit posséder code0 ET code1 (et d'autres éléments) => TRUE
          // "code0|code1" = la choiceList doit posséder code0 OU code1 => TRUE

          return evalChoicesListContainsString(newRefValueList, toCompareValueList);
        }

        case QcOperator.NotContains: {
          return !evalChoicesListContainsString(newRefValueList, toCompareValueList);
        }

        case QcOperator.ContainsText: {
          return evalChoicesListContainsTextString(newRefValueList, toCompareValueList);
        }

        case QcOperator.NotContainsText: {
          return !evalChoicesListContainsTextString(newRefValueList, toCompareValueList);
        }

        default:
          return false;
      }
    } else {
      return false;
    }
  }
  return false;
};

export const evalComparisonLogic = (
  t: TFunction,
  dualOperator: DualOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): boolean => {
  const { arg1, arg2, type } = dualOperator;
  const [refValue, refType] = evaluateComputedRule(
    t,
    arg1,
    dependencies,
    userParams,
    scopeFullPathId,
    userUpn,
    userEmail,
  );
  switch (type) {
    case QcOperator.IsEmpty:
      if (refType === OperatorResult.BOOLEAN) {
        const ref1ValueBool = convertToBooleanValue(refValue);
        return ref1ValueBool === false;
      }
      if (refType === OperatorResult.LISTCHOICE && refValue && refValue.constructor.name === 'Array') {
        return (refValue as Choice[]).length === 0;
      }
      return refValue === '' || refValue === undefined || refValue === null;
    case QcOperator.IsNotEmpty:
      if (refType === OperatorResult.BOOLEAN) {
        const ref1ValueBool = convertToBooleanValue(refValue);
        return ref1ValueBool === true;
      }
      if (refType === OperatorResult.LISTCHOICE && refValue && refValue.constructor.name === 'Array') {
        return (refValue as Choice[]).length !== 0;
      }
      return refValue !== '' && refValue !== undefined && refValue !== null;
    case QcOperator.IsInMyInformation:
      return (refValue as string)?.includes(userUpn) || (refValue as string)?.includes(userEmail);

    default:
      const [toCompareValue, toCompareType] = evaluateComputedRule(
        t,
        arg2,
        dependencies,
        userParams,
        scopeFullPathId,
        userUpn,
        userEmail,
      );
      return evalComparisonWithItems(type, refValue, refType, toCompareValue, toCompareType);
  }
};
