import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from '00.app/App';
import '80.quickConnect.Core/utils/i18n';
import { StoreProvider, createStore } from '30.quickConnect.Stores';
import { setupInterceptorsTo } from '40.quickConnect.DataAccess/axios/axios';
import { createLogger, LoggerProvider } from '80.quickConnect.Core/logger';
import 'animate.css';

const logger = createLogger();

const tag = 'Root Component - unhandledrejection';

// Error dexies non catché
window.addEventListener('unhandledrejection', (error) => {
  logger.trace(tag, error);
});

const rootStore = createStore();
setupInterceptorsTo(axios);

const Root = () => {
  return (
    <React.StrictMode>
      <LoggerProvider value={logger}>
        <StoreProvider value={rootStore}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StoreProvider>
      </LoggerProvider>
    </React.StrictMode>
  );
};
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
