import { Choice } from '90.quickConnect.Models/models';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

export default function findChoice(arr: Choice[] | undefined, valueToFind: Choice): Choice | undefined {
  if (!arr) return;
  return arr.find(
    (c: Choice) =>
      StringExtension.isTheSame(c.value, valueToFind.value) || StringExtension.isTheSame(c.label, valueToFind.label),
  );
}
