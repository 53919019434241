import { EntityBase } from '90.quickConnect.Models/models';

const mapEntityBase = (entity: EntityBase): EntityBase => ({
  id: entity.id ?? '',
  eTag: entity.eTag ?? '',
  createdAt: entity.createdAt ? new Date(entity.createdAt) : new Date(),
  updatedAt: entity.updatedAt ? new Date(entity.createdAt) : undefined,
});

export default mapEntityBase;
