import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormOrWorkflowProps } from './types';
import Workflow from './Workflow';
import { FormOrWorkflowName } from './const';
import Fields from '50.quickConnect.Fields/Fields';
import { FormType } from '90.quickConnect.Models/enums';

const FormOrWorkflow = ({
  formType,
  declaration,
  setDeclaration,
  updateDeclaration,
  setCanSubmit,
  flattenFields,
  declarationData: { Workflow: workflowData },
  steps,
  isEditable,
  context,
  updateFieldErrors,
  updateItemsForThisField,
}: FormOrWorkflowProps) => {
  const { t } = useTranslation('declaration');
  switch (formType) {
    case FormType.Form:
      return (
        <Fields
          data-cy={FormOrWorkflowName}
          fieldsDescriptions={declaration ?? []}
          updateDeclaration={updateDeclaration}
          setCanSubmit={setCanSubmit}
          isReadOnly={!isEditable}
          flattenFields={flattenFields}
          setDeclaration={setDeclaration}
          context={context}
          updateFieldErrors={updateFieldErrors}
          updateItemsForThisField={updateItemsForThisField}
        />
      );
    case FormType.Workflow:
      return workflowData ? (
        <Workflow
          updateDeclaration={updateDeclaration}
          setCanSubmit={setCanSubmit}
          flattenFields={flattenFields}
          updateItemsForThisField={updateItemsForThisField}
          historicalStepDataList={[...workflowData.historicalStepDataList]}
          currentStep={workflowData.currentStep}
          setDeclaration={setDeclaration}
          steps={steps}
          isEditable={isEditable}
          context={context}
          updateFieldErrors={updateFieldErrors}
        />
      ) : (
        <Typography>{t('errors_missing_workflow_data')}</Typography>
      );
    default:
      return <></>;
  }
};

export default observer(FormOrWorkflow);
