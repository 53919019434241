import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { CheckBoxListItemName } from './const';
import { CheckBoxListItemProps } from './types';

const CheckBoxListItem = ({ choice, localValue, parentId, updateLocalState }: CheckBoxListItemProps) => {
  const nameWithId = `${CheckBoxListItemName}-${parentId}`;

  const isSelected = useMemo(() => {
    return localValue.findIndex((selected) => selected.value === choice.value && selected.label === choice.label) > -1;
  }, [choice, localValue]);

  return (
    <ListItem key={`${nameWithId}-${choice.value}-${choice.label}`} disablePadding>
      <Box data-cy={`${nameWithId}-${choice.label}`}>
        <ListItemButton
          data-cy={`${nameWithId}-${choice.value}`}
          role={undefined}
          onClick={updateLocalState(choice)}
          dense
        >
          <ListItemIcon>
            <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText primary={choice.label} />
        </ListItemButton>
      </Box>
    </ListItem>
  );
};
export default observer(CheckBoxListItem);
