import { TFunction } from 'i18next';
import { evalVisibilityBitwiseLogic } from './evalVisibilityBitwiseLogic';
import { evalComparisonLogic } from './evalComparisonLogic';
import { OperatorType } from '90.quickConnect.Models/enums';
import { BaseQCOperator, Dependency, DualOperator, NamedUserParameterValue } from '90.quickConnect.Models/models';
import { mapQcOperatorType } from '90.quickConnect.Models/mappings';

const evaluateVisibility = (
  t: TFunction,
  baseQCOperator: BaseQCOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): boolean => {
  const operatorType = mapQcOperatorType(baseQCOperator.type);
  switch (operatorType) {
    case OperatorType.Bitwise:
      return evalVisibilityBitwiseLogic(
        t,
        baseQCOperator,
        dependencies,
        userParams,
        scopeFullPathId,
        userUpn,
        userEmail,
      );
    case OperatorType.Comparison:
      return evalComparisonLogic(
        t,
        baseQCOperator as DualOperator,
        dependencies,
        userParams,
        scopeFullPathId,
        userUpn,
        userEmail,
      );
    default:
      return false;
  }
};

export default evaluateVisibility;
