import { Choice } from '90.quickConnect.Models/models';

const mapChoice = (choice: Choice): Choice =>
  ({
    label: choice.label,
    value: choice.value,
    data: choice.data ?? undefined,
  } as Choice);

export default mapChoice;
