import React from 'react';
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { MainBox } from './styles';

const MainSkeleton = () => {
  return (
    <Box sx={{ width: '100vw' }}>
      <Skeleton variant="rectangular" sx={MainBox} height={100} />
      <Box
        sx={{
          height: '35vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
        <Typography sx={{ marginTop: '15px' }}>...</Typography>
      </Box>
    </Box>
  );
};
export default observer(MainSkeleton);
