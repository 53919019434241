import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps, OptionsRender } from './types';
import { AllFieldValueTypes, EditDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { DataSourceType } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';
import { useFieldAutoComplete } from '50.quickConnect.Fields/hooks/useFieldAutoComplete';
import useLRU from '50.quickConnect.Fields/hooks/LRU';

const useData = (
  editDesc: EditDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  const {
    DeclarationStore: { requiredField, verifRequiredField, fieldFocused, setFieldFocused },
    EquipmentsStore: { deleteIsInDataSource, setIsInDataSource },
  } = useStore();
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, dataSourceType, dataSource, lruCount } = editDesc;
  const [autoCompleteProp, setAutoCompleteProp] = useState<OptionsRender[]>([]);
  const [searchAllUO, setSearchAllUO] = useState<boolean>(dataSource?.forceSearchAllUO ?? false);
  const [addFocus] = useState<boolean>(false);

  // Custom Hooks
  const {
    getOptionsRenderForFamilyDataSource,
    getOptionsRenderForAAD,
    deleteAutoComplete,
    registerNewFamillySearch,
    registerNewAADSearch,
    registerValidState,
    unRegisterValidState,
  } = useFieldAutoComplete();

  // Pour le LRU
  const {
    lruState: { list, showList },
    displayList,
    hideList,
    deleteLRU,
  } = useLRU({ fullId: fullPathId, lruCount: lruCount ?? 3 });

  const toggleDisplayLruList = useCallback(() => {
    if (showList) hideList();
    else displayList();
  }, [showList, displayList, hideList]);

  // useRefs
  const textFieldRef = useRef();
  const userHasClicked = useRef<boolean>(false);

  // UseMemos
  const disabledField: boolean = useMemo(() => {
    if (!dataSource) return false;

    if (!dataSource.forcedSelection) return false;

    if (typeof value !== 'string') return false;

    return value !== '' && userHasClicked.current === true;
  }, [dataSource, value]);

  const textLocalValue = useMemo(() => {
    return typeof value === 'string' ? value : '';
  }, [value]);

  // End Region useMemo

  const debouncedUpdateAutoCompleteProp = useDebouncedCallback(async (dataSourceTypeIndicator: DataSourceType) => {
    if (dataSourceTypeIndicator === DataSourceType.AzureAD) {
      if (textLocalValue.length > 2 && !userHasClicked.current) {
        const searchResults = await getOptionsRenderForAAD(textLocalValue);
        setAutoCompleteProp(searchResults);
      } else setAutoCompleteProp([]);
    } else {
      if (dataSource) {
        if (textLocalValue.length > 2 && !userHasClicked.current) {
          const searchResults = await getOptionsRenderForFamilyDataSource(textLocalValue, dataSource, searchAllUO);
          setAutoCompleteProp(searchResults);
        } else {
          setAutoCompleteProp([]);
        }
      }
    }
  }, debounceTime);

  // Permet de rappeler la fonction debouncedUpdateAutoCompleteProp au changement du boolean searchAllUO
  const handleOnChangeSearchAllUO = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      setSearchAllUO(() => !searchAllUO);
      await debouncedUpdateAutoCompleteProp(dataSourceType!);
    },
    [searchAllUO, debouncedUpdateAutoCompleteProp, dataSourceType],
  );

  const deleteLRUItem = useCallback(
    async (event: React.SyntheticEvent, valueToRemove: string) => {
      await deleteLRU(valueToRemove);
    },
    [deleteLRU],
  );

  // On définit les callbacks
  const updateGlobalState = useCallback(
    (nextValue: string) => {
      updateDeclaration(fullPathId, nextValue);
    },
    [fullPathId, updateDeclaration],
  );

  const updateState = useCallback(
    (newValue: string) => {
      // Si le champ possède le focus, on le retire
      if (fieldFocused === fullPathId) setFieldFocused(undefined);

      updateGlobalState(newValue);
    },
    [updateGlobalState, fieldFocused, fullPathId, setFieldFocused],
  );

  const outlinedInputChanged = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const {
        target: { value: newValue },
      } = event;
      updateState(newValue);
    },
    [updateState],
  );

  const onClickAutoComplete = useCallback(
    (newValue: string) => {
      if (newValue === '') {
        if (dataSource?.forcedSelection && dataSourceType) {
          deleteIsInDataSource(fullPathId);

          // On delete le internalData correspondant
          deleteAutoComplete(fullPathId, dataSourceType);
          unRegisterValidState(fullPathId);
        }

        // on remet a false le userHasClicked pour permettre l'affichage de la liste
        userHasClicked.current = false;
      } else {
        if (dataSource?.forcedSelection) {
          setIsInDataSource(fullPathId, true);
          registerValidState(fullPathId);
        }

        // Déclenchement de la réf pour éviter de garder l'affichage de la liste ouverte.
        userHasClicked.current = true;

        // On enregistre / Met à jour le internalData correspondant
        if (dataSourceType === DataSourceType.Family) {
          registerNewFamillySearch(fullPathId, newValue);
        } else if (dataSourceType === DataSourceType.AzureAD) {
          registerNewAADSearch(fullPathId, newValue);
        }
      }
      updateState(newValue);
    },
    [
      dataSource?.forcedSelection,
      fullPathId,
      deleteIsInDataSource,
      setIsInDataSource,
      updateState,
      deleteAutoComplete,
      registerNewFamillySearch,
      registerNewAADSearch,
      dataSourceType,
      unRegisterValidState,
      registerValidState,
    ],
  );

  const onChangeAutoComplete = useCallback(
    async (newValue: string) => {
      if (dataSource?.forcedSelection) {
        setIsInDataSource(fullPathId, false);
      }

      updateState(newValue);

      // Cas de la recherche dans les familles ou azureAD (Contacts pas pris en charge) CallApi (ou non)
      if (dataSourceType === DataSourceType.AzureAD || dataSourceType === DataSourceType.Family)
        await debouncedUpdateAutoCompleteProp(dataSourceType);
    },
    [
      dataSource?.forcedSelection,
      updateState,
      setIsInDataSource,
      fullPathId,
      debouncedUpdateAutoCompleteProp,
      dataSourceType,
    ],
  );

  const autocompleteChanged = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      const { type } = event;
      if (type === 'click' || autoCompleteProp.find((option: OptionsRender) => option.value === newValue)) {
        onClickAutoComplete(newValue);
      } else if (newValue === '') {
        onChangeAutoComplete(newValue);
      } else {
        if (disabledField) {
          event.stopPropagation();
          return;
        }

        onChangeAutoComplete(newValue);
      }
    },
    [autoCompleteProp, onClickAutoComplete, onChangeAutoComplete, disabledField],
  );

  const clearState = useCallback(() => {
    updateGlobalState('');
  }, [updateGlobalState]);

  const onBlur = useCallback(() => {
    setFieldFocused(undefined);
  }, [setFieldFocused]);

  // On retourne les valeurs à la vue
  return {
    textLocalValue,
    t,
    clearState,
    outlinedInputChanged,
    autocompleteChanged,
    autoCompleteProp,
    isEventClick: userHasClicked.current,
    searchAllUO,
    setSearchAllUO,
    handleOnChangeSearchAllUO,
    addFocus,
    textFieldRef,
    requiredField,
    verifRequiredField,
    fieldFocused,
    onBlur,
    lruList: list,
    showLruList: showList,
    toggleDisplayLruList,
    deleteLRUItem,
  };
};

export default useData;
