import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box } from '@mui/material';
import { LabelQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FlexMainBoxRow } from '50.quickConnect.Fields/styles';

const LabelQcField = ({ field, setSelectedIndex, isReadOnly, labelSpan }: ValueFieldsTypesProps) => {
  const { id, toolTip, fullPathId } = field;
  const nameWithId = `${LabelQcFieldName}-${id}`;

  const { breakpoints } = useTheme();

  return (
    <Box sx={FlexMainBoxRow(breakpoints)} onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}>
      <Box data-cy={nameWithId}>{labelSpan ?? ''}</Box>
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );
};
export default observer(LabelQcField);
