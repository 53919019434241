import evalDependencies from './evalDependencies';
import { addIfNotPresent } from '80.quickConnect.Core/helpers';
import { BaseQCOperator, Dependency, DualOperator, FieldDesc } from '90.quickConnect.Models/models';

const getDualOperatorDependencies = (
  baseQCOperator: BaseQCOperator,
  array: Dependency[],
  flattenFields: FieldDesc[],
  scopeFullPathId: string,
) => {
  const parenthesisOperator = baseQCOperator as DualOperator;
  if (parenthesisOperator.arg1) {
    const arg1Refs = evalDependencies(parenthesisOperator.arg1, array, flattenFields, scopeFullPathId);
    arg1Refs.forEach((ref) => {
      addIfNotPresent<Dependency>(array, ref);
    });
  }
  if (parenthesisOperator.arg2) {
    const arg2Refs = evalDependencies(parenthesisOperator.arg2, array, flattenFields, scopeFullPathId);
    arg2Refs.forEach((ref) => {
      addIfNotPresent<Dependency>(array, ref);
    });
  }
};
export default getDualOperatorDependencies;
