import React from 'react';
import { observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeclarationsTreeViewName } from './const';
import DeclarationsTree from './DeclarationsTree';
import { ListColumnOrRowContainer } from '10.quickConnect.app/components/domain/Home/styles';
import { useStore } from '30.quickConnect.Stores';

const DeclarationsTrees = () => {
  const { t } = useTranslation('declaration');
  const theme = useTheme();
  // On récupère les stores
  const {
    DeclarationStore: { DraftsStore, HistoryStore },
  } = useStore();
  return (
    <Box data-cy={DeclarationsTreeViewName} sx={ListColumnOrRowContainer(theme)}>
      <DeclarationsTree store={DraftsStore} title={t('declaration_drafts').toString()} />
      <DeclarationsTree store={HistoryStore} title={t('declaration_history').toString()} />
    </Box>
  );
};
export default observer(DeclarationsTrees);
