import { ToolTipContainer } from '90.quickConnect.Models/models';

const mapTooltip = (tooltip: ToolTipContainer): ToolTipContainer => {
  return {
    toolTipType: tooltip.toolTipType,
    value: tooltip.value,
  } as ToolTipContainer;
};

export default mapTooltip;
