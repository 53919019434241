import { AxiosError } from 'axios';
import { AbstractMapQCError } from '90.quickConnect.Models/mappings/errors/abstracts/mapQCError';
import { QCErrorHTTP } from '90.quickConnect.Models/models';
import { IQCErrorHTTP } from '90.quickConnect.Models/models/errors/contract/IQCErrorHTTP';
import { QCErrorHTTPCodeEnum } from '90.quickConnect.Models/enums';

class MapAxiosQCErrorHTTP extends AbstractMapQCError<AxiosError<any, any>, IQCErrorHTTP> {
  mapTo(param: IQCErrorHTTP): AxiosError<any, any> {
    return new AxiosError<any, any>(param.message, param.code);
  }

  mapFrom(param: AxiosError<any, any>): IQCErrorHTTP {
    return new QCErrorHTTP(
      this._mapFromAxiosHTTPCode(param.code),
      param.response?.status || 500,
      param.message,
      param.config,
      this._isTimeout(param),
    );
  }

  private _mapFromAxiosHTTPCode(code?: string): QCErrorHTTPCodeEnum {
    switch (code) {
      case 'ERR_NETWORK':
        return QCErrorHTTPCodeEnum.ERR_NETWORK;

      case 'ERR_BAD_REPONSE':
        return QCErrorHTTPCodeEnum.ERR_BAD_RESPONSE;

      case 'ERR_BAD_REQUEST':
        return QCErrorHTTPCodeEnum.ERR_BAD_REQUEST;

      case 'ERR_INVALID_URL':
        return QCErrorHTTPCodeEnum.ERR_INVALID_URL;

      case 'ERR_CANCELED':
        return QCErrorHTTPCodeEnum.ERR_CANCELED;

      case 'ECONNABORTED':
        return QCErrorHTTPCodeEnum.ECONNABORTED;

      case 'ETIMEOUT':
        return QCErrorHTTPCodeEnum.ETIMEDOUT;

      default:
        return QCErrorHTTPCodeEnum.UNKNOWN;
    }
  }

  private _isTimeout(param: AxiosError<any, any>): boolean {
    return param.message.includes('timeout') || !!param.stack?.includes('timeout');
  }

  isAxiosError<T extends Error>(error: unknown): error is T {
    return error instanceof AxiosError;
  }
}

const mapAxiosQCErrorHTTP = new MapAxiosQCErrorHTTP();

export default mapAxiosQCErrorHTTP;
