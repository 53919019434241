import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, TextField, Tooltip, IconButton } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ClearIcon from '@mui/icons-material/Clear';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeQcFieldName } from './const';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import { TimeDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';

const TimeQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  labelClass,
}: ValueFieldsTypesProps) => {
  const timeDesc = field as TimeDesc;
  const { toolTip, label, id, min, max, fullPathId } = timeDesc;
  const nameWithId = `${TimeQcFieldName}-${id}`;
  const { t, localValue, onChange, onAccept, locale } = useData(timeDesc, updateDeclaration);
  const {
    DeclarationStore: { requiredField, verifRequiredField },
  } = useStore();
  const { breakpoints } = useTheme();

  const labelBox = label ? (
    <Box sx={FlexRow}>
      {labelSpan}
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  ) : (
    <></>
  );

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      sx={FieldMainBox(breakpoints)}
      data-cy={nameWithId}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      {labelBox}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <TimePicker
          inputRef={(input) => {
            if (input != null && verifRequiredField && requiredField?.fullPathId === fullPathId) {
              input.focus();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              data-cy={`${nameWithId}-time`}
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  placeholder: 'HH:mm',
                },
              }}
            />
          )}
          value={localValue}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <Tooltip title={t('formlib_array_deleted_title').toString()} style={{ order: 1 }}>
                <span>
                  <IconButton
                    data-cy={`${nameWithId}-clear`}
                    edge="end"
                    onClick={() => {
                      onAccept(null);
                    }}
                    disabled={isReadOnly}
                  >
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ),
          }}
          InputAdornmentProps={{
            position: 'end',
            style: { order: 2, marginLeft: 0 },
          }}
          minTime={min}
          maxTime={max}
          readOnly={isReadOnly}
          disabled={isReadOnly}
        />
      </LocalizationProvider>
    </Box>
  );
};
export default observer(TimeQcField);
