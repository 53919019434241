import React from 'react';
import { Avatar, Card, CardContent, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from 'react-i18next';
import { HistoryItemProps } from './type';
import { HistoryItemName } from './const';
import { FlexColumn, FlexRow } from '50.quickConnect.Fields/styles';
import useLabel from '50.quickConnect.Fields/hooks/useLabel';
import { FieldType, WorkflowStepStatus } from '90.quickConnect.Models/enums';

const HistoryItem = ({ stepId, stepLabel, stepState, author, editedAt, comment, index }: HistoryItemProps) => {
  const labelSpan = useLabel(stepLabel, undefined, `${HistoryItemName}-${stepId}`, FieldType.Step, []);

  const { t } = useTranslation('declaration');

  const theme = useTheme();

  const {
    palette: {
      primary: { main },
      warning: { main: warningMain },
      success: { main: successMain },
    },
  } = theme;

  const getSateIcon = (state: WorkflowStepStatus): JSX.Element => {
    switch (state) {
      case WorkflowStepStatus.Canceled:
        return (
          <Tooltip title={t('formlib_action_button_cancel').toString()}>
            <Avatar sx={{ bgcolor: warningMain, marginLeft: '5px' }}>
              <DeleteIcon sx={{ fontSize: 40 }} />
            </Avatar>
          </Tooltip>
        );
      case WorkflowStepStatus.Validated:
        return (
          <Tooltip title={t('formlib_action_button_validate').toString()}>
            <Avatar sx={{ bgcolor: successMain, marginLeft: '5px' }}>
              <DoneRoundedIcon sx={{ fontSize: 40 }} />
            </Avatar>
          </Tooltip>
        );
      default:
      case WorkflowStepStatus.None:
        return (
          <Tooltip title={t('workflow_history_none').toString()}>
            <Avatar sx={{ bgcolor: warningMain, marginLeft: '5px' }}>
              <QuestionMarkIcon sx={{ fontSize: 40 }} />
            </Avatar>
          </Tooltip>
        );
    }
  };

  return (
    <Card data-cy={`${HistoryItemName}-${index}`} sx={{ marginBottom: '5px' }}>
      <CardContent>
        <Box sx={FlexRow}>
          <Avatar sx={{ bgcolor: main, marginRight: '5px' }}>{index + 1}</Avatar>

          <Box key={stepId} sx={{ ...FlexColumn, alignItems: 'center' }}>
            {labelSpan}
            <Typography>{t('workflow_history_author', { author: author })}</Typography>
            <Typography>
              {t('workflow_history_edited_at', { editedAt: new Date(editedAt).toLocaleString() })}
            </Typography>
            {comment && <Typography>{t('workflow_history_comment', { comment: comment })}</Typography>}
          </Box>
          {getSateIcon(stepState)}
        </Box>
      </CardContent>
    </Card>
  );
};

export default observer(HistoryItem);
