import { FieldDesc } from '90.quickConnect.Models/models';

/**
 * Permet de mettre à jour la lecture seule (pas d'édition) d'un champ ou un de ses enfants.
 *
 * @param {string} fullPathId
 * @param {FieldDesc} field
 * @param {boolean} isReadOnly
 * @return {*}  {[FieldDesc, boolean]}
 */
const updateReadOnlyFieldOrChild = (
  fullPathId: string,
  field: FieldDesc,
  isReadOnly: boolean,
): [FieldDesc, boolean] => {
  let changeMade = false;
  if (field.fullPathId == fullPathId && field.fieldIsReadOnly !== isReadOnly) {
    changeMade = true;
    field.fieldIsReadOnly = isReadOnly;
  }
  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateReadOnlyFieldOrChild(fullPathId, child, isReadOnly);
      if (childChanged) changeMade = childChanged;
      child = updatedChild;
    });
  }
  return [field, changeMade];
};

export default updateReadOnlyFieldOrChild;
