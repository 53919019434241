/*
 * L'état du workflow
 */
export enum WorkflowStatus {
  None = 0,
  /*
   * A revenir à l'étape précédente
   */
  ToReview = 1,
  /*
   * A passer à l'étape suivante
   */
  ToForward = 2,
  /*
   * Workflow à annuler
   */
  ToCancel = 3,
  /*
   * Workflow à clôturer
   */
  ToClose = 4,
  /*
   * clôturé
   */
  Done = 5,
  /**
   * clôturé
   */
  Canceled = 6,
  /**
   * Workflow bloqué
   */
  Blocked = 99,
}
