import { AllFieldValueTypes } from '90.quickConnect.Models/models';

const convertToBooleanValue = (value: AllFieldValueTypes): boolean | undefined => {
  if (typeof value === 'boolean') return value as boolean;
  if (typeof value === 'string') {
    const stringValue = value as string;
    if (isNaN(+stringValue)) {
      if (['False', 'false'].includes(stringValue)) return false;
      if (stringValue === '') return false;
      if (['True', 'true'].includes(stringValue)) return true;
      if (stringValue === 'TrueOnDevice') return true;
    } else return +stringValue === 1 ? true : false;
  }
  if (typeof value === 'number') return (value as number) === 1 ? true : false;
  return undefined;
};

export default convertToBooleanValue;
