import { RGPDConsents } from '30.quickConnect.Stores/RootStore/DeclarationStore/Payloads/requests';
import { ConsentData } from '90.quickConnect.Models/models';

const mapConsentToRGPDConsents = (consent: ConsentData): RGPDConsents => ({
  acceptedAt: consent.acceptedAt ?? new Date(),
  formName: consent.formName,
  formId: consent.formId,
  fieldFullId: consent.fieldFullId,
  userUPN: consent.userUPN,
  accepted: consent.accepted,
});

export default mapConsentToRGPDConsents;
