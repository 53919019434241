import { NotificationTarget } from '90.quickConnect.Models/models';
import { isRecordWithProperty } from '90.quickConnect.Models/guards';

export default function isNotificationTarget(value: unknown): value is NotificationTarget {
  return (
    typeof value === 'object' &&
    value !== null &&
    isRecordWithProperty(value, 'target', 'string') &&
    'alias' in value &&
    (typeof value.alias === 'string' || value.alias === null || value.alias === undefined)
  );
}
