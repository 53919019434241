import mapBaseConditionalOperator from './mapBaseConditionalOperator';
import { BaseQCOperator } from '90.quickConnect.Models/models';

const mapVisibility = (
  visibility: string | boolean | BaseQCOperator | undefined,
): string | boolean | BaseQCOperator | undefined => {
  if (visibility === 'False' || visibility === false) return false;
  if (visibility === 'True' || visibility === 'FalseOnDevice' || visibility === 'TrueOnDevice' || visibility === true)
    return true;
  else if (typeof visibility === 'string') {
    const visiblityParsed = JSON.parse(visibility as string);
    return mapBaseConditionalOperator(visiblityParsed);
  } else if (typeof visibility === 'object') {
    return mapBaseConditionalOperator(visibility);
  } else return true;
};

export default mapVisibility;
