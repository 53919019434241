import { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { UseDataQcFieldPropsBase } from '50.quickConnect.Fields/FieldsTypes/types';

export const NumericMinValue = 0;
export const NumericMaxValue = 100;

export type UseDataProps = UseDataQcFieldPropsBase & {
  localValue: number | string;
  setLocalValue: Dispatch<SetStateAction<number | string>>;
  handleChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleClear: (event: React.MouseEvent<HTMLButtonElement>) => void;
  minValue: number;
  maxValue: number;
};

export type AvailableNumberValues = {
  label: string;
  value: number | undefined;
};
