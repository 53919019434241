import {
  alpha,
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
} from '@mui/material';
import React, { Dispatch, FunctionComponent } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Pack, PackStatus, WorkSiteAct, WorkSiteActType, WorkSiteProps } from '../types';

type PackListProps = {
  packs: WorkSiteProps['standardPacks'] | WorkSiteProps['otherPacks'];
  setWorkSite: Dispatch<WorkSiteAct>;
};

const TableRowWithHover = styled(TableRow)(({ theme }) => {
  console.log('theme.palette.action.hover', theme.palette.action);
  return {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
    },
    '&:last-child td, &:last-child th': { border: 0 },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.hover,
    },
  };
});

const BorderedTable = styled('div')(({ theme }) => {
  // Same colors as Mui borders, I can't get them otherwise
  // https://github.com/mui/material-ui/issues/35421
  const borderColors = theme.palette.mode === 'light' ? 'rgba(232, 233, 232, 1)' : 'rgba(255, 255, 255, 0.23)';
  return {
    border: '1px solid',
    borderColor: borderColors,
    borderRadius: `${theme.shape.borderRadius}px`,
  };
});

const PackList: FunctionComponent<PackListProps> = (props: PackListProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('moduleWorkSite');

  const getStatusBubble = (pack: Pack) => {
    if (!pack.selected) {
      return '';
    }
    const { status } = pack;
    const bubbleSize = '10px';
    let color;
    switch (status) {
      case PackStatus.COMPLETED:
        color = theme.palette.success.main;
        break;
      case PackStatus.TOCOMPLETE:
        color = theme.palette.warning.main;
        break;
      case PackStatus.SYNCING:
      case PackStatus.WAITING:
      default:
        color = theme.palette.grey['500'];
    }
    return <Box width={bubbleSize} height={bubbleSize} borderRadius={'50%'} bgcolor={color}></Box>;
  };

  const getStatusText = (pack: Pack) => {
    if (!pack.selected) {
      return '';
    }
    const { status } = pack;
    switch (status) {
      case PackStatus.COMPLETED:
        return t('work_site_summary_pack_status_completed');
      case PackStatus.TOCOMPLETE:
        return t('work_site_summary_pack_status_to_complete');
      case PackStatus.SYNCING:
        return t('work_site_summary_pack_status_syncing');
      case PackStatus.WAITING:
        return t('work_site_summary_pack_status_waiting');
      default:
        return '';
    }
  };

  if (!props.packs) {
    return <CircularProgress />;
  }

  function togglePack(e: React.ChangeEvent<HTMLInputElement>, pack: Pack) {
    e.stopPropagation();
    props.setWorkSite({
      type: WorkSiteActType.SELECTPACK,
      value: {
        id: pack.id,
        selected: !pack.selected,
      },
    });
  }

  return (
    <BorderedTable data-cy="PackList">
      <Table size={'small'}>
        <TableBody>
          {props.packs.map((pack) => {
            return (
              <TableRowWithHover
                style={{ cursor: pack.selected ? 'pointer' : 'auto' }}
                key={pack.id}
                onClick={() => {
                  if (pack.selected) {
                    navigate(`./${pack.id}`);
                  }
                }}
                selected={pack.selected}
              >
                <TableCell>
                  <Checkbox
                    checked={pack.selected}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => togglePack(e, pack)}
                  />
                </TableCell>
                <TableCell>{pack.name}</TableCell>
                <TableCell>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    {getStatusBubble(pack)}
                    {getStatusText(pack)}
                  </Stack>
                </TableCell>
                <TableCell>
                  {pack.nbForm} {t('work_site_summary_pack_form')}
                </TableCell>
                <TableCell>{pack.selected && <EditIcon />}</TableCell>
              </TableRowWithHover>
            );
          })}
        </TableBody>
      </Table>
    </BorderedTable>
  );
};

export default PackList;
