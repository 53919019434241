import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { useStore } from '30.quickConnect.Stores';
import { DeclarationViewer } from '90.quickConnect.Models/models';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    DeclarationStore: {
      DraftsStore: { items: drafts, filterThis: filterDraft },
      HistoryStore: { items: declarations, filterThis: filterHistory },
    },
  } = useStore();
  const { t } = useTranslation('declaration');

  const [currentDeclarations, setDeclarations] = useState<DeclarationViewer[]>(declarations ?? []);
  const [currentDrafts, setDrafts] = useState<DeclarationViewer[]>(drafts ?? []);

  useEffect(() => {
    const filteredDeclarations = filterHistory(declarations ?? [], (i: DeclarationViewer) => i.name);
    setDeclarations(filteredDeclarations);
    const filteredDrafts = filterDraft(drafts ?? [], (i: DeclarationViewer) => i.name);
    setDrafts(filteredDrafts);
  }, [declarations, drafts, filterDraft, filterHistory]);

  const sortFn = (a: TreeDataItem, b: TreeDataItem) => {
    return (b as DeclarationViewer)?.editedAt && (a as DeclarationViewer)?.editedAt
      ? new Date((a as DeclarationViewer).editedAt).getTime() - new Date((a as DeclarationViewer).editedAt).getTime()
      : -1;
  };

  return {
    declarations: currentDeclarations,
    drafts: currentDrafts,
    t,
    sortFn,
  };
};
