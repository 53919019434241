import React from 'react';
import { observer } from 'mobx-react';
import { FormControl, CircularProgress, Typography } from '@mui/material';
import useData from './hooks';
import { HierarchicalListRightContentProps } from './types';
import ParentBreadCrumbs from './ParentBreadCrumbs';
import VirtualizedHierarchicalList from './VirtualizedHierarchicalList';
import SmallHierarchicalList from './SmallHierarchicalList';
import HierarchicalSearchBar from './HierarchicalSearchBar';
import { useElementSize, useIsMobile } from '80.quickConnect.Core/hooks';

const HierarchicalListRightContent = ({
  hierarchicalDesc,
  updateDeclaration,
  labelBox,
  hierarchicalChoices,
  deletedItem,
  setDeleteItem,
  isDeleteAll,
  setIsDeleteAll,
}: HierarchicalListRightContentProps) => {
  const { id, multiSelection, isSearchable } = hierarchicalDesc;
  const { t, onClickHandle, goUp, filteredChoices, parents, localValue, nameWithId, setFilterList } = useData(
    hierarchicalDesc,
    updateDeclaration,
    hierarchicalChoices,
    deletedItem,
    setDeleteItem,
    isDeleteAll,
    setIsDeleteAll,
  );

  const isMobile = useIsMobile();

  const [squareRef, { width, height }] = useElementSize();

  return (
    <FormControl
      ref={squareRef}
      data-cy={nameWithId}
      sx={{
        width: isMobile ? window.innerWidth : '100%',
        height: isMobile ? window.innerHeight : '90%',
        margin: 'auto',
        overflowX: 'hidden',
        padding: 1,
      }}
    >
      {labelBox}
      {isSearchable && <HierarchicalSearchBar parentId={id} setFilterList={setFilterList} />}
      {parents && <ParentBreadCrumbs goUp={goUp} parents={parents} parentId={id} />}
      {filteredChoices && filteredChoices.length === 0 && (
        <Typography sx={{ marginTop: '10px' }}>{t('no_elements').toString()}</Typography>
      )}
      {filteredChoices && filteredChoices.length > 200 && width && height && (
        <VirtualizedHierarchicalList
          parentId={id}
          filteredChoices={filteredChoices}
          onClickHandle={onClickHandle}
          localValue={localValue}
          width={width}
          height={height}
          multiSelection={multiSelection}
        />
      )}
      {filteredChoices && filteredChoices.length <= 200 && (
        <SmallHierarchicalList
          parentId={id}
          filteredChoices={filteredChoices}
          onClickHandle={onClickHandle}
          localValue={localValue}
          multiSelection={multiSelection}
        />
      )}
      {filteredChoices === undefined && <CircularProgress />}
    </FormControl>
  );
};
export default observer(HierarchicalListRightContent);
