import { format, parseISO } from 'date-fns';
import { DateTimeFieldType } from '90.quickConnect.Models/enums';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';

export const dateFormat = "yyyy-MM-dd'T'00:00:00.000+00:00";
export const dateFormatWithZeroMillisecond = "yyyy-MM-dd'T'HH:mm:00.000XXXXX";
export const dateFormatWithMillisecond = "yyyy-MM-dd'T'HH:mm:ss.SSSXXXXX";
export const dateFormatInUTCOffset = "yyyy-MM-dd'T'HH:mm:ss.SSS+00:00";
const getDateWithFormat = (date: DateTimeExtension, fieldType: string | undefined): string => {
  try {
    const reformatted = date.toISOString();
    if (!fieldType || fieldType === DateTimeFieldType.Date) {
      return format(parseISO(reformatted), dateFormat);
    }
    return format(parseISO(reformatted), dateFormatWithMillisecond);
    // }
  } catch (error) {
    return '';
  }
};

export default getDateWithFormat;
