import React, { useRef } from 'react';
import { Box, useTheme } from '@mui/material';
import { observer } from 'mobx-react';

import CustomList from '../../Home/Shared/CustomList';
import DeclarationLine from '../../Home/Declarations/DeclarationLine';
import { ListColumnForCustomListContainer } from '../../Home/styles';
import { useStore } from '30.quickConnect.Stores';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { DeclarationViewer } from '90.quickConnect.Models/models';

const HistoricalData = () => {
  const {
    EquipmentsStore: {
      historicalDataViewer: { items: declarations },
    },
  } = useStore();

  const sortFn = (a: TreeDataItem, b: TreeDataItem) => {
    return (b as DeclarationViewer)?.editedAt && (a as DeclarationViewer)?.editedAt
      ? new Date((b as DeclarationViewer).editedAt).getTime() - new Date((a as DeclarationViewer).editedAt).getTime()
      : -1;
  };
  const theme = useTheme();
  const scrollTarget = useRef<HTMLElement>();
  return (
    <Box data-cy={'oldDeclarationName'} sx={ListColumnForCustomListContainer(theme)} ref={scrollTarget}>
      {<CustomList items={declarations} RenderLine={DeclarationLine} sortFn={sortFn} />}
    </Box>
  );
};
export default observer(HistoricalData);
