import { QCScriptFunctionInfo } from './QCScriptFunctionInfo';

/// Contient les éléments pour l'interprétation d'un script sur les mobiles
/// Est serialisée dans les formulaires et modeles
///     - Table des fonctions appelable par l'interpréteur
///     - Pseudo code IL
///     - Table des constantes string, Double, Long, Int

///     - Adresse de fin du code d'initialisation ( zero si pas de code d'init )
export class QCScriptObj {
  /// Nom du sript
  public scriptName = '';

  /// Le numéro de version du compilateur ayant généré cette instance
  public versionCode = -1;

  /// Table des fonctions appelables par QuickConnect
  public functions: Array<QCScriptFunctionInfo> = [];

  /// Taille du PCode
  public pCodeSize = 0;

  /// Le pseudo code du module
  public pCode: Uint8Array = new Uint8Array();

  /// Adresse de fin du code d'initialisation si présent, sinon 0
  public endInitPCode = 0;

  /// La table des chaines constantes
  public constString: Array<string> = [];

  /// La table des doubles constants
  public constDouble: Array<number> = [];

  /// La table des Long constants
  public constLong: Array<bigint> = [];

  /// La table des int constants
  public constInt: Array<number> = [];

  /// Pour debug, le dump du pcode généré
  public pCodeDump = '';

  constructor(init: Partial<QCScriptObj>) {
    Object.assign(this, init);
  }
}
