import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab, Box } from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SendCommandName } from './const';
import { SendCommandProps } from './types';
import useData from './hooks';
import { default as useScrollTo } from '10.quickConnect.app/components/shared/ScrollTo/hooks';

const SendCommand = ({
  canSubmit,
  handleItemDatas,
  sendDeclaration,
  setDeclarationData,
  context,
  flattenFields,
}: SendCommandProps) => {
  const { t, onPublish, requiredField, scrollTarget } = useData(
    handleItemDatas,
    sendDeclaration,
    setDeclarationData,
    context,
    flattenFields,
  );
  const { handleClick } = useScrollTo(requiredField?.fullPathId ?? '', scrollTarget?.current);

  return (
    <Tooltip title={t('formlib_action_button_validate').toString()} placement="right">
      <span>
        <Box color="success" onClick={requiredField ? handleClick : onPublish}>
          <Fab sx={{ marginTop: '10px' }} data-cy={`${SendCommandName}-btn-send`} color="primary" size="large">
            <PlayArrowIcon />
          </Fab>
        </Box>
      </span>
    </Tooltip>
  );
};

export default observer(SendCommand);
