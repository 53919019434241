import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, List, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { SignOutDialogProps } from '10.quickConnect.app/components/shared/Dialogs/SignOutDialog/types';
import { SignOutDialogName } from '10.quickConnect.app/components/shared/Dialogs/SignOutDialog/const';
import DialogChoice from '10.quickConnect.app/components/shared/Dialogs/DialogChoice';
import { DialogChoiceName } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/const';

const SignOutDialog = ({ open, onClose, confirmSignOutAction }: SignOutDialogProps) => {
  const { t } = useTranslation(['declaration', 'forms']);
  const theme = useTheme();

  return (
    <DialogChoice name={SignOutDialogName} open={open} onClose={onClose}>
      <DialogChoice.Title name={SignOutDialogName}>
        {t('qcapp_alert_dialog_save_form_title').toString()}
      </DialogChoice.Title>
      <DialogChoice.Content name={SignOutDialogName}>
        <DialogChoice.ContentText name={SignOutDialogName}>
          {t('qcapp_sign_out_dialog_info')}
          <List>
            <DialogChoice.Option
              name={`${SignOutDialogName}-validate-option`}
              optionTitle={t('qcapp_sign_out_dialog_validate_action').toString()}
              handleClick={confirmSignOutAction}
              avatar={<CheckIcon />}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
            <DialogChoice.Option
              name={`${SignOutDialogName}-cancel-option`}
              optionTitle={t('qcapp_sign_out_dialog_cancel_action').toString()}
              handleClick={onClose}
              avatar={<ClearIcon />}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
          </List>
        </DialogChoice.ContentText>
      </DialogChoice.Content>
      <DialogChoice.Actions name={SignOutDialogName}>
        <Button data-cy={`${DialogChoiceName}-${SignOutDialogName}-close`} onClick={onClose} variant="contained">
          {t('qcapp_dialog_item_data_close', { ns: 'forms' }).toString()}{' '}
        </Button>
      </DialogChoice.Actions>
    </DialogChoice>
  );
};

export default observer(SignOutDialog);
