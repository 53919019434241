import { SxProps, Theme } from '@mui/material';
import { LeftMenuWidth, LargeLeftMenuWidth } from '10.quickConnect.app/components/shared/LayOut/WithMenu/styles';

export const CommandsContainerStyle = (theme: Theme, showLeftMenu: boolean): SxProps => ({
  position: 'fixed',
  bottom: '15px',
  right: '30px',
  [theme.breakpoints.down('md')]: {
    right: '35px',
  },
  // [theme.breakpoints.up('lg')]: {
  //   left: showLeftMenu ? `${LargeLeftMenuWidth + 15}px` : '15px',
  // },
  // [theme.breakpoints.between('md', 'lg')]: {
  //   left: showLeftMenu ? `${LeftMenuWidth + 15}px` : '15px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   left: showLeftMenu ? `calc(100% + 15px)` : '15px',
  // },
});
