import { BaseAttachmentDesc } from '90.quickConnect.Models/models';

const mapBaseAttachmentDesc = (field: BaseAttachmentDesc): BaseAttachmentDesc =>
  ({
    //photoSize: number | undefined;
    maximumSize: field.maximumSize ?? 20,
    source: field.source,
    thumbnail: field.thumbnail,
    readonly: field.readonly,
    maxAttachment: field.maxAttachment ?? 16,
    contentType: field.contentType,
    fileName: field.fileName,
    title: field.title,
    size: field.size,
    createDate: field.createDate,
    purgingDate: field.purgingDate,
    //metas:
    //metasById:
    value: field.value,
  } as unknown as BaseAttachmentDesc);

export default mapBaseAttachmentDesc;
