import { createContext, useContext } from 'react';
import { QCSFunctions, QCUpdatePropFunction } from '20.formLib/DeclarationContainer/contexts/types';
import { errorHandler } from '80.quickConnect.Core/helpers';

// Tag
const tag = '20.formLib/DeclarationContainer/contexts/index.tsx';

const QCSFunctionsContext = createContext<QCSFunctions | undefined>(undefined);

const QCUpdatePropertyContext = createContext<QCUpdatePropFunction | undefined>(undefined);

export const useQCUpdateFunctionsContext = (): QCUpdatePropFunction | never => {
  const context = useContext(QCUpdatePropertyContext);

  if (!context) {
    const error = new Error('a context of type "QCUpdatePropFunction" must be provided');

    errorHandler(tag, error, 'useQCUpdatePropertyContext');
    throw error;
  }

  return context;
};

export const useQCSFunctions = (): QCSFunctions | never => {
  const context = useContext(QCSFunctionsContext);

  if (!context) {
    const error = new Error(`a context must be provided`);

    errorHandler(tag, error, 'useQCSFunctions');

    throw error;
  }

  return context;
};

export const QCSFunctionsProvider = QCSFunctionsContext.Provider;

export const QCUpdateFunctionsProvider = QCUpdatePropertyContext.Provider;
