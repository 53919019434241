import { SxProps } from '@mui/system';

export const MainBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '5%',
  flexDirection: 'column',
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'url(assets/login_background.png)',
  height: '100vh',
  width: '100%',
};

export const CardStyle: SxProps = {
  maxWidth: 500,
};
