import React, { useCallback, useMemo } from 'react';
import { Badge, BottomNavigation, BottomNavigationAction, Box, Tab, Paper } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import { HomeName } from './const';
import { CustomTabs } from './styles';
import useData from './hooks';
import { MAIN_PAGE_SUBROUTES } from '10.quickConnect.app/routes/routes';
import TopBar from '10.quickConnect.app/components/shared/LayOut/TopBar';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { HomeTabs } from '90.quickConnect.Models/enums';
import SendingAttachmentsDialog from '10.quickConnect.app/components/shared/Dialogs/SendingAttachmentsDialog';

const Home = () => {
  const { t } = useTranslation('common');
  const { handleTabChange, valueTabIndex, inboxesTotalNumber, sendAPI } = useData();
  const isMobile = useIsMobile();

  const getLabel = useCallback(
    (key: string) => {
      const translatedString = t(key).toString();
      if (isMobile) {
        return translatedString;
      }
      if (key === 'mainMenu_pages_inboxes' && inboxesTotalNumber && inboxesTotalNumber > 0)
        return (
          <Badge badgeContent={inboxesTotalNumber} color="primary">
            <span>{translatedString}</span>
          </Badge>
        );
      return <span>{translatedString}</span>;
    },
    [inboxesTotalNumber, isMobile, t],
  );

  const getIconBottomNavigation = useCallback(
    (key: string) => {
      switch (key) {
        case 'qrCode':
          return <ConstructionIcon />;
        case 'mainMenu_pages_forms':
          return <NoteAltIcon />;
        case 'mainMenu_pages_declarations':
          return <SaveIcon />;
        case 'mainMenu_pages_inboxes':
          const badgeContent = <EmailIcon />;
          return inboxesTotalNumber > 0 ? (
            <Badge badgeContent={inboxesTotalNumber} color="primary">
              {badgeContent}
            </Badge>
          ) : (
            badgeContent
          );
        default:
          return <span>{'tab unknown'}</span>;
      }
    },
    [inboxesTotalNumber],
  );

  // Barre de navigation
  const navigationBarStyle = useMemo(
    (): JSX.Element =>
      !isMobile ? (
        <CustomTabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="inherit"
          value={valueTabIndex}
          onChange={(event: React.SyntheticEvent, newValue: HomeTabs) => {
            event.preventDefault();
            handleTabChange(newValue);
          }}
          centered={isMobile}
          scrollButtons={isMobile}
          id={`${HomeName}-Tabs`}
        >
          {MAIN_PAGE_SUBROUTES.map(({ key, homeTab }) => (
            <Tab key={key} data-cy={`${HomeName}-${homeTab}`} label={getLabel(key)} value={homeTab} wrapped />
          ))}
        </CustomTabs>
      ) : (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={valueTabIndex}
            onChange={(event: React.SyntheticEvent, newValue: HomeTabs) => {
              return handleTabChange(newValue);
            }}
          >
            {MAIN_PAGE_SUBROUTES.map(({ key, homeTab }) => (
              <BottomNavigationAction
                key={key}
                data-cy={`${HomeName}-${homeTab}`}
                label={getLabel(key)}
                value={homeTab}
                icon={getIconBottomNavigation(key)}
              />
            ))}
          </BottomNavigation>
        </Paper>
      ),
    [isMobile, getIconBottomNavigation, getLabel, handleTabChange, valueTabIndex],
  );

  return (
    <Box data-cy={HomeName} sx={{ display: 'flex', flexDirection: 'column' }}>
      <TopBar
        pageTitle=""
        leftChildren={!isMobile && navigationBarStyle}
        // centerChildren={<C2SLogo src="/assets/c2s-bouygues-logo.png" />}
      />
      <Outlet />
      {isMobile && navigationBarStyle}

      {/* On ajoute la dialog concernant la prise en compte des pièces jointes */}
      {sendAPI && <SendingAttachmentsDialog />}
    </Box>
  );
};
export default observer(Home);
