import { TFunction } from 'i18next';
import { evalComparisonLogic } from './evalComparisonLogic';
import evaluateComputedRule from './evaluateComputedRule';
import { QcOperator, OperatorResult } from '90.quickConnect.Models/enums';
import {
  IIfOperator,
  DualOperator,
  Dependency,
  NamedUserParameterValue,
  AllFieldValueTypes,
  BaseQCOperator,
} from '90.quickConnect.Models/models';
export const evalConditionalValue = (
  t: TFunction,
  dualOperator: IIfOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): [AllFieldValueTypes, OperatorResult] => {
  switch (dualOperator.type) {
    case QcOperator.IIf:
      let conditionnalResultValidator = false;
      const {
        condition: { conditionalOpeType, args },
      } = dualOperator;
      if (conditionalOpeType === QcOperator.And) {
        conditionnalResultValidator = args.every((arg: BaseQCOperator) =>
          evalComparisonLogic(t, arg as DualOperator, dependencies, userParams, scopeFullPathId, userUpn, userEmail),
        );
      } else if (conditionalOpeType === QcOperator.Or) {
        conditionnalResultValidator = args.some((arg: BaseQCOperator) =>
          evalComparisonLogic(t, arg as DualOperator, dependencies, userParams, scopeFullPathId, userUpn, userEmail),
        );
      }
      if (conditionnalResultValidator) {
        return evaluateComputedRule(
          t,
          dualOperator.argIfTrue,
          dependencies,
          userParams,
          scopeFullPathId,
          userUpn,
          userEmail,
        );
      }
      return evaluateComputedRule(
        t,
        dualOperator.argIfFalse,
        dependencies,
        userParams,
        scopeFullPathId,
        userUpn,
        userEmail,
      );
  }
  return [undefined, OperatorResult.ERROR];
};
