import React, { useRef } from 'react';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Collapse, useTheme, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import { useTranslation } from 'react-i18next';
import { ListColumnForCustomListContainer } from '../../styles';
import useData from './hooks';
import DeclarationItemHistory from './DeclarationItemHistory';
import WithMenu from '10.quickConnect.app/components/shared/LayOut/WithMenu';

const DeclarationHistory = () => {
  const { forms, detailsDeclaration, handleMoveTo, handleClick, open } = useData();
  const { t } = useTranslation('declaration');
  const theme = useTheme();
  const scrollTarget = useRef<HTMLElement>();
  return (
    <WithMenu data-cy={'Name'} pageTitle={t('qcapp_histo_edited_search_old')}>
      <Box data-cy={'oldDeclarationName'} sx={ListColumnForCustomListContainer(theme)} ref={scrollTarget}>
        {forms?.declarationCountByFormName?.map((item: any, index: number) => (
          <List
            key={index}
            sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={() => handleClick(item.formName)} key={index}>
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary={`${item.formName} (${item.count})`} />
              {open[item.formName] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[item.formName]} timeout="auto" unmountOnExit>
              {detailsDeclaration &&
              detailsDeclaration.declarationViewer &&
              detailsDeclaration.declarationViewer.length > 0 ? (
                detailsDeclaration.declarationViewer.map((i: any) => (
                  <DeclarationItemHistory data={i} handleMoveTo={handleMoveTo} theme={theme} cle={i.id} key={i.id} />
                ))
              ) : (
                <Typography>{}</Typography>
              )}
            </Collapse>
          </List>
        ))}
      </Box>
    </WithMenu>
  );
};
export default DeclarationHistory;
