/**
 * Les différents types d'Inbox Item
 */
export enum InboxItemType {
  /**
   * Pas défini : erreur
   */
  Unknow = 0,
  /**
   * Formulaire prérempli
   */
  PrefilledForm = 1,

  /**
   * tâche à faire (Todo)
   */
  Task = 2,

  /// <summary>
  /// Formulaire prérempli transféré
  /// </summary>
  TransferredPrefilledForm = 3,
}
