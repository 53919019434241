import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ForgottenPasswordAvailableMethod,
  ForgottenPasswordMethodEnum,
  ForgottenPasswordAvailableMethodsEnum,
} from '../../types';
import { ForgottenPasswordResponse } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';

interface ResetMethodProps {
  availableMethods: ForgottenPasswordResponse | undefined;
  setMethod: React.Dispatch<React.SetStateAction<ForgottenPasswordMethodEnum | undefined>>;
  method: ForgottenPasswordMethodEnum | undefined;
}

const ResetMethod = ({ availableMethods, method, setMethod }: ResetMethodProps) => {
  const { t } = useTranslation('login');
  const recoveryMethods: ForgottenPasswordAvailableMethod[] = [
    {
      label: t('qcapp_by_sms'),

      value: ForgottenPasswordAvailableMethodsEnum.Sms,
    },

    {
      label: t('qcapp_by_email'),

      value: ForgottenPasswordAvailableMethodsEnum.Email,
    },

    {
      label: t('qcapp_by_administrator'),

      value: ForgottenPasswordAvailableMethodsEnum.Administrator,
    },
  ];
  return (
    <FormControl fullWidth>
      <InputLabel id="simple-select-label">{`${t('forget_password.recovery_methode')}`}</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        label="Recovery method"
        onChange={(e: any) => {
          setMethod(e.target.value);
        }}
        value={method ?? ''}
      >
        {availableMethods &&
          recoveryMethods
            .filter((f) => Boolean(f.value & availableMethods?.availableMethods))
            .map((v) => (
              <MenuItem key={v.value} value={v.value}>
                {v.label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};
export default ResetMethod;
