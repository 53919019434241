import { isAddressData } from '90.quickConnect.Models/guards';
import { AddressData } from '90.quickConnect.Models/models';

export function mapToAddressDataFromString(address: string): AddressData | undefined {
  if (address.includes('¤')) {
    const [streetNumber, street, complement, zipCode, city, country] = address.split('¤');
    const addressObj: AddressData = {
      streetNumber: streetNumber.trim(),
      street: street.trim(),
      complement: complement.trim(),
      zipCode: zipCode.trim(),
      city: city.trim(),
      country: country.trim(),
      coordinates: undefined,
    };
    if (!isAddressData(addressObj)) return undefined;
    return addressObj;
  }
}

export function mapAllFieldTypeToAddressData(value: unknown): AddressData | undefined {
  if (isAddressData(value)) return value;
  else if (typeof value === 'string') return mapToAddressDataFromString(value.trim());
}
