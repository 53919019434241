import React from 'react';
import { observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { DeclarationsListsName } from './const';
import { DeclarationsListsProps } from './types';
import { useData } from './hooks';
import CustomList from '10.quickConnect.app/components/domain/Home/Shared/CustomList';
import DeclarationLine from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationLine';
import { ListColumnOrRowContainer } from '10.quickConnect.app/components/domain/Home/styles';

// eslint-disable-next-line no-empty-pattern
const DeclarationsLists = ({}: DeclarationsListsProps) => {
  const theme = useTheme();
  const { declarations, drafts, t, sortFn } = useData();
  return (
    <Box data-cy={DeclarationsListsName} sx={ListColumnOrRowContainer(theme)}>
      <CustomList
        title={t('declaration_drafts').toString()}
        items={drafts}
        RenderLine={DeclarationLine}
        sortFn={sortFn}
      />
      <CustomList
        title={t('declarations').toString()}
        items={declarations}
        RenderLine={DeclarationLine}
        sortFn={sortFn}
      />
    </Box>
  );
};
export default observer(DeclarationsLists);
