import { TFunction } from 'i18next';
import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { convertToBooleanValue } from '80.quickConnect.Core/helpers';

export const operationWithBooleans = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  t: TFunction,
): [AllFieldValueTypes, OperatorResult] => {
  const ref1ValueBool = convertToBooleanValue(ref1Value);
  const ref2ValueBool = convertToBooleanValue(ref2Value);
  if (ref1ValueBool !== undefined && ref2ValueBool !== undefined) {
    switch (type) {
      case QcOperator.Add:
        return [ref1ValueBool && ref2ValueBool, OperatorResult.BOOLEAN];
      case QcOperator.Subst:
        return [ref1ValueBool || ref2ValueBool, OperatorResult.BOOLEAN];
      case QcOperator.Mult:
        return [ref1ValueBool && ref2ValueBool, OperatorResult.BOOLEAN];
      case QcOperator.Div:
        return [ref1ValueBool || ref2ValueBool, OperatorResult.BOOLEAN];
      default:
        return [t('computed_fields_errors_operator_type_unknown').toString(), OperatorResult.ERROR];
    }
  } else {
    return [t('computed_fields_errors_eval_error').toString(), OperatorResult.ERROR];
  }
};
