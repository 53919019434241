import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ChevronRight = ({ title, nameWithId, color }: any) => {
  return (
    <Tooltip title={title} sx={{ ml: 1 }}>
      <IconButton data-cy={`${nameWithId}`} color={color}>
        <ChevronRightIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ChevronRight;
