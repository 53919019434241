import { QCDocument } from '90.quickConnect.Models/models';

const mapQCDocument = (document: QCDocument): QCDocument =>
  ({
    id: document.id,
    documentId: document.documentId,
    schemaId: document.schemaId,
    instanceId: document.instanceId,
    formId: document.formId,
    declarationId: document.declarationId,
    storageUri: document.storageUri,
    name: document.name,
    title: document.title,
    lastModified: document.lastModified ? new Date(document.lastModified) : undefined,
    size: document.size,
    contentType: document.contentType,
    state: document.state,
  } as QCDocument);

export default mapQCDocument;
