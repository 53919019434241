import React from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import useData from '../../DeclarationLine/DeclarationLineMenuOnTap/hooks';
import { getDeclarationIcon } from '../../../helpers';
import { getListItemAvatarStyle } from '../../../styles';
import { DeclarationItemHistoryProps } from '../types';
import { DeclarationItemHistoryName } from './const';
import { getAvatarStyle } from './styles';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { getDuplicateIconStyle } from '10.quickConnect.app/components/domain/Home/styles';
import { DeclarationViewer } from '90.quickConnect.Models/models';

const DeclarationItemHistory = (declaration: DeclarationItemHistoryProps) => {
  const { t } = useTranslation('declaration');
  const { handleMoveTo, theme, data, cle } = declaration;
  const mapDecDesc = (): DeclarationViewer => {
    return {
      id: data.id,
      name: '',
      editedAt: data.editedAt,
      formId: data.id,
      formType: data.formType,
      folderPath: null,
      stringExtract: data.stringExtract,
      isDraft: data.isDraft,
      isLocal: data.isLocal,
    } as unknown as DeclarationViewer;
  };
  const {
    openContextMenu,
    contextMenu,
    contextMenuClose,
    openInNewTab,
    handleDuplicate,
    isMobile,
    isHover,
    setIsHover,
  } = useData(mapDecDesc() as unknown as TreeDataItem);
  return (
    <List
      component="div"
      disablePadding
      key={cle}
      onClick={() => handleMoveTo(data.stringExtract, data.id)}
      data-cy={DeclarationItemHistoryName}
    >
      <ListItem>
        <ListItemButton
          data-cy={`${DeclarationItemHistoryName}-${cle}`}
          sx={{ pl: 4 }}
          onContextMenu={openContextMenu}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => handleMoveTo(data.stringExtract, data.id)}
        >
          <ListItemAvatar sx={getListItemAvatarStyle(theme)}>
            <Avatar sx={getAvatarStyle(theme)}>
              {getDeclarationIcon(data.formType, data?.isDraft, data?.isLocal)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={data.stringExtract} secondary={new Date(data.editedAt).toLocaleString()} />
          {(isMobile || isHover) && (
            <ListItemSecondaryAction onClick={(e) => handleDuplicate(e)}>
              <Tooltip title={t('duplicate_declaration').toString()}>
                <ListItemIcon sx={getDuplicateIconStyle()}>
                  <ContentCopy />
                </ListItemIcon>
              </Tooltip>
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
        <Menu
          open={contextMenu !== null}
          onClose={contextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          MenuListProps={{
            onMouseLeave: contextMenuClose,
          }}
        >
          <MenuItem onClick={openInNewTab}>{t('contextual_menu_open_in_new_tab')}</MenuItem>
          <MenuItem onClick={(e) => handleDuplicate(e)}>{t('duplicate_declaration')}</MenuItem>
          {data.isLocal && <MenuItem>{t('contextual_menu_remove_local')}</MenuItem>}
        </Menu>
      </ListItem>
    </List>
  );
};
export default DeclarationItemHistory;
