import React from 'react';
import { Box } from '@mui/material';

import { BallStyle } from '../../styles';
import useData from './hooks';

const Ball = () => {
  const dimensions = useData();

  return (
    <Box sx={BallStyle}>
      <svg {...dimensions} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 138 139">
        <mask
          id="mask0_696_17937"
          style={{ maskType: 'alpha' }}
          width="138"
          height="139"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <circle cx="68.797" cy="69.297" r="68.797" fill="#C4C4C4"></circle>
        </mask>
        <g stroke="#fff" strokeWidth="8.95" mask="url(#mask0_696_17937)">
          <path d="M-14.842 61.698L78.705 -31.85"></path>
          <path d="M13.074 127.676L125.651 15.098"></path>
          <path d="M42.695 160.783L155.273 48.205"></path>
          <path d="M-4.06 82.563L89.488 -10.985"></path>
          <path d="M31.363 141.036L136.436 35.964"></path>
          <path d="M60.985 174.143L166.057 69.07"></path>
          <path d="M6.373 103.777L110.611 -0.461"></path>
        </g>
      </svg>
    </Box>
  );
};

export default Ball;
