import getCtrlRef from './getCtrlRef';
import getConstValue from './getConstValue';
import getCtrlUserParameterRef from './getCtrlUserParameterRef';
import { BaseQCOperator, Dependency, NamedUserParameterValue, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { QcOperator, OperatorResult } from '90.quickConnect.Models/enums';

const getValue = (
  baseQCOperator: BaseQCOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
): [AllFieldValueTypes, OperatorResult] => {
  switch (baseQCOperator.type) {
    case QcOperator.CtrlRef:
    case QcOperator.CtrlRefReceiver:
      return getCtrlRef(baseQCOperator, dependencies);
    case QcOperator.CtrlUserParameterRef:
      return getCtrlUserParameterRef(baseQCOperator, userParams);
    case QcOperator.ConstDouble:
    case QcOperator.ConstString:
      return getConstValue(baseQCOperator);
    default:
      return [undefined, OperatorResult.ERROR];
  }
};

export default getValue;
