import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Name } from './const';
import { MainBox } from './styles';
import { DeclarationProps } from './types';
import DeclarationViewSkeleton from './Skeletons/declarationViewSkeleton';
import WithMenu from '10.quickConnect.app/components/shared/LayOut/WithMenu';
import useData from '10.quickConnect.app/components/domain/Declaration/hooks';
import DeclarationContainer from '20.formLib/DeclarationContainer';
import { terraCotta400 } from '00.app/theme';
import { DeclarationProvider } from '10.quickConnect.app/components/domain/Declaration/context/declarationContext';

const Declaration = ({ isDuplicated, isDeepLink }: DeclarationProps) => {
  const { t } = useTranslation('declaration');
  const scrollTarget = useRef<HTMLElement>();
  const {
    context,
    entityData,
    declaration,
    flattenFields,
    declarationData,
    setDeclaration,
    setDeclarationData,
    currentActivityId,
    inboxId,
    declarationId,
    isEditable,
    isDeletedDeclarationContext,
  } = useData(isDuplicated, isDeepLink);
  return (
    <WithMenu
      data-cy={Name}
      pageTitle={context ? `${t('qcapp_declaration_title')} : ${context.name}` : t('qcapp_declaration_title')}
    >
      <Box sx={MainBox} ref={scrollTarget}>
        <div id={`scrollToAnchor-${Name}`} />
        {context && declaration ? (
          <DeclarationProvider value={declaration}>
            <DeclarationContainer
              context={context}
              entityData={entityData}
              declaration={declaration}
              flattenFields={flattenFields}
              declarationData={declarationData}
              setDeclarationData={setDeclarationData}
              setDeclaration={setDeclaration}
              activityId={currentActivityId}
              inboxId={inboxId}
              declarationId={declarationId}
              isEditable={isEditable}
            />
          </DeclarationProvider>
        ) : isDeletedDeclarationContext ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', color: `${terraCotta400}` }}>
            {t('qcapp_warning_error_loading_dcl')}
          </Box>
        ) : (
          <DeclarationViewSkeleton />
        )}
      </Box>
    </WithMenu>
  );
};
export default observer(Declaration);
