import React, { FunctionComponent, useContext } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { Outlet } from 'react-router';
import { ChevronLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import WorkSiteTopBar from '../../shared/TopBar/WorkSiteTopBar';
import useWorkSiteSummaryData, { WorkSiteSummaryProps } from './hookWorkSiteSummary';

const WorkSiteContext = React.createContext<Partial<WorkSiteSummaryProps>>({
  workSite: undefined,
  setWorkSite: undefined,
});

export const useWorkSiteContext = (): WorkSiteSummaryProps => {
  const { workSite, setWorkSite } = useContext(WorkSiteContext);
  if (workSite === undefined || setWorkSite === undefined) {
    throw new Error('WorkSiteContext must be defined');
  }
  return { workSite, setWorkSite };
};

const WorkSiteSummaryWrapper: FunctionComponent = () => {
  const { workSite, setWorkSite, backPage } = useWorkSiteSummaryData();
  const { t } = useTranslation('common');

  const backButton = () => {
    return (
      <Button variant={'text'} startIcon={<ChevronLeft />} onClick={backPage} color={'secondary'}>
        {t('back')}
      </Button>
    );
  };

  if (workSite === undefined) {
    return <CircularProgress />;
  }
  return (
    <Box data-cy="WorkSiteSummaryWrapper">
      <WorkSiteTopBar leftElement={backButton()} />
      <WorkSiteContext.Provider value={{ workSite: workSite, setWorkSite: setWorkSite }}>
        <Outlet />
      </WorkSiteContext.Provider>
    </Box>
  );
};

export default WorkSiteSummaryWrapper;
