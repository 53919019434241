import mapTranslation from '../translation/mapTranslation';
import mapTranslationStatus from '../translation/mapTranslationStatus';
import mapEntity from '../mapEntityBase';
import mapEntitySchemaAttribute from './mapEntitySchemaAttribute';
import { EntitySchema } from '90.quickConnect.Models/models';

const mapSchema = (entitySchema: EntitySchema): EntitySchema =>
  ({
    ...mapEntity(entitySchema),
    name: entitySchema.name ?? '',
    description: entitySchema.description ?? '',
    isSelectable: entitySchema.isSelectable ?? true,
    isSelected: entitySchema.isSelected ?? false,
    qrCodeType: entitySchema.qrCodeType ?? '',
    isCacheable: entitySchema.isCacheable ?? false,
    parentId: entitySchema.parentId ?? '',
    customerId: entitySchema.customerId ?? '',
    attributes: entitySchema.attributes ? entitySchema.attributes.map(mapEntitySchemaAttribute) : [],
    tableName: entitySchema.tableName ?? '',
    webhookIds: entitySchema.webhookIds ? entitySchema.webhookIds : [],
    languageStatus: entitySchema.languageStatus ? entitySchema.languageStatus.map(mapTranslationStatus) : [],
    defaultLanguage: entitySchema.defaultLanguage ?? '',
    entityId: entitySchema.entityId ?? '',
    entityType: entitySchema.entityType ?? '',
    fields: entitySchema.fields ?? '',
    translate: entitySchema.translate ? entitySchema.translate.map(mapTranslation) : [],
  } as EntitySchema);

export default mapSchema;
