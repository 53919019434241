import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CommandType } from '../types';
import { UseDataProps } from './types';
import { ItemData } from '90.quickConnect.Models/models';
import { FormType } from '90.quickConnect.Models/enums';
import { FORMS } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  formType: FormType,
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>,
  sendDeclaration: (newItemData: ItemData) => Promise<boolean>,
  setDeclarationData: Dispatch<SetStateAction<ItemData>>,
): UseDataProps => {
  const { t } = useTranslation('declaration');

  const {
    DeclarationStore: { openDialog },
  } = useStore();

  const navigate = useNavigate();

  const onPreviousWorkflow = useCallback(async () => {
    if (formType === FormType.Workflow) {
      const [goToNextStep, nextStepIndex, newItemData] = await handleItemDatas(CommandType.Previous);
      const declarationSend = await sendDeclaration(newItemData);
      if (declarationSend) {
        if (goToNextStep && nextStepIndex && newItemData && newItemData.Workflow) {
          newItemData.Workflow.currentStep = nextStepIndex;
          setDeclarationData(newItemData);
        } else navigate(FORMS, { replace: true });
      }
    } else {
      // On est sur un abandon de saisie
      openDialog();
    }
  }, [formType, handleItemDatas, navigate, sendDeclaration, setDeclarationData, openDialog]);

  return {
    t,
    onPreviousWorkflow,
  };
};

export default useData;
