import React from 'react';
import { observer } from 'mobx-react';
import { Box, List } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { useData } from './hooks';
import { FormTreeItemRendererName } from './const';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import CustomTreeViewItem from '10.quickConnect.app/components/shared/CustomTreeViewItem';
import FormLine from '10.quickConnect.app/components/domain/Home/Forms/FormLine';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';

const FormTreeItemRenderer = ({ id, name, items }: TreeViewData<TreeDataItem>) => {
  const { nodeChildren, formChildren, numberFormChildren } = useData(items);
  return (
    <Box data-cy={FormTreeItemRendererName}>
      <CustomTreeViewItem
        key={id}
        nodeId={id}
        labelText={name}
        labelIcon={<FolderIcon sx={{ mr: 1, fontSize: '32px' }} />}
        labelInfo={numberFormChildren > 0 ? numberFormChildren.toString() : undefined}
      >
        {nodeChildren.map((child) => (
          <FormTreeItemRenderer key={child.id} {...child} />
        ))}
        <List>
          {formChildren.map(({ id: childId, data }) => (
            <FormLine key={childId} {...data!} />
          ))}
        </List>
      </CustomTreeViewItem>
    </Box>
  );
};
export default observer(FormTreeItemRenderer);
