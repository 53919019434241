import Dexie, { Table } from 'dexie';
import {
  InboxeDraftSchema,
  InboxesFilteredSchema,
} from '40.quickConnect.DataAccess/indexedDb/dbs/queriesTypes/inboxes';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { IQuickConnectIndexedDbError } from '40.quickConnect.DataAccess/indexedDb/dbs/interfaces/iQuickConnectIndexedDbError';
import { errorHandler } from '80.quickConnect.Core/helpers';

class InboxesBd extends Dexie implements IQuickConnectIndexedDbError {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/inboxesDb.ts';

  inboxesFiltered!: Table<InboxesFilteredSchema>;

  inboxDraft!: Table<InboxeDraftSchema>;

  constructor() {
    super(new.target.name);
    this.version(5).stores({
      inboxesFiltered: '++userUPN, *activityIds, [userUPN+activityId]',
      inboxDraft: '++id, userUPN, inboxId, declarationId, deleted',
    });
  }

  async addOrUpdateInboxAsync(activityId: string, userUPN: string, sentAt?: Date): Promise<boolean> {
    try {
      return await this.transaction('rw', this.inboxesFiltered, async () => {
        const inboxForThisUserUPN = await this.inboxesFiltered.where({ userUPN, activityId }).first();

        const newInboxSchema = inboxForThisUserUPN
          ? {
              activityIds:
                inboxForThisUserUPN.activityIds.findIndex((activity: string) => activityId === activity) !== -1
                  ? [...inboxForThisUserUPN.activityIds, activityId]
                  : inboxForThisUserUPN.activityIds,
              userUPN,
              sentAt: sentAt ?? inboxForThisUserUPN.sentAt,
              purgeDate: DateTimeExtension.getTomorrowDate(),
            }
          : {
              activityIds: [activityId],
              userUPN,
              sentAt: sentAt ?? new Date(),
              purgeDate: DateTimeExtension.getTomorrowDate(),
            };

        return (await this.inboxesFiltered.put(newInboxSchema)) === userUPN;
      });
    } catch (error: unknown) {
      this.handleDbError(error, 'addOrUpdateInboxAsync');

      return false;
    }
  }

  async getActivityIdsAndSentAtDate(userUPN: string): Promise<{ activityIds: string[]; sentAt: Date } | undefined> {
    try {
      const inbox = await this.inboxesFiltered.where('userUPN').equalsIgnoreCase(userUPN).first();
      if (inbox) {
        return { activityIds: inbox.activityIds, sentAt: inbox.sentAt };
      }
    } catch (error: unknown) {
      this.handleDbError(error, 'getActivityIdsAndOriginalSentAtDate');
    }
    return undefined;
  }

  async purgeInboxesAsync(userUPN: string): Promise<boolean> {
    try {
      const inboxesDeleted = await this.inboxesFiltered
        .where('userUPN')
        .equalsIgnoreCase(userUPN)
        .filter(({ purgeDate }: InboxesFilteredSchema) => !DateTimeExtension.lessThanOneDay(purgeDate, new Date()))
        .delete();

      CustomLogger.getInstance().info(
        InboxesBd.TAG,
        `[Client Web] inboxesDb.ts - purgeInboxesAsync method: Nombre d'inboxes supprimés: ${inboxesDeleted.toString()}`,
      );
      return true;
    } catch (error: unknown) {
      this.handleDbError(error, 'purgeInboxesAsync');

      return false;
    }
  }

  async addInboxToDeleteAsync(userUPN: string, inboxId: string, declarationId: string): Promise<boolean> {
    try {
      await this.transaction('rw', this.inboxDraft, async () => {
        await this.inboxDraft.add({ userUPN, inboxId, declarationId, deleted: false });
      });
      return true;
    } catch (error: unknown) {
      this.handleDbError(error, 'addInboxToDeleteAsync');
      console.log("c'est le add qui fail");
      return false;
    }
  }

  async markInboxAsDeleted(declarationId: string): Promise<boolean> {
    try {
      const updated = await this.transaction('rw', this.inboxDraft, async () => {
        const count = await this.inboxDraft.where({ declarationId }).modify({ deleted: true });

        return count;
      });

      console.log(`Marked ${updated} inboxes as deleted.`);
      return updated > 0;
    } catch (error) {
      console.error('Failed to mark inbox as deleted:', error);
      this.handleDbError(error, 'markInboxAsDeleted');
      return false;
    }
  }

  // Récupérer les inbox à supprimer pour un utilisateur donné
  async getInboxesToDeleteAsync(userUPN: string): Promise<string[]> {
    try {
      const inboxesToDelete = await this.inboxDraft
        .where('userUPN')
        .equalsIgnoreCase(userUPN)
        .and((inbox) => inbox.deleted === true)
        .toArray();
      return inboxesToDelete.map((inbox) => inbox.inboxId);
    } catch (error: unknown) {
      this.handleDbError(error, 'getInboxesToDeleteAsync');
      return [];
    }
  }

  // Méthode pour récupérer les ids de inboxDraft basés sur une liste de declarationId
  async getInboxDraftIdsByDeclarationIds(InboxesId: string[]): Promise<number[]> {
    try {
      const inboxes = await this.inboxDraft.where('inboxId').anyOf(InboxesId).toArray();
      return inboxes.map((inbox) => inbox.id).filter((id) => id !== undefined) as number[];
    } catch (error: unknown) {
      this.handleDbError(error, 'getInboxDraftIdsByDeclarationIds');
      return [];
    }
  }

  async doesDeclarationExist(declarationId: string): Promise<boolean> {
    try {
      // Recherche d'un enregistrement avec le declarationId spécifié
      const entry = await this.inboxDraft.where({ declarationId }).first(); // Utilise 'first' pour obtenir le premier élément correspondant, s'il existe

      // Retourne true si un enregistrement est trouvé, false sinon
      return entry !== undefined;
    } catch (error) {
      console.error('Error checking declaration existence:', error);
      this.handleDbError(error, 'doesDeclarationExist');
      return false; // Retourne false en cas d'erreur
    }
  }

  handleDbError(error: unknown, method: string) {
    errorHandler(InboxesBd.TAG, error, method);
  }
}

const inboxesDb = new InboxesBd();

export default inboxesDb;
