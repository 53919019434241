import { CounterDesc } from '90.quickConnect.Models/models';

const mapCounterDesc = (field: CounterDesc): CounterDesc => {
  return {
    min: field.min ?? 0,
    max: field.max ?? 100,
    value: field.value ?? field.defaultValue ?? undefined,
    defaultValue: field.defaultValue,
  } as CounterDesc;
};

export default mapCounterDesc;
