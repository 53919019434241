import { QCSBool } from './QCSBool';
import { QCSBaseObject } from './QCSBaseObject';
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSDouble } from './QCSDouble';
import { QCSInt } from './QCSInt';
import { QCSString } from './QCSString';
import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';

export class QCSLong extends QCSBaseTypedObject<bigint> {
  public constructor(longValue: bigint) {
    super(longValue);
    this.value = BigInt(longValue);
  }

  public isFalse(): boolean {
    return this.value === BigInt(0);
  }

  public multiplyBy(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(Number(this.value) * arg1.value);
    if (arg1 instanceof QCSInt) return new QCSLong(this.value * BigInt(arg1.value));
    if (arg1 instanceof QCSLong) return new QCSLong(this.value * arg1.value);
    return QCSDouble.QCSNull;
  }

  public add(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(Number(this.value) + arg1.value);
    if (arg1 instanceof QCSInt) return new QCSLong(this.value + BigInt(arg1.value));
    if (arg1 instanceof QCSLong) return new QCSLong(this.value + arg1.value);
    return QCSDouble.QCSNull;
  }

  public sub(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(Number(this.value) - arg1.value);
    if (arg1 instanceof QCSInt) return new QCSLong(this.value - BigInt(arg1.value));
    if (arg1 instanceof QCSLong) return new QCSLong(this.value - arg1.value);
    return QCSDouble.QCSNull;
  }

  public div(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble || arg1 instanceof QCSInt || arg1 instanceof QCSLong)
      return new QCSDouble(DoubleExtension.customDivide(this.value, arg1.value));
    return QCSDouble.QCSNull;
  }

  public modulo(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(DoubleExtension.customModulo(this.value, arg1.value));
    if (arg1 instanceof QCSInt) return new QCSLong(BigInt(DoubleExtension.customModulo(this.value, arg1.value)));
    if (arg1 instanceof QCSLong) return new QCSLong(BigInt(DoubleExtension.customModulo(this.value, arg1.value)));
    return QCSDouble.QCSNull;
  }

  public negation(): QCSBaseObject {
    return new QCSLong(-this.value);
  }

  public isEquals(arg1: QCSBaseObject): boolean {
    if (this === QCSBaseObject.QCSNull || this.value === null)
      return arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null;

    if (arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null) return false;

    switch (true) {
      case arg1 instanceof QCSInt:
      case arg1 instanceof QCSDouble:
        const valueToCompare = arg1.getValue();
        return typeof valueToCompare === 'number' ? this.value === BigInt(valueToCompare) : false;

      case arg1 instanceof QCSLong:
        return this.value === arg1.getValue();

      default:
        return false;
    }
  }

  public greaterThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value > arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value > arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value > arg1.value);
    return new QCSBool(false);
  }

  public lessThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value < arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value < arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value < arg1.value);
    return new QCSBool(false);
  }

  public greaterEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value >= arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value >= arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value >= arg1.value);
    return new QCSBool(false);
  }

  public lessEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value <= arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value <= arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value <= arg1.value);
    return new QCSBool(false);
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    return null;
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    if (methodId === 1) {
      return new QCSString(`${this.value}`);
    }
    return null;
  }

  static from(qcsBaseObject: QCSBaseObject): QCSBaseObject {
    const biZero = new QCSLong(BigInt(0));
    if (qcsBaseObject == null || qcsBaseObject == QCSBaseObject.QCSNull) return biZero;

    const numberConvertisor = Number(qcsBaseObject.getValue());

    return !Number.isNaN(numberConvertisor)
      ? Number.isInteger(numberConvertisor)
        ? new QCSLong(BigInt(numberConvertisor))
        : new QCSLong(BigInt(Math.trunc(numberConvertisor)))
      : biZero;
  }
}
