import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HistoryQCFieldProps } from './types';

const HistoryQCField = ({ prevValue, onTagChange, handleDeleteItem, fieldFullId, disabled }: HistoryQCFieldProps) => {
  return (
    <Box data-cy={'nameWithId'}>
      {prevValue.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Historique des saisies</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {prevValue.map((x: any, index: number) => (
              <List key={index}>
                <ListItem
                  disablePadding
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={disabled}
                      onClick={(event) => handleDeleteItem(event, x)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton disabled={disabled}>
                    <ListItemText primary={x} onClick={(event) => onTagChange(event, x)} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
export default HistoryQCField;
