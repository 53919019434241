import {
  AllFieldValueTypes,
  FieldDesc,
  EditDesc,
  QCNotification,
  NotificationTarget,
} from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { isEmail } from '80.quickConnect.Core/helpers';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';
import { mapToNotificationDataFromHashMap } from '90.quickConnect.Models/mappings';

export const getReferencedTextValue = (
  fieldWithReference: FieldDesc,
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const textValue = valueReferenceDependency as string;

  if (textValue === undefined) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.Separator:
    case FieldType.ReadOnlyValue: {
      return textValue;
    }

    case FieldType.Notification: {
      const { value } = fieldWithReference as EditDesc;
      const notificationValue = isQCNotification(value) ? value : mapToNotificationDataFromHashMap({});
      const regexp = /[,;]/;

      const newSelectedTargets = textValue
        .split(regexp)
        .reduce(
          (acc: NotificationTarget[], current: string) =>
            isEmail(current.trim()) ? [...acc, { alias: null, target: current.trim() } as NotificationTarget] : acc,
          [],
        );

      return { ...notificationValue, selectedTargets: newSelectedTargets };
    }
    default:
      return undefined;
  }
};
