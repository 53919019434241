import { useEffect, useMemo, useState } from 'react';

import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { AttachmentDesc, DeclarationContext, FieldDesc } from '90.quickConnect.Models/models';
import { IsAnAttachmentField } from '90.quickConnect.Models/models/fields/fieldDesc/fieldDesc';
import { useConsent } from '50.quickConnect.Fields/hooks/useConsent';

const useData = (flattenFields: FieldDesc[], context: DeclarationContext): UseDataProps => {
  const {
    LoginStore: {
      signInInfos: { userUPN },
    },
    DeclarationStore: { isDrawerOpened, setIsMaxAttachment },
  } = useStore();

  const [selectedIndex, setSelectedIndex] = useState<string | undefined>();

  const { formId, name } = context;

  const { hasConsentsAsked, fieldsToAsked, onAcceptConsent, onDeclineConsent } = useConsent(
    flattenFields,
    formId,
    name,
    userUPN,
  );

  const getNumberOfAttachement = useMemo(() => {
    const att = flattenFields.filter((f) => IsAnAttachmentField(f.fieldType));
    let count = 0;
    if (att && att.length > 0) {
      att.forEach((f) => {
        count += (f.value as AttachmentDesc[])?.length;
      });
    }
    return count;
  }, [flattenFields]);

  useEffect(() => {
    if (getNumberOfAttachement > 60) {
      setIsMaxAttachment(true);
    } else {
      setIsMaxAttachment(false);
    }
  }, [getNumberOfAttachement, setIsMaxAttachment]);

  return {
    selectedIndex,
    setSelectedIndex,
    isDrawerOpened,
    hasConsentsAsked,
    fieldsToAsked,
    onAcceptConsent,
    onDeclineConsent,
  };
};

export default useData;
