import { ItemData } from '90.quickConnect.Models/models';

const mapItemData = (data: ItemData): ItemData =>
  ({
    PrefilledData: data.PrefilledData,
    Workflow: data.Workflow,
    TodoListData: data.TodoListData,
    Inbox: data.Inbox,
    editedAt: data.editedAt ? new Date(data.editedAt) : new Date(),
  } as ItemData);

export default mapItemData;
