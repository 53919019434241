import { TFunction } from 'i18next';
import getValue from './getValue/getValue';
import { evalArithmeticValue } from './evalArithmeticValue';
import { evalComputedValue } from './evalComputedValue';
import { evalConditionalValue } from './evalConditionalValue';
import { evalComputeBitwiseLogic } from './evalComputeBitwiseLogic';
import { OperatorType, OperatorResult } from '90.quickConnect.Models/enums';
import {
  BaseQCOperator,
  CtrlRefOperator,
  Dependency,
  DualOperator,
  IIfOperator,
  NamedUserParameterValue,
} from '90.quickConnect.Models/models';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { mapQcOperatorType } from '90.quickConnect.Models/mappings';

const evaluateComputedRule = (
  t: TFunction,
  baseQCOperator: BaseQCOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): [AllFieldValueTypes, OperatorResult] => {
  const operatorType = mapQcOperatorType(baseQCOperator.type);
  switch (operatorType) {
    case OperatorType.Bitwise:
      return evalComputeBitwiseLogic(t, baseQCOperator, dependencies, userParams, scopeFullPathId, userUpn, userEmail);
    case OperatorType.Conditional:
      return evalConditionalValue(
        t,
        baseQCOperator as IIfOperator,
        dependencies,
        userParams,
        scopeFullPathId,
        userUpn,
        userEmail,
      );
    case OperatorType.Arithmetic:
      return evalArithmeticValue(
        t,
        baseQCOperator as DualOperator,
        dependencies,
        userParams,
        scopeFullPathId,
        userUpn,
        userEmail,
      );
    case OperatorType.Pattern:
      return evalComputedValue(baseQCOperator as CtrlRefOperator, dependencies, scopeFullPathId);
    case OperatorType.Value:
    case OperatorType.Selector:
      return getValue(baseQCOperator, dependencies, userParams);
    default:
      return [undefined, OperatorResult.ERROR];
  }
};

export default evaluateComputedRule;
