import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { DialogSummaryProps } from './types';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import DialogSummaryItem from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/DialogSummary/DialogSummaryItem';
import { DialogSummaryStyle } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/styles';
import useData from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/DialogSummary/hooks';

const DialogSummary = ({ items, parentId, isReadOnly }: DialogSummaryProps) => {
  const { nameWithId, hasItemsToDisplay, itemsToDisplay } = useData(items, parentId);

  if (itemsToDisplay.length === 0) return <></>;

  return (
    <Box sx={hasItemsToDisplay ? DialogSummaryStyle(isReadOnly) : {}}>
      <Box
        data-cy={nameWithId}
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridGap: '10px',
        }}
      >
        {itemsToDisplay.map((item) => {
          const key = getFieldKey(item);
          const { label, mandatory, fieldType, errors, value } = item;
          if (!value) return null;
          const labelToDisplay = typeof value === 'object' && 'label' in value ? value.label : value;

          return (
            <DialogSummaryItem
              key={key}
              parentId={parentId}
              label={label}
              mandatory={mandatory}
              fieldType={fieldType}
              errors={errors}
              value={labelToDisplay}
            />
          );
        })}
      </Box>
    </Box>
  );
};
export default observer(DialogSummary);
