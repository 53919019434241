import { AllFieldValueTypes, NotificationTarget } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';

export const getReferencedNotificationValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const notificationValues = isQCNotification(valueReferenceDependency) ? valueReferenceDependency : undefined;

  if (!notificationValues) return undefined;
  const { selectedTargets } = notificationValues;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
      return selectedTargets.map((item: NotificationTarget) => item.target).join(', ');

    case FieldType.CheckBox:
      return selectedTargets.length > 0 ? 1 : 0;

    case FieldType.Counter:
    case FieldType.Digits:
      return roundTo(selectedTargets.length, 0);

    case FieldType.Numeric:
      return roundTo(selectedTargets.length, 1);

    case FieldType.Notification:
      return notificationValues;

    default:
      return undefined;
  }
};
