import { v4 as uuidv4 } from 'uuid';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { toJS } from 'mobx';
import { RepeatableQcFieldName } from './const';
import { UseDataProps } from './types';
import { AllFieldValueTypes, DeclarationContext, FieldDesc, RepeatableGroupDesc } from '90.quickConnect.Models/models';
import { ConsentFrequency, FieldType } from '90.quickConnect.Models/enums';
import updateItemsFieldOrChild from '20.formLib/helpers/updateField/updateItemsFieldOrChild';
import { mapFieldDesc } from '90.quickConnect.Models/mappings';
import { useStore } from '30.quickConnect.Stores';
import { isFocusableField } from '80.quickConnect.Core/helpers/isFocusableField';
import useFormEventQCScript from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript';
import { useQCSFunctions } from '20.formLib/DeclarationContainer/contexts';

const useData = (
  field: RepeatableGroupDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  setDeclaration: (value: SetStateAction<FieldDesc[] | undefined>) => void,
  context: DeclarationContext,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  flattenFields: FieldDesc[],
  openByImagesGroupField?: boolean,
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const { id, fullPathId, items, value, groupTemplate, maxRow, desactivatedAutomation } = field;
  const { templateBodies } = context;

  const nameWithId = `${RepeatableQcFieldName}-${id}`;
  const [itemsValue] = useState<FieldDesc[]>([]);

  const [isExpanded, setIsExpanded] = useState(value ? (value as boolean) : openByImagesGroupField ?? false);
  const [isAllExpanded, setIsAllExpanded] = useState(value ? (value as boolean) : openByImagesGroupField ?? false);

  const qcsFormEvent = useFormEventQCScript();
  const qcsFunctions = useQCSFunctions();

  const {
    DeclarationStore: { setFieldFocused },
  } = useStore();

  useEffect(() => {
    if (!isExpanded) {
      setSelectedIndex(undefined);
    }
  }, [isExpanded, setSelectedIndex]);
  const {
    QCScriptStore: { setQCSBaseFieldId, setQCSFieldId, setFlattenFields },
    DeclarationStore: { requiredField, verifRequiredField },
  } = useStore();

  const handleChange = useCallback(
    (expanded: boolean) => {
      setIsExpanded(!expanded);
      setIsAllExpanded((prev) => (prev = !expanded));
      updateDeclaration(fullPathId, !expanded);
    },
    [fullPathId, updateDeclaration],
  );

  // Methode de mise a jour des Form_event ON_ROW_ADDED et On_ROW_DELETED
  const formEventOnRow = useCallback(
    (onRowAdded: boolean, currentFlattenFields: FieldDesc[]) => {
      // Dans le cas des qcscript, on appelle et que le champ desactivatedAutomation est mis a false
      if (qcsFormEvent.isQCScriptForm && !desactivatedAutomation) {
        setQCSBaseFieldId(fullPathId);
        setQCSFieldId(id);
        setFlattenFields(toJS(currentFlattenFields));
        qcsFormEvent.setQCSFunc(qcsFunctions);
        if (onRowAdded) qcsFormEvent.onRowAdded(currentFlattenFields, fullPathId);
        else qcsFormEvent.onRowDeleted(currentFlattenFields, fullPathId);
      }
    },
    [
      qcsFormEvent,
      desactivatedAutomation,
      setQCSBaseFieldId,
      fullPathId,
      setQCSFieldId,
      id,
      setFlattenFields,
      qcsFunctions,
    ],
  );

  const addItem = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // Evite de déplier l'accordéon
      if (isExpanded) event.stopPropagation();
      if (items.length < maxRow) {
        const newIncludeFieldId = items.length.toString();
        const newIncludeChildren = groupTemplate.map((gt) =>
          mapFieldDesc(gt, `${fullPathId}.${id}.${newIncludeFieldId}`, templateBodies, context),
        );

        const newIncludeField: FieldDesc = {
          stateId: uuidv4(),
          fieldType: FieldType.Include,
          items: newIncludeChildren,
          value: true,
          errors: undefined,
          fullPathId: `${fullPathId}.${id}.${newIncludeFieldId}`,
          id: `${id}.${newIncludeFieldId}`,
          fieldIsReadOnly: false,
          isVisible: true,
          checkRGPD: ConsentFrequency.Undef,
        };
        const newItems = [...items, newIncludeField];
        setDeclaration((prev) => {
          if (prev && prev.length > 0) {
            const normalizedFullPathId = fullPathId.toLowerCase();
            prev.forEach((root) => updateItemsFieldOrChild(normalizedFullPathId, root, newItems));
            return [...prev];
          }
        });

        // Donner le focus au premier champ du groupe
        setFieldFocused(newIncludeChildren.find(isFocusableField)?.fullPathId);

        // Dans le cas des qcscript, on appelle onRowAdded
        formEventOnRow(true, [...flattenFields, newIncludeField, ...newIncludeChildren]);
      } else {
        toast.warn(t('fields_repeatable_group_warnings_max', { maxRow }).toString());
      }
    },
    [
      fullPathId,
      id,
      groupTemplate,
      isExpanded,
      flattenFields,
      items,
      maxRow,
      setDeclaration,
      t,
      templateBodies,
      setFieldFocused,
      context,
      formEventOnRow,
    ],
  );

  const removeItem = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, itemToDelete: string) => {
      // Evite de déplier l'accordéon
      event.stopPropagation();
      const index = items.findIndex((f) => f.fullPathId === itemToDelete);
      if (index > -1) {
        const newItems = [...items];
        newItems.splice(index, 1);
        // Mise a jour des index dans le tableau des items car les fullPathId sont erronnés.
        const newItemsWithNewIndex = newItems.map((item, newIndex) => {
          const newFullPathId = item.fullPathId.slice(0, -1) + newIndex.toString();
          const newId = item.id.slice(0, -1) + newIndex.toString();
          item.items.forEach((ci) => {
            ci.fullPathId = newId + '.' + ci.id;
          });
          return { ...item, fullPathId: newFullPathId, id: newId };
        });

        setDeclaration((prev) => {
          if (prev && prev.length > 0) {
            const normalizedFullPathId = fullPathId.toLowerCase();
            prev.forEach((root) => updateItemsFieldOrChild(normalizedFullPathId, root, newItemsWithNewIndex));
            return [...prev];
          }
        });

        // on retire le focus
        setFieldFocused(undefined);

        // Dans le cas des qcscript, on appelle onRowDeleted
        formEventOnRow(false, [...flattenFields, ...newItems]);
      }
    },
    [items, setDeclaration, setFieldFocused, formEventOnRow, flattenFields, fullPathId],
  );

  const expandOrFoldAll = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Evite de déplier l'accordéon
    event.stopPropagation();
    setIsAllExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (verifRequiredField) {
      if (requiredField && requiredField?.fullPathId.includes(fullPathId)) {
        handleChange(!verifRequiredField);
        //  setVerifRequiredField(false);
      }
    }
  }, [fullPathId, handleChange, requiredField, verifRequiredField]);

  return {
    t,
    nameWithId,
    isExpanded,
    handleChange,
    items,
    addItem,
    removeItem,
    isAllExpanded,
    expandOrFoldAll,
    itemsValue,
  };
};

export default useData;
