import mapEntity from '../mapEntityBase';
import { FormEditedData } from '90.quickConnect.Models/models';

const mapFormEditedData = (formEditedDataform: FormEditedData): FormEditedData =>
  ({
    ...mapEntity(formEditedDataform),
    formId: formEditedDataform.formId,
    formParentId: formEditedDataform.formParentId,
    name: formEditedDataform.name,
    serializedBody: formEditedDataform.serializedBody,
    templateBodies: formEditedDataform.templateBodies,
    userUpn: formEditedDataform.userUpn,
    attachmentData: formEditedDataform.attachmentData,
    editedAt: formEditedDataform.editedAt ? new Date(formEditedDataform.editedAt) : new Date(),
    editedData: formEditedDataform.editedData,
    stringExtract: formEditedDataform.stringExtract,
    entitySchemaId: formEditedDataform.entitySchemaId,
    entityInstanceId: formEditedDataform.entityInstanceId,
    alertLevelValue: formEditedDataform.alertLevelValue,
    alertLevelLabel: formEditedDataform.alertLevelLabel,
    workflowStatus: formEditedDataform.workflowStatus,
    entityInstanceStringExtract: formEditedDataform.entityInstanceStringExtract,
    organizationalUnitStringExtract: formEditedDataform.organizationalUnitStringExtract,
    formType: formEditedDataform.formType,
    itemData: formEditedDataform.itemData,
    itemDataStatus: formEditedDataform.itemDataStatus,
    itemDataComment: formEditedDataform.itemDataComment,
    activityId: formEditedDataform.activityId,
    parentActivityId: formEditedDataform.parentActivityId,
    languageCode: formEditedDataform.languageCode,
  } as FormEditedData);

export default mapFormEditedData;
