const getUNIXDateTimeWithTimeZoneOffset = (): string => {
  const date: Date = new Date();
  function z(n: number) {
    return ('0' + n).slice(-2);
  }
  let offset = date.getTimezoneOffset();

  // ECMScript offset sign is the reverse of ISO 8601
  const sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  const offsetString: string = sign + z((offset / 60) | 0) + z(offset % 60);

  return `/Date(${Date.now().toString() + offsetString})/`;
};

export default getUNIXDateTimeWithTimeZoneOffset;
