import React, { Dispatch, FunctionComponent, useState } from 'react';
import { Card, Dialog, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { Report, WorkSiteAct, WorkSiteActType } from '../../types';
import MissingFormDialogContent from '../ReportDialog/MissingFormDialogContent';
import { getTextEllipsisStyle } from '../TextEllipsisStyle';
import ReportSummaryDialogContent from '../ReportDialog/ReportSummaryDialogContent';

type ReportCardProps = {
  report: Report;
  setWorkSite: Dispatch<WorkSiteAct>;
};

const ReportCardStyled = styled(Card)(({ theme }) => ({
  padding: '1rem',
  border: 'solid 1px',
  borderColor: theme.palette.divider,
  cursor: 'pointer',
  maxHeight: '30vh',
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  gap: '1',
  transition: theme.transitions.create('border-color'),
}));

const LinkTypography = styled(Typography)(() => ({
  textDecoration: 'underline',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  textBaseline: 'center',
  gap: '0.1rem',
}));

const ReportCard: FunctionComponent<ReportCardProps> = (props: ReportCardProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const theme = useTheme();
  const { report } = props;
  const [isSeeReportOpen, setIsSeeReportOpen] = useState<boolean>(false);
  const [isMissingOpen, setIsMissingOpen] = useState<boolean>(false);

  const openMissingDialog = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsMissingOpen((open) => !open);
  };
  const openReportDialog = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsSeeReportOpen((open) => !open);
  };

  function toggleReport(e: React.FormEvent<HTMLInputElement | HTMLDivElement>) {
    e.stopPropagation();
    if (props.report.nbMissingReports > 0) {
      //do nothing
      props.setWorkSite({ type: WorkSiteActType.SELECTREPORT, value: { id: report.id, selected: false } });
      return;
    }
    props.setWorkSite({ type: WorkSiteActType.SELECTREPORT, value: { id: report.id, selected: !report.selected } });
  }

  let borderColors = theme.palette.mode === 'light' ? 'rgba(232, 233, 232, 1)' : 'rgba(255, 255, 255, 0.23)';
  if (report.selected) {
    borderColors = theme.palette.divider;
  }

  return (
    <>
      <Dialog open={isSeeReportOpen} onClose={() => setIsSeeReportOpen((open) => !open)} maxWidth={'md'}>
        <ReportSummaryDialogContent report={report} onClose={() => setIsSeeReportOpen(false)} />
      </Dialog>
      <Dialog open={isMissingOpen} onClose={() => setIsMissingOpen((open) => !open)}>
        <MissingFormDialogContent report={report} onClose={() => setIsMissingOpen(false)} />
      </Dialog>
      <ReportCardStyled data-cy="ReportCard" onClick={toggleReport} style={{ borderColor: borderColors }}>
        <Stack direction={'row'} alignItems={'center'} textAlign={'center'} gap={1}>
          {report.selected ? (
            <CheckBoxIcon color={'primary'} />
          ) : (
            <CheckBoxOutlineBlankIcon color={props.report.nbMissingReports > 0 ? 'disabled' : 'action'} />
          )}
          <Tooltip title={report.name} placement={'top'}>
            <Typography sx={getTextEllipsisStyle(2)} fontWeight={'bolder'}>
              {report.name}
            </Typography>
          </Tooltip>
        </Stack>
        <Tooltip title={report.description} placement={'left'}>
          <Typography sx={getTextEllipsisStyle(4)}>{report.description}</Typography>
        </Tooltip>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
          <LinkTypography onClick={openReportDialog}>{t('work_site_summary_reports_see_report')}</LinkTypography>
          {report.nbMissingReports > 0 && (
            <LinkTypography color={'error'} onClick={openMissingDialog}>
              <NoteAltIcon />
              {t('work_site_summary_reports_missing_count', { count: report.nbMissingReports })}
            </LinkTypography>
          )}
        </Stack>
      </ReportCardStyled>
    </>
  );
};

export default ReportCard;
