import React from 'react';
import { Box, List } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { observer } from 'mobx-react';
import { useData } from './hooks';
import { DeclarationTreeItemRendererName } from './const';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import DeclarationLine from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationLine';
import CustomTreeViewItem from '10.quickConnect.app/components/shared/CustomTreeViewItem';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';

const DeclarationTreeItemRenderer = ({ id, name, items }: TreeViewData<TreeDataItem>) => {
  const { nodeChildren, declarationChildren } = useData(items);
  return (
    <Box data-cy={DeclarationTreeItemRendererName}>
      <CustomTreeViewItem
        key={id}
        nodeId={id}
        labelText={name}
        labelIcon={<FolderIcon sx={{ mr: 1 }} />}
        labelInfo={
          declarationChildren?.length && declarationChildren.length > 0
            ? declarationChildren.length.toString()
            : undefined
        }
      >
        {nodeChildren.map((child) => (
          <DeclarationTreeItemRenderer key={child.id} {...child} />
        ))}
        <List>
          {declarationChildren.map(({ id: childId, data }) => (
            <DeclarationLine key={childId} {...data!} />
          ))}
        </List>
      </CustomTreeViewItem>
    </Box>
  );
};
export default observer(DeclarationTreeItemRenderer);
