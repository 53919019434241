import { Dispatch, SetStateAction, useCallback } from 'react';
import { toast } from 'react-toastify';
import { TFunction } from 'i18next';
import { DebouncedState } from 'use-debounce/dist/useDebouncedCallback';
import { mapAttachmentItemDataFromFile } from '90.quickConnect.Models/mappings';
import { AttachmentItemData, BaseAttachmentDesc } from '90.quickConnect.Models/models';
import attachmentDb from '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb';
import { useStore } from '30.quickConnect.Stores';

const useAttachments = (
  onlyImage: boolean,
  localValue: AttachmentItemData[],
  setLocalValue: Dispatch<SetStateAction<AttachmentItemData[]>>,
  t: TFunction,
  attachDesc: BaseAttachmentDesc,
  debouncedUpdateGlobalState: DebouncedState<() => void>,
  isTask?: boolean,
) => {
  const getFileHTMLElement = useCallback((parentId: string): HTMLInputElement | undefined => {
    const fileHMTLElement: HTMLInputElement | null = document.querySelector<HTMLInputElement>(
      `[data-cy="${parentId}-input"]`,
    );
    if (fileHMTLElement === null) return;
    return fileHMTLElement;
  }, []);

  const {
    DeclarationStore: { declarationId },
  } = useStore();

  const verifyAndAddFile = useCallback(
    (files: File[], valueFile: string) => {
      //Récupération de la taille des PJ déjà ajoutées
      let size = 0;
      let nbAttach = localValue.length;
      let messageDuplicateError = '';
      let messageErrors = '';
      let nbDuplicate = 0;
      files.forEach((file) => {
        size = file.size / 1024 ** 2;
      });

      files.forEach(async (file) => {
        //Vérification du type
        const toAdd = !onlyImage || file.type.split('/')[0] === 'image';
        if (toAdd) {
          //Pour chaque fichier à ajouter, vérification si déjà présent
          const alreadyAdded = localValue.some((addedFile) => addedFile.fileName === file.name);
          if (!alreadyAdded) {
            //Vérification du nombre max d'attachment
            if (attachDesc.maxAttachment >= nbAttach + 1 || (isTask && nbAttach + 1 < 2)) {
              //Vérification de la taille
              if (attachDesc.maximumSize * 1024 ** 2 >= file.size || isTask) {
                nbAttach++;
                size += file.size / 1024 / 1024;
                const attachItem = await mapAttachmentItemDataFromFile(file, valueFile);
                setLocalValue((prev) => {
                  const newArray = [...prev, attachItem];
                  return newArray;
                });
                setTimeout(async () => {
                  debouncedUpdateGlobalState();
                  await attachmentDb.addAttachmentItem(attachItem, file, declarationId, false);
                }, 100);
              } else {
                //Taille maximale autorisée atteinte
                messageErrors = `${t('attachment_fields_errors_has_max_size')} (${attachDesc.maximumSize} Mo)`;
                return;
              }
            } else {
              //Nombre max attachment atteint
              messageErrors = `${t('attachment_fields_errors_has_max_count')} (${attachDesc.maxAttachment})`;
              return;
            }
          } else {
            //Fichier déjà sélectionné
            messageDuplicateError += `${messageDuplicateError ? '\n-' : '-'} ${file.name}`;
            nbDuplicate++;
          }
        } else {
          messageErrors = `${t('attachment_fields_errors_onlyAdd_pictureFiles')}`;
        }
      });

      let message = '';
      if (nbDuplicate > 0 && messageDuplicateError) {
        message = `${
          nbDuplicate > 1 ? t('formlib_already_add_attachment') : t('formlib_already_add_attachment')
        }\n${messageDuplicateError}`;
      }
      message += `${message ? '\n' : ''}${messageErrors}`;
      if (message) {
        toast.warn(message);
      }
    },
    [
      declarationId,
      attachDesc.maxAttachment,
      attachDesc.maximumSize,
      debouncedUpdateGlobalState,
      isTask,
      localValue,
      onlyImage,
      setLocalValue,
      t,
    ],
  );

  const handleFileDrop = useCallback(
    (item: any, parentId: string) => {
      const fileElement = getFileHTMLElement(parentId);
      const valueFile = fileElement?.value ?? '';
      if (item) {
        const { files } = item;
        verifyAndAddFile(files, valueFile);
      }
    },
    [verifyAndAddFile, getFileHTMLElement],
  );

  const handleFileDelete = useCallback(
    (item: any) => {
      setLocalValue((prev) => {
        const newArray = [...prev.filter((file) => file.id !== item.id)];
        return newArray;
      });
      setTimeout(async () => {
        await attachmentDb.deleteFromId(item);
        debouncedUpdateGlobalState();
      }, 100);
    },
    [debouncedUpdateGlobalState, setLocalValue],
  );

  const handleFilePreview = useCallback((attachment: AttachmentItemData) => {
    let link = '';

    // If it's a word, powerpoint, or excel, open the file using the office viewer
    if (/.?(word|powerpoint|excel)/.test(attachment.type)) {
      link = 'https://view.officeapps.live.com/op/view.aspx?src=';
    }

    // Create a local url for the file if it's not uploaded yet, use the distant link instead
    if ((attachment.file as File)?.type) {
      link += encodeURI(URL.createObjectURL(attachment.file as File));
    } else if (attachment.distantUri) {
      link += encodeURI(attachment.distantUri);
    }

    window.open(link, '_blank');
    return false;
  }, []);

  return { handleFileDrop, handleFileDelete, handleFilePreview };
};

export default useAttachments;
