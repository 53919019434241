import { useCallback, useState } from 'react';

import { useDeclarationContext } from '10.quickConnect.app/components/domain/Declaration/context/declarationContext';
import { useStore } from '30.quickConnect.Stores';

import type { UseLRU, UseLRUProps, LRUState } from '50.quickConnect.Fields/hooks/LRU/types';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

export default function useLRU({ fullId, lruCount }: UseLRUProps): UseLRU {
  // State
  const [lruState, setLruState] = useState<LRUState>({
    list: [],
    showList: false,
  });

  // Store
  const {
    DeclarationStore: { deleteLRUValue, getLRUDescriptionsByFieldId },
    LoginStore: {
      signInInfos: { userUPN },
    },
  } = useStore();

  // Contexts
  const declaration = useDeclarationContext();

  const displayList = useCallback(() => setLruState({ ...lruState, showList: true }), [lruState]);

  const hideList = useCallback(() => setLruState({ ...lruState, showList: false }), [lruState]);

  const deleteLRU = useCallback(
    async (value: string): Promise<void> => {
      await deleteLRUValue(declaration, fullId, userUPN, value);
    },
    [fullId, deleteLRUValue, declaration, userUPN],
  );

  useEffectOnce(() => {
    const fetchLRU = async () => {
      const lruList = await getLRUDescriptionsByFieldId(declaration, fullId, lruCount ?? 3);
      setLruState({
        ...lruState,
        list: lruList,
      });
    };

    fetchLRU();
  });

  return {
    lruState,
    displayList,
    hideList,
    deleteLRU,
  };
}
