import { TFunction } from 'i18next';
import evaluateComputedRule from './evaluateComputedRule';
import { QcOperator, OperatorResult } from '90.quickConnect.Models/enums';
import {
  BaseQCOperator,
  Dependency,
  NamedUserParameterValue,
  DualOperator,
  MultipleOperator,
  AllFieldValueTypes,
} from '90.quickConnect.Models/models';

export const evalComputeBitwiseLogic = (
  t: TFunction,
  baseQCOperator: BaseQCOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): [AllFieldValueTypes, OperatorResult] => {
  switch (baseQCOperator.type) {
    case QcOperator.Parenthesis:
      const parenthesisOperator = baseQCOperator as DualOperator;
      if (parenthesisOperator.conditionalOpeType === QcOperator.Or)
        return (
          evaluateComputedRule(
            t,
            parenthesisOperator.arg1,
            dependencies,
            userParams,
            scopeFullPathId,
            userUpn,
            userEmail,
          ) ||
          evaluateComputedRule(
            t,
            parenthesisOperator.arg2,
            dependencies,
            userParams,
            scopeFullPathId,
            userUpn,
            userEmail,
          )
        );
      else if (parenthesisOperator.conditionalOpeType === QcOperator.And)
        return (
          evaluateComputedRule(
            t,
            parenthesisOperator.arg1,
            dependencies,
            userParams,
            scopeFullPathId,
            userUpn,
            userEmail,
          ) &&
          evaluateComputedRule(
            t,
            parenthesisOperator.arg2,
            dependencies,
            userParams,
            scopeFullPathId,
            userUpn,
            userEmail,
          )
        );
      break;
    case QcOperator.Multi:
      const multipleOperator = baseQCOperator as MultipleOperator;
      if (multipleOperator.conditionalOpeType === QcOperator.Or)
        return [
          multipleOperator.args.some((arg) =>
            evaluateComputedRule(t, arg, dependencies, userParams, scopeFullPathId, userUpn, userEmail),
          ),
          OperatorResult.BOOLEAN,
        ];
      else if (multipleOperator.conditionalOpeType === QcOperator.And)
        return [
          multipleOperator.args.every((arg) =>
            evaluateComputedRule(t, arg, dependencies, userParams, scopeFullPathId, userUpn, userEmail),
          ),
          OperatorResult.BOOLEAN,
        ];
      break;
    default:
      break;
  }
  return [undefined, OperatorResult.ERROR];
};
