import React from 'react';
import useData from './hooks';
import { VisibilitiesResolverProps } from './types';
import VisibilityResolver from './VisibilityResolver';
import { BaseQCOperator } from '90.quickConnect.Models/models';

/**
 * Ce composant permet des géré la visibilité des fields et de détermine ses dépendances
 * @param param0
 * @returns
 */
const VisibilitiesResolver = ({ children, flattenFields, updateFieldVisibility }: VisibilitiesResolverProps) => {
  const { allHidableFields } = useData(flattenFields);

  return (
    <>
      {allHidableFields.map(({ id, fullPathId, visible }, index) => (
        <VisibilityResolver
          key={`${fullPathId}-${index}`}
          id={id}
          fullPathId={fullPathId}
          visible={visible as BaseQCOperator}
          updateFieldVisibility={updateFieldVisibility}
          flattenFields={flattenFields}
        />
      ))}
      {children}
    </>
  );
};

export default VisibilitiesResolver;
