export enum QCSModulesEnum {
  QCS_FORM_CONTEXT = 1,
  QCS_STRING = 2,
  QCS_DATE = 3,
  QCS_MATH = 4,
  QCS_ARRAY = 5,
  QCS_BOOL = 6,
  QCS_CHOICE_LIST = 7,
  QCS_CHOICE = 8,
  QCS_PATTERN = 9,
  QCS_MATCHER = 10,
  QCS_LONG = 11,
  QCS_INT = 12,
  QCS_DOUBLE = 15,
}
