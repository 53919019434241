import { QCSBaseObject } from './QCSBaseObject';
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSString } from './QCSString';

export class QCSBool extends QCSBaseTypedObject<boolean> {
  public static trueValue: QCSBool = new QCSBool(true);

  public static falseValue: QCSBool = new QCSBool(false);

  public static trueString: QCSString = new QCSString('true');

  public static falseString: QCSString = new QCSString('false');

  public constructor(boolValue: boolean) {
    super(boolValue);
    this.value = boolValue;
  }

  public isFalse(): boolean {
    return this.value === false;
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 2: // parse
        return this.parse(qcParams);
      default:
        return null;
    }
  }

  private static parse(qcParams: Array<QCSBaseObject>): QCSBaseObject {
    const toParse: string = (qcParams[0] as QCSString).value;

    // Comparaison
    if (QCSBool.trueString.value === toParse || toParse === '1') {
      return this.trueValue;
    }

    if (QCSBool.falseString.value === toParse || toParse === '0') {
      return this.falseValue;
    }

    if (QCSBool.trueString.value === toParse || toParse === '1') {
      return this.trueValue;
    }

    if (QCSBool.falseString.value === toParse || toParse === '0') {
      return this.falseValue;
    }

    return this.QCSNull;
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 1: // toString
        return this.value ? QCSBool.trueString : QCSBool.falseString;

      default:
        return null;
    }
  }

  static from(qcsBaseObject: QCSBaseObject): QCSBaseObject {
    if (qcsBaseObject == null || qcsBaseObject == QCSBaseObject.QCSNull) return new QCSBool(false);
    return new QCSBool(Boolean(qcsBaseObject.getValue()));
  }
}
