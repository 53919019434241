import {
  FixedImageDesc,
  FixedImageType,
} from '90.quickConnect.Models/models/fields/fieldDesc/attachmentField/fixedImageDesc';

const mapFieldImageDesc = (fieldDesc: FixedImageDesc): FixedImageType =>
  ({
    value: fieldDesc.value ?? (fieldDesc.fixedImage ? [fieldDesc.fixedImage] : undefined),
  } as unknown as FixedImageType);

export default mapFieldImageDesc;
