import { useCallback, useRef } from 'react';
import { toJS } from 'mobx';
import { UseDataProps } from '../types';
import { AllFieldValueTypes, FieldDesc } from '90.quickConnect.Models/models';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';
import { useStore } from '30.quickConnect.Stores';

export default function useData(
  initialField: FieldDesc,
  handleCancelButtonClick: () => void,
  handleValidateButtonClick: () => void,
): UseDataProps {
  const initialFieldValue = useRef<AllFieldValueTypes>(undefined);
  const { updateProperty } = useQCUpdateFunctionsContext();
  const {
    DeclarationStore: { isDrawerOpened },
    DialogStore: { setItems, items },
  } = useStore();

  useEffectOnce(() => {
    const { items: initialItems, fullPathId, value } = initialField;
    initialFieldValue.current = value;
    setItems(fullPathId, initialItems);
  });

  const handleValidateClick = useCallback(() => {
    // Les données sont déjà mise à jour, rien a faire juste fermer la modale
    handleCancelButtonClick();
  }, [handleCancelButtonClick]);

  const handleCancelClick = useCallback(() => {
    // On doit remettre les données
    const { fullPathId } = initialField;
    const initialItems = items[fullPathId];
    const initialValue = initialFieldValue.current;

    updateProperty(fullPathId, 'items', initialItems);
    updateProperty(fullPathId, 'value', initialValue);
    handleValidateButtonClick();
  }, [updateProperty, handleValidateButtonClick, initialFieldValue, initialField, items]);

  return {
    isDrawerOpened,
    handleCancelClick,
    handleValidateClick,
  };
}
