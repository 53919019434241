import { TFunction } from 'i18next';
import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes, Choice } from '90.quickConnect.Models/models';

export const operationWithChoices = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  t: TFunction,
): [AllFieldValueTypes, OperatorResult] => {
  const ref1ValueList = ref1Value as Choice;
  const ref2ValueList = ref2Value as Choice;
  switch (type) {
    case QcOperator.Add:
      return [ref1ValueList.value.concat(ref2ValueList.value), OperatorResult.STRING];
    case QcOperator.Subst:
      return [ref1ValueList.value.replace(ref2ValueList.value, ''), OperatorResult.STRING];
    case QcOperator.Mult:
      return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
    case QcOperator.Div:
      return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
    default:
      return [t('computed_fields_errors_operator_type_unknown').toString(), OperatorResult.ERROR];
  }
};

export const operationWithChoicesArray = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  t: TFunction,
): [AllFieldValueTypes, OperatorResult] => {
  const ref1ValueList = ref1Value as Choice[];
  const ref2ValueList = ref2Value as Choice[];

  switch (type) {
    case QcOperator.Add:
      return [ref1ValueList.concat(ref2ValueList), OperatorResult.LISTCHOICE];
    case QcOperator.Subst:
      const filteredList = ref1ValueList.filter(
        (el) => ref2ValueList.findIndex((toRemove) => toRemove.value === el.value) < 0,
      );
      return [filteredList, OperatorResult.LISTCHOICE];
    case QcOperator.Mult:
      return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
    case QcOperator.Div:
      return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
    default:
      return [t('computed_fields_errors_operator_type_unknown').toString(), OperatorResult.ERROR];
  }
};

/**
 * Détermine si les éléments de la liste en parametre sont présents dans la liste courante
 * si andExclusif est true, tous les éléments doivent etre trouvés
 * si andExclusif est false, au moins 1 element doit etre trouvé
 *
 * @param cl
 * @return
 */
export const containsValues = (
  choices: Choice[],
  strings: string[],
  andExclusif: boolean,
  isEqualsLength = false,
): boolean => {
  if (strings.length === 0) return false;

  // On vérfie l'égalité de la longueur des 2 tableaux si isEqualsLength est à TRUE
  if (andExclusif && isEqualsLength && choices.length !== strings.length) return false;

  // Dans le cas ou on est dans l'opérateur EQUALS et qu'on une liste de chaines de caractères...
  if (!andExclusif && isEqualsLength && choices.length !== 1) return false;

  // On vérifie selon le andExcusif avec l'opérateur "every" ou "some"
  return andExclusif
    ? strings.every((str: string) => choices.map(({ value }: Choice) => value ?? '').includes(str))
    : strings.some((str: string) => choices.map(({ value }: Choice) => value ?? '').includes(str));
};
