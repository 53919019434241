import { LogLevel } from '@azure/msal-browser';
import { MsalInfos } from '90.quickConnect.Models/models/user/msalConfig';
import { MsalAuthConfig } from '90.quickConnect.Models/models/user/MsalAuthConfig';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

const tag = '90.quickConnect.Models/mappings/others/mapAADConfig.ts';

const mapMsalAuthConfig = (msalInfos: MsalInfos): MsalAuthConfig => {
  const logger = CustomLogger.getInstance();
  return {
    auth: {
      clientId: msalInfos.spa.clientId ?? '',
      clientSecret: msalInfos.spa.clientSecret ?? '',
      authority: msalInfos.authority ?? '',
      redirectUri: msalInfos.spa.redirectUri ?? '',
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              logger.error(tag, message);
              return;
            case LogLevel.Info:
              logger.info(tag, message);
              return;
            case LogLevel.Verbose:
              logger.debug(tag, message);
              return;
            case LogLevel.Warning:
              logger.warn(tag, message);
              return;
          }
        },
      },
    },
  };
};

export default mapMsalAuthConfig;
