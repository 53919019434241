import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, Accordion, AccordionDetails, AccordionSummary, Tooltip, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TodoListQcFieldName } from './const';
import useData from './hooks';
import TaskItems from './TaskList';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { TodoListDesc } from '90.quickConnect.Models/models';

const TodoListQcField = ({ field, setSelectedIndex, updateDeclaration, labelClass }: ValueFieldsTypesProps) => {
  const { t } = useTranslation('declaration');
  const todoListDesc = field as TodoListDesc;
  const { id, userComment, useVariable, fullPathId } = todoListDesc;
  const { setLocalTodoTask, localTodoTask, inprogressTask, finishedTasks, show, handleShow } = useData(
    todoListDesc,
    updateDeclaration,
  );

  const nameWithId = `${TodoListQcFieldName}-${id}`;
  return (
    <Box className={labelClass} id={`scrollToAnchor-${fullPathId}`} style={{ width: '100%' }}>
      {inprogressTask?.length > 0 &&
        inprogressTask?.map((item) => (
          <Box key={item.id} sx={{ width: '100%', mb: '1rem' }}>
            {userComment ? (
              <Accordion data-cy={nameWithId}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel1a-content-${nameWithId}`}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>{item.taskName}</Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TaskItems
                    field={todoListDesc}
                    setSelectedIndex={setSelectedIndex}
                    updateDeclaration={updateDeclaration}
                    item={item}
                    setLocalTodoTask={setLocalTodoTask}
                    listTasks={inprogressTask}
                    localTodoTask={localTodoTask}
                  />
                </AccordionDetails>
              </Accordion>
            ) : (
              <TaskItems
                field={todoListDesc}
                setSelectedIndex={setSelectedIndex}
                updateDeclaration={updateDeclaration}
                item={item}
                setLocalTodoTask={setLocalTodoTask}
                listTasks={inprogressTask}
                localTodoTask={localTodoTask}
              />
            )}
          </Box>
        ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', m: '1rem' }}>
        <Tooltip title={show ? t('formlib_hide_done_task_button_text') : t('formlib_show_done_task_button_text')}>
          <Button variant="contained" onClick={handleShow}>
            {show ? t('formlib_hide_done_task_button_text') : t('formlib_show_done_task_button_text')}
          </Button>
        </Tooltip>
      </Box>
      {show &&
        finishedTasks?.length > 0 &&
        finishedTasks?.map((item) => (
          <Box key={item.id} sx={{ width: '100%', mb: '1rem' }}>
            {userComment ? (
              <Accordion data-cy={nameWithId}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel1a-content-${nameWithId}`}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>{item.taskName}</Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TaskItems
                    field={todoListDesc}
                    setSelectedIndex={setSelectedIndex}
                    updateDeclaration={updateDeclaration}
                    item={item}
                    setLocalTodoTask={setLocalTodoTask}
                    listTasks={inprogressTask}
                    localTodoTask={localTodoTask}
                  />
                </AccordionDetails>
              </Accordion>
            ) : (
              <TaskItems
                field={todoListDesc}
                setSelectedIndex={setSelectedIndex}
                updateDeclaration={updateDeclaration}
                item={item}
                setLocalTodoTask={setLocalTodoTask}
                listTasks={finishedTasks}
                localTodoTask={localTodoTask}
              />
            )}
          </Box>
        ))}
    </Box>
  );
};
export default observer(TodoListQcField);
