import { mapEntityBase } from '..';
import { GenericInboxItem } from '90.quickConnect.Models/models';

const mapGenericInboxItem = (inboxItem: GenericInboxItem): GenericInboxItem =>
  ({
    ...mapEntityBase(inboxItem),
    inboxType: inboxItem.inboxType,
    sender: inboxItem.sender,
    subject: inboxItem.subject,
    sentAt: inboxItem.sentAt ? new Date(inboxItem.sentAt) : new Date(),
    userUPN: inboxItem.userUPN,
    priority: inboxItem.priority,
    replyTo: inboxItem.replyTo,
    formId: inboxItem.formId,
    activityId: inboxItem.activityId,
    parentActivityId: inboxItem.parentActivityId,
    itemData: inboxItem.itemData,
    entitySchemaId: inboxItem.entitySchemaId,
    entityInstanceId: inboxItem.entityInstanceId,
  } as GenericInboxItem);

export default mapGenericInboxItem;
