import React, { CSSProperties, FunctionComponent } from 'react';
import { Button, Grid2, Stack, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import WorkSiteTopBar from '../../../shared/TopBar/WorkSiteTopBar';
import useWorkSiteListData from './hookWorkSiteList';
import WorkSiteCard from './WorkSiteCard';

const containerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexGrow: 1,
  flexWrap: 'wrap',
  width: '100%',
  height: '100%',
  padding: '2rem',
};

const WorkSiteList: FunctionComponent = () => {
  const { workSiteList } = useWorkSiteListData();
  const { t } = useTranslation('moduleWorkSite');
  const theme = useTheme();
  return (
    <>
      <WorkSiteTopBar
        bgColor={theme.palette.success.light}
        leftElement={
          <Stack>
            <Typography fontWeight={'bold'} sx={{ textTransform: 'uppercase' }}>
              {t('work_site_list_title_message')}
            </Typography>
            <Typography>{t('work_site_list_sub_message')}</Typography>
          </Stack>
        }
        rightElement={
          <Button variant={'contained'} startIcon={<AddIcon />}>
            {t('work_site_list_new_worksite')}
          </Button>
        }
      />
      <Grid2 container style={containerStyle} spacing={'2rem'} rowSpacing={0}>
        {workSiteList.map((site) => {
          return (
            <WorkSiteCard
              key={site.id}
              id={site.id}
              icon={site.icon}
              name={site.name}
              published={site.published}
              status={site.status}
            />
          );
        })}
      </Grid2>
    </>
  );
};

export default WorkSiteList;
