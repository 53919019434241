import { TFunction } from 'i18next';
import { ChangeEvent, useMemo, useState } from 'react';
import { SortDirection } from '@mui/material';
import { HeadCell, UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { ColumnType } from '90.quickConnect.Models/enums';
import { QCDocument } from '90.quickConnect.Models/models';

const useData = (t: TFunction, qcDocuments: QCDocument[]): UseDataProps => {
  // eslint-disable-next-line no-empty-pattern
  const {} = useStore();

  // On set le state
  const [order, setOrder] = useState<SortDirection>('asc');
  const [orderBy, setOrderBy] = useState<keyof QCDocument>('lastModified');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const headCells = useMemo(
    () =>
      [
        {
          id: 'name',
          type: ColumnType.String,
          hideOnMobile: false,
        },
        {
          id: 'title',
          type: ColumnType.String,
          hideOnMobile: true,
        },
        {
          id: 'lastModified',
          type: ColumnType.Date,
          hideOnMobile: false,
        },
        {
          id: 'contentType',
          type: ColumnType.String,
          hideOnMobile: true,
        },
        {
          id: 'size',
          type: ColumnType.Number,
          hideOnMobile: true,
        },
        {
          id: 'show',
          type: ColumnType.String,
          hideOnMobile: false,
        },
      ] as HeadCell[],
    [],
  );

  // On ajoute des hooks
  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof QCDocument) => {
    setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - qcDocuments.length) : 0;

  const createSortHandler = (property: keyof QCDocument) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return {
    headCells,
    orderBy,
    order,
    emptyRows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    createSortHandler,
  };
};

export default useData;
