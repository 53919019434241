import React from 'react';
import useData from './hooks';
import { ComputedFieldResolverProps } from './types';

/**
 * Ce composant permet d'avoir un useEffect différent pour chaque ComputedField
 * @param param0
 * @returns
 */
const ComputedFieldResolver = ({
  computeField,
  updateDeclaration,
  updateFieldErrors,
  otherFields,
}: ComputedFieldResolverProps) => {
  useData(computeField, updateDeclaration, updateFieldErrors, otherFields);
  return <></>;
};

export default ComputedFieldResolver;
