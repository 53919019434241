import { Form } from './declarations/form';
import { Choice } from './declarations/choice';
import { QCAction } from './declarations/qcAction';
import { SharedList } from './declarations/sharedList';
import { AttributeValue } from './equipments/attributeValue';
import { EntityData } from './equipments/entityData';
import { EntitySchema } from './equipments/entitySchema';
import { EntitySchemaAttribute } from './equipments/entitySchemaAttribute';
import { BaseQCOperator } from './fields/conditions/baseQCOperator';
import { DualOperator } from './fields/conditions/dualOperator';
import { VisibilityType } from './fields/conditions/visibilityType';
import { MultipleOperator } from './fields/conditions/multipleOperator';
import { ConstNumberOperator } from './fields/conditions/specifics/constNumberOperator';
import { CtrlRefOperator } from './fields/conditions/specifics/ctrlRefOperator';
import { CtrlRefUserParemeterOperator } from './fields/conditions/specifics/ctrlRefUserParemeterOperator';
import { IIfOperator } from './fields/conditions/specifics/iIfOperator';
import { DataSourceField } from './fields/dataSourceField';
import { QCDocument } from './documents/qcDocument';
import { QCAttachment } from './attachments/qcAttachment';
import { AllFieldValueTypes } from './fields/fieldDesc/allFieldValueType';
import { FieldDesc } from './fields/fieldDesc/fieldDesc';
import { FieldData } from './fields/fieldData';
import { MetadataDesc } from './attachments/metadatadesc/metadataDesc';
import { ToolTipContainer } from './fields/toolTipContainer';
import { NotificationTarget } from './fields/values/notificationTarget';
import { SearchFamillyData } from './fields/values/searchFamillyData';
import { Position } from './fields/values/position';
import { AttachmentItemData } from './attachments/attachmentItemData';
import { QCNotification } from './fields/values/qcNotification';
import { TranslationStatus } from './translations/translationStatus';
import { GenericInboxItem } from './inboxs/genericInboxItem';
import { NamedUserParameterValue } from './user/namedUserParameterValue';
import { OrganizationalUnitInfo } from './user/organizationalUnitInfo';
import { EntityBase } from './common/entityBase';
import { KeyValuePair } from './common/keyValuePair';
import { WithChildren } from './common/withChildren';
import { FileIndex } from './documents/fileIndex';
import { OrderBy } from './requests/orderBy';
import { ColumnFilter } from './requests/columnFilter';
import { QCTranslation } from './translations/qcTranslation';
import { MsalConfig } from './user/msalConfig';
import { MsalConfigDevice } from './user/msalConfigDevice';
import { Customer } from './user/customer';
import { SecurityScheme } from './user/securityScheme';
import { ConstStringOperator } from './fields/conditions/specifics/constStringOperator';
import { BaseContainerDesc } from './fields/fieldDesc/containerField/baseContainerDesc';
import { GroupDesc } from './fields/fieldDesc/containerField/groupDesc';
import { CheckBoxDesc } from './fields/fieldDesc/editableFields/checkBoxDesc';
import { ComboDesc } from './fields/fieldDesc/editableFields/comboDesc';
import { DateTimeDesc } from './fields/fieldDesc/editableFields/dateTimeDesc';
import { DigitsDesc } from './fields/fieldDesc/editableFields/digitsDesc';
import { EditDesc } from './fields/fieldDesc/editableFields/editDesc';
import { NumericDesc } from './fields/fieldDesc/editableFields/numericDesc';
import { LabelDesc } from './fields/fieldDesc/otherFIelds/labelDesc';
import { NotificationDesc } from './fields/fieldDesc/editableFields/notificationDesc';
import { WithId } from './common/withId';
import { StepDesc } from './fields/fieldDesc/containerField/stepDesc';
import { DeclarationContext } from './declarations/declarationContext';
import { CounterDesc } from './fields/fieldDesc/editableFields/counterDesc';
import { AddressDesc } from './fields/fieldDesc/editableFields/addressDesc';
import { AddressData } from './fields/values/addressData';
import { CountedList } from './common/CountedList';
import { FormEditedData } from './declarations/formEditedData';
import { FormEditedDataDTO, convertFromFormEditedData } from './declarations/dto/formEditedDataDTO';
import { HistoricalStepData } from './inboxs/historicalStepData';
import { ItemData } from './inboxs/itemDataWorkFlow';
import { WorkflowData } from './inboxs/workflowData';
import { EditedBaseData } from './inboxs/editedBaseData';
import { InboxData } from './inboxs/inboxData';
import { TodoListTaskEditedDataDto } from './inboxs/todoListTaskEditedData';
import { TodoListEditedData } from './inboxs/todoListEditedData';
import { FormViewer } from './declarations/formViewer';
import { HierarchicalDesc } from './fields/fieldDesc/editableFields/hierarchicalDesc';
import { HierarchicalChoice } from './declarations/hierarchicalChoice';
import { InboxViewer } from './inboxs/inboxViewer';
import { DeclarationViewer } from './declarations/declarationViewer';
import { DocumentMetadataDTO, DocumentIndexDTO, StorageInfoDTO } from './documents/documentIndexDTO';
import { Dependency } from './fields/conditions/dependency';
import { Dependencies } from './fields/conditions/dependencies';
import { RepeatableGroupDesc } from './fields/fieldDesc/containerField/repeatableGroupDesc';
import { ComputeDesc } from './fields/fieldDesc/otherFIelds/computeDesc';
import { BaseAttachmentDesc } from './fields/fieldDesc/attachmentField/baseAttachmentDesc';
import { TimeDesc } from './fields/fieldDesc/editableFields/timeDesc';
import { AttachmentDesc } from './fields/fieldDesc/attachmentField/attachmentDesc';
import { PhotoDesc } from './fields/fieldDesc/attachmentField/photoDesc';
import { DrawDesc } from './fields/fieldDesc/attachmentField/drawDesc';
import { IncludeDesc } from './fields/fieldDesc/containerField/includeDesc';
import { Reference } from './fields/conditions/reference';
import { EntityInstanceSearch } from './equipments/entityInstanceSearch';
import { FormContextDTO } from './declarations/dto/formContextDto';
import { DeclarationContextDclDTO } from './declarations/dto/declarationContextDclDto';
import { InboxContextDTO } from './declarations/dto/inboxContextDto';
import { DeclarationContextContainer } from './declarations/declarationContextContainer';
import { LocalBackupDeclaration } from './declarations/localBackupDeclaration';
import { GeolocationData } from './fields/values/geolocationData';
import { CodeReaderDesc } from './fields/fieldDesc/editableFields/codeReaderDesc';
import { FixedImageDesc } from './fields/fieldDesc/attachmentField/fixedImageDesc';
import { TodoListDesc } from './fields/fieldDesc/editableFields/todoListDesc';
import { QcActionDesc } from './fields/fieldDesc/editableFields/QcActionDesc';
import { CtrlRefDateTime } from './fields/fieldDesc/otherTypes/CtrlRefDateTime';
import { DialogDesc } from '90.quickConnect.Models/models/fields/fieldDesc/containerField/dialogDesc';
import { ToastItem } from '90.quickConnect.Models/models/toastify';
import { QCActionData, QCActionValueData } from '90.quickConnect.Models/models/fields/values/QCActionData';
import { ConsentData } from '90.quickConnect.Models/models/consent/consentData';
import {
  TaskEditedDataType,
  TodoListTask,
  TodoTask,
} from '90.quickConnect.Models/models/fields/values/todoListEditedData';
import { QCErrorHTTP } from '90.quickConnect.Models/models/errors/implementation/QCErrorHTTP';
import { BaseListDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/baseListDesc';
import { CheckBoxListDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/checkBoxListDesc';
import { ImagesGroupDesc } from '90.quickConnect.Models/models/fields/fieldDesc/containerField/imagesGroupDesc';

// Dossier common
export type {
  EntityBase,
  KeyValuePair,
  WithChildren,
  WithId,
  CountedList,
  Form,
  Choice,
  QCAction,
  SharedList,
  DeclarationContext,
  FormContextDTO,
  DeclarationContextDclDTO,
  InboxContextDTO,
  DeclarationContextContainer,
  FormEditedData,
  FormEditedDataDTO,
  FileIndex,
  QCDocument,
  QCAttachment,
  MetadataDesc,
  AttributeValue,
  EntityData,
  EntitySchema,
  EntitySchemaAttribute,
  ConstNumberOperator,
  ConstStringOperator,
  CtrlRefOperator,
  CtrlRefUserParemeterOperator,
  IIfOperator,
  BaseQCOperator,
  DualOperator,
  MultipleOperator,
  VisibilityType,
  BaseContainerDesc,
  GroupDesc,
  StepDesc,
  AddressDesc,
  DigitsDesc,
  DialogDesc,
  EditDesc,
  NumericDesc,
  DateTimeDesc,
  ComboDesc,
  CounterDesc,
  CheckBoxDesc,
  LabelDesc,
  AddressData,
  NotificationTarget,
  SearchFamillyData,
  Position,
  AttachmentItemData,
  QCNotification,
  DataSourceField,
  FieldDesc,
  FieldData,
  ToolTipContainer,
  AllFieldValueTypes,
  ColumnFilter,
  OrderBy,
  QCTranslation,
  TranslationStatus,
  MsalConfig as AADConfig,
  MsalConfigDevice as AADConfigDevice,
  Customer,
  GenericInboxItem,
  NamedUserParameterValue,
  OrganizationalUnitInfo,
  SecurityScheme,
  HistoricalStepData,
  ItemData,
  WorkflowData,
  EditedBaseData,
  TodoListEditedData,
  TodoListTaskEditedDataDto,
  FormViewer,
  HierarchicalDesc,
  HierarchicalChoice,
  InboxViewer,
  InboxData,
  DeclarationViewer,
  DocumentIndexDTO,
  DocumentMetadataDTO,
  StorageInfoDTO,
  Dependency,
  Dependencies,
  RepeatableGroupDesc,
  ComputeDesc,
  TimeDesc,
  BaseAttachmentDesc,
  AttachmentDesc,
  PhotoDesc,
  DrawDesc,
  IncludeDesc,
  Reference,
  EntityInstanceSearch,
  LocalBackupDeclaration,
  GeolocationData,
  CodeReaderDesc,
  FixedImageDesc,
  NotificationDesc,
  TodoListDesc,
  QcActionDesc,
  ToastItem,
  QCActionData,
  QCActionValueData,
  CtrlRefDateTime,
  ConsentData,
  TodoTask,
  TodoListTask,
  TaskEditedDataType,
  BaseListDesc,
  CheckBoxListDesc,
  ImagesGroupDesc,
};

export { convertFromFormEditedData, QCErrorHTTP };
