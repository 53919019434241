import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { ComboQcFieldName } from './const';
import { Choice, ComboDesc, FieldDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { debounceTime } from '50.quickConnect.Fields/const';
import { isAnArrayOfChoice, isChoice } from '90.quickConnect.Models/guards';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';

const useData = (
  field: FieldDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère le store
  const {
    SharedListStore: { getSharedList },
  } = useStore();

  // On récupère la translation
  const { t } = useTranslation('declaration');

  const comboDesc = useMemo(() => field as ComboDesc, [field]);
  const { id, fullPathId, toolTip, label, listId, listChoice, value } = useMemo(() => comboDesc, [comboDesc]);
  const nameWithId = `${ComboQcFieldName}-${id}`;

  // label¤valeur pour traiter le cas de doublons dans les listes, mais seule la valeur est stockée
  const [localValue, setLocalValue] = useState<string>(
    value ? `${(value as Choice).label}¤${(value as Choice).value}` : '',
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const [choices, setChoices] = useState<Choice[] | undefined>(listChoice);
  const [listExistedChoise, setListExistedChoise] = useState<Choice[] | undefined>(listChoice);

  const { updateProperty } = useQCUpdateFunctionsContext();
  // On définit les callbacks
  // Update du formulaire au global
  const updateGlobalState = useCallback(() => {
    const selectedChoice = (choices ?? []).find((c) => `${c.label}¤${c.value}` === localValue);
    updateDeclaration(fullPathId, selectedChoice);
  }, [choices, updateDeclaration, fullPathId, localValue]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  // Récupération des choices
  const lookedForList = useRef(false);
  useEffect(() => {
    if (listId && lookedForList.current === false) {
      getSharedList(listId).then((sharedList) => {
        if (sharedList) {
          setListExistedChoise(sharedList.data);
          setChoices(sharedList.data);
          updateProperty(fullPathId, 'listChoice', sharedList.data);
        } else setChoices([{ label: 'not found', value: 'string' } as Choice]);
      });
      lookedForList.current = true;
    }
  }, [listId, getSharedList, choices, updateProperty, fullPathId]);

  const updateLocalState = useCallback(
    ({ target: { value: newValue } }: SelectChangeEvent<string>) => {
      setLocalValue(newValue);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );

  useEffect(() => {
    if (isChoice(value))
      setLocalValue((previousValue) =>
        previousValue !== `${value.label}¤${value.value}` ? `${value.label}¤${value.value}` : previousValue,
      );
    else setLocalValue('');
  }, [value]);

  const handleSeach = useCallback(
    (valueToSearch: string) => {
      setChoices(
        listExistedChoise?.filter((f) =>
          f.label.toLowerCase().replace(/\s/g, '').includes(valueToSearch.toLowerCase().replace(/\s/g, '')),
        ),
      );
    },
    [listExistedChoise],
  );

  const handleClear = useCallback(() => {
    setSearchValue('');
    if (listId) {
      getSharedList(listId).then((sharedList) => {
        if (sharedList) setChoices(sharedList.data);
        else setChoices([{ label: 'not found', value: 'string' } as Choice]);
      });
    }
  }, [getSharedList, listId]);

  const debouncedSearch = useDebouncedCallback((valueToSearch) => {
    handleSeach(valueToSearch);
  }, debounceTime);
  useEffect(() => {
    if (searchValue) {
      debouncedSearch(searchValue);
    }
  }, [debouncedSearch, searchValue]);
  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    updateGlobalState,
    updateLocalState,
    choices,
    nameWithId,
    toolTip,
    label,
    id,
    handleSeach,
    setSearchValue,
    searchValue,
    handleClear,
  };
};

export default useData;
