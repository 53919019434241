import { ChangeEvent, useCallback, useEffect, useMemo, useState, useRef, FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { AllFieldValueTypes, NumericDesc, FieldDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { useStore } from '30.quickConnect.Stores';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';

const useData = (
  numericDesc: NumericDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  flattenFields?: FieldDesc[],
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, reference } = numericDesc;

  // Custom Hooks
  const { isQCScriptForm } = useQCSFormEvent();

  const {
    DeclarationStore: { requiredField, verifRequiredField, isDeepLink, fieldFocused, setFieldFocused },
    DialogStore: { setFullPathIdForFocus },
  } = useStore();

  const isEditingRef = useRef<boolean>(false);

  const [localValue, setLocalValue] = useState<string>(
    typeof value === 'number' ? value.toString() : typeof value === 'string' ? (value as string) : '',
  );

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      isEditingRef.current = true;
      const nextLocaleValue = event.target.value;

      setLocalValue(() => nextLocaleValue);

      // Si le champ possède le focus, on le retire
      if (fieldFocused === fullPathId) setFieldFocused(undefined);

      debouncedUpdateGlobalState();
    },
    [setLocalValue, debouncedUpdateGlobalState, fullPathId, fieldFocused, setFieldFocused],
  );

  const clearState = useCallback(() => {
    setLocalValue('');
    debouncedUpdateGlobalState();
  }, [setLocalValue, debouncedUpdateGlobalState]);

  const onBlur = useCallback(() => {
    setFieldFocused(undefined);
  }, [setFieldFocused]);

  // UseEffect pour le système des références et QCScript...
  useEffect(() => {
    if (value === null && isDeepLink) setLocalValue('');

    if ((reference || isQCScriptForm) && !isEditingRef.current) {
      setLocalValue((prevState: string) => {
        if (typeof value === 'undefined') return '';
        if (value === null) return '';
        if (typeof value === 'string') {
          if (value === '') return '';
          if ((value as string).endsWith('.') || (value as string).endsWith(',')) return value;
          return Number.isInteger(+value) ? (+value).toFixed(1) : value;
        } else {
          return Number.isInteger(value) ? (value as number).toFixed(1) : (value as number).toString();
        }
      });
    }
    isEditingRef.current = false;
  }, [reference, value, isQCScriptForm, isDeepLink]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    clearState,
    handleChange,
    requiredField,
    verifRequiredField,
    fieldFocused,
    onBlur,
  };
};

export default useData;
