import { EntityInstanceSearch } from '90.quickConnect.Models/models';

const splitArrayByAttribute = (mapped: EntityInstanceSearch[]): EntityInstanceSearch[][] => {
  let object = [];
  const result = [];
  for (let i = 0; i < mapped.length; i++) {
    if (i !== 0 && mapped[i].code !== mapped[i - 1].code) {
      //Returning true if it's just numbers
      result.push(object);
      object = [];
      object.push(mapped[i]);
    } else {
      object.push(mapped[i]);
    }
  }
  result.push(object);

  return result;
};

export default splitArrayByAttribute;
