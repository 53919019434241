import React, { useMemo } from 'react';
import { UseDataProps } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/DialogSummary/types';
import { DialogSummaryName } from '50.quickConnect.Fields/FieldsTypes/Containers/DialogQcField/DialogSummary/const';
import { FieldDesc } from '90.quickConnect.Models/models';
import { isAContainerFieldWithTodoList, isAValueEmpty, isEmptyFieldValue } from '90.quickConnect.Models/guards';
import { flatten } from '80.quickConnect.Core/helpers';

const useData = (items: FieldDesc[], parentId: string): UseDataProps => {
  const nameWithId = `${DialogSummaryName}-${parentId}`;

  const itemsToDisplay = useMemo(
    () =>
      flatten(items, (i) => i.items)
        .filter((f) => !isAContainerFieldWithTodoList(f))
        .filter((f) => !isEmptyFieldValue(f.value))
        .filter((f: FieldDesc) => f.isVisible),
    [items],
  );

  const hasItemsToDisplay = useMemo(
    () => items.some(({ value, isVisible }: FieldDesc) => isVisible && !isAValueEmpty(value)),
    [items],
  );

  return {
    nameWithId,
    hasItemsToDisplay,
    itemsToDisplay,
  };
};

export default useData;
