import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';
import { JsonWrapper } from '80.quickConnect.Core/helpers';
import { isCoordinates, isRecord } from '90.quickConnect.Models/guards';
import { GeolocationData } from '90.quickConnect.Models/models';

export function mapToStringFromCoordinates({ longitude, latitude }: GeolocationData): string {
  const lonDble = new DoubleExtension(longitude);
  const latDble = new DoubleExtension(latitude);

  return `${DoubleExtension.convertToLitteral(latDble) ?? ''};${DoubleExtension.convertToLitteral(lonDble) ?? ''}`;
}

export function mapToCoordinatesFromString(value: string): GeolocationData | undefined {
  if (value.startsWith('{')) return JsonWrapper.parse(isCoordinates)(value);

  const strigifiedCoordinates = value.split(new RegExp('[\\s;]', 'g'));

  if (strigifiedCoordinates.length !== 2) return;

  const latitude = DoubleExtension.createDoubleValue(strigifiedCoordinates[0]);
  const longitude = DoubleExtension.createDoubleValue(strigifiedCoordinates[1]);

  return !Number.isNaN(longitude) && !Number.isNaN(latitude) ? { longitude, latitude } : undefined;
}

export function mapToCoordinatesFromHashMap(value: Record<string, unknown>): GeolocationData | undefined {
  const longitude = 'longitude' in value ? DoubleExtension.createDoubleValue(value.longitude) : undefined;
  const latitude = 'latitude' in value ? DoubleExtension.createDoubleValue(value.latitude) : undefined;

  return !Number.isNaN(longitude) && !Number.isNaN(latitude)
    ? {
        longitude,
        latitude,
      }
    : undefined;
}

export function mapAllFieldTypeValuesToCoordinates(value: unknown): GeolocationData | undefined {
  if (isCoordinates(value)) return value;
  else if (typeof value === 'string') return mapToCoordinatesFromString(value);
  else if (isRecord(value)) return mapToCoordinatesFromHashMap(value);
}
