import { Choice, HierarchicalChoice } from '90.quickConnect.Models/models';
import { flatten, arrayIntersect } from '80.quickConnect.Core/helpers';

export default function findChoiceInHierarchicalChoice(
  hierarchicalChoices: HierarchicalChoice[],
  choicesToFind: Choice[],
): Choice[] {
  const choices = flatten(hierarchicalChoices, (i) => i.children)
    .filter((i) => i.value && i.label)
    .map(
      (i) =>
        ({
          label: i.label,
          value: i.value,
          data: undefined,
        } as Choice),
    );

  const choicesFound = arrayIntersect(choices, choicesToFind, ['value', 'label']);

  return choicesFound;
}
