import { flatten } from '80.quickConnect.Core/helpers';
import { FieldDesc } from '90.quickConnect.Models/models';

export const mapGroupIsVisibleItems = (fields: FieldDesc[]): FieldDesc[] => {
  const filterByFullPathId = fields.reduce((acc: string[], field: FieldDesc): string[] => {
    const { isVisible, items } = field;

    if (items.length === 0 || isVisible) return acc;

    const fullPathIds = flatten(items, (i: FieldDesc) => i.items).map((f: FieldDesc) => f.fullPathId);

    return [...acc, ...fullPathIds];
  }, []);

  return fields.filter((f: FieldDesc): boolean => filterByFullPathId.includes(f.fullPathId) === false);
};
