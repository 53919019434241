import { ImagesGroupDesc } from '90.quickConnect.Models/models';
import { mapBaseContainerDesc } from '90.quickConnect.Models/mappings';

export default function mapToImagesGroupDesc(input: ImagesGroupDesc): ImagesGroupDesc {
  return {
    ...mapBaseContainerDesc(input),
    imagesBankId: input.imagesBankId ?? undefined,
    imagesMap: input.imagesMap ?? undefined,
  };
}
