import React from 'react';
import { observer } from 'mobx-react';
import {
  useTheme,
  Box,
  Autocomplete,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import useData from './hooks';
import { TextQcFieldName } from './const';
import { OptionsRender } from './types';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { EditDesc } from '90.quickConnect.Models/models';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import { DataSourceType } from '90.quickConnect.Models/enums';
import HistoryQCField from '50.quickConnect.Fields/FieldsTypes/Others/HistoryQC';

const TextQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  labelClass,
  selectedIndex,
}: ValueFieldsTypesProps) => {
  const editDesc = field as EditDesc;
  const { toolTip, id, lines, dataSourceType, dataSource, fullPathId } = editDesc;
  const nameWithId = `${TextQcFieldName}-${id}`;
  const {
    textLocalValue,
    t,
    clearState,
    outlinedInputChanged,
    autocompleteChanged,
    autoCompleteProp,
    isEventClick,
    searchAllUO,
    handleOnChangeSearchAllUO,
    requiredField,
    verifRequiredField,
    fieldFocused,
    onBlur,
    lruList,
    showLruList,
    toggleDisplayLruList,
    deleteLRUItem,
  } = useData(editDesc, updateDeclaration);

  const { breakpoints } = useTheme();

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      data-cy={nameWithId}
      sx={FieldMainBox(breakpoints)}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      {dataSourceType === DataSourceType.Family && dataSource && dataSource.idDataSource && (
        <FormControlLabel
          sx={{ mb: '1.3em' }}
          control={
            <Checkbox checked={searchAllUO} data-cy={`${nameWithId}-checkox`} onChange={handleOnChangeSearchAllUO} />
          }
          label={t('formlib_mail_info_datasource_search_all_uo')}
          disabled={isReadOnly}
        />
      )}
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      {dataSourceType === DataSourceType.Family && dataSource && dataSource.idDataSource ? (
        <Autocomplete
          data-cy={`${nameWithId}-ul`}
          value={textLocalValue}
          filterOptions={(x) => x}
          getOptionLabel={(option: string | OptionsRender) =>
            typeof option !== 'string' ? option.value : option ?? ''
          }
          fullWidth
          inputValue={textLocalValue}
          onInputChange={autocompleteChanged}
          options={autoCompleteProp}
          freeSolo={true}
          open={
            textLocalValue.length > 2 &&
            !isEventClick &&
            autoCompleteProp.length > 0 &&
            autoCompleteProp.every((v) => v !== null) &&
            selectedIndex === fullPathId
          }
          readOnly={isReadOnly}
          disabled={isReadOnly}
          clearOnBlur={true}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={(option as OptionsRender).id}
                id={(option as OptionsRender).id}
                data-cy={`${nameWithId}-li-${(option as OptionsRender).id}`}
              >
                <div>
                  <Typography fontWeight="bold">{(option as OptionsRender).title}</Typography>
                  <Typography fontWeight="400">{(option as OptionsRender).description}</Typography>
                </div>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              data-cy={`${nameWithId}-input`}
              onBlur={onBlur}
              inputRef={(input) => {
                if (input != null && verifRequiredField && requiredField?.fullPathId === fullPathId) {
                  input.focus();
                } else if (
                  input != null &&
                  !verifRequiredField &&
                  !requiredField &&
                  fieldFocused &&
                  fullPathId.includes(fieldFocused)
                ) {
                  return input.focus();
                }
              }}
              {...params}
            />
          )}
        />
      ) : dataSourceType === DataSourceType.AzureAD ? (
        <Autocomplete
          id={`${nameWithId}-autocomplete-azure`}
          value={textLocalValue}
          filterOptions={(x) => x}
          getOptionLabel={(option: string | OptionsRender) =>
            typeof option !== 'string' ? option.value : option ?? ''
          }
          fullWidth
          inputValue={textLocalValue}
          onInputChange={autocompleteChanged}
          options={autoCompleteProp}
          freeSolo={true}
          open={
            textLocalValue.length > 2 &&
            !isEventClick &&
            autoCompleteProp.length > 0 &&
            autoCompleteProp.every((v) => v !== null) &&
            selectedIndex === fullPathId
          }
          readOnly={isReadOnly}
          disabled={isReadOnly}
          clearOnBlur={true}
          renderOption={(props, option) => (
            <li
              {...props}
              key={(option as OptionsRender).id}
              id={(option as OptionsRender).id}
              data-cy={`${nameWithId}-li-${(option as OptionsRender).id}`}
            >
              <div>
                <Typography fontWeight="bold">{(option as OptionsRender).title}</Typography>
                <Typography fontWeight="400">{(option as OptionsRender).description}</Typography>
              </div>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              data-cy={`${nameWithId}-input-autocomplete-azure`}
              inputRef={(input) => {
                if (input != null && verifRequiredField && requiredField?.fullPathId === fullPathId) {
                  input.focus();
                } else if (
                  input != null &&
                  !verifRequiredField &&
                  !requiredField &&
                  fieldFocused &&
                  fullPathId.includes(fieldFocused)
                ) {
                  return input.focus();
                }
              }}
              {...params}
            />
          )}
        />
      ) : (
        <Box>
          <Box sx={FlexRow}>
            <OutlinedInput
              inputRef={(input) => {
                if (input != null && verifRequiredField && requiredField?.fullPathId === fullPathId) {
                  input.focus();
                } else if (
                  input != null &&
                  !verifRequiredField &&
                  !requiredField &&
                  fieldFocused &&
                  fullPathId === fieldFocused
                ) {
                  return input.focus();
                }
              }}
              data-cy={`${nameWithId}-input`}
              id={`${nameWithId}-input`}
              type={'text'}
              value={textLocalValue}
              placeholder={t("input_text'").toString()}
              required
              onBlur={onBlur}
              sx={{ flex: 1 }}
              onChange={outlinedInputChanged}
              multiline={lines && lines > 1 ? true : false}
              rows={lines && lines > 1 ? lines : undefined}
              inputProps={{
                readOnly: isReadOnly,
                disabled: isReadOnly,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title={t('formlib_array_deleted_title').toString()}>
                    <span>
                      <IconButton data-cy={`${nameWithId}-clear`} edge="end" onClick={clearState} disabled={isReadOnly}>
                        <ClearIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              }
            />
            {lruList.length > 0 && (
              <IconButton
                data-cy={`${nameWithId}-search-lru`}
                edge="end"
                onClick={toggleDisplayLruList}
                disabled={isReadOnly}
              >
                <ManageSearchIcon />
              </IconButton>
            )}
          </Box>
          {showLruList && (
            <Box>
              <HistoryQCField
                disabled={isReadOnly}
                prevValue={lruList}
                onTagChange={autocompleteChanged}
                handleDeleteItem={deleteLRUItem}
                fieldFullId={fullPathId}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default observer(TextQcField);
