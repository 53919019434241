import { useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseQCOperator, Dependencies, FieldDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import evaluateVisibility from '20.formLib/helpers/evals/evaluateVisibility';
import evalDependencies from '20.formLib/helpers/evalDependencies/evalDependencies';

const useData = (
  id: string,
  fullPathId: string,
  visible: BaseQCOperator,
  updateFieldVisibility: (hidableFieldFullPathId: string, newVisibility: boolean) => void,
  flattenFields: FieldDesc[],
): void => {
  const {
    LoginStore: {
      signInInfos: { userParameterValue, userUPN, email },
    },
  } = useStore();

  const previousEvalResult = useRef<boolean | undefined>();

  const { t } = useTranslation('declaration');

  const visibilityDependencies = useMemo(() => {
    const dependencies = evalDependencies(visible, [], flattenFields, fullPathId);
    const newVisibilityDependency: Dependencies = {
      id: id,
      fullPathId: fullPathId,
      rule: visible,
      dependencies: dependencies,
    };
    return newVisibilityDependency;
  }, [visible, flattenFields, id, fullPathId]);

  useEffect(() => {
    if (visibilityDependencies) {
      const { rule, dependencies } = visibilityDependencies;
      const evalResult = evaluateVisibility(t, rule, dependencies, userParameterValue, fullPathId, userUPN, email);
      if (previousEvalResult.current !== evalResult) {
        updateFieldVisibility(fullPathId, evalResult);
        previousEvalResult.current = evalResult;
      }
    }
  }, [visibilityDependencies, updateFieldVisibility, userParameterValue, t, fullPathId, userUPN, email]);
};

export default useData;
