import React from 'react';
import { observer } from 'mobx-react';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { AddressInputFieldProps } from './types';
import useData from './hooks';

const AddressInputField = ({ parentNameWithId, type, currentValue, updateState }: AddressInputFieldProps) => {
  const { t } = useTranslation('declaration');
  const { nameForDataCy, labelForInputLabel, multiline, value } = useData(currentValue, type);

  return (
    <FormControl data-cy={`${parentNameWithId}-${nameForDataCy}`} variant="outlined" fullWidth margin="dense">
      <InputLabel htmlFor={`${parentNameWithId}-${nameForDataCy}-inputlabel`}>{labelForInputLabel}</InputLabel>
      <OutlinedInput
        data-cy={`${parentNameWithId}-${nameForDataCy}-input`}
        id={`${parentNameWithId}-${nameForDataCy}-input`}
        type={'text'}
        value={value ?? ''}
        label={labelForInputLabel}
        multiline={multiline}
        onChange={(_event) => {
          updateState(_event, type);
        }}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={t('formlib_array_deleted_title').toString()}>
              <IconButton
                data-cy={`${parentNameWithId}-${nameForDataCy}-clear-btn`}
                edge="end"
                onClick={() => {
                  updateState(undefined, type);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
export default observer(AddressInputField);
