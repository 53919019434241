// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function isEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (a === undefined || a === null || b === undefined || b === null) {
    return false;
  }

  if (a.constructor !== b.constructor) {
    return false;
  }

  // because of closures
  if (a instanceof Function || b instanceof Function) {
    return a === b;
  }

  if (a instanceof RegExp && b instanceof RegExp) {
    return a.source === b.source && a.flags === b.flags;
  }

  if (a.valueOf() === b.valueOf()) {
    return true;
  }

  // dates must had equal valueOf()
  if (a instanceof Date) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    return a.every((x, i) => isEqual(x, b[i]));
  }

  if (!(a instanceof Object) || !(b instanceof Object)) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  if (keysA.length !== keysB.length) {
    return false;
  }

  if (keysA.some((v) => !keysB.includes(v))) {
    return false;
  }

  return keysA.every((v) => isEqual(a[v], b[v]));
}
