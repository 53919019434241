// TODO: Revoir l'import quand la lib sera Implementé dans le client Web
import { QCSBaseObject } from './QCSBaseObject';
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSString } from './QCSString';
import { Choice } from '90.quickConnect.Models/models/declarations/choice';

export class QCSChoice extends QCSBaseTypedObject<Choice> {
  public constructor(c: Choice) {
    super(c);
    this.value = c;
  }

  public static staticCall = (methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => null;

  public callQCSObject = (methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null => {
    switch (methodId) {
      case 1: // getChoiceValue
        return new QCSString(this.value.value);

      case 2: // getChoiceLabel
      case 3: //getLabel
        return new QCSString(this.value.label);

      case 4: //getPropertyValue
        return new QCSString(this.getPropertyValue(qcParams));

      default:
        return null;
    }
  };

  private getPropertyValue = (qcParams: Array<QCSBaseObject>): string => {
    if (qcParams[0]! instanceof QCSString) {
      const propName: string = (qcParams[0] as QCSString).value;
      if (this.value === null || this.value.data === undefined) return '';
      // Retourne la valeur du dico
      return (this.value.data as Record<string, string | number | boolean>)[propName].toString() ?? '';
    }
    return '';
  };
}
