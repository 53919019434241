import React from 'react';

export const LogoStandardMediumSvg = () => {
  return (
    <svg width="125" height="75" viewBox="0 0 506 253" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="126.5" width="506" height="126.5" fill="white" />
      <rect x="126.5" width="379.5" height="126.5" fill="#464B47" />
      <path d="M0 50.6C0 22.6544 22.6544 0 50.6 0H126.5V126.5H0V50.6Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.85 12.65H50.6C29.6408 12.65 12.65 29.6408 12.65 50.6V113.85H113.85V12.65ZM50.6 0C22.6544 0 0 22.6544 0 50.6V126.5H126.5V0H50.6Z"
        fill="#17DCB8"
      />
      <path
        // eslint-disable-next-line max-len
        d="M212.375 113.724C211.658 113.724 211.048 113.473 210.547 112.971C210.045 112.469 209.794 111.824 209.794 111.035V86.9498C208.074 89.2437 205.851 91.0716 203.127 92.4336C200.475 93.7239 197.321 94.369 193.665 94.369C190.009 94.369 186.784 93.7597 183.988 92.5411C181.192 91.3225 178.827 89.6021 176.891 87.3799C174.956 85.1577 173.486 82.5054 172.483 79.423C171.479 76.3407 170.906 72.9715 170.762 69.3157C170.691 67.882 170.655 66.5559 170.655 65.3373C170.655 64.1187 170.691 62.8284 170.762 61.4664C170.906 57.5955 171.479 54.1188 172.483 51.0364C173.558 47.9541 175.028 45.3376 176.891 43.1871C178.827 40.9649 181.192 39.2804 183.988 38.1334C186.784 36.9148 190.009 36.3055 193.665 36.3055C197.751 36.3055 201.156 37.0582 203.88 38.5635C206.676 39.9972 208.898 41.861 210.547 44.1548V40.0689C210.547 39.2804 210.798 38.6352 211.299 38.1334C211.801 37.6317 212.446 37.3808 213.235 37.3808H223.45C224.238 37.3808 224.883 37.6317 225.385 38.1334C225.887 38.6352 226.138 39.2804 226.138 40.0689V111.035C226.138 111.824 225.887 112.469 225.385 112.971C224.883 113.473 224.238 113.724 223.45 113.724H212.375ZM198.504 81.466C201.084 81.466 203.163 80.8926 204.74 79.7456C206.389 78.5987 207.608 77.165 208.396 75.4446C209.256 73.6525 209.722 71.7529 209.794 69.7458C209.866 68.3838 209.902 67.0218 209.902 65.6598C209.902 64.2979 209.866 62.9717 209.794 61.6814C209.722 59.5309 209.292 57.5238 208.504 55.66C207.787 53.7962 206.604 52.2551 204.955 51.0364C203.378 49.8178 201.228 49.2085 198.504 49.2085C195.852 49.2085 193.701 49.8178 192.052 51.0364C190.475 52.1834 189.328 53.7246 188.612 55.66C187.966 57.5955 187.572 59.7101 187.429 62.004C187.285 64.2262 187.285 66.4484 187.429 68.6705C187.572 70.9644 187.966 73.0791 188.612 75.0145C189.328 76.95 190.475 78.527 192.052 79.7456C193.701 80.8926 195.852 81.466 198.504 81.466Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M260.231 94.369C256.073 94.369 252.381 93.4371 249.156 91.5734C246.002 89.6379 243.529 86.8781 241.736 83.2939C240.016 79.6381 239.156 75.2296 239.156 70.0684V40.0689C239.156 39.2804 239.407 38.6352 239.909 38.1334C240.41 37.6317 241.02 37.3808 241.736 37.3808H253.134C253.923 37.3808 254.568 37.6317 255.07 38.1334C255.571 38.6352 255.822 39.2804 255.822 40.0689V69.4232C255.822 77.4518 259.335 81.466 266.36 81.466C269.729 81.466 272.381 80.4266 274.317 78.3478C276.324 76.1973 277.327 73.2224 277.327 69.4232V40.0689C277.327 39.2804 277.578 38.6352 278.08 38.1334C278.582 37.6317 279.227 37.3808 280.015 37.3808H291.413C292.13 37.3808 292.739 37.6317 293.241 38.1334C293.743 38.6352 293.994 39.2804 293.994 40.0689V90.6057C293.994 91.3942 293.743 92.0393 293.241 92.5411C292.739 93.0429 292.13 93.2938 291.413 93.2938H280.876C280.087 93.2938 279.442 93.0429 278.94 92.5411C278.438 92.0393 278.187 91.3942 278.187 90.6057V86.4122C276.324 88.9928 273.922 90.9641 270.983 92.326C268.044 93.688 264.46 94.369 260.231 94.369Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M310.107 93.2938C309.39 93.2938 308.781 93.0429 308.279 92.5411C307.778 92.0393 307.527 91.3942 307.527 90.6057V40.0689C307.527 39.2804 307.778 38.6352 308.279 38.1334C308.781 37.6317 309.39 37.3808 310.107 37.3808H320.86C321.648 37.3808 322.293 37.6317 322.795 38.1334C323.297 38.6352 323.548 39.2804 323.548 40.0689V90.6057C323.548 91.3942 323.297 92.0393 322.795 92.5411C322.293 93.0429 321.648 93.2938 320.86 93.2938H310.107ZM309.785 28.7788C309.068 28.7788 308.459 28.5279 307.957 28.0261C307.455 27.5243 307.204 26.8792 307.204 26.0906V17.4886C307.204 16.7001 307.455 16.055 307.957 15.5532C308.459 14.9797 309.068 14.693 309.785 14.693H321.075C321.863 14.693 322.508 14.9797 323.01 15.5532C323.584 16.055 323.87 16.7001 323.87 17.4886V26.0906C323.87 26.8792 323.584 27.5243 323.01 28.0261C322.508 28.5279 321.863 28.7788 321.075 28.7788H309.785Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M362.291 94.369C357.058 94.369 352.435 93.4013 348.421 91.4658C344.478 89.5304 341.36 86.7347 339.066 83.0789C336.772 79.3514 335.518 74.907 335.303 69.7458C335.231 68.5989 335.195 67.1652 335.195 65.4448C335.195 63.6527 335.231 62.1832 335.303 61.0363C335.518 55.8034 336.736 51.359 338.958 47.7032C341.252 43.9756 344.406 41.1441 348.421 39.2087C352.435 37.2732 357.058 36.3055 362.291 36.3055C367.094 36.3055 371.18 36.9865 374.549 38.3485C377.918 39.6388 380.678 41.2875 382.829 43.2946C384.979 45.3018 386.556 47.4164 387.56 49.6386C388.635 51.7891 389.208 53.6887 389.28 55.3374C389.352 56.0543 389.101 56.6994 388.527 57.2729C388.026 57.7747 387.416 58.0256 386.7 58.0256H375.194C374.478 58.0256 373.904 57.8464 373.474 57.4879C373.116 57.0578 372.757 56.4844 372.399 55.7675C371.395 53.2586 370.105 51.4665 368.528 50.3913C366.951 49.316 364.979 48.7784 362.614 48.7784C359.388 48.7784 356.843 49.8178 354.98 51.8966C353.116 53.9755 352.112 57.2012 351.969 61.5739C351.897 64.3695 351.897 66.9143 351.969 69.2082C352.184 73.6525 353.188 76.8783 354.98 78.8854C356.843 80.8926 359.388 81.8961 362.614 81.8961C365.123 81.8961 367.13 81.3585 368.635 80.2832C370.141 79.208 371.395 77.4159 372.399 74.907C372.685 74.1902 373.044 73.6525 373.474 73.2941C373.904 72.864 374.478 72.649 375.194 72.649H386.7C387.416 72.649 388.026 72.9357 388.527 73.5092C389.101 74.011 389.352 74.6203 389.28 75.3371C389.208 76.5557 388.85 77.9894 388.205 79.6381C387.631 81.2868 386.664 82.9714 385.302 84.6918C384.011 86.4122 382.327 88.0251 380.248 89.5304C378.169 90.9641 375.624 92.1468 372.614 93.0787C369.675 93.9389 366.234 94.369 362.291 94.369Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M402.514 93.2938C401.726 93.2938 401.08 93.0429 400.579 92.5411C400.077 92.0393 399.826 91.3942 399.826 90.6057V19.6391C399.826 18.8506 400.077 18.2055 400.579 17.7037C401.08 17.2019 401.726 16.951 402.514 16.951H413.052C413.768 16.951 414.378 17.2019 414.879 17.7037C415.381 18.2055 415.632 18.8506 415.632 19.6391V55.9826L432.191 39.2087C432.764 38.7069 433.266 38.2768 433.696 37.9184C434.198 37.56 434.951 37.3808 435.954 37.3808H448.105C448.822 37.3808 449.395 37.6317 449.825 38.1334C450.255 38.5635 450.47 39.1012 450.47 39.7463C450.47 40.033 450.399 40.3556 450.255 40.714C450.112 41.0725 449.861 41.3592 449.503 41.5742L428.75 62.4341L452.191 89.2078C452.836 89.7813 453.158 90.3548 453.158 90.9282C453.158 91.5734 452.907 92.1468 452.406 92.6486C451.976 93.0787 451.438 93.2938 450.793 93.2938H438.32C437.245 93.2938 436.456 93.1146 435.954 92.7562C435.524 92.3977 435.022 91.9676 434.449 91.4658L415.632 70.3909V90.6057C415.632 91.3942 415.381 92.0393 414.879 92.5411C414.378 93.0429 413.768 93.2938 413.052 93.2938H402.514Z"
        fill="white"
      />
      <path
        // eslint-disable-next-line max-len
        d="M71.1435 220.869C65.9107 220.869 61.2871 219.901 57.2728 217.966C53.3302 216.03 50.212 213.235 47.9181 209.579C45.6243 205.851 44.3698 201.407 44.1548 196.246C44.0831 195.099 44.0472 193.665 44.0472 191.945C44.0472 190.153 44.0831 188.683 44.1548 187.536C44.3698 182.303 45.5884 177.859 47.8106 174.203C50.1045 170.476 53.2585 167.644 57.2728 165.709C61.2871 163.773 65.9107 162.805 71.1435 162.805C75.9463 162.805 80.0323 163.486 83.4014 164.848C86.7705 166.139 89.5303 167.787 91.6808 169.795C93.8313 171.802 95.4084 173.916 96.4119 176.139C97.4872 178.289 98.0606 180.189 98.1323 181.837C98.204 182.554 97.9531 183.199 97.3796 183.773C96.8779 184.275 96.2686 184.525 95.5517 184.525H84.0465C83.3297 184.525 82.7562 184.346 82.3261 183.988C81.9677 183.558 81.6093 182.984 81.2509 182.267C80.2473 179.759 78.957 177.966 77.38 176.891C75.803 175.816 73.8317 175.278 71.4661 175.278C68.2404 175.278 65.6956 176.318 63.8318 178.397C61.9681 180.475 60.9645 183.701 60.8211 188.074C60.7495 190.869 60.7495 193.414 60.8211 195.708C61.0362 200.152 62.0398 203.378 63.8318 205.385C65.6956 207.392 68.2404 208.396 71.4661 208.396C73.975 208.396 75.9822 207.858 77.4875 206.783C78.9929 205.708 80.2473 203.916 81.2509 201.407C81.5376 200.69 81.896 200.152 82.3261 199.794C82.7562 199.364 83.3297 199.149 84.0465 199.149H95.5517C96.2686 199.149 96.8779 199.436 97.3796 200.009C97.9531 200.511 98.204 201.12 98.1323 201.837C98.0606 203.056 97.7022 204.489 97.0571 206.138C96.4836 207.787 95.5159 209.471 94.1539 211.192C92.8636 212.912 91.179 214.525 89.1002 216.03C87.0214 217.464 84.4766 218.647 81.4659 219.579C78.5269 220.439 75.0861 220.869 71.1435 220.869Z"
        fill="#464B47"
      />
      <path
        // eslint-disable-next-line max-len
        d="M131.043 220.869C125.094 220.869 120.112 219.901 116.097 217.966C112.083 215.959 109.001 213.127 106.85 209.471C104.771 205.815 103.624 201.55 103.409 196.676C103.338 195.242 103.302 193.629 103.302 191.837C103.302 189.973 103.338 188.361 103.409 186.999C103.624 182.052 104.843 177.787 107.065 174.203C109.287 170.547 112.406 167.752 116.42 165.816C120.434 163.809 125.309 162.805 131.043 162.805C136.706 162.805 141.545 163.809 145.559 165.816C149.574 167.752 152.692 170.547 154.914 174.203C157.136 177.787 158.355 182.052 158.57 186.999C158.713 188.361 158.785 189.973 158.785 191.837C158.785 193.629 158.713 195.242 158.57 196.676C158.355 201.55 157.172 205.815 155.021 209.471C152.943 213.127 149.896 215.959 145.882 217.966C141.868 219.901 136.921 220.869 131.043 220.869ZM131.043 209.041C134.556 209.041 137.208 207.966 139 205.815C140.792 203.593 141.76 200.368 141.903 196.138C141.975 195.063 142.011 193.629 142.011 191.837C142.011 190.045 141.975 188.611 141.903 187.536C141.76 183.379 140.792 180.189 139 177.966C137.208 175.744 134.556 174.633 131.043 174.633C127.531 174.633 124.843 175.744 122.979 177.966C121.187 180.189 120.219 183.379 120.076 187.536C120.004 188.611 119.968 190.045 119.968 191.837C119.968 193.629 120.004 195.063 120.076 196.138C120.219 200.368 121.187 203.593 122.979 205.815C124.843 207.966 127.531 209.041 131.043 209.041Z"
        fill="#464B47"
      />
      <path
        // eslint-disable-next-line max-len
        d="M169.385 219.794C168.668 219.794 168.059 219.543 167.557 219.041C167.056 218.539 166.805 217.894 166.805 217.106V166.569C166.805 165.78 167.056 165.135 167.557 164.633C168.059 164.132 168.668 163.881 169.385 163.881H179.923C180.711 163.881 181.356 164.132 181.858 164.633C182.36 165.135 182.611 165.78 182.611 166.569V170.762C184.475 168.468 186.876 166.569 189.815 165.063C192.754 163.558 196.338 162.805 200.568 162.805C204.797 162.805 208.489 163.773 211.643 165.709C214.868 167.572 217.377 170.332 219.169 173.988C221.033 177.572 221.965 181.945 221.965 187.106V217.106C221.965 217.894 221.678 218.539 221.105 219.041C220.603 219.543 219.994 219.794 219.277 219.794H207.879C207.091 219.794 206.446 219.543 205.944 219.041C205.442 218.539 205.191 217.894 205.191 217.106V187.751C205.191 183.952 204.259 181.013 202.395 178.934C200.603 176.784 197.951 175.708 194.439 175.708C191.069 175.708 188.381 176.784 186.374 178.934C184.367 181.013 183.364 183.952 183.364 187.751V217.106C183.364 217.894 183.113 218.539 182.611 219.041C182.109 219.543 181.5 219.794 180.783 219.794H169.385Z"
        fill="#464B47"
      />
      <path
        // eslint-disable-next-line max-len
        d="M234.203 219.794C233.486 219.794 232.877 219.543 232.375 219.041C231.873 218.539 231.622 217.894 231.622 217.106V166.569C231.622 165.78 231.873 165.135 232.375 164.633C232.877 164.132 233.486 163.881 234.203 163.881H244.74C245.529 163.881 246.174 164.132 246.676 164.633C247.177 165.135 247.428 165.78 247.428 166.569V170.762C249.292 168.468 251.693 166.569 254.632 165.063C257.571 163.558 261.156 162.805 265.385 162.805C269.614 162.805 273.306 163.773 276.46 165.709C279.686 167.572 282.195 170.332 283.987 173.988C285.851 177.572 286.782 181.945 286.782 187.106V217.106C286.782 217.894 286.496 218.539 285.922 219.041C285.42 219.543 284.811 219.794 284.094 219.794H272.697C271.908 219.794 271.263 219.543 270.761 219.041C270.259 218.539 270.009 217.894 270.009 217.106V187.751C270.009 183.952 269.077 181.013 267.213 178.934C265.421 176.784 262.768 175.708 259.256 175.708C255.887 175.708 253.199 176.784 251.192 178.934C249.184 181.013 248.181 183.952 248.181 187.751V217.106C248.181 217.894 247.93 218.539 247.428 219.041C246.926 219.543 246.317 219.794 245.6 219.794H234.203Z"
        fill="#464B47"
      />
      <path
        // eslint-disable-next-line max-len
        d="M321.385 220.869C313.142 220.869 306.583 218.575 301.708 213.987C296.905 209.4 294.361 202.733 294.074 193.988C294.074 193.414 294.074 192.662 294.074 191.73C294.074 190.726 294.074 189.938 294.074 189.364C294.361 183.844 295.615 179.113 297.837 175.171C300.059 171.157 303.178 168.11 307.192 166.031C311.206 163.881 315.902 162.805 321.278 162.805C327.299 162.805 332.317 164.06 336.331 166.569C340.417 169.006 343.464 172.375 345.471 176.676C347.55 180.905 348.589 185.78 348.589 191.3V193.773C348.589 194.489 348.302 195.099 347.729 195.601C347.227 196.102 346.618 196.353 345.901 196.353H310.74C310.74 196.425 310.74 196.568 310.74 196.783C310.74 196.927 310.74 197.07 310.74 197.213C310.812 199.507 311.242 201.622 312.031 203.557C312.891 205.421 314.074 206.927 315.579 208.073C317.156 209.22 319.02 209.794 321.17 209.794C322.962 209.794 324.432 209.543 325.579 209.041C326.726 208.539 327.658 207.93 328.374 207.213C329.163 206.496 329.736 205.887 330.095 205.385C330.74 204.669 331.242 204.238 331.6 204.095C332.03 203.88 332.675 203.772 333.536 203.772H344.718C345.435 203.772 346.009 203.988 346.439 204.418C346.94 204.776 347.155 205.314 347.084 206.03C347.012 207.177 346.403 208.611 345.256 210.331C344.181 211.98 342.568 213.629 340.417 215.278C338.267 216.855 335.579 218.181 332.353 219.256C329.199 220.331 325.543 220.869 321.385 220.869ZM310.74 186.568H331.815V186.353C331.815 183.773 331.385 181.551 330.525 179.687C329.736 177.751 328.554 176.246 326.977 175.171C325.4 174.096 323.5 173.558 321.278 173.558C319.056 173.558 317.156 174.096 315.579 175.171C314.002 176.246 312.783 177.751 311.923 179.687C311.135 181.551 310.74 183.773 310.74 186.353V186.568Z"
        fill="#464B47"
      />
      <path
        // eslint-disable-next-line max-len
        d="M380.95 220.869C375.717 220.869 371.093 219.901 367.079 217.966C363.137 216.03 360.018 213.235 357.725 209.579C355.431 205.851 354.176 201.407 353.961 196.246C353.889 195.099 353.854 193.665 353.854 191.945C353.854 190.153 353.889 188.683 353.961 187.536C354.176 182.303 355.395 177.859 357.617 174.203C359.911 170.476 363.065 167.644 367.079 165.709C371.093 163.773 375.717 162.805 380.95 162.805C385.753 162.805 389.839 163.486 393.208 164.848C396.577 166.139 399.337 167.787 401.487 169.795C403.638 171.802 405.215 173.916 406.218 176.139C407.294 178.289 407.867 180.189 407.939 181.837C408.01 182.554 407.759 183.199 407.186 183.773C406.684 184.275 406.075 184.525 405.358 184.525H393.853C393.136 184.525 392.563 184.346 392.133 183.988C391.774 183.558 391.416 182.984 391.057 182.267C390.054 179.759 388.763 177.966 387.186 176.891C385.609 175.816 383.638 175.278 381.273 175.278C378.047 175.278 375.502 176.318 373.638 178.397C371.774 180.475 370.771 183.701 370.628 188.074C370.556 190.869 370.556 193.414 370.628 195.708C370.843 200.152 371.846 203.378 373.638 205.385C375.502 207.392 378.047 208.396 381.273 208.396C383.781 208.396 385.789 207.858 387.294 206.783C388.799 205.708 390.054 203.916 391.057 201.407C391.344 200.69 391.702 200.152 392.133 199.794C392.563 199.364 393.136 199.149 393.853 199.149H405.358C406.075 199.149 406.684 199.436 407.186 200.009C407.759 200.511 408.01 201.12 407.939 201.837C407.867 203.056 407.509 204.489 406.863 206.138C406.29 207.787 405.322 209.471 403.96 211.192C402.67 212.912 400.985 214.525 398.907 216.03C396.828 217.464 394.283 218.647 391.272 219.579C388.333 220.439 384.893 220.869 380.95 220.869Z"
        fill="#464B47"
      />
      <path
        // eslint-disable-next-line max-len
        d="M442.14 219.794C437.696 219.794 433.896 219.077 430.742 217.643C427.588 216.138 425.187 213.844 423.538 210.762C421.961 207.679 421.173 203.808 421.173 199.149V176.569H412.786C411.997 176.569 411.352 176.318 410.85 175.816C410.348 175.314 410.098 174.669 410.098 173.881V166.569C410.098 165.78 410.348 165.135 410.85 164.633C411.352 164.132 411.997 163.881 412.786 163.881H421.173V146.139C421.173 145.351 421.424 144.705 421.925 144.204C422.499 143.702 423.144 143.451 423.861 143.451H434.398C435.187 143.451 435.832 143.702 436.334 144.204C436.835 144.705 437.086 145.351 437.086 146.139V163.881H450.527C451.244 163.881 451.853 164.132 452.355 164.633C452.857 165.135 453.108 165.78 453.108 166.569V173.881C453.108 174.669 452.857 175.314 452.355 175.816C451.853 176.318 451.244 176.569 450.527 176.569H437.086V197.859C437.086 200.583 437.552 202.733 438.484 204.31C439.488 205.815 441.208 206.568 443.645 206.568H451.495C452.212 206.568 452.821 206.819 453.323 207.321C453.824 207.823 454.075 208.432 454.075 209.149V217.106C454.075 217.894 453.824 218.539 453.323 219.041C452.821 219.543 452.212 219.794 451.495 219.794H442.14Z"
        fill="#464B47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.2802 44.275H37.9501V31.625H94.8751V88.55H82.2251V53.2199L36.8881 98.5568L27.9432 89.6119L73.2802 44.275Z"
        fill="#17DCB8"
      />
    </svg>
  );
};
