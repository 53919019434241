import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, ListItem, ListItemAvatar, ListItemButton, useTheme } from '@mui/material';

import { FormLineName } from './const';
import useData from './hooks';
import DeclarationAlreadySavedDialog from '10.quickConnect.app/components/shared/Dialogs/DeclarationAlreadySavedDialog';

import { getFormIcon } from '10.quickConnect.app/components/domain/Home/helpers';
import {
  CustomListItemText,
  getAvatarStyle as getAvatarStyle,
  getListItemAvatarStyle,
  getListItemButtonStyle,
} from '10.quickConnect.app/components/domain/Home/styles';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { FormViewer } from '90.quickConnect.Models/models';

const FormLine = (form: TreeDataItem) => {
  const { id, formType, name, description } = form as FormViewer;

  const { choiceDialogOpen, declarations, onClickForm, toggleChoiceDialog } = useData(form as FormViewer);

  const theme = useTheme();

  return (
    <ListItem data-cy={FormLineName} disablePadding>
      <ListItemButton
        sx={getListItemButtonStyle(theme)}
        data-cy={`${FormLineName}-${id}`}
        onContextMenu={(event) => event.preventDefault()}
        onClick={onClickForm}
      >
        <ListItemAvatar sx={getListItemAvatarStyle(theme)}>
          <Avatar sx={getAvatarStyle(theme)}>{getFormIcon(formType)}</Avatar>
        </ListItemAvatar>
        <CustomListItemText primary={name} secondary={description} />
      </ListItemButton>
      <DeclarationAlreadySavedDialog
        declarations={declarations}
        form={form as FormViewer}
        open={choiceDialogOpen}
        onClose={toggleChoiceDialog}
      />
    </ListItem>
  );
};

export default observer(FormLine);
