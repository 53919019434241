import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { CheckBoxDesc, AllFieldValueTypes } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { convertToBooleanValue } from '80.quickConnect.Core/helpers';

const useData = (
  checkBoxDesc: CheckBoxDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value, reference } = checkBoxDesc;

  const [localValue, setLocalValue] = useState((convertToBooleanValue(value) as boolean) ?? false);

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const updateState = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setLocalValue(checked);
      debouncedUpdateGlobalState();
    },
    [setLocalValue, debouncedUpdateGlobalState],
  );

  // UseEffect pour les références...
  useEffect(() => {
    const newLocalValue = convertToBooleanValue(value);
    setLocalValue((previousLocalState) =>
      previousLocalState !== newLocalValue ? newLocalValue ?? false : previousLocalState,
    );
  }, [value]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    updateState,
  };
};

export default useData;
