import { errorHandler } from '80.quickConnect.Core/helpers';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { mapQCNotification } from '90.quickConnect.Models/mappings';
import { NotificationTarget, QCNotification } from '90.quickConnect.Models/models';

const tag = '10.quickConnect.app/components/domain/Declaration/helpers/emailParsers/index.ts';

export const emailParse = (serializedEmails: string, logger: CustomLogger): QCNotification => {
  if (serializedEmails && serializedEmails !== '') {
    serializedEmails.trim();

    // serializedEmails peut etre au format
    // Elément unique { "autoSend": false,  "notificationType": 1, "searchAllUO": false,  "selectedTargets": ["email1", "email2", "email3"], "sendToMe": false, "subject": null }
    // ou simplement une chaine "email1, email2, email3"

    if (serializedEmails.startsWith('{')) {
      // Element unique
      try {
        const notificationValueObject = mapQCNotification(JSON.parse(serializedEmails));
        return notificationValueObject;
      } catch (error) {
        errorHandler(tag, error, 'emailParse');
      }
    } else {
      // Au format chaine directement
      const emailsStrSplitted = serializedEmails
        .split(',')
        .map((item) => item.trim()) as unknown as NotificationTarget[];

      return mapQCNotification({ selectedTargets: emailsStrSplitted });
    }
  }

  return mapQCNotification({});
};
