export enum InternalFieldState {
  Undef,
  Visible,
  ReadOnly,
  Mandatory,
  Valid,
}

export enum StateInternalData {
  Undef = 'undef',
  State = 'state',
  DataSource = 'ds',
  AutoCompleteSchema = 'acs',
  AutoCompleteContact = 'acc',
}
