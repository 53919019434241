import React from 'react';
import { useTheme, Box, IconButton, Drawer, Tabs, Tab } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import HardwareIcon from '@mui/icons-material/Hardware';
import { LeftMenuName } from './const';
import { LeftMenuDrawer } from './styles';
import { useData } from './hooks';
import DeclarationMenu from './DeclarationMenu';
import { MenuTabs } from './types';

const LeftMenu = () => {
  const { t } = useTranslation('common');
  // eslint-disable-next-line no-empty-pattern
  const { showLeftMenu, setShowLeftMenu, valueTabIndex, handleTabChange } = useData();

  const theme = useTheme();

  return (
    <Drawer data-cy={LeftMenuName} sx={LeftMenuDrawer(theme)} variant="persistent" anchor="left" open={showLeftMenu}>
      <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'sticky',
          }}
        >
          <Box sx={{ width: '90%' }}>
            <Tabs
              variant="fullWidth"
              value={valueTabIndex}
              onChange={(event, newValue) => {
                handleTabChange(newValue);
              }}
            >
              <Tab value={MenuTabs.Declarations} label={<ContentPasteIcon />} />
              <Tab value={MenuTabs.Equipments} label={<HardwareIcon />} />
            </Tabs>
          </Box>
          <Box>
            <IconButton onClick={() => setShowLeftMenu(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </Box>
        {valueTabIndex === MenuTabs.Declarations && <DeclarationMenu />}
      </Box>
    </Drawer>
  );
};

export default observer(LeftMenu);
