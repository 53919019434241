import React, { useMemo } from 'react';
import { Backdrop, Dialog, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HistoryBackDropProps } from './type';
import HistoryItem from './HistoryItem';
import { HistoryBackDropName } from './const';
import { FlexColumn } from '50.quickConnect.Fields/styles';

const HistoryBackDrop = ({ historicalStepDataList, openHistory, setOpenHistory }: HistoryBackDropProps) => {
  const { t } = useTranslation('inboxes');

  const sortedHistoricalStepDataList = useMemo(() => {
    if (historicalStepDataList.length > 1) {
      return historicalStepDataList.slice().sort((a, b) => {
        return b.editedAt && a.editedAt ? new Date(b.editedAt).getDate() - new Date(a.editedAt).getDate() : -1;
      });
    }
    return historicalStepDataList;
  }, [historicalStepDataList]);

  return (
    <Dialog
      data-cy={HistoryBackDropName}
      open={openHistory}
      sx={{ zIndex: '30', overflowY: 'scroll', marginTop: '100px' }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ ...FlexColumn, alignItems: 'center' }}>
          <Typography variant="h6">{t('ViewingHistory')}</Typography>
          <Typography>{t('qcapp_title_state_wkf')}</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => setOpenHistory(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ ...FlexColumn, alignItems: 'center' }}>
          {sortedHistoricalStepDataList.map(({ stepId, stepLabel, stepState, author, editedAt, comment }, index) => (
            <HistoryItem
              key={stepId}
              stepId={stepId}
              index={index}
              stepLabel={stepLabel}
              stepState={stepState}
              author={author}
              editedAt={editedAt}
              comment={comment}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default observer(HistoryBackDrop);
