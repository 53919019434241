import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import InboxTreeItemRenderer from '../InboxTreeItemRenderer';
import { TasksTreeName } from './const';
import { TasksTreeProps } from './types';
import ViewSkeleton from '10.quickConnect.app/components/domain/Home/Shared/Skeletons/viewSkeleton';
import CustomTree from '10.quickConnect.app/components/domain/Home/Shared/CustomTree';
import { getTreeBoxStyle } from '10.quickConnect.app/components/domain/Home/styles';
import { InboxViewer } from '90.quickConnect.Models/models';

// eslint-disable-next-line no-empty-pattern
const TasksTree = ({ TasksStore, title, cle }: TasksTreeProps) => {
  return (
    <Box
      data-cy={TasksTreeName}
      sx={{ ...getTreeBoxStyle(), height: { xs: 'unset', sm: 'unset', md: 'inherit', lg: 'inherit' } }}
    >
      {TasksStore.items ? (
        <CustomTree<InboxViewer>
          title={title}
          store={TasksStore}
          RenderTreeItem={InboxTreeItemRenderer}
          getName={(i: InboxViewer) => i.subject}
          cle={cle}
        />
      ) : (
        <ViewSkeleton />
      )}
    </Box>
  );
};
export default observer(TasksTree);
