import formatStringByPattern from 'format-string-by-pattern';
import equipmentsDb from '40.quickConnect.DataAccess/indexedDb/dbs/equipmentsDb';
import { EntitySchemaAttributeTypes, DateTimeFieldType, FieldType } from '90.quickConnect.Models/enums';
import { EquipementProperty } from '90.quickConnect.Models/enums/declarations/equipementProperty';
import {
  EntityData,
  FieldDesc,
  AttributeValue,
  Choice,
  HierarchicalChoice,
  AddressData,
  DateTimeDesc,
  QCNotification,
  NotificationTarget,
} from '90.quickConnect.Models/models';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import isHierarchicalChoice from '90.quickConnect.Models/guards/fields/isHierarchicalChoice';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler, flatten } from '80.quickConnect.Core/helpers';
import { SignInResponse } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { UserProperty } from '90.quickConnect.Models/enums/declarations/userProperty';

// Tag
const tag = '30.quickConnect.Stores/helpers/getValueForCalculateLabel.ts';

const getAttributeValue = ({ type, value }: AttributeValue, format?: string): string => {
  switch (type) {
    case EntitySchemaAttributeTypes.Bool:
      return JSON.stringify(value as boolean);

    case EntitySchemaAttributeTypes.DateTime:
    case EntitySchemaAttributeTypes.Date:
      if (value instanceof Date) {
        if (isNaN(value.getTime())) return '';

        const datetimeAttributeType =
          type === EntitySchemaAttributeTypes.DateTime ? DateTimeFieldType.DateTime : DateTimeFieldType.Date;
        return DateTimeExtension.formatHumanReadeable(value, datetimeAttributeType);
      }

      return '';

    case EntitySchemaAttributeTypes.Decimal:
    case EntitySchemaAttributeTypes.Int:
      if (format) {
        const fmt = formatStringByPattern(format);
        return fmt(value as number);
      }

      return (value as number).toString();

    case EntitySchemaAttributeTypes.Email:
      return typeof value === 'string' ? value : '';

    case EntitySchemaAttributeTypes.SharedList:
      return (value as Choice).label;

    case EntitySchemaAttributeTypes.SharedListHierarchical:
      if (Array.isArray(value))
        return (value as HierarchicalChoice[])
          .map((hierarchicalChoice: HierarchicalChoice) => hierarchicalChoice.label)
          .join(', ');

      if (isHierarchicalChoice(value)) return value.label;

      return '';

    case EntitySchemaAttributeTypes.String:
      return value as string;
    default:
      return '';
  }
};

const getFieldValue = (field: FieldDesc, format?: string): string | never => {
  const { fieldType, value } = field;

  if (value === undefined || value === null) return '';

  const logger: CustomLogger = CustomLogger.getInstance();
  switch (fieldType) {
    case FieldType.Address: {
      const { streetNumber, street, complement, zipCode, city, country } = value as AddressData;

      return `${streetNumber} ${street} ${complement} ${zipCode} ${city} ${country}`;
    }

    case FieldType.Alert: {
      const alertValue = value as Choice;

      return alertValue.value ?? '';
    }

    case FieldType.CheckBox: {
      const checkboxValue = value as boolean;

      return checkboxValue ? 'True' : 'False';
    }

    case FieldType.CheckBoxList:
      return (value as Choice[]).map((choice: Choice) => choice.label).join(', ');

    case FieldType.CodeReader: {
      const codeValue = value as string;
      return codeValue;
    }

    case FieldType.RadioList:
    case FieldType.HierarchicalList:
    case FieldType.Combo: {
      if (fieldType === FieldType.HierarchicalList && value && Array.isArray(value)) {
        const hierachicalChoice = (value as HierarchicalChoice[])
          .map((hierarchicalChoice: HierarchicalChoice) => hierarchicalChoice.label)
          .join(', ');
        return hierachicalChoice ?? '';
      }
      const comboValue = value as Choice;

      return comboValue.label;
    }

    case FieldType.Compute: {
      const computeValue = value as string;

      return computeValue;
    }

    case FieldType.Counter: {
      const counterValue = value as number;

      return counterValue.toString();
    }

    case FieldType.DateTime: {
      const { type } = field as DateTimeDesc;
      const dtValue = value as Date;

      return DateTimeExtension.formatHumanReadeable(dtValue, type);
    }

    case FieldType.Numeric:
    case FieldType.Digits: {
      if (!format) return (value as number).toString();

      return formatStringByPattern(format)(value as number);
    }

    case FieldType.Geolocation: {
      if (typeof value !== 'string') return '';

      return value;
    }

    case FieldType.Notification: {
      const { selectedTargets } = value as QCNotification;

      if (selectedTargets.length === 0) return '';

      return selectedTargets.map(({ target }: NotificationTarget): string => target).join(', ');
    }

    case FieldType.ReadOnlyValue:
    case FieldType.RfidReader:
      if (typeof value !== 'string') return '';

      return value;

    case FieldType.Slider: {
      if (typeof value !== 'number') return '';

      if (!format) return value.toString();

      return formatStringByPattern(format)(value);
    }

    case FieldType.Text:
    case FieldType.Time:
    case FieldType.TodoList:
      if (typeof value !== 'string') return '';

      return value;

    case FieldType.Unknow:
    case FieldType.Step:
    case FieldType.Signature:
    case FieldType.Separator:
    case FieldType.RepeatableGroup:
    case FieldType.Plugin:
    case FieldType.Photo:
    case FieldType.Label:
    case FieldType.Include:
    case FieldType.Group:
    case FieldType.FixedImage:
    case FieldType.FixedAttachment:
    case FieldType.Draw:
    case FieldType.Dialog:
    case FieldType.DataSource:
    case FieldType.Audio:
    case FieldType.Array:
    case FieldType.Attachment:
    case FieldType.Action:
    default:
      return '';
  }
};

const getEquipementTokensValues = (property: string, equipement: EntityData): string => {
  switch (property.toUpperCase()) {
    case EquipementProperty.CODE:
      return equipement?.code ?? '';
    case EquipementProperty.TITLE:
      return equipement?.title ?? '';
    default:
      const attribute: AttributeValue | undefined = equipement?.attributes.find(
        (v) => v.attributeLabel.toUpperCase() === property.toUpperCase(),
      );

      if (!attribute) return '';

      return getAttributeValue(attribute);
  }
};

const setUserInfoValueLabel = (property: string, userInfo: SignInResponse) => {
  switch (property) {
    case UserProperty.DCL_USER_EMAIL:
      return userInfo.email;
    case UserProperty.DCL_USER_UPN:
      return userInfo.userUPN;
    case UserProperty.DCL_USER_CONTACT:
      return `${userInfo.email} ${userInfo.phone}`;
    case UserProperty.DCL_USER_FIRSTNAME:
      return userInfo.firstName;
    case UserProperty.DCL_USER_LASTNAME:
      return userInfo.lastName;
    case UserProperty.DCL_USER_JOB:
      return userInfo.job;
    case UserProperty.DCL_USER_PHONE:
      return userInfo.phone;
    case UserProperty.DCL_USER_FULLNAME:
      return `${userInfo.lastName} ${userInfo.firstName}`;
    default:
      return '';
  }
};

export const buildCalculatedLabelWithEquipmentTokens = async (
  labelValue: string,
  equipementTokens: RegExpMatchArray,
  entityInstanceId?: string,
): Promise<string> => {
  try {
    const entityData: EntityData | undefined = await equipmentsDb.getEquipmentById(entityInstanceId);

    if (!entityData) return labelValue;

    // On crée un tableau par lequel on transforme le résultat du matcher
    let equipementTokensWOBrackets: string[] = [];
    equipementTokens.forEach((t) => {
      const currentToken = t.replace(/[[\]]/g, '');
      equipementTokensWOBrackets = [...equipementTokensWOBrackets, currentToken];
    });

    // On remplace dans le libellé calculé
    equipementTokensWOBrackets.forEach((twc) => {
      labelValue = labelValue.replace(`[${twc}]`, getEquipementTokensValues(twc, entityData));
    });

    return labelValue;
  } catch (error: unknown) {
    errorHandler(tag, error, 'buildCalculatedLabelWithEquipmentTokens');

    return labelValue;
  }
};

export const buildCalculatedLabelWithFieldTokens = (
  labelValue: string,
  fieldTokens: RegExpMatchArray[],
  declaration: FieldDesc[],
): string => {
  const flattenFields = flatten(declaration, (field: FieldDesc) => field.items);

  for (const token of fieldTokens) {
    let field: FieldDesc | undefined;

    if (!token.groups) {
      labelValue = labelValue.replace(token[0], '');
      continue;
    }

    const {
      groups: { fieldId, format },
    } = token;

    field = flattenFields.find(({ fullPathId }: FieldDesc) => {
      return fullPathId === fieldId;
    });

    // On n'a pas trouvé le champ par son fullId, on recherche par son ID!
    if (!field) {
      const fields = flattenFields.filter(({ id }: FieldDesc) => id === fieldId);

      // Dans le cas ou on ne trouve rien, on renvoie chaine vide
      if (fields.length === 0) {
        labelValue = labelValue.replace(token[0], '');
        continue;
      }

      // Sinon, on prend le dernier... (Comme sur mobile)
      const [last] = fields.reverse();

      field = last;
    }

    if (!field) {
      labelValue = labelValue.replace(token[0], '');
      continue;
    }

    const formatter: string | undefined = format === '' ? undefined : format;

    const valueToInsert = getFieldValue(field, formatter);

    labelValue = labelValue.replace(token[0], valueToInsert);
  }

  return labelValue;
};

export const buildCalculatedLabelWithSystemRefTokens = (
  labelValue: string,
  userInfoTokens: RegExpMatchArray,
  signInInfos: SignInResponse,
): string => {
  let userInfoTokensWOCurlyBraces: string[] = [];
  userInfoTokens.forEach((t) => {
    const currentToken = t.replace(/[()]/g, '').replace('SystemRef', '');
    userInfoTokensWOCurlyBraces = [...userInfoTokensWOCurlyBraces, currentToken];
  });

  userInfoTokensWOCurlyBraces.forEach((twc) => {
    labelValue = labelValue.replace(`SystemRef(${twc})`, setUserInfoValueLabel(twc, signInInfos) as string);
  });

  return labelValue;
};
