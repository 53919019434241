import { FieldType } from '90.quickConnect.Models/enums';
import { FieldData, AllFieldValueTypes } from '90.quickConnect.Models/models';

export const isFieldDataArray = (f: FieldData, value: AllFieldValueTypes | FieldData[]): value is FieldData[] =>
  (f.fieldType === FieldType.RepeatableGroup ||
    f.fieldType === FieldType.Include ||
    f.fieldType === FieldType.Step ||
    f.fieldType === FieldType.Group ||
    f.fieldType === FieldType.Dialog ||
    f.fieldType === FieldType.ImagesGroup ||
    f.fieldType === FieldType.Action) &&
  Array.isArray(value);
