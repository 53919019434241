import mapFieldDesc from './mapFieldDesc';
import { DeclarationContext, FieldDesc, QcActionDesc } from '90.quickConnect.Models/models';

const mapQcActionDesc = (
  field: QcActionDesc,
  context: DeclarationContext | undefined,
  parentId?: string,
): QcActionDesc => {
  const { actionName } = field;
  const criteria = context ? JSON.parse(context.actionDesc[actionName])?.criteria : null;
  return {
    actionDesc: field.actionDesc ?? undefined,
    actionName: field.actionName ?? '',
    actionLabel: field.actionLabel,
    callOnStart: field.callOnStart ?? false,
    isMultiSelection: field.isMultiSelection ?? false,
    isPopup: field.isPopup ?? true,
    items: criteria
      ? criteria.map((f: FieldDesc) => mapFieldDesc(f, parentId ?? field.id, context?.templateBodies, context))
      : [],
    value: field.value ?? undefined,
  } as QcActionDesc;
};

export default mapQcActionDesc;
