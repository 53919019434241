import { action, makeAutoObservable, observable } from 'mobx';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import RootStore from '30.quickConnect.Stores/RootStore';
import { API_HOST } from '40.quickConnect.DataAccess/axios/apiRoutes';

class CommonStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/CommonStore/index.ts';

  /** Stocke des UULID pour chaque tache en cours */
  loadingRequests: [string, string][] = [];

  fakeIMEI: string | undefined;

  logger: CustomLogger;

  showLeftMenu = false;

  RootStore: RootStore;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    makeAutoObservable(
      this,
      {
        loadingRequests: observable,
        fakeIMEI: observable,
        showLeftMenu: observable,

        setWorkInProgress: action,
        setWorkDone: action,
        setLoadingRequests: action,
        chooseBaseUrl: action,
      },
      { autoBind: true },
    );
  }

  resetStore = (): void => {
    this.loadingRequests = [];
    this.fakeIMEI = undefined;
    this.showLeftMenu = false;
  };

  setShowLeftMenu = (showLeftMenu: boolean) => (this.showLeftMenu = showLeftMenu);

  setLoadingRequests = (loadingRequests: [string, string][]) => (this.loadingRequests = loadingRequests);

  setWorkInProgress = (jobRequest: [string, string]): void => {
    const loadingRequestIndex = this.loadingRequests.findIndex((jb) => jb[0] === jobRequest[0]);
    if (loadingRequestIndex === -1) {
      this.loadingRequests.push(jobRequest);
    }
  };

  setWorkDone = (jobRequestId: string): void => {
    const loadingRequestIndex = this.loadingRequests.findIndex((jb) => jb[0] === jobRequestId);
    if (loadingRequestIndex > -1) {
      this.loadingRequests.splice(loadingRequestIndex, 1);
    }
  };

  chooseBaseUrl = (currentUrl: string) => {
    if (this.RootStore.LoginStore.urlToUse && API_HOST && this.RootStore.LoginStore.urlToUse !== API_HOST) {
      return currentUrl.replace(API_HOST, this.RootStore.LoginStore.urlToUse);
    }
    return currentUrl;
  };
}
export default CommonStore;
