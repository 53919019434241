import { useMemo } from 'react';
import { UseDataProps } from './types';
import { FieldDesc, ComputeDesc } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';

/**
 * Hook qui détermine les dépendances d'une règle de visibilité
 * @param flattenFields
 * @returns
 */
const useData = (flattenFields: FieldDesc[]): UseDataProps => {
  const allComputeFields = useMemo(
    () => flattenFields.filter((f) => f.fieldType === FieldType.Compute).map((f) => f as ComputeDesc),
    [flattenFields],
  );

  return { allComputeFields };
};

export default useData;
