import React from 'react';
import { observer } from 'mobx-react';
import { DialogContent, DialogContentText } from '@mui/material';
import { DialogChoiceContentProps } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';

const DialogChoiceContent = ({ name, children }: DialogChoiceContentProps) => (
  <DialogContent data-cy={`${name}-dialog-content`}>{children}</DialogContent>
);

export default observer(DialogChoiceContent);
