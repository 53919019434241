import { useCallback, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { validate } from 'uuid';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { SynchronizeEquipmentReturnType } from '90.quickConnect.Models/enums';
import useWindowSize from '80.quickConnect.Core/hooks/useWindowSize';
import { EQUIPMENT } from '10.quickConnect.app/routes/paths';
import { EntityData } from '90.quickConnect.Models/models';
import { useLogger } from '80.quickConnect.Core/logger';
import { errorHandler } from '80.quickConnect.Core/helpers';

const useData = (t: TFunction): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/QRCodeScan/hooks.ts';

  // On récupère le store
  const {
    EquipmentsStore: { synchronizeEquipmentsAsync },
  } = useStore();
  const logger = useLogger();

  // On set le state
  const { t: tAxios } = useTranslation('axios');
  const navigate = useNavigate();
  const [data, setData] = useState('');
  const [ratio, setRatio] = useState(1);
  const { width, height } = useWindowSize();
  const [code, setCode] = useState('');

  // On ajoute des hooks

  const handleScan = useCallback(
    (result: any, error: any) => {
      if (result) {
        const text = result.getText();
        if (text) {
          if (text) {
            const splitted = text.split('\\');
            if (splitted.length > 1 && validate(splitted[0]) && validate(splitted[1])) {
              setData(text);
            }
          }
        }
      }

      if (error) {
        errorHandler(tag, error, 'handleScan');

        toast.error(error);
      }
    },
    [tag],
  );

  const handleResponse = useCallback(
    (newData: EntityData | undefined): void => {
      if (newData) navigate(EQUIPMENT.replace(':schemaId', newData.entitySchemaId).replace(':entityId', newData.id));
    },
    [navigate],
  );

  useEffect(() => {
    if (data) {
      synchronizeEquipmentsAsync(data, '', '', SynchronizeEquipmentReturnType.All, undefined, tAxios).then((newData) =>
        handleResponse(newData),
      );
    }
  }, [handleResponse, data, synchronizeEquipmentsAsync, tAxios]);

  useEffect(() => {
    if (height && width) {
      if (width > height) {
        setRatio((height - 200) / width);
      } else {
        setRatio(1);
      }
    }
  }, [height, width]);

  const searchEquipment = useCallback((): void => {
    if (code) {
      //Ajout du tag "##" au code d'équipement pour le différentier côté API du QRcode
      synchronizeEquipmentsAsync(`##${code}`, '', '', SynchronizeEquipmentReturnType.All, undefined, tAxios).then(
        (newData) => handleResponse(newData),
      );
    }
  }, [code, handleResponse, synchronizeEquipmentsAsync, tAxios]);

  return {
    handleScan,
    width,
    ratio,
    code,
    setCode,
    searchEquipment,
  };
};

export default useData;
