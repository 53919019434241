import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import NumberExtension from '80.quickConnect.Core/formatting/NumberExtension';
import { isAddressData, isChoice, isChoiceList, isCoordinates } from '90.quickConnect.Models/guards';
import { AddressData, Choice } from '90.quickConnect.Models/models';
import { BooleanExtension } from '80.quickConnect.Core/formatting/BooleanExtension';
import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';
import { mapToStringFromCoordinates } from '90.quickConnect.Models/mappings';
import { FieldType } from '90.quickConnect.Models/enums';

export function mapToStringFromAddressData(
  { streetNumber, street, complement, zipCode, city, country }: AddressData,
  sep = '¤',
): string {
  return `${streetNumber}${sep}${street}${sep}${complement}${sep}${zipCode}${sep}${city}${sep}${country}`;
}

export function mapAllFieldTypeValuesToString(
  nextValue: unknown,
  fieldType: FieldType | undefined,
): string | undefined {
  if (nextValue === undefined) return;
  if (nextValue === null) return '';

  let result: string | undefined = undefined;

  if (typeof nextValue === 'string') result = nextValue;
  //Date au format yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
  else if (DateTimeExtension.isDate(nextValue)) result = DateTimeExtension.formatIso8601(nextValue);
  else if (isChoice(nextValue)) result = nextValue.value;
  else if (isChoiceList(nextValue)) result = nextValue.map(({ label }: Choice) => label).join(', ');
  else if (nextValue instanceof DoubleExtension) return DoubleExtension.convertToLitteral(nextValue);
  else if (isQCNotification(nextValue)) return StringExtension.join(nextValue.selectedTargets.map((nt) => nt.target));
  else if (typeof nextValue === 'number') {
    if (Number.isInteger(nextValue) && fieldType !== FieldType.Slider && fieldType !== FieldType.Numeric) {
      return NumberExtension.convertIntegerToString(nextValue, '');
    } else {
      return NumberExtension.convertDoubleToString(nextValue, '');
    }
  } else if (typeof nextValue === 'bigint') return NumberExtension.convertBigIntToString(nextValue, '');
  else if (typeof nextValue === 'boolean') return BooleanExtension.toString(nextValue);
  else if (isAddressData(nextValue)) return mapToStringFromAddressData(nextValue);
  else if (isCoordinates(nextValue)) return mapToStringFromCoordinates(nextValue);

  return result;
}
