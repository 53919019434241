import React from 'react';
import { observer } from 'mobx-react';
import { Tab, Badge } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { WorkflowTabHeaderProps } from './types';
import { WorkflowTabHeaderName } from './const';
import useLabel from '50.quickConnect.Fields/hooks/useLabel';
import { WorkflowStepStatus } from '90.quickConnect.Models/enums';

const WorkflowTabHeader: React.VFC<WorkflowTabHeaderProps> = ({
  step,
  stepChanged,
  transitionStatus,
}: WorkflowTabHeaderProps) => {
  const labelSpan = useLabel(
    step.label,
    step.mandatory,
    `${WorkflowTabHeaderName}-${step.id}`,
    step.fieldType,
    undefined,
  );

  const labelSpanWithBadge = (
    <Badge
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      badgeContent={<DoneRoundedIcon sx={{ fontSize: 16 }} />}
      color="success"
    >
      {labelSpan}
    </Badge>
  );

  return (
    <Tab
      sx={{ width: 'auto', m: '0 auto' }}
      label={transitionStatus === WorkflowStepStatus.Validated ? labelSpanWithBadge : labelSpan}
      value={step.id}
      onClick={() => stepChanged(step.id)}
    />
  );
};

export default observer(WorkflowTabHeader);
