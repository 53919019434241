import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { ViewSkeletonName } from './const';

const ViewSkeleton = () => {
  const data = new Array(13).fill('');

  return (
    <Box data-cy={ViewSkeletonName} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {data.map((_skel, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          animation="wave"
          sx={{
            height: '4vh',
            width: { xs: '100vw', sm: '100vw', md: '98vw', lg: '98vw', xl: '98vw' },
            margin: { xs: '100vw', sm: '0 0 10px', md: '0 0 10px', lg: '10px', xl: '10px' },
          }}
        />
      ))}
    </Box>
  );
};

export default ViewSkeleton;
