import { useMemo } from 'react';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { UseDataProps } from '50.quickConnect.Fields/FieldsTypes/Others/ComputeQcField/types';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import { isObject } from '80.quickConnect.Core/helpers/isObject';

const useData = (value: AllFieldValueTypes): UseDataProps => {
  const computedValue = useMemo((): string => {
    if (typeof value === 'string') return value;

    if (typeof value === 'number') return roundTo(value, 4);

    if (value instanceof DateTimeExtension) return value.fromDateTimeToText;

    if (value instanceof Date && !isNaN(value.getTime())) return new DateTimeExtension(value).fromDateTimeToText;

    if (isObject(value)) return JSON.stringify(value);

    return '';
  }, [value]);

  return { computedValue };
};

export default useData;
