import React from 'react';
import { observer } from 'mobx-react';
import { Box, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@mui/material';

import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import AttachFileOutlined from '@mui/icons-material/AttachFileOutlined';
import AudioFileOutlined from '@mui/icons-material/AudioFileOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PictureAsPdfOutlined from '@mui/icons-material/PictureAsPdfOutlined';
import VideoFileOutlined from '@mui/icons-material/VideoFileOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';

import { AttachmentItemName } from './const';
import useData from './hooks';
import { AttachmentItemProps } from './types';
import { dippedGreen500 } from '00.app/theme';

const AttachmentItem = ({
  parentId,
  attachment,
  onDeleteFile,
  onPreviewFile,
  isReadOnly,
  fieldType,
  onlyImage,
}: AttachmentItemProps) => {
  const { localImageSrc } = useData(attachment, fieldType, onlyImage);

  const { thumbnail } = attachment;

  const nameWithParentId = `${parentId}-${AttachmentItemName}`;

  return (
    <Card sx={{ width: 150, height: 150, m: 2 }} data-cy={nameWithParentId}>
      <CardContent sx={{ m: 0, p: 0, position: 'relative' }}>
        <CardActions
          sx={{
            display: 'flex',
            p: 0,
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
          }}
        >
          {attachment.distantUri && (
            <IconButton
              aria-label="show"
              data-cy={`${nameWithParentId}-show`}
              onClick={() => onPreviewFile(attachment)}
            >
              <VisibilityOutlined />
            </IconButton>
          )}
          <Box flex={1} />
        </CardActions>
        {onlyImage ? (
          <CardMedia
            sx={{ cursor: 'pointer', objectFit: 'contain' }}
            component="img"
            height="110"
            image={thumbnail && thumbnail !== '' ? `data:image/png;base64, ${thumbnail}` : ''}
            alt="QuickConnect"
            onClick={async () => window.open(await localImageSrc)}
          />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 110, paddingTop: '1.5rem' }}>
            {attachment.type.includes('pdf') ? (
              <PictureAsPdfOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
            ) : /word|powerpoint|excel/.test(attachment.type) ? (
              <ArticleOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
            ) : attachment.type.includes('audio') ? (
              <AudioFileOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
            ) : attachment.type.includes('video') ? (
              <VideoFileOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
            ) : (
              <AttachFileOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
            )}
          </Box>
        )}
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography
          sx={{ wordBreak: 'break-word', maxWidth: 150, paddingInline: '5px' }}
          variant="caption"
          data-cy={`${nameWithParentId}-label`}
          display="block"
        >
          {attachment.fileName}
        </Typography>
        <IconButton
          aria-label="delete"
          data-cy={`${nameWithParentId}-delete`}
          onClick={() => onDeleteFile(attachment, parentId)}
          disabled={isReadOnly}
        >
          <DeleteOutlineRoundedIcon sx={{ color: dippedGreen500 }} />
        </IconButton>
      </Box>
    </Card>
  );
};

export default observer(AttachmentItem);
