import { FieldData, QCActionData, QCActionValueData } from '90.quickConnect.Models/models';
import { isFieldData } from '90.quickConnect.Models/guards';
import { FieldType } from '90.quickConnect.Models/enums';

export function isActionValueData(input: unknown): input is QCActionValueData {
  if (!isFieldData(input)) return false;

  const { fieldType } = input as FieldData;

  return fieldType === FieldType.RadioList || fieldType === FieldType.CheckBoxList;
}

export function isActionData(input: unknown): input is QCActionData {
  return Array.isArray(input) && input.every(isActionValueData);
}
