/**
 * Liste de valeurs possibles pour indiquer le coté obligatoire ou non d'un champ
 */
export enum Mandatory {
  Optional = 'Optional',
  Required = 'Required',
  Inherited = 'Inherited',
  ChildsValue = 'ChildsValue',
  Variable = 'Variable',
}
