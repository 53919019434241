/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react';
import { useTheme, Typography, Box, Slider, Fab } from '@mui/material';
import { Card, CardActions, CardContent, CardMedia, IconButton } from '@mui/material';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CanvasDraw from 'react-canvas-draw';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { SignatureQcFieldName } from './const';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { BoxForLabel, FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import TwoSidesRenderer from '50.quickConnect.Fields/TwoSidesRenderer';
import { pureWhiteColor, dippedGreen500 } from '00.app/theme';
import { FieldType } from '90.quickConnect.Models/enums';
import { AttachmentItemData, PhotoDesc } from '90.quickConnect.Models/models';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';

const SignatureQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  labelClass,
  openByImagesGroupField,
}: ValueFieldsTypesProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const photoDesc = field as PhotoDesc;
  const { id, fullPathId } = field;
  const nameWithId = `${SignatureQcFieldName}-${id}`;
  const {
    brushRadius,
    setBrushRadius,
    brushColor,
    canvasRef,
    handleExport,
    handleClear,
    onDeleteSignature,
    isChecked,
    attachmentsItemsData,
    getSrcValue,
  } = useData(updateDeclaration, field, photoDesc, setSelectedIndex);
  const { breakpoints, palette } = useTheme();
  const { t } = useTranslation('declaration');

  // Gestion de l'état du panneau avec une logique centralisée
  const isOpen = openByImagesGroupField ? isPanelOpen : selectedIndex === fullPathId;
  const handleOpen = () => {
    if (!isReadOnly) {
      setSelectedIndex(fullPathId);
      setIsPanelOpen(true);
    }
  };
  const handleClose = () => {
    if (openByImagesGroupField) {
      setIsPanelOpen(false);
    } else {
      setSelectedIndex(undefined);
    }
  };

  useEffect(() => {
    // Automatiquement ouvrir si certaines conditions sont remplies
    if (!isReadOnly && selectedIndex !== fullPathId && openByImagesGroupField) {
      setSelectedIndex(fullPathId);
      setIsPanelOpen(true);
    }
  }, [isReadOnly, fullPathId, selectedIndex, setSelectedIndex, openByImagesGroupField]);

  const canvas = useMemo(
    () => (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <CanvasDraw
        enablePanAndZoom={field.fieldType === FieldType.Draw}
        brushRadius={brushRadius}
        brushColor={isChecked ? '#dbb7bb' : brushColor}
        canvasWidth={600}
        canvasHeight={400}
        hideGrid
        hideInterface
        lazyRadius={0}
        ref={canvasRef}
        style={{ objectFit: 'contain' }}
      />
    ),
    [brushColor, brushRadius, canvasRef, field.fieldType, isChecked],
  );

  const rightItems = useMemo(
    () => (
      <Box sx={{ width: '100%' }} data-cy={`${nameWithId}-canvasSignature`}>
        {canvas}
        <Slider
          size="small"
          value={brushRadius}
          onChange={(_event, value) => setBrushRadius(value as number)}
          aria-label="Small"
          valueLabelDisplay="auto"
          step={0.5}
          marks
          min={2}
          max={20}
        />
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Fab color="primary" size="small" onClick={handleExport} sx={{ marginRight: '10px' }}>
              <DoneIcon />
            </Fab>
            <Fab color="error" size="small" onClick={handleClear}>
              <DeleteIcon />
            </Fab>
          </Box>
        </Box>
      </Box>
    ),
    [canvas, handleClear, handleExport, setBrushRadius, brushRadius, nameWithId],
  );

  const list = useMemo(
    () =>
      attachmentsItemsData.map((x: AttachmentItemData, index: number) => (
        <Box sx={FlexRow} key={`${getFieldKey(field)} - ${uuidv4()}`}>
          <Card sx={{ width: 150, m: 2, backgroundColor: `${pureWhiteColor}` }}>
            <CardContent sx={{ m: 0, p: 0 }}>
              <Box>
                <CardMedia
                  component="img"
                  height="100"
                  sx={{ p: 0, objectFit: 'contain' }}
                  src={getSrcValue(x)}
                  alt="QuickConnect"
                  onClick={() => {
                    return getSrcValue(x).startsWith('https')
                      ? window.open(getSrcValue(x))
                      : window.open(URL.createObjectURL(x.file as Blob));
                  }}
                />
                <CardActions key={index} sx={{ display: 'flex', justifyContent: 'end', p: 0, height: 10 }}>
                  <IconButton
                    color="primary"
                    sx={{ mt: 2 }}
                    aria-label="delete"
                    onClick={(event) => {
                      onDeleteSignature(event, x);
                    }}
                    disabled={isReadOnly}
                  >
                    <DeleteOutlineRoundedIcon />
                  </IconButton>
                </CardActions>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )),
    [attachmentsItemsData, getSrcValue, isReadOnly, onDeleteSignature, field],
  );

  return (
    <Box id={`scrollToAnchor-${fullPathId}`} className={labelClass} sx={FieldMainBox(breakpoints)} data-cy={nameWithId}>
      <Box
        data-cy={`${nameWithId}-OpenRightPanelBox`}
        sx={{ ...BoxForLabel(palette), borderColor: selectedIndex === fullPathId ? dippedGreen500 : '' }}
        onClick={handleOpen}
      >
        <Box sx={FlexRow}>
          <Typography sx={{ flexGrow: 1 }} component="span">
            {labelSpan}
          </Typography>
          <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />
        </Box>
        {list}
      </Box>
      {!isReadOnly && selectedIndex === fullPathId && (
        <TwoSidesRenderer parentNameAndId={nameWithId} onClose={handleClose} open={isOpen}>
          {rightItems}
        </TwoSidesRenderer>
      )}
    </Box>
  );
};
export default observer(SignatureQcField);
