import { flatten } from '80.quickConnect.Core/helpers';
import { Choice, HierarchicalChoice } from '90.quickConnect.Models/models';

export default function mapChoiceListFromHierarchicalChoiceList(
  hierarchicalChoiceList: HierarchicalChoice[],
): Choice[] {
  return flatten(hierarchicalChoiceList, (i) => i.children).map(({ label, value, data }: HierarchicalChoice) => ({
    label,
    value,
    data,
  }));
}
