import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import RootStore from '30.quickConnect.Stores/RootStore';
import { errorHandler } from '80.quickConnect.Core/helpers';

class AxiosClientHTTP implements IClientHTTP {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/ClientHTTP/axios/index.ts';

  client: AxiosInstance;

  private static logger: CustomLogger = CustomLogger.getInstance();

  static rootStore: RootStore;

  private static instance: AxiosClientHTTP | null = null;

  private constructor(client: AxiosInstance, rootStore: RootStore) {
    this.client = client;
    AxiosClientHTTP.rootStore = rootStore;
  }

  static getInstance = (client?: AxiosInstance, rootStore?: RootStore): AxiosClientHTTP | never => {
    try {
      if (AxiosClientHTTP.instance === null && (client === undefined || rootStore === undefined))
        throw new Error(`A client and a rootStore must be provided when the instance is equals to null`);

      if (AxiosClientHTTP.instance === null) {
        this.instance = new AxiosClientHTTP(client!, rootStore!);
      }

      return AxiosClientHTTP.instance as AxiosClientHTTP;
    } catch (error: unknown) {
      errorHandler(AxiosClientHTTP.TAG, error, 'getInstance');
      throw error;
    }
  };

  login = async <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: any): Promise<any> => {
    try {
      const response: R = await this.client.post<T, R>(url, data, config);

      AxiosClientHTTP.logger.debug(AxiosClientHTTP.TAG, `AxiosClientHTTP Login Function success`);

      return response;
    } catch (error: unknown) {
      if (error instanceof Error) return error as R;
    }
  };

  post = async <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: any): Promise<R> => {
    try {
      const response: R = await this.client.post<T, R>(url, data, config);

      AxiosClientHTTP.logger.debug(AxiosClientHTTP.TAG, `AxiosClientHTTP Post Function success`);

      return response;
    } catch (error: unknown) {
      const axiosError = error as AxiosError<any>;
      AxiosClientHTTP.handleResponseError(axiosError);
      return error as R;
    }
  };

  get = async <T = any, R = AxiosResponse<T>>(url: string, config?: any): Promise<R> => {
    try {
      const response: R = await this.client.get<T, R>(url, config);

      AxiosClientHTTP.logger.debug(AxiosClientHTTP.TAG, `AxiosClientHTTP Get Function success`);

      return response;
    } catch (error: unknown) {
      const axiosError = error as AxiosError<any>;
      AxiosClientHTTP.handleResponseError(axiosError);
      return error as R;
    }
  };

  private static handleResponseError = (error: AxiosError<any>) => {
    const { response } = error;
    const {
      signInInfos: { userUPN },
    } = this.rootStore.LoginStore;
    let message: string;
    if (response && userUPN) {
      const { status, data, config } = response;
      message = `Code: ${status ?? ''} - Message: ${data.message ?? ''} - Request: ${config.url ?? ''} - Method: ${
        config.method ?? ''
      } - Parameters: ${config.data} - Utilisateur: ${userUPN}`;
    } else {
      message = `Erreur provenant d'axios - ${error.message} - ${error.name}`;
    }

    error.message = message;

    if (response && response.status === 401) {
      AxiosClientHTTP.rootStore.LoginStore.setIsConnectedFor401HTTPCode(false);
      AxiosClientHTTP.rootStore.DeclarationStore.setIsEditingCurrentDeclaration(false);
      AxiosClientHTTP.rootStore.DeclarationStore.setEditableDeclaration(false);
    }

    throw error;
  };
}

export default AxiosClientHTTP;
