/**
 * Fournisseur d'identité exploitable avec l'application
 */
export enum IdentityProviders {
  /**
   * Le système interne de QuickConnect, par défaut
   */
  QuickConnect = 0,
  /**
   * Active Azure Directory (via connecteur OpenId)
   */
  AzureActiveDirectory = 1,
}
