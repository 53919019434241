import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AddressQcFieldName } from './const';
import useData from './hooks';
import { ActionConditionType } from './types';
import AddressSummary from './AddressSummary';
import AddressInputField from './AddressInputField';
import { BoxForLabel, FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { AddressData, AddressDesc } from '90.quickConnect.Models/models';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import TwoSidesRenderer from '50.quickConnect.Fields/TwoSidesRenderer';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';

const AddressQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  labelClass,
}: ValueFieldsTypesProps) => {
  const { id } = field;
  const addressDesc = field as AddressDesc;
  const nameWithId = `${AddressQcFieldName}-${id}`;
  const { toolTip, value, fullPathId } = addressDesc;
  const { t, localValue, updateState, startReverseGeocoding, reverseGeoCodingLoading } = useData(
    addressDesc,
    updateDeclaration,
  );

  const { breakpoints, palette } = useTheme();

  const labelBoxWithChevron = (
    <Box sx={FlexRow}>
      <Box sx={{ flexGrow: 1 }} id={`${nameWithId}-label`}>
        {labelSpan}
      </Box>

      <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );

  const labelBox = (
    <Box sx={FlexRow}>
      <Box id={`${nameWithId}-label`}>{labelSpan}</Box>
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );

  return (
    <Box className={labelClass} sx={FieldMainBox(breakpoints)} data-cy={nameWithId} id={`scrollToAnchor-${fullPathId}`}>
      <Box
        data-cy={`${nameWithId}-OpenRightPanelBox`}
        sx={BoxForLabel(palette)}
        onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
      >
        {labelBoxWithChevron}

        {value && (
          <Box sx={FlexRow}>
            <AddressSummary parentId={id} addressData={value as AddressData} />
          </Box>
        )}
      </Box>

      {!isReadOnly && selectedIndex === fullPathId && (
        <TwoSidesRenderer
          parentNameAndId={nameWithId}
          onClose={() => setSelectedIndex(undefined)}
          open={selectedIndex === fullPathId}
        >
          {labelBox}

          {value && value.coordinates && value.coordinates.latitude && value.coordinates.longitude && (
            <LoadingButton
              loading={reverseGeoCodingLoading}
              variant="contained"
              data-cy={`${nameWithId}-get-address-data`}
              sx={{ width: '100%' }}
              onClick={startReverseGeocoding}
            >
              {t('fields_address_get_address_data').toString()}
            </LoadingButton>
          )}

          <Box
            data-cy={`${nameWithId}-FirstRow`}
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }}
          >
            <Box sx={{ width: { xs: '100%', sm: '30%', md: '30%', lg: '30%' } }}>
              {localValue && (
                <AddressInputField
                  data-cy={`${nameWithId}-streetnumber`}
                  currentValue={localValue}
                  type={ActionConditionType.StreetNumber}
                  updateState={updateState}
                  parentNameWithId={nameWithId}
                />
              )}
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '70%', md: '70%', lg: '70%' }, ml: { xs: 0, sm: 1, md: 1, lg: 1 } }}>
              {localValue && (
                <AddressInputField
                  data-cy={`${nameWithId}-street`}
                  currentValue={localValue}
                  type={ActionConditionType.Street}
                  updateState={updateState}
                  parentNameWithId={nameWithId}
                />
              )}
            </Box>
          </Box>
          {localValue && (
            <AddressInputField
              data-cy={`${nameWithId}-complement`}
              currentValue={localValue}
              type={ActionConditionType.Complement}
              updateState={updateState}
              parentNameWithId={nameWithId}
            />
          )}
          <Box
            data-cy={`${nameWithId}-SecondRow`}
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }}
          >
            <Box sx={{ width: { xs: '100%', sm: '30%', md: '30%', lg: '30%' } }}>
              {localValue && (
                <AddressInputField
                  data-cy={`${nameWithId}-zipcode`}
                  currentValue={localValue}
                  type={ActionConditionType.ZipCode}
                  updateState={updateState}
                  parentNameWithId={nameWithId}
                />
              )}
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '70%', md: '70%', lg: '70%' }, ml: { xs: 0, sm: 1, md: 1, lg: 1 } }}>
              {localValue && (
                <AddressInputField
                  data-cy={`${nameWithId}-city`}
                  currentValue={localValue}
                  type={ActionConditionType.City}
                  updateState={updateState}
                  parentNameWithId={nameWithId}
                />
              )}
            </Box>
          </Box>
          {localValue && (
            <AddressInputField
              data-cy={`${nameWithId}-country`}
              currentValue={localValue}
              type={ActionConditionType.Country}
              updateState={updateState}
              parentNameWithId={nameWithId}
            />
          )}
        </TwoSidesRenderer>
      )}
    </Box>
  );
};
export default observer(AddressQcField);
