import React from 'react';
import { observer } from 'mobx-react';
import { DialogContentText } from '@mui/material';
import { DialogChoiceContentTextProps } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';

const DialogChoiceContentText = ({ name, children }: DialogChoiceContentTextProps) => (
  <DialogContentText component={'div'} data-cy={`${name}-dialog-content-text`}>
    {children}
  </DialogContentText>
);

export default observer(DialogChoiceContentText);
