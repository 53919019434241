import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { debounceTime } from '50.quickConnect.Fields/const';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    FormStore: {
      MainMenuStore: {
        filter,
        setFilter,
        setExpandedNodes,
        expandedNodes,
        items: forms,
        treeNodeKeys: formsTreeNodeKeys,
      },
      numberDisplayed: numberFormsDisplayed,
    },
    UserSettingsStore: { formsAsTree, setFormsAsTree },
  } = useStore();

  // On définit le state
  const [filterInputValue, setFilterInputValue] = useState(filter);
  const [formsTotalNumber, setFormsTotalNumber] = useState(forms ? forms.length : 0);
  const [isAllExpanded, setIsAllExpanded] = useState(expandedNodes.length < formsTreeNodeKeys.length);

  // On ajoute des hooks
  useEffect(() => {
    setIsAllExpanded(expandedNodes.length < formsTreeNodeKeys.length);
  }, [setIsAllExpanded, expandedNodes.length, formsTreeNodeKeys.length]);

  const handleExpandClick = useCallback(() => {
    setExpandedNodes(expandedNodes.length < formsTreeNodeKeys.length ? formsTreeNodeKeys : []);
  }, [expandedNodes.length, formsTreeNodeKeys, setExpandedNodes]);

  const applyFilter = useCallback((): void => {
    setFilter(filterInputValue);
  }, [filterInputValue, setFilter]);

  useEffect(() => {
    if (filter !== '') setExpandedNodes(formsTreeNodeKeys);
  }, [filter, formsTreeNodeKeys, setExpandedNodes]);

  useEffect(() => {
    setFormsTotalNumber(forms ? forms.length : 0);
  }, [forms]);

  const clearFilter = useCallback((): void => {
    setFilterInputValue('');
    setFilter('');
  }, [setFilter]);

  const debouncedSearch = useDebouncedCallback((valueToSearch) => {
    setFilter(valueToSearch);
  }, 100);

  useEffect(() => {
    if (filterInputValue) {
      debouncedSearch(filterInputValue);
    }
  }, [debouncedSearch, filterInputValue]);

  return {
    applyFilter,
    clearFilter,
    filterInputValue,
    setFilterInputValue,
    filter,
    handleExpandClick,
    isAllExpanded,
    formsTreeNodeKeys,
    numberFormsDisplayed,
    formsTotalNumber,
    formsAsTree,
    setFormsAsTree,
  };
};
