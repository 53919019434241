import { Choice } from '90.quickConnect.Models/models';
import { HierarchicalChoice } from '90.quickConnect.Models/models/declarations/hierarchicalChoice';

const mapHierarchicalChoices = (choices: Choice[]): HierarchicalChoice[] => {
  const result = new Map<string, HierarchicalChoice>();

  choices.forEach((choice) => {
    const { label, value, data } = choice;
    const splittedLabels = label.split('\\');

    let root: HierarchicalChoice | undefined = result.get(splittedLabels[0]);

    if (!root) {
      const [rootLabel] = splittedLabels;
      root = {
        label: splittedLabels.length > 1 ? '' : label, // On indique le label pour le dernier chemin de l'arbre
        value: splittedLabels.length > 1 ? '' : value,
        data: data ?? undefined,
        hierarchicalLabel: rootLabel,
        hierarchicalNodePath: rootLabel,
        hierarchicalParentNode: '',
        childrenLabels: [choice.label.toLocaleLowerCase()],
        children: [],
      } as HierarchicalChoice;
      result.set(rootLabel, root);
    } else {
      root.childrenLabels.push(choice.label.toLocaleLowerCase());
    }
    if (splittedLabels.length > 1) {
      splittedLabels.slice(1).reduce((hierarchicalChoice, splittedLabel, depth, arraySliced) => {
        let newLeaf = hierarchicalChoice.children.find((r) => r.hierarchicalLabel === splittedLabel);
        if (!newLeaf) {
          newLeaf = {
            label: arraySliced.length - 1 > depth ? '' : label, // On indique le label pour le dernier chemin de l'arbre
            value: arraySliced.length - 1 > depth ? '' : value, // On indique la valeur pour le dernier chemin de l'arbre
            data: data ?? undefined,
            hierarchicalLabel: splittedLabel,
            hierarchicalNodePath: `${hierarchicalChoice.hierarchicalNodePath}.${splittedLabel}`,
            hierarchicalParentNode: hierarchicalChoice.hierarchicalNodePath,
            childrenLabels: [choice.label.toLocaleLowerCase()],
            children: [],
          } as HierarchicalChoice;
          hierarchicalChoice.children.push(newLeaf);
        } else {
          newLeaf.childrenLabels.push(choice.label.toLocaleLowerCase());
        }
        return newLeaf;
      }, root);
    }
  });

  return [...result].map((item) => {
    return item[1];
  });
};

export default mapHierarchicalChoices;
