import { useEffect } from 'react';
import { FieldDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { useQCSFormEvent } from '10.quickConnect.app/components/domain/Declaration/hooks/useFormEventQCScript/context';
import { useQCSFunctions } from '20.formLib/DeclarationContainer/contexts';

const useData = (flattenFields: FieldDesc[]) => {
  // On recupère le Store
  const {
    QCScriptStore: { controllerMethod, qcsBaseFieldId: qcsFieldId, setControllerMethod },
  } = useStore();

  const { callController, isQCScriptForm, setQCSFunc } = useQCSFormEvent();
  const qcsFunctions = useQCSFunctions();

  useEffect(() => {
    if (controllerMethod !== '' && isQCScriptForm) {
      setTimeout(() => {
        setQCSFunc(qcsFunctions);
        callController(controllerMethod);
        setControllerMethod('');
      }, 10);
    }
  }, [
    controllerMethod,
    isQCScriptForm,
    callController,
    flattenFields,
    qcsFieldId,
    setControllerMethod,
    setQCSFunc,
    qcsFunctions,
  ]);
};

export default useData;
