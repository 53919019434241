export enum AvailableInput {
  None = 0,
  Bool = 1,
  Int = 2,
  Decimal = 3,
  DateTime = 4,
  Time = 5,
  String = 6,
  Geolocation = 7,
  /** Oui ce n'est pas un séparateur */
  Separator = 8,
  File = 9,
  MultiChoice = 10,
  /** DateOnly */
  Date = 11,
  Compute = 12,
  Label = 13,
  Email = 14,
  Address = 15,
  TodoList = 16,
}
