import { useCallback, useMemo } from 'react';

import { AttachmentItemData } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import attachmentDb from '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb';

const useData = (attachment: AttachmentItemData, fieldType: FieldType, onlyImage: boolean) => {
  const { file, type, fileName, thumbnail, distantUri, id } = attachment;

  const createFileFromBase64 = useCallback((): File => {
    const base64Str = thumbnail;
    const bstr = window.atob(base64Str);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type });
  }, [thumbnail, fileName, type]);

  const localImageSrc = useMemo(async () => {
    if (onlyImage) {
      if (file instanceof File) return URL.createObjectURL(file);
      else if (distantUri && distantUri.startsWith('https')) return distantUri;
      else if (thumbnail && thumbnail !== '' && fileName && type) {
        if (id) {
          const attachItemDb = await attachmentDb.getAttachmentById(id);

          if (attachItemDb) return URL.createObjectURL(attachItemDb.file);
        } else {
          const newFile: File = createFileFromBase64();
          const url = URL.createObjectURL(newFile);
          return url;
        }
      }
    } else {
      return '';
    }
  }, [onlyImage, id, distantUri, file, thumbnail, fileName, type, createFileFromBase64]);

  return { localImageSrc, distantUri };
};

export default useData;
