import { useCallback, useEffect, useState } from 'react';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

const defaultDrawerWidth = 720;
const minDrawerWidth = 50;
const maxDrawerWidth = 1000;
const useData = (open: boolean): UseDataProps => {
  const [isIndexSelected, setIsIndexSelected] = useState(false);
  const {
    DeclarationStore: { setOpenDrawer, setCloseDrawer },
  } = useStore();
  const [drawerWidth, setDrawerWidth] = useState<number>(defaultDrawerWidth);

  const handleMouseMove = useCallback((e: any) => {
    const newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  }, [handleMouseMove]);

  const handleMousedown = useCallback(() => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  }, [handleMouseMove, handleMouseUp]);

  useEffect(() => {
    if (open) {
      setOpenDrawer();
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp, true);
      document.removeEventListener('mousemove', handleMouseMove, true);
      setCloseDrawer();
    };
  }, [open, setOpenDrawer, handleMouseMove, handleMouseUp, setCloseDrawer]);

  return { drawerWidth, handleMousedown, setCloseDrawer, setOpenDrawer };
};
export default useData;
