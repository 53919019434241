import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { PasswordRulesType } from '../types';
import { checkPasswordRules } from '80.quickConnect.Core/auth/checkPasswordRules';

const useData = (
  setNewPassword: React.Dispatch<React.SetStateAction<string>>,
  setPasswordRules: React.Dispatch<React.SetStateAction<PasswordRulesType>>,
) => {
  const { t } = useTranslation('login');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onCheckPasswordRules = useCallback(
    (value: string) => {
      setNewPassword(value);

      setPasswordRules(checkPasswordRules(value));
    },
    [setNewPassword, setPasswordRules],
  );
  return { onCheckPasswordRules, t, setShowPassword, showPassword };
};
export default useData;
