// Permet d' avoir un intervalle de durée à partir d'un objet de type Duration
const getDuration = (duration: Duration): string => {
  const { years, months, days, hours, minutes, seconds } = duration;

  if (
    years === undefined &&
    months === undefined &&
    days === undefined &&
    hours === undefined &&
    minutes === undefined &&
    seconds === undefined
  )
    return '';

  let negativeOperator = false;
  let durationValueISO8601 = 'P';
  // if (years !== undefined || months !== undefined || days !== undefined) {
  //   durationValueISO8601 += 'P';
  // }

  if (years !== undefined && years !== 0) {
    if (years < 0) negativeOperator = true;
    durationValueISO8601 += `${Math.abs(years)}Y`;
  }

  if (months !== undefined && months !== 0) {
    if (months < 0) negativeOperator = true;
    durationValueISO8601 += `${Math.abs(months)}M`;
  }

  if (days !== undefined && days !== 0) {
    if (days < 0) negativeOperator = true;
    durationValueISO8601 += `${Math.abs(days)}D`;
  }

  durationValueISO8601 += 'T';

  if (hours !== undefined && hours !== 0) {
    if (hours < 0) negativeOperator = true;
    durationValueISO8601 += `${Math.abs(hours)}H`;
  }

  if (minutes !== undefined && minutes !== 0) {
    if (minutes < 0) negativeOperator = true;
    durationValueISO8601 += `${Math.abs(minutes)}M`;
  }

  if (seconds !== undefined && seconds !== 0) {
    if (seconds < 0) negativeOperator = true;
    durationValueISO8601 += `${Math.abs(seconds)}S`;
  }

  return negativeOperator ? '-' + durationValueISO8601 : durationValueISO8601;
};

export default getDuration;
