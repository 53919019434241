import React from 'react';
import { Box, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import FormsToolBar from './FormsToolBar';
import useData from './hooks';
import { FormsName } from './const';
import CustomList from '10.quickConnect.app/components/domain/Home/Shared/CustomList';
import FormsTree from '10.quickConnect.app/components/domain/Home/Forms/FormsTree';
import FormLine from '10.quickConnect.app/components/domain/Home/Forms/FormLine';
import BackupDeclarationDialog from '10.quickConnect.app/components/shared/Dialogs/BackupDeclarationDialog';
import { ListColumnForFormContainer, TabsMainBox } from '10.quickConnect.app/components/domain/Home/styles';

const Forms = () => {
  const theme = useTheme();
  const { t } = useTranslation('declaration');
  const { formsAsTree, filteredForms, MainMenuStore } = useData();
  return (
    <>
      <Box data-cy={FormsName} sx={TabsMainBox}>
        <FormsToolBar />
        <Box sx={ListColumnForFormContainer(theme)}>
          {formsAsTree ? (
            <FormsTree store={MainMenuStore} />
          ) : (
            <CustomList items={filteredForms} RenderLine={FormLine} />
          )}
          {/* {isMobile && <ScrollTo idToScrollTo="topBarComponent" />} */}
        </Box>
      </Box>
      <BackupDeclarationDialog />
    </>
  );
};
export default observer(Forms);
