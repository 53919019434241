import { EntityInstanceSearch, SearchFamillyData } from '90.quickConnect.Models/models';

/**
 * Fonction permettant de concatener les champs et value selectionner
 */
const concatValue = (oldValue: string, newValue: string, attributeName: string): string => {
  if (attributeName === '') {
    return oldValue === '' ? newValue : `${oldValue} / ${newValue}`;
  } else {
    return oldValue === '' ? `${attributeName} : ${newValue}` : `${oldValue} / ${attributeName} : ${newValue}`;
  }
};

const isInSelect = (idsAttributesSelect: string[], attributeId: string): boolean =>
  idsAttributesSelect && idsAttributesSelect.length > 0 && idsAttributesSelect.includes(attributeId);

const mapSearchValues = (mapped: EntityInstanceSearch[], idsAttributesSelect: string[]): SearchFamillyData[] => {
  const tempResults: Map<string, SearchFamillyData> = new Map();
  mapped.forEach((res: EntityInstanceSearch) => {
    const defaultResValue = res?.value ?? '';
    const defaultResTitle = res?.title ?? '';
    const result: SearchFamillyData | undefined = tempResults.get(res.instanceId);
    const oldDescription = result?.description ?? '';
    const oldTitle = result?.title ?? '';
    const oldValue = result?.value ?? '';

    const description = concatValue(oldDescription, defaultResValue, '');

    const title = isInSelect(idsAttributesSelect, res.attributeId)
      ? idsAttributesSelect.length === 1
        ? defaultResTitle
        : concatValue(oldTitle, defaultResValue, '')
      : oldTitle;
    const value = isInSelect(idsAttributesSelect, res.attributeId) ? concatValue(oldValue, res.value, '') : oldValue;

    const newResult: SearchFamillyData = {
      title,
      description,
      value,
      schemaId: res.schemaId,
      id: res.id,
      entityInstanceId: res.instanceId,
    };

    tempResults.set(res.instanceId, newResult);
  });

  return Array.from(tempResults.values());
};

export default mapSearchValues;
