import React from 'react';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react';
import useData from './hooks';
import { MainBoxStyle } from './styles';
import { LoginAdalName } from './const';

const LoginAdal = () => {
  const { isLogging } = useData();
  return (
    <Box data-cy={LoginAdalName} sx={MainBoxStyle}>
      <Backdrop open={isLogging}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
export default observer(LoginAdal);
