import React from 'react';
import { observer } from 'mobx-react';
import { FormControl, IconButton, InputAdornment, OutlinedInput, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { HierarchicalSearchBarProps } from './types';
import { HierarchicalSearchBarName } from './const';
import useData from './hooks';

const HierarchicalSearchBar: React.VFC<HierarchicalSearchBarProps> = ({
  setFilterList,
  parentId,
}: HierarchicalSearchBarProps) => {
  const nameWithId = `${HierarchicalSearchBarName}-${parentId}`;

  const { setFilter, filter, doSearch, clear, keyPress } = useData(setFilterList);

  const {
    palette: { info },
  } = useTheme();

  return (
    <FormControl data-cy={nameWithId} sx={{ marginTop: '10px' }}>
      <OutlinedInput
        sx={{
          boxShadow: filter ? `0 0 5px 2px ${info.main}` : undefined,
        }}
        data-cy={`${nameWithId}-search`}
        type="text"
        value={filter}
        required
        onChange={(event: React.ChangeEvent<{ value: string }>) => setFilter(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => keyPress(event)}
        startAdornment={
          <InputAdornment position="end">
            <IconButton data-cy={`${nameWithId}-clear`} onClick={doSearch} edge="start" type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          filter ? (
            <InputAdornment position="end">
              <IconButton onClick={clear} onMouseDown={clear} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
    </FormControl>
  );
};
export default observer(HierarchicalSearchBar);
