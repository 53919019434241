import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CommandType } from '../types';
import { UseDataProps } from './types';
import { ItemData } from '90.quickConnect.Models/models';
import { FormType } from '90.quickConnect.Models/enums';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  formType: FormType,
  handleItemDatas: (commandType: CommandType) => Promise<[boolean, string | undefined, ItemData]>,
  sendDeclaration: (newItemData: ItemData) => Promise<boolean>,
  setDeclarationData: Dispatch<SetStateAction<ItemData>>,
  declarationId: string,
): UseDataProps => {
  const {
    DeclarationStore: { openDialog },
  } = useStore();
  const { t } = useTranslation('declaration');

  const onCancel = useCallback(async () => {
    // if (formType === FormType.Workflow) {
    //   const [goToNextStep, nextStepIndex, newItemData] = handleItemDatas(CommandType.Cancel);
    //   const declarationSend = await sendDeclaration(newItemData);
    //   if (declarationSend) {
    //     if (goToNextStep && nextStepIndex && newItemData && newItemData.Workflow) {
    //       newItemData.Workflow.currentStep = nextStepIndex;
    //       setDeclarationData(newItemData);
    //     } else {
    //       openDialog();
    //     }
    //   }
    // } else {
    //   openDialog();
    // }
    openDialog();
  }, [openDialog]);

  return {
    t,
    onCancel,
  };
};

export default useData;
