import { v4 as uuidv4 } from 'uuid';
import mapGroupDesc from './mapGroupDesc';
import mapFieldDesc from './mapFieldDesc';
import { FieldDesc, RepeatableGroupDesc } from '90.quickConnect.Models/models';
import { ConsentFrequency, FieldType } from '90.quickConnect.Models/enums';

const updateFullPathId = (item: FieldDesc, id: string, i: number, parentFullPathId: string | undefined): FieldDesc => {
  // Construire un nouveau fullPathId de manière conditionnelle
  const newFullPathId = parentFullPathId ? `${parentFullPathId}.${i}.${item.id}` : `${id}.${i}.${item.id}`;

  // Mettre à jour récursivement les items si présents
  if (item.items && item.items.length > 0) {
    return {
      ...item,
      fullPathId: newFullPathId,
      items: item.items.map((subItem, subIndex) => updateFullPathId(subItem, id, subIndex, newFullPathId)),
    };
  }

  // Retourner l'élément mis à jour si aucun item enfant
  return {
    ...item,
    fullPathId: newFullPathId,
  };
};

const mapRepeatableGroupDesc = (
  field: RepeatableGroupDesc,
  templateBodies: Record<string, string> | undefined | null,
): RepeatableGroupDesc => {
  const baseField = mapGroupDesc(field);
  const { isCollapsable, items, maxRow, nbRowOnInit, isPopup, summaryTitle } = field;
  const { id, fullPathId } = baseField;
  const newItems: FieldDesc[] = [];
  const groupTemplate: FieldDesc[] = items ? field.items.map((f) => mapFieldDesc(f, id, templateBodies)) : [];
  if (nbRowOnInit) {
    for (let i = 0; i < nbRowOnInit; i++) {
      const itemsFromGroupTemplate = groupTemplate.map((gt) => updateFullPathId(gt, id, i, fullPathId));
      const newIncludeField: FieldDesc = {
        stateId: uuidv4(),
        fieldType: FieldType.Include,
        items: itemsFromGroupTemplate,
        value: true,
        errors: undefined,
        fullPathId: `${fullPathId}.${i}`,
        id: `${id}.${i}`,
        fieldIsReadOnly: false,
        isVisible: true,
        checkRGPD: ConsentFrequency.Undef,
      };
      newItems.push(newIncludeField);
    }
  }
  return {
    ...baseField,
    isCollapsable: isCollapsable,
    groupTemplate: groupTemplate,
    maxRow: maxRow ?? 3,
    isPopup: isPopup ?? false,
    summaryTitle: summaryTitle ?? '',
    nbRowOnInit: nbRowOnInit ?? 0,
    items: newItems,
  } as RepeatableGroupDesc;
};

export default mapRepeatableGroupDesc;
