import { useMemo } from 'react';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';
import { flatten } from '80.quickConnect.Core/helpers';
import { useStore } from '30.quickConnect.Stores';
import { InboxViewer } from '90.quickConnect.Models/models';

export const useData = (children: TreeViewData<TreeDataItem>[]): UseDataProps => {
  const {
    UserSettingsStore: { inboxesAsAlphaNumericSort },
  } = useStore();

  const nodeChildren = useMemo(() => children.filter((child) => child.data === undefined), [children]);

  const inboxChildren = useMemo(() => {
    const inboxes = children.filter((child) => !!child.data);

    return !inboxesAsAlphaNumericSort
      ? inboxes.sort((a, b) => {
          return (b.data as InboxViewer)?.sentAt && (a.data as InboxViewer)?.sentAt
            ? new Date((b.data as InboxViewer).sentAt).getTime() - new Date((a.data as InboxViewer).sentAt).getTime()
            : -1;
        })
      : inboxes;
  }, [children, inboxesAsAlphaNumericSort]);

  const numberPrefilledFormsChildren = useMemo((): number => {
    const flattenChildren = flatten(children, (i: TreeViewData<TreeDataItem>) => i.items);
    return flattenChildren.filter((child) => !!child.data).length;
  }, [children]);

  return {
    nodeChildren,
    inboxChildren,
    numberPrefilledFormsChildren,
  };
};
