import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { PreWrapSpan } from './styles';
import { FieldType, Mandatory } from '90.quickConnect.Models/enums';
import { FlexRow } from '50.quickConnect.Fields/styles';
import {
  AllFieldValueTypes,
  AttachmentItemData,
  Choice,
  GeolocationData,
  QCNotification,
} from '90.quickConnect.Models/models';
import { isAnArrayOfChoice } from '90.quickConnect.Models/guards';

const useLabelForSummaryItem = (
  label: string | undefined,
  mandatory: Mandatory | undefined,
  parentName: string,
  _fieldType: FieldType,
  errors: string[] | undefined,
  valueSummaryItem: AllFieldValueTypes,
): JSX.Element => {
  const { t } = useTranslation('declaration');

  const [labelClass, setLabelClass] = useState('');

  const theme = useTheme();
  const labelSpan = useMemo(() => {
    const labelToUse = label?.trim() ?? '';
    let labelFormattedWithValue = '';
    switch (_fieldType) {
      case FieldType.Notification:
        labelFormattedWithValue = `${
          t('qcapp_declaration_summary_notification') +
          ' : ' +
          ((valueSummaryItem as QCNotification).selectedTargets?.map((item) => item.target).toString() ??
            (valueSummaryItem as string))
        }`;
        break;
      case FieldType.Draw:
      case FieldType.Photo:
      case FieldType.Attachment: {
        const attachmentLabel =
          (valueSummaryItem as AttachmentItemData[])?.length > 1
            ? t('formlib_attachment_single_label_resume')
            : t('formlib_attachment_multi_label_resume');
        labelFormattedWithValue = `${attachmentLabel + ' : ' + (valueSummaryItem as AttachmentItemData[])?.length}`;
        break;
      }
      case FieldType.Geolocation: {
        labelFormattedWithValue = `${
          t('formlib_geoloc_longitude') +
          ' : ' +
          (valueSummaryItem as GeolocationData).longitude +
          ' / ' +
          t('formlib_geoloc_latitude') +
          ' : ' +
          (valueSummaryItem as GeolocationData).latitude
        }`;
        break;
      }
      case FieldType.Time: {
        labelFormattedWithValue =
          labelToUse.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, '') +
          ' : ' +
          format(new Date(valueSummaryItem as string), 'HH:mm');
        break;
      }
      case FieldType.DateTime: {
        labelFormattedWithValue =
          labelToUse.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, '') +
          ' : ' +
          format(new Date(valueSummaryItem as string), 'dd/MM/yyyy HH:mm');
        break;
      }

      case FieldType.Combo:
      case FieldType.RadioList:
        labelFormattedWithValue = `${labelToUse.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/gi, '')} : ${
          (valueSummaryItem as Choice).label
        }`;
        break;

      case FieldType.CheckBoxList:
      case FieldType.HierarchicalList:
        if (isAnArrayOfChoice(valueSummaryItem)) {
          labelFormattedWithValue = `${labelToUse
            .replace(/(<([^>]+)>)/gi, '')
            .replace(/&nbsp;/gi, '')} : ${valueSummaryItem
            .map(({ label: labelChoice }: Choice) => labelChoice)
            .join(', ')}`;
        }
        break;

      default:
        labelFormattedWithValue = labelToUse.replace(/(<([^>]+)>)/gi, '') + ' : ' + valueSummaryItem;
        break;
    }

    let mandatoryIndicator: JSX.Element | undefined = undefined;
    if (mandatory === Mandatory.Required || mandatory === Mandatory.ChildsValue) {
      if (errors && errors.length > 0) {
        mandatoryIndicator = (
          <span style={{ color: theme.palette.error.main, fontWeight: 'bolder', marginRight: '0.5em' }}>*</span>
        );
      } else {
        mandatoryIndicator = <span style={{ marginRight: '0.5em' }}>* </span>;
      }
    }
    return (
      <Box>
        <Box className={labelClass} sx={{ ...FlexRow, alignItems: 'flex-start' }}>
          {mandatoryIndicator}
          <PreWrapSpan
            data-cy={`${parentName}-label`}
            dangerouslySetInnerHTML={{ __html: labelFormattedWithValue }}
          ></PreWrapSpan>
        </Box>
        {errors &&
          errors.map((error, index) => (
            <Typography key={index} variant="body2" color="error">
              {error !== t('errors_mandatory_field') && error}
            </Typography>
          ))}
      </Box>
    );
  }, [label, _fieldType, mandatory, labelClass, parentName, errors, valueSummaryItem, theme.palette.error.main, t]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setLabelClass('animate__animated animate__pulse');
    } else {
      setLabelClass('');
    }
  }, [errors]);

  return labelSpan;
};

export default useLabelForSummaryItem;
