import { useEffect } from 'react';
import usePreviousHook from './usePreviousHook';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: string[] = []) => {
  // Tag
  const tag = '80.quickConnect.Core/hooks/useEffectDebugger.ts';

  const logger = CustomLogger.getInstance();
  const previousDeps = usePreviousHook(dependencies, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    logger.log(tag, `'[use-effect-debugger] ', ${JSON.stringify(changedDeps)}`);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};

export default useEffectDebugger;
