import React from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import InboxesToolBar from './InboxesToolBar';
import { useData } from './hooks';
import { InboxesName } from './const';
import InboxesTrees from './InboxesTrees';
import InboxesLists from './InboxesLists';
import { TabsMainBox } from '10.quickConnect.app/components/domain/Home/styles';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const Inboxes = () => {
  const { inboxesAsTree } = useData();
  return (
    <Box data-cy={InboxesName} sx={{ ...TabsMainBox, marginBottom: '0px' }}>
      <InboxesToolBar />
      {inboxesAsTree ? <InboxesTrees /> : <InboxesLists />}
    </Box>
  );
};
export default observer(Inboxes);
