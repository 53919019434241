import getDefaultTimeValue from './getDefaultTimeValue';
import { TimeDesc } from '90.quickConnect.Models/models';

const mapTimeDesc = (field: TimeDesc): TimeDesc => {
  const defaultValue = getDefaultTimeValue(field);
  return {
    isVertical: field.isVertical,
    value: defaultValue ?? undefined,
    defaultValue: defaultValue ?? undefined,
    initTime: field.initTime ?? undefined,
    min: field.min ? new Date(field.min) : undefined,
    max: field.max ? new Date(field.max) : undefined,
  } as TimeDesc;
};

export default mapTimeDesc;
