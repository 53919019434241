import { areArrays, areObjects } from '80.quickConnect.Core/helpers';

export function isPrimitive(obj1: unknown): boolean {
  return (
    typeof obj1 === 'string' ||
    typeof obj1 === 'bigint' ||
    typeof obj1 === 'boolean' ||
    typeof obj1 === 'number' ||
    typeof obj1 === 'symbol' ||
    typeof obj1 === 'undefined'
  );
}

export function compareDeepValues(obj1: unknown, obj2: unknown): boolean {
  if (typeof obj1 !== typeof obj2) return false;

  if (isPrimitive(obj1) || obj1 === null) return obj1 === obj2;

  if (areArrays(obj1, obj2)) {
    const arr1 = obj1 as unknown[];
    const arr2 = obj2 as unknown[];

    return arr1.length === arr2.length && arr1.every((value, idx) => compareDeepValues(arr2[idx], value));
  }

  if (areObjects(obj1, obj2)) {
    const objStrong2 = obj2 as Record<string, unknown>;
    const objStrong1 = obj1 as Record<string, unknown>;

    if (Object.keys(objStrong1).length !== Object.keys(objStrong2).length) return false;

    let result = true;
    for (const prop in objStrong1) {
      if (!(prop in objStrong2)) return false;

      result = compareDeepValues(objStrong1[prop], objStrong2[prop]);

      if (!result) break;
    }

    return result;
  }

  return false;
}
