import { SxProps } from '@mui/system';
import { FullHeightWithoutTopBar, OverflowForDesktop } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';

export const ColumnContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: OverflowForDesktop,
  height: { md: FullHeightWithoutTopBar, lg: FullHeightWithoutTopBar },
};

export const ListColumnOrRowContainer: SxProps = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
  width: '100%',
};
