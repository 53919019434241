import { FieldType } from '90.quickConnect.Models/enums';
import { FieldDesc } from '90.quickConnect.Models/models';

const isAFieldContainer = ({ fieldType }: FieldDesc): boolean =>
  fieldType === FieldType.Action ||
  fieldType === FieldType.Dialog ||
  fieldType === FieldType.Group ||
  fieldType === FieldType.Include ||
  fieldType === FieldType.ImagesGroup ||
  fieldType === FieldType.RepeatableGroup ||
  fieldType === FieldType.Step;

export default isAFieldContainer;
