import { v4 as uuidv4 } from 'uuid';
import { SESSION_ID } from 'const';

const getSessionId = (): string => {
  let sessionId = window.localStorage.getItem(SESSION_ID);
  if (!sessionId) {
    sessionId = uuidv4();
    window.localStorage.setItem(SESSION_ID, sessionId);
  }
  return sessionId;
};

export default getSessionId;
