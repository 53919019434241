import { ChangeEvent, useState } from 'react';
import { WorkSiteSummaryProps } from '../hookWorkSiteSummary';
import { WorkSiteActType } from '../types';
import { ControlledTextFieldProps } from '../../../shared/SimpleTextField/ControlledTextField';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

type GeneralInfoProps = {
  workSiteNameList: Array<string>;
  agencyList: Array<string>;
  onPostalCodeValidate: ControlledTextFieldProps['onBlur'];
  onUserFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  deleteUserFile: () => void;
};

/** TODO KTY mock CHANTIERS */
const mockListChantier = ['Chantier Bonjour', 'Chantier un autre nom', 'Un autre chantier'];
const mockAgencyList = ['Une agence', 'uneAgence', 'deux Agences!'];
/** TODO KTY mock CHANTIERS */

const useGeneralInfo = (props: WorkSiteSummaryProps): GeneralInfoProps => {
  const [workSiteNameList, setWorkSiteNameList] = useState<Array<string>>([]);
  const [agencyList, setAgencyList] = useState<Array<string>>([]);

  /**  TODO KTY appeler la liste des chantiers possibles
   * quand en édition d'un chantier, disable le worksite name car utilsable comme ID
   * **/
  useEffectOnce(() => {
    setWorkSiteNameList(mockListChantier);
  });

  useEffectOnce(() => {
    setAgencyList(mockAgencyList);
  });

  const onPostalCodeValidate = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const { value } = e.target;
    const regex = /^\d{5}$/;
    if (regex.test(value)) {
      props.setWorkSite({ type: WorkSiteActType.POSTCODE, value: value });
      return true;
    }
    return false;
  };

  const onUserFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      props.setWorkSite({ type: WorkSiteActType.LOGO, value: files[0] });
    }
  };

  const deleteUserFile = () => {
    props.setWorkSite({ type: WorkSiteActType.LOGO, value: undefined });
  };

  return { workSiteNameList, agencyList, onPostalCodeValidate, onUserFileUpload, deleteUserFile };
};

export default useGeneralInfo;
