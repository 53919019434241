import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { MainBoxLoginStyle, CardLoginStyle } from './styles';

const LoginSkeleton = () => {
  return (
    <Box sx={MainBoxLoginStyle}>
      <Skeleton variant="rectangular" animation="wave" sx={CardLoginStyle} />
    </Box>
  );
};
export default LoginSkeleton;
