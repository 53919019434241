import { QCSBaseTypedObject } from './QCSBaseTypedObject';

export class QCSInstance extends QCSBaseTypedObject<object | null> {
  value: object | null = null;

  constructor(instance: object) {
    super(instance);
    this.value = instance;
  }
}
