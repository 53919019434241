import { EditDesc } from '90.quickConnect.Models/models';
import { TodoListDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/todoListDesc';

const mapTodoListDesc = ({ userComment, todoListId }: TodoListDesc): TodoListDesc =>
  ({
    userComment: userComment ?? '',
    todoListId: todoListId ?? '',
  } as unknown as TodoListDesc);

export default mapTodoListDesc;
