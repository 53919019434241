import { getMonday } from '80.quickConnect.Core/helpers';
import { DefaultDateTimeValue } from '90.quickConnect.Models/enums';
import { DateTimeDesc } from '90.quickConnect.Models/models';

const getDateTimeDefaultValue = (field: DateTimeDesc): Date | undefined => {
  let defaultValue = field.defaultValue ? new Date(field.defaultValue) : undefined;
  const now = new Date();
  switch (field.initDate) {
    case DefaultDateTimeValue.FirstMonthday:
      defaultValue = new Date(now.getFullYear(), now.getMonth(), 1);
      break;
    case DefaultDateTimeValue.FirstWeekday:
      defaultValue = getMonday(now);
      break;
    case DefaultDateTimeValue.Today:
      defaultValue = now;
      break;
    case DefaultDateTimeValue.Tomorrow:
      const tomorrow = new Date(now);
      tomorrow.setDate(now.getDate() + 1);
      defaultValue = new Date(tomorrow.setHours(0, 0, 0, 0));
      break;
    case DefaultDateTimeValue.Undefined:
      defaultValue = undefined;
      break;
    case DefaultDateTimeValue.Yesterday:
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      defaultValue = new Date(yesterday.setHours(0, 0, 0, 0));
      break;
    default:
      defaultValue = now;
  }
  return defaultValue;
};

export default getDateTimeDefaultValue;
