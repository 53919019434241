import React, { FunctionComponent } from 'react';
import { Box, Button, MobileStepper, Stack } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReportDialogProps, useDialogTitle } from './dialogUtils';

const ReportSummaryDialogContent: FunctionComponent<ReportDialogProps> = (props: ReportDialogProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const title = useDialogTitle(props, t('work_site_summary_reports_dialog_preview_report'));
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.report.previewPictures.length;
  const steps = props.report.previewPictures;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === maxSteps - 1) {
        return 0;
      }
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return maxSteps - 1;
      }
      return prevActiveStep - 1;
    });
  };

  function getContent() {
    if (props.report.previewPictures.length === 0) {
      return <Box padding={'1rem'}>{t('work_site_summary_reports_dialog_preview_unavailable')}</Box>;
    }
    return (
      <Stack alignItems={'center'}>
        <Box
          sx={{
            height: '70vh',
            maxWidth: '100%',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            alt={t('work_site_summary_reports_dialog_preview_alt') || ''}
            src={steps[activeStep]}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </Box>
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext}>
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack}>
              <KeyboardArrowLeft />
            </Button>
          }
          style={{ width: '100%' }}
        />
      </Stack>
    );
  }

  return (
    <Stack>
      <Box padding={'1rem'}>{title}</Box>
      {getContent()}
    </Stack>
  );
};

export default ReportSummaryDialogContent;
