import React, { FunctionComponent } from 'react';
import { Alert, Stack, styled, Typography } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReportDialogProps, useDialogTitle } from './dialogUtils';

type SortedAlerts = { success: Array<JSX.Element>; error: Array<JSX.Element> };

const CustomStack = styled(Stack)(() => ({
  gap: '0.5rem',
  paddingTop: '1rem',
}));

const MissingFormDialogContent: FunctionComponent<ReportDialogProps> = (props: ReportDialogProps) => {
  const { t } = useTranslation('moduleWorkSite');
  const title = useDialogTitle(props, t('work_site_summary_reports_dialog_missing_forms'));
  const getContent = () => {
    const sortedAlerts = Object.keys(props.report.forms).reduce(
      (acc: SortedAlerts, key: string): SortedAlerts => {
        const family = props.report.forms[key];
        const successes: SortedAlerts['success'] = [];
        const errors: SortedAlerts['error'] = [];
        family.forEach((form) => {
          const alert = (
            <Alert
              key={form.id}
              severity={form.completed ? 'success' : 'error'}
              icon={form.completed ? undefined : <CancelOutlined />}
            >
              {form.name}
            </Alert>
          );
          if (form.completed) {
            successes.push(alert);
          } else {
            errors.push(alert);
          }
        });
        if (successes.length > 0) {
          acc.success.push(<Typography fontWeight={'bold'}>{key}</Typography>);
          acc.success.push(...successes);
        }
        if (errors.length > 0) {
          acc.error.push(<Typography fontWeight={'bold'}>{key}</Typography>);
          acc.error.push(...errors);
        }
        return acc;
      },
      { success: [], error: [] },
    );

    return (
      <>
        {sortedAlerts.error.length > 0 && (
          <CustomStack>
            <Typography variant={'h5'}>{t('work_site_summary_reports_dialog_missing_missing_form')}</Typography>
            {sortedAlerts.error}
          </CustomStack>
        )}
        {sortedAlerts.success.length > 0 && (
          <CustomStack>
            <Typography variant={'h5'}>{t('work_site_summary_reports_dialog_missing_completed_form')}</Typography>
            {sortedAlerts.success}
          </CustomStack>
        )}
      </>
    );
  };

  return (
    <Stack padding={'1.5rem'}>
      {title}
      {getContent()}
    </Stack>
  );
};

export default MissingFormDialogContent;
