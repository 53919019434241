import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseGetFormContextProps } from '10.quickConnect.app/components/domain/Declaration/hooks/useGetFormContext/types';
import { DeclarationContext, EntityData } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import {
  API_GET_DECLARATION_CONTEXT,
  API_GET_DUPLICATE_DECLARATION_CONTEXT,
  API_GET_FORM_CONTEXT,
  API_GET_INBOX_CONTEXT,
} from '40.quickConnect.DataAccess/axios/apiRoutes';
import declarationsDb from '40.quickConnect.DataAccess/indexedDb/dbs/declarationsDb';
import { SynchronizeEquipmentReturnType } from '90.quickConnect.Models/enums';
import { errorHandler } from '80.quickConnect.Core/helpers';

const useGetDeclarationContext = (): UseGetFormContextProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/Declaration/hooks/useGetFormContext/index.tsx';
  const {
    DeclarationStore: { getDeclarationContext, getFormContext, getFormContextByFormNameAsync },
    CommonStore: { chooseBaseUrl },
    EquipmentsStore: { synchronizeEquipmentsAsync },
    LoginStore: {
      signInInfos: { userUPN },
    },
  } = useStore();

  const { t: tAxios } = useTranslation('axios');

  const getContext = useCallback(
    async (
      isDuplicated: boolean | undefined,
      formId?: string,
      declarationId?: string,
      inboxId?: string,
      entityInstanceId?: string,
    ): Promise<DeclarationContext | undefined> => {
      try {
        switch (true) {
          case inboxId !== undefined:
            return await getDeclarationContext(
              chooseBaseUrl(API_GET_INBOX_CONTEXT).replace(':inboxId', inboxId!),
              declarationId,
              entityInstanceId,
              true,
            );

          case declarationId !== undefined && isDuplicated === true:
            return await getDeclarationContext(
              chooseBaseUrl(API_GET_DUPLICATE_DECLARATION_CONTEXT).replace(':duplicateDeclarationId', declarationId!),
              declarationId,
              entityInstanceId,
            );

          case declarationId !== undefined && !!isDuplicated === false:
            const localDeclaration = await declarationsDb.getLocalDraftByDeclarationId(declarationId!);

            if (localDeclaration) {
              return localDeclaration;
            } else {
              return await getDeclarationContext(
                chooseBaseUrl(API_GET_DECLARATION_CONTEXT).replace(':declarationId', declarationId!),
                declarationId,
                entityInstanceId,
              );
            }

          case formId !== undefined:
            return await getFormContext(
              chooseBaseUrl(API_GET_FORM_CONTEXT).replace(':formId', formId!),
              formId!,
              userUPN,
              entityInstanceId,
            );

          default:
            throw new Error('Context initialization required one parameter');
        }
      } catch (error: unknown) {
        errorHandler(tag, error, 'getContext');
      }
    },
    [userUPN, tag, getFormContext, getDeclarationContext, chooseBaseUrl],
  );

  const checkEntityDataWithFormContext = useCallback(
    (entityData: EntityData, formContext: DeclarationContext): void => {
      if (!entityData) throw new Error('checkEntityDataWithFormContext method failed: entityData non fournies');

      const { entitySchemaId: formEntitySchemaId } = formContext;

      const { entitySchemaId: equipmentDataSchemaId } = entityData;

      if (formEntitySchemaId !== equipmentDataSchemaId)
        throw new Error(
          `checkEntityDataWithFormContext method failed: le schemaId de l'équipement ${equipmentDataSchemaId} est différent du schémaId du formulaire ${formEntitySchemaId}`,
        );
    },
    [],
  );

  const getEquipmentByDeepLink = useCallback(
    async (code: string): Promise<EntityData | undefined> => {
      try {
        // Le formulaire est associé à une famille. On récupère l'équipement
        return await synchronizeEquipmentsAsync(
          `##${code}`,
          undefined,
          undefined,
          SynchronizeEquipmentReturnType.Attributs,
          false,
          tAxios,
        );
      } catch (error: unknown) {
        errorHandler(tag, error, 'getEquipmentByDeepLink');
      }
      return undefined;
    },
    [tAxios, synchronizeEquipmentsAsync, tag],
  );

  const getContextByDeepLink = useCallback(
    async (params: URLSearchParams): Promise<DeclarationContext | undefined> => {
      try {
        return await getFormContextByFormNameAsync(params);
      } catch (error: unknown) {
        if (error instanceof Error) errorHandler(tag, error, 'getContextByDeepLink', 'error');
        return undefined;
      }
    },
    [getFormContextByFormNameAsync, tag],
  );

  return {
    getContext,
    getContextByDeepLink,
    getEquipmentByDeepLink,
    checkEntityDataWithFormContext,
  };
};

export default useGetDeclarationContext;
