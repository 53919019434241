import { TFunction } from 'i18next';
import evaluateVisibility from './evaluateVisibility';
import { evalComparisonLogic } from './evalComparisonLogic';
import { QcOperator } from '90.quickConnect.Models/enums';
import {
  BaseQCOperator,
  Dependency,
  NamedUserParameterValue,
  DualOperator,
  MultipleOperator,
} from '90.quickConnect.Models/models';
import { isDualOperator, isMultipleOperator } from '90.quickConnect.Models/guards';

export const evalVisibilityBitwiseLogic = (
  t: TFunction,
  baseQCOperator: BaseQCOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): boolean => {
  switch (baseQCOperator.type) {
    case QcOperator.Parenthesis:
      const parenthesisOperator = baseQCOperator as DualOperator;
      if (parenthesisOperator.conditionalOpeType === QcOperator.Or)
        return (
          evaluateVisibility(
            t,
            parenthesisOperator.arg1,
            dependencies,
            userParams,
            scopeFullPathId,
            userUpn,
            userEmail,
          ) ||
          evaluateVisibility(t, parenthesisOperator.arg2, dependencies, userParams, scopeFullPathId, userUpn, userEmail)
        );
      else if (parenthesisOperator.conditionalOpeType === QcOperator.And)
        return (
          evaluateVisibility(
            t,
            parenthesisOperator.arg1,
            dependencies,
            userParams,
            scopeFullPathId,
            userUpn,
            userEmail,
          ) &&
          evaluateVisibility(t, parenthesisOperator.arg2, dependencies, userParams, scopeFullPathId, userUpn, userEmail)
        );
      else {
        // La logique n'est pas la même on fonction du type d'élément évalué... Visibilité ou transition 🙁
        // Dans le cas des transition le type est dans conditionalOpeType (pb côté Angular...)
        if (baseQCOperator.conditionalOpeType && isDualOperator(baseQCOperator)) {
          const transition = {
            ...baseQCOperator,
            type: baseQCOperator.conditionalOpeType,
          } as DualOperator;
          return evalComparisonLogic(t, transition, dependencies, userParams, scopeFullPathId, userUpn, userEmail);
        } else if (!baseQCOperator.conditionalOpeType && isDualOperator(baseQCOperator)) {
          const { arg1 } = baseQCOperator;

          if (isMultipleOperator(arg1)) {
            const newArgs = arg1.args.map((qcBaseOp: BaseQCOperator) => ({
              ...qcBaseOp,
              type: qcBaseOp.conditionalOpeType!,
            }));
            const transition: MultipleOperator = {
              ...arg1,
              args: newArgs,
            };
            return evaluateVisibility(t, transition, dependencies, userParams, scopeFullPathId, userUpn, userEmail);
          }
        }
      }

      return false;
    case QcOperator.Multi:
      const multipleOperator = baseQCOperator as MultipleOperator;
      if (multipleOperator.conditionalOpeType === QcOperator.Or)
        return multipleOperator.args.some((arg) =>
          evaluateVisibility(t, arg, dependencies, userParams, scopeFullPathId, userUpn, userEmail),
        );
      else if (multipleOperator.conditionalOpeType === QcOperator.And)
        return multipleOperator.args.every((arg) =>
          evaluateVisibility(t, arg, dependencies, userParams, scopeFullPathId, userUpn, userEmail),
        );
      return false;
    default:
      return false;
  }
};
