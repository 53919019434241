import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, Typography, Fab, Dialog, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { GeolocationQcFieldName } from './const';
import useData from './hooks';

import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { BoxForLabel, FieldMainBox } from '50.quickConnect.Fields/styles';
import { FlexRow } from '50.quickConnect.Fields/styles';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { GeolocationDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/geolocationDesc';
import ChevronRight from '10.quickConnect.app/components/shared/ChevronRight';

const GeolocationQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  labelClass,
}: ValueFieldsTypesProps) => {
  const geolocationDesc = field as GeolocationDesc;
  const { toolTip, id, fullPathId } = geolocationDesc;
  const nameWithId = `${GeolocationQcFieldName}-${id}`;
  const { t, mapContainer, locations, localValue, handleValidate, handleCloseDialog, handleDelete } = useData(
    geolocationDesc,
    updateDeclaration,
    nameWithId,
    setSelectedIndex,
    selectedIndex,
  );
  const { breakpoints, palette } = useTheme();

  return (
    <Box className={labelClass} sx={FieldMainBox(breakpoints)} data-cy={nameWithId} id={`scrollToAnchor-${fullPathId}`}>
      <Box
        data-cy={`${nameWithId}-OpenRightPanelBox`}
        sx={BoxForLabel(palette)}
        onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
      >
        <Box sx={FlexRow}>
          <Typography sx={{ flexGrow: 1 }} id={`${nameWithId}-label`} component="span">
            {labelSpan}
          </Typography>

          <ChevronRight title={t('open_list').toString()} nameWithId color="secondary" />
        </Box>
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
        {localValue?.latitude && localValue.longitude && (
          <>
            <Typography>{`${t('formlib_geoloc_latitude')} : ${localValue?.latitude}`}</Typography>
            <Typography>{`${t('formlib_geoloc_longitude')} : ${localValue?.longitude}`}</Typography>
          </>
        )}
      </Box>

      {!isReadOnly && selectedIndex && (
        <Dialog fullWidth open={selectedIndex === fullPathId}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
          >
            <Button
              sx={{ borderRadius: '5%' }}
              data-cy={`CloseIcon-${nameWithId}`}
              onClick={handleCloseDialog}
              endIcon={<CloseIcon />}
            >
              {t('formlib_close_button_text')}
            </Button>
            {mapContainer ?? <></>}
            <Box
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '2px', m: '5px' }}
            >
              <Box sx={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Typography>{`${t('formlib_geoloc_latitude')} : ${locations?.lat}`}</Typography>
                <Typography>{`${t('formlib_geoloc_longitude')} : ${locations?.lng}`}</Typography>
              </Box>
              <Box sx={{ justifyContent: 'center' }}>
                <Fab
                  color="primary"
                  size="small"
                  sx={{ marginRight: '10px' }}
                  onClick={handleValidate}
                  data-cy={`ValidateBtn-${nameWithId}`}
                >
                  <DoneIcon />
                </Fab>
                <Fab color="error" size="small" data-cy={`CloseBtn-${nameWithId}`}>
                  <DeleteIcon onClick={handleDelete} />
                </Fab>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};
export default observer(GeolocationQcField);
