import React from 'react';
import { observer } from 'mobx-react';
import { Box, useTheme } from '@mui/material';
import { InboxesTreesViewName } from './const';
import PrefilledFormsTree from './PrefilledFormsTree';
import OtherInboxesTree from './OtherInboxesTree';
import TasksTree from './TasksTree';
import { useData } from './hooks';
import { ListColumnOrRowContainer } from '10.quickConnect.app/components/domain/Home/styles';

const InboxesTrees = () => {
  const theme = useTheme();
  const { OthersStore, PreFilledStore, TasksStore, prefilledItemsTitle, tasksItemsTitle, othersItemsTitle, t } =
    useData();
  return (
    <Box data-cy={InboxesTreesViewName} sx={ListColumnOrRowContainer(theme)}>
      <PrefilledFormsTree PreFilledStore={PreFilledStore} title={prefilledItemsTitle} />
      <TasksTree TasksStore={TasksStore} title={tasksItemsTitle} cle={t('tasks.subFolder')} />
      <OtherInboxesTree
        OthersStore={OthersStore}
        title={othersItemsTitle}
        cle={t('qcapp_inbox_item_category_not_found')}
      />
    </Box>
  );
};
export default observer(InboxesTrees);
