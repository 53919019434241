import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Tooltip, Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import DialogSummary from '../DialogQcField/DialogSummary';
import useData from './hooks';
import { AddNewEntry } from './styles';
import RepeatableQcFieldChild from './RepeatableQcFieldChild';
import { PreWrapSpan } from '50.quickConnect.Fields/hooks/styles';
import { FieldMainBox } from '50.quickConnect.Fields/styles';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { RepeatableGroupDesc } from '90.quickConnect.Models/models';
import { ContainerFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from '50.quickConnect.Fields/FieldsTypes/Containers/GroupQcField/styles';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';

const RepeatableQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  setDeclaration,
  context,
  updateFieldErrors,
  updateItemsForThisField,
  openByImagesGroupField,
}: ContainerFieldsTypesProps) => {
  const groupField = field as RepeatableGroupDesc;
  const { id, summary, fullPathId, isPopup, summaryTitle } = groupField;
  const {
    t,
    nameWithId,
    isExpanded,
    handleChange,
    items,
    addItem,
    removeItem,
    isAllExpanded,
    expandOrFoldAll,
    itemsValue,
  } = useData(
    groupField,
    updateDeclaration,
    setDeclaration,
    context,
    setSelectedIndex,
    flattenFields,
    openByImagesGroupField,
  );

  const customTheme = useTheme();
  const { breakpoints } = customTheme;

  return (
    <Box sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <CustomAccordion
        theme={customTheme}
        data-cy={nameWithId}
        expanded={isExpanded}
        onChange={() => handleChange(isExpanded)}
        elevation={0}
      >
        <CustomAccordionSummary
          theme={customTheme}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel1a-content-${nameWithId}`}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}> {labelSpan}</Box>

            {items && items.length > 0 && isExpanded && (
              <Tooltip
                title={
                  isAllExpanded
                    ? t('fields_repeatable_group_collapse_all').toString()
                    : t('fields_repeatable_group_expand_all').toString()
                }
              >
                <IconButton data-cy={`${nameWithId}-expandOrFoldAll`} onClick={expandOrFoldAll}>
                  {isAllExpanded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                </IconButton>
              </Tooltip>
            )}

            <Box>
              <Tooltip title={t('fields_repeatable_group_add').toString()}>
                <span>
                  <IconButton data-cy={`${nameWithId}-add`} color="primary" onClick={addItem} disabled={isReadOnly}>
                    <AddCircleIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </CustomAccordionSummary>

        <CustomAccordionDetails theme={customTheme} data-cy={`${nameWithId}-accordionDetails`}>
          {items.map((item, index) => {
            const key = getFieldKey(item);
            return (
              <>
                <MandatoryField key={key} fieldDesc={item}>
                  <RepeatableQcFieldChild
                    key={key}
                    updateDeclaration={updateDeclaration}
                    labelSpan={labelSpan}
                    flattenFields={flattenFields}
                    setDeclaration={setDeclaration}
                    removeItem={removeItem}
                    summary={summary}
                    field={item}
                    updateItemsForThisField={updateItemsForThisField}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    isReadOnly={isReadOnly}
                    index={index}
                    forceExpand={isAllExpanded}
                    parentId={id}
                    context={context}
                    updateFieldErrors={updateFieldErrors}
                    isPopup={isPopup}
                    summaryTitle={summaryTitle}
                  />
                </MandatoryField>
              </>
            );
          })}
          <Box sx={AddNewEntry} pb={1} pt={-3}>
            <Box>
              <PreWrapSpan data-cy={`${nameWithId}-label-down`}>
                {t('fields_repeatable_group_text_add_row')}
              </PreWrapSpan>
            </Box>
            <Tooltip title={t('fields_repeatable_group_add').toString()}>
              <span>
                <IconButton data-cy={`${nameWithId}-addDown`} color="primary" onClick={addItem} disabled={isReadOnly}>
                  <AddCircleIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
      {/* on est obligé d'utiliser l'opérateur spread ([...items]) pour que react comprennent les updates */}
      {summary === true && !isExpanded && (
        <DialogSummary items={[...itemsValue]} parentId={fullPathId} isReadOnly={isReadOnly} />
      )}
    </Box>
  );
};
export default observer(RepeatableQcField);
