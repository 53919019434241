import { TFunction } from 'i18next';
import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';

export const operationWithStrings = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  t: TFunction,
): [AllFieldValueTypes, OperatorResult] => {
  const ref1ValueStr = ref1Value!.toString();
  const ref2ValueStr = ref2Value!.toString();
  switch (type) {
    case QcOperator.Add:
      return [ref1ValueStr.concat(ref2ValueStr), OperatorResult.STRING];
    case QcOperator.Subst:
      return [ref1ValueStr.replace(ref2ValueStr, ''), OperatorResult.STRING];
    case QcOperator.Mult:
      return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
    case QcOperator.Div:
      return [t('computed_fields_errors_operator_incompatible').toString(), OperatorResult.ERROR];
    default:
      return [t('computed_fields_errors_operator_type_unknown').toString(), OperatorResult.ERROR];
  }
};
