import { AxiosError } from 'axios';
import { action, makeAutoObservable } from 'mobx';
import { AbortRequestsStore } from '../interfaces';
import { GetSharedListsRequest } from './Payloads/requests';
import { GetSharedListsResponse } from './Payloads/responses';
import { FieldDesc, KeyValuePair, SharedList } from '90.quickConnect.Models/models';
import { mapSharedList } from '90.quickConnect.Models/mappings';
import sharedListsDb from '40.quickConnect.DataAccess/indexedDb/dbs/sharedListsDb';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { API_GET_SHARED_LISTS } from '40.quickConnect.DataAccess/axios/apiRoutes';
import RootStore from '30.quickConnect.Stores/RootStore';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { errorHandler } from '80.quickConnect.Core/helpers';
class SharedListStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/SharedListStore/index.ts';

  clientHTTP: IClientHTTP;

  // Ne pas mettre dans resetStore
  shouldAbortRequests = false;

  logger: CustomLogger;

  RootStore: RootStore;

  sharedLists: SharedList[] | undefined;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    makeAutoObservable(
      this,
      {
        setShouldAbortRequests: action,
        getSharedList: action.bound,
        getSharedLists: action.bound,
      },
      { autoBind: true },
    );
  }

  setShouldAbortRequests = (shouldAbortRequests: boolean) => (this.shouldAbortRequests = shouldAbortRequests);

  resetStore = (): void => {
    this.sharedLists = [];
  };

  async getSharedLists(sharedListIds: string[]): Promise<void> {
    try {
      if (!this.sharedLists) this.sharedLists = (await sharedListsDb.sharedLists.toArray()) ?? [];
      const uniqueSharedListIds = [...new Set(sharedListIds)];
      // On récupère les shared liste depuis indexedDB
      const sharedListIdsAndEtags: KeyValuePair<string, string | undefined>[] = await Promise.all(
        uniqueSharedListIds.map(async (sharedListId) => {
          const foundSharedList = this.sharedLists!.find((sl) => sl.id === sharedListId);
          return { key: sharedListId, value: foundSharedList?.etag } as KeyValuePair<string, string | undefined>;
        }),
      );

      // On demande au serveur les shared listes en lui envoyant les ETags et le userUPN
      const {
        LoginStore: {
          signInInfos: { userUPN: forThisUPN },
        },
      } = this.RootStore;
      const request = {
        sharedListIdsAndEtags: sharedListIdsAndEtags,
        forThisUPN,
      } as GetSharedListsRequest;
      const response = await this.clientHTTP.post<GetSharedListsResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_GET_SHARED_LISTS),
        request,
        {
          withCredentials: true,
        },
      );
      const { data } = response;
      if (data && 200 <= response.status && response.status < 300) {
        const { sharedLists } = data;
        const mapped = sharedLists.map(mapSharedList);

        // On sauvegarde les sharedLists
        if (mapped.length > 0) {
          await Promise.all(
            mapped.map(async (mappedSharedList) => {
              // On ajoute la shared List au store
              if (this.sharedLists) {
                const sharedListIndex = this.sharedLists.findIndex((sl) => sl.id === mappedSharedList.id);
                if (sharedListIndex >= 0) {
                  this.sharedLists[sharedListIndex] = mappedSharedList;
                } else {
                  this.sharedLists.push(mappedSharedList);
                }
              }
              // On ajoute la shared List à la base locale
              const dbItem = await sharedListsDb.sharedLists.get({ id: mappedSharedList.id });
              if (dbItem) {
                await sharedListsDb.sharedLists.put(mappedSharedList);
              } else {
                await sharedListsDb.sharedLists.add(mappedSharedList);
              }
            }),
          );
        }
      } else {
        this.logger.trace(SharedListStore.TAG, response);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetSharedListsResponse>;
        errorAxios.message = `La récupération des listes partagées a échoué. Statut: ${errorAxios.response?.data.status} - Message: ${errorAxios.response?.data.message}`;
        errorHandler(SharedListStore.TAG, errorAxios, 'getSharedLists');
      } else errorHandler(SharedListStore.TAG, error, 'getSharedLists');
    }
  }

  async getSharedList(sharedListId: string): Promise<SharedList | undefined> {
    try {
      if (!this.sharedLists) this.sharedLists = (await sharedListsDb.sharedLists.toArray()) ?? [];
      const sharedList = this.sharedLists?.find((sl) => sl.id === sharedListId);
      return sharedList;
    } catch (error) {
      errorHandler(SharedListStore.TAG, error, 'getSharedList');
    }
  }
}
export default SharedListStore;
