import React, { Fragment, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { AttributeValue, Choice, NotificationTarget } from '90.quickConnect.Models/models';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/enums';
import { emailParse } from '10.quickConnect.app/components/domain/Declaration/helpers/emailParsers';
import { useLogger } from '80.quickConnect.Core/logger';

const useData = (): UseDataProps => {
  const { t } = useTranslation('equipment');
  const logger = useLogger();

  const displayAttributeValues = useCallback(
    (attribute: AttributeValue): string => {
      const { value, type } = attribute;
      if (value === null) return '-';

      switch (type) {
        case EntitySchemaAttributeTypes.Bool:
          return t(`entityData.value.${attribute.value}`);

        case EntitySchemaAttributeTypes.Email:
          if (typeof value === 'string') {
            const { selectedTargets } = emailParse(value, logger);
            const stringnifiedSelectedTargets = selectedTargets.reduce(
              (acc: string[], { target, alias }: NotificationTarget) => {
                const textToDisplay = alias && alias !== '' ? `${target} (${alias})` : target;

                return [...acc, textToDisplay];
              },
              [],
            );

            return stringnifiedSelectedTargets.join(', ');
          } else {
            return '-';
          }

        case EntitySchemaAttributeTypes.Decimal:
        case EntitySchemaAttributeTypes.Int:
          return typeof value === 'string' && !isNaN(parseFloat(value))
            ? value
            : typeof value === 'number'
            ? value.toString()
            : '-';

        case EntitySchemaAttributeTypes.Date:
          return typeof value === 'string' && !isNaN(Date.parse(value)) ? new Date(value).toLocaleDateString() : '-';
        case EntitySchemaAttributeTypes.DateTime:
          return typeof value === 'string' && !isNaN(Date.parse(value)) ? new Date(value).toLocaleString() : '-';

        case EntitySchemaAttributeTypes.SharedListHierarchical:
        case EntitySchemaAttributeTypes.SharedList:
          if (Array.isArray(value)) {
            const stringnifiedChoice = (value as Choice[]).reduce(
              (acc: string[], { label }: Choice) => [...acc, label],
              [],
            );

            return stringnifiedChoice.join(', ');
          }
          return typeof value === 'string' ? value : '-';

        default:
          return typeof value === 'string' ? value : '-';
      }
    },
    [logger, t],
  );

  return { displayAttributeValues };
};

export default useData;
