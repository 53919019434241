import { createContext, useContext } from 'react';
import { ImagesGroupContextProps } from '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/types';
import { errorHandler } from '80.quickConnect.Core/helpers';

const TAG = '50.quickConnect.Fields/FieldsTypes/Containers/ImagesGroupQcField/contexts/imagesGroupContext.ts';

const ImagesGroupContext = createContext<ImagesGroupContextProps | undefined>(undefined);

export const createImageGroupContextValue = ({
  flattenFields,
  imagesStateInfos,
  handleClickImageItem,
  updateGlobalDeclaration,
  closePopup,
}: ImagesGroupContextProps): ImagesGroupContextProps => ({
  imagesStateInfos,
  flattenFields,
  handleClickImageItem,
  updateGlobalDeclaration,
  closePopup,
});

export const useImagesGroupContext = (): ImagesGroupContextProps => {
  const imagesGroupContext = useContext(ImagesGroupContext);

  if (!imagesGroupContext) {
    const error = new Error('Pas de valeur fourni pour le context de ImagesGroupContext');
    errorHandler(TAG, error, 'useImagesGroupContext', 'error');

    throw error;
  }

  return imagesGroupContext;
};

export const ImagesGroupProvider = ImagesGroupContext.Provider;
