import { JsonWrapper } from '80.quickConnect.Core/helpers';
import { isChoice } from '90.quickConnect.Models/guards';
import { Choice } from '90.quickConnect.Models/models';

export function parseChoice(str: string): Choice | undefined {
  try {
    const choice = JsonWrapper.parse<Choice>(isChoice)(str, true);

    return choice;
  } catch (error) {
    const choice: Choice = {
      label: str,
      value: str,
      data: undefined,
    };

    return choice;
  }
}
