import mergeImages from 'merge-images';
import { errorHandler } from '80.quickConnect.Core/helpers';

const tag = '50.quickConnect.Fields/FieldsTypes/Attachments/helpers/bipMapDrawer.ts';

export const createBase64FromCanvas = async (canvasContainer: any): Promise<string> => {
  try {
    return await mergeImages([canvasContainer.childNodes[0].toDataURL(), canvasContainer.childNodes[1].toDataURL()]);
  } catch (error) {
    errorHandler(tag, error, 'createBase64FromCanvas');
    return '';
  }
};
