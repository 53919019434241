import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material';

import { UseDataProps, OptionsRender } from './types';

import { useStore } from '30.quickConnect.Stores';
import { debounceTime } from '50.quickConnect.Fields/const';
import { DataSourceType, NotificationType } from '90.quickConnect.Models/enums';
import {
  NotificationDesc,
  NotificationTarget,
  QCNotification,
  AllFieldValueTypes,
} from '90.quickConnect.Models/models';
import { isQCNotification } from '80.quickConnect.Core/helpers/common';
import { camelize, errorHandler } from '80.quickConnect.Core/helpers';
import {
  isOptionsRender,
  notificationHelperClearOption,
  notificationHelperCreateOption,
  notificationHelperRemoveOption,
  notificationHelperSelectOption,
} from '50.quickConnect.Fields/FieldsTypes/Inputs/NotificationQcField/helpers';
import { useFieldAutoComplete } from '50.quickConnect.Fields/hooks/useFieldAutoComplete';
import useLRU from '50.quickConnect.Fields/hooks/LRU';

/* eslint-disable max-lines-per-function */
const useData = (
  notificationDesc: NotificationDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: QCNotification) => void,
  selectedIndex: string | undefined,
  setSelectedIndex: Dispatch<SetStateAction<string | undefined>>,
): UseDataProps => {
  // Tag
  const tag = '50.quickConnect.Fields/FieldsTypes/Inputs/NotificationQcField/hook.tsx';

  const { t } = useTranslation('declaration');

  const {
    EquipmentsStore: { setIsInDataSource },
  } = useStore();

  const { getOptionsRenderForAAD, getOptionsRenderForFamilyDataSource } = useFieldAutoComplete();
  const {
    fullPathId,
    value: values,
    dataSourceType,
    dataSource,
    showAutoSendToMe,
    reference,
    autoSend,
    lruCount,
  } = notificationDesc;

  const [autoCompleteProp, setAutoCompleteProp] = useState<OptionsRender[]>([]);

  const [localValue, setLocalValue] = useState('');

  const [notificationData, setNotificationData] = useState<QCNotification>(() =>
    isQCNotification(values)
      ? { ...values, unProcessedData: '' }
      : ({
          notificationType: NotificationType.Email,
          subject: null,
          autoSend: autoSend ?? true,
          searchAllUO: false,
          sendToMe: false,
          selectedTargets: [],
          unProcessedData: '',
        } as QCNotification),
  );

  const {
    lruState: { list, showList },
    displayList,
    hideList,
    deleteLRU,
  } = useLRU({ fullId: fullPathId, lruCount: lruCount ?? 3 });

  const isDataSourceAADOrFamilly = useMemo(
    (): boolean => dataSourceType === DataSourceType.AzureAD || dataSourceType === DataSourceType.Family,
    [dataSourceType],
  );

  const valueToDisplay = useMemo(
    () =>
      isDataSourceAADOrFamilly
        ? notificationData.selectedTargets
        : isQCNotification(notificationData) && typeof notificationData !== 'string'
        ? notificationData.selectedTargets.map(
            (notificationTarget: NotificationTarget) => notificationTarget?.target ?? notificationTarget,
          )
        : [],
    [isDataSourceAADOrFamilly, notificationData],
  );

  const debouncedUpdateAutoCompleteProp = useDebouncedCallback(async (dataSourceTypeIndicator: DataSourceType) => {
    if (dataSourceTypeIndicator === DataSourceType.AzureAD) {
      if (localValue.length > 2) {
        const searchFieldValues = await getOptionsRenderForAAD(localValue);

        setAutoCompleteProp(searchFieldValues);
      } else setAutoCompleteProp([]);
    } else {
      if (dataSource) {
        if (localValue.length > 2) {
          const searchFieldValues = await getOptionsRenderForFamilyDataSource(localValue, dataSource, true);

          setAutoCompleteProp(searchFieldValues);
        } else setAutoCompleteProp([]);
      }
    }
  }, debounceTime);

  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, notificationData);
  }, [fullPathId, notificationData, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const handleOnChangeCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedIndex(fullPathId);
      setNotificationData({
        ...notificationData,
        sendToMe: e.target.checked,
      });
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState, notificationData, setSelectedIndex, fullPathId],
  );

  // Remplissage par LRU
  const onTagChange = useCallback(
    (event: React.SyntheticEvent, valueToAdd: string) => {
      event.preventDefault();
      setSelectedIndex(fullPathId);
      const { selectedTargets } = notificationData;
      const newSelectedTargets = [
        ...selectedTargets,
        {
          alias: '',
          target: valueToAdd,
        },
      ];

      setNotificationData({
        ...notificationData,
        selectedTargets: newSelectedTargets,
      });

      hideList();
      debouncedUpdateGlobalState();
    },
    [setSelectedIndex, debouncedUpdateGlobalState, fullPathId, notificationData, hideList],
  );

  const handleOnInputChange = useCallback(
    (event: React.SyntheticEvent, newValues: any) => {
      event.preventDefault();
      setSelectedIndex(fullPathId);
      setLocalValue(newValues);
      const nextNotificationData = {
        ...notificationData,
        unProcessedData: newValues,
      };
      setNotificationData(nextNotificationData);
      if (dataSourceType && newValues.length > 2) {
        if (dataSource?.forcedSelection) {
          setIsInDataSource(fullPathId, false);
        }
        switch (dataSourceType) {
          case DataSourceType.Family:
            debouncedUpdateAutoCompleteProp(DataSourceType.Family);
            break;
          case DataSourceType.AzureAD:
            debouncedUpdateAutoCompleteProp(DataSourceType.AzureAD);
            break;
          default:
            break;
        }
      } else {
        debouncedUpdateGlobalState();
      }
    },
    [
      setSelectedIndex,
      fullPathId,
      notificationData,
      dataSourceType,
      dataSource?.forcedSelection,
      setIsInDataSource,
      debouncedUpdateAutoCompleteProp,
      debouncedUpdateGlobalState,
    ],
  );

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: unknown[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<NotificationTarget> | undefined,
    ) => {
      event.preventDefault();
      // On prend en compte la reason...
      switch (reason) {
        case 'createOption': {
          // On ne prend que la dernière valeur...
          const [str] = value.reverse();

          if (typeof str !== 'string') return;
          const nextNotificationData = notificationHelperCreateOption(str, notificationDesc, notificationData, t);
          setNotificationData(nextNotificationData);
          break;
        }

        case 'selectOption': {
          // Dans ce cas, on aura un optionsRender Type...
          const [option] = value.reverse();
          if (isOptionsRender(option)) {
            const nextNotificationData = notificationHelperSelectOption(
              option,
              notificationDesc,
              notificationData,
              details,
              t,
            );
            setNotificationData(nextNotificationData);
          }
          break;
        }

        case 'removeOption': {
          const nextNotificationData = notificationHelperRemoveOption(notificationData, details);
          setNotificationData(nextNotificationData);
          break;
        }

        case 'clear': {
          const nextNotificationData = notificationHelperClearOption(notificationData);
          setNotificationData(nextNotificationData);
          break;
        }

        default:
          break;
      }
      debouncedUpdateGlobalState();
    },

    [notificationData, notificationDesc, t, debouncedUpdateGlobalState],
  );

  const handleDeleteItem = useCallback(
    async (event: React.SyntheticEvent, valueToRemove: string) => {
      event.preventDefault();
      await deleteLRU(valueToRemove);
    },
    [deleteLRU],
  );

  const createQCNotificationObject = useCallback(
    (v: AllFieldValueTypes): QCNotification | undefined => {
      try {
        if (typeof v !== 'string' && isQCNotification(v))
          return {
            ...v,
            selectedTargets: v.selectedTargets.filter(
              (nt: NotificationTarget, i: number, array: NotificationTarget[]) =>
                array.findIndex((ntBis: NotificationTarget) => ntBis.target === nt.target) === i,
            ),
          };

        if (typeof v !== 'string') return undefined;

        if (v === '') return undefined;

        const notifData: Partial<QCNotification> = JSON.parse(v, function (key: string, value: any) {
          const camelCaseKey: string = camelize(key);

          if (this instanceof Array || camelCaseKey === key) return value;
          else this[camelCaseKey] = value;
        });

        const newSelectedTargets =
          notifData.selectedTargets?.filter(
            (notifTarget: NotificationTarget, index: number, array: NotificationTarget[]) =>
              array.findIndex((nT: NotificationTarget) => nT.target === notifTarget.target) === index,
          ) ?? [];

        return {
          autoSend: notifData.autoSend ?? true,
          notificationType: notifData.notificationType ?? NotificationType.Email,
          searchAllUO: notifData.searchAllUO ?? false,
          selectedTargets: newSelectedTargets,
          sendToMe: notifData.sendToMe ?? false,
          subject: notifData.subject ?? null,
        } as QCNotification;
      } catch (error: unknown) {
        errorHandler(tag, error, 'createQCNotificationObject');
        return;
      }
    },
    [tag],
  );

  // UseEffect pour les references...
  useEffect(() => {
    try {
      const nextValue: QCNotification | undefined = createQCNotificationObject(values);
      if (nextValue)
        setNotificationData((previousState: QCNotification) =>
          previousState !== nextValue ? nextValue : previousState,
        );
    } catch (error: unknown) {
      errorHandler(tag, error, 'useEffect');
    }
  }, [reference, tag, values, createQCNotificationObject, debouncedUpdateGlobalState]);

  const toggleShowLruList = useCallback(() => {
    if (!showList) displayList();
    else hideList();
  }, [showList, displayList, hideList]);

  const open: boolean = useMemo(
    () => isDataSourceAADOrFamilly && selectedIndex === fullPathId && localValue.length > 2,
    [selectedIndex, fullPathId, localValue, isDataSourceAADOrFamilly],
  );

  // console.log(`open: ${fullPathId} - ${open}`);

  return {
    isDataSourceAADOrFamilly,
    valueToDisplay,
    handleOnInputChange,
    autoCompleteProp,
    setLocalValue,
    localValue,
    handleChange,
    t,
    dataSource,
    showAutoSendToMe,
    notificationData,
    handleOnChangeCheckBox,
    handleDeleteItem,
    toggleShowLruList,
    lruList: list,
    showLruList: showList,
    onTagChange,
    open,
  };
};

export default useData;
