import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { UseDataProps } from './types';
import useAttachments from '50.quickConnect.Fields/FieldsTypes/Attachments/useAttachments';
import {
  AllFieldValueTypes,
  AttachmentItemData,
  BaseAttachmentDesc,
  FieldDesc,
  TodoListDesc,
  TodoListTask,
  TodoTask,
} from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  field: TodoListDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  setLocalTodoTask: React.Dispatch<React.SetStateAction<TodoTask>>,
  listTasks: TodoListTask[],
  item: any,
  localTodoTask: TodoTask,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const {
    InboxesStore: { changeTaskState },
  } = useStore();

  const [localValue, setLocalValue] = useState<AttachmentItemData[]>(item?.attachment ?? []);
  const [localValueStatusCheck, setLocalValueStatusCheck] = useState(item.state == 100 ? true : false);
  const [currentValueStatusCheck, setCurrentValueStatusCheck] = useState<boolean>(false);

  const [currentAttachementValue, setCurrentAttachementValue] = useState<AttachmentItemData[]>([]);
  const [comment, setComment] = useState<string>(item.comment ?? '');
  const [currentCommentValue, setCurrentCommentValue] = useState<string>('');

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {}, []);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const { handleFileDrop, handleFileDelete, handleFilePreview } = useAttachments(
    true,
    localValue,
    setLocalValue,
    t,
    field as any,
    debouncedUpdateGlobalState,
    true,
  );

  useEffect(() => {
    if (
      (comment?.length > 0 && currentCommentValue.length > 0) ||
      (localValue.length > 0 && currentAttachementValue.length > 0) ||
      currentValueStatusCheck
    ) {
      const isExist = localTodoTask.taskEditedData.find((y) => y.id === item.id);
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!isExist) {
        const x = localTodoTask.taskEditedData.map((task) =>
          task.id === item.id
            ? {
                ...task,
                comment: comment,
                editedData: null,
                attachment: localValue.length == 0 ? null : localValue[0],
                state: localValueStatusCheck ? 100 : 0,
              }
            : task,
        );

        setLocalTodoTask({
          ...localTodoTask,
          taskEditedData: x,
        });
      } else {
        setLocalTodoTask({
          ...localTodoTask,
          taskEditedData: [
            ...localTodoTask.taskEditedData,
            {
              attachment: localValue.length == 0 ? null : localValue[0],
              comment: comment,
              editedData: null,
              id: item.id,
              name: item.taskName,
              state: localValueStatusCheck ? 100 : 0,
              taskId: item.taskId,
              taskOrder: item.taskOrder,
            },
          ],
        });
      }
      setCurrentValueStatusCheck(false);
      setCurrentCommentValue('');
      setCurrentAttachementValue([]);
    }
  }, [
    comment,
    currentAttachementValue.length,
    currentCommentValue.length,
    currentValueStatusCheck,
    item,
    localTodoTask,
    localValue,
    localValueStatusCheck,
    setLocalTodoTask,
  ]);
  const outlinedInputChanged = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      target: { value: newValue },
    } = event;
    setComment(newValue);
  }, []);

  const updateState = useCallback(
    (event: React.SyntheticEvent<Element>, checked: boolean) => {
      setLocalValueStatusCheck(checked);
      changeTaskState({ ...item, state: checked ? 100 : 0, comment: comment, attachment: localValue }, checked);
      debouncedUpdateGlobalState();
    },
    [changeTaskState, comment, debouncedUpdateGlobalState, item, localValue],
  );
  useEffect(() => {
    if (comment) {
      setCurrentCommentValue(comment);
    }
    if (localValue) {
      setCurrentAttachementValue(localValue);
    }
    if (localValueStatusCheck || !localValueStatusCheck) {
      setCurrentValueStatusCheck(false);
    }
  }, [comment, localValue, localValueStatusCheck]);
  // On retourne les valeurs à la vue
  return {
    localValue,
    setLocalValue,
    updateState,
    handleFileDrop,
    handleFileDelete,
    handleFilePreview,
    setComment,
    comment,
    outlinedInputChanged,
    localValueStatusCheck,
  };
};

export default useData;
