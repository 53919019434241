import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { FixedSizeList } from 'react-window';
import { Box } from '@mui/material';
import HierarchicalItem from '../HierarchicalItem';
import { VirtualizedHierarchicalListProps } from './types';
import { VirtualizedHierarchicalListName } from './const';

const VirtualizedHierarchicalList: React.VFC<VirtualizedHierarchicalListProps> = ({
  filteredChoices,
  height,
  width,
  onClickHandle,
  localValue,
  parentId,
  multiSelection,
}: VirtualizedHierarchicalListProps) => {
  const nameWithId = `${VirtualizedHierarchicalListName}-${parentId}`;

  const fixedSizeList = useMemo(
    () => (
      <FixedSizeList
        itemData={filteredChoices}
        itemCount={filteredChoices.length}
        itemSize={50}
        height={height}
        width={width - 32}
      >
        {({ data, index, style }) => (
          <div style={style}>
            <HierarchicalItem
              key={`${nameWithId}-${data[index].hierarchicalNodePath}`}
              onClickHandle={onClickHandle}
              hierarchicalChoice={data[index]}
              localValue={localValue}
              parentId={parentId}
              multiSelection={multiSelection}
            />
          </div>
        )}
      </FixedSizeList>
    ),
    [filteredChoices, height, localValue, multiSelection, nameWithId, onClickHandle, parentId, width],
  );

  return <Box data-cy={nameWithId}>{fixedSizeList}</Box>;
};
export default observer(VirtualizedHierarchicalList);
