import mapBaseContainerDesc from './mapBaseContainerDesc';
import { GroupDesc } from '90.quickConnect.Models/models';

const mapGroupDesc = (field: GroupDesc): GroupDesc => {
  const mapped = {
    ...mapBaseContainerDesc(field),
    isCollapsable: field.isCollapsable ?? true,
  } as GroupDesc;
  if (field.isCollapsable === false) mapped.value = true;
  return mapped;
};

export default mapGroupDesc;
