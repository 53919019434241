import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { AllFieldValueTypes, Choice, HierarchicalChoice, HierarchicalDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { mapHierarchicalChoices } from '90.quickConnect.Models/mappings';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';

const useData = (
  hierarchicalDesc: HierarchicalDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère le store
  const {
    SharedListStore: { getSharedList },
  } = useStore();

  const { updateProperty } = useQCUpdateFunctionsContext();

  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { listId, listChoice, fullPathId } = hierarchicalDesc;
  const [hierarchicalChoices, setHierarchicalChoices] = useState<HierarchicalChoice[] | undefined>(() => {
    if (listChoice) {
      return listChoice;
    }
    return undefined;
  });
  const [deletedItem, setDeleteItem] = useState<string>();
  const [isDeleteAll, setIsDeleteAll] = useState<boolean>(false);
  // Récupération des hierarchicalChoices
  const lookedForList = useRef(false);

  useEffectOnce(() => {
    if (listId && lookedForList.current === false) {
      getSharedList(listId).then((sharedList) => {
        if (sharedList) {
          setHierarchicalChoices(sharedList.hierarchicalChoices);
          updateProperty(fullPathId, 'listChoice', sharedList.hierarchicalChoices);
        } else setHierarchicalChoices(mapHierarchicalChoices([{ label: 'not found', value: 'string' } as Choice]));
      });
      lookedForList.current = true;
    }
  });

  const handleDeleteAll = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      updateDeclaration(fullPathId, []);
      setIsDeleteAll(true);
    },
    [fullPathId, updateDeclaration],
  );
  // On retourne les valeurs à la vue
  return {
    t,
    hierarchicalChoices,
    deletedItem,
    setDeleteItem,
    handleDeleteAll,
    setIsDeleteAll,
    isDeleteAll,
  };
};

export default useData;
