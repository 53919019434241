import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import { useStore } from '30.quickConnect.Stores';
import { UseDataProps } from '10.quickConnect.app/components/shared/Dialogs/SendingAttachmentsDialog/types';

const useData = (): UseDataProps => {
  const {
    DeclarationStore: { sendingAttachments, attachmentsToSend },
  } = useStore();

  const [open, setOpen] = useState<boolean>(sendingAttachments);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const maxAttachmentsRef = useRef<number>(0);

  const progress = useMemo((): number => {
    const totalAttachmentsToSend = Math.max(maxAttachmentsRef.current, attachmentsToSend);
    return Math.round(((totalAttachmentsToSend - attachmentsToSend) * 100) / totalAttachmentsToSend);
  }, [attachmentsToSend]);

  useEffect(() => {
    if (maxAttachmentsRef.current < attachmentsToSend) maxAttachmentsRef.current = attachmentsToSend;

    setOpen(sendingAttachments);
  }, [sendingAttachments, attachmentsToSend]);

  return {
    open,
    onClose,
    attachmentsToSend,
    progress,
  };
};

export default useData;
