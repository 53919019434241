const roundTo = (num: number, precision?: number): string => {
  // return precision
  //   ? (Math.round((num + Number.EPSILON) * 100000000) / 100000000).toFixed(precision)
  //   : (Math.round((num + Number.EPSILON) * 100000000) / 100000000).toString();

  return precision
    ? (Math.round((num + Number.EPSILON) * 10 ** precision) / 10 ** precision).toString()
    : (Math.round((num + Number.EPSILON) * 100000000) / 100000000).toString();
};
export default roundTo;
