import { FormType } from '90.quickConnect.Models/enums';
import { FormViewer } from '90.quickConnect.Models/models';

const mapFormViewer = (baseForm: FormViewer): FormViewer =>
  ({
    id: baseForm.id ?? '',
    name: baseForm.name ?? '',
    description: baseForm.description ?? '',
    formType: baseForm.formType ?? FormType.Unknow,
    folderPath: baseForm.folderPath ?? '',
    entitySchemaId: baseForm.entitySchemaId,
  } as FormViewer);

export default mapFormViewer;
