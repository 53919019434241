import { useLocation, useNavigate, useParams } from 'react-router';
import { Dispatch, Reducer, useReducer } from 'react';
import { PackStatus, WorkSiteAct, WorkSiteActType, WorkSiteProps, workSiteReducer } from './types';
import { WORK_SITE_SELECT, WORK_SITE_SUMMARY } from '10.quickConnect.app/routes/paths';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

//* TODO KTY MOCK DATA *//

const mockPackData = [
  { id: 'unId', name: 'Sécurité', status: PackStatus.COMPLETED, nbForm: 4, selected: false },
  { id: 'deuxId', name: 'Suivi Béton', status: PackStatus.SYNCING, nbForm: 4, selected: true },
  { id: 'troisId', name: 'Contrôles qualité', status: PackStatus.TOCOMPLETE, nbForm: 4, selected: false },
  { id: 'quatesId', name: 'Renforcement carbone', status: PackStatus.WAITING, nbForm: 4, selected: true },
  { id: 'cinqId', name: 'suivi des éléments préfabriqués', status: PackStatus.COMPLETED, nbForm: 4, selected: false },
  { id: 'sixId', name: 'tracking et BL dans SAP (€)', status: PackStatus.COMPLETED, nbForm: 4, selected: true },
];

const mockWORKSITE: WorkSiteProps = {
  hasChanged: false,
  id: 'IDDeMonChantier',
  name: 'bonjour je suis un nom2',
  agency: 'uneAgence',
  postalCode: '012120',
  sharePointSiteName: 'SharePointName',
  sharePointLibraryName: 'SharePointLibraryName',
  logo: 'Alors la le logo ça vas être fun ...',

  standardPacks: mockPackData,
  otherPacks: mockPackData.map((elem) => ({ ...elem, id: `${elem.id}other` })),

  reports: [
    {
      id: 'unId',
      name: "Rapport 1: J'ai besoins aussi d'un nom de rapport assez long parce que le dev front il doit aussi gérer ça",
      nbMissingReports: 3,
      description:
        "Description d'un formulaire, il faut que j'écrive quelque chose de long pour voir comment l'interface vas réagir. Si je ne fait pas ça les utilisateurs vont pouvoir avoir une description à ralonge qui vas casser l'interface et ça on ne veux pas que ça arrive Je vais donc continuer a écrire des lignes de texte pour enquiquiner le dev front",
      previewPictures: [
        'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image11.png',
        'https://devplequickconnect590.blob.core.windows.net/quick-connect-imagesbank-container/8eb7cb84-420a-4d50-861a-637f9237ee30/img/original/Image3.png',
        'https://i.pinimg.com/originals/9c/7b/e4/9c7be43979a736a8695361a544630b97.jpg',
      ],
      selected: false,
      forms: {
        Securite: [
          { id: 'formId1', name: 'Form1Secu', completed: true },
          { id: 'formId2', name: 'Form2Secu', completed: false },
        ],
        'Suivi Béton': [
          { id: 'formIdB1', name: 'Form1SB', completed: true },
          { id: 'formIdB2', name: 'Form2SB', completed: true },
        ],
        'Contrôles qualité': [
          { id: 'formIdCQ1', name: 'Form1CQ', completed: false },
          { id: 'formIdCQ2', name: 'Form2CQ', completed: false },
        ],
      },
    },
    {
      id: 'deuxId',
      name: 'Rapport 2',
      nbMissingReports: 0,
      description: "Description d'un formulaire",
      previewPictures: [
        'https://placehold.co/1000x400/EEE/31343C',
        'https://placehold.co/1000x1000/EEE/31343C',
        'https://placehold.co/100x1000/EEE/31343C',
        'https://placehold.co/50x50/EEE/31343C',
      ],
      selected: false,
      forms: { Securite: [{ id: 'formId', name: 'Form1', completed: true }] },
    },
    {
      id: 'TroisId',
      name: 'Rapport 3',
      nbMissingReports: 1,
      description: "Description d'un formulaire",
      previewPictures: [],
      selected: false,
      forms: { Securite: [{ id: 'formId', name: 'Form1', completed: false }] },
    },
    {
      id: 'QuatreId',
      name: 'Rapport 4',
      nbMissingReports: 0,
      description: "Description d'un formulaire",
      previewPictures: [],
      selected: false,
      forms: { Securite: [{ id: 'formId', name: 'Form1', completed: true }] },
    },
  ],
};

//* TODO KTY MOCK DATA *//

export type WorkSiteSummaryProps = {
  workSite: WorkSiteProps;
  setWorkSite: Dispatch<WorkSiteAct>;
};

export type NavigationProps = {
  backPage: () => void;
};

const useWorkSiteSummaryData = (): WorkSiteSummaryProps & NavigationProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workSiteId } = useParams();
  const [workSite, setWorkSite] = useReducer<Reducer<WorkSiteProps, WorkSiteAct>>(workSiteReducer, {
    id: workSiteId,
    hasChanged: false,
  });

  useEffectOnce(() => {
    console.log('TODO KTY call the chantier data !');
    const responseWorksite = mockWORKSITE;
    if (responseWorksite.name !== undefined) {
      setWorkSite({ type: WorkSiteActType.NAME, value: responseWorksite.name });
    }
    if (responseWorksite.agency !== undefined) {
      setWorkSite({ type: WorkSiteActType.AGENCY, value: responseWorksite.agency });
    }
    if (responseWorksite.postalCode !== undefined) {
      setWorkSite({ type: WorkSiteActType.POSTCODE, value: responseWorksite.postalCode });
    }
    if (responseWorksite.standardPacks !== undefined) {
      //TODO KTY parse the real data to Packs
      setWorkSite({ type: WorkSiteActType.STANDARDPACKS, value: responseWorksite.standardPacks });
    }
    if (responseWorksite.otherPacks !== undefined) {
      //TODO KTY parse the real data to Packs
      setWorkSite({ type: WorkSiteActType.OTHERPACKS, value: responseWorksite.otherPacks });
    }
    if (responseWorksite.reports !== undefined) {
      //TODO KTY parse real data to Reports
      setWorkSite({ type: WorkSiteActType.REPORTS, value: responseWorksite.reports });
    }
  });

  function backPage() {
    //TODO KTY display dialog if unsaved changes
    if (location.pathname.replace(workSite.id || '', '') === WORK_SITE_SUMMARY.replace(':workSiteId', '')) {
      navigate(WORK_SITE_SELECT);
      return;
    }
    navigate('.');
  }

  return { workSite, setWorkSite, backPage };
};

export default useWorkSiteSummaryData;
