/**
 * Enumération de l'ordonnencement des colonnes
 */
export enum ColumnOrderDirections {
  /**
   * Ordonné de manière ascendante
   */
  Asc = 0,
  /**
   * Ordonné de manière descendante
   */
  Desc = 1,
}
