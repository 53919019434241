const parseTimeFromAPI = (dateFromAPIStr: string): Date | undefined => {
  if (!dateFromAPIStr || dateFromAPIStr === '') return undefined;
  const timeArray = dateFromAPIStr
    .substring(dateFromAPIStr.indexOf('T') + 1, dateFromAPIStr.indexOf('T') + 6)
    .split(':')
    .map((t) => parseInt(t));

  const dt = new Date();
  dt.setHours(timeArray[0]);
  dt.setMinutes(timeArray[1]);

  return dt;
};

export default parseTimeFromAPI;
