import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import AttachmentItem from './AttachmentItem';
import useData from './hooks';
import { AttachmentsListName } from './const';
import { AttachmentsListProps } from './types';
import { FlexColumn, FlexRow } from '50.quickConnect.Fields/styles';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const AttachmentsList = ({
  parentId,
  attachments,
  onDrop,
  onDelete,
  onPreview,
  onlyImage,
  isReadOnly,
  stateId,
  fieldType,
}: AttachmentsListProps) => {
  const nameWithParentId = `${parentId}-${AttachmentsListName}-${stateId}`;
  // On récupère les hooks
  const { t } = useTranslation('declaration');
  const isMobile = useIsMobile();
  const { handleChange, drop } = useData(onDrop, nameWithParentId);

  const iconAdd = (
    <label htmlFor={`${nameWithParentId}-input`}>
      <IconButton component="span" data-cy={`${nameWithParentId}-add`} color="primary" disabled={isReadOnly}>
        <AddCircleOutlineIcon />
      </IconButton>
    </label>
  );

  const attachListMobile = (
    <Box
      sx={{
        display: 'flex',
        height: 200,
        width: '100%',
        border: '1px solid grey',
        overflowX: 'auto',
        overflowY: 'hidden',
        p: 1,
      }}
    >
      {attachments.length > 0 ? (
        attachments.map((item) => (
          <Box key={item.id}>
            <AttachmentItem
              onlyImage={onlyImage || item.type.startsWith('image/')}
              fieldType={fieldType}
              parentId={nameWithParentId}
              attachment={item}
              onDeleteFile={onDelete}
              onPreviewFile={onPreview}
              isReadOnly={isReadOnly}
            />
          </Box>
        ))
      ) : (
        <Box sx={{ ...FlexColumn, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="caption">{t('attachment_fields_labels_no_file_selected')}</Typography>
          <Box>
            {iconAdd}
            <Typography variant="caption">{t('attachment_fields_labels_add_picture')}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );

  const attachList = (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 200,
        border: '1px dashed grey',
        overflowX: 'auto',
        overflowY: 'hidden',
        p: 1,
      }}
      ref={drop}
    >
      {attachments.length > 0 ? (
        attachments.map((item) => (
          <Box key={item.id}>
            <AttachmentItem
              onlyImage={onlyImage || item.type.startsWith('image/')}
              fieldType={fieldType}
              parentId={nameWithParentId}
              attachment={item}
              onDeleteFile={onDelete}
              onPreviewFile={onPreview}
              isReadOnly={isReadOnly}
            />
          </Box>
        ))
      ) : (
        <Box sx={{ ...FlexColumn, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="caption">{t('attachment_fields_labels_no_file_selected')}</Typography>
          <Box>
            {iconAdd}
            <Typography variant="caption">
              {onlyImage ? t('attachment_fields_labels_drag_anImage_to_add') : t('attachment_fields_select_file')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
  return (
    <Box sx={{ ...FlexColumn, justifyContent: 'start' }}>
      <Box>{attachments.length > 0 ? iconAdd : <></>}</Box>
      <Box sx={{ ...FlexRow, height: 200, justifyContent: 'start' }} data-cy={nameWithParentId}>
        <input
          style={{ display: 'none' }}
          type="file"
          data-cy={`${nameWithParentId}-input`}
          id={`${nameWithParentId}-input`}
          name={`${nameWithParentId}-input`}
          onChange={handleChange}
          accept={onlyImage ? 'image/*' : '*/*'}
          multiple
          readOnly={isReadOnly}
          disabled={isReadOnly}
        />
        {isMobile ? attachListMobile : attachList}
      </Box>
    </Box>
  );
};

export default observer(AttachmentsList);
