import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import useAttachments from '../useAttachments';
import { UseDataProps } from './types';
import { debounceTime } from '50.quickConnect.Fields/const';
import { AllFieldValueTypes, BaseAttachmentDesc, AttachmentItemData } from '90.quickConnect.Models/models';

const useData = (
  onlyImage: boolean,
  attachDesc: BaseAttachmentDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { fullPathId, value } = attachDesc;
  const [localValue, setLocalValue] = useState((value as AttachmentItemData[]) ?? []);

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const { handleFileDrop, handleFileDelete, handleFilePreview } = useAttachments(
    onlyImage,
    localValue,
    setLocalValue,
    t,
    attachDesc,
    debouncedUpdateGlobalState,
  );

  const updateState = useCallback(() => {
    //setLocalValue(event.target.value);
    debouncedUpdateGlobalState();
  }, [debouncedUpdateGlobalState]);

  // On retourne les valeurs à la vue
  return {
    t,
    localValue,
    setLocalValue,
    updateState,
    handleFileDrop,
    handleFileDelete,
    handleFilePreview,
  };
};

export default useData;
