import { NamedUserParameterValue } from '90.quickConnect.Models/models';

const mapUserParams = (userParam: NamedUserParameterValue): NamedUserParameterValue =>
  ({
    userParameterId: userParam.userParameterId,
    name: userParam.name,
    userParameterType: userParam.userParameterType,
    value: userParam.value,
    valueInt: userParam.valueInt,
    valueDecimal: userParam.valueDecimal,
  } as NamedUserParameterValue);

export default mapUserParams;
