/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { toJS } from 'mobx';
import { useLocation, useNavigate } from 'react-router';
import { format } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';
import { toast } from 'react-toastify';
import { CommandType, UseDataProps } from './types';
import { DECLARATIONS, FORMS } from '10.quickConnect.app/routes/paths';
import {
  BaseQCOperator,
  DeclarationContext,
  FieldDesc,
  HistoricalStepData,
  ItemData,
  StepDesc,
} from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';
import { ValidationActionType, FormType, WorkflowStatus, WorkflowStepStatus } from '90.quickConnect.Models/enums';
import evaluateVisibility from '20.formLib/helpers/evals/evaluateVisibility';
import evalDependencies from '20.formLib/helpers/evalDependencies/evalDependencies';
import attachmentDb from '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { dateFormatWithMillisecond } from '80.quickConnect.Core/helpers/dateFormat';
import inboxesDb from '40.quickConnect.DataAccess/indexedDb/dbs/inboxesDb';

export const saveDebounceTime = 10000;

const useData = (
  context: DeclarationContext,
  declaration: FieldDesc[],
  declarationData: ItemData,
  flattenFields: FieldDesc[],
  steps: StepDesc[],
  activityId: string | undefined,
  inboxId: string | undefined,
  declarationId: string,
): UseDataProps => {
  // Tag
  const tag = '20.formLib/DeclarationContainer/Commands/hooks.ts';

  // On récupère le store
  const {
    DeclarationStore: {
      saveDeclarationAsync,
      setEditableDeclaration,
      openDeclarationDialog,
      closeDialog,
      setIsUseDeclarationComponent,
      setIsEditingCurrentDeclaration,
      removeLocalDeclaration,
      isEditingCurrentDeclaration,
      editableDeclaration,
      resetStore: dclResetStore,
    },
    InboxesStore: { removeInboxActivityId, deleteInboxAsync, deleteInboxes },
    LoginStore: { signInInfos, getUserParameterValuesAsync },
    CommonStore: { showLeftMenu, setWorkInProgress, setWorkDone },
    QCScriptStore: { resetStore: qcsResetStore },
    ConsentStore: { resetStore: consentResetStore },
  } = useStore();

  const isOnline = useNavigatorOnLine();
  const { organizationalUnitName, userUPN, email } = signInInfos;
  const { Workflow } = declarationData;
  const { pathname } = useLocation();

  const { t } = useTranslation('declaration');
  const { t: tAxios } = useTranslation('axios');
  const { formType } = context;
  // On définit le state
  const synchronizeDeclarationJob = useMemo(
    () => [uuidv4(), t('job_message_synchronize_declaration')] as [string, string],
    [t],
  );
  const transferDeclarationJob = useMemo(
    () => [uuidv4(), t('job_message_transfer_declaration')] as [string, string],
    [t],
  );
  const deleteInboxJob = useMemo(() => [uuidv4(), t('job_message_delete_inbox')] as [string, string], [t]);
  const navigate = useNavigate();

  const [openCommands, setOpenCommands] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const evaluateTransition = useCallback(
    async (step: StepDesc): Promise<boolean> => {
      const { transition, id, fullPathId } = step;
      if (transition) {
        const transitionRule = transition as BaseQCOperator;
        const otherFields = flattenFields.filter((f) => f.id !== id);
        const dependencies = evalDependencies(transitionRule, [], otherFields, fullPathId);
        const userParameterValue = await getUserParameterValuesAsync();
        return evaluateVisibility(t, transitionRule, dependencies, userParameterValue, fullPathId, userUPN, email);
      }
      // Si il n'y a pas de règle de transition, on considère qu'on a le droit de passer à l'étape suivante
      return true;
    },
    [email, flattenFields, t, getUserParameterValuesAsync, userUPN],
  );

  const handleItemDatas = useCallback(
    async (commandType: CommandType): Promise<[boolean, string | undefined, ItemData]> => {
      /*** Gestion du workflow ***/
      let goToNextStep = false;
      let nextItemData: ItemData = declarationData;
      let nextStepIndex: string | undefined;
      if (Workflow && formType === FormType.Workflow) {
        const { currentStep: currentStepId } = Workflow;
        const currentStepIndex = steps.findIndex(
          (s) => s.id.localeCompare(currentStepId, undefined, { sensitivity: 'base' }) === 0,
        );
        const currentStep: StepDesc = steps[currentStepIndex];

        let newWorkflowStatus = WorkflowStatus.None;
        let newStepState = WorkflowStepStatus.None;

        switch (commandType) {
          case CommandType.Send:
            // Si on est à la dernière étape, on indique que le Workflow est terminé
            if (currentStepIndex === steps.length - 1) newWorkflowStatus = WorkflowStatus.ToClose;
            // Sinon on passe à l'étape suivante
            else {
              newWorkflowStatus = WorkflowStatus.ToForward;
              // On évalue les droits sur la current step pour savoir si on est autorisé à passer à l'étape suivante
              goToNextStep = await evaluateTransition(currentStep);
              nextStepIndex = steps[currentStepIndex + 1].id;
            }
            newStepState = WorkflowStepStatus.Validated;
            break;
          case CommandType.Previous:
            if (currentStepIndex > 0) {
              newWorkflowStatus = WorkflowStatus.ToReview;
              // Récupération de la dernière étape historique
              const [lastHistoricalStep] = Workflow.historicalStepDataList.slice(-1);
              // Regarde si la derniere étape de l'historique est notre étape (même user)
              if (lastHistoricalStep && lastHistoricalStep.author === userUPN) {
                const previousStep = steps[currentStepIndex - 1];
                nextStepIndex = previousStep.id;
                goToNextStep = true;
              } else {
                goToNextStep = false;
              }
            }
            newStepState = WorkflowStepStatus.Canceled;
            break;
          case CommandType.Cancel:
            newWorkflowStatus = WorkflowStatus.ToCancel;
            goToNextStep = false;
            nextStepIndex = currentStepId;
            newStepState = WorkflowStepStatus.Canceled;
            break;
          case CommandType.Pause:
          case CommandType.Transfer:
            newWorkflowStatus = WorkflowStatus.None;
            goToNextStep = false;
            nextStepIndex = currentStepId;
            newStepState = WorkflowStepStatus.Editing;
            break;
        }

        // On update les HistoricalStepData
        const newHistoricalStepData = {
          author: userUPN,
          stepId: currentStepId,
          stepLabel: currentStep.label,
          editedAt: new Date(),
          comment: null,
          stepState: newStepState,
        } as HistoricalStepData;

        if (declarationData && Workflow) {
          const { historicalStepDataList } = Workflow;
          nextItemData = {
            ...nextItemData,
            Workflow: {
              ...Workflow,
              currentStep: currentStepId,
              currentWorkflowState: newWorkflowStatus,
              historicalStepDataList:
                commandType === CommandType.Pause
                  ? historicalStepDataList
                  : [...historicalStepDataList, newHistoricalStepData],
            },
          } as ItemData;
        }
      }
      /*** Fin Gestion du workflow ***/
      return [goToNextStep, nextStepIndex, nextItemData];
    },
    [declarationData, evaluateTransition, formType, steps, userUPN, Workflow],
  );

  const sendDeclaration = useCallback(
    async (newItemData: ItemData): Promise<boolean> => {
      let declarationSend = false;
      if (signInInfos) {
        const editedAt = new Date();
        CustomLogger.getInstance().info(
          tag,
          `[Client Web] - Evenement "sendDeclaration" déclenché par l'utilisateur ${userUPN} pour la dcl ${
            context.name
          } le ${format(editedAt, dateFormatWithMillisecond)}.`,
        );
        context.editedAt = editedAt;
        setWorkInProgress(synchronizeDeclarationJob);
        // Mise en base locale dans la table déclaration
        declarationSend = await saveDeclarationAsync(
          context,
          declaration,
          userUPN,
          organizationalUnitName,
          tAxios,
          false,
          newItemData,
          activityId,
          inboxId,
          isOnline,
          declarationId,
          signInInfos,
          true,
        );
        if (declarationSend && (inboxId || (context.formType === FormType.Workflow && context.id))) {
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!context.activityId) await removeInboxActivityId(userUPN, context.activityId, new Date());
        }
        setWorkDone(synchronizeDeclarationJob[0]);
      }

      return declarationSend;
    },
    [
      tag,
      signInInfos,
      removeInboxActivityId,
      userUPN,
      setWorkInProgress,
      synchronizeDeclarationJob,
      saveDeclarationAsync,

      context,
      declaration,
      organizationalUnitName,
      tAxios,
      activityId,
      inboxId,
      isOnline,
      declarationId,
      setWorkDone,
    ],
  );

  const transferDeclaration = useCallback(
    async (newItemData: ItemData | undefined): Promise<boolean> => {
      let declarationTransfer = false;
      if (signInInfos) {
        const editedAt = new Date();
        CustomLogger.getInstance().info(
          tag,
          `[Client Web] - Evenement "transferDeclaration" déclenché par l'utilisateur ${userUPN} pour la dcl ${
            context.name
          } le ${format(editedAt, dateFormatWithMillisecond)}.`,
        );
        context.editedAt = editedAt;
        context.validationAction = ValidationActionType.TransferredToMe;
        setWorkInProgress(transferDeclarationJob);
        // Mise en base locale dans la table déclaration
        declarationTransfer = await saveDeclarationAsync(
          context,
          declaration,
          userUPN,
          organizationalUnitName,
          tAxios,
          false,
          newItemData,
          activityId,
          inboxId,
          isOnline,
          declarationId,
          signInInfos,
          true,
        );
        if (declarationTransfer && (inboxId || (context.formType === FormType.Workflow && context.id))) {
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!context.activityId) await removeInboxActivityId(userUPN, context.activityId, new Date());
        }
        setWorkDone(transferDeclarationJob[0]);
      }

      return declarationTransfer;
    },
    [
      tag,
      signInInfos,
      removeInboxActivityId,
      userUPN,
      setWorkInProgress,
      transferDeclarationJob,
      saveDeclarationAsync,
      context,
      declaration,
      organizationalUnitName,
      tAxios,
      activityId,
      inboxId,
      isOnline,
      declarationId,
      setWorkDone,
    ],
  );

  /**
   * Permet de fermer la dialogue quand un utilisateur quitte une déclaration sans mettre en pause, abandon ou envoyer
   */
  const onCloseDialog = useCallback((): void => {
    closeDialog();
  }, [closeDialog]);

  /**
   * Enregistre la déclaration en local ou serveur
   */
  const saveDeclarationHookAsync = useCallback(
    async (saveAsDraft = false, sendAPI = false) => {
      if (signInInfos && editableDeclaration && (isEditingCurrentDeclaration || inboxId)) {
        await saveDeclarationAsync(
          context,
          declaration,
          userUPN,
          organizationalUnitName,
          tAxios,
          saveAsDraft,
          declarationData,
          activityId,
          inboxId,
          isOnline,
          declarationId,
          signInInfos,
          sendAPI,
        );
      }
    },
    [
      editableDeclaration,
      activityId,
      context,
      declaration,
      declarationData,
      declarationId,
      inboxId,
      isOnline,
      organizationalUnitName,
      saveDeclarationAsync,
      signInInfos,
      tAxios,
      userUPN,
      isEditingCurrentDeclaration,
    ],
  );

  /**
   * Enregistre les inbox à supprimer en local ou serveur
   */
  const deleteInboxHookAsync = useCallback(
    async (sendAPI = false) => {
      if (signInInfos) {
        await deleteInboxAsync(context, userUPN, tAxios, inboxId, declarationId, sendAPI);
      }
    },
    [signInInfos, deleteInboxAsync, context, userUPN, tAxios, inboxId, declarationId],
  );

  /**
   * Crée une sauvegarde serveur par la dialogue
   */
  const saveDeclarationInDraft = useCallback(async () => {
    if (signInInfos) {
      setWorkInProgress(synchronizeDeclarationJob);
      await saveDeclarationHookAsync(true, true);
      if (inboxId) await deleteInboxHookAsync(true);
      setIsUseDeclarationComponent(false);
      setIsEditingCurrentDeclaration(false);
      setEditableDeclaration(false);
      onCloseDialog();
      setWorkDone(synchronizeDeclarationJob[0]);
      dclResetStore();
      consentResetStore();
      qcsResetStore();
      navigate(FORMS, { replace: true });
    }
  }, [
    signInInfos,
    setWorkInProgress,
    synchronizeDeclarationJob,
    saveDeclarationHookAsync,
    inboxId,
    deleteInboxHookAsync,
    setIsUseDeclarationComponent,
    setIsEditingCurrentDeclaration,
    setEditableDeclaration,
    onCloseDialog,
    setWorkDone,
    dclResetStore,
    consentResetStore,
    qcsResetStore,
    navigate,
  ]);

  /**
   * Crée une sauvegarde serveur par la dialogue
   */
  const onAbandonInbox = useCallback(async () => {
    if (signInInfos) {
      setWorkInProgress(deleteInboxJob);
      await deleteInboxHookAsync(true);
      try {
        const apiResponse = await deleteInboxes();
        if (apiResponse) {
          const infoMsg = tAxios('qcapp_delete_inbox_success');
          toast.success(infoMsg);
        } else {
          const infoMsg = tAxios('qcapp_delete_inbox_fail');
          toast.error(infoMsg);
          return true;
        }
      } catch (error) {
        toast.error(`Error calling deleteInboxes :${error}`);
      }
      setIsUseDeclarationComponent(false);
      setIsEditingCurrentDeclaration(false);
      setEditableDeclaration(false);
      onCloseDialog();
      setWorkDone(deleteInboxJob[0]);
      dclResetStore();
      consentResetStore();
      qcsResetStore();
      navigate(FORMS, { replace: true });
    }
  }, [
    setEditableDeclaration,
    dclResetStore,
    consentResetStore,
    tAxios,
    qcsResetStore,
    signInInfos,
    deleteInboxHookAsync,
    setWorkInProgress,
    setWorkDone,
    deleteInboxJob,
    setIsEditingCurrentDeclaration,
    setIsUseDeclarationComponent,
    onCloseDialog,
    navigate,
    deleteInboxes,
  ]);

  /**
   * Permet de fermer la dialogue et de revenir sur le component FORMS sans sauver la déclaration.
   */
  const onExitDeclaration = useCallback(async () => {
    onCloseDialog();
    setIsUseDeclarationComponent(false);
    setIsEditingCurrentDeclaration(false);
    setEditableDeclaration(false);
    dclResetStore();
    qcsResetStore();
    consentResetStore();
    // Supprimer les pièces jointes
    await attachmentDb.deleteAttachmentItemsDataByDeclarationId(declarationId);
    await removeLocalDeclaration(declarationId, true, context.formId, signInInfos.userUPN);
    const doesDeclarationExist = await inboxesDb.doesDeclarationExist(declarationId);
    if (doesDeclarationExist) {
      await inboxesDb.markInboxAsDeleted(declarationId);
      try {
        const apiResponse = await deleteInboxes();
        if (apiResponse) {
          const infoMsg = tAxios('qcapp_delete_inbox_success');
          toast.success(infoMsg);
        } else {
          const infoMsg = tAxios('qcapp_delete_inbox_fail');
          toast.error(infoMsg);
          return true;
        }
      } catch (error) {
        toast.error('Error calling deleteInboxes :' + error);
      }
    }

    if (pathname.includes('declaration/')) {
      navigate(DECLARATIONS, { replace: true });
    } else {
      navigate(FORMS, { replace: true });
    }
  }, [
    onCloseDialog,
    setIsUseDeclarationComponent,
    setIsEditingCurrentDeclaration,
    setEditableDeclaration,
    dclResetStore,
    qcsResetStore,
    consentResetStore,
    declarationId,
    removeLocalDeclaration,
    context.formId,
    signInInfos.userUPN,
    pathname,
    deleteInboxes,
    tAxios,
    navigate,
  ]);

  const onPreviousPage = useCallback(() => {
    setOpenCommands(false);
    navigate(-1);
  }, [setOpenCommands, navigate]);

  // Ce code permet de sauvegarder en local la saisie courante

  const debouncedSaveDeclarationHookAsync = useDebouncedCallback(() => {
    saveDeclarationHookAsync();
  }, saveDebounceTime);

  useEffect(() => {
    debouncedSaveDeclarationHookAsync();
  });

  return {
    t,
    openCommands,
    setOpenCommands,
    showLeftMenu,
    handleItemDatas,
    sendDeclaration,
    transferDeclaration,
    openHistory,
    setOpenHistory,
    openDeclarationDialog,
    onCloseDialog,
    saveDeclarationInDraft,
    onExitDeclaration,
    onAbandonInbox,
    onPreviousPage,
  };
};

export default useData;
