import { OperatorType, QcOperator } from '90.quickConnect.Models/enums';

const mapQcOperatorType = (value: QcOperator): OperatorType => {
  switch (value) {
    case QcOperator.Container:
      return OperatorType.ComparisonContainer;

    case QcOperator.Add:
    case QcOperator.Subst:
    case QcOperator.Mult:
    case QcOperator.Div:
      return OperatorType.Arithmetic;

    case QcOperator.CtrlRef:
    case QcOperator.CtrlRefReceiver:
    case QcOperator.IsInMyInformation:
      return OperatorType.Selector;

    case QcOperator.Sum:
    case QcOperator.Avg:
      return OperatorType.Pattern;

    case QcOperator.ConstDouble:
    case QcOperator.ConstString:
    case QcOperator.CtrlUserParameterRef:
      return OperatorType.Value;

    case QcOperator.And:
    case QcOperator.Or:
    case QcOperator.Parenthesis:
    case QcOperator.Multi:
      return OperatorType.Bitwise;

    case QcOperator.IIf:
      return OperatorType.Conditional;

    case QcOperator.Equal:
    case QcOperator.Less:
    case QcOperator.Greater:
    case QcOperator.LessOrEqual:
    case QcOperator.GreaterOrEqual:
    case QcOperator.Different:
    case QcOperator.Contains:
    case QcOperator.NotContains:
    case QcOperator.ContainsText:
    case QcOperator.NotContainsText:
    case QcOperator.StartsWith:
    case QcOperator.EndsWith:
    case QcOperator.IsEmpty:
    case QcOperator.IsNotEmpty:
      return OperatorType.Comparison;

    default:
      throw new Error('Unknow operator type to manage');
  }
};

export default mapQcOperatorType;
