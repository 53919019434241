import { action, makeAutoObservable, observable } from 'mobx';

import { makePersistable, clearPersistedStore, stopPersisting } from 'mobx-persist-store';
import RootStore from '30.quickConnect.Stores/RootStore';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';

class SessionStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/SessionStore/index.ts';

  isSynchronizingRepository = false;

  logger: CustomLogger;

  RootStore: RootStore;

  lastURLSaved: string | undefined;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.logger = logger;
    this.RootStore = rootStore;
    makeAutoObservable(
      this,
      {
        isSynchronizingRepository: observable,
        lastURLSaved: observable,

        resetStore: action,
        setLastURLSaved: action,
        setIsSynchronizingRepository: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: this.constructor.name,
      properties: ['isSynchronizingRepository', 'lastURLSaved'],
      storage: window.sessionStorage,
    });
  }

  setLastURLSaved = (lastURLSaved: string | undefined) => {
    this.lastURLSaved = lastURLSaved;
  };

  setIsSynchronizingRepository = (isSynchronizingRepository: boolean) =>
    (this.isSynchronizingRepository = isSynchronizingRepository);

  resetStore = () => {
    stopPersisting(this);
    clearPersistedStore(this);
    this.setIsSynchronizingRepository(false);
    this.lastURLSaved = undefined;
  };
}
export default SessionStore;
