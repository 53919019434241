import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, Typography } from '@mui/material';
import { ComputeQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import useData from '50.quickConnect.Fields/FieldsTypes/Others/ComputeQcField/hooks';

const ComputeQcField = ({ field, labelSpan, setSelectedIndex, isReadOnly }: ValueFieldsTypesProps) => {
  const { id, toolTip, value, fullPathId } = field;
  const nameWithId = `${ComputeQcFieldName}-${id}`;

  const { computedValue } = useData(value);

  const { breakpoints } = useTheme();

  return (
    <Box
      data-cy={nameWithId}
      sx={FieldMainBox(breakpoints)}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      <Box sx={FlexRow}>
        <Box>{labelSpan}</Box>
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <Typography data-cy={`${nameWithId}-result`}>{computedValue}</Typography>
    </Box>
  );
};
export default observer(ComputeQcField);
