import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Fab, useTheme } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import { PauseCommandName } from './const';
import { PauseCommandProps } from './types';
import useData from './hooks';

const PauseCommand = ({
  context,
  declaration,
  updateDeclaration,
  flattenFields,
  handleItemDatas,
  setDeclarationData,
  activityId,
  inboxId,
  declarationId,
}: PauseCommandProps) => {
  const { t, onPause } = useData(
    context,
    declaration,
    updateDeclaration,
    flattenFields,
    handleItemDatas,
    setDeclarationData,
    activityId,
    inboxId,
    declarationId,
  );

  const theme = useTheme();

  const {
    palette: {
      info: { light },
    },
  } = theme;

  return (
    <Tooltip title={t('formlib_draft_button_text').toString()} placement="right">
      <Fab
        sx={{ marginTop: '10px', backgroundColor: light }}
        data-cy={`${PauseCommandName}-btn-pause`}
        size="large"
        onClick={onPause}
      >
        <PauseIcon />
      </Fab>
    </Tooltip>
  );
};

export default observer(PauseCommand);
