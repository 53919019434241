import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const useData = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [dimensions, setDimensions] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    if (isLg) {
      setDimensions({
        height: 317,
        width: 318,
      });
    } else if (isMd) {
      setDimensions({
        height: 235,
        width: 236,
      });
    } else if (isSm) {
      setDimensions({
        height: 157,
        width: 158,
      });
    } else if (isXs) {
      setDimensions({
        height: 79,
        width: 80,
      });
    }
  }, [isLg, isMd, isSm, isXs]);

  return dimensions;
};

export default useData;
