import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { debounceTime } from '50.quickConnect.Fields/const';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    InboxesStore: {
      inboxesTotalNumber,
      PreFilledStore: {
        items: preFilled,
        expandedNodes: preFilledExpandedNodes,
        treeNodeKeys: preFilledTreeNodeKeys,
        setFilter: setPreFilledFilter,
        filter: preFilledFilter,
        setExpandedNodes: setPreFilledExpandedNodes,
        numberDisplayed: numberPreFilledDisplayed,
      },
      TasksStore: {
        items: tasks,
        expandedNodes: tasksExpandedNodes,
        treeNodeKeys: tasksTreeNodeKeys,
        setFilter: setTaskFilter,
        filter: tasksFilter,
        setExpandedNodes: setTasksExpandedNodes,
        numberDisplayed: numberTasksDisplayed,
      },
      OthersStore: {
        items: others,
        expandedNodes: othersExpandedNodes,
        treeNodeKeys: othersTreeNodeKeys,
        setFilter: setOtherFilter,
        filter: otherFilter,
        setExpandedNodes: setOthersExpandedNodes,
        numberDisplayed: numberOthersDisplayed,
      },
    },
    UserSettingsStore: { inboxesAsTree, setInboxesAsTree, setInboxesAsAlphaNumericSort, inboxesAsAlphaNumericSort },
  } = useStore();

  // On définit le state
  const [filterInputValue, setFilterInputValue] = useState(preFilledFilter ?? tasksFilter ?? otherFilter);
  const inboxesTreeNodeNumber = useMemo(
    () => preFilledTreeNodeKeys.length + tasksTreeNodeKeys.length + othersTreeNodeKeys.length,
    [othersTreeNodeKeys.length, preFilledTreeNodeKeys.length, tasksTreeNodeKeys.length],
  );
  const isAllExpanded = useMemo(
    () =>
      preFilledExpandedNodes.length + tasksExpandedNodes.length + othersExpandedNodes.length < inboxesTreeNodeNumber,
    [inboxesTreeNodeNumber, othersExpandedNodes.length, preFilledExpandedNodes.length, tasksExpandedNodes.length],
  );

  const handleExpandClick = useCallback(() => {
    setPreFilledExpandedNodes(isAllExpanded ? preFilledTreeNodeKeys : []);
    setTasksExpandedNodes(isAllExpanded ? tasksTreeNodeKeys : []);
    setOthersExpandedNodes(isAllExpanded ? othersTreeNodeKeys : []);
  }, [
    isAllExpanded,
    othersTreeNodeKeys,
    preFilledTreeNodeKeys,
    setOthersExpandedNodes,
    setPreFilledExpandedNodes,
    setTasksExpandedNodes,
    tasksTreeNodeKeys,
  ]);

  const applyFilter = useCallback((): void => {
    setPreFilledFilter(filterInputValue);
    setTaskFilter(filterInputValue);
    setOtherFilter(filterInputValue);
  }, [filterInputValue, setOtherFilter, setPreFilledFilter, setTaskFilter]);

  useEffect(() => {
    if (preFilledFilter !== '' && tasksFilter !== '' && otherFilter !== '') {
      setPreFilledExpandedNodes(preFilledTreeNodeKeys);
      setTasksExpandedNodes(tasksTreeNodeKeys);
      setOthersExpandedNodes(othersTreeNodeKeys);
    }
  }, [
    otherFilter,
    othersTreeNodeKeys,
    preFilledFilter,
    preFilledTreeNodeKeys,
    setOthersExpandedNodes,
    setPreFilledExpandedNodes,
    setTasksExpandedNodes,
    tasksFilter,
    tasksTreeNodeKeys,
  ]);

  const clearFilter = useCallback((): void => {
    setFilterInputValue('');
    setPreFilledFilter('');
    setTaskFilter('');
    setOtherFilter('');
  }, [setOtherFilter, setPreFilledFilter, setTaskFilter]);

  const numberItemsDisplayed = useMemo(
    () => numberPreFilledDisplayed + numberTasksDisplayed + numberOthersDisplayed,
    [numberPreFilledDisplayed, numberTasksDisplayed, numberOthersDisplayed],
  );

  const debouncedSearch = useDebouncedCallback((valueToSearch) => {
    setPreFilledFilter(valueToSearch);
    setTaskFilter(valueToSearch);
    setOtherFilter(valueToSearch);
  }, debounceTime);

  useEffect(() => {
    if (filterInputValue) {
      debouncedSearch(filterInputValue);
    }
  }, [debouncedSearch, filterInputValue]);

  return {
    applyFilter,
    clearFilter,
    filterInputValue,
    setFilterInputValue,
    inboxFilter: preFilledFilter ?? tasksFilter ?? otherFilter,
    handleExpandClick,
    isAllExpanded,
    isAllExpandedDisabled: inboxesTreeNodeNumber === 0,
    numberItemsDisplayed,
    inboxesTotalNumber,
    inboxesAsTree,
    setInboxesAsTree,
    setInboxesAsAlphaNumericSort,
    inboxesAsAlphaNumericSort,
  };
};
