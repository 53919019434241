import React, { cloneElement } from 'react';
import { observer } from 'mobx-react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { DialogChoiceOptionsProps } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';

const DialogChoiceOptions = ({ handleClick, optionTitle, avatar, name, sx }: DialogChoiceOptionsProps) => {
  return (
    <ListItem data-cy={name} onClick={handleClick} sx={{ cursor: 'pointer' }}>
      {avatar && (
        <ListItemAvatar>
          <Avatar sx={sx}>{avatar}</Avatar>
        </ListItemAvatar>
      )}
      <ListItemText primary={optionTitle} />
    </ListItem>
  );
};

export default observer(DialogChoiceOptions);
