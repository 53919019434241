import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import { FlexRow } from '../../../../styles';
import { AddressSummaryName } from './const';
import { AddressSummaryProps } from './types';

const AddressSummary = ({
  parentId,
  addressData: { streetNumber, street, zipCode, complement, city, country },
}: AddressSummaryProps) => {
  const nameWithParentId = `${AddressSummaryName}-${parentId}`;
  return (
    <Box sx={FlexRow} data-cy={nameWithParentId}>
      {streetNumber && (
        <Typography data-cy={`${nameWithParentId}-streetNumber`} variant="caption">{`${streetNumber}`}</Typography>
      )}
      {street && (
        <Typography data-cy={`${nameWithParentId}-street`} variant="caption" marginLeft={1}>
          {street}
        </Typography>
      )}
      {complement && (
        <Typography data-cy={`${nameWithParentId}-complement`} variant="caption" marginLeft={1}>
          {complement}
        </Typography>
      )}
      {zipCode && (
        <Typography data-cy={`${nameWithParentId}-zipCode`} variant="caption" marginLeft={1}>{`${zipCode}`}</Typography>
      )}
      {city && (
        <Typography data-cy={`${nameWithParentId}-city`} variant="caption" marginLeft={1}>{`${city}`}</Typography>
      )}
      {country && (
        <Typography data-cy={`${nameWithParentId}-country`} variant="caption" marginLeft={1}>
          {country}
        </Typography>
      )}
    </Box>
  );
};
export default observer(AddressSummary);
