import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePreviousHook = (value: any, initialValue: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePreviousHook;
