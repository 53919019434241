import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import { AttachmentItemData, QCAttachment } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { DocumentTransferState, TransferMode } from '90.quickConnect.Models/enums';
import { mapQCAttachmentFromAttachmentItemData } from '90.quickConnect.Models/mappings';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class AttachmentDb extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb.ts';

  attachments!: Table<QCAttachment>;

  logger: CustomLogger = CustomLogger.getInstance();

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('attachmentDb');
    this.version(2).stores({
      attachments:
        'id,declarationId,distantUri,transferMode,[transferState+transferMode],[distantUri+transferMode+transferState]',
    });
  }

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.attachments.clear();
      logger.log(AttachmentDb.TAG, `all tables from ${this.name} have been cleared`);
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'clearAllTables');
    }
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.attachments.count();
    } catch (error: unknown) {
      errorHandler(AttachmentDb.TAG, error, 'countRecords');

      return 0;
    }
  };

  getAttachmentItemDataByDeclarationId = async (declarationId: string): Promise<QCAttachment[]> => {
    try {
      const records = this.attachments.where({ declarationId: declarationId });

      return records ? await records.toArray() : [];
    } catch (error: unknown) {
      errorHandler(AttachmentDb.TAG, error, 'getAttachmentItemDataByDeclarationId');
      return [];
    }
  };

  /**
   *
   * @returns la liste des attachment dont l'indexation n'a pas été faite
   * et dont la distantUri est vide
   * et dont le transferMode est Upload
   */
  public getAttachmentToIndex = async (): Promise<QCAttachment[]> => {
    const attachToIndex = this.attachments.where({
      distantUri: '',
      transferMode: TransferMode.UPLOAD,
      transferState: DocumentTransferState.ToBeTransfered,
    });

    return attachToIndex ? attachToIndex.toArray() : [];
  };

  /**
   * @returns la liste des attachment dont l'indexation a été faite
   * et dont la distantUri n'est pas vide
   * et dont le transferMode est Upload
   */
  public getAttachmentToSend = async (): Promise<QCAttachment[]> => {
    const attachToSend = this.attachments
      .where({
        transferState: DocumentTransferState.ToBeTransfered,
        transferMode: TransferMode.UPLOAD,
      })
      .and((attach) => {
        return (
          attach.file instanceof File && attach.distantUri != undefined && attach.distantUri != '' && attach.nbTry > 0
        );
      });

    return attachToSend ? attachToSend.toArray() : [];
  };

  public async updateAttachment(attach: QCAttachment): Promise<boolean> {
    let countRecordsUpdated = 0;
    return this.transaction('rw', this.attachments, async () => {
      countRecordsUpdated = await this.attachments.update(attach.id, attach);
      return countRecordsUpdated > 0;
    });
  }

  public async updateAttachments(attachmentsToUpdate: QCAttachment[]): Promise<boolean> {
    try {
      await this.attachments.bulkPut(attachmentsToUpdate);

      return true;
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'updateAttachments');
      return false;
    }
  }

  /**
   * supprime les attachments à purger
   */
  public removeAttachmentToPurge = async (): Promise<void> => {
    const logger = CustomLogger.getInstance();
    try {
      const today = new Date();
      const attachToDelete = this.attachments
        .where('transferState')
        .notEqual('1')
        .and((attach) => {
          return attach.expirationDate != undefined && attach.expirationDate < today;
        });
      logger.log(AttachmentDb.TAG, `Attachment to delete: ${attachToDelete != undefined ? attachToDelete.count() : 0}`);
      await attachToDelete.delete();
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'removeAttachmentToPurge');
    }
  };

  /**
   * Update de declarationId pour les attachments
   * @param attachmentsFile liste des attachments à mettre à jour
   * @param declarationId  id de la déclaration
   */
  public updateDeclIdForAttachment = async (
    attachmentsFile: AttachmentItemData[],
    declarationId: string,
    sendAPI: boolean,
  ): Promise<void> => {
    try {
      await Promise.all(
        attachmentsFile.map(async (attach) => {
          const qcAttach = await this.attachments.get(attach.id);
          if (qcAttach != undefined) {
            qcAttach.declarationId = declarationId;
            if (sendAPI) {
              qcAttach.transferState = DocumentTransferState.ToBeTransfered;
            }
            await this.attachments.put(qcAttach);
          }
        }),
      );
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'updateDclIdForAttachment');
    }
  };

  public async updateAttachmentItemDataForWorkFlow(
    attachmentsFile: AttachmentItemData[],
    declarationId: string,
    sendAPI: boolean,
    attachItemDataIds: string[],
  ): Promise<void> {
    if (attachItemDataIds.length === 0) return;

    return this.transaction('rw', this.attachments, async () => {
      const attachItemDataCurrentStep = attachmentsFile.filter(({ id }: AttachmentItemData) =>
        attachItemDataIds.includes(id),
      );

      for (const attach of attachItemDataCurrentStep) {
        const qcAttach = await this.attachments.get(attach.id);
        if (qcAttach != undefined) {
          qcAttach.declarationId = declarationId;
          if (sendAPI) {
            qcAttach.transferState = DocumentTransferState.ToBeTransfered;
          }
          await this.attachments.put(qcAttach);
        }
      }
    });
  }

  /**
   * Ajout d'un attachment avec son fichier en BDD
   * @param attachment attachment à ajouter
   * @param file  fichier à ajouter dans l'attachment
   * @param update Optionnel - Permet la suppression de l'item existant avant l'enregistrement. (Utile lors de la duplication de déclaration)
   */
  public addAttachmentItem = async (
    attachment: AttachmentItemData,
    file: File,
    declarationId: string,
    update?: boolean,
  ): Promise<void> => {
    try {
      const qcAttach = mapQCAttachmentFromAttachmentItemData(attachment, declarationId);
      qcAttach.file = file;
      if (update) {
        await this.attachments.put(qcAttach, qcAttach.id);
      } else {
        await this.attachments.add(qcAttach);
      }
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'addAttachmentItem');
    }
  };

  /**
   *
   *
   * @param {string} attachmentId
   * @memberof AttachmentDb
   */
  public getAttachmentById = async (attachmentId: string): Promise<QCAttachment | undefined> => {
    try {
      return await this.attachments.get({ id: attachmentId });
    } catch (error: unknown) {
      errorHandler(AttachmentDb.TAG, error, 'getAttachmentById');
    }
  };

  /**
   *
   *
   * @param {AttachmentItemData} attachment
   * @memberof AttachmentDb
   */
  public deleteFromId = async (attachment: AttachmentItemData): Promise<void> => {
    try {
      await this.attachments.where('id').equals(attachment.id).delete();
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'deleteFromId');
    }
  };

  deleteAttachmentItemsDataByDeclarationId = async (declarationId: string): Promise<number> => {
    try {
      return await this.attachments.where({ declarationId: declarationId }).delete();
    } catch (error: unknown) {
      errorHandler(AttachmentDb.TAG, error, 'deleteAttachmentItemsDataByDeclarationId');
    }
    return 0;
  };

  public getFileByDistantUri = async (distantUri: string): Promise<QCAttachment | undefined> => {
    try {
      const doc = await this.attachments
        .where({
          distantUri: distantUri,
        })
        .first();

      return doc;
    } catch (error) {
      errorHandler(AttachmentDb.TAG, error, 'getFileByDistantUri');
    }
  };
}
const attachmentDb = new AttachmentDb();

export default attachmentDb;
