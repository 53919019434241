import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { ConsentData } from '90.quickConnect.Models/models';
import { ConsentDataQuery } from '40.quickConnect.DataAccess/indexedDb/types';
import { RGPDConsents } from '30.quickConnect.Stores/RootStore/DeclarationStore/Payloads/requests';
import { DocumentTransferState } from '90.quickConnect.Models/enums';
import { mapConsentToRGPDConsents } from '90.quickConnect.Models/mappings';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class QcConsentFormRGPD extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/QcConsentFormRGPD.ts';

  consentForm!: Table<ConsentData>;

  logger: CustomLogger;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('consentFormDd');
    this.version(2).stores({
      consentForm: '[formId+userUPN+fieldFullId],transferState',
    });
    this.logger = CustomLogger.getInstance();
  }

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.consentForm.clear();
      logger.log(QcConsentFormRGPD.TAG, `all tables from ${this.name} have been cleared`);
    } catch (error) {
      errorHandler(QcConsentFormRGPD.TAG, error, 'clearAllTables');
    }
  }

  async getFields(formId: string, userUPN: string, fullPathIds: string[]): Promise<ConsentData[]> {
    const queries: ConsentDataQuery[] = fullPathIds.map((fieldFullId: string) => ({
      formId,
      userUPN,
      fieldFullId,
    }));

    const results = await Promise.all(
      queries.map(async (current: ConsentDataQuery): Promise<ConsentData[]> => {
        return this.consentForm
          .where({ formId: current.formId, userUPN: current.userUPN, fieldFullId: current.fieldFullId })
          .toArray();
      }),
    );

    return results.reduce((acc: ConsentData[], current: ConsentData[]) => [...acc, ...current], []);
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.consentForm.count();
    } catch (error: unknown) {
      errorHandler(QcConsentFormRGPD.TAG, error, 'countRecords');
      return 0;
    }
  };

  public async updateConsent(fields: ConsentData[]): Promise<void> {
    await this.transaction('rw', this.consentForm, async () => {
      for (const field of fields) {
        await this.consentForm.put(field, field.transferState);
      }
    });
  }

  public async getRGPDConsentsToSendAsync(): Promise<RGPDConsents[]> {
    const consentsToUpload = await this.consentForm
      .where({ transferState: DocumentTransferState.ToBeTransfered })
      .toArray();

    return consentsToUpload.map(mapConsentToRGPDConsents);
  }

  public async updateConsentToBeTransferred(): Promise<void> {
    const consentsToUpload = await this.consentForm
      .where({ transferState: DocumentTransferState.Transferring })
      .toArray();

    this.transaction('rw', this.consentForm, async () => {
      for (const consent of consentsToUpload) {
        const nextConsent = { ...consent, transferState: DocumentTransferState.TransfertOK };
        await this.consentForm.put(nextConsent, [nextConsent.formId, nextConsent.userUPN, nextConsent.fieldFullId]);
      }
    });
  }

  public async transferringConsents(): Promise<ConsentData[] | never> {
    const results: ConsentData[] = [];
    return this.transaction('rw', this.consentForm, async () => {
      const consents = await this.consentForm.where({ transferState: DocumentTransferState.ToBeTransfered }).toArray();
      for (const consent of consents) {
        const nextConsent = { ...consent, transferState: DocumentTransferState.Transferring };

        const recordedConsents = await this.consentForm.put(nextConsent, [
          consent.formId,
          consent.userUPN,
          consent.fieldFullId,
        ]);

        if (!recordedConsents)
          throw new Error(
            `consent update failed for this key : [${consent.formId}, ${consent.userUPN}, ${consent.fieldFullId}]`,
          );

        results.push(nextConsent);
      }

      return results;
    })
      .then((consentsData: ConsentData[]) => consentsData)
      .catch((error) => {
        throw error as Error;
      });
  }
}
const consentFormDd = new QcConsentFormRGPD();

export default consentFormDd;
