import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation } from 'react-router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ClearIcon from '@mui/icons-material/Clear';
import { TodoListQcFieldName } from '../const';
import { TaskItemsProps } from '../types';
import useData from './hooks';
import AttachmentsList from '50.quickConnect.Fields/FieldsTypes/Attachments/AttachmentsList';
import { FieldType } from '90.quickConnect.Models/enums';
import { FlexRow } from '50.quickConnect.Fields/styles';

const TaskItems = ({ field, updateDeclaration, item, setLocalTodoTask, listTasks, localTodoTask }: TaskItemsProps) => {
  const {
    localValue,
    handleFileDrop,
    handleFileDelete,
    comment,
    outlinedInputChanged,
    updateState,
    localValueStatusCheck,
  } = useData(field, updateDeclaration, setLocalTodoTask, listTasks, item, localTodoTask);

  const { t } = useTranslation('declaration');
  const { id } = field;
  const nameWithId = `${TodoListQcFieldName}-${id}`;
  return (
    <Box>
      <Box sx={{ ...FlexRow, mb: '1rem' }}>
        <FormControlLabel
          data-cy={nameWithId}
          control={<Checkbox data-cy={`${nameWithId}-checkBox`} />}
          label={item.taskName}
          checked={localValueStatusCheck}
          onChange={updateState}
        />
      </Box>
      <Box sx={{ ...FlexRow, mb: '1rem' }}>
        <OutlinedInput
          data-cy={`${nameWithId}-input`}
          id={`${nameWithId}-input`}
          type={'text'}
          value={comment}
          required
          sx={{ flex: 1 }}
          onChange={outlinedInputChanged}
          placeholder={t('comments').toString()}
          multiline={true}
          rows={undefined}
          inputProps={{
            readOnly: false,
            disabled: false,
          }}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title={t('formlib_array_deleted_title').toString()}>
                <IconButton data-cy={`${nameWithId}-clear`} edge="end" onClick={() => {}} disabled={false}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </Box>
      <DndProvider backend={HTML5Backend}>
        <FormControl data-cy={`${nameWithId}-control`} variant="outlined" fullWidth>
          <AttachmentsList
            fieldType={FieldType.Attachment}
            onlyImage={false}
            parentId={nameWithId}
            attachments={localValue}
            onDrop={handleFileDrop}
            onDelete={handleFileDelete}
            onPreview={() => {
              return true;
            }}
            isReadOnly={false}
            stateId={item.id}
          />
        </FormControl>
      </DndProvider>
    </Box>
  );
};
export default observer(TaskItems);
