import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import NumberExtension from '80.quickConnect.Core/formatting/NumberExtension';
import { errorHandler } from '80.quickConnect.Core/helpers';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';

/**
 * Outils de manipulation des numbers décimaux
 */
export class DoubleExtension extends NumberExtension {
  protected static TAG = '80.quickConnect.Core/formatting/DoubleExtension.ts';
  constructor(num: unknown) {
    super(num);
    this.isDecimal = true;
  }

  public static convertToLitteral(numVal: unknown): string | undefined {
    if (!(numVal instanceof DoubleExtension)) {
      errorHandler(DoubleExtension.TAG, new Error('param must be instanceof DoubleExtension.'), 'convertToLitteral');
      return;
    }

    return numericLocaleFormatLitteral(numVal, {
      maximumFractionDigits: 8,
      useGrouping: false,
      minimumFractionDigits: 1,
    });
  }

  public static decimalFormat10Min1 = (value?: number | null): number => {
    if (value === undefined || value === null) return NaN;
    return parseFloat(roundTo(value, 10));
  };

  public static customDivide = (dividing: number | bigint, divider: number | bigint): number | never => {
    if (divider === 0) throw new Error('divide by zero');

    return Number(dividing) / Number(divider);
  };

  public static customModulo = (dividing: number | bigint, divider: number | bigint): number | never => {
    if (divider === 0) throw new Error('divide by zero');

    return Number(dividing) % Number(divider);
  };
}
