import { SxProps, Theme } from '@mui/material';

export const LeftMenuWidth = 300;

export const LargeLeftMenuWidth = 400;

export const AdaptWidthToMenu = (theme: Theme, showLeftMenu: boolean): SxProps => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(showLeftMenu && {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${LargeLeftMenuWidth}px)`,
      marginLeft: `${LargeLeftMenuWidth}px`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: `calc(100% - ${LeftMenuWidth}px)`,
      marginLeft: `${LeftMenuWidth}px`,
    },
    [theme.breakpoints.down('md')]: {
      width: `0px`,
      marginLeft: `0px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
});

export const MainBoxStyle = (theme: Theme, showLeftMenu: boolean): SxProps => ({
  flexGrow: 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...AdaptWidthToMenu(theme, showLeftMenu),
});
