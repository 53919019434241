import { DeclarationViewer, FormEditedData } from '90.quickConnect.Models/models';

const mapFormEditedDataFormToDeclarationViewer = (
  formEditedDataForm: FormEditedData,
  folderPath: string,
  formId: string,
): DeclarationViewer =>
  ({
    id: formEditedDataForm.id ?? '',
    name: formEditedDataForm.name ?? '',
    editedAt: formEditedDataForm.editedAt ? new Date(formEditedDataForm.editedAt) : new Date(),
    formType: formEditedDataForm.formType ?? '',
    folderPath: folderPath ?? '',
    formId: formId ?? '',
    stringExtract: formEditedDataForm.stringExtractDisplayed ?? formEditedDataForm.stringExtract ?? '',
  } as DeclarationViewer);

export default mapFormEditedDataFormToDeclarationViewer;
