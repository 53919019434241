import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import { CustomTreeViewItemProps } from './types';
import { NoPaddingTreeItem } from './styles';
import { CustomTreeViewItemName } from './const';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const CustomTreeViewItem = ({ color, labelIcon, labelInfo, labelText, children, nodeId }: CustomTreeViewItemProps) => {
  const isMobile = useIsMobile();
  return (
    <NoPaddingTreeItem
      data-cy={`${CustomTreeViewItemName}-${nodeId}`}
      nodeId={nodeId}
      color={color}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1.5, pr: 0 }} color={color}>
          {labelIcon}
          <Typography variant={isMobile ? 'body1' : 'body2'} sx={{ fontWeight: '1em', mr: 1 }}>
            {labelText}
          </Typography>
          <Typography variant={isMobile ? 'body1' : 'body2'} color={color} sx={{ mr: 1 }}>
            {labelInfo && `(${labelInfo})`}
          </Typography>
        </Box>
      }
    >
      {children}
    </NoPaddingTreeItem>
  );
};
export default observer(CustomTreeViewItem);
