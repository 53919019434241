import { useCallback, useState } from 'react';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { FEATURE_010 } from '30.quickConnect.Stores/RootStore/LoginStore/const';

const useData = (): UseDataProps => {
  const {
    LoginStore: {
      checkForFeature,
      currentOuCode,
      setCurrentOuCode,
      userOrganizationnalUnits,
      reduceOrganizationnalUnits,
      findOrganizationalUnitByCode,
      getParentCodes,
      getUserOrganizationnalUnits,
    },
  } = useStore();

  const [ouMenuAnchorEl, setOuMenuAnchorEl] = useState<null | HTMLElement>(null);
  const ouMenuOpen = Boolean(ouMenuAnchorEl);
  const isMultiOUenabled = checkForFeature(FEATURE_010);

  const handleOuMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setOuMenuAnchorEl(event.currentTarget);
      getUserOrganizationnalUnits();
    },
    [getUserOrganizationnalUnits],
  );
  const handleOuMenuClose = useCallback(() => {
    setOuMenuAnchorEl(null);
  }, []);
  const handleOuMenuMoveTo = (ouCode: string) => {
    setCurrentOuCode(ouCode);
    setOuMenuAnchorEl(null);
    // Optionnel: déclencher une mise à jour du composant parent
    window.dispatchEvent(new Event('storage'));
  };

  return {
    ouMenuOpen,
    ouMenuAnchorEl,
    isMultiOUenabled,
    currentOuCode,
    userOrganizationnalUnits,
    handleOuMenuClick,
    handleOuMenuClose,
    handleOuMenuMoveTo,
    reduceOrganizationnalUnits,
    getParentCodes,
    findOrganizationalUnitByCode,
  };
};

export default useData;
