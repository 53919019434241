import { QCSBool } from './QCSBool';
import { QCSBaseObject } from './QCSBaseObject';
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSDouble } from './QCSDouble';
import { QCSLong } from './QCSLong';
import { QCSString } from './QCSString';
import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';

// Limitations de la plage basé sur le langage c#
const MIN_VALUE_INT = -2147483648;
const MAX_VALUE_INT = 2147483647;

// Permet de vérifier si le nombre donné
type Int32 = number;

const isInt = (num: number): num is Int32 => num >= MIN_VALUE_INT && num <= MAX_VALUE_INT;

export class QCSInt extends QCSBaseTypedObject<Int32> {
  public constructor(intValue: number) {
    super(+intValue);
    if (!isInt(intValue)) this.value = 0;
    else this.value = +intValue;
  }

  public isFalse(): boolean {
    return this.value === 0;
  }

  public multiplyBy(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(this.value * arg1.value);
    if (arg1 instanceof QCSInt) return new QCSInt(this.value * arg1.value);
    if (arg1 instanceof QCSLong) return new QCSLong(BigInt(this.value) * arg1.value);
    return QCSDouble.QCSNull;
  }

  public add(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(this.value + arg1.value);
    if (arg1 instanceof QCSInt) return new QCSInt(this.value + arg1.value);
    if (arg1 instanceof QCSLong) return new QCSLong(BigInt(this.value) + arg1.value);
    return QCSDouble.QCSNull;
  }

  public sub(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(this.value - arg1.value);
    if (arg1 instanceof QCSInt) return new QCSInt(this.value - arg1.value);
    if (arg1 instanceof QCSLong) return new QCSLong(BigInt(this.value) - arg1.value);
    return QCSDouble.QCSNull;
  }

  public div(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(DoubleExtension.customDivide(this.value, arg1.value));
    if (arg1 instanceof QCSInt) return new QCSInt(DoubleExtension.customDivide(this.value, arg1.value));
    if (arg1 instanceof QCSLong) return new QCSLong(BigInt(DoubleExtension.customDivide(this.value, arg1.value)));

    return QCSDouble.QCSNull;
  }

  public modulo(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(DoubleExtension.customModulo(this.value, arg1.value));
    if (arg1 instanceof QCSInt) return new QCSInt(DoubleExtension.customModulo(this.value, arg1.value));
    if (arg1 instanceof QCSLong) return new QCSLong(BigInt(DoubleExtension.customModulo(this.value, arg1.value)));
    return QCSDouble.QCSNull;
  }

  public negation(): QCSBaseObject {
    return new QCSInt(-this.value);
  }

  public isEquals(arg1: QCSBaseObject): boolean {
    if (this === QCSBaseObject.QCSNull || this.value === null)
      return arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null;

    if (arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null) return false;

    switch (true) {
      case arg1 instanceof QCSInt:
      case arg1 instanceof QCSDouble:
        return this.value === arg1.getValue();

      case arg1 instanceof QCSLong:
        return this.value === Number(arg1.getValue());

      default:
        return false;
    }
  }

  public greaterThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value > arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value > arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value > arg1.value);
    return new QCSBool(false);
  }

  public lessThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value < arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value < arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value < arg1.value);
    return new QCSBool(false);
  }

  public greaterEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value >= arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value >= arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value >= arg1.value);
    return new QCSBool(false);
  }

  public lessEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value <= arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value <= arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value <= arg1.value);
    return new QCSBool(false);
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    return null;
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    if (methodId === 1) {
      return new QCSString(`${this.value}`);
    }
    return null;
  }

  static from(qcsBaseObject: QCSBaseObject): QCSBaseObject {
    if (qcsBaseObject == null || qcsBaseObject == QCSBaseObject.QCSNull) return new QCSInt(0);
    return new QCSInt(Math.trunc(Number(qcsBaseObject.getValue())));
  }
}
