import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { AudioQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';

const AudioQcField = ({ field, setSelectedIndex }: ValueFieldsTypesProps) => {
  const { t } = useTranslation('declaration');
  const { id } = field;
  const nameWithId = `${AudioQcFieldName}-${id}`;
  return (
    <Button
      data-cy={nameWithId}
      variant="contained"
      disabled
      startIcon={<ErrorIcon />}
      onClick={() => setSelectedIndex(undefined)}
    >
      AudioQcField
    </Button>
  );
};
export default observer(AudioQcField);
