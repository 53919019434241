import { AxiosError } from 'axios';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { QCErrorHTTPCodeEnum } from '90.quickConnect.Models/enums';
import { mapAxiosQCErrorHTTP } from '90.quickConnect.Models/mappings';
import { QCErrorHTTP } from '90.quickConnect.Models/models';
import { BaseResponse } from '40.quickConnect.DataAccess/axios/baseResponse';

const writeLog = (tag: string, errMsg: string, logMethod: string, method?: string) => {
  const message = method ? `${method} method failed: ${errMsg}` : `Méthode incconue: ${errMsg}`;
  const logger = CustomLogger.getInstance();

  switch (logMethod) {
    case 'error':
      logger.error(tag, message);
      break;

    case 'debug':
      logger.debug(tag, message);
      break;

    case 'trace':
      logger.trace(tag, message);
      break;

    case 'info':
      logger.info(tag, message);
      break;

    case 'warn':
      logger.warn(tag, message);
      break;

    case 'log':
      logger.log(tag, message);
  }
};

const dispatchErrorResponse = (tag: string, error: AxiosError<any>, levelLog?: string, method?: string) => {
  const qcErrorHttp = mapAxiosQCErrorHTTP.mapFrom(error);

  const url = qcErrorHttp.getUrl();
  const isTimeOut = qcErrorHttp.isTimeout();

  switch (qcErrorHttp.getCode()) {
    case QCErrorHTTPCodeEnum.ERR_NETWORK: {
      const msg = `Pas de connexion réseau ${url ? "pour l'url: " + url : ''} description: ${qcErrorHttp.getMessage()}`;
      writeLog(tag, msg, levelLog ?? 'trace', method);
      break;
    }

    case QCErrorHTTPCodeEnum.ETIMEDOUT: {
      const msg = `Timeout ${url ? "pour l'url: " + url : ''} description: ${qcErrorHttp.getMessage()}`;
      writeLog(tag, msg, 'trace', method);
      break;
    }

    case QCErrorHTTPCodeEnum.ECONNABORTED: {
      if (isTimeOut) {
        const msg = `Timeout ${url ? "pour l'url: " + url : ''} description: ${qcErrorHttp.getMessage()}`;
        writeLog(tag, msg, 'trace', method);
      } else {
        const msg = `Erreur ${url ? "pour l'url: " + url : ''} description: ${qcErrorHttp.getMessage()}`;
        writeLog(tag, msg, levelLog ?? 'error', method);
      }
      break;
    }

    default:
      const msg = `Erreur inconnue ${url ? "pour l'url: " + url : ''} description: ${qcErrorHttp.getMessage()}`;
      writeLog(tag, msg, levelLog ?? 'trace', method);
      break;
  }
};

export const errorHandler = (tag: string, error: unknown, method?: string, level?: string) => {
  const levelLog = level ?? 'trace';
  if (QCErrorHTTP.isHttpError<AxiosError<BaseResponse>>(error)) {
    dispatchErrorResponse(tag, error, level, method);
  } else if (error instanceof Error) {
    const message = method ? `${method} method failed: ${error.message}` : `Méthode incconue: ${error.message}`;
    writeLog(tag, message, levelLog, method);
  }
};
