import Resizer from 'react-image-file-resizer';

const MIN_SIZE_IN_KO_TO_RESIZE = 20480;

const resizeBase64 = (
  file: File,
  maxWidth = 128,
  maxHeight = 128,
  quality = 100,
  rotation = 0,
): Promise<string | File | Blob | ProgressEvent<FileReader>> | string => {
  if (file.type.startsWith('image')) {
    // Vérifions si l'image a besoin d'être resize

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        'JPEG',
        quality,
        rotation,
        (uri) => {
          return resolve(uri);
        },
        'base64',
      );
    });
  } else {
    return '';
  }
};

export default resizeBase64;
