import { SxProps } from '@mui/system';
import { slateGrey700 } from '00.app/theme';

export const MainBoxStyle = (isMobile: boolean): SxProps =>
  ({
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100%-10px)',
    height: isMobile ? '125px' : 'auto',
    maxHeight: isMobile ? '125px' : 'auto',
    alignItems: 'center',
    marginRight: '10px',
    marginLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    gap: isMobile ? '0em' : '2em',
    flexWrap: 'wrap',
  } as SxProps);

export const DividerStyle = {
  borderLeft: `2px solid ${slateGrey700}`,
  padding: '10px',
  height: 50,
} as SxProps;
