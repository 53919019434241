import { validate } from 'uuid';
import mapHierarchicalChoices from '../sharedList/mapHierarchicalChoices';
import { Choice, HierarchicalDesc } from '90.quickConnect.Models/models';

const mapHierarchicalDesc = (field: HierarchicalDesc): HierarchicalDesc => {
  let listChoice;
  let listId;
  if (field.listDef) {
    if (validate(field.listDef)) listId = field.listDef;
    else {
      const choices = JSON.parse(field.listDef);
      if (Array.isArray(choices)) listChoice = mapHierarchicalChoices(choices as Choice[]);
    }
  }

  return {
    listDef: field.listDef,
    listChoice: listChoice,
    listId: listId,
    isPopup: field.isPopup,
    defaultValue: field.defaultValue ?? undefined,
    multiSelection: field.multiSelection ?? false,
    isSearchable: field.isSearchable ?? false,
    value: field.value ?? [],
  } as HierarchicalDesc;
};

export default mapHierarchicalDesc;
