import { isMandatoryItems } from './isMandatoryItems';
import { BaseContainerDesc } from '90.quickConnect.Models/models';
import { Mandatory } from '90.quickConnect.Models/enums';

const mapBaseContainerDesc = ({
  value,
  summary,
  defaultValue,
  reference,
  canDuplicate,
  id,
  items,
  mandatory,
}: BaseContainerDesc): BaseContainerDesc => {
  let defValue;
  if (defaultValue) defValue = JSON.parse(defaultValue);

  const mandatoryItems = items && items.some((i) => isMandatoryItems(i));

  return {
    value: value ?? defValue ?? false,
    id: id,
    summary: summary,
    defaultValue: defaultValue,
    canDuplicate: canDuplicate ?? true,
    reference: reference,
    mandatory: mandatoryItems ? Mandatory.Required : mandatory,
  } as BaseContainerDesc;
};

export default mapBaseContainerDesc;
