import { EditDesc, GeolocationData } from '90.quickConnect.Models/models';
import { GeolocationDesc } from '90.quickConnect.Models/models/fields/fieldDesc/editableFields/geolocationDesc';

const mapGeolocationDesc = ({ value, showMap, showSearchBar }: GeolocationDesc): EditDesc => {
  return {
    showMap: showMap ?? '',
    showSearchBar: showSearchBar ?? false,
    value: value
      ? {
          latitude: (value as GeolocationData).latitude ?? 0,
          longitude: (value as GeolocationData).longitude ?? 0,
          precision: 0,
        }
      : undefined,
  } as unknown as EditDesc;
};

export default mapGeolocationDesc;
