import { JsonWrapper } from '80.quickConnect.Core/helpers';
import { isNotificationDataPartial, isQCNotification } from '80.quickConnect.Core/helpers/common';
import { NotificationType } from '90.quickConnect.Models/enums';
import { isChoice, isChoiceList, isNotificationTarget, isRecord } from '90.quickConnect.Models/guards';
import { Choice, NotificationTarget, QCNotification } from '90.quickConnect.Models/models';

const EMAIL_REGEX = /^[a-zA-Z0-9+._%+-]{1,256}@[a-zA-Z0-9.-]{0,64}(\.[a-zA-Z0-9-]{0,25})+$/;

const createContactFromArray = (emails: string): NotificationTarget[] =>
  emails
    .replace(',', ';')
    .split(';')
    .reduce(
      (acc: NotificationTarget[], current: string) =>
        EMAIL_REGEX.test(current.trim())
          ? [...acc, { alias: null, target: current.trim() } as NotificationTarget]
          : acc,
      [],
    );

export const mapToNotificationDataFromString = (serialized: string, autoSend = true): QCNotification | undefined => {
  if (serialized && serialized !== '') {
    const serializedTrim = serialized.trim();

    // Elément unique { "autoSend": false, "notificationType": 1, "selectedTargets": [ { "title": "Eric Ballot", "value": "eric.ballot.c2s@gmail.com" }], "sendToMe": false, "subject": null, "searchAllUO":false }
    // ou sendToMe ¤ xxxx@xxxx.xxx; yyyy@yyyy.yyy; zzzz@zzzz.zzz ¤ searchAllUO
    // ou simplement une chaine "xxxx@xxxx.xxx; yyyy@yyyy.yyy; zzzz@zzzz.zzz"
    if (serializedTrim.startsWith('{')) {
      const notificationData = JSON.parse(serializedTrim);

      if (!isNotificationDataPartial(notificationData))
        throw new Error("L'objet donné n'est pas de type NotificationData");

      return {
        autoSend: notificationData.autoSend ?? autoSend ?? true,
        notificationType: notificationData.notificationType ?? NotificationType.Email,
        searchAllUO: notificationData.searchAllUO,
        selectedTargets: notificationData.selectedTargets ?? [],
        sendToMe: notificationData.sendToMe ?? false,
        subject: notificationData.subject ?? null,
      };
    } else {
      if (serializedTrim.includes('¤')) {
        const notification = serializedTrim.split('¤');

        if (notification.length === 3) {
          const sendToMe = Boolean(notification[0].trim());
          const selectedTargets = createContactFromArray(notification[1]);
          const searchAllUO = Boolean(notification[2].trim());

          return {
            notificationType: NotificationType.Email,
            subject: null,
            autoSend: autoSend ?? true,
            sendToMe,
            selectedTargets,
            searchAllUO,
          } as QCNotification;
        } else {
          const sendToMe = Boolean(notification[0]);
          const selectedTargets = createContactFromArray(notification[1]);

          return {
            notificationType: NotificationType.Email,
            subject: null,
            autoSend: autoSend ?? true,
            searchAllUO: false,
            sendToMe,
            selectedTargets,
          } as QCNotification;
        }
      } else {
        const selectedTargets = createContactFromArray(serializedTrim.trim());

        if (selectedTargets.length > 0)
          return {
            notificationType: NotificationType.Email,
            subject: null,
            autoSend: autoSend ?? true,
            searchAllUO: false,
            sendToMe: false,
            selectedTargets,
          } as QCNotification;
      }
    }
  }

  return undefined;
};

export function mapToNotificationTargetFromString(nt: string): NotificationTarget | undefined {
  if (nt.startsWith('{')) return JsonWrapper.parse(isNotificationTarget)(nt);
  else {
    const regExpMail = new RegExp('^w+([.-_]?w+)*@w+([.-_]?w+)*(.w{2,3})+$', 'gi');

    if (regExpMail.test(nt))
      return {
        alias: null,
        target: nt,
      };
  }
}

export function mapToNotificationTarget(obj: unknown[]): NotificationTarget[] {
  return obj.reduce((acc: NotificationTarget[], current: unknown) => {
    if (typeof current === 'string') {
      const nt = mapToNotificationTargetFromString(current.trim());

      return nt ? [...acc, nt] : acc;
    } else if (isNotificationTarget(current)) return [...acc, current];
    else return acc;
  }, []);
}

export function mapToNotificationDataFromHashMap(obj: Record<string, unknown>): QCNotification {
  const selectedTargets =
    'selectedTargets' in obj && Array.isArray(obj.selectedTargets) ? mapToNotificationTarget(obj.selectedTargets) : [];

  return {
    autoSend: 'autoSend' in obj && typeof obj.autoSend === 'boolean' ? obj.autoSend : true,
    notificationType:
      'notificationType' in obj && obj.notificationType === 1 ? obj.notificationType : NotificationType.Email,
    searchAllUO: 'searchAllUO' in obj && typeof obj.searchAllUO === 'boolean' ? obj.searchAllUO : false,
    sendToMe: 'sendToMe' in obj && typeof obj.sendToMe === 'boolean' ? obj.sendToMe : false,
    subject: 'subject' in obj && typeof obj.subject === 'string' ? obj.subject : null,
    selectedTargets,
  };
}

export function mapAllFieldTypeValuesToNotificationData(value: unknown): QCNotification | undefined {
  if (isQCNotification(value)) return value;
  else if (typeof value === 'string') return mapToNotificationDataFromString(value);
  else if (isChoice(value)) return mapToNotificationDataFromString(value.value);
  else if (isChoiceList(value))
    return mapToNotificationDataFromString(value.map(({ value: choiceVal }: Choice) => choiceVal).join(';'));
  else if (isRecord(value)) return mapToNotificationDataFromHashMap(value);
  else return;
}
