/**
 * Enumération des opérateurs disponible dans les champ 'FORMULA'
 */
export enum QcOperator {
  Unknow = -1,
  /** opérateur pour les champ calculé */
  Add = 0,
  Subst = 1,
  Mult = 2,
  Div = 3,
  ConstDouble = 4,
  CtrlRef = 5,
  /** opérateur pour le champ visibilité */
  ConstString = 6,
  Sum = 7,
  Avg = 8,
  Equal = 10,
  Less = 11,
  Greater = 12,
  LessOrEqual = 13,
  GreaterOrEqual = 14,
  Different = 15,
  Contains = 16,
  NotContains = 17,
  StartsWith = 18,
  EndsWith = 19,
  IsEmpty = 20,
  IsNotEmpty = 21,
  IsInMyInformation = 22,
  IIf = 23,

  /** Operateur concernant les choicesList */
  ContainsText = 24,
  NotContainsText = 25,

  /** opérateur pour les règles de transition d'étape */
  Parenthesis = 40,
  And = 41,
  Or = 42,
  CtrlUserParameterRef = 43,
  Multi = 44,
  // On regarde le UserUPN ou l'Email en référence à un champ
  CtrlRefReceiver = 45,

  /* Note : CES OPERATEURs N'EXISTENT PAS DANS LE RESTE DU SYSTEME !!!
            Il est présent dans lecode du front uniquement pour gérer
            des spéciaux lors de la création des expressions de calcul
  */
  Container = 9999,
}
