import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const getTextEllipsisStyle = (lines: number): SxProps<Theme> => {
  return {
    // Attribute used to display ... after the text if the text is too long
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    '-webkit-line-clamp': `${lines}`, // nubers of lines before ellipsis
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
  };
};
