import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { choiceDialogName } from './const';

import {
  DECLARATION,
  DECLARATIONS,
  NEW_DECLARATION,
  NEW_DECLARATION_WITH_EQUIPMENT,
} from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';
import { useNavigatorOnLine } from '80.quickConnect.Core/hooks';
import { DeclarationViewer, FormViewer } from '90.quickConnect.Models/models';
import useDialogQCChoice from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/useDialogQCChoice';
import {
  DialogChoiceActionType,
  DialogChoiceState,
} from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/type';
import { DialogChoiceEnum } from '10.quickConnect.app/components/shared/Dialogs/DialogChoice/enums';

const useData = (
  { id, entityInstanceId }: FormViewer,
  declarations: DeclarationViewer[],
  onCloseDialog: () => void,
  open: boolean,
) => {
  const { t } = useTranslation('forms');
  const theme = useTheme();

  const navigate = useNavigate();

  const {
    DeclarationStore: { removeLocalDeclaration },
  } = useStore();

  const formDraftOccurence = useMemo(() => {
    return declarations.filter((declaration) => declaration.formId === id).length;
  }, [declarations, id]);

  const onCreateDeclaration = useCallback(() => {
    let link = NEW_DECLARATION.replace(':formId', id);

    if (entityInstanceId) {
      link = NEW_DECLARATION_WITH_EQUIPMENT.replace(':formId', id).replace(':entityInstanceId', entityInstanceId);
    }

    navigate(link);
  }, [entityInstanceId, id, navigate]);

  const onContinueDeclaration = useCallback(() => {
    let link = '';
    if (declarations.length === 1) {
      const [{ id: declarationId }] = declarations;

      link = DECLARATION.replace(':formId', id).replace(':declarationId', declarationId);
    } else {
      link = DECLARATIONS;
    }
    onCloseDialog();

    navigate(link);
  }, [navigate, onCloseDialog, id, declarations]);

  const onDeleteDeclaration = useCallback(async () => {
    if (declarations.length === 1) {
      const [{ id: declarationId }] = declarations;

      await removeLocalDeclaration(declarationId, true);
      onCloseDialog();
      toast.success(t('qcapp_form_choice_dialog_success_one').toString());
    } else {
      navigate(DECLARATIONS);
    }
  }, [declarations, navigate, onCloseDialog, removeLocalDeclaration, t]);

  // On utilise désormais un custom hooks pour la création de la dialogue de choix
  const initialState: DialogChoiceState = useMemo(
    () => ({
      open,
      dialogTitle: t('qcapp_alert_dialog_save_form_title'),
      dialogInfo: t('qcapp_alert_form_type_info'),
      name: choiceDialogName,
      options: [
        {
          action: { type: DialogChoiceEnum.AddNewDcl },
          name: `${choiceDialogName}-dialog-option-create-dcl`,
          optionTitle: t('qcapp_new_form_type_info'),
          avatar: AddIcon,
          sx: { backgroundColor: theme.palette.primary.main },
        },
        {
          action: { type: DialogChoiceEnum.ContinueDcl },
          name: `${choiceDialogName}-dialog-option-continue-dcl`,
          optionTitle: t('qcapp_get_form_type_info'),
          avatar: EditIcon,
          sx: { backgroundColor: theme.palette.primary.main },
        },
        {
          action: { type: DialogChoiceEnum.RemoveDcl },
          name: `${choiceDialogName}-dialog-option-delete-dcl`,
          optionTitle: t('qcapp_delete_form_type_info'),
          avatar: DeleteIcon,
          sx: { backgroundColor: theme.palette.primary.main },
        },
      ],
    }),
    [open, t, theme.palette.primary.main],
  );

  const reducer = useCallback(
    (state: DialogChoiceState, action: DialogChoiceActionType) => {
      const { type } = action;

      switch (type) {
        case DialogChoiceEnum.AddNewDcl: {
          onCreateDeclaration();
          onCloseDialog();

          const newState = { ...state, open: false };
          return newState || state;
        }

        case DialogChoiceEnum.RemoveDcl: {
          onDeleteDeclaration();
          onCloseDialog();

          const newState = { ...state, open: false };
          return newState || state;
        }

        case DialogChoiceEnum.ContinueDcl: {
          onContinueDeclaration();
          onCloseDialog();

          const newState = { ...state, open: false };
          return newState || state;
        }

        case DialogChoiceEnum.Close: {
          onCloseDialog();

          const newState = { ...state, open: false };
          return newState || state;
        }

        default:
          return { ...state };
      }
    },
    [onDeleteDeclaration, onContinueDeclaration, onCreateDeclaration, onCloseDialog],
  );

  const { DialogQCChoice } = useDialogQCChoice(reducer, initialState, open, onCloseDialog);

  return {
    onCreateDeclaration,
    onContinueDeclaration,
    onDeleteDeclaration,
    formDraftOccurence,
    DialogQCChoice,
  };
};

export default useData;
