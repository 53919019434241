/**
 * Les types de Propriété associée au user pour le libellé calculé : SystemRef(Propriété associée au user)
 */
export enum UserProperty {
  DCL_USER_UPN = 'DCL_USER_UPN',
  DCL_USER_LASTNAME = 'DCL_USER_LASTNAME',
  DCL_USER_EMAIL = 'DCL_USER_EMAIL',
  DCL_USER_CONTACT = 'DCL_USER_CONTACT',
  DCL_USER_FULLNAME = 'DCL_USER_FULLNAME',
  DCL_USER_PHONE = 'DCL_USER_PHONE',
  DCL_USER_JOB = 'DCL_USER_JOB',
  DCL_USER_FIRSTNAME = 'DCL_USER_FIRSTNAME',
}
