import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interfaces/clearDataBase';
import { LogConfiguration, TelemetryLogDB } from '80.quickConnect.Core/logger/type';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';

export class LogDb extends Dexie implements ClearDataBase {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/logDb.ts';

  logs!: Table<LogConfiguration>;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('logDb');
    this.version(1).stores({
      logs: 'id',
    });
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.logs.count();
    } catch (error: unknown) {
      errorHandler(LogDb.TAG, error, 'countRecords');
      return 0;
    }
  };

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.logs.clear();
      logger.log(LogDb.TAG, `all tables from ${this.name} have been cleared`);
    } catch (error) {
      errorHandler(LogDb.TAG, error, 'clearAllTables');
    }
  }

  /**
   *
   * @param logger Référence à la classe CustomLogger
   * @returns Promise<TelemetryLogDB | undefined>
   */
  getLogs = async (logger: CustomLogger): Promise<TelemetryLogDB | undefined> => {
    try {
      const logs = await this.logs.toArray();

      const items = logs.map((log) => log.item);
      const crashs: string[] = logs.reduce((acc: string[], current) => {
        return current.CrashDump && current.CrashDump !== null ? [...acc, current.CrashDump as string] : acc;
      }, []);

      return {
        Items: items,
        CrashDump: crashs.length === 0 ? null : crashs,
      } as TelemetryLogDB;
    } catch (error) {
      if (error instanceof Error)
        error.message = `La récupération des logs dans la table des logs à échoué: ${error.message}`;
      errorHandler(LogDb.TAG, error, 'getLogs');
    }
  };
}

const logDb = new LogDb();

export default logDb;
