import { DeclarationViewer, FormEditedData } from '90.quickConnect.Models/models';

const mapDeclarationViewer = (
  declarationViewer: DeclarationViewer | FormEditedData,
  isLocal: boolean,
): DeclarationViewer =>
  ({
    id: declarationViewer.id ?? '',
    name: declarationViewer.name ?? '',
    editedAt: declarationViewer.editedAt ? new Date(declarationViewer.editedAt) : new Date(),
    formType: declarationViewer.formType ?? '',
    folderPath: declarationViewer.folderPath ?? '',
    formId: declarationViewer.formId ?? '',
    stringExtract: declarationViewer.stringExtract ?? '',
    isDraft: declarationViewer.isDraft ?? false,
    isLocal: isLocal,
  } as DeclarationViewer);

export default mapDeclarationViewer;
