import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes, FieldData } from '90.quickConnect.Models/models';
import { isFieldDataArray } from '90.quickConnect.Models/guards';

export const mapFromFieldData = (fieldData: FieldData, parentId?: string): FieldData => {
  const fullPathId: string = parentId ? `${parentId}.${fieldData.id}` : fieldData.id;
  if (isFieldDataArray(fieldData, fieldData.value))
    fieldData.value = fieldData.value.map((f: FieldData) => mapFromFieldData(f, fullPathId));

  return {
    ...fieldData,
    id: fullPathId,
  };
};
