import { v4 as uuidv4 } from 'uuid';
import { FieldDesc } from '90.quickConnect.Models/models';

const updateErrorsFieldOrChild = (fullPathId: string, field: FieldDesc, errors: string[]): [FieldDesc, boolean] => {
  let changeMade = false;
  if (field.fullPathId == fullPathId && field.errors !== errors) {
    field.errors = errors;
    // field.stateId = uuidv4();
    changeMade = true;
  }
  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updateErrorsFieldOrChild(fullPathId, child, errors);
      if (childChanged) {
        changeMade = childChanged;
        // field.errors = errors?.filter((errorMsg: string) => !field.errors?.includes(errorMsg));
      }
      child = updatedChild;
    });
  }
  return [field, changeMade];
};

export default updateErrorsFieldOrChild;
