import React from 'react';
import { observer } from 'mobx-react';
import { List } from '@mui/material';
import HierarchicalItem from '../HierarchicalItem';
import { SmallHierarchicalListProps } from './types';
import { SmallHierarchicalListName } from './const';

const SmallHierarchicalList = ({
  filteredChoices,
  onClickHandle,
  localValue,
  parentId,
  multiSelection,
}: SmallHierarchicalListProps) => {
  const nameWithId = `${SmallHierarchicalListName}-${parentId}`;
  return (
    <List sx={{ width: '100%', marginTop: '10px' }} data-cy={nameWithId}>
      {filteredChoices.map((hierarchicalChoice) => (
        <HierarchicalItem
          key={`${nameWithId}-${hierarchicalChoice.hierarchicalNodePath}`}
          onClickHandle={onClickHandle}
          hierarchicalChoice={hierarchicalChoice}
          localValue={localValue}
          parentId={parentId}
          multiSelection={multiSelection}
        />
      ))}
    </List>
  );
};
export default observer(SmallHierarchicalList);
