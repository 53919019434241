import { QCSBaseObject } from './QCSBaseObject';
import { QCSString } from './QCSString';
import { QCSMatcher } from './QCSMatcher';

export class QCSPattern extends QCSBaseObject {
  private _pattern = '';

  private _regEx = new RegExp('');

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 1: // compile
        return this.compile(qcParams);

      default:
        return null;
    }
  }

  private static compile(qcParams: Array<QCSBaseObject>): QCSBaseObject {
    const pattern: string = (qcParams[0] as QCSString).value;
    const result: QCSPattern = new QCSPattern();
    result._pattern = pattern;
    result._regEx = new RegExp(pattern, 'gi');

    return result;
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    switch (methodId) {
      case 2: // matcher
        return this.matcher(qcParams);

      default:
        return null;
    }
  }

  private matcher(qcParams: Array<QCSBaseObject>): QCSBaseObject {
    const toLookIn: string = (qcParams[0] as QCSString).value;
    if (this._regEx === null) {
      this._regEx = new RegExp(this._pattern, 'gi');
    }

    const result: QCSMatcher = new QCSMatcher(this._regEx, toLookIn);
    return result;
  }
}
