import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Button, List, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { choiceDialogName } from './const';
import useData from './hooks';
import { ChoiceDialogProps } from './types';
import DialogChoice from '10.quickConnect.app/components/shared/Dialogs/DialogChoice';

const DeclarationAlreadySavedDialog = ({ declarations, form, open, onClose }: ChoiceDialogProps) => {
  const { id: formId } = form;

  const { t } = useTranslation('forms');
  const theme = useTheme();

  // Get all the declarations related to the selected form in order to display the correct options
  const relatedDeclarations = useMemo(
    () => declarations.filter((declaration) => declaration.formId === formId),
    [declarations, formId],
  );

  const { onCreateDeclaration, onContinueDeclaration, onDeleteDeclaration, formDraftOccurence } = useData(
    form,
    relatedDeclarations,
    onClose,
    open,
  );

  return (
    <DialogChoice name={choiceDialogName} onClose={onClose} open={open}>
      <DialogChoice.Title name={choiceDialogName}>{t('qcapp_alert_dialog_save_form_title')}</DialogChoice.Title>
      <DialogChoice.Content name={choiceDialogName}>
        <DialogChoice.ContentText name={choiceDialogName}>{t('qcapp_alert_form_type_info')}</DialogChoice.ContentText>
        <List>
          <DialogChoice.Option
            handleClick={onCreateDeclaration}
            name={`${choiceDialogName}-dialog-option-create-dcl`}
            optionTitle={t('qcapp_new_form_type_info')}
            avatar={<AddIcon />}
            sx={{ backgroundColor: theme.palette.primary.main }}
          />
          <DialogChoice.Option
            handleClick={onContinueDeclaration}
            name={`${choiceDialogName}-dialog-option-continue-dcl`}
            optionTitle={t('qcapp_get_form_type_info')}
            avatar={<EditIcon />}
            sx={{ backgroundColor: theme.palette.primary.main }}
          />
          {formDraftOccurence < 2 && (
            <DialogChoice.Option
              handleClick={onDeleteDeclaration}
              name={`${choiceDialogName}-dialog-option-delete-dcl`}
              optionTitle={t('qcapp_delete_form_type_info')}
              avatar={<DeleteIcon />}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
          )}
        </List>
      </DialogChoice.Content>
      <DialogChoice.Actions name={choiceDialogName}>
        <Button data-cy={`${choiceDialogName}-close`} onClick={onClose} variant="contained">
          {t('qcapp_dialog_item_data_close')}{' '}
        </Button>
      </DialogChoice.Actions>
    </DialogChoice>
  );
};

export default observer(DeclarationAlreadySavedDialog);
