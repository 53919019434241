import React from 'react';
import { observer } from 'mobx-react';
import { Box, List } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { useData } from './hooks';
import { InboxTreeItemRendererName } from './const';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import InboxLine from '10.quickConnect.app/components/domain/Home/Inboxes/InboxLine';
import CustomTreeViewItem from '10.quickConnect.app/components/shared/CustomTreeViewItem';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';

const InboxTreeItemRenderer = ({ id, name, items, cle }: TreeViewData<TreeDataItem>) => {
  const { nodeChildren, inboxChildren, numberPrefilledFormsChildren } = useData(items);
  return (
    <Box data-cy={InboxTreeItemRendererName} sx={{ m: 1, mb: 1.3 }}>
      <CustomTreeViewItem
        key={id}
        nodeId={id}
        labelText={cle && name === '' ? cle : name}
        labelIcon={<FolderIcon sx={{ mr: 1 }} />}
        labelInfo={numberPrefilledFormsChildren > 0 ? numberPrefilledFormsChildren.toString() : undefined}
      >
        {nodeChildren.map((child) => (
          <InboxTreeItemRenderer key={child.id} {...child} />
        ))}
        <List sx={{ overflowY: 'auto' }}>
          {inboxChildren.slice().map(({ id: childId, data }) => (
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            <InboxLine key={childId} {...data!} />
          ))}
        </List>
      </CustomTreeViewItem>
    </Box>
  );
};
export default observer(InboxTreeItemRenderer);
