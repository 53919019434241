export class QCSBaseObject {
  public static QCSNull: QCSBaseObject = new QCSBaseObject();

  public getValue(): unknown {
    return null;
  }

  public multiplyBy(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public add(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public sub(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public div(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public modulo(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public greaterThan(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public lessThan(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public greaterEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public lessEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    return QCSBaseObject.QCSNull;
  }

  public negation() {
    return QCSBaseObject.QCSNull;
  }

  public equals(obj: unknown): boolean {
    if (obj instanceof QCSBaseObject) {
      if (this === QCSBaseObject.QCSNull) {
        return obj.getValue() === QCSBaseObject.QCSNull.getValue();
      } else {
        if (obj === QCSBaseObject.QCSNull) return false;
      }
      return JSON.stringify(this.getValue()) === JSON.stringify(obj.getValue());
    }
    return false;
  }

  public isFalse(): boolean {
    return this === QCSBaseObject.QCSNull;
  }
}
