import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DialogQcField from '../../DialogQcField';
import useData from './hooks';
import { RepeatableQcFieldChildProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { FieldMainBox, LabelOnAccordion } from '50.quickConnect.Fields/styles';
import FieldRenderer from '50.quickConnect.Fields/Fields/FieldRenderer';
import getFieldKey from '50.quickConnect.Fields/helpers/getFieldKey';
import { DialogDesc, FieldDesc } from '90.quickConnect.Models/models';
import { C2SImportant } from '50.quickConnect.Fields/Fields/styles';
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from '50.quickConnect.Fields/FieldsTypes/Containers/GroupQcField/styles';
import MandatoryField from '10.quickConnect.app/components/domain/Home/Shared/MandatoryFields';
import DeclarationStore from '30.quickConnect.Stores/RootStore/DeclarationStore';

const RepeatableQcFieldChild = ({
  field,
  updateDeclaration,
  selectedIndex,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  setDeclaration,
  removeItem,
  index,
  forceExpand,
  parentId,
  context,
  updateFieldErrors,
  updateItemsForThisField,
  openByImagesGroupField,
  isPopup,
  labelSpan,
  summaryTitle,
}: RepeatableQcFieldChildProps) => {
  const groupField = field as FieldDesc;
  const { fullPathId, items } = groupField;
  const { t, nameWithId, isExpanded, handleChange } = useData(
    parentId,
    forceExpand,
    groupField,
    updateDeclaration,
    setSelectedIndex,
    openByImagesGroupField,
  );
  // On récupère le store
  const {
    LoginStore: { signInInfos },
  } = useStore();
  const customTheme = useTheme();
  const { breakpoints } = customTheme;

  const [computedSummaryTitle, setComputedSummaryTitle] = useState<string>('');
  useEffect(() => {
    DeclarationStore.calculateLabel(field.items, summaryTitle, signInInfos, context).then((res) => {
      setComputedSummaryTitle(res);
    });
  }, [context, flattenFields, field.items, isExpanded, signInInfos, summaryTitle]);

  const getCustomAccordionDetails = useMemo(() => {
    return (
      <CustomAccordionDetails theme={customTheme}>
        {items.map((item) => {
          const key = getFieldKey(item);
          return item?.importance ? (
            <Box sx={C2SImportant(item.importance)} key={key}>
              <MandatoryField key={key} fieldDesc={item}>
                <FieldRenderer
                  key={key}
                  field={item}
                  updateDeclaration={updateDeclaration}
                  selectedIndex={selectedIndex}
                  updateItemsForThisField={updateItemsForThisField}
                  setSelectedIndex={setSelectedIndex}
                  isReadOnly={isReadOnly || item.fieldIsReadOnly}
                  flattenFields={flattenFields}
                  setDeclaration={setDeclaration}
                  context={context}
                  updateFieldErrors={updateFieldErrors}
                />
              </MandatoryField>
            </Box>
          ) : (
            <MandatoryField key={key} fieldDesc={item}>
              <FieldRenderer
                key={key}
                field={item}
                updateDeclaration={updateDeclaration}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                updateItemsForThisField={updateItemsForThisField}
                isReadOnly={isReadOnly || item.fieldIsReadOnly}
                flattenFields={flattenFields}
                setDeclaration={setDeclaration}
                context={context}
                updateFieldErrors={updateFieldErrors}
              />
            </MandatoryField>
          );
        })}
      </CustomAccordionDetails>
    );
  }, [
    context,
    customTheme,
    flattenFields,
    isReadOnly,
    items,
    selectedIndex,
    setDeclaration,
    setSelectedIndex,
    updateDeclaration,
    updateFieldErrors,
    updateItemsForThisField,
  ]);

  const getCustomAccordion = () => {
    return (
      <CustomAccordion
        theme={customTheme}
        expanded={isPopup ? false : isExpanded}
        onChange={() => handleChange(isExpanded)}
        elevation={0}
      >
        <CustomAccordionSummary
          theme={customTheme}
          expandIcon={isPopup ? <KeyboardArrowRightIcon /> : <ExpandMoreIcon />}
          aria-controls={`panel1a-content1-${nameWithId}`}
          id={`panel1a-content1-${nameWithId}`}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
              id="coucou"
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Tooltip title={t('formlib_array_deleted_title').toString()}>
                  <span>
                    <IconButton
                      data-cy={`${nameWithId}-remove`}
                      color="primary"
                      onClick={(event) => removeItem(event, fullPathId)}
                      disabled={isReadOnly}
                    >
                      <DeleteOutlineRoundedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Typography sx={LabelOnAccordion}>{computedSummaryTitle}</Typography>
              </Box>
              <Typography mr={1}>{index + 1}</Typography>
            </Box>
          </Box>
        </CustomAccordionSummary>
        {isPopup ? <></> : getCustomAccordionDetails}
      </CustomAccordion>
    );
  };

  function getDialog() {
    return (
      <DialogQcField
        key={`dialog-${field.fullPathId}`}
        field={{ ...field, customOpener: getCustomAccordion() } as DialogDesc}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        isReadOnly={false}
        context={context}
        flattenFields={flattenFields}
        updateDeclaration={updateDeclaration}
        labelSpan={labelSpan}
        updateFieldErrors={updateFieldErrors}
        setDeclaration={setDeclaration}
        updateItemsForThisField={updateItemsForThisField}
      />
    );
  }

  return (
    <Box data-cy={nameWithId} sx={FieldMainBox(breakpoints)} id={`scrollToAnchor-${fullPathId}`}>
      <>{isPopup || getCustomAccordion()}</>
      <>{isPopup && getDialog()}</>
    </Box>
  );
};
export default observer(RepeatableQcFieldChild);
