import Dexie, { Table, Transaction } from 'dexie';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { errorHandler } from '80.quickConnect.Core/helpers';

export interface IFieldSearchHistory {
  id?: string;
  description: string[];
  userUPN: string;
}

export class FieldSearchHistory extends Dexie {
  // Tag
  private static readonly TAG = '40.quickConnect.DataAccess/indexedDb/dbs/historyDb.ts';

  history!: Table<IFieldSearchHistory>;

  logger: CustomLogger;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('fieldSearchHistory');
    this.version(2).stores({
      history: 'id++, description, [id+userUPN]',
    });

    this.logger = CustomLogger.getInstance();
  }

  async modifyFieldHistory(fieldToInsert: IFieldSearchHistory) {
    try {
      if (!fieldToInsert.id) return;

      this.transaction('rw', this.history, async () => {
        await this.history.put(fieldToInsert, fieldToInsert.id);
      });
    } catch (error) {
      errorHandler(FieldSearchHistory.TAG, error, 'modifyFieldHistory');
    }
  }

  async getFieldHistoryById(id?: string): Promise<IFieldSearchHistory | undefined> {
    try {
      if (!id) return;

      return await this.history.get(id);
    } catch (error) {
      errorHandler(FieldSearchHistory.TAG, error, 'getFieldHistoryById');
    }
  }

  async getLRUDescriptionsByFieldLRUFullIdAsync(
    lruFullId: string,
    userUPN: string,
  ): Promise<IFieldSearchHistory | undefined> {
    try {
      const result = await this.history.where({ id: lruFullId, userUPN }).first();

      return result;
    } catch (error) {
      errorHandler(FieldSearchHistory.TAG, error, 'getLRUDescriptionsByFieldIdAsync');
    }
  }

  async removeLRUValue(lruFullId: string, userUPN: string, valueToRemove: string): Promise<void> {
    const lru = await this.history.where({ id: lruFullId, userUPN }).first();

    if (!lru)
      throw new Error(
        `removeLRUValue en erreur: aucun lru sauvé en base locale pour le lruFullId suivant: ${lruFullId}`,
      );

    await this.modifyFieldHistory({
      ...lru,
      description: lru.description.filter((val: string) => val !== valueToRemove),
    });
  }

  countRecords = async (): Promise<number> => {
    try {
      return await this.history.count();
    } catch (error: unknown) {
      errorHandler(FieldSearchHistory.TAG, error, 'countRecords');

      return 0;
    }
  };
}

export const fieldSearchHistoryDb = new FieldSearchHistory();
