import { AllFieldValueTypes, GeolocationData, FieldDesc, AddressData, Position } from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';

/**
 * Retourne une chaine de caractères depuis un objet GeoLocationData
 * @example <caption>Lat:48.80743 / Lon: 2.23485</caption>
 */
const getCoordinatesLabel = (coordinates: GeolocationData): string => {
  return `Lat: ${numericLocaleFormatLitteral(coordinates.latitude)} / Lon: ${numericLocaleFormatLitteral(
    coordinates.longitude,
  )}`;
};

export const getReferencedGeolocationValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
  fieldWithReference: FieldDesc,
): AllFieldValueTypes => {
  const geolocationValue = valueReferenceDependency as GeolocationData;
  const { value: addressValue } = fieldWithReference;

  if (!geolocationValue) return addressValue;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
      return getCoordinatesLabel(geolocationValue);

    case FieldType.Geolocation:
      return geolocationValue;

    case FieldType.CheckBox:
      return 1;

    case FieldType.Address:
      const addressReferencedValue = fieldWithReference.value as AddressData;
      const { latitude, longitude } = geolocationValue;
      return latitude && longitude
        ? { ...addressReferencedValue, coordinates: geolocationValue as Position }
        : addressReferencedValue;
    default:
      return undefined;
  }
};
