import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

export function hasValueForThisProperty<T extends object>(
  arr: T[],
  property: keyof T,
  valueToFind: string | number | boolean,
): boolean {
  if (arr.length === 0) return false;

  return arr.some((i) => {
    const propertySearched = i[property];
    if (typeof propertySearched !== typeof valueToFind) return false;

    return typeof propertySearched === 'string'
      ? StringExtension.isTheSame(propertySearched, valueToFind as string)
      : i[property] === valueToFind;
  });
}
