export class QCScriptFunctionInfo {
  // Nom de la fonction
  name: string;

  // Adresse mémoire du début de la fonction (après paramètres)
  memoryAddress: number;

  constructor(name: string, memoryAddress: number) {
    this.name = name;
    this.memoryAddress = memoryAddress;
  }
}
