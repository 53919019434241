import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { mapFieldDesc, mapQCNotification, parseTimeFromAPI } from '90.quickConnect.Models/mappings';
import { isFieldDataArray } from '90.quickConnect.Models/guards';
import {
  FieldData,
  FieldDesc,
  RepeatableGroupDesc,
  AttachmentItemData,
  AllFieldValueTypes,
  DateTimeDesc,
  QCActionValueData,
} from '90.quickConnect.Models/models';
import { ConsentFrequency, FieldType, FormType, DateTimeFieldType } from '90.quickConnect.Models/enums';
import attachmentDb from '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb';
import { DateTimeExtension } from '80.quickConnect.Core/formatting/DateTimeExtension';
import AxiosClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/axios/index';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import { isNotificationDataPartial } from '80.quickConnect.Core/helpers/common';
import { errorHandler } from '80.quickConnect.Core/helpers';

const tag = '10.quickConnect.app/components/domain/Declaration/helpers/initForm/updateInitialField.ts';

const getFileByRemoteStorage = async (
  attachmentItemData: AttachmentItemData,
): Promise<AttachmentItemData | undefined> => {
  try {
    const clientHTTP: IClientHTTP = AxiosClientHTTP.getInstance();
    const { distantUri } = attachmentItemData;

    if (!distantUri || distantUri === '') return undefined;

    const remoteFileResponse = await clientHTTP.get(distantUri, { responseType: 'blob' });

    const blob: Blob = remoteFileResponse.data;

    const file: File = new File([blob], attachmentItemData.fileName, { type: attachmentItemData.type });

    return { ...attachmentItemData, file };
  } catch (error: unknown) {
    if (error instanceof Error) {
      errorHandler(tag, error, 'getFileByRemoteStorage');
    }
    return undefined;
  }
};

const isCurrentStep = (fullPathId: string, currentStepId?: string): boolean | never => {
  if (!currentStepId) return false;

  return fullPathId.toLowerCase().startsWith(currentStepId.toLowerCase());
};

const getDateTime = (
  editedValue: AllFieldValueTypes,
  initialField: DateTimeDesc,
): DateTimeExtension | string | never => {
  if (typeof editedValue !== 'string') throw new Error('Func getDateTime failed: Only string are allowed!!');

  if (editedValue === '') return editedValue;

  const dt = new Date(editedValue);
  const dtUTC = new Date(
    dt.getUTCFullYear(),
    dt.getUTCMonth(),
    dt.getUTCDate(),
    dt.getUTCHours(),
    dt.getUTCMinutes(),
    dt.getUTCSeconds(),
  );

  const dateTimeISOString = format(dtUTC, DateTimeExtension.FORMAT_ISO_8601_FULL_SEPARATOR_WITHOUT_TIMEZONE);

  // Petite erreur dans le format de la timezone nous oblige a vérifier 2 variables
  const offset1 = '+00:00';
  const offset2 = '+0000';

  const dtString1 = dateTimeISOString + offset1;
  const dtString2 = dateTimeISOString + offset2;

  const dateType = initialField.type;

  if (dateType === DateTimeFieldType.DateTime && (dtString1 === editedValue || dtString2 === editedValue))
    return new DateTimeExtension(dtUTC, true);

  return new DateTimeExtension(editedValue);
};

const curryMapUpdate =
  (
    updateInitalFieldFn: (
      initialField: FieldDesc,
      editedField: FieldData,
      templateBodies: Record<string, string> | null | undefined,
      dclId: string,
      isDuplicate: boolean,
      isInboxId: boolean,
      formType: FormType,
      currentStepId?: string,
    ) => Promise<FieldDesc | undefined>,
  ) =>
  (fieldDesc: FieldDesc) =>
  (editedValues: FieldData[]) =>
  (templateBodies: Record<string, string> | null | undefined) =>
  (dclId: string) =>
  (isDuplicate: boolean, isInboxId: boolean) =>
  (formType: FormType) =>
  async (currentStepId?: string): Promise<FieldDesc | undefined> => {
    try {
      const { fullPathId } = fieldDesc;

      const editedField: FieldData | undefined = editedValues.filter((f: FieldData) => f.id === fullPathId).pop();

      if (editedField)
        await updateInitalFieldFn(
          fieldDesc,
          editedField,
          templateBodies,
          dclId,
          isDuplicate,
          isInboxId,
          formType,
          currentStepId,
        );
    } catch (error: unknown) {
      errorHandler(tag, error, 'curryMapUpdate', 'trace');

      return fieldDesc;
    }
  };

export const updateInitalField = async (
  initialField: FieldDesc,
  editedField: FieldData,
  templateBodies: Record<string, string> | null | undefined,
  dclId: string,
  isDuplicate: boolean,
  isInboxId: boolean,
  formType: FormType,
  currentStepId?: string,
): Promise<FieldDesc> => {
  // Verifions le type et la valeur des champs concernés
  const { fieldType, label, value: editedValue } = editedField;

  // Protection sur les champs dont une duplication n'est pas autorisée...
  if (isDuplicate && initialField.canDuplicate === false) return initialField;

  if (!isFieldDataArray(editedField, editedValue)) {
    switch (fieldType) {
      case FieldType.Label:
        if (label !== null) {
          initialField.label = label;
          initialField.isVisible = label !== null;
        }
        break;

      case FieldType.Photo:
      case FieldType.Attachment:
      case FieldType.Audio:
      case FieldType.Draw:
      case FieldType.FixedAttachment:
      case FieldType.Signature: {
        if (typeof editedValue === 'string' || editedValue === null) {
          initialField.value = [];
          break;
        }

        if (formType === FormType.Workflow && !isCurrentStep(initialField.fullPathId, currentStepId)) {
          initialField.value = editedValue;
          break;
        }

        const attachmentsData: AttachmentItemData[] = editedValue as AttachmentItemData[];

        const newEditedValue = await Promise.all(
          attachmentsData.map(async (attachmentData: AttachmentItemData) => {
            try {
              const fileResponse =
                (await attachmentDb.getAttachmentById(attachmentData.id)) ??
                (await getFileByRemoteStorage(attachmentData));

              if (!fileResponse) return;

              const fileDownloaded = fileResponse.file as File;

              if (!isDuplicate && !isInboxId) {
                attachmentDb.addAttachmentItem(attachmentData, fileDownloaded, dclId, true);
                return { ...attachmentData, declarationId: dclId, file: fileDownloaded } as AttachmentItemData;
              }

              const obj = {
                id: uuidv4(),
                localUri: fileDownloaded.name,
                distantUri: '',
                title: fileDownloaded.name,
                fileName: fileDownloaded.name,
                creationDate: new Date(),
                purgeDate: '',
                type: fileDownloaded.type,
                size: fileDownloaded.size ?? 0,
                metadata: [],
                thumbnail: attachmentData.thumbnail,
                icon: '',
                file: fileDownloaded,
              } as AttachmentItemData;
              attachmentDb.addAttachmentItem(obj, fileDownloaded, dclId, false);
              return { ...obj, declarationId: dclId, file: fileDownloaded } as AttachmentItemData;
            } catch (error: unknown) {
              if (error instanceof Error) {
                error.message = `la pièce jointe ${attachmentData.fileName} n'a pas pu être chargé. Message: ${error.message}`;
                errorHandler(tag, error, 'updateInitialField');
              }
              return attachmentData;
            }
          }),
        );
        initialField.value = newEditedValue.reduce(
          (acc, current) => (current !== undefined ? [...acc, current] : acc),
          [] as AttachmentItemData[],
        );
        break;
      }

      // Cas des dateTime, un cas un peu complexe mis en evidence par la méthode toUTCDateTime de la classe QCSDate
      case FieldType.DateTime:
        const dateTimeField = initialField as DateTimeDesc;
        const dateTimeValue: DateTimeExtension | string = getDateTime(editedValue, dateTimeField);
        initialField.value = dateTimeValue;
        break;

      case FieldType.Notification: {
        if (!isNotificationDataPartial(editedValue)) break;

        const notificationData = mapQCNotification(editedValue);
        initialField.value = notificationData;
        break;
      }

      // Cas des time, où il faut manipuler les dates en fonction des heures d'hiver et d'été afin d'éviter le décalage d'été / hiver
      case FieldType.Time:
        if (typeof editedValue !== 'string') throw new Error('Error TimeValue updateInitialField');
        const timeValue: Date | undefined = parseTimeFromAPI(editedValue);
        initialField.value = timeValue;
        break;

      case FieldType.Action:
        const actionEditedValues = (editedValue ?? []) as QCActionValueData[];

        // Ici, on doit prendre en compte le fait que la value actionEditedValue.value est soit un ChoiceList (actionInitialField.isMultiSelection === true) soit un Choice (actionInitialField.isMultiSelection === false)
        const newActionEditedValues = actionEditedValues.map((actionEditedValue: QCActionValueData) => ({
          ...actionEditedValue,
          value: Array.isArray(actionEditedValue.value) ? actionEditedValue.value : [actionEditedValue.value],
        }));

        initialField.value = newActionEditedValues;
        break;

      default:
        initialField.value = editedValue;
        break;
    }

    return initialField;
  }

  //
  switch (fieldType) {
    case FieldType.RepeatableGroup: {
      const { groupTemplate, id } = initialField as RepeatableGroupDesc;
      const newItems = await Promise.all(
        editedValue.map(async (fieldData: FieldData, index: number) => {
          const indexStr = index.toString();
          const newIncludeChildren = groupTemplate.map((gt: FieldDesc) => {
            return mapFieldDesc(gt, `${fieldData.id}`, templateBodies);
          });

          const newIncludeField: FieldDesc = {
            stateId: uuidv4(),
            fieldType: FieldType.Include,
            items: newIncludeChildren,
            value: true,
            errors: undefined,
            fullPathId: fieldData.id,
            id: `${id}.${indexStr}`,
            fieldIsReadOnly: false,
            isVisible: true,
            checkRGPD: ConsentFrequency.Undef,
          };

          await updateInitalField(
            newIncludeField,
            fieldData,
            templateBodies,
            dclId,
            isDuplicate,
            isInboxId,
            formType,
            currentStepId,
          );
          return newIncludeField;
        }),
      );

      initialField.items = newItems;

      initialField.value = false;
      break;
    }

    case FieldType.Include:
    case FieldType.Step:
    case FieldType.Group:
    case FieldType.ImagesGroup:
    case FieldType.Dialog: {
      const { items } = initialField;
      const newItems = await Promise.all(
        items.map(async (fieldDesc: FieldDesc) => {
          const newItem = await curryMapUpdate(updateInitalField)(fieldDesc)(editedValue)(templateBodies)(dclId)(
            isDuplicate,
            isInboxId,
          )(formType)(currentStepId);

          return newItem ?? fieldDesc;
        }),
      );

      initialField.items = newItems;
      initialField.value = editedValue.length > 0;
      break;
    }

    default:
      initialField.value = editedValue;
      break;
  }

  return initialField;
};
