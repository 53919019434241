import { LatLngLiteral } from 'leaflet';
import { UseDataQcFieldPropsBase } from '50.quickConnect.Fields/FieldsTypes/types';
import { GeolocationData } from '90.quickConnect.Models/models';

export type UseDataProps = UseDataQcFieldPropsBase & {
  mapContainer: JSX.Element | undefined;
  locations: LatLngLiteral | undefined;
  localValue: GeolocationData | undefined;
  handleValidate: () => void;
  setQcMap: React.Dispatch<React.SetStateAction<L.Map | undefined>>;
  setMapContainer: (value: React.SetStateAction<JSX.Element | undefined>) => void;
  initializeMapRef: React.MutableRefObject<boolean>;
  handleCloseDialog: () => void;
  handleDelete: () => void;
};

export enum ShowMapEnum {
  Dynamic = 'Dynamic',
  Static = 'Static',
}
