import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

export const useData = (): UseDataProps => {
  const { t } = useTranslation('inboxes');

  // On récupère le store
  const {
    InboxesStore: { PreFilledStore, TasksStore, OthersStore, prefilledItemsCount, tasksItemsCount, othersItemsCount },
  } = useStore();

  const prefilledItemsTitle = useMemo((): string => {
    const startTitle = t('qcapp_inbox_item_prefilled_form_node_name');
    return prefilledItemsCount > 0 ? `${startTitle} (${prefilledItemsCount})` : startTitle;
  }, [prefilledItemsCount, t]);

  const tasksItemsTitle = useMemo((): string => {
    const startTitle = t('qcapp_inbox_item_todolist_node_name');
    return tasksItemsCount > 0 ? `${startTitle} (${tasksItemsCount})` : startTitle;
  }, [tasksItemsCount, t]);

  const othersItemsTitle = useMemo((): string => {
    const startTitle = t('qcapp_inbox_item_category_not_found');
    return othersItemsCount > 0 ? `${startTitle} (${othersItemsCount})` : startTitle;
  }, [othersItemsCount, t]);

  return {
    PreFilledStore,
    TasksStore,
    OthersStore,
    prefilledItemsTitle,
    tasksItemsTitle,
    othersItemsTitle,
    t,
  };
};
