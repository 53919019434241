import { MutableRefObject, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { UseDataProps } from './types';
import { AttachmentItemData, DrawDesc } from '90.quickConnect.Models/models';
import { AllFieldValueTypes, FieldDesc } from '90.quickConnect.Models/models';
import attachmentDb from '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb';

import { blackColor } from '00.app/theme';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { useStore } from '30.quickConnect.Stores';
import { createBase64FromCanvas } from '50.quickConnect.Fields/FieldsTypes/Attachments/helpers/bipMapDrawer';

const defaultBackGroundImage = (defaultImage: AttachmentItemData | undefined): string => {
  if (!defaultImage) return '';

  const { distantUri } = defaultImage;

  return distantUri;
};

const useData = (
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  field: FieldDesc,
  drawDesc: DrawDesc,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
): UseDataProps => {
  // Tag
  const tag = '50.quickConnect.Fields/FieldsTypes/Attachments/DrawQcField/hooks.tsx';

  const logger = CustomLogger.getInstance();
  const { fullPathId, value, fieldType, maxAttachment, maximumSize, defaultImage } = drawDesc;
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const [brushRadius, setBrushRadius] = useState(10);
  const [brushColor, setBrushColor] = useState(blackColor);
  const [signatureList, setSignatureList] = useState<string[]>([]);
  const [signatureFileList, setSignatureFileList] = useState<AttachmentItemData[]>(
    (value as AttachmentItemData[]) ?? [],
  );

  // BackGround image - fourni par défaut par la prop defaultImage
  const [backGroundImage, setBackGroundImage] = useState<string>(() => defaultBackGroundImage(defaultImage));
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const canvasRef: MutableRefObject<any> = useRef();

  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, signatureFileList);
  }, [fullPathId, signatureFileList, updateDeclaration]);

  const {
    DeclarationStore: { declarationId },
  } = useStore();

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  });

  const dataURItoBlob = (dataURI: string, fileName: string) => {
    const byteString = atob(dataURI.split(',')[1]);
    // eslint-disable-next-line prefer-destructuring
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], fileName, { type: mimeString });
  };

  const handleExport = useCallback(async () => {
    const b64 =
      backGroundImage !== ''
        ? await createBase64FromCanvas(canvasRef.current.canvasContainer)
        : canvasRef.current?.getDataURL('png', false, '#FFFFFF');

    logger.log(tag, `'fieldType: ', ${fieldType}`);
    const fileName = `QC_${b64.split(';')[0].split('/')[1].toUpperCase()}_${uuidv4()}`;
    // Pour eviter l'ajout d'une signature vide
    setSignatureList([...signatureList, b64]);
    const blobFile = dataURItoBlob(b64, fileName);

    const obj = {
      id: uuidv4(),
      localUri: fileName,
      distantUri: '',
      title: fileName,
      fileName: fileName,
      creationDate: new Date(),
      purgeDate: '',
      type: blobFile.type,
      size: blobFile.size ?? 0,
      metadata: [],
      thumbnail: b64.replace(/^data:image\/[a-z]+;base64,/, ''),
      icon: '',
      file: blobFile,
    } as AttachmentItemData;
    if (maxAttachment > signatureFileList.length) {
      if (maximumSize * 1024 ** 2 >= blobFile.size) {
        setSignatureFileList([...signatureFileList, obj]);
        canvasRef.current?.clear();
        await attachmentDb.addAttachmentItem(obj, blobFile, declarationId);
        debouncedUpdateGlobalState();
        setSelectedIndex(undefined);
      } else {
        toast.warn(`${t('attachment_fields_errors_has_max_size')} (${maximumSize} Mo)`);
      }
    } else {
      toast.warn(`${t('attachment_fields_errors_has_max_count')} (${maxAttachment})`);
    }
  }, [
    tag,
    declarationId,
    fieldType,
    logger,
    signatureList,
    maximumSize,
    maxAttachment,
    signatureFileList,
    debouncedUpdateGlobalState,
    t,
    setSelectedIndex,
    backGroundImage,
  ]);

  const onDeleteSignature = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: AttachmentItemData) => {
      event.preventDefault();
      event.stopPropagation();
      const filtredList = signatureFileList.filter((x) => x.id !== data.id);
      setSignatureFileList(filtredList);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState, signatureFileList],
  );
  const handleClear = useCallback(() => {
    setBackGroundImage(defaultBackGroundImage(defaultImage));
    canvasRef.current?.clear();
  }, [defaultImage]);

  const handleErase = useCallback(() => {
    setIsChecked((prev) => !prev);

    if (!isChecked) canvasRef.current.ctx.drawing.globalCompositeOperation = 'destination-out';
    else canvasRef.current.ctx.drawing.globalCompositeOperation = 'source-over';
  }, [isChecked]);

  const attachmentsItemsData: AttachmentItemData[] = useMemo(() => {
    if (!Array.isArray(value)) return [];

    return value;
  }, [value]);

  useEffect(() => {
    if (
      signatureFileList.some(
        (attachmentItemData: AttachmentItemData) =>
          attachmentItemData.file &&
          (attachmentItemData.file instanceof File === false || attachmentItemData.file instanceof Blob === false),
      )
    ) {
      Promise.all(
        signatureFileList.map(async (attach: AttachmentItemData) => {
          const localFile = await attachmentDb.getAttachmentById(attach.id);
          attach.file = localFile?.file ?? undefined;
          return attach;
        }),
      ).then((newSignatureFileList) => {
        setSignatureFileList((prevState: AttachmentItemData[]): AttachmentItemData[] =>
          prevState !== newSignatureFileList ? newSignatureFileList : prevState,
        );
      });
    }
  }, [field.fieldType, signatureFileList]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { files } = event.currentTarget;
    let link = '';
    if (files) {
      link += encodeURI(URL.createObjectURL(files[0]));
      setBackGroundImage(link);
    }
  }, []);

  // src
  const getSrcValue = useCallback((attachItem: AttachmentItemData) => {
    return attachItem.distantUri
      ? attachItem.distantUri
      : attachItem.file &&
        (attachItem.file instanceof File || attachItem.file instanceof Blob) &&
        Object.keys(Object.getPrototypeOf(attachItem.file)).length > 0
      ? URL.createObjectURL(attachItem.file as Blob)
      : '';
  }, []);

  const handleUndo = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    canvasRef.current.undo();
  }, []);

  // On retourne les valeurs à la vue
  return {
    handleUndo,
    t,
    brushRadius,
    setBrushRadius,
    brushColor,
    setBrushColor,
    signatureList,
    canvasRef,
    handleExport,
    handleClear,
    signatureFileList,
    onDeleteSignature,
    backGroundImage,
    handleChange,
    handleErase,
    isChecked,
    attachmentsItemsData,
    getSrcValue,
  };
};

export default useData;
