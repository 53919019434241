import { DefaultTimeValue } from '90.quickConnect.Models/enums';
import { TimeDesc } from '90.quickConnect.Models/models';
import { parseTimeFromAPI } from '90.quickConnect.Models/mappings';

const getDefaultTimeValue = (field: TimeDesc) => {
  let defaultValue = field.defaultValue ? new Date(field.defaultValue) : undefined;

  switch (field.initTime) {
    case DefaultTimeValue.Now:
    default:
      defaultValue = new Date();
      break;
    case DefaultTimeValue.Undef:
      break;
  }

  if (typeof field.value === 'string' && !isNaN(new Date(field.value).getTime())) {
    defaultValue = parseTimeFromAPI(field.value);
  }

  return defaultValue;
};

export default getDefaultTimeValue;
