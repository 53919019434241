export function isTypedRecord<V>(input: unknown): input is Record<string, V> {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  for (const key in input) {
    if (!(key in input)) {
      continue;
    }

    if (typeof (input as Record<string, unknown>)[key] !== typeof ({} as V)) {
      return false;
    }
  }

  return true;
}

export function isRecordWithProperty(
  input: object,
  prop: string,
  type: string | ((input: unknown) => boolean),
): boolean {
  const value = (input as Record<string, unknown>)[prop];
  return (
    prop in input &&
    (type === 'undefined'
      ? value === undefined || value === null
      : typeof type === 'string'
      ? typeof value === type
      : value === null || !!type(value))
  );
}

export function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}
