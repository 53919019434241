import React, { FunctionComponent, ReactElement } from 'react';
import { Box, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

const topBarStyle = (theme: Theme, bgColor?: string) => {
  const backgroundColor = bgColor || theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200];

  return {
    backgroundColor: backgroundColor,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    minHeight: '52px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem',
  };
};

type TopBarProps = {
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  bgColor?: string;
};

const WorkSiteTopBar: FunctionComponent<TopBarProps> = (props: TopBarProps) => {
  const theme = useTheme();
  return (
    <Box margin={'1rem 2rem 0rem 2rem'}>
      <Box style={topBarStyle(theme, props.bgColor)}>
        <>
          {props.leftElement}
          {props.rightElement}
        </>
      </Box>
    </Box>
  );
};

export default WorkSiteTopBar;
