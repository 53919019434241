import React from 'react';
import { observer } from 'mobx-react';
import { Breadcrumbs, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ParentBreadCrumbsProps } from './types';
import { ParentBreadCrumbsName } from './const';

const ParentBreadCrumbs = ({ parents, goUp, parentId }: ParentBreadCrumbsProps) => {
  const nameWithId = `${ParentBreadCrumbsName}-${parentId}`;
  return (
    <Breadcrumbs
      sx={{ marginTop: '10px' }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      data-cy={nameWithId}
    >
      {parents.map((parent) => (
        <Button key={parent.hierarchicalNodePath} onClick={() => goUp(parent)}>
          {parent.hierarchicalLabel}
        </Button>
      ))}
    </Breadcrumbs>
  );
};
export default observer(ParentBreadCrumbs);
