import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { DECLARATION_HISTORY } from '10.quickConnect.app/routes/paths';
import { debounceTime } from '50.quickConnect.Fields/const';

export const useData = (): UseDataProps => {
  // On récupère le store
  const {
    DeclarationStore: {
      HistoryStore: {
        items: declarations,
        expandedNodes: declarationsExpandedNodes,
        treeNodeKeys: declarationsTreeNodeKeys,
        setFilter: setDeclarationFilter,
        filter: declarationFilter,
        setExpandedNodes: setDeclarationsExpandedNodes,
        numberDisplayed: declarationDisplayed,
      },
      DraftsStore: {
        items: drafts,
        expandedNodes: draftsExpandedNodes,
        treeNodeKeys: draftsTreeNodeKeys,
        setFilter: setDraftFilter,
        filter: draftFilter,
        setExpandedNodes: setDraftsExpandedNodes,
        numberDisplayed: draftDisplayed,
      },
    },
    UserSettingsStore: { declarationsAsTree, setDeclarationsAsTree },
  } = useStore();
  const navigate = useNavigate();

  // On définit le state
  const [filterInputValue, setFilterInputValue] = useState(declarationFilter ?? draftFilter);
  const numberDisplayed = useMemo(() => draftDisplayed + declarationDisplayed, [declarationDisplayed, draftDisplayed]);
  const totalNumber = useMemo(
    () => (declarations?.length ?? 0) + (drafts?.length ?? 0),
    [declarations?.length, drafts?.length],
  );
  const isAllExpanded = useMemo(
    () =>
      declarationsExpandedNodes.length + draftsExpandedNodes.length <
      declarationsTreeNodeKeys.length + draftsTreeNodeKeys.length,
    [
      declarationsExpandedNodes.length,
      declarationsTreeNodeKeys.length,
      draftsExpandedNodes.length,
      draftsTreeNodeKeys.length,
    ],
  );

  const handleExpandClick = useCallback(() => {
    setDeclarationsExpandedNodes(isAllExpanded ? declarationsTreeNodeKeys : []);
    setDraftsExpandedNodes(isAllExpanded ? draftsTreeNodeKeys : []);
  }, [
    declarationsTreeNodeKeys,
    draftsTreeNodeKeys,
    isAllExpanded,
    setDeclarationsExpandedNodes,
    setDraftsExpandedNodes,
  ]);

  const applyFilter = useCallback(
    (valueToSearch: string): void => {
      setDeclarationFilter(valueToSearch);
      setDraftFilter(valueToSearch);
    },
    [setDeclarationFilter, setDraftFilter],
  );

  useEffect(() => {
    if (declarationFilter !== '' && draftFilter !== '') {
      setDeclarationsExpandedNodes(declarationsTreeNodeKeys);
      setDraftsExpandedNodes(draftsTreeNodeKeys);
    }
  }, [
    declarationFilter,
    declarationsTreeNodeKeys,
    draftFilter,
    draftsTreeNodeKeys,
    setDeclarationsExpandedNodes,
    setDraftsExpandedNodes,
  ]);

  const clearFilter = useCallback((): void => {
    setFilterInputValue('');
    setDeclarationFilter('');
    setDraftFilter('');
  }, [setDeclarationFilter, setDraftFilter]);

  const navigateToOldDeclaration = useCallback(() => {
    navigate(DECLARATION_HISTORY);
  }, [navigate]);

  const debouncedSearch = useDebouncedCallback((valueToSearch) => {
    applyFilter(valueToSearch);
  }, debounceTime);
  useEffect(() => {
    if (filterInputValue) {
      debouncedSearch(filterInputValue);
    }
  }, [debouncedSearch, filterInputValue]);
  return {
    applyFilter,
    clearFilter,
    filterInputValue,
    setFilterInputValue,
    filter: declarationFilter ?? draftFilter,
    handleExpandClick,
    isAllExpanded,
    declarationsTreeNodeKeys,
    numberDisplayed,
    totalNumber,
    declarationsAsTree,
    setDeclarationsAsTree,
    navigateToOldDeclaration,
  };
};
