import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UserDataProps } from './types';
import { AllFieldValueTypes, TodoListDesc, TodoTask } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  field: TodoListDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
): UserDataProps => {
  // On récupère la translation
  const { fullPathId } = field;
  const { t } = useTranslation('declaration');
  const {
    InboxesStore: {
      getTodoListTask,
      TasksStore: { items: tasks },
      inprogressTask,
      finishedTasks,
      allTask,
    },
  } = useStore();
  const location = useLocation();
  const [localTodoTask, setLocalTodoTask] = useState<TodoTask>({
    id: null,
    name: null,
    state: 0,
    todoListId: null,
    taskEditedData: [],
  });
  const [show, setShow] = useState<boolean>(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localTodoTask);
  }, [fullPathId, localTodoTask, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const debouncedcheckLocation = useDebouncedCallback(() => {
    navigate(-1);
    toast.warn(`${t('qcapp_warning_error_opening_template_form')}`);
  }, 100);
  useEffect(() => {
    if (!location.pathname.includes('inbox')) {
      debouncedcheckLocation();
    }
  }, [debouncedcheckLocation, location.pathname, navigate]);

  useEffect(() => {
    if (localTodoTask) {
      debouncedUpdateGlobalState();
    }
  }, [debouncedUpdateGlobalState, localTodoTask]);
  useEffect(() => {
    if (allTask.length == 0) {
      getTodoListTask((state as any)?.activityId).then((result: any) => {
        if (localTodoTask.taskEditedData.length === 0) {
          setLocalTodoTask({
            id: result?.todoList?.id,
            name: result?.todoList?.todoListName,
            state: 0,
            todoListId: result?.todoList?.todoListId,
            taskEditedData: [],
          });
        }
      });
    }
  }, [allTask.length, getTodoListTask, localTodoTask, state]);

  const handleShow = useCallback(() => {
    setShow(!show);
  }, [show]);
  // On retourne les valeurs à la vue
  return {
    setLocalTodoTask,
    localTodoTask,
    inprogressTask,
    finishedTasks,
    handleShow,
    show,
  };
};

export default useData;
