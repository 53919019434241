import { useCallback, useReducer } from 'react';
import { useNavigate } from 'react-router';

import { NEW_DECLARATION, NEW_DECLARATION_WITH_EQUIPMENT } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';
import { FormViewer } from '90.quickConnect.Models/models';

const useData = ({ id, entityInstanceId }: FormViewer) => {
  const navigate = useNavigate();

  const {
    DeclarationStore: {
      DraftsStore: { items: declarations = [] },
    },
  } = useStore();

  const [choiceDialogOpen, toggleChoiceDialog] = useReducer((state) => !state, false);

  const onClickForm = useCallback(() => {
    const relatedDeclarations = declarations.filter((declaration) => declaration.formId === id);

    if (relatedDeclarations.length === 0) {
      let link = NEW_DECLARATION.replace(':formId', id);

      if (entityInstanceId) {
        link = NEW_DECLARATION_WITH_EQUIPMENT.replace(':formId', id).replace(':entityInstanceId', entityInstanceId);
      }

      navigate(link);
    } else {
      toggleChoiceDialog();
    }
  }, [declarations, entityInstanceId, id, navigate]);

  return { declarations, choiceDialogOpen, onClickForm, toggleChoiceDialog };
};

export default useData;
