import React from 'react';
import { observer } from 'mobx-react';
import { FormControl, List } from '@mui/material';
import useData from './hooks';
import { CheckBoxListRightContentName } from './const';
import { CheckBoxListRightContentProps } from './types';
import CheckBoxListItem from './CheckBoxListItem';
import { Choice, ComboDesc } from '90.quickConnect.Models/models';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';

const CheckBoxListRightContent = ({
  field,
  updateDeclaration,
  labelBox,
  choices,
  isItemDeleted,
  setIsItemDeleted,
  isDeleteAll,
  setIsDeleteAll,
}: CheckBoxListRightContentProps) => {
  const comboDesc = field as ComboDesc;
  const { id } = comboDesc;
  const nameWithId = `${CheckBoxListRightContentName}-${id}`;
  const { t, localValue, updateLocalState, label, setLabel, currentChoices, handleClear, onSearch } = useData(
    comboDesc,
    updateDeclaration,
    choices,
    isItemDeleted,
    setIsItemDeleted,
    setIsDeleteAll,
    isDeleteAll,
  );

  return (
    <FormControl data-cy={nameWithId} sx={{ width: '100%' }}>
      {currentChoices && (currentChoices.length >= 10 || onSearch) && (
        <SearchBar
          toolTip={t('search_bar').toString()}
          filterInputValue={label}
          setFilter={setLabel}
          filter={label}
          applyFilter={() => {}}
          clearFilter={handleClear}
          setSearchIconToRigth={false}
        />
      )}
      {labelBox}
      <List sx={{ width: '100%', marginTop: '10px' }}>
        {currentChoices.map((choice: Choice, index: number) => (
          <CheckBoxListItem
            key={`${nameWithId}-${choice.value}-${choice.label}`}
            parentId={id}
            updateLocalState={updateLocalState}
            choice={choice}
            localValue={localValue}
          />
        ))}
      </List>
    </FormControl>
  );
};
export default observer(CheckBoxListRightContent);
