import equipmentsDb from '40.quickConnect.DataAccess/indexedDb/dbs/equipmentsDb';
import { errorHandler } from '80.quickConnect.Core/helpers';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { EntitySchemaAttributeTypes, FieldType } from '90.quickConnect.Models/enums';
import {
  AllFieldValueTypes,
  Choice,
  FieldData,
  HierarchicalChoice,
  KeyValuePair,
  EntityData,
  AttributeValue,
  QCDocument,
  FormEditedData,
} from '90.quickConnect.Models/models';

class DataSourceValue {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/helpers/DataSourceValue/index.ts';

  /**
   * Id de la famille de l'équipement
   */
  public schemaId = '';

  /**
   *Id de l'équipement
   */
  public instanceId = '';

  /**
   * Code de l'équipement
   */
  public code = '';

  /**
   * titre de l'équipement
   */
  public title = '';

  /**
   * Ensemble clé, valeurs des attributs ou dico des valeurs disponibles ({ attributeLabel: attributeValue })
   *
   * @private
   * @type {(Record<string, unknown> | null)}
   * @memberof DataSourceStore
   */
  private mDicoValues: Record<string, unknown> | null = null;

  /**
   * Ensemble clé, valeurs des attributs dont la clé est le PropertyName à la place du name de l'attribut ({ attributeId: attributeValue })
   *
   * @private
   * @type {(Record<string, unknown> | null)}
   * @memberof DataSourceStore
   */
  private mDicoPropertyNameValues: Record<string, unknown> | null = null;

  public constructor(schemaId = '', instanceId = '', code = '', title = '', fdl: FieldData[] = []) {
    this.schemaId = schemaId;
    this.instanceId = instanceId;
    this.code = code;
    this.title = title;

    this.fromFieldDataList(fdl);
  }

  public setPropertyValue = (key: string, propertyName: string, value: unknown): void => {
    if (this.mDicoValues === null) this.mDicoValues = {} as Record<string, unknown>;

    this.mDicoValues[key] = value;

    if (this.mDicoPropertyNameValues === null) this.mDicoPropertyNameValues = {};

    this.mDicoPropertyNameValues[propertyName] = value;
  };

  public getPropertyValue = (from: string): unknown | null => {
    if (this.mDicoValues) {
      return from in this.mDicoValues ? this.mDicoValues[from as keyof KeyValuePair<string, unknown>] : null;
    }

    if (this.mDicoPropertyNameValues) {
      return from in this.mDicoPropertyNameValues
        ? this.mDicoPropertyNameValues[from as keyof KeyValuePair<string, unknown>]
        : null;
    }
  };

  /**
   * Réinit des dico depuis la FieldDataList
   * @defObject
   * @param {FieldData[]} fdl
   */
  private fromFieldDataList = (fdl: FieldData[]): void => {
    if (fdl.length === 0) return;

    for (const fd of fdl) {
      const { value } = fd;
      const strVal = value as string;
      if (fd.id === '##values') {
        try {
          this.mDicoValues = JSON.parse(strVal);
        } catch (error: unknown) {
          if (error instanceof Error) {
            error.message = `Parse du JSON en erreur pour les mDicoValues: ${error.message}`;
            errorHandler(DataSourceValue.TAG, error, 'fromFieldDataList');
          }
        }
      } else if (fd.id === '##names') {
        try {
          this.mDicoPropertyNameValues = JSON.parse(strVal);
        } catch (error: unknown) {
          if (error instanceof Error) {
            error.message = `Parse du JSON en erreur pour les mDicoPropertyNameValues: ${error.message}`;
            errorHandler(DataSourceValue.TAG, error, 'fromFieldDataList');
          }
        }
      }
    }
  };

  public buildEntityDataFromDataSourceValue = async (): Promise<void> => {
    try {
      if (!this.mDicoPropertyNameValues || !this.mDicoValues)
        throw new Error('Les dictionnaires ne doivent pas être à null.');

      const arrayDicoValues: [string, unknown][] = Object.entries(this.mDicoValues);
      const arrayDicoPropertyNameValues: [string, unknown][] = Object.entries(this.mDicoPropertyNameValues);

      if (arrayDicoValues.length !== arrayDicoPropertyNameValues.length)
        throw new Error("Les 2 dictionnaires n'ont pas la même longueur");

      // Construction du pseudo EntityData
      const attributes = arrayDicoValues.map((dicoValue: [string, unknown], index: number) => {
        const [keyDicoVal, dicoVal] = dicoValue;
        const [keyDicoProp, dicoPropVal] = arrayDicoPropertyNameValues[index];

        const type: EntitySchemaAttributeTypes = this.getEntitySchemaAttributeTypes(dicoVal);

        const attributeValue: AttributeValue = {
          attributeId: keyDicoProp,
          attributeLabel: keyDicoVal,
          type,
          value: dicoPropVal as AllFieldValueTypes,
        };

        return attributeValue;
      });

      const entityData: EntityData = {
        eTag: '',
        createdAt: new Date(),
        id: this.instanceId,
        title: this.title,
        code: this.code,
        organizationalUnitId: '',
        entitySchemaId: this.schemaId,
        entityschemaid: this.schemaId,
        attributes,
        correlationId: '',
        qcTagCode: '',
        qcDocuments: [] as QCDocument[],
        historicalData: [] as FormEditedData[],
        parentSchemasIds: [],
      };

      await equipmentsDb.remplaceOrInsert(entityData);
    } catch (error: unknown) {
      if (error instanceof Error) {
        error.message = `Construction de l'entityData en erreur: ${error.message}`;
        errorHandler(DataSourceValue.TAG, error, 'buildEntityDataFromDataSourceValue');
      }
    }
  };

  /**
   * Permet de sérializer les prop de la classe dans un stringFieldData (FieldData). Utilisée dans le cas des DataSource dans les internalData.
   *
   * @return {*}  {FieldData[]}
   * @memberof DataSourceValue
   */
  public asFieldDataList = (): FieldData[] => {
    const result: FieldData[] = [];
    if (this.mDicoValues) {
      try {
        const valueStr = JSON.stringify(this.mDicoValues);
        const fd: FieldData = {
          id: '##values',
          fieldType: FieldType.Text,
          label: 'values',
          value: valueStr,
        };

        result.push(fd);
      } catch (error: unknown) {
        if (error instanceof Error) {
          error.message = `Stringify du JSON en erreur: ${error.message}`;
          errorHandler(DataSourceValue.TAG, error, 'asFieldDataList');
        }
      }
    }

    if (this.mDicoPropertyNameValues) {
      try {
        const valueStr = JSON.stringify(this.mDicoPropertyNameValues);
        const fd: FieldData = {
          id: '##names',
          fieldType: FieldType.Text,
          label: 'names',
          value: valueStr,
        };

        result.push(fd);
      } catch (error: unknown) {
        if (error instanceof Error) {
          error.message = `Stringify du JSON en erreur pour les mDicoPropertyNameValues: ${error.message}`;
          errorHandler(DataSourceValue.TAG, error, 'asFieldDataList');
        }
      }
    }

    return result;
  };

  private getEntitySchemaAttributeTypes = (value: unknown): EntitySchemaAttributeTypes | never => {
    switch (true) {
      case typeof value === 'boolean':
        return EntitySchemaAttributeTypes.Bool;

      case typeof value === 'number':
        return Number.isInteger(value) ? EntitySchemaAttributeTypes.Int : EntitySchemaAttributeTypes.Decimal;

      case typeof value === 'string':
        const valStr = value as string;
        if (valStr.startsWith('{')) {
          const valParsed = JSON.parse(valStr);

          if ('selectedTargets' in valParsed) return EntitySchemaAttributeTypes.Email;

          if ('label' in valParsed && 'value' in valParsed) return EntitySchemaAttributeTypes.SharedList;
        }

        if (Number.isNaN(Date.parse(valStr))) return EntitySchemaAttributeTypes.String;

        if (valStr.endsWith('T00:00:00+00:00')) return EntitySchemaAttributeTypes.Date;

        return EntitySchemaAttributeTypes.DateTime;

      case typeof value === 'object':
        const objValue: any = value as any;
        if (!Array.isArray(value)) return EntitySchemaAttributeTypes.Email;

        const valArray = objValue as (Choice | HierarchicalChoice)[];

        return valArray.every((val) => 'hierarchicalLabel' in val)
          ? EntitySchemaAttributeTypes.SharedListHierarchical
          : EntitySchemaAttributeTypes.SharedList;

      default:
        throw new Error('Aucun EntitySchemaAttributeType pour la value: ' + value?.toString());
    }
  };
}

export default DataSourceValue;
