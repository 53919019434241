import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionConditionType, DataForComponent } from '../types';
import { UseDataProps } from './types';
import { AddressData } from '90.quickConnect.Models/models';

const useData = (currentValue: AddressData, type: ActionConditionType): UseDataProps => {
  const { t } = useTranslation('declaration');

  const { nameForDataCy, labelForInputLabel, multiline, value } = useMemo(() => {
    switch (type) {
      case ActionConditionType.Street:
        return {
          nameForDataCy: 'street',
          labelForInputLabel: t('formlib_address_road_watermark'),
          multiline: true,
          value: currentValue.street,
        } as DataForComponent;
      case ActionConditionType.StreetNumber:
        return {
          nameForDataCy: 'streetNumber',
          labelForInputLabel: t('formlib_address_num_watermark'),
          multiline: false,
          value: currentValue.streetNumber,
        } as DataForComponent;
      case ActionConditionType.Complement:
        return {
          nameForDataCy: 'complement',
          labelForInputLabel: t('formlib_address_compl_watermark'),
          multiline: true,
          value: currentValue.complement,
        } as DataForComponent;
      case ActionConditionType.ZipCode:
        return {
          nameForDataCy: 'zipCode',
          labelForInputLabel: t(t('formlib_address_zip_code_watermark')),
          multiline: false,
          value: currentValue.zipCode,
        } as DataForComponent;
      case ActionConditionType.City:
        return {
          nameForDataCy: 'city',
          labelForInputLabel: t(t('formlib_address_city_watermark')),
          multiline: false,
          value: currentValue.city,
        } as DataForComponent;
      case ActionConditionType.Country:
        return {
          nameForDataCy: 'country',
          labelForInputLabel: t('formlib_address_country_watermark'),
          multiline: true,
          value: currentValue.country,
        } as DataForComponent;
    }
  }, [type, t, currentValue]);

  // On retourne les valeurs à la vue
  return { t, nameForDataCy, labelForInputLabel, multiline, value };
};
export default useData;
