/**
 * Donne la valeur correspondant a une clé dans un dico ou une defaultValue
 */
export default function findValueInObjectOrDefault(
  input: Record<string, unknown>,
  key: string,
  defaultValue: unknown = undefined,
): unknown {
  if (!(key in input)) return defaultValue;

  return input[key];
}
