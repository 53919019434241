import { FieldType } from '90.quickConnect.Models/enums';
import { BaseQCOperator, FieldDesc, Dependency, CtrlRefOperator } from '90.quickConnect.Models/models';

const getFieldScope = (scopeFullPathId: string) => scopeFullPathId.split('.').slice(0, -1).join('.') + '.';

/** Permet de savoir si l'input de contrôle se situe dans un groupe répétable */
const isRepeatableGroupFieldType = (fields: FieldDesc[]): boolean =>
  fields.some(({ fieldType }: FieldDesc) => fieldType === FieldType.RepeatableGroup);

const curryHasRepeatableGroupInParentScope = (fn: (fields: FieldDesc[]) => boolean) => (fieldScope: string) => {
  const fieldTypeArray = fieldScope.split('.');

  return (flattenFields: FieldDesc[]) => {
    const fields = flattenFields.reduce((acc: FieldDesc[], current: FieldDesc): FieldDesc[] => {
      const fieldToAdd = fieldTypeArray.find((ft: string) => current.id.toUpperCase() === ft.toUpperCase());

      return fieldToAdd && current.fieldType === FieldType.RepeatableGroup ? [...acc, current] : acc;
    }, []);

    return fn(fields);
  };
};

const getRefDependencies = (
  baseQCOperator: BaseQCOperator,
  flattenFields: FieldDesc[],
  scopeFullPathId: string,
): Dependency | undefined => {
  const ctrlRef = baseQCOperator as CtrlRefOperator;
  // Identifier par scope
  const fieldScope = getFieldScope(scopeFullPathId);
  let control;
  if (
    fieldScope.includes('.') &&
    curryHasRepeatableGroupInParentScope(isRepeatableGroupFieldType)(fieldScope)(flattenFields)
  ) {
    control = flattenFields.find(
      (f) =>
        f.fullPathId.toUpperCase() === ctrlRef.controlId.toUpperCase() ||
        (f.id.toUpperCase() === ctrlRef.controlId.toUpperCase() &&
          f.fullPathId.toUpperCase() === fieldScope.toUpperCase() + ctrlRef.controlId.toUpperCase()),
    );

    // Recherche dans les parents
    if (!control) {
      control = flattenFields.find(
        (f) =>
          f.fullPathId.toUpperCase() === ctrlRef.controlId.toUpperCase() ||
          f.id.toUpperCase() === ctrlRef.controlId.toUpperCase(),
      );
    }
  } else {
    control = flattenFields.find(
      (f) =>
        f.fullPathId.toUpperCase() === ctrlRef.controlId.toUpperCase() ||
        f.id.toUpperCase() === ctrlRef.controlId.toUpperCase(),
    );
  }
  if (control) return control;
  return undefined;
};

export default getRefDependencies;
