import DataSourceValue from '30.quickConnect.Stores/helpers/DataSourceValue';
import { AttributeValue, EntityData } from '90.quickConnect.Models/models';

export const mapEquipmentForFormLib = ({
  entitySchemaId,
  id,
  code,
  attributes,
  title,
  createdAt,
}: EntityData): DataSourceValue => {
  const dataSourceValue: DataSourceValue = new DataSourceValue();

  // On remplit les properties
  dataSourceValue.schemaId = entitySchemaId;
  dataSourceValue.instanceId = id;
  dataSourceValue.code = code;
  dataSourceValue.title = title;

  attributes.forEach(({ value, attributeId, attributeLabel }: AttributeValue) => {
    dataSourceValue.setPropertyValue(attributeLabel, attributeId, value);
  });

  dataSourceValue.setPropertyValue('Title', 'title', title);
  dataSourceValue.setPropertyValue('Code', 'code', code);
  dataSourceValue.setPropertyValue('CreateDate', 'createDate', createdAt);

  return dataSourceValue;
};
