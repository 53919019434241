import { useMemo } from 'react';
import { UseDataProps } from './types';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { TreeViewData } from '10.quickConnect.app/components/domain/Home/types';
import { flatten } from '80.quickConnect.Core/helpers';

export const useData = (children: TreeViewData<TreeDataItem>[]): UseDataProps => {
  const nodeChildren = useMemo(() => children.filter((child) => child.data === undefined), [children]);

  const formChildren = useMemo(() => children.filter((child) => !!child.data), [children]);

  const numberFormChildren = useMemo(() => {
    const flattenChildren = flatten(children, (i) => i.items);
    return flattenChildren.filter((child) => !!child.data).length;
  }, [children]);

  return {
    nodeChildren,
    formChildren,
    numberFormChildren,
  };
};
