import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react';
import { Name } from './const';
import { MainBox } from './styles';
import { EntityDataProps } from './types';
import useData from '10.quickConnect.app/components/domain/Equipment/EntityData/hooks';

const EntityData: React.FC<EntityDataProps> = ({ entityData }) => {
  const { t } = useTranslation('equipment');
  const { displayAttributeValues } = useData();
  const scrollTarget = useRef<HTMLElement>();

  return (
    <Box data-cy={Name} sx={MainBox} ref={scrollTarget}>
      <div id={`scrollToAnchor-${Name}`} />
      {entityData.attributes.length > 0 && (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('entityData.attributeLabel')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('entityData.value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entityData.attributes.map((attribute) => (
              <TableRow key={attribute.attributeId}>
                <TableCell>{attribute.attributeLabel}</TableCell>
                <TableCell>{displayAttributeValues(attribute)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {/* {!isMobile && scrollTarget.current && (
        <ScrollTo
          idToScrollTo={`scrollToAnchor-${Name}`}
          target={scrollTarget.current}
          parentRect={scrollTarget.current.getBoundingClientRect()}
        />
      )} */}
    </Box>
  );
};

export default observer(EntityData);
