import { AddressData, Position } from '90.quickConnect.Models/models';
import { isRecordWithProperty } from '90.quickConnect.Models/guards';

export function isPositionCoordinates(input: unknown): input is Position {
  return (
    typeof input === 'object' &&
    input !== null &&
    !Array.isArray(input) &&
    isRecordWithProperty(input, 'longitude', 'number') &&
    isRecordWithProperty(input, 'latitude', 'number') &&
    isRecordWithProperty(input, 'precision', 'number')
  );
}

export function isAddressData(input: unknown): input is AddressData {
  return (
    typeof input === 'object' &&
    input !== null &&
    !Array.isArray(input) &&
    isRecordWithProperty(input, 'streetNumber', 'string') &&
    isRecordWithProperty(input, 'street', 'string') &&
    isRecordWithProperty(input, 'complement', 'string') &&
    isRecordWithProperty(input, 'zipCode', 'string') &&
    isRecordWithProperty(input, 'city', 'string') &&
    isRecordWithProperty(input, 'country', 'string') &&
    (isRecordWithProperty(input, 'coordinates', 'undefined') ||
      isRecordWithProperty(input, 'coordinates', (val) => val === null || isPositionCoordinates(val)))
  );
}
