import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
export const numericLocaleFormatLitteral = (
  nextValue: string | number | DoubleExtension | undefined,
  optionsNumberFormat: Intl.NumberFormatOptions = { maximumFractionDigits: 8, useGrouping: false },
): string => {
  if (nextValue === undefined) return '';
  // Cast Local sur le fait que ca peut etre une chaine de caractère
  const numVal = typeof nextValue === 'string' ? parseNumber(nextValue) : nextValue;

  if (numVal === undefined) return '';
  const intl: Intl.NumberFormat = new Intl.NumberFormat(undefined, optionsNumberFormat);

  const numToLitt = nextValue instanceof DoubleExtension ? Number(numVal) : numVal;

  return intl.format(numToLitt as number);
};
