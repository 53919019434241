import { QCScriptObj } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCScriptObj';

export class QCSInterpreterContext {
  pc: number;

  currentObj: QCScriptObj;

  currentMethodName: string;

  constructor(
    obj: QCScriptObj,
    methodName: string,
    entryPointToCall: number,
    private backupTop: number,
    private backupBp: number,
  ) {
    this.currentObj = obj;
    this.currentMethodName = methodName;
    this.pc = entryPointToCall;
  }

  public getBackupBp(): number {
    return this.backupBp;
  }

  public getBackupTop(): number {
    return this.backupTop;
  }
}
