import { QCSBool } from './QCSBool';
import { QCSBaseTypedObject } from './QCSBaseTypedObject';
import { QCSInt } from './QCSInt';
import { QCSLong } from './QCSLong';
import { QCSString } from './QCSString';
import { DoubleExtension } from '80.quickConnect.Core/formatting/DoubleExtension';
import { QCSBaseObject } from '20.formLib/helpers/QCScriptLib/QuickConnect.QCScript/IL/QCSObject/QCSBaseObject';

export class QCSDouble extends QCSBaseTypedObject<number> {
  public constructor(value: number) {
    // Pb dans le cas des chiffres extrêmement petits, on va réduire à 10 décimales - Un second arrondi sera fait à la mise a jour des valeurs du formulaire
    const dblValue: number = DoubleExtension.decimalFormat10Min1(+value);
    super(dblValue);
    this.value = dblValue;
  }

  public isFalse(): boolean {
    return this.value === 0;
  }

  public multiplyBy(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(this.value * arg1.value);
    if (arg1 instanceof QCSInt) return new QCSDouble(this.value * arg1.value);
    if (arg1 instanceof QCSLong) return new QCSDouble(this.value * Number(arg1.value));
    return QCSDouble.QCSNull;
  }

  public add(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(this.value + arg1.value);
    if (arg1 instanceof QCSInt) return new QCSDouble(this.value + arg1.value);
    if (arg1 instanceof QCSLong) return new QCSDouble(this.value + Number(arg1.value));
    return QCSDouble.QCSNull;
  }

  public sub(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(this.value - arg1.value);
    if (arg1 instanceof QCSInt) return new QCSDouble(this.value - arg1.value);
    if (arg1 instanceof QCSLong) return new QCSDouble(this.value - Number(arg1.value));
    return QCSDouble.QCSNull;
  }

  public div(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(DoubleExtension.customDivide(this.value, arg1.value));
    if (arg1 instanceof QCSInt) return new QCSDouble(DoubleExtension.customDivide(this.value, arg1.value));
    if (arg1 instanceof QCSLong) return new QCSDouble(DoubleExtension.customDivide(this.value, arg1.value));
    return QCSDouble.QCSNull;
  }

  public modulo(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSDouble(DoubleExtension.customModulo(this.value, arg1.value));
    if (arg1 instanceof QCSInt) return new QCSDouble(DoubleExtension.customModulo(this.value, arg1.value));
    if (arg1 instanceof QCSLong) return new QCSDouble(DoubleExtension.customModulo(this.value, arg1.value));
    return QCSDouble.QCSNull;
  }

  public negation(): QCSBaseObject {
    return new QCSDouble(-this.value);
  }

  public isEquals(arg1: QCSBaseObject): boolean {
    if (this === QCSBaseObject.QCSNull || this.value === null)
      return arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null;

    if (arg1 === QCSBaseObject.QCSNull || arg1.getValue() === null) return false;

    switch (true) {
      case arg1 instanceof QCSInt:
      case arg1 instanceof QCSDouble:
        return this.value === arg1.getValue();

      case arg1 instanceof QCSLong:
        return this.value === Number(arg1.getValue());

      default:
        return false;
    }
  }

  public greaterThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value > arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value > arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value > arg1.value);
    return new QCSBool(false);
  }

  public lessThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value < arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value < arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value < arg1.value);
    return new QCSBool(false);
  }

  public greaterEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value >= arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value >= arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value >= arg1.value);
    return new QCSBool(false);
  }

  public lessEqualThan(arg1: QCSBaseObject): QCSBaseObject {
    if (arg1 instanceof QCSDouble) return new QCSBool(this.value <= arg1.value);
    if (arg1 instanceof QCSInt) return new QCSBool(this.value <= arg1.value);
    if (arg1 instanceof QCSLong) return new QCSBool(this.value <= arg1.value);
    return new QCSBool(false);
  }

  public static staticCall(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    return null;
  }

  public callQCSObject(methodId: number, qcParams: Array<QCSBaseObject>): QCSBaseObject | null {
    if (methodId === 1) {
      return new QCSString(this.value.toLocaleString());
    }
    return null;
  }

  static from(qcsBaseObject: QCSBaseObject): QCSBaseObject {
    if (qcsBaseObject == null || qcsBaseObject == QCSBaseObject.QCSNull) return new QCSDouble(0);
    return new QCSDouble(Number(qcsBaseObject.getValue()));
  }
}
