import { AxiosError } from 'axios';
import { action, makeAutoObservable, observable, computed, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';
import { TFunction } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import JSONBig from 'json-bigint';
import { AbortRequestsStore } from '../interfaces';
import ViewStore from '../ViewStore';
import { SignInResponse } from '../LoginStore/Payloads/responses';
import { IndexDocumentRequest, TelemetryLogsRequest } from './Payloads/requests';
import {
  ActionResponse,
  GetDeclarationContextResponse,
  GetFileByRemoteAPIResponse,
  GetIndexDocumentResponse,
  GetRunDeclarationViewerResponse,
  OMSReverseGeocodingResponse,
  SaveInstrumentationResponse,
  SynchronizeDeclarationResponse,
} from './Payloads/responses';
import {
  API_POST_HISTORIQUEDECLARATION,
  API_POST_INSTRUMENTATION,
  API_POST_QC_ACTION,
  API_GET_FORM_CONTEXT_DEEP_LINK,
} from '40.quickConnect.DataAccess/axios/apiRoutes';
import {
  FormEditedData,
  FieldDesc,
  DeclarationViewer,
  KeyValuePair,
  DocumentIndexDTO,
  AttachmentItemData,
  QCAttachment,
  FieldData,
  convertFromFormEditedData,
  ItemData,
  DeclarationContext,
  Position,
  StepDesc,
  LocalBackupDeclaration,
  FormEditedDataDTO,
  TaskEditedDataType,
  QCErrorHTTP,
  NotificationDesc,
  QCNotification,
  EditDesc,
  NotificationTarget,
} from '90.quickConnect.Models/models';
import {
  API_GET_DECLARATIONS,
  API_GET_DOCUMENT_INDEX,
  API_SYNCHRONIZE_DECLARATION,
} from '40.quickConnect.DataAccess/axios/apiRoutes';
import { getDeviceType, flatten, errorHandler } from '80.quickConnect.Core/helpers';
import {
  mapToFieldData,
  mapDeclarationViewer,
  mapDeclarationContext,
  mapToFieldVisibility,
  mapFromQCAttachmentToAttachmentItemData,
  mapDistantUriForOtherStep,
} from '90.quickConnect.Models/mappings';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import declarationsDb from '40.quickConnect.DataAccess/indexedDb/dbs/declarationsDb';
import { APP_NAME, APP_VERSION, FormLibVersion } from 'const';
import getSessionId from '80.quickConnect.Core/helpers/getSessionId';
import { IsAnAttachmentField } from '90.quickConnect.Models/models/fields/fieldDesc/fieldDesc';
import attachmentDb from '40.quickConnect.DataAccess/indexedDb/dbs/attachmentDb';
import {
  DocumentTransferState,
  FieldType,
  FormType,
  InboxItemType,
  InternalFieldState,
  WorkflowStatus,
  WorkflowStepStatus,
} from '90.quickConnect.Models/enums';
import logDb from '40.quickConnect.DataAccess/indexedDb/dbs/logDb';
import { IFieldSearchHistory, fieldSearchHistoryDb } from '40.quickConnect.DataAccess/indexedDb/dbs/historyDb';
import {
  RequestData,
  SearchRequestData,
} from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationHistory/types';
import RootStore from '30.quickConnect.Stores/RootStore';
import { AddressData } from '90.quickConnect.Models/models/fields/values/addressData';
import { OMS_GET_REVERSE_GEOCODING_URI } from '40.quickConnect.DataAccess/axios/openMapStreetApiRoutes';
import { SynchronizeDeclaration } from '40.quickConnect.DataAccess/indexedDb/dbs/queriesTypes/declaration';
import IClientHTTP from '40.quickConnect.DataAccess/ClientHTTP/interface';
import {
  buildCalculatedLabelWithEquipmentTokens,
  buildCalculatedLabelWithFieldTokens,
  buildCalculatedLabelWithSystemRefTokens,
} from '30.quickConnect.Stores/helpers/getValueForCalculateLabel';
import { dateFormatWithMillisecond } from '80.quickConnect.Core/helpers/dateFormat';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';
import DataSourceValue from '30.quickConnect.Stores/helpers/DataSourceValue';
import { isATodoListData, isAnAttachmentItemsData, isFieldDataArray } from '90.quickConnect.Models/guards';
import inboxesDb from '40.quickConnect.DataAccess/indexedDb/dbs/inboxesDb';
import getLRUFullId from '70.quickConnect.Common/LRU';
import { notificationFieldsCheckUnprocessedData } from '50.quickConnect.Fields/FieldsTypes/Inputs/NotificationQcField/helpers';
import { ValidationActionType } from '90.quickConnect.Models/enums/declarations/ValidationActionType';

class DeclarationStore implements AbortRequestsStore {
  // Tag
  private static readonly TAG = '30.quickConnect.Stores/RootStore/DeclarationStore/index.ts';

  sendingDeclaration = false;

  clientHTTP: IClientHTTP;

  // Ne pas mettre dans resetStore
  shouldAbortRequests = false;

  // Flag indiquant que l'utilisateur édite une déclaration - utile lorsqu'il rafraichit
  isEditingCurrentDeclaration = false;

  logger: CustomLogger;

  HistoryStore: ViewStore<DeclarationViewer>;

  DraftsStore: ViewStore<DeclarationViewer>;

  LeftMenuHistoryStore: ViewStore<DeclarationViewer>;

  LeftMenuDraftsStore: ViewStore<DeclarationViewer>;

  requiredField: FieldDesc | undefined = undefined;

  scrollTarget: React.MutableRefObject<HTMLElement | undefined> | undefined;

  verifRequiredField = false;

  RootStore: RootStore;

  isDeletedDeclarationContext = false;

  // Flag indiquant que l'utilisateur ouvre une déclaration nouvelle ou existante.
  isUseDeclarationComponent = false;

  // Flag d'ouverture de la Dialogue de déclaration afin d'enregistrer ou non
  openDeclarationDialog = false;

  // Flag permettant de savoir si la déclaration est editable. Cela va notamment servir pour la fonction goHome dans le hooks de LeftItems
  editableDeclaration = false;

  // Flag pour savoir si le tiroir est ouvert ou pas
  isDrawerOpened = false;

  currentStepWorkFlow: StepDesc | undefined;

  numberDisplayed = 0;

  openConsentPopup = false;

  isMaxAttachment = false;

  backupDclContext: LocalBackupDeclaration[] = [];

  // QCScriptObj
  controllerMethod = '';

  qcsFieldId = '';

  declarationId = '';

  sendAPI = false;

  // Indication pour les pièces jointes
  attachmentsToSend = 0;

  sendingAttachments = false;

  // Deep Link
  isDeepLink = false;

  // InternalData
  internalDataDico: Map<string, FieldData> = new Map<string, FieldData>();

  // focusField
  fieldFocused: string | undefined;

  constructor(rootStore: RootStore, logger: CustomLogger) {
    this.RootStore = rootStore;
    this.clientHTTP = rootStore.clientHTTP;
    this.logger = logger;

    makeAutoObservable(
      this,
      {
        sendingDeclaration: observable,
        shouldAbortRequests: observable,
        numberDisplayed: observable,
        isUseDeclarationComponent: observable,
        openDeclarationDialog: observable,
        isDrawerOpened: observable,
        isEditingCurrentDeclaration: observable,
        openConsentPopup: observable,
        editableDeclaration: observable,
        isDeletedDeclarationContext: observable,
        currentStepWorkFlow: observable,
        isMaxAttachment: observable,
        declarationId: observable,
        sendAPI: observable,
        requiredField: observable,
        scrollTarget: observable,
        verifRequiredField: observable,
        attachmentsToSend: observable,
        sendingAttachments: observable,
        isDeepLink: observable,
        internalDataDico: observable,
        internalData: computed,
        fieldFocused: observable,

        setFieldFocused: action.bound,
        setIsDeepLink: action.bound,
        setAttachmentsToSend: action.bound,
        setSendingAttachments: action.bound,
        setBackupDclContext: action.bound,
        setSendAPI: action.bound,
        setDeclarationStoreId: action.bound,
        setEditableDeclaration: action.bound,
        setIsEditingCurrentDeclaration: action.bound,
        setIsUseDeclarationComponent: action.bound,
        openDialog: action.bound,
        closeDialog: action.bound,
        setOpenDrawer: action.bound,
        setCloseDrawer: action.bound,
        getDeclarationContext: action.bound,
        setShouldAbortRequests: action,
        setSendingDeclaration: action.bound,
        saveDeclarationAsync: action.bound,
        getDeclarationList: action.bound,
        removeLocalDeclaration: action.bound,
        getFileByRemoteAPI: action.bound,
        setCloseConsentPopup: action.bound,
        setOpenConsentPopup: action.bound,
        setIsDeletedDeclarationContext: action.bound,
        setCurrentStepWorkFlow: action.bound,
        setIsMaxAttachment: action.bound,
        setRequiredField: action.bound,
        setScrollTarget: action.bound,
        setVerifRequiredField: action.bound,
        findBackUpsByUserUPNAsync: action.bound,
      },
      { autoBind: true },
    );

    void makePersistable(this, {
      name: 'DeclarationStore',
      properties: ['isEditingCurrentDeclaration', 'editableDeclaration', 'isUseDeclarationComponent'],
      storage: window.localStorage,
    });

    this.HistoryStore = new ViewStore<DeclarationViewer>(this.logger, 'HistoryStore');
    this.DraftsStore = new ViewStore<DeclarationViewer>(this.logger, 'DraftsStore');

    this.LeftMenuHistoryStore = new ViewStore<DeclarationViewer>(this.logger, 'LeftMenuHistoryStore');
    this.LeftMenuDraftsStore = new ViewStore<DeclarationViewer>(this.logger, 'LeftMenuDraftsStore');
  }

  get internalData(): FieldData[] {
    if (this.internalDataDico.size === 0) return [];
    return Array.from(this.internalDataDico.values());
  }

  setFieldFocused = (path: string | undefined): void => {
    this.fieldFocused = path;
  };

  setIsDeepLink = (isDeepLink: boolean): void => {
    this.isDeepLink = isDeepLink;
  };

  setSendingAttachments = (isSendingAttachements: boolean): void => {
    this.sendingAttachments = isSendingAttachements;
  };

  setAttachmentsToSend = (newTotalAttachmentToSend: number): void => {
    this.attachmentsToSend = newTotalAttachmentToSend;
  };

  setBackupDclContext = (backupDclContext: LocalBackupDeclaration[]): void => {
    this.backupDclContext = backupDclContext;
  };

  setSendAPI = (sendAPI: boolean): void => {
    this.sendAPI = sendAPI;
  };

  setDeclarationStoreId = (declarationId: string): void => {
    this.declarationId = declarationId;
  };

  setScrollTarget = (target: React.MutableRefObject<HTMLElement | undefined> | undefined) => {
    this.scrollTarget = target;
  };

  setVerifRequiredField = (flag: boolean) => {
    this.verifRequiredField = flag;
  };

  setRequiredField = (field: FieldDesc | undefined) => {
    this.requiredField = field;
  };

  setIsMaxAttachment = (data: boolean) => {
    this.isMaxAttachment = data;
  };

  setIsDeletedDeclarationContext = (status: boolean): void => {
    this.isDeletedDeclarationContext = status;
  };

  setCurrentStepWorkFlow = (data: StepDesc) => {
    this.currentStepWorkFlow = data;
    this.editableDeclaration = true;
  };

  setEditableDeclaration = (editableDeclaration: boolean): void => {
    this.editableDeclaration = editableDeclaration;
  };

  setSendingDeclaration = (sendingDeclaration: boolean) => (this.sendingDeclaration = sendingDeclaration);

  setIsUseDeclarationComponent = (isUseDeclarationComponent: boolean) =>
    (this.isUseDeclarationComponent = isUseDeclarationComponent);

  resetStore = (): void => {
    this.sendingDeclaration = false;
    this.isUseDeclarationComponent = false;
    this.openDeclarationDialog = false;
    this.isDrawerOpened = false;
    this.numberDisplayed = 0;
    this.editableDeclaration = false;
    this.DraftsStore.reset();
    this.HistoryStore.reset();
    this.LeftMenuDraftsStore.reset();
    this.LeftMenuHistoryStore.reset();
    this.backupDclContext = [];
    this.internalDataDico = new Map<string, FieldData>();
  };

  setOpenDrawer = () => {
    this.isDrawerOpened = true;
  };

  setCloseDrawer = () => {
    this.isDrawerOpened = false;
  };

  setOpenConsentPopup = () => {
    this.openConsentPopup = true;
  };

  setCloseConsentPopup = () => {
    this.openConsentPopup = false;
  };

  setIsEditingCurrentDeclaration = (isEditingCurrentDeclaration: boolean): boolean =>
    (this.isEditingCurrentDeclaration = isEditingCurrentDeclaration);

  openDialog = () => (this.openDeclarationDialog = true);

  closeDialog = () => (this.openDeclarationDialog = false);

  setShouldAbortRequests = (shouldAbortRequests: boolean) => (this.shouldAbortRequests = shouldAbortRequests);

  // Permet d'obetnir la liste des médias à partir d'editedData
  findMediasType(fieldData: FieldData): AttachmentItemData[] {
    const { value } = fieldData;

    // Est-ce que le fieldData est un attachmentItemData?
    if (isAnAttachmentItemsData(value)) return value as AttachmentItemData[];

    // Est-ce que le fieldData provient du TodoList?
    if (isATodoListData(value) && value.taskEditedData.length > 0) {
      return value.taskEditedData
        .filter((x: TaskEditedDataType | undefined) => x !== undefined)
        .reduce(
          (acc: AttachmentItemData[], { attachment }: TaskEditedDataType) => (attachment ? [...acc, attachment] : acc),
          [],
        );
    }
    // Est-ce que c'est un container??
    if (isFieldDataArray(fieldData, value))
      return value.reduce((acc: AttachmentItemData[], current: FieldData) => {
        const result = this.findMediasType(current);
        return result.length > 0 ? [...acc, ...result] : acc;
      }, []);

    // Sinon on retourne []
    return [];
  }

  findFieldInGroup = (obj: FieldDesc, fieldId: string): FieldDesc => {
    if (obj.id === fieldId) {
      return obj;
    }
    for (const i in obj.items) {
      const x = this.findFieldInGroup(obj.items[i], fieldId);
      if (x.id === fieldId) {
        return obj.items[i];
      }
    }
    return obj;
  };

  /**
   * Permet de récupérer une adresse depuis des coordonnées GPS.
   * @param {Position} coordinates
   */
  startReverseGeocodingAsync = async (coordinates: Position): Promise<AddressData | undefined> => {
    try {
      this.logger.resetInitDateTimeApp();
      const { latitude, longitude } = coordinates;

      if (!latitude || !longitude) return undefined;

      // Construction de la requête reverseGeocoding
      const reverseGeocodingURL = OMS_GET_REVERSE_GEOCODING_URI.replace(':latitude', latitude.toString()).replace(
        ':longitude',
        longitude.toString(),
      );

      const reverseGeocodingResponse = await this.clientHTTP.get<OMSReverseGeocodingResponse>(reverseGeocodingURL);

      if (reverseGeocodingResponse.status !== 200 || !reverseGeocodingResponse.data.address) return undefined;

      const { address } = reverseGeocodingResponse.data;

      return {
        streetNumber: address.house_number ?? '',
        street: address.road ?? '',
        zipCode: address.postcode?.toString() ?? '',
        city: address.suburb ?? address.town ?? address.municipality ?? '',
        complement: '',
        country: address.country ?? '',
        coordinates,
      } as AddressData;
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'startReverseGeocodinAsync');
    }
  };

  getIndexDocumentDTO = async (files: QCAttachment[]): Promise<boolean> => {
    const documentDTO: DocumentIndexDTO[] = [];
    type HashMap = {
      [correlation: string]: QCAttachment;
    };
    const hashMap: HashMap = {};
    //Création des documentDTO pour indexation
    files.map((item) => {
      const documentToAdd = {
        ApplicationName: APP_NAME,
        DocName: item.docName,
        DocContentType: item.docContentType,
        DocTitle: item.docTitle,
        DocCreateDate: item.docCreateDate,
        PurgeDate: item.purgeDate,
        //metadata: item.metadata,
        CorrelationId: item.id,
        Id: item.id,
      } as DocumentIndexDTO;
      hashMap[item.id] = item;
      documentDTO.push(documentToAdd);
    });
    //Création de la request
    const request = {
      NewsDocumentsIndex: documentDTO,
    } as IndexDocumentRequest;
    try {
      if (!this.shouldAbortRequests) {
        const response = await this.clientHTTP.post<GetIndexDocumentResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_GET_DOCUMENT_INDEX),
          request,
          {
            withCredentials: true,
          },
        );
        if (200 <= response.status && response.status <= 299) {
          const { storagesInfos } = response.data;
          const toUpdate: QCAttachment[] = [];
          storagesInfos.forEach((storageInfo) => {
            if (hashMap[storageInfo.correlationId]) {
              //Mise à jour des distantUri à partir du correlationId
              hashMap[storageInfo.correlationId].distantUri = storageInfo.uri;
              hashMap[storageInfo.correlationId].transferState = DocumentTransferState.ToBeTransfered;
              this.logger.log(
                DeclarationStore.TAG,
                `INDEX_DOC_OK - CorrelationId: ${storageInfo.correlationId} - distantUrl: ${storageInfo.uri}`,
              );
              toUpdate.push(hashMap[storageInfo.correlationId]);
            }
          });

          if (toUpdate) {
            //Sauvegarde en BDD des modifications
            await attachmentDb.updateAttachments(toUpdate);
            //Mise à jour des distantUris des attachment dans les déclarations
            await this.updateDeclarationWithStorageUri(toUpdate);
          }
          return true;
        } else {
          throw new Error(`INDEX_DOC_ERR - Statut code ${response.status}`);
        }
      }
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'getIndexDocumentDTO');
    }
    return false;
  };

  getDeclarationList = async (userUPN: string): Promise<void> => {
    try {
      if (!this.shouldAbortRequests) {
        const getDeclListUri = this.RootStore.CommonStore.chooseBaseUrl(
          API_GET_DECLARATIONS.replace(':forThisUPN', userUPN),
        );
        const response = await this.clientHTTP.get<GetRunDeclarationViewerResponse>(getDeclListUri, {
          withCredentials: true,
        });
        // Historique
        const declarations = response.data.historic.map((d) => mapDeclarationViewer(d, false));
        // On ordonne par date décroissante
        const uniqueDeclarations = declarations
          .slice()
          .filter((d: DeclarationViewer) => d.folderPath !== 'unclassified')
          .sort((a: DeclarationViewer, b: DeclarationViewer) => {
            return b.editedAt && a.editedAt ? new Date(b.editedAt).getTime() - new Date(a.editedAt).getTime() : -1;
          });
        const sortedByDateDeclarations = uniqueDeclarations.filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id),
        );
        this.HistoryStore.setItems(sortedByDateDeclarations);
        this.LeftMenuHistoryStore.setItems(sortedByDateDeclarations);

        // Brouillons
        // const apiDrafts = response.data.drafts.map((d) => mapDeclarationViewer(d, false));

        const localDrafts = await declarationsDb.getLocalDrafts(userUPN, this.logger);
        const mappedLocalDrafts = localDrafts.map((d) => mapDeclarationViewer(d, true));
        const drafts = mappedLocalDrafts;

        const uniqueDrafts = drafts.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
        // On ordonne par date décroissante
        const sortedByDateDrafts = uniqueDrafts.slice().sort((a, b) => {
          return b.editedAt && a.editedAt ? new Date(b.editedAt).getTime() - new Date(a.editedAt).getTime() : -1;
        });
        this.DraftsStore.setItems(sortedByDateDrafts);
        this.LeftMenuDraftsStore.setItems(sortedByDateDrafts);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetRunDeclarationViewerResponse>;
        errorAxios.message = `Une erreur est survenue lors de la récupération des formulaires pour cet userUPN ${userUPN}: 
         ${errorAxios?.response?.data?.message ?? errorAxios?.response?.statusText ?? errorAxios.message}`;
        errorHandler(DeclarationStore.TAG, errorAxios, 'getDeclarationList', 'error');
      } else if (error instanceof Error) {
        error.message = `Une erreur est survenue lors de la récupération des formulaires pour cet userUPN ${userUPN} : ${error.message}`;
        errorHandler(DeclarationStore.TAG, error, 'getDeclarationList', 'error');
      }
    }
  };

  clearDraftsAndHistoricItems = () => {
    this.DraftsStore.reset();
    this.LeftMenuDraftsStore.reset();
    this.HistoryStore.reset();
    this.LeftMenuHistoryStore.reset();
  };

  handleWrittingHistory = (declaration: FieldDesc[]) => {
    flatten(declaration, (i) => i.items)
      .filter((i) => i.lruCount)
      .forEach(async (x: FieldDesc) => {
        if (x.lruCount) {
          const lru = await fieldSearchHistoryDb.getFieldHistoryById(x.id);

          if (x.fieldType === FieldType.Notification) {
            const { value, fullPathId } = x as NotificationDesc;
            if (value) {
              try {
                const { selectedTargets } = value as QCNotification;
                if (selectedTargets.length > 0) {
                  const description = [
                    ...(lru?.description ?? []),
                    ...selectedTargets.map((nt: NotificationTarget) => nt.target),
                  ];
                  const {
                    LoginStore: {
                      signInInfos: { userUPN },
                    },
                  } = this.RootStore;
                  const objToModify: IFieldSearchHistory = {
                    description,
                    id: getLRUFullId(declaration, fullPathId.split('.')),
                    userUPN,
                  };
                  await fieldSearchHistoryDb.modifyFieldHistory(objToModify);
                }
              } catch (error) {
                // eslint-disable-next-line no-empty
              }
            }
          } else if (x.fieldType === FieldType.Text) {
            const { value, fullPathId } = x as EditDesc;

            if (value && typeof value === 'string' && value !== '') {
              const description = [...(lru?.description ?? []), value];
              const {
                LoginStore: {
                  signInInfos: { userUPN },
                },
              } = this.RootStore;
              const objToModify: IFieldSearchHistory = {
                description,
                id: getLRUFullId(declaration, fullPathId.split('.')),
                userUPN,
              };
              await fieldSearchHistoryDb.modifyFieldHistory(objToModify);
            }
          }
        }
      });
  };

  /**
   * Getion du libellé calculé
   * @param declaration
   * @param stringExtract
   * @param signInInfos
   * @param context
   * @returns
   */
  static async calculateLabel(
    declaration: FieldDesc[],
    stringExtract: string,
    signInInfos: SignInResponse,
    context: DeclarationContext,
  ) {
    /**
     * Pattern RegEx pour la découverte des tokens dans le libellé calculé
     */
    const sPatternSystemRefForStringExtract = new RegExp('SystemRef\\s*[(]\\s*(\\w+)\\s*[)]', 'g');
    const sPatternEquipementForStringExtract = new RegExp('\\[\\s*([^\\]:\\s]*)\\s*[:]*\\s*(\\s*\\S*\\s*)\\]', 'g');
    const sPatternFieldForStringExtract = new RegExp(
      '\\{\\s*(?<fieldId>[^\\}:\\s]*)\\s*[:]*\\s*(?<format>\\s*\\d*[.,]*\\d*\\s*)\\}',
      'mg',
    );

    let labelValue = stringExtract;
    if (stringExtract !== undefined) {
      const fieldTokens = [...stringExtract.matchAll(sPatternFieldForStringExtract)];
      const userInfoTokens = stringExtract.match(sPatternSystemRefForStringExtract);
      const equipementTokens = stringExtract.match(sPatternEquipementForStringExtract);

      if (equipementTokens) {
        labelValue = await buildCalculatedLabelWithEquipmentTokens(
          labelValue,
          equipementTokens,
          context.entityInstanceId,
        );
      }

      if (fieldTokens) {
        labelValue = buildCalculatedLabelWithFieldTokens(labelValue, fieldTokens, declaration);
      }

      if (userInfoTokens) {
        labelValue = buildCalculatedLabelWithSystemRefTokens(labelValue, userInfoTokens, signInInfos);
      }

      return labelValue;
    }
    return context.name;
  }

  saveDeclarationAsync = async (
    context: DeclarationContext,
    declaration: FieldDesc[],
    userUpn: string,
    organizationalUnitName: string,
    t: TFunction,
    isDraft: boolean,
    itemData: ItemData | undefined,
    activityId: string | undefined,
    inboxId: string | undefined,
    isOnline: boolean,
    declarationId: string,
    signInInfos: SignInResponse,
    sendAPI?: boolean | undefined,
  ): Promise<boolean> => {
    try {
      if (declaration) {
        this.handleWrittingHistory(declaration);
      }
      if (context) {
        // RAZ de l'horodatage
        this.logger.resetInitDateTimeApp();
        this.logger.trace(DeclarationStore.TAG, 'Début de la sauvegarde de la déclaration');

        const { language } = window.navigator;
        const languageCode = language.substring(0, 2);

        // Est-ce que la déclaration possède des PJ
        const attachmentsFile: AttachmentItemData[] = (
          await attachmentDb.getAttachmentItemDataByDeclarationId(declarationId)
        ).map(mapFromQCAttachmentToAttachmentItemData);
        // Modification de la declarationId pour les attachments
        const indexationFlag = sendAPI === true && isDraft === false;
        if (attachmentsFile.length > 0)
          if (context.formType === FormType.Workflow) {
            // Attention dans les cas des Wkf, il faut s'assurer d'envoyer seulement celles de l'étape courante...
            const currentStepId = itemData?.Workflow?.currentStep;
            if (!currentStepId) throw new Error(`Pas de currentStepId pour ce workflow: ${context.name}`);

            const currentStepField = declaration.filter(
              ({ id }: FieldDesc) => id.localeCompare(currentStepId, undefined, { sensitivity: 'base' }) === 0,
            );

            if (currentStepField.length === 0)
              throw new Error(`Pas de currentStepField pour ce workflow: ${context.name}`);

            const attachIdsToSend =
              flatten(currentStepField, (i) => i.items)
                .filter(
                  ({ fieldType, value }: FieldDesc) => IsAnAttachmentField(fieldType) && isAnAttachmentItemsData(value),
                )
                .reduce((acc: string[], current: FieldDesc) => {
                  return [...acc, ...(current.value as AttachmentItemData[]).map(({ id }: AttachmentItemData) => id)];
                }, []) ?? [];
            await attachmentDb.updateAttachmentItemDataForWorkFlow(
              attachmentsFile,
              declarationId,
              indexationFlag,
              attachIdsToSend,
            );
          } else {
            await attachmentDb.updateDeclIdForAttachment(attachmentsFile, declarationId, indexationFlag);
          }

        // Calcul du champ calculé s'il en existe un
        const stringExtractDisplayed = await DeclarationStore.calculateLabel(
          declaration,
          context.stringExtract,
          signInInfos,
          context,
        );

        const editedData = await this.getEditedData(
          declarationId,
          context,
          userUpn,
          declaration,
          attachmentsFile,
          organizationalUnitName,
          languageCode,
          itemData,
          activityId,
          inboxId,
          isDraft,
          sendAPI,
          t,
          stringExtractDisplayed,
        );

        // Si il y a internet et qu'on n'est pas dans une sauvegarde automatique en local, on envoie la déclaration à l'API
        if (sendAPI && !isDraft) {
          editedData.stringExtract = (await DeclarationStore.calculateLabel(
            declaration,
            context.stringExtract,
            signInInfos,
            context,
          )) as string;
          // On enregistre d'abord en base locale

          // On se servira du customerName comme référence (avant c'était la base uri mais cet observable n'est pas toujours set)
          const { urlToUse } = this.RootStore.LoginStore;
          const { getRGPDConsentsToSend } = this.RootStore.ConsentStore;
          const synchronizeDeclarationQuery = {
            userUPN: userUpn,
            imei: getSessionId(),
            userAgentDevice: window.navigator.userAgent,
            typeDevice: getDeviceType(),
            applicationName: APP_NAME,
            appVersion: APP_VERSION,
            qcLibVersion: FormLibVersion,
            consentsRGPD: await getRGPDConsentsToSend(),
            languageCode: languageCode,
            editedData: [convertFromFormEditedData(editedData)],
            customerId: urlToUse,
            transferState: DocumentTransferState.ToBeTransfered,
          } as SynchronizeDeclaration;
          const declarationSaved = await declarationsDb.addDeclarationToUpload(synchronizeDeclarationQuery);

          if (declarationSaved) {
            // Suppression de la déclaration dans la base locale
            await declarationsDb.deleteLocalBackupAsync(this.logger, context.formId, userUpn);

            this.setBackupDclContext(
              this.backupDclContext.filter(
                ({ formId, userUPN }: LocalBackupDeclaration) => formId !== context.formId && userUPN !== userUpn,
              ),
            );
            await declarationsDb.removeDraftDeclarationById(declarationId);
            this.DraftsStore.removeItem(declarationId);
            this.LeftMenuDraftsStore.removeItem(declarationId);

            if (
              context.formType === FormType.Workflow &&
              itemData?.Workflow?.currentWorkflowState === WorkflowStatus.ToForward
            ) {
              // On fake l'itemData pour eviter de sauter les etapes...
              const contextWithEditedData: DeclarationContext = {
                ...context,
                id: declarationId,
                itemData: itemData,
                editedData: editedData.editedData,
                stringExtract: editedData.stringExtract,
                internalData: editedData.internalData,
              };
              await declarationsDb.insertBackUpDeclarationContext(contextWithEditedData, userUpn, this.logger);

              return true;
            }

            if (!(await declarationsDb.deleteListItemsDeclaration(declarationId))) {
              throw new Error(
                `La suppression de la liste locale des elements de la déclaration a échoué pour la déclaration ${declarationId}`,
              );
            }
            this.setIsEditingCurrentDeclaration(false);
            this.setEditableDeclaration(false);
            this.RootStore.QCScriptStore.resetStore();
            this.RootStore.ConsentStore.resetStore();
            this.resetStore();
            this.setSendingDeclaration(true);
            this.setIsMaxAttachment(false);
            this.setSendAPI(true);
            this.internalDataDico = new Map<string, FieldData>();
            const infoMsg = t('qcapp_save_declaration_success', { name: editedData.stringExtract });
            toast.success(infoMsg);
            return true;
          } else {
            t('synchronizeDeclaration.errorWithMsg', {
              msg: `Statut code`,
            });
            return false;
          }
        }
        // sinon sauvegarde en BDD de la déclaration
        else {
          if (isDraft) {
            await this.saveInLocalDraftDeclarationsTable(editedData, context, itemData, declarationId);
            toast.success(t('pause_declaration_success', { name: editedData.stringExtractDisplayed }).toString());

            this.setIsUseDeclarationComponent(false);
            this.setIsEditingCurrentDeclaration(false);
            this.setEditableDeclaration(false);
            this.RootStore.QCScriptStore.resetStore();
            this.RootStore.ConsentStore.resetStore();
            this.resetStore();
            this.internalDataDico = new Map<string, FieldData>();

            return true;
          } else {
            try {
              let newItemData = context.itemData;
              if (
                context.formType === FormType.Workflow &&
                itemData?.Workflow?.currentWorkflowState === WorkflowStatus.ToForward
              ) {
                // On fake l'itemData pour eviter de sauter les etapes...
                newItemData = {
                  ...itemData,
                  Workflow: { ...itemData.Workflow, currentWorkflowState: WorkflowStatus.None },
                };
              }
              const newContext = { ...context, itemData: newItemData };
              const contextWithEditedData: DeclarationContext = {
                ...newContext,
                id: declarationId,
                itemData: newItemData,
                editedData: editedData.editedData,
                stringExtract: editedData.stringExtract,
                internalData: editedData.internalData,
              };
              await declarationsDb.insertBackUpDeclarationContext(contextWithEditedData, userUpn, this.logger);
            } catch (error) {
              if (error instanceof Error) {
                error.message = `Sauvegarde dans l'indexedDB a échoué: ${error.message}`;
                errorHandler(DeclarationStore.TAG, error, 'saveDeclarationAsync', 'error');
              }
            }
          }
        }
      }
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'saveDeclarationAsync', 'error');

      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<any>;
        const errorMsg = `${errorAxios?.response?.data?.message}`;
        toast.error(
          t('qcapp_warning_service_send_action_error', {
            msg: errorMsg,
          }).toString(),
        );
      } else if (error instanceof Error) {
        const errorMsg = error.message;
        toast.error(
          t('qcapp_warning_service_send_action_error', {
            msg: errorMsg,
          }).toString(),
        );
      }
    }
    this.setSendingDeclaration(false);
    return false;
  };

  saveAsDraftDeclarationAsync = async (): Promise<void> => {
    if (this.backupDclContext.length === 0) return;

    const {
      signInInfos: { userUPN },
    } = this.RootStore.LoginStore;

    const dclListItems: DeclarationViewer[] = this.backupDclContext.map((backDcl: LocalBackupDeclaration) => ({
      id: backDcl.id!,
      editedAt: new Date(),
      name: backDcl.name,
      formId: backDcl.formId,
      formType: backDcl.formType,
      folderPath: backDcl.folderPath,
      stringExtract: backDcl.stringExtract,
      isDraft: true,
      isLocal: true,
    }));

    const dclstoSavePlainObj = toJS(this.backupDclContext);

    await declarationsDb.declarationListItems.bulkPut(toJS(dclListItems));

    const uniqueListItemDcls = [...(this.DraftsStore.items ?? []), ...dclListItems]
      .filter((d: DeclarationViewer) => d.folderPath !== 'unclassified')
      .sort((a: DeclarationViewer, b: DeclarationViewer) => {
        return b.editedAt && a.editedAt ? new Date(b.editedAt).getTime() - new Date(a.editedAt).getTime() : -1;
      })
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    this.DraftsStore.setItems(uniqueListItemDcls);
    this.LeftMenuDraftsStore.setItems(uniqueListItemDcls);

    const dclsToSave = dclstoSavePlainObj.map((dcl: LocalBackupDeclaration) => ({
      ...dcl,
      isDraft: true,
    }));

    await declarationsDb.draftDeclarations.bulkPut(dclsToSave);

    await this.deleteLocalBackups(userUPN);

    this.setBackupDclContext([]);
  };

  /**
   * Sauvegarde la déclaration dans la table DraftDeclarations
   * @param editedData
   * @param context
   * @param declarationId
   */
  saveInLocalDraftDeclarationsTable = async (
    editedData: FormEditedData,
    context: DeclarationContext,
    itemData?: ItemData,
    declarationId?: string,
  ): Promise<void | undefined> => {
    try {
      // Sauvegarde dans la table draftDeclarations
      editedData.isDraft = true;

      context.isDraft = true;
      context.editedData = editedData.editedData;
      context.id = declarationId;
      context.itemData = itemData;

      // Internal Data
      context.internalData = editedData.internalData;

      const localDraftDeclaration: LocalBackupDeclaration = {
        ...context,
        userUPN: editedData.userUpn,
      };

      if (declarationId) {
        const draftedDeclaration = await declarationsDb.draftDeclarations.get(declarationId);

        if (draftedDeclaration) {
          await declarationsDb.draftDeclarations.put(localDraftDeclaration, declarationId);
          const declarationListItems = await declarationsDb.declarationListItems.get(declarationId);
          await declarationsDb.declarationListItems.put(
            {
              ...declarationListItems,
              editedAt: new Date(),
            } as DeclarationViewer,
            declarationId,
          );
        } else {
          await declarationsDb.draftDeclarations.add({ ...localDraftDeclaration, editedAt: new Date() }, declarationId);
        }
        const { name, formId, folderPath, formType } = context;
        const { stringExtract, userUpn } = editedData;

        // Vérifions s'il existe aussi dans la table listItems
        const existinglistItemsDeclaration = await declarationsDb.declarationListItems.get(declarationId);

        if (existinglistItemsDeclaration) {
          await declarationsDb.declarationListItems.put(
            {
              ...existinglistItemsDeclaration,
              isDraft: true,
              editedAt: new Date(),
            } as DeclarationViewer,
            declarationId,
          );
        } else {
          // On sauvegarde l'élément de la liste
          await declarationsDb.declarationListItems.add({
            id: declarationId,
            name,
            editedAt: new Date(),
            formId,
            formType,
            folderPath,
            stringExtract,
            isDraft: true,
            isLocal: true,
          } as DeclarationViewer);
        }

        await declarationsDb.backupDeclarationContext
          .where({
            formId,
            userUPN: userUpn,
          })
          .delete();

        this.setBackupDclContext(
          this.backupDclContext.filter(
            ({ formId: formIdLoc, userUPN }: LocalBackupDeclaration) =>
              formIdLoc !== context.formId && userUPN !== userUpn,
          ),
        );
      }
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'saveInLocalDraftDeclarationsTable');
    }
  };

  getFileByRemoteAPI = async (distantUri: string, filename: string, type: string): Promise<File | undefined> => {
    try {
      const response = await this.clientHTTP.get<GetFileByRemoteAPIResponse>(distantUri, {
        responseType: 'blob',
        withCredentials: true,
      });

      if (response.status <= 200 && response.status < 299) {
        const file = new File([response.data], filename, { type });

        return file;
      }
    } catch (error) {
      const errorAxios = error as AxiosError<unknown>;
      if (errorAxios.response?.status === 404) {
        return;
      } else {
        errorHandler(DeclarationStore.TAG, error, 'getFileByRemoteAPI');
      }
    }
  };

  getDeclarationContext = async (
    url: string,
    declarationId: string | undefined,
    entityInstanceId?: string,
    isInbox?: boolean,
  ): Promise<DeclarationContext | undefined> => {
    try {
      if (!this.shouldAbortRequests) {
        const response = await this.clientHTTP.get<GetDeclarationContextResponse>(url, { withCredentials: true });

        if (!response.data.declarationContext) return;
        const mapped = mapDeclarationContext(response.data.declarationContext, entityInstanceId, isInbox);
        // Si la donnée n'est pas côté serveur on check coté bdd locale
        if (!mapped.editedData && declarationId) {
          const data = await declarationsDb.declarations.get(declarationId);
          if (data) {
            mapped.id = data.id;
            mapped.editedData = data.editedData;
            mapped.activityId = data.activityId;
            mapped.entityInstanceId = data.entityInstanceId;
          }
        }

        // On indique qu'on ouvre une déclaration nouvelle ou existante.
        return mapped;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorAxios = error as AxiosError<GetDeclarationContextResponse>;
        errorAxios.message = `Une erreur est survenue lors de la récupération du formulaire : ${errorAxios?.response?.data?.status}  ${errorAxios?.response?.data?.message}`;
        errorHandler(DeclarationStore.TAG, errorAxios, 'getDeclarationContext');
      } else if (error instanceof Error) {
        error.message = `Une erreur est survenue lors de la récupération du formulaire : ${error.message}`;
        errorHandler(DeclarationStore.TAG, error, 'getDeclarationContext');
      }
      this.setIsDeletedDeclarationContext(true);
    }
  };

  getFormContext = async (
    url: string,
    formId: string,
    userUPN: string,
    entityInstanceId?: string,
  ): Promise<DeclarationContext | undefined> => {
    try {
      if (!this.shouldAbortRequests) {
        // Vérifions d'abord s'il existe pas une déclaration enregistrée automatiquement en base locale

        let mapped: DeclarationContext;

        const contextBackup = await declarationsDb.getBackUpDeclarationContext(formId, userUPN, this.logger);

        if (contextBackup) {
          const { userUPN: declarationUserUPN, ...declarationContext } = contextBackup;

          mapped = declarationContext;
        } else {
          const response = await this.clientHTTP.get<GetDeclarationContextResponse>(url, { withCredentials: true });

          if (!response.data.declarationContext) return;

          mapped = mapDeclarationContext(response.data.declarationContext, entityInstanceId);
        }

        // On indique qu'on ouvre une déclaration nouvelle ou existante.
        return mapped;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<GetDeclarationContextResponse>;
        axiosError.message = `Une erreur est survenue lors de la récupération du formulaire : ${axiosError?.response?.data?.status}  ${axiosError?.response?.data?.message}`;
        errorHandler(DeclarationStore.TAG, error, 'getFormContext', 'error');
      } else if (error instanceof Error) {
        error.message = `Une erreur est survenue lors de la récupération du formulaire : ${error.message}`;
        errorHandler(DeclarationStore.TAG, error, 'getFormContext', 'error');
      }
    }
  };

  getFormContextByFormNameAsync = async (params: URLSearchParams): Promise<DeclarationContext | undefined> => {
    try {
      if (this.shouldAbortRequests) return;

      const formName: string = params.get('formname')!;
      const exchangeId = params.get('exchangeid');
      const exchangeData = params.get('exchangedata');

      const {
        CommonStore: { chooseBaseUrl },
      } = this.RootStore;
      const deepLinkUrl: string = exchangeId
        ? chooseBaseUrl(API_GET_FORM_CONTEXT_DEEP_LINK)
            .replace(':formName', formName)
            .replace(':exchangeId', exchangeId)
        : chooseBaseUrl(API_GET_FORM_CONTEXT_DEEP_LINK).replace(':formName/:exchangeId', formName);

      const getFormContextDeepLinkResponse = await this.clientHTTP.get<GetDeclarationContextResponse>(deepLinkUrl, {
        withCredentials: true,
      });

      if (!getFormContextDeepLinkResponse.data.declarationContext)
        throw new Error(`no declarationContext data provided`);

      if (exchangeData && !exchangeId) {
        getFormContextDeepLinkResponse.data.declarationContext.formContextDto.injectedData = exchangeData;
      }

      return mapDeclarationContext(getFormContextDeepLinkResponse.data.declarationContext, undefined);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw error;
      }

      throw new Error(`Unknown Error`);
    }
  };

  removeLocalDeclaration = async (
    declarationId: string,
    deleteInDeclarationTable?: boolean,
    formId?: string,
    userUPN?: string,
  ): Promise<void> => {
    try {
      if (deleteInDeclarationTable && declarationId) {
        const deletedRecordDeclaration = await declarationsDb.removeDeclaration(this.logger, declarationId);

        if (deletedRecordDeclaration)
          this.logger.log(
            DeclarationStore.TAG,
            `${deletedRecordDeclaration} déclarations de la table locale déclarations ont été supprimées`,
          );

        // Suppression en table des brouillons
        const deletedDraftsDcls = await declarationsDb.removeDraftDeclarationById(declarationId);
      }

      if (formId && userUPN) {
        if (deleteInDeclarationTable && declarationId) {
          const deletedRecordBackupDeclaration = await declarationsDb.removeBackupDeclaration(
            this.logger,
            formId,
            userUPN,
          );

          if (deletedRecordBackupDeclaration) {
            this.setBackupDclContext(
              this.backupDclContext.filter(
                ({ formId: formIdLoc, userUPN: userUPNLoc }: LocalBackupDeclaration) =>
                  formId !== formIdLoc && userUPN !== userUPNLoc,
              ),
            );

            this.logger.log(
              DeclarationStore.TAG,
              `${deletedRecordBackupDeclaration} déclarations de la table locale backupDeclarationContext ont été supprimées`,
            );
          }
        }
      }

      if (this.DraftsStore.items && this.DraftsStore.items.length > 0) {
        const newDraftsLists = this.DraftsStore.items.filter(
          (declarationViewer: DeclarationViewer): boolean => declarationViewer.id !== declarationId,
        );
        this.DraftsStore.setItems(newDraftsLists);
      }
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'removeLocalDeclaration');
    }
  };

  private updateDeclarationWithStorageUri = async (attachments: QCAttachment[]) => {
    const dicoDeclaration: KeyValuePair<string, FormEditedData>[] = [];
    const dicoAttachment: KeyValuePair<string, QCAttachment[]>[] = [];

    attachments.forEach(async (attach) => {
      //Récupération de toutes les déclarations à mettre à jour
      const decl = await declarationsDb.getDeclaration(attach.declarationId);
      if (decl != undefined) {
        dicoDeclaration.push({ key: attach.declarationId, value: decl });
      }

      //Ajout dans la hashmap dicoAttachment
      const value = dicoAttachment.filter((item) => item.key === attach.declarationId);
      if (value.length > 0) {
        value[0].value.push(attach);
      } else {
        const attachByDecl = [];
        attachByDecl.push(attach);
        dicoAttachment.push({ key: attach.declarationId, value: attachByDecl });
      }
    });

    //Traitement par déclaration
    const declarationsToUpdate: FormEditedData[] = [];
    dicoDeclaration.map((item) => {
      const attachDataString = item.value.attachmentData ?? '';
      // Dé sérialise les attachmentsItemData comme un dico
      const attachData = new Map<string, AttachmentItemData>(JSON.parse(attachDataString));
      const listAttach = dicoAttachment.find((list) => list.key === item.key)?.value;
      if (listAttach) {
        // Mettre à jour le storage URI
        let toSave = false;
        listAttach.map((attach) => {
          const attachToUpdate = attachData.size > 0 ? attachData.get(attach.id) : undefined;
          if (attachToUpdate != undefined) {
            toSave = true;
            attachToUpdate.distantUri = attach.distantUri;
          }
        });

        if (toSave) {
          const newData = JSON.stringify(Array.from(attachData.entries()));
          item.value.attachmentData = newData;
          //Update editedData
          item.value.editedData = this.updateEditedDataWithStorageUri(item.value.editedData, attachData);
          declarationsToUpdate.push(item.value);
        }
      }
    });
    //Sauvegarde des modifications
    if (declarationsToUpdate.length > 0) {
      declarationsDb.declarations.bulkPut(declarationsToUpdate);
    }
  };

  private updateEditedDataWithStorageUri = (
    editedData: string,
    attachData: Map<string, AttachmentItemData>,
  ): string => {
    const fd: FieldData[] = JSON.parse(editedData) as FieldData[];
    const declUpdated: FieldData[] = [];
    const modified = this.recursiveUpdateEditedDataWithStorageUri(fd, attachData, declUpdated);
    if (modified) {
      const newEditedData = JSON.stringify(declUpdated);
      return newEditedData;
    }
    return editedData;
  };

  private recursiveUpdateEditedDataWithStorageUri = (
    fieldDataList: FieldData[],
    attachData: Map<string, AttachmentItemData>,
    declUpdated: FieldData[] | undefined,
  ): boolean => {
    let result = false;
    fieldDataList.map((fd) => {
      if (
        fd.fieldType === FieldType.Group ||
        fd.fieldType === FieldType.RepeatableGroup ||
        fd.fieldType === FieldType.Include ||
        fd.fieldType === FieldType.Dialog ||
        fd.fieldType === FieldType.Step
      ) {
        return this.recursiveUpdateEditedDataWithStorageUri(fd.value as FieldData[], attachData, undefined);
      } else if (
        fd.fieldType === FieldType.Attachment ||
        fd.fieldType === FieldType.Draw ||
        fd.fieldType === FieldType.Signature ||
        fd.fieldType === FieldType.Photo
      ) {
        const attList = fd.value as AttachmentItemData[];
        if (attList) {
          attList.map((attach) => {
            const att = attachData.get(attach.id);
            if (att) {
              att.distantUri = attach.distantUri;
              result = true;
            }
          });
        }
      }
      if (declUpdated) {
        declUpdated.push(fd);
      }
    });

    return result;
  };

  modifyFormEditedData = async (
    formEditedDataDTO: FormEditedDataDTO,
    notIndexed: QCAttachment[],
    attachmentsFile: AttachmentItemData[],
  ): Promise<FormEditedDataDTO> => {
    await Promise.all(
      attachmentsFile.map(async (attach) => {
        const indexedAttach = await attachmentDb.attachments.get(attach.id);
        if (indexedAttach != undefined) {
          attach.distantUri = indexedAttach.distantUri;
        }
      }),
    );
    // A voir si besoin

    // formEditedData.attachmentData = JSON.stringify(Array.from(attachmentsFile.entries()));
    let attachmentList: any[] = [];
    // permet de parcourir l'objet editedData et modifier l'attribut distanUri
    const data = JSON.parse(formEditedDataDTO.editedData) as FieldData[];
    const newData = await Promise.all(
      data.map((x) => {
        const attachment = this.findMediasType(x);
        attachmentList = [...attachmentList, ...attachment];
        attachmentList.forEach((v) => {
          if (Array.isArray(v.value)) {
            v.value.forEach(
              (n1: { id: string; distantUri: string | undefined; file: Blob | MediaSource | File | undefined }) => {
                const findId = notIndexed.find((f) => f.id === n1.id);
                if (findId) {
                  n1.distantUri = findId?.distantUri;
                  n1.file = undefined;
                }
              },
            );
          } else {
            // cas todoListe : on n'a pas l'attribut value
            const findId = notIndexed.find((f) => f.id == v.id);
            if (findId) {
              v.distantUri = findId?.distantUri;
            }
          }
        });
      }),
    );

    this.logger.log(DeclarationStore.TAG, 'modifyFormEditedData finished');
    formEditedDataDTO.editedData = JSON.stringify(data);
    return formEditedDataDTO;
  };

  sendDeclarationToAPIAsync = async (): Promise<boolean> => {
    const {
      LoginStore: { urlToUse },
    } = this.RootStore;
    if (typeof urlToUse === 'string') {
      const requests: SynchronizeDeclaration[] = await declarationsDb.getDeclarationsToUpload(urlToUse);

      if (requests.length === 0) {
        this.logger.log(DeclarationStore.TAG, `Aucune requête depuis la table declarationToUpload à envoyer`);
      }
      requests.forEach((request: SynchronizeDeclaration) => {
        const { editedData } = request;
        editedData.forEach((dataEdited: FormEditedDataDTO) => {
          const { stringExtract, userUpn, editedAt } = dataEdited;
          // Début de l'envoi des dcl
          this.logger.info(
            DeclarationStore.TAG,
            `[Client Web] - Evenement "sendDeclarationToAPIAsync": Envoi par l'utilisateur ${userUpn} pour la dcl ${stringExtract} le ${
              editedAt as string
            } Début du transfert le ${format(new Date(), dateFormatWithMillisecond)}.`,
          );
        });
      });

      let allAttachmentAreIndexed = true;

      // Récupérer toutes les pièces jointes non indexées dans la BDD
      const notIndexed = await attachmentDb.getAttachmentToIndex();

      if (notIndexed && notIndexed.length > 0) {
        // Indexation de toutes les pièces jointes
        allAttachmentAreIndexed = await this.getIndexDocumentDTO(notIndexed);
      }

      const okDeclarationSent = await Promise.all(
        requests.map(async (requestSync: SynchronizeDeclaration) => {
          const { customerId: custom, transferState, ...request } = requestSync;

          const { editedData: formsEditedDataDto } = request;

          const newFormsEditedDataDto = formsEditedDataDto.map(async (formEditedDataDto: FormEditedDataDTO) => {
            const attachmentItemDataforFEDDto: AttachmentItemData[] = (
              await attachmentDb.getAttachmentItemDataByDeclarationId(formEditedDataDto.id)
            ).map(mapFromQCAttachmentToAttachmentItemData);

            const newFormEditedDataDto = await this.modifyFormEditedData(
              formEditedDataDto,
              notIndexed,
              attachmentItemDataforFEDDto,
            );

            return newFormEditedDataDto;
          });

          request.editedData = await Promise.all(newFormsEditedDataDto);

          // Consentement
          request.consentsRGPD = await this.RootStore.ConsentStore.transferringConsents();

          if (!this.shouldAbortRequests && allAttachmentAreIndexed) {
            const response = await this.clientHTTP.post<SynchronizeDeclarationResponse>(
              this.RootStore.CommonStore.chooseBaseUrl(API_SYNCHRONIZE_DECLARATION),
              request,
              {
                withCredentials: true,
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  'Content-Type': 'application/json',
                },
              },
            );
            if (200 <= response.status && response.status <= 299) {
              const infoMsg = `L'envoi de la déclaration a réussi`;

              // Info log sur les dcl

              request.editedData.forEach((dataEdited: FormEditedDataDTO) => {
                const { stringExtract, userUpn } = dataEdited;
                // Début de l'envoi des dcl
                this.logger.info(
                  DeclarationStore.TAG,
                  `Evenement "sendDeclarationToAPIAsync": Envoi par l'utilisateur ${userUpn} pour la dcl ${stringExtract} le ${
                    dataEdited.editedAt as string
                  } Fin du transfert le ${format(new Date(), dateFormatWithMillisecond)}.`,
                );
              });

              // Suppression de la declaration du tableau de FormEditedData
              await declarationsDb.removeDeclarationByUserUPN(request.userUPN);

              // Gestion des notifications des logs
              return true;
            } else {
              const error = new Error(
                `Une erreur est survenue lors de l'envoi de la déclaration: Statut: ${response.data.status}, Message: ${response.data.message}`,
              );

              errorHandler(DeclarationStore.TAG, error, 'sendDeclarationToAPIAsync', 'error');
              return false;
            }
          } else {
            return false;
          }
        }),
      );

      if (!okDeclarationSent.every((result: boolean) => result)) {
        return false;
      }

      // On envoie les pièces jointes
      // Prendre en compte l'envoi de pièce en asynchrone
      const attachmentPromiseResults = await this.sendAttachments();

      if (!attachmentPromiseResults) {
        return false;
      }

      // Mise a jour des consents
      const bConsentsTransferred = await this.RootStore.ConsentStore.updateToBoTransferredConsents();

      if (!bConsentsTransferred) return false;

      const {
        LoginStore: {
          signInInfos: { userUPN },
        },
      } = this.RootStore;

      const logsSent = await this.sendLogsToServerAsync(userUPN);

      if (!logsSent) return false;
      // Purge des inboxes de plus d'une journée.
      const bOldInboxes = await inboxesDb.purgeInboxesAsync(userUPN);

      return bOldInboxes;
    }

    return false;
  };

  sendAttachments = async (): Promise<boolean> => {
    // Envoi de toutes les photos en attente dans la BDD
    const toSend = await attachmentDb.getAttachmentToSend();
    this.setAttachmentsToSend(toSend.length);
    this.setSendingAttachments(toSend.length > 0);
    // On n'attend pas le retour de l'upload pour ne pas bloquer l'UI (ATTENTION) - Pb sur les unhandledrejection
    return Promise.all(
      toSend.map(async (attach) => {
        try {
          const data = new FormData();
          data.append('fileContent', attach.file);
          this.logger.log(DeclarationStore.TAG, `Envoi du fichier: ${attach.distantUri}`);
          const responseAttach = await this.clientHTTP.post(attach.distantUri, data, {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (200 <= responseAttach.status && responseAttach.status <= 299) {
            // Envoi effectué, mise à jour de l'attachment
            attach.transferState = DocumentTransferState.TransfertOK;
            this.logger.log(DeclarationStore.TAG, 'Fichier envoyé');
            this.decreaseAttachmentsToSend();
          } else {
            const error = new Error(
              `Fichier en erreur: ${attach.file.name} - Statut: ${responseAttach.status} - StatusText: ${responseAttach.statusText}`,
            );

            errorHandler(DeclarationStore.TAG, error, 'sendAttachments', 'error');

            attach.transferState = DocumentTransferState.TransfertKO;
            attach.nbTry = attach.nbTry - 1;
          }
          // Sauvegarde des modifications
          return await attachmentDb.updateAttachment(attach);
        } catch (error) {
          errorHandler(DeclarationStore.TAG, error, 'sendAttachments', 'error');

          return false;
        }
      }),
    )
      .then(async (values: boolean[]) => {
        // Suppression des documents à purger
        await attachmentDb.removeAttachmentToPurge();

        // Tempo (Demandé par Patrick)
        setTimeout(() => {
          this.setSendingAttachments(false);
        }, 2000);

        return values.every((v: boolean) => v);
      })
      .catch((reason: any) => {
        errorHandler(DeclarationStore.TAG, reason, 'sendAttachments');

        this.setSendingAttachments(false);
        return false;
      });
  };

  /**
   * Permet d'envoyer les logs à l'API via le endPoint "saveintrumentation"
   * @param {string} userUPN Identifiant de l'utilisateur
   * @returns True si ok
   */
  sendLogsToServerAsync = async (userUPN: string): Promise<boolean> => {
    try {
      // Récupération de tous les logs exceptés les logs errors dans la table indexedDB

      const telemetrylogDB = await logDb.getLogs(this.logger);

      if (telemetrylogDB) {
        // Construction de la requête
        const IMEI = getSessionId();
        const ApplicationName = APP_NAME;
        const { CrashDump, Items } = telemetrylogDB;
        const telemetryLogsRequest: TelemetryLogsRequest = {
          userUPN,
          IMEI,
          ApplicationName,
          CrashDump,
          Items,
        };

        // Récupération de la response
        const response = await this.clientHTTP.post<SaveInstrumentationResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(API_POST_INSTRUMENTATION),
          telemetryLogsRequest,
          {
            withCredentials: true,
          },
        );

        if (200 > response.status && response.status >= 300) {
          const error = new Error(
            `La requête de l'instrumentation dans la function sendLogsToServerAsync dans le declarationStore a échoué: Statut: ${response.data.status}, Message: ${response.data.message}`,
          );
          errorHandler(DeclarationStore.TAG, error, 'sendDeclarationToAPIAsync', 'error');

          return false;
        }
        // Destruction des logs
        await logDb.clearAllTables(this.logger);
        return true;
      } else {
        return true;
      }
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'sendDeclarationToAPIAsync');

      return false;
    }
  };

  private async getEditedData(
    declarationId: string,
    {
      formId,
      name,
      formParentId,
      formBody,
      entitySchemaId,
      formType,
      entityInstanceId,
      templateBodies,
      editedAt,
      validationAction,
    }: DeclarationContext,
    userUpn: string,
    declaration: FieldDesc[],
    attachment: AttachmentItemData[],
    organizationalUnitName: string,
    languageCode: string,
    itemData: ItemData | undefined,
    activityId: string | undefined,
    inboxId: string | undefined,
    isDraft: boolean,
    sendAPI: boolean | undefined,
    t: TFunction,
    stringExtract?: string,
  ): Promise<FormEditedData> {
    const attachData = new Map<string, AttachmentItemData>();
    attachment.map((attach) => {
      delete attach.file;
      attachData.set(attach.id, attach);
    });

    // Utilisation du ComputeStore en passant par le RootStore
    const {
      ComputeStore: { formatValuesBeforeSendDeclaration },
      EquipmentsStore: { isEmptyDataSourceForcedSelection },
    } = this.RootStore;

    // Cas QCAction si la valeur est vide
    const emptyActionsIds = flatten(declaration, (i) => i.items)
      .filter((f) => {
        const emptyValue = (Array.isArray(f.value) && f.value.length === 0) || !!f.value === false;
        return f.fieldType === FieldType.Action && emptyValue;
      })
      .map(({ id }: FieldDesc) => id);

    let simpleFields = declaration
      .map((f) => formatValuesBeforeSendDeclaration(f))
      .map(isEmptyDataSourceForcedSelection)
      .filter((f) => mapToFieldVisibility(f))
      .map((f) => mapToFieldData(f));

    notificationFieldsCheckUnprocessedData(simpleFields, isDraft, sendAPI, this.setFieldFocused, t);

    if (formType === FormType.Workflow) {
      const allAttachments = await attachmentDb.getAttachmentItemDataByDeclarationId(declarationId);

      if (allAttachments.length > 0) {
        simpleFields = mapDistantUriForOtherStep(allAttachments, simpleFields);
      }
    }
    // cas inbox : pour supprimer l'inbox apres l'envoi de declaration
    let isInbox;
    if (formType !== FormType.Workflow) {
      const isTodoList = simpleFields.filter((item) => item.fieldType === FieldType.TodoList);
      isInbox = inboxId
        ? { Inbox: { Id: inboxId, Type: isTodoList.length ? InboxItemType.Task : InboxItemType.PrefilledForm } }
        : itemData;
    }
    if (formType === FormType.Workflow) {
      delete itemData?.PrefilledData;
      delete itemData?.TodoListData;
      if (inboxId) {
        const x = itemData?.Workflow?.historicalStepDataList?.find(
          (item) => item.stepState === WorkflowStepStatus.Canceled,
        );
        isInbox = x ? { ...itemData, Inbox: { Id: inboxId, Type: InboxItemType.PrefilledForm } } : itemData;
      }
    } else if (itemData && inboxId) {
      isInbox = { ...itemData, Inbox: { Id: inboxId, Type: InboxItemType.PrefilledForm } };
    } else {
      isInbox = itemData;
    }

    return {
      id: declarationId,
      eTag: '',
      formParentId: formParentId,
      formId: formId,
      name: name,
      serializedBody: formBody,
      templateBodies: templateBodies ? JSON.stringify(templateBodies) : null,
      userUpn,
      attachmentData: JSON.stringify(Array.from(attachData.entries())),
      createdAt: editedAt ?? new Date(),
      editedAt: editedAt ?? new Date(),
      editedData: JSONBig.stringify(simpleFields),
      stringExtract: stringExtract ?? name,
      entitySchemaId: entitySchemaId,
      entityInstanceId: entityInstanceId,
      alertLevelValue: '',
      alertLevelLabel: '',
      workflowStatus: undefined, // ne sert à rien
      entityInstanceStringExtract: '',
      organizationalUnitStringExtract: organizationalUnitName,
      formType: formType,
      itemData: JSON.stringify(isInbox ?? itemData) === '{}' ? null : JSON.stringify(isInbox ?? itemData),
      itemDataStatus: itemData?.Workflow?.currentWorkflowState ?? 0,
      itemDataComment: '',
      activityId: activityId ?? uuidv4(),
      parentActivityId: undefined,
      languageCode: languageCode,
      inboxId: inboxId,
      isDraft: isDraft,
      stringExtractDisplayed: stringExtract,
      internalData: this.internalData.length === 0 ? undefined : JSON.stringify(this.internalData),
      validationAction: validationAction ?? ValidationActionType.Validated,
    } as FormEditedData;
  }

  async getOldDeclaration(requestInfo: RequestData) {
    try {
      const result = await this.clientHTTP.post(
        this.RootStore.CommonStore.chooseBaseUrl(API_POST_HISTORIQUEDECLARATION),
        requestInfo,
        {
          withCredentials: true,
        },
      );
      if (result) {
        return result;
      }
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'getOldDeclaration', 'error');
    }
  }

  async actionSearchData(requestData?: SearchRequestData) {
    try {
      const result = await this.clientHTTP.post<ActionResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(API_POST_QC_ACTION),
        requestData,
        {
          withCredentials: true,
        },
      );
      if (result) {
        return result;
      }
    } catch (error) {
      if (QCErrorHTTP.isHttpError<AxiosError<ActionResponse>>(error)) {
        error.message = `Statut: ${error.response?.data.status} - Message: ${error.response?.data.message}`;
      }
      errorHandler(DeclarationStore.TAG, error, 'actionSearchData');
    }
  }

  /**
   *Permet de récupérer une sauvegarde de déclaration - S'il y a une sauvegarde présente, on set l'état hasBackUp à true, ce qui déclanchera l'ouverture d'une popup proposant à l'utilisateur de reprendre la saisie de la déclaration
   *
   * @param {string} userUPN
   * @memberof DeclarationStore
   */
  findBackUpsByUserUPNAsync = async (userUPN: string): Promise<void> => {
    try {
      const backupDclContext = await declarationsDb.findBackUpByUserUPNAsync(this.logger, userUPN);

      if (backupDclContext.length > 0) this.setBackupDclContext(backupDclContext);
    } catch (error: unknown) {
      errorHandler(DeclarationStore.TAG, error, 'findBackUpsByUserUPNAsync');
    }
  };

  deleteLocalBackupAsync = async (formId: string): Promise<boolean> => {
    try {
      const { userUPN } = this.RootStore.LoginStore.signInInfos;
      const backupDeleted = await declarationsDb.deleteLocalBackupAsync(this.logger, formId, userUPN);

      if (!backupDeleted) throw new Error(`Declaration not delete for formId: ${formId}`);

      this.setBackupDclContext(
        this.backupDclContext.filter(
          (backUpDcl: LocalBackupDeclaration) => backUpDcl.formId !== formId && backUpDcl.userUPN !== userUPN,
        ),
      );

      this.setIsEditingCurrentDeclaration(false);
      this.setEditableDeclaration(false);
      return true;
    } catch (error: unknown) {
      errorHandler(DeclarationStore.TAG, error, 'deleteLocalBackupAsync');
    }
    return false;
  };

  deleteLocalBackups = async (userUPN: string): Promise<void> => {
    await declarationsDb.backupDeclarationContext.where('userUPN').equals(userUPN).delete();
    this.setBackupDclContext([]);
    this.setIsEditingCurrentDeclaration(false);
    this.setEditableDeclaration(false);
  };

  private decreaseAttachmentsToSend = (): void => {
    const newValue = this.attachmentsToSend - 1;
    this.setAttachmentsToSend(newValue);
  };

  public parseInternalData = (serializedInternalData: string): void | never => {
    if (typeof serializedInternalData !== 'string' || serializedInternalData.startsWith('[') === false)
      throw new Error(
        '[Client Web] declarationStore.ts parseInternalData method failed: La donnée serializedInternalData doit être une chaine de caractères sérializée',
      );

    const internalDataParsed: FieldData[] = JSON.parse(serializedInternalData);

    if (!Array.isArray(internalDataParsed)) return;

    internalDataParsed.forEach((fd: FieldData) => {
      this.internalDataDico.set(fd.id, fd);
    });
  };

  /**
   * Enregistre l'état du champ dans le cas des internalData
   *
   * @param {string} fullId
   * @param {InternalFieldState} state
   * @param {boolean} value
   * @memberof DeclarationStore
   */
  public registerNewState = (fullId: string, state: InternalFieldState, value: boolean) => {
    const dicoKey = `${fullId}.(state)`;
    const fd: FieldData | undefined = this.internalDataDico.get(dicoKey);

    if (!fd) {
      const sfd: FieldData = {
        id: dicoKey,
        label: 'state',
        fieldType: FieldType.Text,
        value: this.getStateValue(state, value, '----'),
      };

      this.internalDataDico.set(dicoKey, sfd);
    } else {
      const { value: previousValue } = fd;
      this.internalDataDico.set(dicoKey, { ...fd, value: this.getStateValue(state, value, previousValue as string) });
    }
  };

  private getStateValue = (
    state: InternalFieldState,
    stateValue: boolean | null,
    currentStateValue: string | null,
  ): string => {
    if (currentStateValue === null || currentStateValue.length !== 4) return '----';

    const sStateValue: string = stateValue === null ? '-' : stateValue ? '1' : '0';

    switch (state) {
      case InternalFieldState.Visible:
        return StringExtension.replaceAt(0, sStateValue, currentStateValue);

      case InternalFieldState.ReadOnly:
        return StringExtension.replaceAt(1, sStateValue, currentStateValue);

      case InternalFieldState.Mandatory:
        return StringExtension.replaceAt(2, sStateValue, currentStateValue);

      case InternalFieldState.Valid:
        return StringExtension.replaceAt(3, sStateValue, currentStateValue);
      case InternalFieldState.Undef:
      default:
        return '---';
    }
  };

  public registerNewDataSource = (
    fullId: string,
    { code, instanceId, schemaId, title, asFieldDataList }: DataSourceValue,
  ) => {
    const dicoKey = `${fullId}.(ds)`;
    const fd: FieldData | undefined = this.internalDataDico.get(dicoKey);

    if (!fd) {
      const fdl: FieldData[] = asFieldDataList();

      const dsFd: FieldData = {
        id: dicoKey,
        label: 'ds',
        fieldType: FieldType.DataSource,
        code,
        instanceId,
        schemaId,
        title,
        value: fdl,
      };

      this.internalDataDico.set(dicoKey, dsFd);
    } else {
      this.internalDataDico.set(dicoKey, { ...fd, value: asFieldDataList() });
    }
  };

  public registerNewAutoCompleteForFamilly = (fullId: string, schemaId: string, entityInstanceId: string) => {
    // (AutoCompletSchema
    const dicoKey = `${fullId}.(acs)`;
    const fd: FieldData | undefined = this.internalDataDico.get(dicoKey);

    // On enregistre la nouvelle valeur du champ autoComplete de la manière suivante:
    // SchemaId/EntityInstanceId

    const value = `${schemaId}/${entityInstanceId}`;

    if (!fd) {
      // On crée le nouveau StringFieldData
      const dsFd: FieldData = {
        id: dicoKey,
        label: 'acs',
        fieldType: FieldType.Text,
        value,
      };

      this.internalDataDico.set(dicoKey, dsFd);
    } else {
      this.internalDataDico.set(dicoKey, { ...fd, value });
    }
  };

  public registerNewAutoCompleteForAAD = (fullId: string, value: string) => {
    // (AutoCompletSchema
    const dicoKey = `${fullId}.(acc)`;
    const fd: FieldData | undefined = this.internalDataDico.get(dicoKey);

    // On enregistre la nouvelle valeur du champ autoComplete de la manière suivante:

    if (!fd) {
      // On crée le nouveau StringFieldData
      const dsFd: FieldData = {
        id: dicoKey,
        label: 'acc',
        fieldType: FieldType.Text,
        value,
      };

      this.internalDataDico.set(dicoKey, dsFd);
    } else {
      this.internalDataDico.set(dicoKey, { ...fd, value });
    }
  };

  public deleteAutoCompleteForFamilly = (fullId: string) => {
    // (AutoCompletSchema
    const dicoKey = `${fullId}.(acs)`;
    const isExist: boolean = this.internalDataDico.has(dicoKey);

    if (!isExist) return;

    this.internalDataDico.delete(dicoKey);
  };

  public deleteAutoCompleteForAAD = (fullId: string) => {
    // (AutoCompletSchema
    const dicoKey = `${fullId}.(acc)`;
    const isExist: boolean = this.internalDataDico.has(dicoKey);

    if (!isExist) return;

    this.internalDataDico.delete(dicoKey);
  };

  async hasDraftsDcl(userUPN: string): Promise<boolean> {
    return (await declarationsDb.getLocalDrafts(userUPN, this.logger)).length > 0;
  }

  async removeBackUpDcl(formId: string): Promise<boolean> {
    const { userUPN } = this.RootStore.LoginStore.signInInfos;
    return !!(await declarationsDb.removeBackupDeclaration(this.logger, formId, userUPN));
  }

  /**
   * Récupère les données du LRU en fonction de l'ID du champ et du nombre indiqué par l'attribut LRUCount
   * @param fullId
   * @param lruCount
   */
  async getLRUDescriptionsByFieldId(fields: FieldDesc[], fullId: string, lruCount: number): Promise<string[]> {
    try {
      const {
        LoginStore: {
          signInInfos: { userUPN },
        },
      } = this.RootStore;

      const lruFullId = getLRUFullId(fields, fullId.split('.'));
      const result = await fieldSearchHistoryDb.getLRUDescriptionsByFieldLRUFullIdAsync(lruFullId, userUPN);

      if (!result) return [];

      return result.description.reverse().slice(0, lruCount);
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'getLRUDescriptionsByFieldId');
      return [];
    }
  }

  async modifyFieldHistory(objectToModify: IFieldSearchHistory) {
    await fieldSearchHistoryDb.modifyFieldHistory(objectToModify);
  }

  async deleteLRUValue(fields: FieldDesc[], fullId: string, userUPN: string, value: string): Promise<void> {
    try {
      const lruFullId = getLRUFullId(fields, fullId.split('.'));

      await fieldSearchHistoryDb.removeLRUValue(lruFullId, userUPN, value);
    } catch (error) {
      errorHandler(DeclarationStore.TAG, error, 'deleteLRUValue');
    }
  }
}

export default DeclarationStore;
