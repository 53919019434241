import React from 'react';
import { observer } from 'mobx-react';
import useData from './hooks';
import { ErrorsResolverProps } from './types';
import ErrorResolver from './ErrorResolver';

/**
 * Ce composant permet de gérer les erreurs de type mandatory, min, max...
 * @param param0
 * @returns
 */
const ErrorsResolver = ({
  children,
  flattenFields,
  updateFieldErrors,
  setCanSubmit,
  formType,
}: ErrorsResolverProps) => {
  const { allFieldsToCkeck } = useData(flattenFields, setCanSubmit, formType, updateFieldErrors);

  return (
    <>
      {allFieldsToCkeck.map((fieldToCheck) => (
        <ErrorResolver
          key={`${fieldToCheck.fullPathId}`}
          fieldToCheck={fieldToCheck}
          allFieldsToCkeck={allFieldsToCkeck}
          updateFieldErrors={updateFieldErrors}
          flattenFields={flattenFields}
        />
      ))}
      {children}
    </>
  );
};

export default observer(ErrorsResolver);
