const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K): [K, T[]][] =>
  list.reduce((groups, currentItem) => {
    const groupKey = getKey(currentItem);
    const currentGroup = groups.find((k) => k[0] === groupKey);
    if (currentGroup) currentGroup[1].push(currentItem);
    else groups.push([groupKey, [currentItem]]);
    return groups;
  }, [] as [K, T[]][]);

export default groupBy;
