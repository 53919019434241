import { Fab, FabProps, styled } from '@mui/material';
import { terraCotta100, terraCotta400, terraCotta200, terraCotta500 } from '00.app/theme';

export const CancelWorkFlowCommandStyledComponent = styled(Fab)<FabProps>(() => ({
  background: `linear-gradient(45deg, ${terraCotta100} 30%, ${terraCotta400} 90%)`,
  marginTop: '10px',
  '&:hover': {
    background: `linear-gradient(45deg, ${terraCotta200} 30%, ${terraCotta500} 90%)`,
  },
}));
