import NumberExtension from '80.quickConnect.Core/formatting/NumberExtension';
import { isActionData } from '90.quickConnect.Models/guards';
import { QCActionData } from '90.quickConnect.Models/models';

export default function mapAllFieldTypeValuesToDigit(value: unknown): number | undefined {
  if (value === null || value === undefined) return undefined;

  if (isActionData(value) && value.length > 0) {
    const [firstAction] = value as QCActionData;
    const { value: actionDataValue } = firstAction;

    return mapAllFieldTypeValuesToDigit(actionDataValue);
  }

  const int = NumberExtension.createIntegerValue(value);

  if (!NumberExtension.isAValidNumber(int)) return undefined;

  return int;
}
