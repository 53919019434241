/**
 * Les différents états d'un fichier
 */
export enum CommonFileState {
  /** Non envoyé */
  unload = 0,
  /** Envoi en cours */
  loading = 1,
  /** Envoyé */
  loaded = 2,
  /** En cours de traitement */
  processing = 4,
  /** Echec de l'envoi */
  loadingFailed = 3,
}
