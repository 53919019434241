import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataPropos } from './types';
import { useStore } from '30.quickConnect.Stores';
import { Mandatory, SynchronizeEquipmentReturnType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes, CodeReaderDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { mapEquipmentForFormLib } from '90.quickConnect.Models/mappings';
import DataSourceValue from '30.quickConnect.Stores/helpers/DataSourceValue';
import { useQCUpdateFunctionsContext } from '20.formLib/DeclarationContainer/contexts';

const useData = (
  field: CodeReaderDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  updateFieldErrors: (mandatoryFieldFullPathId: string, newErrors: string[]) => void,
): UseDataPropos => {
  const { searchEquipment, mustBeOfSchema, mustHaveQCTag, fullPathId, value, mandatory, id, reference } = field;

  const { t } = useTranslation(['axios', 'declaration']);
  const {
    EquipmentsStore: { synchronizeEquipmentsAsync },
    DeclarationStore: { registerNewDataSource },
  } = useStore();

  const { updateProperty } = useQCUpdateFunctionsContext();

  const [code, setCode] = useState<string>('');
  const [localValue, setLocalValue] = useState<string | undefined>((value?.toString() as string) ?? undefined);
  const [open, setOpen] = useState(false);
  const handleClickOpen = useCallback(() => {
    setOpen(true);
    setSelectedIndex(fullPathId);
  }, [fullPathId, setSelectedIndex]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, code);
  }, [updateDeclaration, fullPathId, code]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const handleAddRfidCode = useCallback(() => {
    setLocalValue(code);
    updateGlobalState();
    setCode('');
    handleClose();
  }, [code, handleClose, updateGlobalState]);

  const checkEquipment = useCallback((): void => {
    if (code && searchEquipment) {
      //Ajout du tag "##" au code d'équipement pour le différentier côté API du QRcode
      synchronizeEquipmentsAsync(
        `##${code}`,
        mustBeOfSchema,
        mustHaveQCTag,
        SynchronizeEquipmentReturnType.All,
        undefined,
        t,
      ).then((newData) => {
        if (newData) {
          // On met a jour la prop 'entityData'
          updateProperty(fullPathId, 'entityData', newData);
          // Enregistrement de l'entityData pour les internalData
          const dataSourceValue: DataSourceValue = mapEquipmentForFormLib(newData);
          registerNewDataSource(fullPathId, dataSourceValue);

          if (mandatory !== Mandatory.Required) {
            setLocalValue(code);
            updateFieldErrors(fullPathId, []);
            debouncedUpdateGlobalState();
            handleClose();
          } else {
            setLocalValue(code);
            debouncedUpdateGlobalState();
            handleClose();
          }
        } else {
          if (mandatory !== Mandatory.Required) {
            updateFieldErrors(fullPathId, [t('errors_mandatory_field', { ns: 'declaration' })]);
            setLocalValue(code);
            setCode('');
            debouncedUpdateGlobalState();
            handleClose();
          } else {
            setCode('');
            debouncedUpdateGlobalState();
            handleClose();
          }
        }
      });
    } else {
      setLocalValue(code);
      handleClose();
      debouncedUpdateGlobalState();
    }
  }, [
    code,
    registerNewDataSource,
    updateProperty,
    debouncedUpdateGlobalState,
    fullPathId,
    handleClose,
    mandatory,
    mustBeOfSchema,
    mustHaveQCTag,
    searchEquipment,
    synchronizeEquipmentsAsync,
    t,
    updateFieldErrors,
  ]);
  const handleClear = useCallback(() => {
    setLocalValue('');
    setCode('');
    updateFieldErrors(fullPathId, []);
    debouncedUpdateGlobalState();
  }, [debouncedUpdateGlobalState, fullPathId, updateFieldErrors]);

  // useEffect pour les références...
  useEffect(() => {
    const newLocalValue = typeof value === 'string' ? value : undefined;
    setLocalValue((previousLocalState: string | undefined) =>
      newLocalValue !== previousLocalState ? newLocalValue : previousLocalState,
    );
  }, [value]);

  return {
    code,
    setCode,
    checkEquipment,
    localValue,
    setLocalValue,
    handleClickOpen,
    handleClose,
    open,
    handleClear,
    handleAddRfidCode,
  };
};
export default useData;
