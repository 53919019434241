import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import useWindowSize from './useWindowSize';

const useIsMobile = (): boolean => {
  const {
    breakpoints: {
      values: { md },
    },
  } = useTheme();

  const { width } = useWindowSize();

  const mobile = useMemo(() => {
    return !!width && width < md;
  }, [width, md]);

  return mobile;
};

export default useIsMobile;
