/* eslint-disable max-lines-per-function */
import { TFunction } from 'i18next';
import evaluateComputedRule from './evaluateComputedRule';
import { OperatorResult } from '90.quickConnect.Models/enums';
import { DualOperator, Dependency, NamedUserParameterValue, AllFieldValueTypes } from '90.quickConnect.Models/models';
import {
  operationWithBooleans,
  operationWithDates,
  operationWithNumbers,
  operationWithStrings,
  operationWithTimes,
  operationWithChoices,
  operationWithChoicesArray,
} from '20.formLib/helpers/evals/getArithmeticOperations';

export const evalArithmeticValue = (
  t: TFunction,
  dualOperator: DualOperator,
  dependencies: Dependency[],
  userParams: NamedUserParameterValue[],
  scopeFullPathId: string,
  userUpn: string,
  userEmail: string,
): [AllFieldValueTypes, OperatorResult] => {
  const [ref1Value, ref1Type] = evaluateComputedRule(
    t,
    dualOperator.arg1,
    dependencies,
    userParams,
    scopeFullPathId,
    userUpn,
    userEmail,
  );
  const [ref2Value, ref2Type] = evaluateComputedRule(
    t,
    dualOperator.arg2,
    dependencies,
    userParams,
    scopeFullPathId,
    userUpn,
    userEmail,
  );
  if (
    typeof ref1Value !== 'undefined' &&
    typeof ref2Value !== 'undefined' &&
    ref1Value !== null &&
    ref2Value !== null
  ) {
    // Cas des dates
    if (
      (ref1Type === OperatorResult.DATE || ref1Type === OperatorResult.DATETIME) &&
      (ref2Type === OperatorResult.DATE || ref2Type === OperatorResult.DATETIME)
    ) {
      const isDateTime = ref1Type === OperatorResult.DATETIME || ref2Type === OperatorResult.DATETIME;

      return operationWithDates(ref1Value, ref2Value, dualOperator.type, t, isDateTime);
    }
    // Cas des times (millisecondes)
    else if (ref1Type === OperatorResult.TIME && ref2Type === OperatorResult.TIME) {
      return operationWithTimes(ref1Value, ref2Value, dualOperator.type, t);
    }
    // Cas des numbers
    else if (
      ((ref1Type === OperatorResult.INT && ref2Type === OperatorResult.INT) ||
        (ref1Type === OperatorResult.STRING && ref2Type === OperatorResult.STRING)) &&
      !isNaN(Number(ref1Value)) &&
      !isNaN(Number(ref2Value))
    ) {
      const precision =
        ref1Type === (OperatorResult.DOUBLE as string) || ref2Type === (OperatorResult.DOUBLE as string) ? 10 : 0;
      return operationWithNumbers(ref1Value, ref2Value, dualOperator.type, precision, t);
    } else if (
      (ref1Type === OperatorResult.DOUBLE && ref2Type === OperatorResult.DOUBLE) ||
      (ref1Type === OperatorResult.INT && ref2Type === OperatorResult.DOUBLE) ||
      (ref1Type === OperatorResult.DOUBLE && ref2Type === OperatorResult.INT)
    ) {
      const precision =
        ref1Type === (OperatorResult.DOUBLE as string) || ref2Type === (OperatorResult.DOUBLE as string) ? 10 : 0;
      return operationWithNumbers(ref1Value, ref2Value, dualOperator.type, precision, t);
    }
    // Cas des string
    else if (ref1Type === OperatorResult.STRING && ref2Type === OperatorResult.STRING) {
      return operationWithStrings(ref1Value, ref2Value, dualOperator.type, t);
    }
    // Cas des boolean
    else if (ref1Type === OperatorResult.BOOLEAN && ref2Type === OperatorResult.BOOLEAN) {
      operationWithBooleans(ref1Value, ref2Value, dualOperator.type, t);
    }
    // Cas des choices
    else if (ref1Type === OperatorResult.LISTCHOICE && ref2Type === OperatorResult.LISTCHOICE) {
      if (ref1Value.constructor.name !== 'Array' && ref2Value.constructor.name !== 'Array') {
        operationWithChoices(ref1Value, ref2Value, dualOperator.type, t);
      } else if (ref1Value.constructor.name === 'Array' && ref2Value.constructor.name === 'Array') {
        operationWithChoicesArray(ref1Value, ref2Value, dualOperator.type, t);
      }
    }
  } else {
    if (
      ref1Type === OperatorResult.INT &&
      ref2Type === OperatorResult.INT &&
      (ref1Value === undefined || ref2Value === undefined)
    ) {
      // Cas où on une valeur sur undefined
      if (!!ref1Value === false) return ['', OperatorResult.STRING];
      if (!!ref2Value === false) return ['', OperatorResult.STRING];
    }
    if (
      (ref1Type === OperatorResult.DOUBLE && ref2Type === OperatorResult.DOUBLE) ||
      (ref1Type === OperatorResult.INT && ref2Type === OperatorResult.DOUBLE) ||
      (ref1Type === OperatorResult.DOUBLE && ref2Type === OperatorResult.INT) ||
      (ref1Type === OperatorResult.INT && ref2Type === OperatorResult.STRING) ||
      (ref1Type === OperatorResult.STRING && ref2Type === OperatorResult.INT) ||
      (ref1Type === OperatorResult.STRING &&
        ref2Type === OperatorResult.STRING &&
        (!isNaN(ref1Value as number) || !isNaN(ref2Value as number)))
    ) {
      return operationWithNumbers(ref1Value, ref2Value, dualOperator.type, undefined, t);
    }
  }
  return [t('computed_fields_errors_eval_error').toString(), OperatorResult.ERROR];
};
