import React, { KeyboardEvent } from 'react';
import { useTheme, IconButton, Tooltip, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { observer } from 'mobx-react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { SearchBarName } from './const';
import { SearchBarProps } from './types';
import { searchBarContainer } from './styles';
import { useIsMobile } from '80.quickConnect.Core/hooks';

const SearchBar = ({
  toolTip,
  filterInputValue,
  filter,
  setFilter,
  applyFilter,
  clearFilter,
  setSearchIconToRigth,
}: SearchBarProps) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const {
    palette: {
      info: { main },
    },
  } = theme;

  return (
    <Tooltip title={toolTip}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ flex: isMobile ? 1 : 'inherit' }}
      >
        <FormControl sx={{ width: '100%', padding: '5px' }}>
          <OutlinedInput
            sx={searchBarContainer(theme)}
            data-cy={`${SearchBarName}-search-forms-input`}
            type="text"
            value={filterInputValue}
            required
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              e.stopPropagation();
            }}
            onChange={(event: React.ChangeEvent<{ value: string }>) => setFilter(event.target.value)}
            startAdornment={
              !setSearchIconToRigth && (
                <InputAdornment position="end">
                  <IconButton
                    data-cy={`${SearchBarName}-search-forms-button`}
                    onClick={() => applyFilter()}
                    edge="start"
                    type="submit"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
            endAdornment={
              <>
                {setSearchIconToRigth && (
                  <InputAdornment position="end">
                    <IconButton
                      data-cy={`${SearchBarName}-search-forms-button`}
                      onClick={() => applyFilter()}
                      edge="end"
                      type="submit"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )}
                {filter ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => clearFilter()} onMouseDown={() => clearFilter()} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : undefined}
              </>
            }
          />
        </FormControl>
      </form>
    </Tooltip>
  );
};

export default observer(SearchBar);
