import { AddressData, AddressDesc } from '90.quickConnect.Models/models';

/**
 * Retourne une adresse par défaut
 * Attention à ne pas utiliser la même instance
 */
export const defaultAddress = () =>
  ({
    streetNumber: '',
    street: '',
    complement: '',
    zipCode: '',
    city: '',
    country: '',
    coordinates: undefined,
  } as AddressData);

const mapAddressDesc = (field: AddressDesc): AddressDesc => {
  let defaultValue;
  if (field.defaultValue) {
    try {
      defaultValue = JSON.parse(field.defaultValue);
    } catch (e) {
      defaultValue = defaultAddress();
    }
  }

  return {
    value: field.value ?? defaultValue ?? defaultAddress(),
    defaultValue: defaultValue,
  } as unknown as AddressDesc;
};

export default mapAddressDesc;
