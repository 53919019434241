import React, { useState } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useData from './hooks';
import { InboxLineName } from './const';
import { getInboxIcon } from '10.quickConnect.app/components/domain/Home/helpers';
import {
  CustomListItemText,
  getAvatarStyle,
  getListItemAvatarStyle,
  getListItemButtonStyle,
} from '10.quickConnect.app/components/domain/Home/styles';
import { InboxViewer } from '90.quickConnect.Models/models';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import ConfirmDialog from '10.quickConnect.app/components/shared/LayOut/ConfirmDialog';

const InboxLine = (inboxItem: TreeDataItem) => {
  const { t } = useTranslation('inboxes');
  const {
    openDeclaration,
    openContextMenu,
    contextMenu,
    contextMenuClose,
    openInNewTab,
    confirmDialogOpen,
    handleConfirmDialogClose,
    handleConfirmDialogConfirm,
    dialogSubtitle,
    ouDeclarationCode,
  } = useData(inboxItem);
  const { inboxType, subject, sentAt } = inboxItem as InboxViewer;

  const theme = useTheme();

  return (
    <ListItem data-cy={InboxLineName} disablePadding>
      <ListItemButton
        sx={getListItemButtonStyle(theme)}
        data-cy={`${InboxLineName}-${inboxItem.id}`}
        onContextMenu={openContextMenu}
        onClick={() => openDeclaration()}
      >
        <ListItemAvatar sx={getListItemAvatarStyle(theme)}>
          <Avatar sx={getAvatarStyle(theme)}>{getInboxIcon(inboxType)}</Avatar>
        </ListItemAvatar>
        <CustomListItemText primary={subject} secondary={sentAt.toLocaleString()} />
      </ListItemButton>
      <Menu
        open={contextMenu !== null}
        onClose={contextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={openInNewTab}>{t('contextual_menu_open_in_new_tab')}</MenuItem>
      </Menu>
      <ConfirmDialog
        open={confirmDialogOpen}
        close={handleConfirmDialogClose}
        actionForLabelOne={() => handleConfirmDialogConfirm(ouDeclarationCode)}
        actionForLabelTwo={handleConfirmDialogClose}
        actionLabelOne={t('qcapp_button_yes').toString()}
        actionLabelTwo={t('qcapp_button_no').toString()}
        cancelLabel={t('qcapp_dialog_action_cancel').toString()}
        title={t('qcapp_alert_dialog_change_ou').toString()}
        subtitle={dialogSubtitle || ''}
        sendingData={false}
      />
    </ListItem>
  );
};
export default InboxLine;
