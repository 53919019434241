import mapBaseConditionalOperator from '../others/mapBaseConditionalOperator';
import { BaseQCOperator, StepDesc } from '90.quickConnect.Models/models';

const mapStepDesc = (field: StepDesc): StepDesc => {
  let transition: BaseQCOperator | undefined;
  if (field.transition) {
    if (typeof field.transition === 'string' || field.transition instanceof String) {
      const transitionParsed = JSON.parse(field.transition as string);
      transition = mapBaseConditionalOperator(transitionParsed);
    } else {
      transition = mapBaseConditionalOperator(field.transition);
    }
  }
  return {
    summary: field.summary,
    transition: transition,
  } as StepDesc;
};

export default mapStepDesc;
