import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordRulesType } from '../../Login/types';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import { ChangePasswordResquest } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/requests';
import { verifyResetCodeAndPassword } from '80.quickConnect.Core/helpers/verifyForm';
import { errorHandler } from '80.quickConnect.Core/helpers';
import useEffectOnce from '80.quickConnect.Core/hooks/useEffectOnce';

const useData = (): UseDataProps => {
  // Tag
  const tag = '10.quickConnect.app/components/domain/Home/UserSetting/hooks.ts';

  const {
    LoginStore: { signInInfos, changePassword, connectByMsal },
    UserSettingsStore: { getApiInformation },
  } = useStore();
  const { t: login } = useTranslation('login');
  const { t: userSetting } = useTranslation('userSetting');
  const [apiInfo, setApiInfo] = useState<Record<string, unknown> | undefined>();

  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [passwordRules, setPasswordRules] = useState<PasswordRulesType>({
    passLength: false,
    lowerCase: false,
    upperCase: false,
    digit: false,
    specialCharacters: false,
  });

  const onChangePassword = useCallback(async () => {
    if (verifyResetCodeAndPassword(true, oldPassword, newPassword, confirmPassword, passwordRules, login)) {
      const info: ChangePasswordResquest = {
        userUPN: signInInfos.userUPN,
        currentPassword: oldPassword,
        newPassword,
      };
      try {
        const data = await changePassword(info, userSetting);
        if (data) {
          setOpenChangePassword(false);
        }
      } catch (error: unknown) {
        errorHandler(tag, error, 'onChangePassword');
      }
    }
  }, [
    tag,
    changePassword,
    confirmPassword,
    login,
    newPassword,
    oldPassword,
    passwordRules,
    signInInfos.userUPN,
    userSetting,
  ]);

  useEffectOnce(() => {
    const fetchApiInfos = async () => {
      const infos = await getApiInformation();

      setApiInfo(infos);
    };

    fetchApiInfos();
  });

  return {
    signInInfos,
    apiInfo,
    confirmPassword,
    newPassword,
    oldPassword,
    setConfirmPassword,
    openChangePassword,
    setNewPassword,
    setOldPassword,
    onChangePassword,
    setOpenChangePassword,
    passwordRules,
    setPasswordRules,
    connectByMsal,
  };
};
export default useData;
