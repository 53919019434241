export class Stack<T> {
  public _store: T[] = [];

  public push(val: T): void {
    this._store.push(val);
  }

  public pop(): T | undefined {
    return this._store.pop();
  }

  public peek(): T | undefined {
    return this._store[this._store.length - 1];
  }
}
