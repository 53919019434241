import { errorHandler } from '80.quickConnect.Core/helpers';

const tag = '80.quickConnect.Core/helpers/jsonWrapper.ts';

const parse =
  <T>(guard: (obj: any) => obj is T) =>
  (text: string, throwError = false): T | undefined => {
    try {
      const parsed = JSON.parse(text);

      return guard(parsed) ? parsed : undefined;
    } catch (error) {
      if (error instanceof Error) error.message = `Le parse n'a pas fonctionné ${error.message}`;
      errorHandler(tag, error, 'parse');

      if (throwError) throw error;
    }
  };

const JsonWrapper = { parse };
export default JsonWrapper;
