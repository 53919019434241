import {
  getChoiceList,
  getValueFromChoice,
  getValueFromChoiceProperties,
  isNumeric,
} from '80.quickConnect.Core/helpers/common';
import {
  FieldDesc,
  AllFieldValueTypes,
  Choice,
  QCNotification,
  NotificationTarget,
  ComboDesc,
} from '90.quickConnect.Models/models';
import { FieldType } from '90.quickConnect.Models/enums';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { splitAndTrim, isQCNotification } from '80.quickConnect.Core/helpers/common';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';
import { mapToNotificationDataFromHashMap } from '90.quickConnect.Models/mappings';

export const getReferencedComboValue = (
  fieldWithReference: FieldDesc,
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
  columnId: string | undefined,
): AllFieldValueTypes => {
  const comboListValues = valueReferenceDependency as Choice;
  const referencesProperties = columnId?.split(',');

  const { listChoice: listChoiceFromExp } = fieldWithReference as ComboDesc;

  if (!comboListValues) return fieldWithReference.value;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
      const result = getValueFromChoiceProperties([comboListValues], referencesProperties, true).join(', ');
      if (result.length === 0) return undefined;
      if (typeof result === 'number') return numericLocaleFormatLitteral(result);

      return result;

    case FieldType.Notification:
      const { value } = fieldWithReference;
      const notificationValue = isQCNotification(value) ? value : mapToNotificationDataFromHashMap({});
      const res = getValueFromChoiceProperties([comboListValues], referencesProperties, true);

      if (!res) return undefined;

      const resultNotification =
        splitAndTrim(res)
          /* eslint-disable-next-line no-useless-escape */
          .filter((item: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item))
          .map((item: string) => ({ alias: null, target: item } as NotificationTarget)) ?? [];

      return {
        ...notificationValue,
        selectedTargets: resultNotification,
      };

    case FieldType.CheckBox:
      return comboListValues ? 1 : 0;

    case FieldType.Combo:
    case FieldType.CheckBoxList:
    case FieldType.HierarchicalList:
    case FieldType.RadioList:
    case FieldType.Alert:
      return getValueFromChoice([comboListValues], listChoiceFromExp, fieldTypeFieldWithReference);

    case FieldType.Counter:
    case FieldType.Digits:
      return parseInt(getValueFromChoiceProperties([comboListValues], referencesProperties).join(', '));

    case FieldType.Numeric:
    case FieldType.Slider:
      return parseNumber(getValueFromChoiceProperties([comboListValues], referencesProperties).join(', '));

    default:
      return undefined;
  }
};
