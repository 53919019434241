/**
 * Les types disponible des paramètres utilisateurs
 */
export enum UserSettingsType {
  None,
  Boolean,
  Int,
  Decimal,
  SharedList,
  Text,
}
