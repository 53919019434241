import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RepeatableQcFieldChildName } from './const';
import { UseDataProps } from './types';
import { AllFieldValueTypes, FieldDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  parentId: string,
  forceExpand: boolean,
  field: FieldDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  setSelectedIndex: React.Dispatch<React.SetStateAction<string | undefined>>,
  openByImagesGroupField?: boolean,
): UseDataProps => {
  const { t } = useTranslation('declaration');
  const { id, fullPathId, value } = field;
  const nameWithId = `${RepeatableQcFieldChildName}-${id}`;

  const [isExpanded, setIsExpanded] = useState(value ? (value as boolean) : openByImagesGroupField ?? false);
  const {
    DeclarationStore: { verifRequiredField, setVerifRequiredField, requiredField, scrollTarget },
  } = useStore();
  useEffect(() => setIsExpanded(forceExpand), [forceExpand]);

  // const handleChange = useCallback(
  //   (_event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
  //     setIsExpanded(expanded);
  //     updateDeclaration(fullPathId, expanded);
  //   },
  //   [fullPathId, updateDeclaration],
  // );

  useEffect(() => {
    if (!isExpanded) {
      setSelectedIndex(undefined);
    }
  }, [isExpanded, setSelectedIndex]);
  const handleChange = useCallback(
    (expanded: boolean) => {
      setIsExpanded(!expanded);
      updateDeclaration(fullPathId, !expanded);
    },
    [fullPathId, updateDeclaration],
  );
  useEffect(() => {
    if (verifRequiredField) {
      if (requiredField && requiredField?.fullPathId.includes(fullPathId)) {
        handleChange(!verifRequiredField);
        //  setVerifRequiredField(false);
      }
    }
  }, [fullPathId, handleChange, requiredField, verifRequiredField]);
  return {
    t,
    nameWithId,
    isExpanded,
    handleChange,
  };
};

export default useData;
