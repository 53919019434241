import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { convertToBooleanValue } from '80.quickConnect.Core/helpers';
import { isFloat, isNumeric } from '80.quickConnect.Core/helpers/common';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';
import { numericLocaleFormatLitteral } from '80.quickConnect.Core/helpers/numericLocaleFormatLitteral';

export const getReferencedComputeValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
): AllFieldValueTypes => {
  const computeValue = typeof valueReferenceDependency === 'string' ? valueReferenceDependency : undefined;

  if (!computeValue) return undefined;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue: {
      if (!isNumeric(computeValue)) return computeValue;

      const numValue = parseNumber(computeValue);
      return numericLocaleFormatLitteral(numValue);
    }

    case FieldType.CheckBox:
      return convertToBooleanValue(computeValue);

    case FieldType.Counter:
    case FieldType.Digits: {
      // Cas particulier depuis l'adaption avec les locales
      const numValue = parseNumber(computeValue);
      return isNumeric(numValue) ? parseNumber(numValue.toFixed(0)) : undefined;
    }

    case FieldType.Numeric: {
      // Cas particulier depuis l'adaption avec les locales
      const numValue = parseNumber(computeValue);
      if (numValue === undefined) return undefined;
      return numValue;
    }

    default:
      return undefined;
  }
};
