import { AllFieldValueTypes, TodoTask } from '90.quickConnect.Models/models';

export const isATodoListData = (value: AllFieldValueTypes): value is TodoTask =>
  !Array.isArray(value) &&
  typeof value === 'object' &&
  value !== null &&
  'id' in value &&
  'name' in value &&
  'state' in value &&
  'taskEditedData' in value &&
  'todoListId' in value;
