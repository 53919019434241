const findSeparator = (input: string): string | null => {
  let insideQuotes = false;
  let separator: string | null = null;
  let quoteType: string | null = null;

  for (let i = 0; i < input.length; i++) {
    const char = input[i];

    if (char === '"' || char === "'") {
      if (quoteType === null) {
        quoteType = char;
        // Si nous trouvons un guillemet, basculer l'état insideQuotes
        insideQuotes = !insideQuotes;
      } else {
        if (char === quoteType) {
          insideQuotes = !insideQuotes;
          quoteType = null;
        }
      }
    } else if (char === ',' && !insideQuotes) {
      // Si nous trouvons une virgule en dehors des guillemets et qu'aucun séparateur n'a été trouvé auparavant, définir le séparateur sur la virgule
      if (separator === null) {
        separator = ',';
      }
    } else if (char === '|' && !insideQuotes) {
      // Si nous trouvons un pipe (|) en dehors des guillemets et qu'aucun séparateur n'a été trouvé auparavant, définir le séparateur sur le pipe
      if (separator === null) {
        separator = '|';
      }
    }
  }

  return separator;
};

const isCommaSeparator = (str: string): boolean => {
  return findSeparator(str) === ',';
};

const haveSeparator = (str: string): boolean => findSeparator(str) === ',' || findSeparator(str) === '|';

const joinCommaSeparator = (strs: string[]): string => {
  // Faut vérifier si les choix sélectionnés contiennent une virgule
  return strs
    .map((str: string) => {
      // Le cas où il n'y a pas ni guillemets ni simple quotes
      if (!str.includes("'") && !str.includes('"')) return `"${str}"`;

      // Et les 2 cas tordus....
      if (str.includes("'") && !str.includes('"')) return `"${str}"`;
      if (!str.includes("'") && str.includes('"')) return `'${str}'`;

      // Le cas le plus simple
      if (!str.includes(',')) return str;

      return '';
    })
    .join(',');
};

const joinPipeSeparator = (strs: string[]): string => {
  // Faut vérifier si les choix sélectionnés contiennent une virgule
  return strs
    .map((str: string) => {
      // Le cas où il n'y a pas ni guillemets ni simple quotes
      if (!str.includes("'") && !str.includes('"')) return `"${str}"`;

      // Et les 2 cas tordus....
      if (str.includes("'") && !str.includes('"')) return `"${str}"`;
      if (!str.includes("'") && str.includes('"')) return `'${str}'`;

      // Le cas le plus simple
      if (!str.includes('|')) return str;

      return '';
    })
    .join('|');
};

const splitCommaSeparator = (str?: string): string[] => {
  if (!str) return [];

  const result: string[] = [];
  let currentSegment = '';

  let insideQuotes = false;
  let quoteType: string | null = null;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === '"' || char === "'") {
      if (quoteType === null) {
        // Si nous trouvons un guillemet, basculer l'état insideQuotes
        insideQuotes = !insideQuotes;
        quoteType = char;
      } else if (quoteType === char) {
        insideQuotes = !insideQuotes;
        quoteType = null;
      } else {
        currentSegment += char;
      }
    } else if (char === ',' && !insideQuotes) {
      // Si nous trouvons une virgule en dehors des guillemets, ajouter le segment actuel à la liste des résultats
      result.push(currentSegment.trim());
      currentSegment = '';
    } else {
      // Sinon, ajouter le caractère au segment actuel
      currentSegment += char;
    }
  }

  // Ajouter le dernier segment à la liste des résultats
  result.push(currentSegment.trim());

  return result;
};

const splitPipeSeparator = (str?: string): string[] => {
  if (!str) return [];

  const result: string[] = [];
  let currentSegment = '';

  let insideQuotes = false;
  let quoteType: string | null = null;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === '"' || char === "'") {
      if (quoteType === null) {
        // Si nous trouvons un guillemet, basculer l'état insideQuotes
        insideQuotes = !insideQuotes;
        quoteType = char;
      } else if (quoteType === char) {
        insideQuotes = !insideQuotes;
        quoteType = null;
      } else {
        currentSegment += char;
      }
    } else if (char === '|' && !insideQuotes) {
      // Si nous trouvons une virgule en dehors des guillemets, ajouter le segment actuel à la liste des résultats
      result.push(currentSegment.trim());
      currentSegment = '';
    } else {
      // Sinon, ajouter le caractère au segment actuel
      currentSegment += char;
    }
  }

  // Ajouter le dernier segment à la liste des résultats
  result.push(currentSegment.trim());

  return result;
};

export {
  splitCommaSeparator,
  splitPipeSeparator,
  joinCommaSeparator,
  joinPipeSeparator,
  isCommaSeparator,
  haveSeparator,
};
