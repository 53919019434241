import React from 'react';
import useData from './hooks';
import { ComputedFieldsResolverProps } from './types';
import ComputedFieldResolver from './ComputedFieldResolver';

/**
 * Ce composant permet des géré les ComputedFields et de déterminer ses dépendances
 * @param param0
 * @returns
 */
const ComputedFieldsResolver = ({
  children,
  flattenFields,
  updateDeclaration,
  updateFieldErrors,
}: ComputedFieldsResolverProps) => {
  const { allComputeFields } = useData(flattenFields);

  return (
    <>
      {allComputeFields.map((computeField) => (
        <ComputedFieldResolver
          key={computeField.fullPathId}
          computeField={computeField}
          updateDeclaration={updateDeclaration}
          otherFields={flattenFields.filter((f) => f.fullPathId !== computeField.fullPathId)}
          updateFieldErrors={updateFieldErrors}
        />
      ))}
      {children}
    </>
  );
};

export default ComputedFieldsResolver;
