import React from 'react';
import { observer } from 'mobx-react';
import { Box, FormControl, useTheme } from '@mui/material';

import { FixedImageQcFieldName } from './const';

import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import {
  FixedImage,
  FixedImageType,
} from '90.quickConnect.Models/models/fields/fieldDesc/attachmentField/fixedImageDesc';

const FixedImageQcField = ({
  field,
  isReadOnly,
  labelSpan,
  setSelectedIndex,
}: // updateDeclaration,
ValueFieldsTypesProps) => {
  const { id, toolTip, value, fullPathId } = field as FixedImageType;

  const fixedImage = (Array.isArray(value) ? value.at(0) : {}) as FixedImage;
  const { distantUri = '', thumbnail = '', title = '' } = fixedImage;

  const nameWithId = `${FixedImageQcFieldName}-${id}`;

  const { breakpoints } = useTheme();

  return (
    <Box
      data-cy={`${nameWithId}-box`}
      onClick={isReadOnly ? undefined : () => setSelectedIndex(fullPathId)}
      sx={FieldMainBox(breakpoints)}
    >
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <FormControl sx={{ display: 'flex', alignItems: 'center' }} data-cy={`${nameWithId}-control`} variant="outlined">
        <img
          alt={title}
          height={250}
          onClick={() => window.open(distantUri)}
          src={thumbnail ? `data:image/png;base64, ${thumbnail}` : distantUri ? distantUri : ''}
          style={{ cursor: distantUri ? 'pointer' : 'not-allowed', maxWidth: '500px', objectFit: 'contain' }}
        />
      </FormControl>
    </Box>
  );
};

export default observer(FixedImageQcField);
