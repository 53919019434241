import styled from '@emotion/styled';
import { ListItemText, SxProps, Theme, Tabs } from '@mui/material';
import {
  FullHeightWithoutTopBar,
  FullHeightWithoutTopInboxesBar,
  OverflowForDesktop,
  OverflowForMobile,
} from '10.quickConnect.app/components/shared/LayOut/TopBar/style';
import { dippedGreen500, slateGrey300, slateGrey700 } from '00.app/theme';

export const C2SLogo = styled.img`
  max-height: 60px;
  width: auto;

  display: none;
  ${(props) => (props.theme as any).breakpoints.up('lg')} {
    display: flex;
  }
`;

export const getListItemButtonStyle = (theme: Theme): SxProps => ({
  [theme.breakpoints.down('md')]: { paddingLeft: 0, paddingRight: 0 },
});

export const getAvatarStyle = (theme: Theme): SxProps => ({
  // backgroundColor: theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.info.main,
  backgroundColor: dippedGreen500,
  [theme.breakpoints.up('md')]: {
    width: 40,
    height: 40,
    fontSize: 20, // définit la taille l'icon
    mr: 2,
  },
  [theme.breakpoints.down('md')]: {
    width: 35,
    height: 35,
    fontSize: 20, // définit la taille l'icon
  },
});

export const getAvatarDeclarationStyle = (theme: Theme): SxProps => ({
  backgroundColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    width: 40,
    height: 40,
    fontSize: 20, // définit la taille l'icon
    mr: 2,
  },
  [theme.breakpoints.down('md')]: {
    width: 35,
    height: 35,
    fontSize: 20, // définit la taille l'icon
  },
});

const AvatarWidth = 36;
const AvatarWidthSmall = 44;

export const CustomTabs = styled(Tabs)`
  & .MuiTab-root {
    color: ${(props) => (props.theme as Theme).palette.lightThemeTextColor.main};
  }
`;

export const CustomListItemText = styled(ListItemText)`
  & > .MuiListItemText-primary {
    font-size: 1em !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & > .MuiListItemText-secondary {
    font-size: 1em !important;
  }

  ${(props) => (props.theme as any).breakpoints.down('md')} {
    width: 90vw;
    & > .MuiListItemText-primary {
      max-width: 95%;
    }
  }
  ${(props) => (props.theme as any).breakpoints.up('md')} {
    width: 100%;
    & > .MuiListItemText-primary {
      max-width: 95%;
    }
  }
`;

export const linkStyle: SxProps = { width: '98%' };

export const getListItemAvatarStyle = (theme: Theme): SxProps => ({
  [theme.breakpoints.up('md')]: {
    minWidth: `${AvatarWidth}px`,
  },
  [theme.breakpoints.down('md')]: {
    minWidth: `${AvatarWidthSmall}px`,
  },
});

export const getDuplicateIconStyle = (): SxProps => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: 'transparent',
  outline: 0,
  border: 0,
  margin: 0,
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textAlign: 'center',
  flex: '0 0 auto',
  width: '2rem',
  overflow: 'visible',
  padding: '0.5em',
  fontSize: '1.5rem',
  borderRadius: '50%',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
});

export const ListColumnOrRowContainer = (theme: Theme): SxProps => ({
  flex: 1,
  display: 'flex',
  width: { md: '80%', lg: '80%' },
  height: FullHeightWithoutTopBar,
  margin: { md: '16px auto 0px', lg: '16px auto 0px' },
  marginBottom: { xs: '90px', sm: '90px' },
  border:
    theme.palette.mode === 'dark'
      ? { md: `1px solid ${slateGrey700}`, lg: `1px solid ${slateGrey700}` }
      : { md: `1px solid ${slateGrey300}`, lg: `1px solid ${slateGrey300}` },
  borderRadius: { md: '10px', lg: '10px' },
  flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
});

export const ListColumnOrRowForCustomListContainer = (theme: Theme): SxProps => ({
  alignSelf: 'flex-end',
  display: 'flex',
  width: { md: '80%', lg: '80%' },
  overflowY: OverflowForDesktop,
  margin: { md: '16px auto 0px', lg: '16px auto 0px' },
  border: theme.palette.mode === 'dark' ? `1px solid ${slateGrey700}` : `1px solid ${slateGrey300}`,
  borderRadius: { xs: 'none', sm: 'none', md: '10px', lg: '10px' },
  flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
});

export const ListColumnForCustomListContainer = (theme: Theme): SxProps => ({
  alignSelf: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  overflowY: OverflowForDesktop,
  margin: { md: '16px auto 0px', lg: '16px auto 0px' },
  border:
    theme.palette.mode === 'dark'
      ? { md: `1px solid ${slateGrey700}`, lg: `1px solid ${slateGrey700}` }
      : { md: `1px solid ${slateGrey300}`, lg: `1px solid ${slateGrey300}` },
  borderRadius: { xs: 'none', sm: 'none', md: '10px', lg: '10px' },
  height: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 200px)', lg: 'calc(100vh - 200px)' },

  width: { md: '75%', lg: '75%' },
  padding: { md: '20px', lg: '20px' },
});

export const ListColumnContainer: SxProps = {
  padding: '.3rem .5rem 0',
  display: 'flex',
  flexDirection: 'column',
  overflowY: OverflowForDesktop,
  height: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 250px)', lg: 'calc(100vh - 250px)' },
  width: '100%',
  borderRadius: { xs: 'none', sm: 'none', md: '10px', lg: '10px' },
};

export const getTreeBoxStyle = (hideScrollBar?: boolean | undefined): SxProps => ({
  height: hideScrollBar ? 'auto' : FullHeightWithoutTopBar,
  minWidth: { md: '10em', lg: '20em' },
  maxWidth: { md: '99%', lg: '99%' },
  width: '99%',
});

export const TabsMainBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: FullHeightWithoutTopBar,
};

export const ListColumnForUserSettingListContainer = (theme: Theme): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  margin: { md: '16px auto', lg: '16px auto' },
  border:
    theme.palette.mode === 'dark'
      ? { md: `1px solid ${slateGrey700}`, lg: `1px solid ${slateGrey700}` }
      : { md: `1px solid ${slateGrey300}`, lg: `1px solid ${slateGrey300}` },
  borderRadius: { xs: 'none', sm: 'none', md: '10px', lg: '10px' },
  height: FullHeightWithoutTopBar,
  width: { md: '90%', lg: '90%' },
  padding: { md: '20px', lg: '20px' },
});

export const ListColumnForFormContainer = (theme: Theme): SxProps => ({
  ...ListColumnOrRowContainer(theme),
});
