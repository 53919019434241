export class ModuleMethodInfo {
  moduleId = 0;

  methodId = 0;

  methodName = '';

  nbArg = 0;

  nbArgOpt = 0;

  isStatic = false;

  returnType = 0;

  constructor(init?: Partial<ModuleMethodInfo>) {
    Object.assign(this, init);
  }
}
