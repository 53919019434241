import {
  AllFieldValueTypes,
  AttachmentItemData,
  Choice,
  FieldData,
  HierarchicalChoice,
} from '90.quickConnect.Models/models';
import { HierarchicalListData } from '90.quickConnect.Models/models/fields/values/hierarchicalListData';

export const isAnAttachmentItemData = (
  input: string | Choice | HierarchicalChoice | AttachmentItemData | HierarchicalListData | FieldData,
): boolean =>
  typeof input === 'object' &&
  input !== null &&
  'localUri' in input &&
  'distantUri' in input &&
  'title' in input &&
  'fileName' in input &&
  'creationDate' in input &&
  'purgeDate' in input &&
  'type' in input &&
  'size' in input &&
  'thumbnail' in input &&
  'metadata' in input;

export const isAnAttachmentItemsData = (value: AllFieldValueTypes): boolean =>
  Array.isArray(value) &&
  value.some(
    (data: string | Choice | HierarchicalChoice | AttachmentItemData | HierarchicalListData | FieldData) =>
      !isAnAttachmentItemData(data),
  ) === false;
