import { TFunction } from 'i18next';
import { OperatorResult, QcOperator } from '90.quickConnect.Models/enums';
import roundTo from '80.quickConnect.Core/helpers/roundEpsilon';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import { parseNumber } from '80.quickConnect.Core/helpers/parseNumber';

export const operationWithNumbers = (
  ref1Value: AllFieldValueTypes,
  ref2Value: AllFieldValueTypes,
  type: QcOperator,
  precision: number | undefined,
  t: TFunction,
): [AllFieldValueTypes, OperatorResult] => {
  const int1 = Number.isNaN(Number(ref1Value)) ? '' : Number(ref1Value);
  const int2 = Number.isNaN(Number(ref2Value)) ? '' : Number(ref2Value);
  switch (type) {
    case QcOperator.Add:
      if (typeof int1 === 'number' && typeof int2 === 'number') {
        return [+roundTo(int1 + int2, precision), precision === 0 ? OperatorResult.INT : OperatorResult.DOUBLE];
      } else if (int1 === '' && typeof int2 === 'number') {
        return operationWithNumbers('0', int2, type, precision, t);
      } else if (int2 === '' && typeof int1 === 'number') {
        return operationWithNumbers(int1, '0', type, precision, t);
      } else {
        return ['', OperatorResult.STRING];
      }
    case QcOperator.Subst:
      if (typeof int1 === 'number' && typeof int2 === 'number') {
        return [+roundTo(int1 - int2, precision), precision === 0 ? OperatorResult.INT : OperatorResult.DOUBLE];
      } else if (int1 === '' && typeof int2 === 'number') {
        return operationWithNumbers(0, int2, type, precision, t);
      } else if (int2 === '' && typeof int1 === 'number') {
        return operationWithNumbers(int1, 0, type, precision, t);
      } else {
        return ['', OperatorResult.STRING];
      }
    case QcOperator.Mult: {
      const num1Val = parseNumber(ref1Value);
      const num2Val = parseNumber(ref2Value);
      return num1Val === undefined || num2Val === undefined || isNaN(num1Val) || isNaN(num2Val)
        ? [t('computed_fields_errors_eval_error').toString(), OperatorResult.ERROR]
        : [+roundTo(num1Val * num2Val, precision), precision === 0 ? OperatorResult.INT : OperatorResult.DOUBLE];
    }
    case QcOperator.Div:
      const num1Val = parseNumber(ref1Value);
      const num2Val = parseNumber(ref2Value);
      if (num1Val === undefined || num2Val === undefined || isNaN(num1Val) || isNaN(num2Val)) {
        return [t('computed_fields_errors_eval_error').toString(), OperatorResult.ERROR];
      }
      const result = +roundTo(num1Val / num2Val, precision);
      return isFinite(result)
        ? [result, Number.isInteger(result) ? OperatorResult.INT : OperatorResult.DOUBLE]
        : [t('computed_fields_errors_operation_unauthorized').toString(), OperatorResult.ERROR];
    default:
      return [t('computed_fields_errors_operator_type_unknown').toString(), OperatorResult.ERROR];
  }
};
