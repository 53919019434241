import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import { SeparatorQCFieldBoxStyle, SeparatorQCFieldTypoStyle } from './styles';
import { SeparatorQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';

const SeparatorQcField = ({ field, labelSpan }: ValueFieldsTypesProps) => {
  const { id, label, toolTip } = field;
  const nameWithId = `${SeparatorQcFieldName}-${id}`;

  return label ? (
    <>
      <Box data-cy={nameWithId} sx={SeparatorQCFieldBoxStyle}>
        <Typography component="span" sx={SeparatorQCFieldTypoStyle}>
          {labelSpan} {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
        </Typography>
      </Box>
    </>
  ) : (
    <Box data-cy={nameWithId} sx={SeparatorQCFieldBoxStyle}>
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );
};
export default observer(SeparatorQcField);
