import { useScrollTrigger } from '@mui/material';
import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Stores';

const useData = (idToScrollTo: string, target: Node | Window | undefined): UseDataProps => {
  const {
    DeclarationStore: { setVerifRequiredField },
  } = useStore();
  const { t } = useTranslation('declaration');
  const debouncedUpdateGlobalState = useDebouncedCallback(
    (event?: React.MouseEvent<HTMLDivElement>) => {
      let parentNode: ParentNode;
      if (target && (target as ParentNode)) parentNode = target as ParentNode;
      else parentNode = (event?.target as HTMLDivElement).ownerDocument || document;
      if (parentNode) {
        const anchor = document.getElementById(`scrollToAnchor-${idToScrollTo}`);
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
          // setVerifRequiredField(true);
        }
      }
    },
    idToScrollTo.includes('.') ? 1000 : 0,
  );

  const handleClick = useCallback(
    (event?: React.MouseEvent<HTMLDivElement>) => {
      setVerifRequiredField(true);
      debouncedUpdateGlobalState(event);
      toast.error(t('formlib_message_invalid').toString());
    },
    [debouncedUpdateGlobalState, setVerifRequiredField, t],
  );
  /*source : https://mui.com/components/app-bar/#back-to-top
    options (object [optional]):
      options.disableHysteresis (bool [optional]): Defaults to false. Disable the hysteresis. Ignore the scroll direction when determining the trigger value.
      options.target (Node [optional]): Defaults to window.
      options.threshold (number [optional]): Defaults to 100. Change the trigger value when the vertical scroll strictly crosses this threshold (exclusive).
      */
  const trigger = useScrollTrigger({ target: target });

  return {
    handleClick,
    trigger,
  };
};

export default useData;
