// Gestion des modules QC

import { CaseInsensitiveMap } from '../utils/CaseInsensitiveMap';
import { ModuleMethodInfo } from './ModuleMethodInfo';
import { RegisteredMethodsById, RegisteredMethodsByName, RegisteredModules } from './types/QCSModules';

// - FormContext
export class QCSModules {
  private _registeredModules: RegisteredModules = new CaseInsensitiveMap();

  private _registeredMethodsByName: RegisteredMethodsByName = new CaseInsensitiveMap();

  private _registeredMethodsById: RegisteredMethodsById = new CaseInsensitiveMap();

  qcsFormContext = 1;

  qcsString = 2;

  qcsDate = 3;

  qcsMath = 4;

  qcsArray = 5;

  qcsBool = 6;

  qcsChoiceList = 7;

  qcsChoice = 8;

  qcsPattern = 9;

  qcsMatcher = 10;

  qcsLong = 11;

  qcsInt = 12;

  qcsDouble = 15;

  constructor() {
    this.registerModules();
  }

  /* eslint max-lines-per-function: off */
  private registerModules(): void {
    // FormContext
    if (!this._registeredModules.has('FormContext')) this._registeredModules.set('FormContext', this.qcsFormContext);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'setValue',
        returnType: this.qcsBool,
        methodId: 1,
        nbArg: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsBoolean',
        returnType: this.qcsBool,
        methodId: 2,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsDouble',
        returnType: this.qcsDouble,
        methodId: 3,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsInt',
        returnType: this.qcsInt,
        methodId: 4,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsDate',
        returnType: this.qcsDate,
        methodId: 5,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsChoice',
        returnType: this.qcsChoice,
        methodId: 6,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsChoiceList',
        returnType: this.qcsChoiceList,
        methodId: 7,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getValAsText',
        returnType: this.qcsString,
        methodId: 8,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'setReadOnly',
        methodId: 9,
        nbArg: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'setVisible',
        methodId: 10,
        nbArg: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'showWarning',
        methodId: 11,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'showInformation',
        methodId: 12,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'debug',
        methodId: 13,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getReadOnly',
        methodId: 14,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getFormName',
        methodId: 15,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'setMandatory',
        methodId: 16,
        nbArg: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'sumValAsInt',
        returnType: this.qcsInt,
        methodId: 17,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'sumValAsDouble',
        returnType: this.qcsDouble,
        methodId: 18,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'getFormContext',
        methodId: 21,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsFormContext,
        methodName: 'count',
        methodId: 22,
        nbArg: 0,
        nbArgOpt: 0,
      }),
    );

    // String
    this._registeredModules.set('QCString', this.qcsString);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'compare',
        returnType: this.qcsInt,
        methodId: 1,
        nbArg: 3,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'contains',
        returnType: this.qcsBool,
        methodId: 2,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'startsWith',
        returnType: this.qcsBool,
        methodId: 3,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'endsWith',
        returnType: this.qcsBool,
        methodId: 4,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'indexOf',
        returnType: this.qcsInt,
        methodId: 5,
        nbArg: 1,
        nbArgOpt: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'lastIndexOf',
        returnType: this.qcsInt,
        methodId: 6,
        nbArg: 1,
        nbArgOpt: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'length',
        returnType: this.qcsInt,
        methodId: 7,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'insert',
        returnType: this.qcsString,
        methodId: 8,
        nbArg: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'isNullOrEmpty',
        returnType: this.qcsBool,
        methodId: 9,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'remove',
        returnType: this.qcsString,
        methodId: 10,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'replace',
        returnType: this.qcsString,
        methodId: 11,
        nbArg: 2,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'substring',
        returnType: this.qcsString,
        methodId: 12,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'toLower',
        returnType: this.qcsString,
        methodId: 13,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'toUpper',
        returnType: this.qcsString,
        methodId: 14,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'trim',
        returnType: this.qcsString,
        methodId: 15,
        nbArg: 0,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'format',
        returnType: this.qcsString,
        methodId: 16,
        nbArg: 1,
        nbArgOpt: 16,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'padLeft',
        returnType: this.qcsString,
        methodId: 17,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'padRight',
        returnType: this.qcsString,
        methodId: 18,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'split',
        returnType: this.qcsArray,
        methodId: 19,
        nbArg: 1,
        nbArgOpt: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'compareTo',
        returnType: this.qcsInt,
        methodId: 20,
        nbArg: 1,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsString,
        methodName: 'join',
        returnType: this.qcsString,
        methodId: 21,
        nbArg: 2,
        nbArgOpt: 1,
        isStatic: true,
      }),
    );

    // Date
    this._registeredModules.set('QCDate', this.qcsDate);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'undef',
        methodId: 1,
        nbArg: 0,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'now',
        returnType: this.qcsDate,
        methodId: 2,
        nbArg: 0,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'utcNow',
        returnType: this.qcsDate,
        methodId: 3,
        nbArg: 0,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'today',
        returnType: this.qcsDate,
        methodId: 4,
        nbArg: 0,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getDate',
        returnType: this.qcsDate,
        methodId: 5,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getDay',
        returnType: this.qcsInt,
        methodId: 6,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getDayOfWeek',
        returnType: this.qcsInt,
        methodId: 7,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getDayOfYear',
        returnType: this.qcsInt,
        methodId: 8,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getHour',
        returnType: this.qcsInt,
        methodId: 9,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'isUtcTime',
        returnType: this.qcsBool,
        methodId: 10,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getMillisecond',
        returnType: this.qcsInt,
        methodId: 11,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getMinute',
        returnType: this.qcsInt,
        methodId: 12,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getMonth',
        returnType: this.qcsInt,
        methodId: 13,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getSecond',
        returnType: this.qcsInt,
        methodId: 14,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getTimeOfDay',
        returnType: this.qcsDouble,
        methodId: 15,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getYear',
        returnType: this.qcsInt,
        methodId: 16,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'addDays',
        returnType: this.qcsDate,
        methodId: 17,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'addHours',
        returnType: this.qcsDate,
        methodId: 18,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'addMilliseconds',
        returnType: this.qcsDate,
        methodId: 19,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'addMinutes',
        returnType: this.qcsDate,
        methodId: 20,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'addMonths',
        returnType: this.qcsDate,
        methodId: 21,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'addSeconds',
        returnType: this.qcsDate,
        methodId: 22,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'compare',
        returnType: this.qcsInt,
        methodId: 23,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'parse',
        returnType: this.qcsDate,
        methodId: 24,
        nbArg: 1,
        nbArgOpt: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'toLocalTime',
        returnType: this.qcsDate,
        methodId: 25,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'toString',
        returnType: this.qcsString,
        methodId: 26,
        nbArg: 0,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'toUtcDatetime',
        returnType: this.qcsDate,
        methodId: 27,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'create',
        returnType: this.qcsDate,
        methodId: 28,
        nbArg: 3,
        nbArgOpt: 3,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDate,
        methodName: 'getTime',
        returnType: this.qcsLong,
        methodId: 29,
        nbArg: 0,
        nbArgOpt: 0,
      }),
    );

    // Math
    this._registeredModules.set('QCMath', this.qcsMath);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'abs',
        methodId: 1,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'acos',
        methodId: 2,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'acosh',
        methodId: 3,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'asin',
        methodId: 4,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'asinh',
        methodId: 5,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'atan',
        methodId: 6,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'atan2',
        methodId: 7,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'atanh',
        methodId: 8,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'ceiling',
        methodId: 9,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'cos',
        methodId: 10,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'cosh',
        methodId: 11,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'exp',
        methodId: 12,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'floor',
        methodId: 13,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'ieeeRemainder',
        methodId: 14,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'log',
        methodId: 15,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'logBase',
        methodId: 16,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'log10',
        methodId: 17,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'maxInt',
        methodId: 18,
        returnType: this.qcsInt,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'maxDouble',
        methodId: 19,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'minInt',
        methodId: 20,
        returnType: this.qcsInt,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'minDouble',
        methodId: 21,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'roundMid',
        methodId: 22,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'roundDigMid',
        methodId: 23,
        returnType: this.qcsDouble,
        nbArg: 3,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'roundDig',
        methodId: 24,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'round',
        methodId: 25,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'sign',
        methodId: 26,
        returnType: this.qcsInt,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'sin',
        methodId: 27,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'sinh',
        methodId: 28,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'sqrt',
        methodId: 29,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'tan',
        methodId: 30,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'tanh',
        methodId: 31,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'truncate',
        methodId: 32,
        returnType: this.qcsDouble,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMath,
        methodName: 'pow',
        methodId: 33,
        returnType: this.qcsDouble,
        nbArg: 2,
        isStatic: true,
      }),
    );

    // Array
    this._registeredModules.set('QCArray', this.qcsArray);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsArray,
        methodName: 'length',
        returnType: this.qcsInt,
        methodId: 1,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsArray,
        methodName: 'size',
        returnType: this.qcsInt,
        methodId: 2,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsArray,
        methodName: 'set',
        methodId: 3,
        nbArg: 2,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsArray,
        methodName: 'append',
        methodId: 4,
        nbArg: 1,
      }),
    );

    // Boolean
    this._registeredModules.set('QCBool', this.qcsBool);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsBool,
        methodName: 'toString',
        returnType: this.qcsString,
        methodId: 1,
        nbArg: 0,
        nbArgOpt: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsBool,
        methodName: 'parse',
        returnType: this.qcsBool,
        methodId: 2,
        nbArg: 1,
        isStatic: true,
      }),
    );

    // QCChoiceList
    this._registeredModules.set('QCChoiceList', this.qcsChoiceList);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoiceList,
        methodName: 'length',
        returnType: this.qcsInt,
        methodId: 1,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoiceList,
        methodName: 'size',
        returnType: this.qcsInt,
        methodId: 2,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoiceList,
        methodName: 'get',
        returnType: this.qcsChoice,
        methodId: 3,
        nbArg: 1,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoiceList,
        methodName: 'toDisplayString',
        returnType: this.qcsString,
        methodId: 4,
        nbArg: 0,
      }),
    );

    // QCChoice
    this._registeredModules.set('QCChoice', this.qcsChoice);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoice,
        methodName: 'getChoiceValue',
        returnType: this.qcsString,
        methodId: 1,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoice,
        methodName: 'getChoiceLabel',
        returnType: this.qcsString,
        methodId: 2,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoice,
        methodName: 'getLabel',
        returnType: this.qcsString,
        methodId: 3,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsChoice,
        methodName: 'getPropertyValue',
        returnType: this.qcsString,
        methodId: 4,
        nbArg: 1,
      }),
    );

    // QCPattern
    this._registeredModules.set('QCPattern', this.qcsPattern);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsPattern,
        methodName: 'compile',
        returnType: this.qcsPattern,
        methodId: 1,
        nbArg: 1,
        isStatic: true,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsPattern,
        methodName: 'matcher',
        returnType: this.qcsMatcher,
        methodId: 2,
        nbArg: 1,
      }),
    );

    this._registeredModules.set('QCMatcher', this.qcsMatcher);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMatcher,
        methodName: 'find',
        returnType: this.qcsBool,
        methodId: 1,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMatcher,
        methodName: 'groupCount',
        returnType: this.qcsInt,
        methodId: 2,
        nbArg: 0,
      }),
    );
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsMatcher,
        methodName: 'group',
        returnType: this.qcsString,
        methodId: 3,
        nbArg: 1,
      }),
    );

    this._registeredModules.set('QCLong', this.qcsLong);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsLong,
        methodName: 'toString',
        returnType: this.qcsString,
        methodId: 1,
        nbArg: 0,
      }),
    );

    this._registeredModules.set('QCInt', this.qcsInt);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsInt,
        methodName: 'toString',
        returnType: this.qcsString,
        methodId: 1,
        nbArg: 0,
      }),
    );

    this._registeredModules.set('QCDouble', this.qcsDouble);
    this.registerModuleMethod(
      new ModuleMethodInfo({
        moduleId: this.qcsDouble,
        methodName: 'toString',
        returnType: this.qcsString,
        methodId: 1,
        nbArg: 0,
      }),
    );

    //Modules suivants
    // ...
  }

  private registerModuleMethod(mmi: ModuleMethodInfo): void {
    const { moduleId, methodId, methodName } = mmi;
    const byId: CaseInsensitiveMap<number, ModuleMethodInfo> = new CaseInsensitiveMap();
    byId.set(methodId, mmi);

    if (!this._registeredMethodsById.has(moduleId)) {
      // Le dictionnaire n'existe pas dans this._registeredMethodsById, il faut le créer!!
      this._registeredMethodsById.set(moduleId, byId);
    } else {
      this._registeredMethodsById.get(moduleId)?.set(methodId, mmi);
    }

    const byName: CaseInsensitiveMap<string, ModuleMethodInfo> = new CaseInsensitiveMap();
    byName.set(methodName, mmi);

    if (!this._registeredMethodsByName.has(moduleId)) {
      // Le dictionnaire n'existe pas dans this._registeredMethodsByName, il faut le créer!!
      this._registeredMethodsByName.set(moduleId, byName);
    } else {
      this._registeredMethodsByName.get(moduleId)?.set(methodName, mmi);
    }
  }

  /**
   * Détermine si la méthode externe que le script veut appeler est reconnue
   *
   * @param {number} moduleId
   * @param {number} methodId
   * @return {*}  {{ mmi?: ModuleMethodInfo; errMessage: string; isExist: boolean }}
   * @memberof QCSModules
   */
  public getModuleMethodInfo(
    moduleId: number,
    methodId: number,
  ): { mmi?: ModuleMethodInfo; errMessage: string; isExist: boolean } {
    let errMessage = '';
    let isExist = true;

    const methodsForModule: CaseInsensitiveMap<number, ModuleMethodInfo> | undefined =
      this._registeredMethodsById.get(moduleId);
    if (!methodsForModule) {
      errMessage = `unregistered module ${moduleId}`;
      isExist = false;
      return {
        errMessage,
        isExist,
      };
    }

    const mmi: ModuleMethodInfo | undefined = methodsForModule.get(methodId);
    if (!mmi) {
      errMessage = `unregistered method {methodId} for module ${moduleId}`;
      isExist = false;
      return {
        errMessage,
        isExist,
      };
    }

    return { mmi, errMessage, isExist };
  }

  // /// <summary>
  // /// Retourne le message sur pb d'argument
  // /// </summary>
  // /// <param name="mmi"></param>
  // /// <returns></returns>
  // public string GetArgumentOutOfRangeDetail(ModuleMethodInfo mmi)
  // {
  //     string moduleName = "<unknow>";
  //     foreach (KeyValuePair<string, int> kvp in _registeredModules)
  //     {
  //         if (kvp.Value == mmi.ModuleId)
  //         {
  //             moduleName = kvp.Key;
  //             break;
  //         }
  //     }

  //     string plurielArg = mmi.NbArg > 1 ? "s" : "";
  //     if (mmi.NbArgOpt == 0)
  //         return $"{moduleName}.{mmi.MethodName} must have {mmi.NbArg} argument{plurielArg}";
  //     else
  //         return $"{moduleName}.{mmi.MethodName} must have {mmi.NbArg} argument{plurielArg} and {mmi.NbArgOpt} optional";
  // }
}
