import React from 'react';
import { Button, FormControlLabel, Paper, Tooltip, Typography, Switch } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Box } from '@mui/system';
import { DividerStyle } from '../../Declarations/DeclarationsToolBar/styles';
import { FormsToolBarProps } from './types';
import { MainBoxStyle } from './styles';
import { useData } from './hooks';
import { FormsToolBarName } from './const';
import { hideOnMobile } from '20.formLib/sharedStyles';
import SearchBar from '10.quickConnect.app/components/shared/SearchBar';

// eslint-disable-next-line no-empty-pattern
const FormsToolBar = ({}: FormsToolBarProps) => {
  const { t } = useTranslation(['forms', 'common']);
  const {
    applyFilter,
    clearFilter,
    filterInputValue,
    setFilterInputValue,
    filter,
    handleExpandClick,
    isAllExpanded,
    formsTreeNodeKeys,
    numberFormsDisplayed,
    formsTotalNumber,
    formsAsTree,
    setFormsAsTree,
  } = useData();

  return (
    <Paper elevation={0} data-cy={FormsToolBarName} sx={MainBoxStyle}>
      <SearchBar
        toolTip={t('search_bar')}
        setFilter={setFilterInputValue}
        filterInputValue={filterInputValue}
        filter={filter}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        setSearchIconToRigth={false}
      />
      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>

      <Tooltip
        disableHoverListener={!formsAsTree}
        title={
          isAllExpanded
            ? t('qcapp_common_expand_all', { ns: 'common' }).toString()
            : t('qcapp_common_collapse_all', { ns: 'common' }).toString()
        }
      >
        <span>
          <Button
            sx={{ ...hideOnMobile, visibility: formsAsTree ? 'visible' : 'hidden' }}
            disabled={formsTreeNodeKeys.length === 0}
            variant="contained"
            startIcon={isAllExpanded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            onClick={handleExpandClick}
          >
            {isAllExpanded
              ? t('qcapp_common_expand_all', { ns: 'common' })
              : t('qcapp_common_collapse_all', { ns: 'common' })}
          </Button>
        </span>
      </Tooltip>
      <Box sx={{ ...hideOnMobile, ...DividerStyle }}></Box>

      {/* En mode mobile on n'affiche pas le bouton */}
      <Tooltip title={t('qcapp_common_switch_view_mode', { ns: 'common' }).toString()}>
        <FormControlLabel
          sx={{ ...hideOnMobile }}
          control={
            <Switch
              checked={formsAsTree}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setFormsAsTree(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('tree_view').toString()}
        />
      </Tooltip>
    </Paper>
  );
};
export default observer(FormsToolBar);
