import { QcOperator } from '90.quickConnect.Models/enums';
import {
  BaseQCOperator,
  ConstNumberOperator,
  ConstStringOperator,
  CtrlRefOperator,
  CtrlRefUserParemeterOperator,
  DualOperator,
  IIfOperator,
  MultipleOperator,
} from '90.quickConnect.Models/models';

const mapBaseConditionalOperator = (conditionalOperator: BaseQCOperator): BaseQCOperator => {
  const baseConditionalOperator = {
    id: conditionalOperator.id,
    type: conditionalOperator.type,
    conditionalOpeType: conditionalOperator.conditionalOpeType,
    label: conditionalOperator.label,
  } as BaseQCOperator;

  switch (conditionalOperator.type) {
    case QcOperator.ConstDouble:
      return {
        ...baseConditionalOperator,
        value: (conditionalOperator as ConstNumberOperator).value,
      } as ConstNumberOperator;
    case QcOperator.ConstString:
      return {
        ...baseConditionalOperator,
        value: (conditionalOperator as ConstStringOperator).value,
      } as ConstStringOperator;
    case QcOperator.Avg:
    case QcOperator.CtrlRef:
    case QcOperator.Sum:
    case QcOperator.CtrlRefReceiver:
      return {
        ...baseConditionalOperator,
        controlId: (conditionalOperator as CtrlRefOperator).controlId,
      } as CtrlRefOperator;
    case QcOperator.CtrlUserParameterRef:
      return {
        ...baseConditionalOperator,
        parameterId: (conditionalOperator as CtrlRefUserParemeterOperator).parameterId,
      } as CtrlRefUserParemeterOperator;
    case QcOperator.Equal:
    case QcOperator.Less:
    case QcOperator.Greater:
    case QcOperator.LessOrEqual:
    case QcOperator.GreaterOrEqual:
    case QcOperator.Different:
    case QcOperator.Contains:
    case QcOperator.NotContains:
    case QcOperator.ContainsText:
    case QcOperator.NotContainsText:
    case QcOperator.StartsWith:
    case QcOperator.EndsWith:
    case QcOperator.IsEmpty:
    case QcOperator.IsNotEmpty:
    case QcOperator.Parenthesis:
    case QcOperator.Or:
    case QcOperator.And:
    case QcOperator.Add:
    case QcOperator.Subst:
    case QcOperator.Mult:
    case QcOperator.Div:
      return {
        ...baseConditionalOperator,
        arg1: (conditionalOperator as DualOperator).arg1,
        arg2: (conditionalOperator as DualOperator).arg2,
      } as DualOperator;
    case QcOperator.IIf:
      return {
        ...baseConditionalOperator,
        condition: mapBaseConditionalOperator((conditionalOperator as IIfOperator).condition),
        argIfTrue: mapBaseConditionalOperator((conditionalOperator as IIfOperator).argIfTrue),
        argIfFalse: mapBaseConditionalOperator((conditionalOperator as IIfOperator).argIfFalse),
      } as IIfOperator;
    case QcOperator.Multi:
      return {
        ...baseConditionalOperator,
        args: (conditionalOperator as MultipleOperator).args.map(mapBaseConditionalOperator),
      } as MultipleOperator;
    case QcOperator.Unknow:
    case QcOperator.IsInMyInformation:
    case QcOperator.Container:
    default:
      return {
        ...baseConditionalOperator,
      };
  }
};

export default mapBaseConditionalOperator;
