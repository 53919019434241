import { AttachmentItemData, QCAttachment } from '90.quickConnect.Models/models';

const mapFromQCAttachmentToAttachmentItemData = (qcAttachment: QCAttachment): AttachmentItemData => ({
  id: qcAttachment.id,
  localUri: qcAttachment.localUri,
  distantUri: qcAttachment.distantUri,
  title: qcAttachment.docTitle,
  fileName: qcAttachment.docName,
  creationDate: qcAttachment.docCreateDate,
  purgeDate: qcAttachment.purgeDate,
  type: qcAttachment.docContentType,
  size: qcAttachment.file.size,
  thumbnail: qcAttachment.thumbnail,
  metadata: qcAttachment.metadata,
  file: qcAttachment.file,
});

export default mapFromQCAttachmentToAttachmentItemData;
