import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, Checkbox, FormControlLabel } from '@mui/material';
import useData from './hooks';
import { CheckBoxQcFieldName } from './const';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FlexMainBoxRow } from '50.quickConnect.Fields/styles';
import { CheckBoxDesc } from '90.quickConnect.Models/models';

const CheckBoxQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  labelClass,
}: ValueFieldsTypesProps) => {
  const checkBoxDesc = field as CheckBoxDesc;
  const { toolTip, label, id, fullPathId } = checkBoxDesc;
  const nameWithId = `${CheckBoxQcFieldName}-${id}`;
  const { t, localValue, updateState } = useData(checkBoxDesc, updateDeclaration);

  const { breakpoints } = useTheme();

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      sx={FlexMainBoxRow(breakpoints)}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      <FormControlLabel
        data-cy={nameWithId}
        control={
          <Checkbox
            readOnly={isReadOnly}
            disabled={isReadOnly}
            data-cy={`${nameWithId}-checkBox`}
            checked={localValue}
            onChange={updateState}
          />
        }
        label={label ? labelSpan : ''}
      />
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  );
};
export default observer(CheckBoxQcField);
