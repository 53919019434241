import { FieldDesc } from '90.quickConnect.Models/models';

export const updatePropertyFieldOrChild = <T extends FieldDesc>(
  fullPathIdPropertyField: string,
  field: T,
  property: string,
  propertyValue: any,
): [T, boolean] => {
  let changeMade = false;

  if (field.fullPathId === fullPathIdPropertyField && property in field) {
    field[property as keyof T] = propertyValue;
    changeMade = true;
  }

  if (!changeMade && field.items !== undefined && field.items.length > 0) {
    field.items.forEach((child) => {
      const [updatedChild, childChanged] = updatePropertyFieldOrChild(
        fullPathIdPropertyField,
        child,
        property,
        propertyValue,
      );
      if (childChanged) {
        changeMade = childChanged;
        // field.errors = errors?.filter((errorMsg: string) => !field.errors?.includes(errorMsg));
      }
      child = updatedChild;
    });
  }
  return [field, changeMade];
};
