import { BaseQCOperator, DualOperator, MultipleOperator } from '90.quickConnect.Models/models';

export const isBaseOperator = (input: unknown): input is BaseQCOperator => {
  return (
    typeof input === 'object' &&
    input !== null &&
    'type' in input &&
    'id' in input &&
    'conditionalOpeType' in input &&
    'label' in input
  );
};

export const isDualOperator = (input: unknown): input is DualOperator => {
  return typeof input === 'object' && input !== null && isBaseOperator(input) && 'arg1' in input && 'arg2' in input;
};

export const isMultipleOperator = (input: unknown): input is MultipleOperator => {
  return typeof input === 'object' && input !== null && 'args' in input && Array.isArray(input.args);
};
