import { useCallback } from 'react';
import { toJS } from 'mobx';
import { UseHydrateDeclarationWithEquipmentProps } from './types';
import { useStore } from '30.quickConnect.Stores';
import {
  EntityData,
  FieldDesc,
  ComboDesc,
  AttributeValue,
  AllFieldValueTypes,
  Choice,
  HierarchicalDesc,
  SharedList,
  HierarchicalChoice,
} from '90.quickConnect.Models/models';
import { FieldType, SharedListType } from '90.quickConnect.Models/enums';
import updateValueFieldOrChild from '20.formLib/helpers/updateField/updateValueFieldOrChild';
import { choiceListParse } from '80.quickConnect.Core/helpers/choiceListParsers';
import { emailParse } from '10.quickConnect.app/components/domain/Declaration/helpers/emailParsers';
import { isChoice } from '90.quickConnect.Models/guards';

const useHydrateDeclarationWithEquipment = (): UseHydrateDeclarationWithEquipmentProps => {
  // On récupère le store
  const {
    SharedListStore: { getSharedList, logger },
  } = useStore();

  const findChoiceItemInSharedList = useCallback(
    (
      items: Choice[] | HierarchicalChoice[],
      value: AllFieldValueTypes,
      listType: SharedListType,
    ): AllFieldValueTypes => {
      // Parse la string "valeur" afin de s'assurer de la bonne structure de données.
      const valueStringnified = value as string;
      const nextListValues = choiceListParse(valueStringnified, items, listType, logger);

      if (listType === SharedListType.Hierarchical) {
        return (nextListValues as HierarchicalChoice[]).map(({ label, value: hValue, data }: HierarchicalChoice) => ({
          label,
          value: hValue,
          data,
        }));
      } else return nextListValues;
    },
    [logger],
  );

  const getFormattedValueFromEquipmentAsync = useCallback(
    async (bindedAttributes: AttributeValue, bindedField: FieldDesc): Promise<AllFieldValueTypes> => {
      const { fieldType } = bindedField;
      const { value } = bindedAttributes;
      let listId: string | undefined;
      let sharedList: SharedList | undefined;

      switch (fieldType) {
        case FieldType.Combo:
        case FieldType.RadioList:
          // Recuperation de la liste partagee:
          // eslint-disable-next-line prefer-destructuring
          listId = (bindedField as ComboDesc).listId;
          if (listId) {
            sharedList = await getSharedList(listId);
          }

          if (sharedList !== undefined && typeof value === 'string') {
            const choiceItem = findChoiceItemInSharedList(sharedList.data, value, sharedList.listType) as Choice[];
            return choiceItem.length === 1 ? toJS(choiceItem[0]) : '';
          }

          return value;

        case FieldType.HierarchicalList:
          // Recuperation de la liste partagee:
          // eslint-disable-next-line prefer-destructuring
          listId = (bindedField as HierarchicalDesc).listId;
          if (listId) {
            sharedList = await getSharedList(listId);
          }
          if (sharedList !== undefined && typeof value === 'string') {
            const hierarchicalChoiceItem = findChoiceItemInSharedList(
              sharedList.hierarchicalChoices,
              value,
              sharedList.listType,
            );
            return hierarchicalChoiceItem;
          } else if (sharedList && isChoice(value)) {
            const valStringified = JSON.stringify([value]);

            const hierarchicalChoiceItem = findChoiceItemInSharedList(
              sharedList.hierarchicalChoices,
              valStringified,
              sharedList.listType,
            );
            return hierarchicalChoiceItem;
          }

          return value;

        case FieldType.Digits:
        case FieldType.Numeric:
          if (value && typeof value === 'number') {
            return value.toString();
          } else if (value && typeof value === 'string' && !isNaN(parseFloat(value))) {
            return value;
          } else {
            return '';
          }

        case FieldType.Notification:
          return emailParse(value as string, logger);

        default:
          return value;
      }
    },
    [getSharedList, findChoiceItemInSharedList, logger],
  );

  const hydrateDeclarationWithEquipmentAttributesAsync = useCallback(
    async (declaration: FieldDesc[], newEntityData: EntityData): Promise<FieldDesc[]> => {
      const results = await Promise.all(
        declaration.map(async (bindedField: FieldDesc) => {
          const bindedAttributes = newEntityData.attributes.find((a) => a.attributeId === bindedField.binding);
          if (!bindedAttributes) return bindedField;

          const value = await getFormattedValueFromEquipmentAsync(bindedAttributes, bindedField);
          const [result] = updateValueFieldOrChild(bindedField.fullPathId, bindedField, value);
          return result;
        }),
      );

      return results;
    },
    [getFormattedValueFromEquipmentAsync],
  );

  return { hydrateDeclarationWithEquipmentAttributesAsync };
};

export default useHydrateDeclarationWithEquipment;
