import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UseDataProps } from './types';
import { HOME } from '10.quickConnect.app/routes/paths';
import { useStore } from '30.quickConnect.Stores';

const useData = (showLeftMenuButton: boolean | undefined): UseDataProps => {
  // On récupère le store
  const {
    CommonStore: { setShowLeftMenu, showLeftMenu },
    DeclarationStore: {
      isUseDeclarationComponent: openSaveDraftDeclarationDialog,
      openDialog,
      editableDeclaration,
      isEditingCurrentDeclaration,
      setIsEditingCurrentDeclaration,
    },
  } = useStore();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path, { replace: false });
  };

  const goHome = useCallback(
    (e?: Event): void => {
      // Manage open in a new tab with scroll click
      if (e?.type === 'auxclick') {
        window.open(HOME, '_blank');
        return;
      }
      if (openSaveDraftDeclarationDialog && editableDeclaration) {
        openDialog();
        return;
      }
      if (isEditingCurrentDeclaration) setIsEditingCurrentDeclaration(false);
      navigate(HOME);
    },
    [
      navigate,
      openSaveDraftDeclarationDialog,
      openDialog,
      editableDeclaration,
      setIsEditingCurrentDeclaration,
      isEditingCurrentDeclaration,
    ],
  );

  useEffect(() => {
    if (!showLeftMenuButton) setShowLeftMenu(false);
  }, [setShowLeftMenu, showLeftMenuButton]);

  return {
    handleNavigation,
    goHome,
    setShowLeftMenu,
    showLeftMenu,
  };
};

export default useData;
