import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Name } from './const';
import useData from './hooks';
import { QCDocumentProps } from './types';
import { MainBox } from './styles';
import { getComparator } from '80.quickConnect.Core/helpers';

const QCDocuments: React.FC<QCDocumentProps> = ({ qcDocuments }: QCDocumentProps) => {
  const { t } = useTranslation('equipment');
  const {
    headCells,
    orderBy,
    order,
    emptyRows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    createSortHandler,
  } = useData(t, qcDocuments);

  return (
    <Box data-cy={Name} sx={MainBox}>
      <TableContainer>
        <Table size={'medium'}>
          <TableHead>
            <TableRow>
              {headCells.map(({ id, hideOnMobile }) => (
                <TableCell
                  key={id}
                  sortDirection={orderBy === id ? order : false}
                  sx={
                    hideOnMobile
                      ? { fontWeight: 'bold', display: { xs: 'none', sm: 'none', md: 'table-cell', lg: 'table-cell' } }
                      : { fontWeight: 'bold' }
                  }
                >
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id && order !== false ? order : 'asc'}
                    onClick={createSortHandler(id)}
                  >
                    {t(`qcDocument.${id}`)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {qcDocuments
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                      {row.title}
                    </TableCell>
                    <TableCell>{row.lastModified?.toString()}</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                      {row.contentType}
                    </TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'none', md: 'table-cell', lg: 'table-cell' } }}>
                      {row.size}
                    </TableCell>
                    <TableCell
                      sx={{ display: { xs: 'table-cell', sm: 'table-cell', md: 'table-cell', lg: 'table-cell' } }}
                    >
                      <Box>
                        <Tooltip title={t('qcDocument_showFile').toString()}>
                          <IconButton color="primary" component="label" onClick={() => window.open(row.storageUri)}>
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={qcDocuments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
export default observer(QCDocuments);
