import { SxProps, Theme } from '@mui/material';
import { AppModulesIDs } from '30.quickConnect.Stores/RootStore/LoginStore/Payloads/responses';
import { borderLightColor, kraft500, slateGrey900 } from '00.app/theme';

export const TopBarHeight = 75;

export const ToolBar = 125;

export const BottomNavBarHeight = 90;

export const TopInboxesBarHeight = 250;

export const HeightWithoutTopBar = `calc(100vh - ${TopBarHeight}px)`;

export const HeightWithoutTopAndBottomBar = `calc(100vh - ${TopBarHeight}px - ${ToolBar} - ${BottomNavBarHeight}px)`;

export const HeightWithoutTopInboxesBar = `calc(100vh - ${TopInboxesBarHeight}px)`;

export const FullHeightWithoutTopBar = {
  xs: HeightWithoutTopAndBottomBar,
  sm: HeightWithoutTopAndBottomBar,
  md: HeightWithoutTopBar,
  lg: HeightWithoutTopBar,
};

export const FullHeightWithoutTopInboxesBar = {
  xs: HeightWithoutTopAndBottomBar,
  sm: HeightWithoutTopAndBottomBar,
  md: HeightWithoutTopInboxesBar,
  lg: HeightWithoutTopInboxesBar,
};

export const OverflowForDesktop = { xs: 'unset', sm: 'unset', md: 'auto', lg: 'auto' };

export const OverflowForMobile = { xs: 'auto', sm: 'auto', md: 'unset', lg: 'unset' };

export const MainBox: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  height: '100%',
  paddingLeft: '1vw',
  paddingRight: '1vw',
  width: '100%',
};

export const TopBarStyle = (theme: Theme, module?: string): SxProps => {
  const isModule = module === AppModulesIDs.MODULE_PARAM_WORK_SITE_ID;
  const bgColor = isModule ? borderLightColor : kraft500;
  return {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: `${TopBarHeight}px`,
    background:
      theme.palette.mode === 'light' ? bgColor : `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${bgColor}`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // ...AdaptWidthToMenu(theme, showLeftMenu),
    '& .MuiButtonBase-root': {
      color: isModule ? slateGrey900 : 'auto',
    },
  };
};

export const LeftItemsStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  flex: '1 1 0px',
};
