import { SxProps, Theme } from '@mui/material';
import { LeftMenuWidth, LargeLeftMenuWidth } from '../styles';

export const LeftMenuDrawer = (theme: Theme): SxProps => ({
  flexShrink: 0,
  [theme.breakpoints.up('lg')]: {
    width: `${LargeLeftMenuWidth}px`,
    '& .MuiDrawer-paper': {
      width: `${LargeLeftMenuWidth}px`,
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: `${LeftMenuWidth}px`,
    '& .MuiDrawer-paper': {
      width: `${LeftMenuWidth}px`,
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
});
