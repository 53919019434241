import { TFunction } from 'i18next';
import { FieldType } from '90.quickConnect.Models/enums';
import { AllFieldValueTypes } from '90.quickConnect.Models/models';
import convertToBooleanValue from '80.quickConnect.Core/helpers/convertToBooleanValue';

export const getReferencedCheckboxValue = (
  valueReferenceDependency: AllFieldValueTypes,
  fieldTypeFieldWithReference: FieldType,
  t: TFunction,
): AllFieldValueTypes => {
  const checkboxValue = valueReferenceDependency as boolean;

  switch (fieldTypeFieldWithReference) {
    case FieldType.Text:
    case FieldType.Label:
    case FieldType.ReadOnlyValue:
      return convertToBooleanValue(checkboxValue)
        ? t('reference_checkbox_true').toString().toUpperCase()
        : t('reference_checkbox_false').toString().toUpperCase();
    case FieldType.CheckBox:
      return checkboxValue;
    case FieldType.Counter:
    case FieldType.Digits:
      return checkboxValue ? '1' : '0';
    case FieldType.Numeric:
      return checkboxValue ? '1.0' : '0.0';

    default:
      return undefined;
  }
};
