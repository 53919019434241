import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { List, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomListInboxesProps } from './types';
import { InboxesListName } from './const';
import ViewSkeleton from '10.quickConnect.app/components/domain/Home/Shared/Skeletons/viewSkeleton';
import { TitlesMargin } from '10.quickConnect.app/components/domain/Home/Inboxes/styles';
import { useIsMobile } from '80.quickConnect.Core/hooks';
import { ListColumnContainer } from '10.quickConnect.app/components/domain/Home/styles';
import { InboxViewer } from '90.quickConnect.Models/models';

const CustomListInboxes = ({ title, items, RenderLine, sortFn }: CustomListInboxesProps) => {
  const scrollTarget = useRef<HTMLElement>();

  const isMobile = useIsMobile();
  const { t } = useTranslation('inboxes');
  return (
    <Box data-cy={InboxesListName} sx={ListColumnContainer} ref={scrollTarget}>
      <div id={`scrollToAnchor-${InboxesListName}`} />
      {title && (
        <Typography p={2} align="center" sx={TitlesMargin} variant={'h6'}>
          {title}
        </Typography>
      )}
      {items ? (
        <List>
          {items.length > 0 ? (
            items
              .slice()
              .sort(sortFn)
              .map((i) => <RenderLine key={i.id} {...i} />)
          ) : (
            <Typography align="center" p={1}>
              {t('no_elements').toString()}
            </Typography>
          )}
        </List>
      ) : (
        <ViewSkeleton />
      )}
      {/* {!isMobile && scrollTarget.current && (
        <ScrollTo
          idToScrollTo={`scrollToAnchor-${InboxesListName}`}
          target={scrollTarget.current}
          parentRect={scrollTarget.current.getBoundingClientRect()}
        />
      )} */}
    </Box>
  );
};
export default observer(CustomListInboxes);
