/**
 * Les différents types de formulaire
 */
export enum FormType {
  /**
   * Pas defini : erreur
   */
  Unknow = 0,

  /**
   * Un formulaire simple
   */
  Form = 1,

  /**
   * Un modele de formulaire
   * Peut etre inclu dans un formulaire simple
   */
  Template = 2,
  /**
   * Un formulaire de type workflow contenant des étapes
   */
  Workflow = 3,
}
