import { FieldType } from '90.quickConnect.Models/enums';
import { isActionData, isChoiceList } from '90.quickConnect.Models/guards';
import { mapChoiceListFromString } from '90.quickConnect.Models/mappings';
import { Choice } from '90.quickConnect.Models/models';

export default function mapAllFieldTypeValuesToChoiceList(nextValue: unknown): Choice[] | undefined {
  if (isChoiceList(nextValue)) return [...nextValue];
  else if (isActionData(nextValue) && nextValue.length > 0) {
    const [firstActionElement] = nextValue;

    const { fieldType, value } = firstActionElement;

    return fieldType === FieldType.CheckBoxList ? (value as Choice[]) : [value as Choice];
  } else if (typeof nextValue === 'string') return mapChoiceListFromString(nextValue);
}
