import { FieldType } from '90.quickConnect.Models/enums';
import { FieldDesc, RepeatableGroupDesc } from '90.quickConnect.Models/models';

export default function getLRUFullId(fields: FieldDesc[], paths: string[]): string | never {
  if (paths.length < 1) throw new Error('getLRUFullId en erreur: le paramètre "paths" ne peut pas être vide');

  const [rootId, ...subIds] = paths;

  // Dans le cas où il n'y a qu'un seul ID dans le paths, on le retourne directement.
  if (paths.length === 1) return rootId;

  // Sinon on continue la recherche.
  const field = fields.find(({ id }: FieldDesc) => id === rootId);

  if (!field) throw new Error(`getLRUFullId en erreur: aucun champ trouvé avec l'id "${rootId}"`);

  const { items, fieldType } = field;

  if (fieldType === FieldType.RepeatableGroup) {
    const { groupTemplate } = field as RepeatableGroupDesc;

    return `${rootId}.${getLRUFullId(groupTemplate, subIds.slice(2))}`;
  } else {
    return `${rootId}.${getLRUFullId(items, subIds)}`;
  }
}
