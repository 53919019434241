import { Choice } from '90.quickConnect.Models/models';
import { isRecordWithProperty } from '90.quickConnect.Models/guards';

const isChoice = (field: any): field is Choice => {
  return (
    field &&
    typeof field === 'object' &&
    !Array.isArray(field) &&
    isRecordWithProperty(field, 'label', 'string') &&
    isRecordWithProperty(field, 'value', 'string')
  );
};

export default isChoice;
