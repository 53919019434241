import { arrayIntersect, errorHandler, flatten, parseAndCamelize } from '80.quickConnect.Core/helpers';
import { SharedListType } from '90.quickConnect.Models/enums';
import { Choice, HierarchicalChoice } from '90.quickConnect.Models/models';
import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { StringExtension } from '80.quickConnect.Core/formatting/StringExtension';

const tag = '80.quickConnect.Core/helpers/choiceListParsers/index.ts';

/**
 * Permet de parser une liste simple ou hiérarchique afin de pouvoir binder correctement les valeurs des attributs d'un équipement dans un formulaire.
 */
export const choiceListParse = (
  serializedList: string,
  items: Choice[] | HierarchicalChoice[],
  listType: SharedListType,
  logger: CustomLogger,
  splitSeparatorString = ',',
): Choice[] | HierarchicalChoice[] => {
  if (serializedList !== '' && serializedList !== null) {
    serializedList = serializedList.trim();

    // serializedList peut etre au format
    // tableau [ { "label": "monlabel", "value": "mavalue" }, ... ]
    // Elément unique { "label": "monlabel", "value": "mavalue" }
    // ou simplement une chaine "xxxx, yyyy, zzzz"

    if (serializedList.startsWith('[')) {
      try {
        type BaseChoices = Choice | HierarchicalChoice;
        const list = parseAndCamelize(serializedList) as Choice[];
        const key = list.every((val: Choice) => 'value' in val) ? 'value' : 'label';
        const choices = arrayIntersect(
          listType === SharedListType.Hierarchical ? flatten(items as HierarchicalChoice[], (i) => i.children) : items,
          list,
          key,
        );
        return listType === SharedListType.Hierarchical ? (choices as HierarchicalChoice[]) : (choices as Choice[]);
      } catch (error) {
        if (error instanceof Error)
          error.message = 'La normalisation de la donnée a échouée comme tableau: ' + error.message;
        errorHandler(tag, error, 'choiceListParse');
      }
    } else {
      // Element unique
      if (serializedList.startsWith('{')) {
        try {
          const list = parseAndCamelize(serializedList);

          // Des fois, on a pas tout a fait un choice...
          return (items as Choice[]).reduce((acc: Choice[], current: Choice | HierarchicalChoice) => {
            if ('value' in list) return StringExtension.isTheSame(current.value, list.value) ? [...acc, current] : acc;
            else if ('label' in list)
              return StringExtension.isTheSame(current.label, list.label) ? [...acc, current] : acc;
            else return acc;
          }, []);
        } catch (error) {
          if (error instanceof Error)
            error.message = 'La normalisation de la donnée a échouée comme objet: ' + error.message;
          errorHandler(tag, error, 'choiceListParse');
        }
      } else {
        // Au format chaine directement
        const listStrSplitted = serializedList.split(splitSeparatorString).map((item) => item.trim());

        if (listType === SharedListType.Hierarchical) {
          const hierarchicalItems = items as HierarchicalChoice[];
          const flattenHierarchicalChoiceItemsChildren = flatten(
            hierarchicalItems,
            (hierarchicalItem) => hierarchicalItem.children,
          );
          // const flattenHierarchicalChoiceItemsChildren = hierarchicalItems.flatMap(
          // (hierarchicalItem: HierarchicalChoice) => hierarchicalItem,
          // );

          return listStrSplitted.reduce((acc: HierarchicalChoice[], current: string) => {
            const hierarchicalChoice = flattenHierarchicalChoiceItemsChildren.find(
              (choice: HierarchicalChoice) => choice.label === current || choice.value === current,
            );
            return hierarchicalChoice ? [...acc, hierarchicalChoice] : acc;
          }, []);
        } else {
          const choiceItems = items as Choice[];
          //const flattenChoiceItemsData = flatten(choiceItems, (choiceItem) =>  choiceItem.data as Choice[]);
          const flattenChoiceItemsData = choiceItems.flatMap((choiceItem: Choice) => choiceItem);
          return listStrSplitted.reduce((acc: Choice[], current: string) => {
            const choiceItem = flattenChoiceItemsData.find(
              (choice: Choice) => choice.label === current || choice.value === current,
            );
            return choiceItem ? [...acc, choiceItem] : acc;
          }, []);
        }
      }
    }
  }

  return [];
};
