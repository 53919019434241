import { FieldDesc } from '90.quickConnect.Models/models';

const mapDesactivatedAutomation = (fields: FieldDesc[], desactivatedAutomation: boolean): FieldDesc[] =>
  fields.map((field: FieldDesc) => ({
    ...field,
    desactivatedAutomation,
    items: mapDesactivatedAutomation(field.items, desactivatedAutomation),
  }));

export default mapDesactivatedAutomation;
