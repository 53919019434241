import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { EditDesc } from '90.quickConnect.Models/models';
import { debounceTime } from '50.quickConnect.Fields/const';
import { isNumeric } from '80.quickConnect.Core/helpers/common';
import { useStore } from '30.quickConnect.Stores';

const useData = (
  editDesc: EditDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: number | null) => void,
): UseDataProps => {
  const { fullPathId, value, reference } = editDesc;

  const {
    DeclarationStore: { isDeepLink },
  } = useStore();

  // on définit les states
  const [localValue, setLocalValue] = useState<number | null>(() => {
    if (typeof value === 'number') return value;
    if (typeof value === 'string' && !Number.isNaN(value)) return +value;
    return null;
  });

  // On définit les callbacks
  const updateGlobalState = useCallback(() => {
    updateDeclaration(fullPathId, localValue);
  }, [fullPathId, localValue, updateDeclaration]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const handleChangeRating = useCallback(
    (event: any, newValue: number | null) => {
      setLocalValue(newValue ?? 0);
      debouncedUpdateGlobalState();
    },
    [debouncedUpdateGlobalState],
  );

  // UseEffect pour le système des références...
  useEffect(() => {
    if (value === null && isDeepLink) setLocalValue(null);

    const newLocalValue = isNumeric(value) ? +value : undefined;
    setLocalValue((prevLocalValue) => (prevLocalValue !== newLocalValue ? newLocalValue ?? 0 : prevLocalValue));
  }, [reference, value, isDeepLink]);

  // On retourne les valeurs à la vue
  return { handleChangeRating, localValue };
};
export default useData;
