import { toJS } from 'mobx';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { UseDataProps } from './types';
import { HierarchicalListRightContentName } from './const';
import { AllFieldValueTypes, HierarchicalDesc, HierarchicalChoice } from '90.quickConnect.Models/models';
import { mapHierarchicalChoices } from '90.quickConnect.Models/mappings';
import { flatten } from '80.quickConnect.Core/helpers';
import { debounceTime } from '50.quickConnect.Fields/const';

const useData = (
  hierarchicalDesc: HierarchicalDesc,
  updateDeclaration: (updatedFieldFullPathId: string, newValue: AllFieldValueTypes) => void,
  hierarchicalChoices: HierarchicalChoice[],
  deletedItem: string | undefined,
  setDeleteItem: React.Dispatch<React.SetStateAction<string | undefined>>,
  isDeleteAll: boolean,
  setIsDeleteAll: Dispatch<SetStateAction<boolean>>,
): UseDataProps => {
  // On récupère la translation
  const { t } = useTranslation('declaration');
  const { id, defaultValue, fullPathId, multiSelection, value, reference } = hierarchicalDesc;

  const [filterList, setFilterList] = useState('');

  const nameWithId = useMemo(() => `${HierarchicalListRightContentName}-${id}`, [id]);

  const flattenNodes = useMemo(() => flatten(hierarchicalChoices ?? [], (h) => h.children), [hierarchicalChoices]);
  const [parents, setParents] = useState<HierarchicalChoice[]>();
  const [currentChoicesLevel, setCurrentChoicesLevel] = useState<HierarchicalChoice[] | undefined>(hierarchicalChoices);

  const filteredChoices = useMemo(() => {
    const stringToSearch = filterList.toLocaleLowerCase();
    const filtered = currentChoicesLevel?.filter(
      (ccl) => filterList === '' || ccl.childrenLabels.some((childLabel) => childLabel.indexOf(stringToSearch) !== -1),
    );

    return filtered;
  }, [currentChoicesLevel, filterList]);

  const [localValue, setLocalValue] = useState<HierarchicalChoice[]>((value as HierarchicalChoice[]) ?? []);

  // On définit les callbacks
  // Update du formulaire au global
  const updateGlobalState = useCallback(() => {
    // if (localValue.length === 1) updateDeclaration(fullPathId, localValue[0]);
    updateDeclaration(fullPathId, localValue);
  }, [localValue, updateDeclaration, fullPathId]);

  useEffect(() => {
    if (deletedItem) {
      const filteredLocalValue = localValue?.filter((it) => it.value !== deletedItem);
      setLocalValue(filteredLocalValue);
      setDeleteItem(undefined);
    }
  }, [deletedItem, localValue, setDeleteItem]);

  useEffect(() => {
    if (isDeleteAll) {
      setLocalValue([]);
      setIsDeleteAll(false);
    }
  }, [isDeleteAll, setIsDeleteAll]);

  const debouncedUpdateGlobalState = useDebouncedCallback(() => {
    updateGlobalState();
  }, debounceTime);

  const getParents = useCallback(
    (hierarchicalChoice: HierarchicalChoice): HierarchicalChoice[] => {
      const results: HierarchicalChoice[] = [];
      const parent = flattenNodes.find((n) => n.hierarchicalNodePath === hierarchicalChoice.hierarchicalParentNode);
      if (parent) {
        results.push(parent);
        if (parent.hierarchicalNodePath) return results.concat(getParents(parent));
      }
      return results;
    },
    [flattenNodes],
  );

  const onClickHandle = useCallback(
    (hierarchicalChoice: HierarchicalChoice) => () => {
      if (hierarchicalChoice.children.length > 0) {
        setParents((prev) => [...(prev ?? []), hierarchicalChoice]);
        setCurrentChoicesLevel(hierarchicalChoice.children);
      } else {
        if (multiSelection) {
          const newChecked = [...localValue];
          const currentIndex = newChecked.findIndex(
            (item: HierarchicalChoice) =>
              hierarchicalChoice.label === item.label && hierarchicalChoice.value === item.value,
          );
          if (currentIndex === -1) {
            newChecked.push(hierarchicalChoice);
          } else {
            newChecked.splice(currentIndex, 1);
          }
          setLocalValue(newChecked);
          debouncedUpdateGlobalState();
        } else {
          setLocalValue([hierarchicalChoice]);
          debouncedUpdateGlobalState();
        }
      }
    },
    [debouncedUpdateGlobalState, localValue, multiSelection],
  );

  const goUp = useCallback(
    (hierarchicalChoice: HierarchicalChoice) => {
      const newParents = getParents(hierarchicalChoice);
      setParents(newParents);
      const newParent = flattenNodes.find((n) => n.hierarchicalNodePath === hierarchicalChoice.hierarchicalParentNode);
      const newList = newParent ? newParent.children : hierarchicalChoices;
      setCurrentChoicesLevel(newList);
    },
    [flattenNodes, getParents, hierarchicalChoices],
  );

  // Gestion de la valeur par défaut, on est obligé de la faire ici car les sharedList peuvent ne pas être présentent lors du mapping
  useEffect(() => {
    if (defaultValue) {
      let newValue: HierarchicalChoice[] | undefined;
      try {
        const parsedDefaultValue = JSON.parse(defaultValue);
        if (parsedDefaultValue.constructor.name === 'Array') newValue = mapHierarchicalChoices(parsedDefaultValue);
      } catch {
        if (typeof defaultValue === 'string') {
          let choice: HierarchicalChoice | undefined = undefined;
          if (hierarchicalChoices) {
            hierarchicalChoices.forEach((l) =>
              l.children.find((item: any) => {
                if (item.label === defaultValue || item.value === defaultValue) {
                  choice = item;
                }
              }),
            );
          }

          if (choice) newValue = [choice as HierarchicalChoice];
        }
      }
      if (newValue) {
        setLocalValue(newValue);
        debouncedUpdateGlobalState();
      }
    }
  }, [debouncedUpdateGlobalState, defaultValue, hierarchicalChoices]);

  // useEffect pour les références...
  useEffect(() => {
    if (reference && Array.isArray(value)) {
      const newLocalValue = value as HierarchicalChoice[];
      setLocalValue((previousLocalState: HierarchicalChoice[]) =>
        previousLocalState !== newLocalValue ? newLocalValue : previousLocalState,
      );
    }
  }, [reference, value]);

  // On retourne les valeurs à la vue
  return {
    t,
    updateGlobalState,
    onClickHandle,
    goUp,
    filteredChoices,
    parents,
    localValue,
    nameWithId,
    setFilterList,
  };
};

export default useData;
