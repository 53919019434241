import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import { camelize, errorHandler } from '80.quickConnect.Core/helpers';

const tag = '80.quickConnect.Core/helpers/parseAndCamelize.ts';

function reviverCamelize(this: any, key: string, value: any): any | undefined {
  const camelizedKey = camelize(key);

  if (this instanceof Array || camelizedKey === key) {
    // if this is Array
    // or key does not change after converted to camel case
    // then just return the value so that the default "reviving" is done
    return value;
  } else {
    // if key changes assing value to camel case one and return nothing
    this[camelizedKey] = value;
  }
}

const parseAndCamelize = (str: string): any => {
  try {
    return JSON.parse(str, reviverCamelize);
  } catch (error: unknown) {
    errorHandler(tag, error, 'parseAndCamelize');

    return undefined;
  }
};

export default parseAndCamelize;
