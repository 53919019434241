import { ActionParamValue } from '10.quickConnect.app/components/domain/Home/Declarations/DeclarationHistory/types';
import { getAllParentsFieldsAreVisible } from '50.quickConnect.Fields/helpers/visibility';
import { errorHandler, flatten } from '80.quickConnect.Core/helpers';
import { FieldType } from '90.quickConnect.Models/enums';
import { mapToFieldData } from '90.quickConnect.Models/mappings';
import { FieldData, FieldDesc } from '90.quickConnect.Models/models';

const tag = '50.quickConnect.Fields/FieldsTypes/Others/QcAction/utils/index.ts';

const checkActionCriterias = (field: FieldDesc, paramsIds: string[]): boolean => {
  const { fieldType, id } = field;

  return (
    paramsIds.some((paramId: string) => id.toLocaleLowerCase().startsWith(paramId)) && fieldType !== FieldType.Action
  );
};

const checkFieldsForAddActionParams = (
  declaration: FieldDesc[],
  { id: actionId, items: actionParams, fullPathId: actionFullPathId }: FieldDesc,
): FieldDesc[] => {
  const paramsIds: string[] = actionParams.reduce(
    (acc: string[], { id }: FieldDesc) => [
      ...acc,
      id.endsWith('*') ? id.slice(0, -1).toLocaleLowerCase() : id.toLocaleLowerCase(),
    ],
    [actionId],
  );

  const otherFields = declaration.filter(({ fullPathId }: FieldDesc) => fullPathId !== actionFullPathId);

  return flatten(otherFields, (i) => i.items).filter((field: FieldDesc) => checkActionCriterias(field, paramsIds));
};

const excludeMediaAndContainerFields = (fieldDesc: FieldDesc): boolean => {
  const { items, fieldType } = fieldDesc;
  if (items.length > 0) {
    fieldDesc.items = items.filter(excludeMediaAndContainerFields);
  }

  return (
    fieldType !== FieldType.Attachment &&
    fieldType !== FieldType.Audio &&
    fieldType !== FieldType.Draw &&
    fieldType !== FieldType.FixedAttachment &&
    fieldType !== FieldType.FixedImage &&
    fieldType !== FieldType.Photo &&
    fieldType !== FieldType.Signature &&
    fieldType !== FieldType.Dialog &&
    fieldType !== FieldType.Group &&
    fieldType !== FieldType.Include &&
    fieldType !== FieldType.RepeatableGroup
  );
};

const excludeNoVisibleField = (fieldDesc: FieldDesc, _: number, fields: FieldDesc[]): boolean => {
  const { isVisible, isVisibleOnDevice, fullPathId } = fieldDesc;

  const bVisibility = isVisibleOnDevice ?? isVisible;

  if (!fullPathId.includes('.')) return bVisibility;

  // Il faut vérifier si les parents sont visibles
  const bVisibilityParents = getAllParentsFieldsAreVisible(fields, fullPathId);

  return bVisibility && bVisibilityParents;
};

const excludeFieldWithNoValues = (fieldDesc: FieldDesc): boolean => {
  const { items, value } = fieldDesc;

  if (items.length > 0) fieldDesc.items = items.filter(excludeFieldWithNoValues);

  return value !== undefined;
};

const mapToActionParamValues = ({ label, value, id, fieldType }: FieldData): ActionParamValue => ({
  id,
  fieldType,
  label: label ?? undefined,
  value,
});

export const getActionParamFromDeclaration = (declaration: FieldDesc[], actionFullId: string): ActionParamValue[] => {
  try {
    const actionField = declaration.find((field: FieldDesc) => field.fullPathId === actionFullId);

    if (!actionField) {
      throw new Error(`Aucun champ action avec le fullPathId: ${actionFullId} n'a été trouvé`);
    }

    const fieldsToAddForActionParams = checkFieldsForAddActionParams(declaration, actionField);

    return fieldsToAddForActionParams
      .filter(excludeNoVisibleField)
      .filter(excludeMediaAndContainerFields)
      .filter(excludeFieldWithNoValues)
      .map((f) => mapToFieldData(f, true))
      .map(mapToActionParamValues);
  } catch (error) {
    errorHandler(tag, error, 'getActionParamFromDeclaration', 'trace');
    return [];
  }
};
